import React, { useEffect, useState } from "react";
import "./auth.css";
import { Auth } from "aws-amplify";
import Login from "./Login/login";
import Signup from "./Signup/signup";
import checkMark from "../../assets/images/checkMark.svg";
import { useHistory, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Redirect } from "react-router-dom";
import HideIFA from "../HideIFA/hideIFA";
import config from "../../config/settings";

const AuthPage = observer(function AuthPage(props) {
  const [isLogin, setIsLogin] = useState();
  const [render, setRender] = useState(false);
  const [thankYouPage, setThankYOuPage] = useState(false);
  const [autoApprovePage, setAutoApprovePage] = useState(false);
  const [mobile, setMobile] = useState("");
  const [kycData, setkycData] = useState("");
  const [showPage, setShowPage] = useState("Login");
  const [targetUrl, setTargetUrl] = useState("/overview");
  const [hideIFA, setHideIFA] = useState(false);
  const location = useLocation();
  function handleWindowSizeChange() {
    if (window.innerWidth <= 1080 || window.innerHeight <= 600) {
      setHideIFA(true);
    } else {
      setHideIFA(false);
    }
  }
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const redirect = queryParams.get("redirect");
    if (redirect && redirect != "") setTargetUrl(redirect);
  }, [location]);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    if (window.innerWidth <= 1080 || window.innerHeight <= 600) {
      setHideIFA(true);
    }
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  // check if user login or not
  useEffect(() => {
    props.showCommonHeader(false);
    props.showCommonSidebar(false);

    let checkLogin = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        setRender(true);
        setIsLogin(true);
      } catch {
        setRender(true);
        setIsLogin(false);
      }
    };
    return checkLogin();
  }, [props]);

  useEffect(() => {
    const show_page = localStorage.getItem("thank_you_page");
    if (show_page) {
      setThankYOuPage(true);
    }
    const signup = JSON.parse(localStorage.getItem("signup"));
    if (signup) {
      setShowPage("Signup");
    }
  }, [thankYouPage]);
  // on change
  const onChange = (value) => {
    setShowPage(value);
  };

  // if user is login then redirect to overview page
  if (
    isLogin === true &&
    JSON.parse(localStorage.getItem("Authenticated")) == true
  ) {
    if(localStorage.getItem("kyc_status") != 2)
    {
      return Auth.signOut()
      .then(() => {
        return <Redirect to="/" />;
        localStorage.clear();
      })
      .catch((error) => {
        console.log(error);
      });
    }
    else
    {
      return <Redirect to={targetUrl} />;
    }
    
  }

  if (hideIFA && showPage === "Signup") {
    return <HideIFA />;
  }

  return (
    <div>
      {render === false ? (
        <div />
      ) : (
        <div className="auth_main_container">
          {/* header */}
          <header className="auth_header">
            <img
              src="https://arthmate-p2p-public-assets.s3.ap-south-1.amazonaws.com/images/1invest-logo-color-dark.png"
              alt="logo"
              className="auth_logo_img"
              onClick={() => window.open(config.website.url, "_self")}
            />
            <h1 className="auth_header_text"> Partner Registration </h1>
          </header>

          {thankYouPage && autoApprovePage ? (
            <div className="thank_page_container">
              <div className="thank_image_container">
                <img
                  src={checkMark}
                  alt="check_mark_icon"
                  className="check_mark"
                />
              </div>
              <div className="thank_main_title">
                <h3>Thank you for registering with us.</h3>
              </div>
            </div>
          ) : thankYouPage ? (
            <div className="thank_page_container">
              <div className="thank_image_container">
                <img
                  src={checkMark}
                  alt="check_mark_icon"
                  className="check_mark"
                />
              </div>
              <div className="thank_main_title">
                <h3>Thank you for registering with us.</h3>
                <p>
                  Your documents have been successfully uploaded and your KYC is
                  under review. We will get back to you within 1 business day.
                  <br /> <br />
                  <strong>
                    Please check your registered email for updates.
                  </strong>
                </p>
              </div>
            </div>
          ) : (
            /*** auth rest container */
            <div className={"auth_rest_container"}>
              {/* image div */}
              <div className="auth_image_container">
                {/* title */}
                {/* <h1 className="auth_main_title">IFA Dashboard</h1> */}
                {/* image */}
                {/* <img src={AuthIcon} alt="auth_icon" className="auth_image" /> */}
              </div>
              {/* form div */}
              <div className="auth_form_container">
                {showPage === "Login" ? (
                  <Login
                    show={onChange}
                    changeMobile={(mobile, page) => {
                      setMobile(mobile);
                      setShowPage(page);
                    }}
                    rejectKyc={(kycData, page) => {
                      setkycData(kycData);
                      setShowPage(page);
                    }}
                    targetUrl={targetUrl}
                  />
                ) : (
                  <Signup
                    show={onChange}
                    show_thank_screen={setThankYOuPage}
                    auto_approve_screen={setAutoApprovePage}
                    mobile={mobile}
                    kycData={kycData}
                    setShowPage={setShowPage}
                  />
                )}
              </div>
            </div>
          )}
          {/* <Ticker /> */}
        </div>
      )}
    </div>
  );
});

export default AuthPage;
