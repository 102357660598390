import React, { useEffect, useRef, useState, createRef } from "react";
import { Auth } from "aws-amplify";
import "./poolclient.css";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SearchIcon from "@mui/icons-material/Search";
import { Pools_API } from "../../services/api";
import {
  ClientOverview,
  ClientActiveInvestments,
  ClientClosedInvestments,
  ClientInprocessInvestments,
  OverviewFilter,
  CustomTooltip,
  ContentLoadingLoader,
  PoolClientlistModel,
  SampleToast,
  InfoAlert,
} from "../../components";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Box,
  Grid,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import { useStores } from "../../models";
import {
  errorToast,
  convertAmount,
  successToast,
  infoToast,
  handleKeyDown,
} from "../../utils/helper";
import { eventLog } from "../../utils/firebase";
// import HideIFA from "../HideIFA/hideIFA";

const PoolClient = (props) => {
  const navigate = useHistory();
  const api = new Pools_API();
  const param = useLocation();
  const rootStore = useStores();
  const [render, setRender] = useState(false);
  const [loginRender, setLoginRender] = useState(false);
  const [isLogin, setIsLogin] = useState();
  const screenOptions = ["Overview", "Active", "Closed", "In-process"];
  const [screenName, setScreenName] = useState(screenOptions[0]);
  const [filterData, setFilterData] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [investLoader, setInvestLoader] = useState(false);
  const [page, setPage] = useState(0);
  const rowsPerPage = 5;
  const [filterValue, setFilterValue] = useState("All");
  const [overviewCall, setOverviewCall] = useState(0);
  const [activeCall, setActiveCall] = useState(0);
  const [closedCall, setClosedCall] = useState(0);
  const [inprocessCall, setInprocessCall] = useState(0);
  const [selectedClients, setSelectedClients] = useState([]);
  const activeRef = useRef(null);
  const [openClientModel, setOpenClientModel] = useState(false);
  const [searchByNameOrMobile, setSearchByNameOrMobile] = useState(null);
  const [fetchingTableData, setFetchingTableData] = useState(false);
  const [totalClients, setTotalClients] = useState(false);
  const valueRef = useRef([]);
  // const [hideIFA, setHideIFA] = useState(false);
  const closedRef = useRef(null);
  const inprocessRef = useRef(null);
  const infoText =
    "The sum of principal amount repaid and interest received till date.";

  const poolsData = param?.state?.poolsData;
  const tableContainer = {
    marginTop: "10px",
    width: "92%",
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
    borderTop: "1px solid #c7c7c7",
    borderLeft: "1px solid #c7c7c7",
    borderRight: "1px solid #c7c7c7",
    alignSelf: "center",
    overflow: "scroll",
  };

  const tableHeader = {
    backgroundColor: "#ececec",
    fontWeight: "600",
    fontSize: 16,
    fontFamily: "Asap",
    color: "#1f1f1f",
    borderRight: "1px solid #c7c7c7",
    paddingTop: "15px",
    paddingLeft: "18px",
    paddingRight: "0px",
    paddingBottom: "15px",
  };

  const tableRow = {
    fontSize: 14,
    fontFamily: "Asap",
    fontWeight: "400",
    color: "#1c1c1c",
    borderRight: "1px solid #c7c7c7",
    paddingTop: "18px",
    paddingLeft: "18px",
    paddingRight: "0px",
    paddingBottom: "18px",
  };

  // function handleWindowSizeChange() {
  //   if (window.innerWidth <= 1080 || window.innerHeight <= 600) {
  //     setHideIFA(true);
  //     props.showCommonHeader(false);
  //     props.showCommonSidebar(false);
  //   } else {
  //     setHideIFA(false);
  //     props.showCommonHeader(true);
  //     props.showCommonSidebar(true);
  //   }
  // }

  // useEffect(() => {
  //   window.addEventListener("resize", handleWindowSizeChange);
  //   if (window.innerWidth <= 1080 || window.innerHeight <= 600) {
  //     setHideIFA(true);
  //     props.showCommonHeader(false);
  //     props.showCommonSidebar(false);
  //   }
  //   return () => {
  //     window.removeEventListener("resize", handleWindowSizeChange);
  //   };
  // }, []);

  // check if user login or not
  useEffect(() => {
    props.showCommonHeader(true);
    props.showCommonSidebar(true);
    let checkLogin = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        let status = localStorage.getItem("kyc_status");
        if (status != 2) {
          setIsLogin(false);
        }
        else
        {
        setIsLogin(true);
        setLoginRender(true);
        }
        // fetchClientList(param.state.pool.type, searchByNameOrMobile, 1);
      } catch {
        setIsLogin(false);
      }
    };
    return checkLogin();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    valueRef.current = clientData.map(
      (_, i) => valueRef.current[i] ?? createRef()
    );
  }, [clientData]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setPage(0);
      fetchClientList(param.state.pool.type, searchByNameOrMobile, 1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchByNameOrMobile]);

  // fetch the client list
  const fetchClientList = (type, searchNameOrMobile, queryPage) => {
    setFetchingTableData(true);
    rootStore.clientList
      .getClientlist(
        0,
        0,
        type == 0 || type == 2 ? false : true,
        queryPage,
        rowsPerPage,
        searchNameOrMobile,
        "name",
        true
      )
      .then((res) => {
        if (res.kind === "ok") {
          var tempData = res.data.map((item) => {
            let tempObj;
            tempObj = selectedClients.find(
              (client) => client.uuid == item.uuid
            );
            if (tempObj) {
              return {
                ...item,
                amount: tempObj && tempObj.amount ? tempObj.amount : "",
                monthly_payout:
                  param.state.pool.type !== 1 ? tempObj.monthly_payout : null,
                maturity_payout:
                  param.state.pool.type !== 1 ? tempObj.maturity_payout : null,
              };
            } else {
              return {
                ...item,
                amount: "",
                monthly_payout:
                  param.state.pool.type === 2
                    ? true
                    : param.state.pool.type !== 1
                    ? false
                    : null,
                maturity_payout:
                  param.state.pool.type === 0
                    ? true
                    : param.state.pool.type !== 1
                    ? false
                    : null,
              };
            }
          });
          setClientData(tempData);
          setFilterData(tempData);
          setRender(true);
          setFetchingTableData(false);
          if (res.meta && res.meta.pagination && res.meta.pagination.count) {
            setTotalClients(res.meta.pagination.count);
          }
        } else {
          setRender(true);
          errorToast(res.error);
        }
      });
  };

  // handle page change function
  const handleChangePage = (event, newPage) => {
    setSelectedClients(selectedClients);
    fetchClientList(param.state.pool.type, event.target.value, newPage + 1);
    setPage(newPage);
  };

  // sort list via name
  const sortByName = (a, b) => {
    if (String(a.name).trim() < String(b.name).trim()) {
      return -1;
    }
    if (String(a.name).trim() > String(b.name).trim()) {
      return 1;
    }
    return 0;
  };

  // request search function
  const requestSearch = (event) => {
    // if active search call
    if (screenName == "Active") {
      activeRef.current.handlerActiveSearch(event);
    }

    // if closed search call
    if (screenName == "Closed") {
      closedRef.current.handlerClosedSearch(event);
    }

    // if inprocess search call
    if (screenName == "In-process") {
      inprocessRef.current.handlerInprocessSearch(event);
    }
  };

  // if user is not login then redirect to login page
  if (isLogin === false) {
    return <Redirect to="/" />;
  }

  // if the parameter is undefined
  if (param.state === undefined) {
    return <Redirect to="/pools" />;
  }

  // close model
  const handleClose = () => {
    setOpenClientModel(false);
  };

  //check if given id exists in selectedClients array
  const checkIfSelected = (clientId) => {
    for (let i = 0; i < selectedClients.length; i++) {
      const client = selectedClients[i];
      if (client["uuid"] == clientId) {
        return true;
      }
    }
    return false;
  };

  //handle amount change for any of the given client
  const handleValueChange = (
    clientId,
    clientName,
    clientAvailableFunds,
    freedom_plan_invested_amount,
    invested_pools,
    consent_status,
    maturity_payout,
    monthly_payout,
    event,
    isDelete = false
  ) => {
    if (isDelete) {
      let index = clientData.findIndex((client) => client.uuid == clientId);
      if (index != -1) {
        valueRef.current[index].current.value = "";
      }
      let testArray = selectedClients;
      var filteredArray = testArray.filter((e) => e.uuid !== clientId);
      setSelectedClients([...filteredArray]);
    }
    let flag = -1;
    for (let i = 0; i < selectedClients.length; i++) {
      const client = selectedClients[i];
      if (client["uuid"] == clientId) {
        flag = i;
        client["amount"] = parseInt(event.target.value);
        client["monthly_payout"] = monthly_payout;
        client["maturity_payout"] = maturity_payout;
      }
    }
    if (flag != -1) {
      if (event.target.value == 0 || isNaN(event.target.value)) {
        let tempArray = selectedClients;
        tempArray.splice(flag, 1);
        setSelectedClients([...tempArray]);
      }
    } else if (event.target.value != 0 && !isNaN(event.target.value)) {
      setSelectedClients([
        ...selectedClients,
        {
          uuid: clientId,
          name: clientName,
          amount: parseInt(event.target.value),
          freedom_plan_invested_amount: freedom_plan_invested_amount,
          invested_pools: invested_pools,
          wallet_balance: clientAvailableFunds,
          consent_status: consent_status,
          maturity_payout: maturity_payout,
          monthly_payout: monthly_payout,
        },
      ]);
    }
    let tempArray = clientData;
    tempArray.forEach((item) => {
      if (item.uuid === clientId) {
        item.amount = parseInt(event.target.value);
        item.maturity_payout = maturity_payout;
        item.monthly_payout = monthly_payout;
      }
    });
    setClientData(() => [...tempArray]);
    if (selectedClients.length == 0) {
      setOpenClientModel(false);
    }
  };

  // on send  payment link to investors
  const handleSubmit = async () => {
    setInvestLoader(true);
    var data = [];
    let invest_raised = true;
    for (let i = 0; i < selectedClients.length; i++) {
      const client = selectedClients[i];
      if (client.consent_status != 2 || client.wallet_balance < client.amount) {
        invest_raised = false;
      }
      //In case of growth plan extract plan details by tenure and type
      const tenure = param.state.pool.tenure;
      let selectedPlan;
      if (client.monthly_payout && param.state.pool.type != 1) {
        selectedPlan = poolsData.filter((item) => {
          return item.tenure == tenure && item.type == 2;
        });
      } else if (client.maturity_payout && param.state.pool.type != 1) {
        selectedPlan = poolsData.filter((item) => {
          return item.tenure == tenure && item.type == 0;
        });
      } else {
        selectedPlan = [param.state.pool];
      }
      selectedPlan = selectedPlan[0];
      let planMinInvestment = selectedPlan.minimum_investment
        ? Number(selectedPlan.minimum_investment)
        : 1000;
      let minimumInvestmentThreshold = planMinInvestment * 1;
      let investedAmount = 0;
      if (param.state.pool.type == 1) {
        investedAmount = client.freedom_plan_invested_amount
          ? client.freedom_plan_invested_amount
          : 0;
        minimumInvestmentThreshold = planMinInvestment - investedAmount;
      } else {
        if (client.invested_pools.length) {
          client.invested_pools.forEach((pool) => {
            if (pool.pool_template_uuid == selectedPlan.id) {
              investedAmount += pool.investment_amount;
            }
            minimumInvestmentThreshold = planMinInvestment - investedAmount;
          });
        }
      }
      if (client.amount < Number(minimumInvestmentThreshold)) {
        errorToast(
          `${client.name}'s investment cannot be less than ₹${minimumInvestmentThreshold}.`
        );
        setInvestLoader(false);
        return;
      } else if (client.amount % 1000 != 0) {
        errorToast(
          `${client.name}'s investment should be in multiple of ₹ 1000.`
        );
        setInvestLoader(false);
        return;
      }
      data.push({
        investment_amount: client.amount,
        pool_template_id: selectedPlan.id,
        investor_id: client.uuid,
      });
    }
    let inputs = {
      investments: data,
    };
    await api.sendPaymentLink(inputs).then((res) => {
      if (res.kind === "ok") {
        if (invest_raised == true) {
          successToast("Investment(s) successful");
          eventLog({
            type: "investment_success",
            action: {
              trigger: "true",
            },
          });
        } else {
          successToast("Investment request(s) sent successfully.");
          eventLog({
            type: "investment_mail_sent",
            action: {
              trigger: "true",
            },
          });
        }
        setTimeout(() => {
          setInvestLoader(false);
          handleClose();
          setOpenClientModel(false);
          changeInputValueToDefault();
          setSelectedClients([]);
        }, 3000);
      } else {
        setInvestLoader(false);
        errorToast(res.error);
      }
    });
    setInvestLoader(false);
  };

  const changeInputValueToDefault = () => {
    const tempArray = [];
    clientData.map((item, index) => {
      valueRef.current[index].current.value = "";
      tempArray.push({
        ...item,
        amount: "",
        monthly_payout:
          param.state.pool.type === 2
            ? true
            : param.state.pool.type != 1
            ? false
            : null,
        maturity_payout:
          param.state.pool.type === 0
            ? true
            : param.state.pool.type != 1
            ? false
            : null,
      });
    });
    setClientData(tempArray);
  };

  // if (hideIFA) {
  //   return <HideIFA />;
  // }
  // request search function
  const requestClientSearch = () => {
    setPage(0);
    const filter_rows = filterData.filter((row) => {
      return row.name
        .toLowerCase()
        .includes(searchByNameOrMobile.toLowerCase());
    });
    setClientData(filter_rows);
  };

  //handle payout type check
  // const handlePayoutCheck = (clientId) => {
  //   let tempArray = clientData;
  //   tempArray.forEach((item) => {
  //     if (item.uuid === clientId) {
  //       item.monthly_payout = !item.monthly_payout;
  //       item.maturity_payout = !item.maturity_payout;
  //     }
  //   });
  //   let tempClients = selectedClients;
  //   tempClients.forEach((item) => {
  //     if (item.uuid === clientId) {
  //       item.monthly_payout = !item.monthly_payout;
  //       item.maturity_payout = !item.maturity_payout;
  //     }
  //   });
  //   setSelectedClients(() => [...tempClients]);
  //   setClientData(() => [...tempArray]);
  // };

  return (
    <div>
      {loginRender === false ? (
        <div />
      ) : (
        <div className="poolclient_div_container">
          <div className="poolclient_div_tablecontainer">
            {/* header */}
            <div className="poolclient_header_div">
              <div className="poolclient_header_div_title_container">
                <div className="poolclient_header_button_div_container">
                  <h1
                    className="poolclient_header_div_subtitle"
                    onClick={() => navigate.push("/pools")}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    Investment Plan
                  </h1>
                  <ArrowForwardIosIcon
                    style={{
                      color: "#969494",
                      fontSize: "15px",
                      paddingLeft: "5px",
                      paddingRight: "5px",
                    }}
                  />
                  <h1 className="poolclient_header_div_subtitle_copy">
                    Select Clients
                  </h1>
                </div>

                <h1 className="poolclient_header_div_title">
                  {param.state.pool.pool_title}
                </h1>
                <br />
                <div className="poolclient_header_main_div">
                  <div className="poolclient_header_div_element">
                    <span style={{ color: "#757575" }}>
                      Minimum Investment :{" "}
                    </span>
                    <span>
                      ₹ {convertAmount(param.state.pool.minimum_investment)}
                    </span>
                    <span style={{ margin: "20px", color: "#757575" }}>|</span>
                  </div>
                  <div className="poolclient_header_div_element">
                    <span style={{ color: "#757575" }}>
                      Interest Rate p.a. :{" "}
                    </span>
                    <span>{parseFloat(param.state.pool.interest_rate).toFixed(2)} %</span>
                    <span style={{ margin: "20px", color: "#757575" }}>|</span>
                  </div>
                  <div className="poolclient_header_div_element">
                    <span style={{ color: "#757575" }}>Tenure : </span>
                    <span>
                      {param.state.pool?.pool_title?.toLowerCase() ==
                      "freedom plan"
                        ? "Withdraw anytime"
                        : param.state.pool.tenure == "undefined"
                        ? "NA"
                        : `${param.state.pool.tenure} ${
                            param.state.pool.tenure == 1 &&
                            param.state.pool.tenure_type === "Months"
                              ? "Month"
                              : param.state.pool.tenure_type
                          }`}
                    </span>
                    <span style={{ margin: "20px", color: "#757575" }}>|</span>
                  </div>
                  <div className="poolclient_header_div_element">
                    <span style={{ color: "#757575" }}>Selected Client : </span>
                    <span>{selectedClients.length}</span>
                  </div>
                </div>
              </div>
            </div>
            {/* search_bar */}
            <div className="clientlist_search_bar_div">
              <div className="clientlist_search_bar" style={{ width: "220px" }}>
                {/* icon */}
                <SearchIcon
                  style={{
                    fontSize: "20px",
                    paddingRight: "10px",
                    color: "#555555",
                  }}
                />
                {/* input box */}
                <input
                  type="text"
                  placeholder="Search by Name/Mobile Number"
                  value={searchByNameOrMobile}
                  className="clientlist_search_bar_input"
                  onChange={(event) => {
                    setSearchByNameOrMobile(event.target.value);
                  }}
                />
              </div>
            </div>
            {/* table */}
            {render === false ? (
              <ContentLoadingLoader />
            ) : (
              <div className="tableContainer">
                <TableContainer style={tableContainer}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left" style={tableHeader}>
                          Name
                        </TableCell>
                        <TableCell align="left" style={tableHeader}>
                          Mobile Number
                        </TableCell>
                        <TableCell align="left" style={tableHeader}>
                          Available Funds
                        </TableCell>
                        <TableCell align="left" style={tableHeader}>
                          Invested Amount
                        </TableCell>
                        {/* {param.state.pool.type == 0 ||
                        param.state.pool.type == 2 ? (
                          <TableCell
                            align="left"
                            style={{ ...tableHeader, width: "300px" }}
                          >
                            Payouts
                          </TableCell>
                        ) : null} */}
                        <TableCell
                          align="left"
                          style={{ ...tableHeader, width: "380px" }}
                        >
                          Amount to Invest
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody style={{ opacity: fetchingTableData ? 0.3 : 1 }}>
                      {clientData.map((row, index) => (
                        <TableRow
                          key={row.uuid}
                          style={{
                            backgroundColor:
                              index % 2 === 0 ? "#fafafa" : "#fff",
                          }}
                        >
                          <TableCell style={tableRow} align="left">
                            <p
                              className="clientlist_client_name"
                              style={{
                                color: "#1c1c1c",
                              }}
                            >
                              {row.name}
                            </p>
                            {row.consent_status != 0 && (
                              <p
                                className="clientlist_consent_status"
                                style={{
                                  color:
                                    row.consent_status == 1
                                      ? "#FF0000"
                                      : "#008000",
                                }}
                              >
                                {row.consent_status == 1
                                  ? "Consent Pending"
                                  : "Consent Received"}
                              </p>
                            )}
                          </TableCell>
                          <TableCell style={tableRow} align="left">
                            {row.mobile}
                          </TableCell>
                          <TableCell style={tableRow} align="left">
                            ₹ {convertAmount(row.withdrawable)}
                          </TableCell>
                          <TableCell style={tableRow} align="left">
                            <div className="clientlist_table_column_div">
                              <div>₹ {convertAmount(row.aum)}</div>
                              <div
                                style={{
                                  marginRight: "10%",
                                  color: "#3A86FF",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  if (
                                    row.kyc_status == 2 ||
                                    row.kyc_status == 1
                                  ) {
                                    navigate.push("/clients/investments", {
                                      name: row.name,
                                      uuid: row.uuid,
                                      net_worth: row.net_worth,
                                    });
                                    eventLog({
                                      type: "client_investments",
                                      action: {
                                        trigger: "true",
                                      },
                                    });
                                  } else {
                                    navigate.push("/clients/kyc", {
                                      uuid: row.uuid,
                                      disable: true,
                                    });
                                  }
                                }}
                              >
                                View
                              </div>
                            </div>
                          </TableCell>
                          {/* Select Payout */}
                          {/* {param.state.pool.type == 0 ||
                          param.state.pool.type == 2 ? (
                            <TableCell style={tableRow} align="left">
                              <Box sx={{ flexGrow: 1 }}>
                                <Grid container spacing={1}>
                                  <Grid item xs={4}>
                                    <div>
                                      <input
                                        type="radio"
                                        id="payout_type"
                                        value={row.monthly_payout}
                                        name={`Monthly_${index + 1}`}
                                        onChange={() => {
                                          handlePayoutCheck(
                                            row.uuid,
                                            "monthly_payout"
                                          );
                                        }}
                                        checked={row.monthly_payout}
                                        disabled={
                                          param.state.pool.type === 0 &&
                                          (param.state.pool.tenure === "1" ||
                                            param.state.pool.tenure === "3" ||
                                            param.state.pool.tenure === "6")
                                        }
                                      />
                                      <label className="payout_radio_label">
                                        {"Monthly"}
                                      </label>
                                    </div>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <div>
                                      <input
                                        type="radio"
                                        id="payout_type"
                                        value={row.maturity_payout}
                                        name={`At_Maturity${index + 1}`}
                                        onChange={() => {
                                          handlePayoutCheck(
                                            row.uuid,
                                            "maturity_payout"
                                          );
                                        }}
                                        checked={row.maturity_payout}
                                      />
                                      <label className="payout_radio_label">
                                        {"At Maturity"}
                                      </label>
                                    </div>
                                  </Grid>
                                </Grid>
                              </Box>
                            </TableCell>
                          ) : null} */}
                          <TableCell style={tableRow} align="left">
                            {/* &#x20B9; */}
                            <input
                              onWheel={() => document.activeElement.blur()}
                              type="number"
                              onKeyDown={handleKeyDown}
                              style={{
                                outline: "none",
                                color: checkIfSelected(row.uuid)
                                  ? ""
                                  : "darkgrey",
                              }}
                              placeholder={"₹ 00.00"}
                              value={row.amount ? row.amount : undefined}
                              step="1000.00"
                              min="00.00"
                              id={row.uuid}
                              ref={valueRef.current[index]}
                              // max={row.available_funds}
                              onChange={(e) => {
                                handleValueChange(
                                  row.uuid,
                                  row.name,
                                  row.available_funds,
                                  row.freedom_plan_invested_amount,
                                  row.invested_pools,
                                  row.consent_status,
                                  row.maturity_payout,
                                  row.monthly_payout,
                                  e
                                );
                              }}
                              className="amountInputField"
                            ></input>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  {/* if no data available */}
                  {clientData.length === 0 ? (
                    <div className="no_row_found_div">
                      <FindInPageIcon
                        style={{
                          fontSize: "100px",
                          color: "#555555",
                        }}
                      />
                      <p className="no_row_found">
                        {param.state.pool.type == 1
                          ? "No client found with approved kyc status"
                          : `No client found with minimum investable amount of ${param.state.pool.minimum_investment}.`}
                      </p>
                    </div>
                  ) : (
                    <div />
                  )}
                </TableContainer>
                {/* pagination */}
                <div className="pagination_div">
                  <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    count={totalClients}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                  />
                </div>
                <div className="client_list_container_button_div">
                  <button
                    disabled={selectedClients.length == 0 ? true : false}
                    style={{
                      background: selectedClients.length == 0 ? "darkgray" : "",
                    }}
                    className="bottom_container_button"
                    onClick={() => {
                      // errorToast("Please select atleast one client to proceed.");
                      for (let i = 0; i < selectedClients.length; i++) {
                        const client = selectedClients[i];
                        const tenure = param.state.pool.tenure;
                        let selectedPlan = param.state.pool;
                        let planMinInvestment = selectedPlan.minimum_investment
                          ? Number(selectedPlan.minimum_investment)
                          : 1000;
                        let minimumInvestmentThreshold = planMinInvestment * 1;
                        let investedAmount = 0;
                        if (param.state.pool.type == 1) {
                          investedAmount = client.freedom_plan_invested_amount
                            ? client.freedom_plan_invested_amount
                            : 0;
                          minimumInvestmentThreshold =
                            planMinInvestment - investedAmount;
                        } else {
                          if (client.invested_pools.length) {
                            client.invested_pools.forEach((pool) => {
                              if (pool.pool_template_uuid == selectedPlan.id) {
                                investedAmount += pool.investment_amount;
                              }
                              minimumInvestmentThreshold =
                                planMinInvestment - investedAmount;
                            });
                          }
                        }
                        if (
                          client.amount < Number(minimumInvestmentThreshold)
                        ) {
                          eventLog({
                            type: "review_and_invest",
                            action: {
                              trigger: "false",
                            },
                          });
                          errorToast(
                            `Suggested investment cannot be less than ₹ ${minimumInvestmentThreshold}.`
                          );
                          setInvestLoader(false);
                          return;
                        } else if (client.amount % 1000 != 0) {
                          eventLog({
                            type: "review_and_invest",
                            action: {
                              trigger: "false",
                            },
                          });
                          errorToast(
                            `${client.name}'s investment should be in multiple of ₹ 1000.`
                          );
                          setInvestLoader(false);
                          return;
                        } else if (
                          Number(client.wallet_balance) < Number(client.amount)
                        ) {
                          eventLog({
                            type: "review_and_invest",
                            action: {
                              trigger: "false",
                            },
                          });
                          infoToast(
                            `Suggested investments is less than the investors wallet balance. The investor will have to add funds first and then approve the investment request`
                          );
                        }
                      }
                      eventLog({
                        type: "review_and_invest",
                        action: {
                          trigger: "true",
                        },
                      });
                      setOpenClientModel(true);
                    }}
                  >
                    Review & Send Request
                  </button>
                </div>
              </div>
            )}
          </div>
          <PoolClientlistModel
            openClientModel={openClientModel}
            handleClose={handleClose}
            clientData={selectedClients}
            handleValueChange={handleValueChange}
            pool={param.state.pool}
            handleSubmit={handleSubmit}
            sortByName={sortByName}
            investLoader={investLoader}
          />
          {/* toast container */}
          <SampleToast />
        </div>
      )}
    </div>
  );
};

export default PoolClient;
