import React, { useEffect, useState } from "react";
import "./support.css";
import { useHistory, Redirect } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Auth } from "aws-amplify";
import { Support_API } from "../../services/api";
import SupportIcon from "../../assets/images/support.svg";
import { ContentLoadingLoader, SampleToast } from "../../components";
import { errorToast, successToast } from "../../utils/helper";
import Loader from "react-loader-spinner";
import { eventLog } from "../../utils/firebase";
import config, { support } from "../../config/settings";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import { set } from "mobx";
import { Phone } from "@mui/icons-material";
import { kycStatus_API } from "../../services/api/kycstatus/kycstatus-api";
// import HideIFA from "../HideIFA/hideIFA";

const Support = observer(function Support(props) {
  const urlParams = new URLSearchParams(window.location.search);
  const name = localStorage.getItem("name");
  const mobile = localStorage.getItem("mobile");
  const email = localStorage.getItem("email");
  const api = new Support_API();
  const navigate = useHistory();
  const [subject, setSubject] = useState(
    urlParams.get("subject") ? urlParams.get("subject") : ""
  );
  const [manual, setManual] = useState("");
  const [message, setMessage] = useState("");
  const [render, setRender] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isLogin, setIsLogin] = useState();
  const [loginRender, setLoginRender] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [dropped, setDropped] = useState(false);
  // const [hideIFA, setHideIFA] = useState(false);

  // function handleWindowSizeChange() {
  //   if (window.innerWidth <= 1080 || window.innerHeight <= 600) {
  //     setHideIFA(true);
  //     props.showCommonHeader(false);
  //     props.showCommonSidebar(false);
  //   } else {
  //     setHideIFA(false);
  //     props.showCommonHeader(true);
  //     props.showCommonSidebar(true);
  //   }
  // }

  // useEffect(() => {
  //   window.addEventListener("resize", handleWindowSizeChange);
  //   if (window.innerWidth <= 1080 || window.innerHeight <= 600) {
  //     setHideIFA(true);
  //     props.showCommonHeader(false);
  //     props.showCommonSidebar(false);
  //   }
  //   return () => {
  //     window.removeEventListener("resize", handleWindowSizeChange);
  //   };
  // }, []);

  // check if user login or not
  useEffect(() => {
    props.showCommonHeader(true);
    //props.showCommonSidebar(true);
    let checkLogin = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        let status = localStorage.getItem("kyc_status");
        if (status != 2) {
          setIsLogin(false);
        }
        else
        {
        fetchIfakycStatus();
        }
        // setIsLogin(true);
        // setLoginRender(true);
        setTimeout(() => {
          setRender(true);
        }, 500);
      } catch {
        setIsLogin(false);
              errorToast(
          "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
        );
      }
    };
    return checkLogin();
    // eslint-disable-next-line
  }, []);

  // fetch ifa kyc status
  const fetchIfakycStatus = () => {
    setLoader(true);
    // const api = new kycStatus_API();

    // fetch data
    // api
    //   .getkycStatusData()
    //   .then((res) => {
    //     if (res.kind === "ok") {
    //       props.showCommonSidebar(true);
    //       setLoader(false);
    //       setIsLogin(true);
    //       setLoginRender(true);
    //     } else {
    //       setLoader(false);
    //       navigate.push("/");
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     setLoader(false);
    //     errorToast(
    //       "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
    //     );
    //   });

    props.showCommonSidebar(true);
    setLoader(false);
    setIsLogin(true);
    setLoginRender(true);
  };
  // handle subject change
  const handleChange = (e) => {
    setSubject(e.target.value);
  };

  const handleManual = (e) => {
    setManual(e.target.value);
  };
  // handle message change
  const handleChangeMessage = (e) => {
    setMessage(e.target.value);
  };

  // handle submit
  const onSubmit = async () => {
    if (
      subject === "" ||
      message === "" ||
      subject.trim() === "" ||
      message.trim() === ""
    ) {
      eventLog({
        type: "support_submit_cta",
        action: {
          trigger: "true",
        },
      });
      errorToast("Please fill out the required fields.");
    } else if (subject === "Other" && manual === "") {
      eventLog({
        type: "support_submit_cta",
        action: {
          trigger: "true",
        },
      });
      errorToast("Please type a subject.");
    } else {
      setLoader(true);

      // payload data
      let payload_data = {
        name: name,
        email: email,
        mobile: mobile,
        subject: manual == "" ? subject : manual,
        message: message,
        from: "IFA",
      };

      // call the api
      await api.updateSupportRequest(payload_data).then((res) => {
        if (res.kind === "ok") {
          setTimeout(() => {
            successToast("Successfully submitted.");
            setSubject("");
            setMessage("");
            setLoader(false);
            eventLog({
              type: "support_submit_cta",
              action: {
                trigger: "true",
              },
            });
          }, 3000);
        } else {
          errorToast(res.error);
          setSubject("Select your subject");
          setMessage("");
          setLoader(false);
          eventLog({
            type: "support_submit_cta",
            action: {
              trigger: "false",
            },
          });
        }
      });
    }
  };

  // if user is not login then redirect to login page
  if (isLogin === false) {
    return <Redirect to="/" />;
  }

  // if (hideIFA) {
  //   return <HideIFA />;
  // }
  const menuItem = {
    fontFamily: "Asap",
    fontSize: "14px",
  };

  return (
    <div>
      {loginRender === false ? (
        <div />
      ) : (
        <div className="support_div_container">
          {/* pool display div */}
          {render === false ? (
            <ContentLoadingLoader />
          ) : (
            <div className="support_main_div">
              {/* header */}
              <div className="support_header">
                {/* image */}
                <img
                  src={SupportIcon}
                  alt="support_icon"
                  className="support_image"
                />
              </div>

              {/* form div */}
              <div className="support_form_div">
                {/* form main container */}
                <div
                  className="support_form_container"
                  style={{ overflow: "hidden" }}
                >
                  {/* title */}
                  <div className="support_form_container_div">
                    <p className="support_form_container_title">Tell us more</p>
                  </div>
                  {/* subject */}
                  <div className="support_subject">
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth sx={{ borderColor: "#494949" }}>
                        <InputLabel
                          id="demo-simple-select-label"
                          style={{
                            fontFamily: "Asap",
                            fontSize: "14px",
                            color: "#838383",
                          }}
                        >
                          Select subject *
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={subject}
                          label="Select subject"
                          onChange={handleChange}
                          style={{
                            fontFamily: "Asap",
                            fontSize: "14px",
                          }}
                        >
                          <MenuItem
                            style={menuItem}
                            value={"Investor onboarding"}
                          >
                            Investor onboarding
                          </MenuItem>
                          <MenuItem
                            style={menuItem}
                            value={"Investor investment issues"}
                          >
                            Investor investment issues
                          </MenuItem>
                          <MenuItem
                            style={menuItem}
                            value={"Investor withdrawal issues"}
                          >
                            Investor withdrawal issues
                          </MenuItem>
                          {/* <MenuItem style={menuItem} value={"Business Support"}>
                            Business Support
                          </MenuItem> */}
                          <MenuItem
                            style={menuItem}
                            value={"Support/Report an issue"}
                          >
                            Support/Report an issue
                          </MenuItem>
                          <MenuItem
                            style={menuItem}
                            value={"Request a feature"}
                          >
                            Request a feature
                          </MenuItem>
                          <MenuItem
                            style={menuItem}
                            value={"Commission Related"}
                          >
                            Commission Related
                          </MenuItem>
                          {/* <MenuItem style={menuItem} value={"Report Bug"}>
                            Report Bug
                          </MenuItem> */}
                          <MenuItem style={menuItem} value={"Others"}>
                            Others
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </div>
                  <textarea
                    style={{
                      display: subject == "Other" ? "flex" : "none",
                      marginTop: "5px",
                    }}
                    type="text"
                    placeholder="Subject *"
                    className="support_input_box_subject"
                    onChange={handleManual}
                  />

                  {/* message */}
                  <textarea
                    type="text"
                    placeholder="Type your message *"
                    className="support_input_box_message"
                    value={message}
                    onChange={handleChangeMessage}
                  />

                  {/* submit */}
                  <button
                    className="support_submit_container"
                    onClick={onSubmit}
                  >
                    {loader ? (
                      <Loader
                        type="TailSpin"
                        color="#fff"
                        height={15}
                        width={15}
                      />
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </div>
              <div className="contact_box" style={{ overflow: "hidden" }}>
                <div className="support_form_container_div">
                  <p
                    style={{ marginLeft: "20px" }}
                    className="support_form_container_title"
                  >
                    Contact us
                  </p>
                </div>
                <div className="details">
                  <span className="phone_icon" style={{ marginLeft: "20px" }}>
                    <PhoneIcon style={{ marginRight: "8px" }} />
                    {config.support.mobile}
                  </span>
                  <span className="email_icon" style={{ marginLeft: "20px" }}>
                    <EmailIcon style={{ marginRight: "8px" }} />
                    {config.support.email}
                  </span>
                </div>
              </div>
            </div>
          )}
          {/* toast container */}
          <SampleToast />
        </div>
      )}
    </div>
  );
});

export default Support;
