import { API } from "aws-amplify";
import * as Types from "../api.types";

/**
 * Manages all requests to the API.
 */

export class Profile_API {
  /**
   * @description This function is for converting raw data into the actual data
   * @param raw
   * @returns it return the actual data that can be displayed
   */

  async getIFAProfileData(): Promise<Types.GetIfaProfileData> {
    //call the api try and catch
    try {
      const response: any = await API.get("baseurl", "/ifa/profile", {});
      return { kind: "ok", data: response.data };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description this function is for sending email verification link
   * @param data
   * @returns success or faliure
   */

  async sendEmailVerification(data: any): Promise<Types.GetIfaProfileData> {
    // payload
    const params = {
      body: data,
    };
    //call the api try and catch
    try {
      const response: any = await API.post(
        "baseurl",
        "/users/request/email",
        params
      );
      return { kind: "ok", data: response.data };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }
  /**
   * @description This function is for converting raw data into the actual data
   * @param raw
   * @returns it return the actual data that can be displayed
   */
  async getIfaCommissionsData(data: any): Promise<Types.GetIfaProfileData> {
    // payload
    const params = {
      body: data,
    };
    //call the api try and catch
    try {
      const response: any = await API.post(
        "baseurl",
        "/ifa/commissions",
        params
      );
      return { kind: "ok", data: response.data };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description This function is for get invoice
   * @param raw
   * @returns it return the actual data that can be displayed
   */
  async getIFACommissionPdf(data: any): Promise<Types.GetIfaProfileData> {
    // payload
    const params = {
      body: data,
    };
    //call the api try and catch
    try {
      const response: any = await API.post("baseurl", "/ifa/invoices", params);
      return { kind: "ok", data: response.data };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description This function is for get invoice
   * @param raw
   * @returns it return the actual data that can be displayed
   */
  async uploadIFACommissionPdf(data: any): Promise<Types.GetIfaProfileData> {
    // payload
    const params = {
      body: data,
    };
    //call the api try and catch
    try {
      const response: any = await API.post(
        "baseurl",
        "/ifa/invoices/upload",
        params
      );
      return { kind: "ok", data: response.data };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }
}
