import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import Switch from "@mui/material/Switch";
import {
  convertIntoDefaultState,
  convertIntoErrorState,
  errorToast,
  infoToast,
  successToast,
  checkValidPan,
  yearsBefore,
  checkForAddressType,
  getAge,
  getCurrentAddressDocuments,
  getPermanentAddressDocuments,
} from "../../utils/helper";
import {
  InputBox,
  InputBoxV2,
  UploadInputV2,
  UploadInputV3,
  StateDropdown,
} from "../../components/index.js";
import config from "../../config/settings";
import { authoriserKycDetailsState } from "../../constant/clientKycState";
import Checkbox from "@mui/material/Checkbox";
import "./clientkyc.css";
import { KYC_LINK_API_V2 } from "../../services/api";
import moment from "moment";

let maxRetries = 2;

const EntityKycDetails = (props) => {
  const [entityKycDetails, setEntityKycDetails] = useState(
    convertIntoDefaultState(authoriserKycDetailsState)
  );
  const [entityKycDetailsError, setEntityKycDetailsError] = useState(
    convertIntoErrorState(authoriserKycDetailsState)
  );

  const [currentDocumentsToRender, setCurrentDocumentsToRender] = useState([]);

  const [permanentDocumentsToRender, setPermanentDocumentsToRender] = useState(
    []
  );

  const [panVerifiedSuccessful, setPanVerifiedSuccessful] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [maxDate, setMaxDate] = useState("");
  const [ctaLoader, setCtaLoader] = useState(false);
  const [secondPan, setSecondPan] = useState(false);
  const [document, setDocument] = useState({
    document_type: null,
    document_front: null,
    document_back: null,
  });
  const api = new KYC_LINK_API_V2();
  useEffect(() => {
    const { sDate, mDate } = yearsBefore(100);
    setStartDate(sDate);
    setMaxDate(mDate);

    //gets the address field to render
    setCurrentDocumentsToRender(
      getCurrentAddressDocuments(authoriserKycDetailsState)
    );

    //get the permanent field to render
    setPermanentDocumentsToRender(
      getPermanentAddressDocuments(authoriserKycDetailsState)
    );
  }, []);

  useEffect(() => {
    if (props.investorType === "sole_proprietorship") {
      setPanVerifiedSuccessful(true);
      setEntityKycDetails({
        ...entityKycDetails,
        authoriser_pan_name: props.basicKycDetails["pan_name"],
        authoriser_dob: props.basicKycDetails["dob"],
      });
    }
  }, []);
  useEffect(() => {
    //update kyc details for parent
    props.setEntityKycDetails({
      ...entityKycDetails,
      authoriser_address_type: checkForAddressType(document.document_type),
      authoriser_address_front_image: document.document_front,
      authoriser_address_back_image: document.document_back,
    });
    //remove error
    props.setErrorState({
      ...entityKycDetailsError,
    });
  }, [entityKycDetails, document]);

  //check for error
  useEffect(() => {
    if (props.errorState && props.errorState != null) {
      setEntityKycDetailsError({
        ...entityKycDetailsError,
        ...props.errorState,
      });
    }
  }, [props]);

  //array for address types
  const addressProofArray = ["Aadhaar Card", "Passport", "Driving License"];

  const verifyPAN = async () => {
    // check for pan validation
    setCtaLoader(true);
    if (!checkValidPan(entityKycDetails["authoriser_pan"])) {
      setEntityKycDetailsError({
        ...entityKycDetailsError,
        authoriser_pan: true,
      });
      setCtaLoader(false);
      return;
    }
    if (!entityKycDetails["is_authoriser_pan_consent_accepted"]) {
      errorToast("Please provide consent in order to move ahead.", true);
      setCtaLoader(false);
      return;
    }
    if (!entityKycDetails["authoriser_pan_name"]) {
      errorToast("Please provide Pan Name in order to move ahead.", true);
      setCtaLoader(false);
      return;
    }
    if (!entityKycDetails["authoriser_dob"]) {
      errorToast("Please provide Date of Birth in order to move ahead.", true);
      setCtaLoader(false);
      return;
    }
    // payload
    let payload = {
      pan: String(entityKycDetails["authoriser_pan"]).toUpperCase(),
      pan_name: String(entityKycDetails["authoriser_pan_name"]).toUpperCase(),
      dob: moment(entityKycDetails["authoriser_dob"]).format("DD-MM-YYYY"),
      is_secondary: true,
    };

    // call the pan check api
    await api.checkPanNumber(payload).then((res) => {
      if (res.kind == "ok") {
        const data = res.data;
        if (data.message == "PAN verified successfully") {
          successToast(data.message);
          setPanVerifiedSuccessful(true);
          setEntityKycDetails({
            ...entityKycDetails,
            authoriser_pan_res: data.pan_res,
            authoriser_pan_name: data.pan_name,
            is_authoriser_pan_consent_accepted:
              entityKycDetails["is_authoriser_pan_consent_accepted"],
          });
          setSecondPan(true);
          setCtaLoader(false);
        } else {
          if (maxRetries == 0) {
            props.setPanValidationFailed(true);
            return;
          } else {
            maxRetries = maxRetries - 1;
            errorToast(data.message, true);
            setCtaLoader(false);
          }
        }
      } else {
        if (maxRetries == 0) {
          props.setPanValidationFailed(true);
          return;
        } else {
          maxRetries = maxRetries - 1;
          errorToast(res.error, true);
          setCtaLoader(false);
        }
      }
    });
  };

  //handle toggle switch
  const handleSwitchChange = (e) => {
    setEntityKycDetails((prevState) => ({
      ...prevState,
      authoriser_current_address_flag: e.target.checked,
    }));
  };
  
  return (
    <div>
      {/* pan number */}
      <InputBoxV2
        label={"PAN"}
        id="authoriser_pan"
        type={authoriserKycDetailsState["authoriser_pan"].type}
        disabled={panVerifiedSuccessful}
        maxLength="10"
        autoCapitalize={true}
        value={entityKycDetails["authoriser_pan"]}
        setValue={setEntityKycDetails}
        object={entityKycDetails}
        required={authoriserKycDetailsState["authoriser_pan"]["required"]}
        errorObject={entityKycDetailsError}
        setErrorObject={setEntityKycDetailsError}
        isError={entityKycDetailsError["authoriser_pan"]}
        errorText={
          !entityKycDetails["authoriser_pan"]
            ? "This field cannot be empty."
            : "Invalid PAN. Please try again."
        }
        visibility={props.investorType !== "sole_proprietorship"}
      />
       {/* pan name */}
       <InputBoxV2
        label={"Name as per PAN"}
        id="authoriser_pan_name"
        type={authoriserKycDetailsState["authoriser_pan_name"].type}
        disabled={panVerifiedSuccessful}
        //autoCapitalize={true}
        value={entityKycDetails["authoriser_pan_name"]}
        setValue={setEntityKycDetails}
        object={entityKycDetails}
        required={authoriserKycDetailsState["authoriser_pan_name"]["required"]}
        errorObject={entityKycDetailsError}
        setErrorObject={setEntityKycDetailsError}
        isError={entityKycDetailsError["authoriser_pan_name"]}
        visibility={props.investorType !== "sole_proprietorship"}
        errorText={
          !entityKycDetails["authoriser_pan_name"]
            ? "This field cannot be empty."
            : "Invalid PAN. Please try again."
        }
      />

      {/* Date of Birth. */}
      <InputBoxV2
        label={"Date of Birth"}
        id="authoriser_dob"
        type={authoriserKycDetailsState["authoriser_dob"].type}
        disabled={panVerifiedSuccessful}
        maxLength="10"
        autoCapitalize={true}
        value={entityKycDetails["authoriser_dob"]}
        setValue={setEntityKycDetails}
        object={entityKycDetails}
        required={authoriserKycDetailsState["authoriser_dob"]["required"]}
        errorObject={entityKycDetailsError}
        setErrorObject={setEntityKycDetailsError}
        visibility={props.investorType !== "sole_proprietorship"}
        isError={entityKycDetailsError["authoriser_dob"]} //props.errorState.hasOwnProperty("authoriser_dob")?props.errorState.authoriser_dob:
        errorText={
          !entityKycDetails["authoriser_dob"]
            ? "This field cannot be empty."
            : getAge(entityKycDetails["authoriser_dob"]) > 18
            ? "Invalid Date of Birth."
            : "Age must exceed 18 years."
        }
        max={maxDate}
        min={startDate}
      />
      <div
        className="add_cientkycv2_pan_consent_checkbox"
        style={{ display: !panVerifiedSuccessful ? "flex" : "none" }}
      >
        <Checkbox
          checked={entityKycDetails["is_authoriser_pan_consent_accepted"]}
          onChange={() =>
            setEntityKycDetails({
              ...entityKycDetails,
              is_authoriser_pan_consent_accepted:
                !entityKycDetails["is_authoriser_pan_consent_accepted"],
            })
          }
        />

        <div className="add_cientkycv2_pan_consent_checkbox_condition">
          I/we have specific consent from the Investor for onboarding with
          1Invest and to retrieve their PAN details from NSDL and their KYC from
          CKYC Registry for the purpose of validation and verification.
        </div>
      </div>
      {/* verify pan */}
      {/* cta */}
      <div
        className="entity_pan_cta_container"
        style={{ display: !panVerifiedSuccessful ? "flex" : "none" }}
      >
        <div className="entity_pan_cta" onClick={verifyPAN}>
          {ctaLoader ? (
            <Loader type="TailSpin" color="#FFFFFF" height={25} width={25} />
          ) : (
            <p className="entity_pan_cta_text">Verify</p>
          )}
        </div>
      </div>
      {/* pan name */}
      <InputBoxV2
        label={"Name as per PAN"}
        id="authoriser_pan_name"
        type={authoriserKycDetailsState["authoriser_pan_name"].type}
        disabled={true}
        value={entityKycDetails["authoriser_pan_name"]}
        setValue={setEntityKycDetails}
        object={entityKycDetails}
        required={authoriserKycDetailsState["authoriser_pan_name"]["required"]}
        errorObject={entityKycDetailsError}
        setErrorObject={setEntityKycDetailsError}
        isError={entityKycDetailsError["authoriser_pan_name"]}
        visibility={panVerifiedSuccessful && !secondPan}
        errorText={
          !entityKycDetails["authoriser_pan_name"]
            ? "This field cannot be empty."
            : "Invalid PAN. Please try again."
        }
      />

      {/* Date of Birth. */}
      <InputBoxV2
        label={"Date of Birth"}
        id="authoriser_dob"
        type={authoriserKycDetailsState["authoriser_dob"].type}
        disabled={true}
        maxLength="10"
        autoCapitalize={true}
        value={entityKycDetails["authoriser_dob"]}
        setValue={setEntityKycDetails}
        object={entityKycDetails}
        required={authoriserKycDetailsState["authoriser_dob"]["required"]}
        errorObject={entityKycDetailsError}
        setErrorObject={setEntityKycDetailsError}
        visibility={panVerifiedSuccessful && !secondPan}
        isError={entityKycDetailsError["authoriser_dob"]} //props.errorState.hasOwnProperty("authoriser_dob")?props.errorState.authoriser_dob:
        errorText={
          !entityKycDetails["authoriser_dob"]
            ? "This field cannot be empty."
            : getAge(entityKycDetails["authoriser_dob"]) > 18
            ? "Invalid Date of Birth."
            : "Age must exceed 18 years."
        }
        max={maxDate}
        min={startDate}
      />
      <div className="add_client_investor_type">
        <label>
          Gender<span style={{ color: "red" }}> *</span>
        </label>

        <div className="add_client_link_gender_type">
          <label id="male" style={{ marginRight: "10%" }}>
            <input
              id="male"
              type="radio"
              name="Gender"
              value={"male"}
              className="radioClass"
              onChange={(e) => {
                setEntityKycDetails({
                  ...entityKycDetails,
                  authoriser_gender: e.target.value,
                });
                setEntityKycDetailsError({
                  ...entityKycDetailsError,
                  authoriser_gender: false,
                });
              }}
            />
            Male
          </label>

          <label id="female" style={{ marginRight: "10%" }}>
            <input
              id="female"
              type="radio"
              name="Gender"
              value={"female"}
              className="radioClass"
              onChange={(e) => {
                setEntityKycDetails({
                  ...entityKycDetails,
                  authoriser_gender: e.target.value,
                });
                setEntityKycDetailsError({
                  ...entityKycDetailsError,
                  authoriser_gender: false,
                });
              }}
            />
            Female
          </label>

          <label id="others">
            <input
              id="others"
              type="radio"
              name="Gender"
              value={"others"}
              className="radioClass"
              onChange={(e) => {
                setEntityKycDetails({
                  ...entityKycDetails,
                  authoriser_gender: e.target.value,
                });
                setEntityKycDetailsError({
                  ...entityKycDetailsError,
                  authoriser_gender: false,
                });
              }}
            />
            Others
          </label>
        </div>
        <div>
          <p
            className="input_box_v2_invalid_text"
            style={{
              opacity: entityKycDetailsError.authoriser_gender ? 1 : 0,
              marginTop: "2px",
            }}
          >
            {entityKycDetailsError.authoriser_gender
              ? "Please select appropriate gender."
              : ""}
          </p>
        </div>
      </div>
      <UploadInputV2
        id="authoriser_selfie"
        label={"Selfie"}
        object={entityKycDetails}
        type={authoriserKycDetailsState["authoriser_selfie"].type}
        value={authoriserKycDetailsState["authoriser_selfie"]}
        setValue={setEntityKycDetails}
        imageSizeLimit={15} //required if note is provided
        required={authoriserKycDetailsState["authoriser_selfie"]["required"]}
        note={
          "Upload image in JPG/JPEG/PNG format. Max. file size allowed is 15 MB."
        }
        errorObject={entityKycDetailsError}
        setErrorObject={setEntityKycDetailsError}
        isError={entityKycDetailsError["authoriser_selfie"]}
        requiredFileTypes={["image/jpeg", "image/png", "image/jpg"]}
        acceptedFormat=".jpg, .jpeg, .png"
      />
      {/* address */}
      {/* <InputBoxV2
        label={"Address"}
        id="authoriser_address"
        type={authoriserKycDetailsState["authoriser_address"].type}
        //disabled={true}
        maxLength="255"
        // autoCapitalize={true}
        value={entityKycDetails["authoriser_address"]}
        setValue={setEntityKycDetails}
        object={entityKycDetails}
        required={authoriserKycDetailsState["authoriser_address"]["required"]}
        errorObject={entityKycDetailsError}
        setErrorObject={setEntityKycDetailsError}
        isError={entityKycDetailsError["authoriser_address"]}
        errorText={
          entityKycDetails["authoriser_address"]
            ? "Invalid Address"
            : "This field cannot be empty."
        }
      /> */}

      <h4 id="add_client_proprietor_detail_subtitle">Current Address</h4>

      {currentDocumentsToRender.map((key, index) => {
        if (key.includes("state")) {
          return (
            <StateDropdown
              label={authoriserKycDetailsState[key].label}
              id={key}
              type={authoriserKycDetailsState[key].type}
              dataArray={config.state_array}
              maxLength="255"
              value={entityKycDetails[key]}
              setValue={setEntityKycDetails}
              object={entityKycDetails}
              specialCharCheck={
                authoriserKycDetailsState[key].hasOwnProperty(
                  "specialCharCheck"
                )
                  ? authoriserKycDetailsState[key].specialCharCheck
                  : false
              }
              required={authoriserKycDetailsState[key]["required"]}
              errorObject={entityKycDetailsError}
              setErrorObject={setEntityKycDetailsError}
              isError={entityKycDetailsError[key]} //props.errorState.hasOwnProperty("address")?props.errorState.address:
              errorText={
                entityKycDetails[key]
                  ? "Invalid State"
                  : "This field cannot be empty."
              }
            />
          );
        } else {
          return (
            <InputBoxV2
              key={key}
              label={authoriserKycDetailsState[key].label}
              id={key}
              type={authoriserKycDetailsState[key].type}
              maxLength={key.includes("pin") ? "6" : "255"}
              value={entityKycDetails[key]}
              setValue={setEntityKycDetails}
              object={entityKycDetails}
              specialCharCheck={
                authoriserKycDetailsState[key].hasOwnProperty(
                  "specialCharCheck"
                )
                  ? authoriserKycDetailsState[key].specialCharCheck
                  : false
              }
              required={authoriserKycDetailsState[key]["required"]}
              errorObject={entityKycDetailsError}
              setErrorObject={setEntityKycDetailsError}
              isError={entityKycDetailsError[key]}
              errorText={
                entityKycDetails[key]
                  ? `Invalid ${key.replace(/^[^_]+_/, "")}`
                  : "This field cannot be empty."
              }
            />
          );
        }
      })}

      {/* dropdown document upload */}
      <UploadInputV3
        id="authoriser_address_type"
        label={"Current Address Proof"}
        dropDownArray={addressProofArray}
        object={document}
        value={document}
        setValue={setDocument}
        imageSizeLimit={15} //required if note is provided
        labelForDocumentFront={"Front Side"}
        labelForDocumentBack={"Back Side"}
        idForDocumentFront={"authoriser_address_front_image"}
        idForDocumentBack={"authoriser_address_back_image"}
        note={
          "Upload image in JPG/JPEG/PNG/PDF format. Max. file size allowed is 15 MB."
        }
        errorObject={entityKycDetailsError}
        setErrorObject={setEntityKycDetailsError}
        isError={entityKycDetailsError["authoriser_address_type"]}
      />

      <div className="address_toggle">
        {/* premament details */}
        <p id="address_toggle_label">
          Permanent address is the same as current address
        </p>

        {/* props.isGstRequired */}
        <Switch
          checked={entityKycDetails["authoriser_current_address_flag"]}
          onChange={handleSwitchChange}
          inputProps={{ "aria-label": "controlled" }}
          sx={{
            "& .MuiSwitch-thumb": {
              color: entityKycDetails["authoriser_current_address_flag"]
                ? "#3a86ff"
                : "#FFFFFF",
            },
          }}
        />
      </div>

      {!authoriserKycDetailsState["authoriser_current_address_flag"] && (
        <h4 id="add_client_proprietor_detail_subtitle">Permanent Address</h4>
      )}

      {!entityKycDetails["authoriser_current_address_flag"] &&
        permanentDocumentsToRender.map((key, index) => {
          if (key.includes("state")) {
            return (
              <StateDropdown
                label={authoriserKycDetailsState[key].label}
                id={key}
                type={authoriserKycDetailsState[key].type}
                dataArray={config.state_array}
                maxLength="255"
                value={entityKycDetails[key]}
                setValue={setEntityKycDetails}
                specialCharCheck={
                  authoriserKycDetailsState[key].hasOwnProperty(
                    "specialCharCheck"
                  )
                    ? authoriserKycDetailsState[key].specialCharCheck
                    : false
                }
                object={entityKycDetails}
                required={authoriserKycDetailsState[key]["requiredCheck"]}
                errorObject={entityKycDetailsError}
                setErrorObject={setEntityKycDetailsError}
                isError={entityKycDetailsError[key]} //props.errorState.hasOwnProperty("address")?props.errorState.address:
                errorText={
                  entityKycDetails[key]
                    ? "Invalid State"
                    : "This field cannot be empty."
                }
              />
            );
          } else {
            return (
              <InputBoxV2
                key={key}
                label={authoriserKycDetailsState[key].label}
                id={key}
                type={authoriserKycDetailsState[key].type}
                maxLength={key.includes("pin") ? "6" : "355"}
                value={entityKycDetails[key]}
                setValue={setEntityKycDetails}
                object={entityKycDetails}
                specialCharCheck={
                  authoriserKycDetailsState[key].hasOwnProperty(
                    "specialCharCheck"
                  )
                    ? authoriserKycDetailsState[key].specialCharCheck
                    : false
                }
                required={authoriserKycDetailsState[key]["requiredCheck"]}
                errorObject={entityKycDetailsError}
                setErrorObject={setEntityKycDetailsError}
                isError={entityKycDetailsError[key]}
                errorText={
                  entityKycDetails[key]
                    ? `Invalid ${key.replace(/^[^_]+_/, "")}`
                    : "This field cannot be empty."
                }
              />
            );
          }
        })}
    </div>
  );
};

export default EntityKycDetails;
