import { API } from "aws-amplify";
import * as Types from "../api.types";

/**
 * Manages all requests to the API.
 */
export class Report_API {
  /**
   * @description This function is to record report request
   * @returns it returns success and faliure
   */
  async recordReportRequest(
    data: any
  ): Promise<Types.RecordReportRequestResult> {
    // set param
    let params = {
      body: data,
    };

    //call the api try and catch
    try {
      const response: any = await API.post(
        "baseurl",
        "/report-requests",
        params
      );
      return { kind: "ok", data: response.data };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error: "Error while recording report request.",
          };
        } else {
          if (err.response.data.code === 422) {
            return {
              kind: "bad-data",
              error: err.response.data.errors,
            };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }



  async annualIncomeReportRequest(
    data: any
  ): Promise<Types.AnnualIncomeReportRequestResult> {
    // set param
    let params = {
      body: data,
    };

    //call the api try and catch
    try {
      const response: any = await API.post(
        "baseurl",
        `/ifa/clients/income-requests`,
        params
      );
      return { kind: "ok", data: response.data };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error: "Error while fetching annual income request.",
          };
        } else {
          if (err.response.data.code === 422) {
            return {
              kind: "bad-data",
              error: err.response.data.errors,
            };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }
}
