import React, { useEffect } from "react";
import "../NetworkErrorScreen/networkerror.css";
import { useNetwork } from "../../components";
import config from "../../config/settings";

const NetworkErrorScreen = function (props) {
  const connection = useNetwork();
  useEffect(() => {
    props.setIsNetworkDown(true);
  }, []);
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <div style={{ textAlign: "center" }}>
        <img
          src={config.network_error_base_64}
          alt="network error image"
          style={{ display: "block", margin: "auto" }}
        />
        <div style={{ marginTop: "6%" }}>
          <p className="title">Network issue detected.</p>
        </div>
        <div>
          <p className="subtitle">
            Please ensure a stable internet connection to continue.
          </p>
        </div>
        <div style={{ marginTop: "8%" }}>
          <button
            style={{
              backgroundColor: "#3A86FF",
              color: "#FFFFFF",
              width: "40%",
              border: "none",
              padding: "3%",
              cursor: "pointer",
            }}
            onClick={() => {
              if (connection) {
                props.setIsNetworkDown(false);
                window.location.reload();
              }
              window.location.reload();
            }}
          >
            Refresh Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default NetworkErrorScreen;
