import "./consent-request-model.css";
import Modal from "@mui/material/Modal";
import Loader from "react-loader-spinner";
import CloseIcon from "@mui/icons-material/Close";

export default function ConsentRequestModel(props) {
  return (
    <Modal
      open={props.openModel}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{
        backgroundColor: "rgba(102, 102, 102, 0.72)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className="consent_request_model_div">
        <div className="consent_request_model_header">
          <div style={{ float: "left" }}>
            <h1 className="consent_request_model_header_title">
              Send Consent Request to Investor
            </h1>
          </div>
          <div
            style={{ float: "right", cursor: "pointer" }}
            onClick={props.handleClose}
          >
            <h1 className="consent_request_model_header_title">
              <CloseIcon />
            </h1>
          </div>
        </div>
        <div className="consent_request_model_body">
          <div>
            An email will be sent to the investor to provide consent to you for
            the following:
          </div>
          <div>
            <ul style={{ marginTop: "20px" }}>
              {props.soaConsent !== 2 ? (
                <li>
                  To access any reports, certificates or statements linked to
                  their investments on 1Invest.
                </li>
              ) : null}
              {props.investmentConsent !== 2 ? (
                <li>
                  To act on their behalf for selecting the 1Invest plan and
                  investing funds from their escrow account maintained with RNVP
                  Technology Private Limited. This authorization is revocable at
                  any time, for any reason.
                </li>
              ) : null}
            </ul>
          </div>
          {/* send button */}
          <div style={{ textAlign: "center" }}>
            <button
              className="consent_request_model_button"
              onClick={() => props.onProceed()}
              disabled={props.amountInvalid}
              style={{
                backgroundColor: props.amountInvalid ? "gray" : "#3A86FF",
              }}
            >
              {props.loading ? (
                <Loader type="TailSpin" color="#fff" height={15} width={15} />
              ) : (
                "Send Request"
              )}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
