import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import "./login.css";
import { Auth } from "aws-amplify";
import Loader from "react-loader-spinner";
import { useStores } from "../../../models";
import InfoIcon from "@mui/icons-material/Info";
import { errorToast, infoToast, successToast } from "../../../utils/helper";
import { SampleToast } from "../../../components";
import { eventLog } from "../../../utils/firebase";
import { IFA_KYC_API, User_API } from "../../../services/api";
import config from "../../../config/settings";

// inputs fields component
const Field = React.forwardRef(
  (
    {
      label,
      type,
      handelChange,
      disabled,
      mobileInvalid,
      mobileInvalidText,
      otpInvalid,
      otpInvalidText,
      changeClick,
      onSendOTP,
      resendOtpCount,
      secondLeft,
    },
    ref
  ) => {
    // prevent key press only numbers
    const onKeyPress = (e) => {
      const re = /[^0-9]/g;
      if (re.test(e.key)) {
        e.preventDefault();
      }
    };

    return (
      <div
        className="login_field_container"
        style={{ marginTop: label === "Mobile Number" ? 0 : "12px" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <label className="login_label_container">{label}</label>
          <p
            onClick={changeClick}
            className="login_label_container"
            style={{
              display: label === "Mobile Number" && disabled ? "flex" : "none",
              color: "#3a86ff",
              marginLeft: "5px",
              fontWeight: "500",
              cursor: "pointer",
              fontSize: "14px",
            }}
          >
            Change
          </p>

          <p
            onClick={onSendOTP}
            className="login_label_container"
            style={{
              display:
                label === "OTP" && resendOtpCount > 0 && secondLeft === 0
                  ? "flex"
                  : "none",
              color: "#3a86ff",
              marginLeft: "5px",
              fontWeight: "500",
              cursor: "pointer",
              fontSize: "14px",
            }}
          >
            Resend
          </p>

          {secondLeft >= 10 ? (
            <p
              className="login_label_container"
              style={{
                display: label === "OTP" && secondLeft > 0 ? "flex" : "none",
                color: "#1c1c1c",
                marginLeft: "auto",
                marginRight: 0,
                fontWeight: "500",
                fontSize: "14px",
                alignSelf: "left",
              }}
            >
              00:{secondLeft}
            </p>
          ) : (
            <p
              className="login_label_container"
              style={{
                display: label === "OTP" && secondLeft > 0 ? "flex" : "none",
                color: "#1c1c1c",
                marginLeft: "auto",
                marginRight: 0,
                fontWeight: "500",
                fontSize: "14px",
                alignSelf: "left",
              }}
            >
              00:0{secondLeft}
            </p>
          )}
        </div>
        <input
          ref={ref}
          type={type}
          disabled={disabled}
          className="login_input_container"
          maxLength={label !== "OTP" ? 10 : 4}
          onChange={handelChange}
          onKeyPress={onKeyPress}
          style={{
            borderColor: mobileInvalid
              ? "#ff2e2e"
              : otpInvalid
              ? "#ff2e2e"
              : undefined,
          }}
        />

        {/* validation error */}
        <div className="login_invalid_div">
          {/* icon */}
          <InfoIcon
            style={{
              fontSize: "14px",
              paddingRight: "5px",
              color: "#ff2e2e",
              opacity: mobileInvalid ? 1 : otpInvalid ? 1 : 0,
            }}
          />
          {/* text */}
          <p
            className="login_invalid_text"
            style={{
              opacity: mobileInvalid ? 1 : otpInvalid ? 1 : 0,
            }}
          >
            {mobileInvalid
              ? mobileInvalidText
              : otpInvalid
              ? otpInvalidText
              : "Invalid"}
          </p>
        </div>
      </div>
    );
  }
);

// main login component
const Login = (props) => {
  const mobileRef = useRef();
  const otpRef = useRef();
  const [disable, setDisable] = useState(true);
  const [loader, setLoader] = useState(false);
  const [user, setUser] = useState(null);
  const [sendOtp, setSendOtp] = useState(false);
  const [mobileInvalid, setMobileInvalid] = useState(false);
  const [mobileInvalidText, setMobileInvalidText] = useState("");
  const [otpInvalid, setOtpInvalid] = useState(false);
  const [otpInvalidText, setOtpInvalidText] = useState("");
  const [resendOtpCount, setResendOtpCount] = useState(3);
  const [secondLeft, setSecondLeft] = useState(59);
  const navigate = useHistory();
  const rootStore = useStores();
  const api = new IFA_KYC_API();
  const userApi = new User_API();

  useEffect(() => {
    let myTime = setInterval(() => {
      if (resendOtpCount <= 2 && secondLeft > 0) {
        setSecondLeft(secondLeft - 1);
      }
      if (secondLeft === 0) {
        clearInterval(myTime);
      }
    }, 1000);
    return () => {
      clearInterval(myTime);
    };
  });

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        if (!disable) {
          sendOtp ? onLogin() : checkMobile();
        }
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [disable, sendOtp]);

  // prevent handle submit
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  // hanlde input field changes
  const onhandelChange = (event) => {
    setMobileInvalid(false);
    setMobileInvalidText("");
    if (event.target.value.length === 10) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  };

  // check mobile
  const checkMobile = () => {
    var number_format = /^[6-9][0-9]{9}$/;
    if (number_format.test(mobileRef.current.value)) {
      onSendOTP();
    } else {
      setMobileInvalid(true);
      setMobileInvalidText("Invalid mobile");
    }
  };

  // send the otp
  const onSendOTP = async () => {
    setLoader(true);
    setDisable(true);
    setResendOtpCount(resendOtpCount - 1);
    setSecondLeft(59);
    const number = mobileRef.current.value;
    const payload = {
      mobile: number,
      isIfa: true,
    };
    await userApi
      .sendMobileVerification(payload)
      .then((response) => {
        if (response.kind == "ok") {
          console.log(response.data.isExistingUser);
          let userName = response.data.pan_number;
          if (
            !response.data.isExistingUser ||
            (response.data.isExistingUser && response.data.oldExistingUser)
          )
            userName = `+91${number}`;
          console.log("userName--->", userName);
          Auth.signIn(userName)
            .then((res) => {
              setUser(res);
              setLoader(false);
              setDisable(false);
              setSendOtp(true);
              successToast("OTP has been sent successfully.");
              eventLog({
                type: "auth_otp_send",
                action: {
                  trigger: "true",
                },
              });
            })
            .catch((err) => {
              errorToast(
                "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
              );
              eventLog({
                type: "auth_otp_send",
                action: {
                  trigger: "false",
                },
              });
              setTimeout(() => {
                mobileRef.current.value = "";
                otpRef.current.value = "";
                setLoader(false);
                setDisable(false);
                setSendOtp(false);
              }, 5000);
            });
        } else {
          setLoader(false);
          errorToast(
            "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
          );
        }
      })
      .catch((error) => {
        console.log("Error while sending OTP ::: ", error);
        setLoader(false);
        errorToast(
          "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
        );
        eventLog({
          type: "auth_otp_send",
          action: {
            trigger: "false",
          },
        });
      });
  };

  // on login
  const onLogin = async () => {
    if (otpRef.current.value.length === 0) {
      setOtpInvalid(true);
      setOtpInvalidText("OTP cannot be empty");
    } else if (otpRef.current.value.length !== 4) {
      setOtpInvalid(true);
      setOtpInvalidText("Invalid OTP");
    } else if (isNaN(parseInt(otpRef.current.value))) {
      setOtpInvalid(true);
      setOtpInvalidText("OTP must be a number");
    } else {
      setDisable(true);
      setLoader(true);
      await Auth.sendCustomChallengeAnswer(user, otpRef.current.value)
        .then(async (res) => {
          if (res && !res.Session) {
            const curr_user = await Auth.currentUserCredentials();
            const data = {
              name: "",
              email: "",
              uuid: curr_user.identityId,
              mobile: mobileRef.current.value,
            };

            rootStore.userData.getUserData(data).then(async (res) => {
              if (res.kind === "ok") {
                localStorage.setItem("mobile", res.data.mobile);
                if (res.data.kyc_status === 0) {
                  infoToast(
                    "Your KYC is incomplete. Please complete your KYC before proceeding further."
                  );

                  eventLog({
                    type: "auth_login_in",
                    action: {
                      trigger: "false",
                    },
                  });
                  localStorage.setItem("signup", true);
                  setTimeout(() => {
                    setLoader(false);
                    setDisable(false);
                    setSendOtp(false);
                    props.show("Signup");
                  }, 5000);
                } else if (res.data.kyc_status === 1) {
                  await Auth.signOut()
                    .then(() => {
                      errorToast(
                        "Your KYC is still in review. Please be patient while we are reviewing your documents.",
                        1
                      );
                      eventLog({
                        type: "auth_login_in",
                        action: {
                          trigger: "false",
                        },
                      });
                      setTimeout(() => {
                        mobileRef.current.value = "";
                        otpRef.current.value = "";
                        setLoader(false);
                        setDisable(false);
                        setSendOtp(false);
                      }, 5000);
                    })
                    .catch((err) => {
                      errorToast(
                        "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
                      );
                      eventLog({
                        type: "auth_login_in",
                        action: {
                          trigger: "false",
                        },
                      });
                      setTimeout(() => {
                        mobileRef.current.value = "";
                        otpRef.current.value = "";
                        setLoader(false);
                        setDisable(false);
                        setSendOtp(false);
                      }, 5000);
                    });
                } else if (res.data.kyc_status == 2) {
                  // successToast("Successful login.");
                  localStorage.setItem("name", res.data.name);
                  localStorage.setItem("email", res.data.email);
                  localStorage.setItem("Authenticated", true);
                  eventLog({
                    type: "auth_login_in",
                    action: {
                      trigger: "true",
                    },
                  });
                  setTimeout(() => {
                    navigate.push(props.targetURL);
                  }, 2000);
                } else if (res.data.kyc_status === 3) {
                  //await Auth.signOut()
                  try {
                    errorToast("Your KYC has been Rejected");
                    let payload = {
                      mobile: mobileRef.current.value,
                    };
                    return await api.fetchDocuments(payload).then((res) => {
                      if (res.kind == "ok") {
                        let data = res.data;
                        setTimeout(() => {
                          props.rejectKyc(data, "Signup");
                        }, 3000);
                      } else {
                        setTimeout(() => {
                          errorToast(res.error);
                        }, 2000);
                        return false;
                      }
                    });
                  } catch (err) {
                    errorToast(
                      "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
                    );
                    setTimeout(() => {
                      mobileRef.current.value = "";
                      otpRef.current.value = "";
                      setLoader(false);
                      setDisable(false);
                      setSendOtp(false);
                    }, 5000);
                  }
                } else {
                  errorToast(
                    "There is something wrong with your kyc status. Please contact support",
                    1
                  );
                  setTimeout(() => {
                    mobileRef.current.value = "";
                    otpRef.current.value = "";
                    setLoader(false);
                    setDisable(false);
                    setSendOtp(false);
                  }, 5000);
                }
              } else {
                await Auth.signOut()
                  .then(() => {
                    if (
                      res.error === "You are a new user please signup first"
                    ) {
                      errorToast(res.error);
                      eventLog({
                        type: "auth_login_in",
                        action: {
                          trigger: "false",
                        },
                      });
                      localStorage.clear();
                      // localStorage.removeItem('mobileRef');
                      setTimeout(() => {
                        setDisable(false);
                        setLoader(false);
                        props.changeMobile(mobileRef.current.value, "Signup");
                      }, 5000);
                    } else {
                      errorToast(res.error);
                      eventLog({
                        type: "auth_login_in",
                        action: {
                          trigger: "false",
                        },
                      });
                      setTimeout(() => {
                        mobileRef.current.value = "";
                        otpRef.current.value = "";
                        setLoader(false);
                        setDisable(false);
                        setSendOtp(false);
                      }, 5000);
                    }
                  })
                  .catch((err) => {
                    errorToast(
                      "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
                    );
                    setTimeout(() => {
                      mobileRef.current.value = "";
                      otpRef.current.value = "";
                      setLoader(false);
                      setDisable(false);
                      setSendOtp(false);
                    }, 5000);
                  });
              }
            });
          } else {
            setOtpInvalid(true);
            setOtpInvalidText(
              "Wrong OTP. Please check and enter the correct OTP."
            );
            setLoader(false);
            setDisable(false);
          }
        })
        .catch((err) => {
          errorToast(
            "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
          );
          setTimeout(() => {
            mobileRef.current.value = "";
            otpRef.current.value = "";
            setLoader(false);
            setDisable(false);
            setSendOtp(false);
          }, 5000);
        });
    }
  };

  const onChangeClick = async () => {
    await Auth.signOut()
      .then(() => {
        setUser(null);
        setDisable(false);
        setSendOtp(false);
        localStorage.clear();
      })
      .catch((err) => {
        errorToast(
          "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
        );
        setTimeout(() => {
          setUser(null);
          setLoader(false);
          setDisable(false);
          setSendOtp(false);
        }, 5000);
      });
  };

  return (
    <div className="main_login">
      {/* login container */}
      <div className="login_container">
        {/* header */}
        <p id="welcome_title">Welcome!</p>
        <header className="login_header">
          <h1 className="login_header_title">Login</h1>
        </header>

        {/* form */}
        <form className="login_form_container" onSubmit={handleSubmit}>
          {/* fields */}
          <Field
            ref={mobileRef}
            label="Mobile Number"
            type="text"
            disabled={sendOtp}
            handelChange={onhandelChange}
            mobileInvalid={mobileInvalid}
            mobileInvalidText={mobileInvalidText}
            changeClick={onChangeClick}
            onSendOTP={onSendOTP}
            resendOtpCount={resendOtpCount}
            secondLeft={secondLeft}
          />
          {!sendOtp ? (
            <div />
          ) : (
            <Field
              ref={otpRef}
              label="OTP"
              type="text"
              disabled={!sendOtp}
              otpInvalid={otpInvalid}
              otpInvalidText={otpInvalidText}
              handelChange={() => {
                setOtpInvalid(false);
                setOtpInvalidText("");
                if (otpRef.current.value.length == 4) {
                  eventLog({
                    type: "otp_entered",
                    action: {
                      trigger: "true",
                    },
                  });
                }
              }}
              onSendOTP={onSendOTP}
              resendOtpCount={resendOtpCount}
              secondLeft={secondLeft}
            />
          )}

          {/* submit */}
          <div className="login_field_container">
            <button
              className="login_submit_container"
              style={{
                cursor: disable ? "not-allowed" : "pointer",
              }}
              onClick={sendOtp ? onLogin : checkMobile}
              disabled={disable}
            >
              {loader ? (
                <Loader type="TailSpin" color="#fff" height={15} width={15} />
              ) : sendOtp ? (
                "Login"
              ) : (
                "Send OTP"
              )}
            </button>
          </div>
        </form>

        {/* footer */}
        <footer className="login_footer">
          <p className="login_footer_text">
            Don’t have an account?
            <p
              className="login_footer_text_link"
              onClick={() => {
                props.show("Signup");
                localStorage.setItem("signup", true);
              }}
            >
              Sign up
            </p>
          </p>
        </footer>
      </div>

      {/* toast container */}
      <SampleToast />
    </div>
  );
};

export default Login;
