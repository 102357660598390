import React, { useRef, useState, useEffect } from "react";
import "./signup.css";
import {
  SampleToast,
  AccountCreation,
  KycDetails,
  BankVerify,
  OrgDocument,
} from "../../../components";
import StepNavigation from "../../../components/StepNavigation/stepnavigation";
import CircularProgress from "@mui/material/CircularProgress";
import { useStores } from "../../../models";
import { errorToast, verifyNameMatch } from "../../../utils/helper";
import { documents } from "../../../config/settings";
import { Auth } from "aws-amplify";

// actual component
const Signup = (props) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [previousStep, setPreviousStep] = useState(0);
  const [render, setRender] = useState(false);
  const rootStore = useStores();
  const [ifa, setIfa] = useState();
  const [firmName, setFirmName] = useState("");
  const [skipOrgDocument, setSkipOrgDocument] = useState(false);
  const [isGstRequired, setIsGstRequired] = useState(false);
  const [isExcludedFirm, setIsExcludedFirm] = useState(false);
  const [isPennyDrop, setIsPennyDrop] = useState(false);

  useEffect(() => {
    fetchIfa();
  }, [currentStep]);

  useEffect(() => {
    var tempFirmState = {};
    for (let object of documents.firm.types) {
      if (object.value == firmName) {
        tempFirmState = object;
        break;
      }
    }

    if (
      tempFirmState &&
      tempFirmState.orgDocuments &&
      tempFirmState.orgDocuments.length != 0
    ) {
      setSkipOrgDocument(false);
    } else {
      setSkipOrgDocument(true);
    }
    if (tempFirmState && tempFirmState.inputRegisteredAddress) {
      setIsExcludedFirm(true);
    }
  }, [firmName]);

  const fetchIfa = async () => {
    rootStore.userData
      .getIfaData()
      .then(async (res) => {
        if (res.kind === "ok") {
          const data = res.data;
          setIfa(data);
          setFirmName(data.firm_name ? data.firm_name : "");
          setIsGstRequired(
            data.gst_number && data.gst_number.length != 0 ? true : false
          );

          //if all success
          if (
            data.has_profile == 1 &&
            data.has_document == 1 &&
            data.has_org_document == 1 &&
            data.has_bank == 1
          ) {
            const payload = {
              has_profile: data && data.has_profile ? data.has_profile : 0,
              has_document: data && data.has_document ? data.has_document : 0,
              has_org_document:
                data && data.has_org_document ? data.has_org_document : 0,
              has_bank: data && data.has_bank ? data.has_bank : 0,
              is_penny_drop: isPennyDrop,
            };

            onSuccess(payload);
            return;
          } else {
            if (!render) {
              if (data.has_profile == 0) {
                setCurrentStep(0);
              } else if (data.has_document == 0) {
                setCurrentStep(1);
              } else if (data.has_org_document == 0) {
                setCurrentStep(2);
              } else if (data.has_bank == 0) {
                setCurrentStep(3);
              } else {
                errorToast(
                  "You are already our partner. Your KYC is still in review. Please be patient while we are reviewing your documents.",
                  1
                );
              }
            }
            setRender(true);
          }
        } else {
          setRender(true);
        }
      })
      .catch((error) => {
        setRender(true);
        errorToast("Error in API");
      });
  };

  const onSuccess = (payload) => {
    setRender(false);
    rootStore.userData
      .updateKycStatus(payload)
      .then(async (res) => {
        if (res.kind === "ok") {
          if (res.data.isApprove == 1) {
            props.auto_approve_screen(true);
          }
          sendEmailVerification();
          props.show_thank_screen(true);
          await Auth.signOut();
          props.setShowPage("Login");
          setRender(true);
        } else {
          errorToast("Could not update KYC status. Please retry");
          setRender(true);
        }
      })
      .catch((error) => {
        setRender(true);
        errorToast("Error in API");
      });
  };

  const sendEmailVerification = () => {
    const payload = {
      email: ifa.email,
      name: ifa.name,
      is_ifa: true,
    };
    rootStore.userData
      .sendEmailVerification(payload)
      .then(async (res) => {
        if (res.kind === "ok") {
          return;
        }
      })
      .catch((error) => {
        setRender(true);
        errorToast("Error in sending Email verification");
      });
  };
  return (
    <div className="main_signup">
      <div className="signup_container">
        {/* header */}
        <div className="stepper">
        <hr  className={skipOrgDocument===false? "end_navigator": "step_navigator" }/>
          <StepNavigation
            ifa={ifa}
            currentStep={currentStep}
            skipOrgDocument={skipOrgDocument}
            has_profile={ifa && ifa.has_profile ? ifa.has_profile : 0}
            has_document={ifa && ifa.has_document ? ifa.has_document : 0}
            has_org_document={
              ifa && ifa.has_org_document ? ifa.has_org_document : 0
            }
            has_bank={ifa && ifa.has_bank ? ifa.has_bank : 0}
          />
        </div>
        <header className="signup_header">
          <h1 className="signup_header_title">
            Complete your registration in a few easy steps!
          </h1>
        </header>
        <div></div>

        {render ? (
          <div className="signup_ifa_kyc">
            {currentStep == 0 ? (
              <AccountCreation
                ifa={ifa}
                show={props.show}
                setShowPage={props.setShowPage}
                setCurrentStep={setCurrentStep}
                setFirmName={setFirmName}
                isGstRequired={isGstRequired}
                setIsGstRequired={setIsGstRequired}
                isExcludedFirm={isExcludedFirm}
              />
            ) : null}
            {currentStep == 1 ? (
              <KycDetails
                ifa={ifa}
                show={props.show}
                setCurrentStep={setCurrentStep}
                skipOrgDocument={skipOrgDocument}
                firmName={firmName}
                isGstRequired={isGstRequired}
                isExcludedFirm={isExcludedFirm}
              />
            ) : null}
            {currentStep == 2 ? (
              <OrgDocument
                ifa={ifa}
                show={props.show}
                setCurrentStep={setCurrentStep}
                firmName={firmName}
                isExcludedFirm={isExcludedFirm}
              />
            ) : null}
            {currentStep == 3 ? (
              <BankVerify
                ifa={ifa}
                show={props.show}
                setCurrentStep={setCurrentStep}
                isExcludedFirm={isExcludedFirm}
                setIsPennyDrop={setIsPennyDrop}
              />
            ) : null}
          </div>
        ) : (
          <div className="kyc-loader">
            <div>
              <CircularProgress />
            </div>
            <div style={{ marginTop: "7px" }}>Loading...</div>
          </div>
        )}
      </div>

      {/* toast container */}
      <SampleToast />
    </div>
  );
};

export default Signup;
