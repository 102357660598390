import * as React from "react";
import "./client-growth-plan-investments-model.css";
import Modal from "@mui/material/Modal";
import Loader from "react-loader-spinner";
import CloseIcon from "@mui/icons-material/Close";
import { convertAmount, errorToast } from "../../utils/helper";
import { Client_API } from "../../services/api";

export default function ClientGrowthPlanInvestmentsModel(props) {
  const [growthPlanInvestmentsData, setGrowthPlanInvestmentsData] =
    React.useState([]);
  const [loader, setLoader] = React.useState(false);
  const selectedPoolType = props.selectedPoolType;

  const api = new Client_API();

  React.useEffect(() => {
    if (props.openModel) {
      fetchGrowthPlanInvestments();
    }
  }, [props.openModel]);

  const fetchGrowthPlanInvestments = () => {
    setLoader(true);

    const payload = {
      user_uuid: props.clientUuid,
      pool_template_uuid: props.poolTemplateUuid,
    };

    api
      .fetchClientGrowthPlanInvestments(payload)
      .then((res) => {
        if (res.kind === "ok") {
          setGrowthPlanInvestmentsData(res.data);
          setLoader(false);
        } else {
          errorToast("Error in fetching client transaction history");
          setLoader(false);
        }
      })
      .catch((error) => {
        errorToast(
          "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
        );
        setLoader(false);
      });
  };

  return (
    <Modal
      open={props.openModel}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{
        backgroundColor: "rgba(102, 102, 102, 0.72)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className="client_growth_plan_investments_model_div">
        <div className="client_growth_plan_investments_model_header">
          <div style={{ float: "left", width: "80%" }}>
            <h1 className="client_growth_plan_investments_model_header_title">
              Growth Plan Investment Details
            </h1>
          </div>
          <div
            style={{ float: "right", cursor: "pointer" }}
            onClick={props.handleClose}
          >
            <h1 className="client_growth_plan_investments_model_header_title">
              <CloseIcon />
            </h1>
          </div>
        </div>

        <div className="client_growth_plan_investments_model_main_container_div">
          <div className="client_growth_plan_investments_model_main_div">
            <div>
              <h1 className="client_growth_plan_investments_model_title">
                Start Date
              </h1>
            </div>
            <div>
              <h1 className="client_growth_plan_investments_model_title">
                Invested Amount
              </h1>
            </div>
            <div>
              <h1 className="client_growth_plan_investments_model_title">
                {selectedPoolType == 0 ? "Maturity Date" : "Next Earnings Date"}
              </h1>
            </div>
            <div>
              <h1 className="client_growth_plan_investments_model_title">
                {selectedPoolType == 0 ? "Maturity Amount" : "Next Earnings Amount"}
              </h1>
            </div>
          </div>
          {/* title */}
          {!loader ? (
            <React.Fragment>
              {growthPlanInvestmentsData &&
                growthPlanInvestmentsData.length != 0 &&
                growthPlanInvestmentsData.map((item, key) => (
                  <div
                    className="client_growth_plan_investments_model_main_div"
                    index={key}
                  >
                    <div>
                      <h1 className="client_growth_plan_investments_model_title_value">
                        {item.investment_start_date}
                      </h1>
                    </div>
                    <div>
                      <h1
                        className="client_growth_plan_investments_model_title_value"
                        style={{ color: "#424242", fontWeight: 600 }}
                      >
                        <span
                          className="client_growth_plan_investments_model_title_value"
                          style={{
                            fontFamily: "'Open Sans', sans-serif",
                            fontWeight: 600,
                            color: "#424242",
                          }}
                        >
                          ₹
                        </span>{" "}
                        {convertAmount(item.invested_amount)}
                      </h1>
                    </div>
                    <div>
                      <h1 className="client_growth_plan_investments_model_title_value">
                        {item.maturity_date}
                      </h1>
                    </div>
                    <div>
                      <h1
                        className="client_growth_plan_investments_model_title_value"
                        style={{ color: "#424242", fontWeight: 600 }}
                      >
                        <span
                          className="client_growth_plan_investments_model_title_value"
                          style={{
                            fontFamily: "'Open Sans', sans-serif",
                            fontWeight: 600,
                            color: "#424242",
                          }}
                        >
                          ₹
                        </span>{" "}
                        {selectedPoolType == 0
                          ? convertAmount(item.maturity_amount)
                          : convertAmount(item.payout_amount)}
                      </h1>
                    </div>
                  </div>
                ))}
            </React.Fragment>
          ) : (
            <Loader type="TailSpin" color="#fff" height={15} width={15} />
          )}
        </div>
      </div>
    </Modal>
  );
}
