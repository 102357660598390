import React, { useEffect, useState } from "react";
import {
  PoolTemplates,
  SampleToast,
  ContentLoadingLoader,
} from "../../components";
import "./poollist.css";
import { useLocation, useHistory, Redirect } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Auth } from "aws-amplify";
import { kycStatus_API } from "../../services/api/kycstatus/kycstatus-api";
import { errorToast, successToast } from "../../utils/helper";
import { APP_RELEASE_API } from "../../services/api";
import config from "../../config/settings";

const PoolList = observer(function PoolList(props) {
  const appReleaseApi = new APP_RELEASE_API();
  const [loginRender, setLoginRender] = useState(false);
  const [isLogin, setIsLogin] = useState();
  const [loader, setLoader] = useState(false);
  const navigate = useHistory();

  useEffect(() => {
    props.showCommonHeader(true);
    checkLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkLogin = async () => {
    try {
      setLoader(true);
      await Auth.currentAuthenticatedUser();
      await Promise.all([fetchIfakycStatus(), fetchReleaseStatus()]);
    } catch {
      setIsLogin(false);
      errorToast(
        "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@arthmatedirect.com"
      );
    }
  };

  const fetchIfakycStatus = async () => {
    setLoader(true);
    const api = new kycStatus_API();
    try {
      // Fetch data from API
      await api.getkycStatusData();
      props.showCommonSidebar(true);
      setIsLogin(true);
      setLoginRender(true);
    } catch (error) {
      console.log(error);
      errorToast(
        "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
      );
    } finally {
      setLoader(false);
    }
  };

  const fetchReleaseStatus = async () => {
    try {
      setLoader(true);
      const response = await appReleaseApi.getAppReleaseData();
      if (response.kind === "ok") {
        const fp_status_info = {
          flag_status: response.data.freedom_plan_display,
          flag_note: response.data.note,
        };
        config.fp_flag_info = fp_status_info;
        config.pool_templates = response.data.pool_templates;
      }
    } catch (error) {
      console.log(error);
      errorToast(
        "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
      );
    } finally {
      setLoader(false);
    }
  };

  // If user is not logged in, redirect to login page
  if (isLogin === false) {
    return <Redirect to="/" />;
  }

  return (
    <div>
      {loginRender === false ? (
        <ContentLoadingLoader />
      ) : (
        <div className="pool_list_div_container">
          <div className="pool_list_heading_container">
            <h1 className="pool_list_header_div_title"> Investment Plans </h1>
          </div>

          {loader ? (
            <ContentLoadingLoader />
          ) : (
            <div className="pool_list_content">
              <PoolTemplates {...props} />
            </div>
          )}
        </div>
      )}
      <SampleToast />
    </div>
  );
});

export default PoolList;