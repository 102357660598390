import React, { useEffect, useRef, useState } from "react";
import "./client-switch-plan-modal.css";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { APP_RELEASE_API, SwitchPlanAPI } from "../../services/api";
import { convertAmount, errorToast, successToast } from "../../utils/helper";
import Loader from "react-loader-spinner";
import { useStores } from "../../models";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CircleIcon from "@mui/icons-material/Circle";

export default function ClientSwitchPlanModal(props) {
  const inputRef = useRef(null);
  const appReleaseApi = new APP_RELEASE_API();
  const switch_plan_api = new SwitchPlanAPI();
  const rootStore = useStores();
  const regexPattern = /^[1-9]\d*000$/;
  const perPage = 10;
  const [loader, setLoader] = useState(true);
  const [planLoader, setPlanLoader] = useState(false);
  const [investmentPlan, setInvestmentPlan] = useState([]);
  const [planType, setPlanType] = useState(0);
  const [maturityPlan, setMaturityPlan] = useState([]);
  const [monthlyPlan, setMonthlyPlan] = useState([]);
  const [maturitySelectedIndex, setMaturitySelectedIndex] = useState(0);
  const [monthlySelectedIndex, setMonthlySelectedIndex] = useState(0);
  const [amount, setAmount] = useState(null);
  const [amountError, setAmountError] = useState("");
  const [investLoader, setInvestLoader] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState({
    uuid: "",
    type: 0,
    minimum_invested_amount: 0,
    tenure: 0,
    tenure_type: 0,
    interest_rate: 13,
  });

  useEffect(() => {
    configData();
  }, []);

  // fetch config data
  const configData = async () => {
    await appReleaseApi.getAppReleaseData().then((response) => {
      if (response.kind == "ok") {
        let plan = response.data.pool_templates.filter((obj) => {
          if (props.clientData.freedom_plan_withdrawable < 100000) {
            return obj.type == 0;
          } else {
            return obj.type !== 1;
          }
        });
        setInvestmentPlan(plan);
        setLoader(false);
        fetchInvestmentPlan(planType);
      }
    });
  };

  // update selected plan
  const updateSelectedPlan = (plan, index) => {
    let planBody = {};
    planBody["uuid"] = plan[index].id;
    planBody["type"] = plan[index].type;
    planBody["minimum_invested_amount"] = plan[index].minimum_investment;
    planBody["tenure"] = plan[index].tenure;
    planBody["tenure_type"] = plan[index].tenure_type;
    planBody["interest_rate"] = plan[index].interest_rate;
    if (inputRef.current) {
      inputRef.current.value = "";
    }
    setAmount(null);
    setAmountError("");
    setSelectedPlan((prevState) => {
      return {
        ...prevState,
        ...planBody,
      };
    });
  };

  // fetch investment plan
  const fetchInvestmentPlan = async (type) => {
    setPlanLoader(true);
    rootStore.openPool.getOpenPools(perPage, type).then((res) => {
      if (res.kind === "ok") {
        if (type == 0) {
          setMaturityPlan(res.data);
          setMaturitySelectedIndex(0);
        } else if (type == 2) {
          setMonthlyPlan(res.data);
          setMonthlySelectedIndex(0);
        }
        updateSelectedPlan(res.data, 0);
        setPlanLoader(false);
      } else {
        errorToast(res.error);
        setPlanLoader(false);
      }
    });
  };

  // handle tab toggle
  const handleToggle = (item) => {
    setPlanType(item.type);
    if (maturityPlan.length == 0 || monthlyPlan.length == 0) {
      fetchInvestmentPlan(item.type);
    } else {
      if (item.type == 0) {
        updateSelectedPlan(maturityPlan, maturitySelectedIndex);
      } else {
        updateSelectedPlan(monthlyPlan, monthlySelectedIndex);
      }
    }
  };

  // handler tenure toggle
  const handleTenureToggle = (index) => {
    if (planType == 0) {
      setMaturitySelectedIndex(index);
      updateSelectedPlan(maturityPlan, index);
    } else {
      setMonthlySelectedIndex(index);
      updateSelectedPlan(monthlyPlan, index);
    }
  };

  // tenure view
  const TenureView = (props) => {
    return (
      <div className="client_switch_plan_tenure_container">
        {props.item.map((e, index) => {
          return (
            <div
              key={index}
              className="client_switch_plan_tenure_div"
              style={{
                borderColor: e.id == selectedPlan.uuid ? "#3A86FF" : "#AEAEAE",
              }}
              onClick={() => props.onTenureToggle(index)}
            >
              {/* icon */}
              {e.id == selectedPlan.uuid ? (
                <RadioButtonCheckedIcon
                  style={{
                    color: "#3A86FF",
                    fontSize: 16,
                  }}
                />
              ) : (
                <RadioButtonUncheckedIcon
                  style={{
                    color: "#AEAEAE",
                    fontSize: 16,
                  }}
                />
              )}

              {/* tenure text */}
              <p
                className="client_switch_plan_tenure_text"
                style={{
                  color: e.id == selectedPlan.uuid ? "#3A86FF" : "#AEAEAE",
                }}
              >
                {e.tenure} {e.tenure_type}
              </p>
            </div>
          );
        })}
      </div>
    );
  };

  // on change text
  const onChangeText = (e) => {
    const { value } = e.target;
    if (value == "") {
      setAmountError("Amount cannot be empty.");
    } else if (!regexPattern.test(value)) {
      setAmountError(
        `Please enter an amount that is a multiple of ₹ ${convertAmount(1000)}`
      );
    } else if (parseInt(value) < selectedPlan.minimum_invested_amount) {
      setAmountError(
        `Please enter an amount that is greater than or equal to ₹ ${convertAmount(
          selectedPlan.minimum_invested_amount
        )}.`
      );
    } else if (
      parseInt(value) >
      props.clientData.freedom_plan_withdrawable -
        props.clientData.switch_plan_amount
    ) {
      setAmountError(
        "Please enter an amount that is less than or equal to availale amount for switching."
      );
    } else {
      setAmountError("");
    }
    setAmount(value);
  };

  // on invest
  const onInvest = async () => {
    setInvestLoader(true);

    // payload
    const payload = {
      client_uuid: props.clientData.uuid,
      plan_uuid: selectedPlan.uuid,
      amount: amount,
    };

    // call the api
    switch_plan_api.addSwitchPlansRequest(payload).then((res) => {
      if (res.kind == "ok") {
        successToast("Switch Plan request initiated successfully.");
        setInvestLoader(false);
        props.closeAndUpdate();
      } else {
        errorToast(res.error);
        setInvestLoader(false);
        props.handleClose();
      }
    });
  };

  return (
    <Modal
      open={props.openModel}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{
        backgroundColor: "rgba(102, 102, 102, 0.40)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className="client_switch_plan_main_div">
        {/* header */}
        <div className="client_switch_plan_header">
          {/* title */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <p className="client_switch_plan_title">Switch Plan Request :</p>
            <p
              className="client_switch_plan_title"
              style={{ opacity: 0.6, marginLeft: 5, fontSize: "16px" }}
            >
              {props.clientData.name}
            </p>
          </div>
          {/* close icon */}
          <p
            className="client_switch_plan_close_icon"
            onClick={props.handleClose}
          >
            <CloseIcon
              style={{
                color: "#000000",
                fontSize: 30,
              }}
            />
          </p>
        </div>

        {/* rest container */}
        {loader ? (
          <div className="client_switch_plan_loader_div">
            <Loader type="TailSpin" color="#414141" height={40} width={40} />
          </div>
        ) : (
          <div style={{ paddingLeft: 20, paddingRight: 20 }}>
            {/* top view */}
            <div className="client_switch_plan_top_div">
              <div className="client_switch_plan_row_div">
                <p className="client_switch_plan_h3">
                  Freedom Plan Invested Amount including earnings:
                </p>
                <p className="client_switch_plan_h2">
                  ₹ {convertAmount(props.clientData.freedom_plan_withdrawable)}
                </p>
              </div>

              {/* list */}
              <div className="client_switch_plan_list_div">
                {/* icon */}
                <div className="client_switch_plan_list_row_div">
                  <CircleIcon
                    style={{
                      color: "#363636",
                      fontSize: 12,
                    }}
                  />
                  <p className="client_switch_plan_list_text_heading">
                    Available to switch:
                  </p>

                  <p className="client_switch_plan_list_text_content">
                    ₹{" "}
                    {convertAmount(
                      props.clientData.freedom_plan_withdrawable -
                        props.clientData.switch_plan_amount
                    )}
                  </p>
                </div>

                <div className="client_switch_plan_list_row_div">
                  <CircleIcon
                    style={{
                      color: "#363636",
                      fontSize: 12,
                    }}
                  />
                  <p className="client_switch_plan_list_text_heading">
                    In-process and pending request:
                  </p>

                  <p className="client_switch_plan_list_text_content">
                    ₹ {convertAmount(props.clientData.switch_plan_amount)}
                  </p>
                </div>
              </div>

              {/* description */}
              <p className="client_switch_plan_h4">
                You can choose to invest the entire/partial amount currently
                invested in Freedom Plan in any of the other plans from the
                below mentioned options.
              </p>
            </div>

            {/* plan container */}
            <div className="client_switch_plan_investment">
              {/* plan tab header */}
              <div className="client_switch_plan_investment_toggle">
                {investmentPlan.map((item, index) => {
                  return item.type == 1 ? null : (
                    <div
                      key={index}
                      className={
                        planType == item.type
                          ? "client_switch_plan_investment_active"
                          : "client_switch_plan_investment_inactive"
                      }
                      id={item.type}
                      onClick={() => handleToggle(item)}
                    >
                      {item.title}
                    </div>
                  );
                })}
              </div>

              {/* select tenure and minimum invested amount */}
              {planLoader ? (
                <div className="client_switch_plan_loader_div">
                  <Loader
                    type="TailSpin"
                    color="#414141"
                    height={20}
                    width={20}
                  />
                </div>
              ) : (
                <>
                  {/* plan description */}
                  <div className="client_switch_plan_investment_sub_heading">
                    {planType == 0 && (
                      <p className="client_switch_plan_investment_content">
                        With Growth Plan, earn up to{" "}
                        {selectedPlan.interest_rate}% per annum. Invested
                        amount, including the interest earned, is paid at the
                        end of the plan tenure.
                      </p>
                    )}
                    {planType == 2 && (
                      <p className="client_switch_plan_investment_content">
                        Invest in Growth Plan with Monthly Earnings to earn up
                        to {selectedPlan.interest_rate}% per annum. Interest
                        earned is paid every month. Invested amount is repaid at
                        the end of the plan tenure.
                      </p>
                    )}
                  </div>

                  {/* select tenure */}
                  <p className="client_switch_plan_select_tenure_text">
                    Select Tenure
                  </p>

                  {/* tenure mapping */}
                  <TenureView
                    item={planType == 0 ? maturityPlan : monthlyPlan}
                    onTenureToggle={(index) => handleTenureToggle(index)}
                  />

                  {/* minimum invested amount */}
                  <div
                    className="client_switch_plan_input_div"
                    style={{
                      borderColor:
                        amountError.length > 0 ? "#e73535" : "#424242",
                    }}
                  >
                    {/* input box */}
                    <input
                      ref={inputRef}
                      type="text"
                      placeholder={`Enter Minimum Investment Amount  ₹ ${convertAmount(
                        selectedPlan.minimum_invested_amount
                      )}`}
                      className="client_switch_plan_input"
                      pattern={regexPattern.source}
                      onChange={(e) => onChangeText(e)}
                    />
                  </div>

                  {/* error text */}
                  {amountError.length > 0 ? (
                    <p className="client_switch_error_text">{amountError}</p>
                  ) : (
                    <></>
                  )}

                  {/* info text */}
                  {/* <p className="client_switch_info_text">
                    Amount should be in multiples of ₹ 1,000.
                  </p> */}
                </>
              )}
            </div>

            {/* invest button */}
            {selectedPlan.uuid != "" ? (
              <button
                className="client_switch_plan_invest_button"
                style={{
                  cursor:
                    amount && amountError.length == 0
                      ? "pointer"
                      : "not-allowed",
                }}
                onClick={() => {
                  if (!investLoader) {
                    if (amount && amountError.length == 0) {
                      onInvest();
                    }
                  }
                }}
              >
                {investLoader ? (
                  <Loader type="TailSpin" color="#fff" height={15} width={15} />
                ) : (
                  "Invest"
                )}
              </button>
            ) : (
              <></>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
}
