import React from "react";
import Loader from "react-loader-spinner";

const FullScreenLoader = (props) => {
  return (
    <div
      style={{
        display: "flex",
        position: "fixed",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        height: "100%",
        width: "100%",
        backgroundColor: props.backgroundColor!=null ? props.backgroundColor :"rgba(0,0,0,0.75)",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 300,
      }}
    >
      <Loader type="TailSpin" color= {props.color ?? "#fff"} height={80} width={80} />
    </div>
  );
};

export default FullScreenLoader;
