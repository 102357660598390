import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Auth } from "aws-amplify";
import { useHistory, Redirect } from "react-router-dom";

import { errorToast } from "../../utils/helper";
import "./downloads.css";
import { ContentLoadingLoader, MarketingModel } from "../../components";
import { Downloads_API } from "../../services/api/Downloads/downloads-api";
import { kycStatus_API } from "../../services/api/kycstatus/kycstatus-api";
import "./downloads.css";
// import HideIFA from "../HideIFA/hideIFA";

const Downloads = observer(function Downloads(props) {
  const [isLogin, setIsLogin] = useState();
  const [loginRender, setLoginRender] = useState(false);
  const [downloadableData, setDownloadableData] = useState([]);
  const [render, setRender] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const navigate = useHistory();
  const [loader, setLoader] = useState(false);
  const api = new Downloads_API();
  // const [hideIFA, setHideIFA] = useState(false);

  // function handleWindowSizeChange() {
  //   if (window.innerWidth <= 1080 || window.innerHeight <= 600) {
  //     setHideIFA(true);
  //     props.showCommonHeader(false);
  //     props.showCommonSidebar(false);
  //   } else {
  //     setHideIFA(false);
  //     props.showCommonHeader(true);
  //     props.showCommonSidebar(true);
  //   }
  // }

  // useEffect(() => {
  //   window.addEventListener("resize", handleWindowSizeChange);
  //   if (window.innerWidth <= 1080 || window.innerHeight <= 600) {
  //     setHideIFA(true);
  //     props.showCommonHeader(false);
  //     props.showCommonSidebar(false);
  //   }
  //   return () => {
  //     window.removeEventListener("resize", handleWindowSizeChange);
  //   };
  // }, []);

  // check if user login or not
  useEffect(() => {
    // getHistory()
    props.showCommonHeader(true);
    let checkLogin = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        let status = localStorage.getItem("kyc_status");
        if (status != 2) {
          setIsLogin(false);
        }
        else
        {
        fetchIfakycStatus();
        }
        // setIsLogin(true);
        // setLoginRender(true);
        // downloadablematerials();
        console.log(downloadableData);
        setRender(false);
      } catch {
        setIsLogin(false);
             errorToast(
          "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
        );
      }
    };
    return checkLogin();
    // eslint-disable-next-line
  }, []);

  // fetch ifa kyc status
  const fetchIfakycStatus = () => {
    setLoader(true);
    // const api = new kycStatus_API();

    // fetch data
    // api
    //   .getkycStatusData()
    //   .then((res) => {
    //     if (res.kind === "ok") {
    //       props.showCommonSidebar(true);
    //       setLoader(false);
    //       downloadablematerials();
    //       setIsLogin(true);
    //       setLoginRender(true);
    //     } else {
    //       setLoader(false);
    //       navigate.push("/");
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     setLoader(false);
    //     errorToast(
    //       "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
    //     );
    //   });
    props.showCommonSidebar(true);
    setLoader(false);
    downloadablematerials();
    setIsLogin(true);
    setLoginRender(true);
  };
  // fetch the client list
  const downloadablematerials = () => {
    api.getDownloadableData().then((res) => {
      if (res.kind === "ok") {
        setDownloadableData(res.data);
        setRender(true);
      } else {
        setRender(true);
        errorToast(res.error);
      }
    });
  };
  const fileName = "P2P Material";

  //new download
  function downloadBase64File(base64Data, contentType) {
    // const contentType='image/png'
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  // if (hideIFA) {
  //   return <HideIFA />;
  // }
  return (
    <div>
      {loginRender === false ? (
        <div />
      ) : (
        <div className="downloads_div_container">
          {render === false ? (
            <ContentLoadingLoader />
          ) : (
            <div className="downloads_main_div">
              <div>
                <div style={{ display: "table-row", padding: "2%" }}>
                  <a
                    className={"tabdownloads selected"}
                    style={{ fontSize: "19px", marginBottom: "30px" }}
                  >
                    Available Resources
                  </a>
                </div>
                <div>
                  <MarketingModel
                    marketingData={downloadableData}
                    downloadBase64File={downloadBase64File}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
});

export default Downloads;
