import { API } from "aws-amplify";
import * as Types from "../api.types";
import { OpenPoolSnapshot } from "../../../models";

export class Pools_API {
  /**
   * @description this function is for getting the open pools list
   * @returns this function returns the open pools list
   */
  async getOpenPoolsAPI(
    per_page: any,
    type: any,
  ): Promise<Types.GetOpenPoolResult> {
    // convert the raw data into actual data
    const dataConverter = (raw: any) => {
      return {
        id: raw.id,
        type: raw.type == null ? 0 : raw.type,
        pool_title: raw.title == undefined ? "undefined" : raw.title,
        risk_rate:
          raw.risk_rating == undefined ? "undefined" : raw.risk_rating.title,
        badge_title: raw.badge == undefined ? "undefined" : raw.badge.title,
        tenure: raw.tenure == undefined ? "undefined" : String(raw.tenure),
        tenure_type:
          raw.tenure_type == undefined
            ? "undefined"
            : raw.tenure_type == 1
              ? raw.tenure == undefined ? "Months" : raw.tenure == 1 ? "Month" : "Months"
              : raw.tenure == undefined ? "Days" : raw.tenure == 1 ? "Day" : "Days",
        interest_rate:
          raw.interest_rate == undefined
            ? "undefined"
            : String(raw.interest_rate.toFixed(2)),
        minimum_investment:
          raw.minimum_investment_amount == undefined
            ? "undefined"
            : String(raw.minimum_investment_amount),
        start_date:
          raw.issue == undefined ? "undefined" : raw.issue.start.formatted,
        end_date:
          raw.issue == undefined ? "undefined" : raw.issue.end.formatted,
        totle_investment:
          raw.invested_amount == undefined
            ? "undefined"
            : String(raw.invested_amount),
        is_shielded:
          raw.is_shielded == undefined ? "undefined" : raw.is_shielded,
        display: raw.display == undefined ? false : raw.display,
        description:
          raw.description == undefined || raw.description == null
            ? "undefined"
            : raw.description,
        logo_color:
          raw.title == undefined
            ? "#123456"
            : raw.title == "Ultra Short Term"
              ? "#E39436"
              : raw.title == "Short Term"
                ? "#7F53DC"
                : raw.title == "Medium Term"
                  ? "#1CC29F"
                  : "#123456",
        badge_color:
          raw.badge == undefined
            ? "undefined"
            : raw.badge.title == "Trending"
              ? "#9535C2"
              : raw.badge.title == "Hot Picks"
                ? "#4BA8D0"
                : "#123456",
        risk_color:
          raw.risk_rating === undefined
            ? "#123456"
            : String(raw.risk_rating.title).startsWith("A")
              ? "#12924D"
              : String(raw.risk_rating.title).startsWith("B")
                ? "#E29705"
                : "#F04237",
      };
    };

    async function mainConverter(raw_response: any) {
      const pools_data = raw_response.data;
      return {
        pools: pools_data.map(dataConverter),
        pagination: raw_response.meta
          ? raw_response.meta.pagination
            ? {
              current_page:
                raw_response.meta.pagination.current_page == undefined
                  ? 0
                  : raw_response.meta.pagination.current_page,
              count:
                raw_response.meta.pagination.count == undefined
                  ? 0
                  : raw_response.meta.pagination.count,
              starts_from:
                raw_response.meta.pagination.starts_from == undefined
                  ? 0
                  : raw_response.meta.pagination.starts_from,
              per_page:
                raw_response.meta.pagination.per_page == undefined
                  ? 0
                  : raw_response.meta.pagination.per_page,
              total_count:
                raw_response.meta.pagination.total_count == undefined
                  ? 0
                  : raw_response.meta.pagination.total_count,
            }
            : {
              current_page: 0,
              count: 0,
              starts_from: 0,
              per_page: 0,
              total_count: 0,
            }
          : {
            current_page: 0,
            count: 0,
            starts_from: 0,
            per_page: 0,
            total_count: 0,
          },
      };
    }

    //call the api try and catch
    try {
      const response: any = await API.get("baseurl", "/v2/pools", {
        queryStringParameters: {
          type: type,
          pageSize: per_page,
        },
        headers: {
          "Content-type": "application/json",
        },
      });
      const convertedData: any = await mainConverter(response);
      return { kind: "ok", openpools: convertedData };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description this function is for get specific pool by its id
   * @param id
   * @returns json data
   */
  async getPoolById(id: any): Promise<Types.GetPoolByIdResult> {
    // convert the raw data into actual data
    const dataConverter = (raw: any) => {
      return {
        id: raw.id,
        pool_title: raw.title == undefined ? "undefined" : raw.title,
        risk_rate:
          raw.risk_rating == undefined ? "undefined" : raw.risk_rating.title,
        badge_title: raw.badge == undefined ? "undefined" : raw.badge.title,
        tenure: raw.tenure == undefined ? "undefined" : String(raw.tenure),
        tenure_type:
          raw.tenure_type == undefined
            ? "undefined"
            : raw.tenure_type == 1
              ? "Months"
              : "Days",
        interest_rate:
          raw.interest_rate == undefined
            ? "undefined"
            : String(Math.floor(raw.interest_rate.toFixed(2))),
        minimum_investment:
          raw.minimum_investment_amount == undefined
            ? "undefined"
            : String(raw.minimum_investment_amount),
        start_date:
          raw.issue == undefined ? "undefined" : raw.issue.start.formatted,
        end_date:
          raw.issue == undefined ? "undefined" : raw.issue.end.formatted,
        totle_investment:
          raw.invested_amount == undefined
            ? "undefined"
            : String(raw.invested_amount),
        is_shielded:
          raw.is_shielded == undefined ? "undefined" : raw.is_shielded,
        description:
          raw.description == undefined || raw.description == null
            ? "undefined"
            : raw.description,
        logo_color:
          raw.title == undefined
            ? "#123456"
            : raw.title == "Ultra Short Term"
              ? "#E39436"
              : raw.title == "Short Term"
                ? "#7F53DC"
                : raw.title == "Medium Term"
                  ? "#1CC29F"
                  : "#123456",
        badge_color:
          raw.badge == undefined
            ? "undefined"
            : raw.badge.title == "Trending"
              ? "#9535C2"
              : raw.badge.title == "Hot Picks"
                ? "#4BA8D0"
                : "#123456",
        risk_color:
          raw.risk_rating === undefined
            ? "#123456"
            : String(raw.risk_rating.title).startsWith("A")
              ? "#12924D"
              : String(raw.risk_rating.title).startsWith("B")
                ? "#E29705"
                : "#F04237",
        partners: raw.partners,
      };
    };

    //call the api try and catch
    try {
      const response: any = await API.get("baseurl", `/v2/pools/${id}`, {});
      const rawData = response.data;
      const convertedData: any = dataConverter(rawData);
      return { kind: "ok", getpoolbyid: convertedData };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description this function is for send the payment link to the investors
   * @param data
   * @returns this return the sucess and failure message
   */
  async sendPaymentLink(data: any): Promise<Types.GetSendPaymentLink> {
    // payload
    const params = {
      body: data,
    };

    //call the api try and catch
    try {
      const response: any = await API.post("baseurl", "/v2/ifa/links", params);
      return { kind: "ok", data: response };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description this function is for send the transaction fail mail to the investors
   * @param data
   * @returns this return the sucess and failure message
   */
  async sendTransactionFailedMail(
    data: any
  ): Promise<Types.GetSendPaymentLink> {
    // payload
    const params = {
      body: data,
    };

    //call the api try and catch
    try {
      const response: any = await API.post(
        "baseurl",
        `/v2/ifa-transaction/transaction-fail`,
        params
      );
      return { kind: "ok", data: response };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }
}
