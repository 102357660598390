import React, { useEffect, useRef, useState } from "react";
import { Auth } from "aws-amplify";
import "./clientinvestment.css";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SearchIcon from "@mui/icons-material/Search";
import { Report_API } from "../../services/api";
import MenuItem from "@mui/material/MenuItem";
import {
  ClientOverview,
  ClientActiveInvestments,
  ClientClosedInvestments,
  ClientInprocessInvestments,
  OverviewFilter,
  ClientGrowthPlan,
  ClientFreedomPlan,
  SampleToast,
  ActionDropdown,
  TransactionHistoryModel,
  useWindowDimensions,
  ConsentRequestModel,
  PoolTemplates,
  ContentLoadingLoader,
  Dropdown,
} from "../../components";
import Loader from "react-loader-spinner";
import { eventLog } from "../../utils/firebase";
import {
  convertAccountSpaces,
  errorToast,
  successToast,
} from "../../utils/helper";
import { Client_API, APP_RELEASE_API } from "../../services/api";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import config, { pool_templates } from "../../config/settings";
import { useStores } from "../../models";
import BorrowerMapping from "../BorrowerMapping/borrowerMapping";
// import HideIFA from "../HideIFA/hideIFA";

const defaultClientOverviewData = {
  active_total_invested: 0,
  active_total_principal: 0,
  active_total_interest: 0,
  active_avg_interest_rate: 0,
  active_investment_count: 0,
  past_total_invested: 0,
  past_total_principal: 0,
  past_total_interest: 0,
  past_avg_interest_rate: 0,
  past_investment_count: 0,
  total_invested: 0,
  total_principal: 0,
  total_interest: 0,
  avg_interest_rate: 0,
  total_investment_count: 0,
  in_process_investment_count: 0,
  freedom_plan_invested_amount: 0,
  freedom_plan_earnings: 0,
  freedom_plan_daily_earnings: 0,
  freedom_plan_interest_rate: 0,
  growth_maturity_interest_rate: 0,
  virtual_account_data: {},
  kyc_approved: 0,
  is_net_worth: 0,
  active_invested_amount: {},
  inprocess_invested_amount: {},
  overall_earnings: {},
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const ClientInvestment = (props) => {
  const navigate = useHistory();
  const rootStore = useStores();
  const param = useLocation();
  const api = new Client_API();
  const reportAPI = new Report_API();
  const appReleaseApi = new APP_RELEASE_API();
  const [loginRender, setLoginRender] = useState(false);
  const [isLogin, setIsLogin] = useState();
  const [overviewData, setOverviewData] = useState(defaultClientOverviewData);
  const screenOptions = ["Overview", "Growth Plan", "Freedom Plan"];
  const [screenName, setScreenName] = useState(screenOptions[0]);
  const [filterValue, setFilterValue] = useState("All");
  const [overviewCall, setOverviewCall] = useState(0);
  const [growthPlanCall, setGrowthPlanCall] = useState(0);
  const [freedomPlanCall, setFreedomPlanCall] = useState(0);
  const [activeCall, setActiveCall] = useState(0);
  const [closedCall, setClosedCall] = useState(0);
  const [inprocessCall, setInprocessCall] = useState(0);
  // const [hideIFA, setHideIFA] = useState(false);
  const activeRef = useRef(null);
  const [showKYC, setShowKYC] = useState(false);
  const closedRef = useRef(null);
  const inprocessRef = useRef(null);
  const [openConsentRequestModel, setOpenConsentRequestModel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [consentDisabled, setConsentDisabled] = useState(false);
  const [ifaID, setIfaID] = useState("");
  const [consentState, setConsentState] = useState(0);
  const [requestType, setRequestType] = useState(0);
  const dimensions = useWindowDimensions();
  const [showNomineeDetails, setShowNomineeDetails] = useState(false);
  const [fileType, setFileType] = useState("pdf");
  // new states
  const [isActiveDropdown, setIsActiveDropdown] = useState(false);
  const [year, setYear] = useState("");
  const [showVirtualAccountDetails, setShowVirtualAccountDetails] =
    useState(false);
  const [showAnnualIncomeStatement, setShowAnnualIncomeStatement] =
    useState(false);
  const [showBorrowerList, setshowBorrowerList] = useState(false);
  const [ctaLoader, setCtaLoader] = useState(false);
  const [showProvisionalCertifiate, setShowProvisionalCertifiate] =
    useState(false);
  const [openTransactionHistoryModel, setOpenTransactionHistoryModel] =
    useState(false);

  // function handleWindowSizeChange() {
  //   if (window.innerWidth <= 1080 || window.innerHeight <= 600) {
  //     setHideIFA(true);
  //     props.showCommonHeader(false);
  //     props.showCommonSidebar(false);
  //   } else {
  //     setHideIFA(false);
  //     props.showCommonHeader(true);
  //     props.showCommonSidebar(true);
  //   }
  // }

  // useEffect(() => {
  //   window.addEventListener("resize", handleWindowSizeChange);
  //   if (window.innerWidth <= 1080 || window.innerHeight <= 600) {
  //     setHideIFA(true);
  //     props.showCommonHeader(false);
  //     props.showCommonSidebar(false);
  //   }
  //   return () => {
  //     window.removeEventListener("resize", handleWindowSizeChange);
  //   };
  // }, []);

  // check if user login or not
  useEffect(() => {
    props.showCommonHeader(true);
    props.showCommonSidebar(true);
    let checkLogin = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        let status = localStorage.getItem("kyc_status");
        if (status != 2) {
          setIsLogin(false);
        } else {
          setIsLogin(true);
          await fetch_release_status();
          fetchClientOverview();
          await fetch_release_status();
        }
      } catch {
        setIsLogin(false);
      }
    };
    return checkLogin();
    // eslint-disable-next-line
  }, []);

  useEffect(async () => {
    try {
      let ifa_data = await Auth.currentCredentials();
      setIfaID(ifa_data.identityId);
    } catch (e) {
      console.log("e >>", e);
    }
  }, []);

  useEffect(() => {
    if (overviewData.consent_withdrawn) {
      setConsentState(3);
    } else if (
      overviewData.consent_status === 2 &&
      overviewData.soa_consent === 2
    ) {
      setConsentState(2);
    } else if (
      overviewData.consent_status === 1 ||
      overviewData.soa_consent === 1
    ) {
      setConsentState(1);
    } else {
      setConsentState(0);
    }
  }, [overviewData]);
  // if (hideIFA) {
  //   return <HideIFA />;
  // }

  const handleReportGeneration = async (state) => {
    if (!year && state === "annual_income_statement") {
      setCtaLoader(false);
      return errorToast("Please select Year.");
    }
    const payload = {
      investor_uuid: param.state.uuid,
      financial_year: year,
    };
    await reportAPI.annualIncomeReportRequest(payload).then((res) => {
      if (res.kind === "ok") {
        setFileType("pdf");
        eventLog({
          type: "requested_annualincome_statement",
          action: {
            trigger: "true",
          },
        });
        setCtaLoader(false);
        return successToast(
          "The Provisional Income Statement will be sent to your registered email ID within 1 hour."
        );
      } else {
        setFileType("pdf");
        setCtaLoader(false);
        errorToast(res.error);
      }
    });
  };

  useEffect(() => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    if (currentMonth === 3) {
      setShowProvisionalCertifiate(true);
    }
  }, []);

  // calling the app_realse api
  const fetch_release_status = async () => {
    await appReleaseApi.getAppReleaseData().then((response) => {
      if (response.kind == "ok") {
        const fp_status_info = {
          flag_status: response.data.freedom_plan_display,
          flag_note: response.data.note,
        };
        config.fp_flag_info = fp_status_info;
        config.pool_templates = Array.isArray(response.data.pool_templates)
          ? response.data.pool_templates
          : [response.data.pool_templates];
      }
    });
  };


  // const fetchPdf = async () => {
  //   try {
  //     const uri = 'https://www.irs.gov/pub/irs-pdf/f1040.pdf';
  //     const res = await fetch(uri)
  //     console.log(res.json())
  //   } catch (error) {
  //     console.log('Error fetching PDF:', error);
  //   }
  // };

  const fetchClientOverview = () => {
    // payload
    let payload = {
      client_id: param.state.uuid,
    };
    api
      .fetchClientSummary(payload)
      .then((res) => {
        if (res.kind === "ok") {
          setOverviewData(res.data);
          if (res.data.kyc_approved == 2) {
            setShowKYC(true);
          }
          setLoginRender(true);
        } else {
          setLoginRender(false);
        }
      })
      .catch((error) => {
        errorToast(
          "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
        );
      });
  };

  const maskNumber = (number, type) => {
    let numString = "";
    if (typeof number != "string") {
      numString = number.toString();
    } else {
      numString = number;
    }
    let lastFour = numString.slice(numString.length - 4);
    switch (type) {
      case "pan":
        return "XXXXXX" + lastFour;
      case "account_number":
        return "XXXX XXXX " + lastFour;
      case "ifsc_code":
        return "XXXXXXX" + lastFour;
      case "adhaar_number":
        return "XXXX XXXX " + lastFour;
    }
  };
  const cellBox = (key, value) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div className="virtual-cell-box">
          <div className="virtual-cell-box-header">{key}</div>
          <div className="virtual-cell-box-value">
            {key == "Account Number"
              ? convertAccountSpaces(String(value))
              : value}
          </div>
        </div>
        <ContentCopyIcon
          className="virtual-model-copy-icon"
          style={{ marginTop: "9px", color: "#585858", fontSize: "18px" }}
          onClick={async () =>
            await onClickReferralCode(value !== undefined ? value : "")
          }
        />
      </div>
    );
  };

  const cellBox2 = (key, value) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div className="virtual-cell-box2">
          <div className="virtual-cell-box-header-2">{key}</div>
          <div className="virtual-cell-box-value-2">{value}</div>
        </div>
      </div>
    );
  };

  const onClickReferralCode = async (keyValue) => {
    await navigator.clipboard.writeText(keyValue);
    successToast("Text copied to clipboard", false);
  };

  // request search function
  const requestSearch = (event) => {
    // if active search call
    if (screenName == "Active") {
      activeRef.current.handlerActiveSearch(event);
      eventLog({
        type: "active_investments ",
        action: {
          trigger: "true",
        },
      });
    }

    // if closed search call
    if (screenName == "Closed") {
      closedRef.current.handlerClosedSearch(event);
      eventLog({
        type: "closed_investments",
        action: {
          trigger: "true",
        },
      });
    }

    // if inprocess search call
    if (screenName == "In-process") {
      inprocessRef.current.handlerInprocessSearch(event);
      eventLog({
        type: "inprocess_investments",
        action: {
          trigger: "true",
        },
      });
    }
  };

  // if user is not login then redirect to login page
  if (isLogin === false) {
    return <Redirect to="/" />;
  }

  // if the parameter is undefined
  if (param.state === undefined) {
    return <Redirect to="/clients" />;
  }

  const handleClose = () => {
    setYear("");
    setShowVirtualAccountDetails(false);
    setShowNomineeDetails(false);
    setShowAnnualIncomeStatement(false);
    setshowBorrowerList(false);
  };

  const handleInvestmentConsent = async () => {
    setConsentDisabled(true);
    setLoading(true);
    let payload = {
      client_id: param.state.uuid,
      ifa_id: ifaID,
      status: 1,
      soa_status: 1,
    };
    await api.postClientInvestmentConsent(payload).then((res) => {
      eventLog({
        type: "send_investment_consent",
        action: {
          trigger: "true",
        },
      });
      if (res.kind === "ok") {
        eventLog({
          type: "sent_consent_mail",
          action: {
            trigger: "true",
          },
        });

        successToast("Consent request has been sent successfully");
        fetchClientOverview();
        setLoading(false);
        setOpenConsentRequestModel(false);
      } else {
        eventLog({
          type: "sent_consent_mail",
          action: {
            trigger: "false",
          },
        });
        setConsentDisabled(false);
        errorToast(res.error);
        setLoading(false);
        setOpenConsentRequestModel(false);
      }
    });
  };

  const showFreedomPlan =
    !config.fp_flag_info.flag_status &&
    overviewData.freedom_plan_invested_amount == 0
      ? false
      : true;

  return (
    <div>
      {/* show model */}
      <Modal
        open={showVirtualAccountDetails}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        scrool="paper"
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="virtual-model-div">
          {/* header */}
          <div className="virtual-model-header-div">
            <h1 className="virtual-model-header-text">
              Virtual Account Details
            </h1>
            <CloseIcon
              className="virtual-model-cross-icon"
              onClick={handleClose}
            />
          </div>
          <div className="virtual-model-body-div">
            <div className="virtual-model-inner-body-div">
              <div className="cellBox-parent-div">
                {cellBox(
                  "Account Number",
                  overviewData.virtual_account_data.vendor_escrow_ac_no
                )}
              </div>
              <div className="cellBox-parent-div">
                {cellBox(
                  "Account Holder Name",
                  overviewData.virtual_account_data.vendor_account_holder_name
                )}
              </div>
            </div>
            <div className="virtual-model-inner-body-div">
              <div className="cellBox-parent-div">
                {cellBox(
                  "Bank Name",
                  overviewData.virtual_account_data.vendor_bank
                )}
              </div>
              <div className="cellBox-parent-div">
                {cellBox(
                  "Account Type",
                  overviewData.virtual_account_data.vendor_account_type
                )}
              </div>
            </div>
            <div className="virtual-model-inner-body-div">
              <div className="cellBox-parent-div">
                {cellBox(
                  "IFSC",
                  overviewData.virtual_account_data.vendor_ifsc_code
                )}
              </div>
              <div className="cellBox-parent-div">
                {cellBox(
                  "Branch Name",
                  overviewData.virtual_account_data.vendor_branch
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={showNomineeDetails}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        scrool="paper"
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="virtual-model-div-2">
          {/* header */}
          <div className="virtual-model-header-div">
            <h1 className="virtual-model-header-text">Nominee Details</h1>
            <CloseIcon
              className="virtual-model-cross-icon"
              onClick={handleClose}
            />
          </div>
          <div className="virtual-model-body-div-margin2">
            <div className="virtual-model-inner-body-div">
              {overviewData
                ? overviewData.nominee
                  ? Object.entries(overviewData.nominee).map((title) => (
                      <div className="cellBox-parent-div" key={title[0]}>
                        {title[0] === "phone_number"
                          ? cellBox2("Mobile Number", title[1] ? title[1] : "-")
                          : title[0] === "pan_number"
                          ? cellBox2(
                              "PAN Number",
                              title[1] ? (
                                maskNumber(title[1], "pan")
                              ) : (
                                <div style={{ marginLeft: "26px" }}>-</div>
                              )
                            )
                          : title[0] == "relation"
                          ? cellBox2(
                              "Relationship",
                              title[1] ? (
                                title[1]
                              ) : (
                                <div style={{ margin: "5px" }}>-</div>
                              )
                            )
                          : title[0] == "name"
                          ? cellBox2(
                              "Name",
                              title[1] ? (
                                title[1]
                              ) : (
                                <div style={{ marginLeft: "26px" }}>-</div>
                              )
                            )
                          : cellBox2(
                              title[0],
                              title[1] ? (
                                title[1]
                              ) : (
                                <div style={{ marginLeft: "26px" }}>-</div>
                              )
                            )}
                      </div>
                    ))
                  : ""
                : ""}
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={showAnnualIncomeStatement}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        scrool="paper"
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="virtual-model-div-3">
          {/* header */}
          <div className="virtual-model-header-div-2">
            <h1 className="virtual-model-header-text">
              Annual Income Statement
            </h1>
            <CloseIcon
              className="virtual-model-cross-icon"
              onClick={handleClose}
            />
          </div>

          {showProvisionalCertifiate && (
            <div className="provisional-model-body-div-margin">
              <h2 className="provisional-model-header-text">
                Provisional Income Certificate <br />
                (FY 2023-2024)
              </h2>
              <button
                onClick={() => {
                  handleReportGeneration("income_statement");
                }}
                className="provisional_income_statement_button"
              >
                {ctaLoader ? (
                  <Loader
                    type="TailSpin"
                    color="#FFFFFF"
                    height={15}
                    width={15}
                  />
                ) : (
                  "Request PDF"
                )}
              </button>
            </div>
          )}
          <div className="annual-model-body-div-margin2">
            <h2 className="annual-model-header-text">
              Income Statement for earlier FYs
            </h2>
            <div className="annual-model-inner-body-div">
              <div className="selectDropDownannual">
                <Dropdown
                  setValue={setYear}
                  value={year}
                  isActive={isActiveDropdown}
                  setIsActive={setIsActiveDropdown}
                  dropdownList={[{ label: "FY 2023-24", value: "FY 2023-24" }]}
                  placeholder="Choose Statement Period"
                />
              </div>
              <div
                className="date_selection_column_div"
                style={{
                  textAlign: "right",
                }}
              >
                <button
                  onClick={() => {
                    setCtaLoader(true);
                    handleReportGeneration("annual_income_statement");
                  }}
                  className={
                    year
                      ? "active_ais_button annual_income_statement_button"
                      : "annual_income_statement_button"
                  }
                >
                  {ctaLoader ? (
                    <Loader
                      type="TailSpin"
                      color="#FFFFFF"
                      height={15}
                      width={15}
                    />
                  ) : (
                    "Request PDF"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      
      {loginRender === false ? (
        <ContentLoadingLoader />
      ) : (
        <div className="clientinvestment_div_container">
          <div className="clientinvestment_div_tablecontainer">
            {/* header */}
            <div className="clientinvestment_header_div">
              <div className="clientinvestment_header_button_div_container">
                <h1
                  className="clientinvestment_header_div_subtitle"
                  onClick={() => navigate.push("/clients")}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  Clients
                </h1>
                <ArrowForwardIosIcon
                  style={{
                    color: "#969494",
                    fontSize: "15px",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                  }}
                />
                <h1 className="clientinvestment_header_div_subtitle"
                style={{
                  cursor: "pointer",
                }}
                onClick={handleClose}>
                  {param.state.name}
                </h1>
                {showBorrowerList && <><ArrowForwardIosIcon
                  style={{
                    color: "#969494",
                    fontSize: "15px",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                  }}
                />
                <h1 className="clientinvestment_header_div_subtitle">
                  Borrower Mapping
                </h1></>}
              </div>
              <div className="clientinvestment_header_subject_div_container">
                <div className="clientinvestment_header_div_title_container">
                  <h1 className="clientinvestment_header_div_title">
                    {showBorrowerList ? "Borrower Mapping" : param.state.name}
                  </h1>
                </div>
                <div className="clientinvestment_header_div_buttons_container">
                  {/* Investment Consent */}
                  {consentState == 0 ? (
                    <div />
                  ) : (
                    <div
                      className="client_investment_consent_button"
                      style={{
                        height: "30px",
                        backgroundColor:
                          consentState < 1
                            ? "#edf4ff"
                            : consentState == 1
                            ? "#FFF0E1"
                            : consentState == 2
                            ? "#E7FFE3"
                            : consentState == 3
                            ? "#FFEBEB"
                            : "#edf4ff",
                        color:
                          consentState < 1
                            ? "#3a86ff"
                            : consentState == 1
                            ? "#E2730D"
                            : consentState == 2
                            ? "#2DAE18"
                            : consentState == 3
                            ? "#D32F2F"
                            : "#3a86ff",
                      }}
                      disabled={consentState || consentDisabled}
                    >
                      <div style={{ width: "auto" }}>
                        {consentState == 3
                          ? "Consent Withdrawn"
                          : consentState == 1
                          ? "Consent Pending"
                          : consentState == 2
                          ? "Consent Received"
                          : ""}
                      </div>
                    </div>
                  )}
                  {/* button */}
                  <div className="clientinvestment_header_button_div_container">
                    <ActionDropdown
                      title={"Client Details"}
                      width="200px"
                      items={[
                        {
                          label: "View KYC Details",
                          visibility: true,
                          action: () => {
                            navigate.push("/clients/kyc", {
                              uuid: param.state.uuid,
                              disable: true,
                            });
                            eventLog({
                              type: "view_kyc",
                              action: {
                                trigger: "true",
                              },
                            });
                          },
                        },
                        {
                          label: "Virtual Account Details",
                          visibility: showKYC,
                          action: () => {
                            setShowVirtualAccountDetails(true);
                          },
                        },
                        Object.keys(overviewData.nominee ?? {}).length != 0
                          ? {
                              label: "Nominee Details",
                              visibility: showKYC,
                              action: () => {
                                setShowNomineeDetails(true);
                              },
                            }
                          : {},
                        {
                          label: "Net Worth Certificate",
                          visibility: true,
                          action: () => {
                            if (param.state.net_worth === 0) {
                              navigate.push("/clients/networth", {
                                uuid: param.state.uuid,
                                disable: false,
                                net_worth: param.state.net_worth,
                              });
                              eventLog({
                                type: "upload_net_worth_certificate",
                                action: {
                                  trigger: "true",
                                },
                              });
                            } else if (param.state.net_worth === 1) {
                              navigate.push("/clients/networth", {
                                uuid: param.state.uuid,
                                disable: false,
                                net_worth: param.state.net_worth,
                              });
                              eventLog({
                                type: "net_worth_certificate_in_review",
                                action: {
                                  trigger: "true",
                                },
                              });
                            } else if (param.state.net_worth === 2) {
                              navigate.push("/clients/networth", {
                                uuid: param.state.uuid,
                                disable: false,
                                net_worth: param.state.net_worth,
                              });
                              eventLog({
                                type: "visit_net_worth_certificate_",
                                action: {
                                  trigger: "true",
                                },
                              });
                            }
                          },
                        },
                        {
                          label: "Annual Income Statement",
                          visibility: true,
                          action: () => {
                            setShowAnnualIncomeStatement(true);
                          },
                        },
                        {
                          label: "Borrower Mapping",
                          visibility: true,
                          action: () => {
                            setshowBorrowerList(true);
                          },

                          // action: () => {
                          //   navigate.push("/clients/mapping", {
                          //     uuid: param.state.uuid,
                          //     disable: true,
                          //   });
                          //   // eventLog({
                          //   //   type: "borrower_mapping",
                          //   //   action: {
                          //   //     trigger: "true",
                          //   //   },
                          //   // });
                          // },
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {showBorrowerList ? <BorrowerMapping /> :
          <div className="client_investment_summary">
            <ClientOverview
              clientUuid={param.state.uuid}
              filterValue={filterValue}
              param={param}
              overviewData={overviewData}
              overviewCall={overviewCall}
              setOpenTransactionHistoryModel={setOpenTransactionHistoryModel}
              setOpenConsentRequestModel={setOpenConsentRequestModel}
              setOverviewCall={(value) => setOverviewCall(value)}
              dimensions={dimensions}
              fetchAgain={() => {
                setLoginRender(false);
                fetchClientOverview();
              }}
            />
            <div className="client_investment_summary_templates">
              {overviewData.total_invested_amount ? (
                <div>
                  <div className="client_summary_toggle_container">
                    {config.pool_templates.map((item, index) => {
                      return item.type === 1 && !showFreedomPlan ? null : (
                        <div
                          className={
                            requestType == item.type
                              ? "client_summary_toggle_element_active"
                              : "client_summary_toggle_element"
                          }
                          id={item.type}
                          key={index}
                          onClick={() => setRequestType(item.type)}
                        >
                          {item.title}
                        </div>
                      );
                    })}
                  </div>
                  {requestType != 1 && (
                    <ClientGrowthPlan
                      key={requestType}
                      clientUuid={param.state.uuid}
                      growthPlanCall={growthPlanCall}
                      overviewData={overviewData}
                      setGrowthPlanCall={(value) => setGrowthPlanCall(value)}
                      selectedPoolType={requestType}
                      dimensions={dimensions}
                      walletBalance={overviewData.available_to_invest}
                      onInvestment={() => fetchClientOverview()}
                    />
                  )}
                  {/* {requestType == 2 && (
                    <ClientGrowthPlan
                      clientUuid={param.state.uuid}
                      growthPlanCall={growthPlanCall}
                      overviewData={overviewData}
                      setGrowthPlanCall={(value) => setGrowthPlanCall(value)}
                      selectedPoolType={2}
                      dimensions={dimensions}
                      walletBalance={overviewData.available_to_invest}
                      onInvestment={() => fetchClientOverview()}
                    />
                  )} */}
                  {requestType == 1 && showFreedomPlan && (
                    <ClientFreedomPlan
                      clientUuid={param.state.uuid}
                      overviewData={overviewData}
                      freedomPlanCall={freedomPlanCall}
                      setFreedomPlanCall={(value) => setFreedomPlanCall(value)}
                      dimensions={dimensions}
                      walletBalance={overviewData.available_to_invest}
                      onInvestment={() => fetchClientOverview()}
                      pool_type={requestType}
                    />
                  )}
                </div>
              ) : (
                <PoolTemplates
                  clientUuid={param.state.uuid}
                  walletBalance={overviewData.available_to_invest}
                  {...props}
                />
              )}
            </div>
          </div>}

          {/* Transaction History */}
          <TransactionHistoryModel
            openModel={openTransactionHistoryModel}
            handleClose={() => setOpenTransactionHistoryModel(false)}
            loading={loading}
            clientUuid={param.state.uuid}
            reportDeliveryData={overviewData.report_delivery_data}
            page={2}
          />

          {/* Consent request model */}
          <ConsentRequestModel
            openModel={openConsentRequestModel}
            handleClose={() => setOpenConsentRequestModel(false)}
            loading={loading}
            investmentConsent={overviewData.consent_status}
            soaConsent={overviewData.soa_consent}
            onProceed={() => handleInvestmentConsent()}
          />
        </div>
      )}
      <SampleToast />
    </div>
  );
};

export default ClientInvestment;
