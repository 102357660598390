import React, { useRef, useState, useEffect } from "react";
import Loader from "react-loader-spinner";

import "./signup-button.css"

export default function SignupButton(props) {
  return (
    <div className="signup_field_container" style={{display: props.hasOwnProperty("visibility") && !props.visibility ? "none" : "",}}>
      <button
        className={props.disabled?"signup_submit_container_disable":"signup_submit_container"}
        style={{
          cursor: props.disabled ? "not-allowed" : "pointer",
          zIndex: 0,
        }}
        onClick={(e)=>{
          e.preventDefault();
          props.onSubmit(e)
        }}
        disabled={props.disabled}
      >
        {props.loader ? (
          <Loader type="TailSpin" color="#fff" height={15} width={15} />
        ) : (
          props.text
        )}
      </button>
    </div>
  );
}
