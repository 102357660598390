import * as React from "react";
import "./add-new-client.css";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { eventLog } from "../../utils/firebase";

const option_text = {
  fontSize: 14,
  fontFamily: "Asap",
  fontWeight: "400",
  color: "#1c1c1c",
  padding: "7px",
  paddingLeft: "20px",
};

const menu_container = {
  width: 150,
  marginTop: 2,
  marginLeft: 20,
  borderRadius: 2,
};

export default function AddNewClient(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    eventLog({
      type: "add_new_client",
      action: {
        trigger: "true",
      },
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        style={{
          border: "1px solid #3a86ff",
        }}
        variant="contained"
      >
        {/* text */}
        <p className="clientlist_header_button_text">Add New Client</p>
        {/* Down arrow icon */}
        <KeyboardArrowDownIcon
          style={{
            color: "#FFFFFF",
            fontSize: "25px",
          }}
        />
      </Button>

      {/* add client options */}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          style: menu_container,
        }}
      >
        <MenuItem
          style={option_text}
          onClick={() => {
            setAnchorEl(null);
            props.sendLink();
            eventLog({
              type: "send_client_kyc_link",
              action: {
                trigger: "true",
              },
            });
          }}
        >
          Send KYC Link
        </MenuItem>
        <MenuItem
          style={option_text}
          onClick={() => {
            setAnchorEl(null);
            props.addClient();    
            eventLog({
              type: "add_client_kyc",
              action: {
                trigger: "true",
              },
            });  
          }}
        >
          Add Client KYC
        </MenuItem>
      </Menu>
    </div>
  );
}
