import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import {
  convertIntoDefaultState,
  convertIntoErrorState,
  errorToast,
  infoToast,
  successToast,
  checkValidPan,
  yearsBefore,
  getClientEntityAssociatedOrgDocuments,
  getCurrentAddressDocuments,
  getBeneficialAddressDocuments,
} from "../../utils/helper";
import {
  InputBox,
  InputBoxV2,
  UploadInputV2,
  UploadInputV3,
  StateDropdown,
} from "../../components/index.js";
import { orgDocumentState } from "../../constant/clientKycState";
import Checkbox from "@mui/material/Checkbox";
import "./clientkyc.css";
import config from "../../config/settings";

const EntityDocuments = (props) => {
  const [entityDocuments, setEntityDocuments] = useState(
    convertIntoDefaultState(orgDocumentState)
  );
  const [entityDocumentsError, setEntityDocumentsError] = useState(
    convertIntoErrorState(orgDocumentState)
  );
  const [documentsToRender, setDocumentsToRender] = useState([]);
  const [currentDocumentsToRender, setCurrentDocumentsToRender] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [maxDate, setMaxDate] = useState("");
  const board_resolution_link = config.documents.board_resolution_link;

  //beneficial owner current state
  const [beneficialOwner, setBeneficialOwner] = useState(1);

  const [beneficialDocumentsToRender, setBeneficialDocumentsToRender] =
    useState([]);

  // send profile value
  useEffect(() => {
    const { sDate, mDate } = yearsBefore(100);
    setStartDate(sDate);
    setMaxDate(mDate);
  }, []);

  useEffect(() => {
    setDocumentsToRender(
      getClientEntityAssociatedOrgDocuments(
        orgDocumentState,
        props.investorType
      )
    );

    //gets the address field to render
    setCurrentDocumentsToRender(getCurrentAddressDocuments(orgDocumentState));

    //gets beneficial owner documents to render
    setBeneficialDocumentsToRender(
      getBeneficialAddressDocuments(orgDocumentState)
    );
  }, []);

  const entityDocument = [
    "Udyam Registration Certificate",
    "Shop & Establishment Act License",
    "Shop & Establishment Certificate",
    "CST Certificate",
    "GST Certificate",
    "VAT Certificate",
    "Sales & Income Tax Returns",
    "IEC (Importer Exporter Code) License",
    "Complete Income Tax Returns",
  ];

  //entity document 1
  const [entityDocument1, setEntityDocument1] = useState({
    document_type: null,
    document_front: null,
  });

  //entity document 2
  const [entityDocument2, setEntityDocument2] = useState({
    document_type: null,
    document_front: null,
  });

  const maxAllowedDate = new Date();
  maxAllowedDate.setDate(maxAllowedDate.getDate() - 1);

  useEffect(() => {
    props.setEntityDocuments({
      ...entityDocuments,
      entity_document_1: {
        document_type: entityDocument1.document_type,
        document: entityDocument1.document_front,
      },
      entity_document_2: {
        document_type: entityDocument2.document_type,
        document: entityDocument2.document_front,
      },
      beneficial_owner_selected: beneficialOwner,
    });

    //remove error
    props.setErrorState({
      ...entityDocumentsError,
    });
  }, [entityDocuments, entityDocument1, entityDocument2, beneficialOwner]);

  //check for error
  useEffect(() => {
    if (props.errorState && props.errorState != null) {
      setEntityDocumentsError({
        ...entityDocumentsError,
        ...props.errorState,
      });
    }
  }, [props]);

  useEffect(() => {
    if (
      new Date().toISOString().slice(0, 10) ==
      new Date(entityDocuments["date_of_incorporation"])
        .toISOString()
        .slice(0, 10)
    ) {
      props.setErrorState({
        ...entityDocumentsError,
        date_of_incorporation: true,
      });
    }
  }, [entityDocuments["date_of_incorporation"]]);

  //add beneficial section on add more
  const addBenefcialComponent = () => {
    if (beneficialOwner < 6) {
      setBeneficialOwner((prevCount) => prevCount + 1);
    }
  };

  return (
    <div>
      {/* entity name */}
      {props.investorType == "sole_proprietorship" ? (
        <InputBoxV2
          label={"Entity Name"}
          id="entity_name"
          type={orgDocumentState["entity_name"].type}
          // disabled={true}
          autoCapitalize={true}
          value={entityDocuments["entity_name"]}
          setValue={setEntityDocuments}
          object={entityDocuments}
          required={orgDocumentState["entity_name"]["required"]}
          errorObject={entityDocumentsError}
          setErrorObject={setEntityDocumentsError}
          isError={entityDocumentsError["entity_name"]}
          errorText={
            !entityDocuments["entity_name"]
              ? "This field cannot be empty."
              : "Invalid Entity Name"
          }
          inputType={"string"}
        />
      ) : (
        <></>
      )}
      {/* Date of Incorporation. */}
      <InputBoxV2
        label={"Date of Incorporation"}
        id="date_of_incorporation"
        type={orgDocumentState["date_of_incorporation"].type}
        // disabled={true}
        maxLength="10"
        autoCapitalize={true}
        value={entityDocuments["date_of_incorporation"]}
        setValue={setEntityDocuments}
        object={entityDocuments}
        required={orgDocumentState["date_of_incorporation"]["required"]}
        errorObject={entityDocumentsError}
        setErrorObject={setEntityDocumentsError}
        isError={entityDocumentsError["date_of_incorporation"]}
        errorText={
          !entityDocuments["date_of_incorporation"]
            ? "This field cannot be empty."
            : new Date().toISOString().slice(0, 10) ==
              new Date(entityDocuments["date_of_incorporation"])
                .toISOString()
                .slice(0, 10)
            ? "Date of incorporation cannot be the same as today's date. Please enter a different date."
            : "Invalid Date of Incorporation."
        }
        max={maxAllowedDate.toISOString().slice(0, 10)}
        min={"1850-01-01"}
      />
      {props.investorType == "sole_proprietorship" ? (
        <>
          {/* dropdown entity document  */}
          <UploadInputV3
            id="entity_document_dropdown_1"
            label={"Entity Document 1"}
            dropDownArray={entityDocument.filter(
              (item) => item !== entityDocument2.document_type
            )}
            object={entityDocument1}
            value={entityDocument1}
            setValue={setEntityDocument1}
            imageSizeLimit={15} //required if note is provided
            idForDocumentFront={"entity_document_1"}
            isError={entityDocumentsError["entity_document_1"]}
            optional={true}
            note={
              "Upload image in JPG/JPEG/PNG/PDF format. Max. file size allowed is 15 MB."
            }
          />
          <UploadInputV3
            id="entity_document_dropdown_2"
            label={"Entity Document 2"}
            dropDownArray={entityDocument.filter(
              (item) => item !== entityDocument1.document_type
            )}
            object={entityDocument2}
            value={entityDocument2}
            setValue={setEntityDocument2}
            imageSizeLimit={15} //required if note is provided
            idForDocumentFront={"entity_document_2"}
            isError={entityDocumentsError["entity_document_2"]}
            optional={true}
            note={
              "Upload image in JPG/JPEG/PNG/PDF format. Max. file size allowed is 15 MB."
            }
          />{" "}
        </>
      ) : (
        <></>
      )}
      {documentsToRender.map((key, index) => {
        return (
          <UploadInputV2
            key={index}
            label={
              typeof orgDocumentState[key].label === "string"
                ? orgDocumentState[key].label
                : orgDocumentState[key].label[props.investorType]
            }
            id={key}
            optional={false}
            setValue={setEntityDocuments}
            object={entityDocuments}
            rightLabel={orgDocumentState[key].template}
            fileLink={orgDocumentState[key].template_link}
            required={orgDocumentState[key].required}
            imageSizeLimit={15} //required if note is provided
            note={
              "Upload image in JPG/JPEG/PNG/PDF format. Max. file size allowed is 15 MB."
            }
            errorObject={entityDocumentsError}
            setErrorObject={setEntityDocumentsError}
            isError={entityDocumentsError[key]}
          />
        );
      })}

      {/* BENEFICIAL OWNER'S DOCUMENT SECTION */}
      <div
        style={{
          display:
              props.investorType == "private_limited_company" ||
              props.investorType == "trust" ||
              props.investorType == "partnership_firm"
                ? ""
                : "none",
        }}
      >
        <h4 className="beneficial_owner_tile">Beneficial Owner's Kyc</h4>
        <p id="beneficial_owner_subtext">
          Please upload KYC documents of the Beneficial Owners, i.e, person with
          more 15 % stake
        </p>

        {[...Array(beneficialOwner)].map((_, outerIndex) => (
          <>
            {/* //horizontal line */}
            {outerIndex + 1 !== 1 ? (
              <hr
                style={{
                  display: props.totalDisable ? "none" : "block",
                  height: "1px",
                  border: 0,
                  borderTop: "1px solid #ccc",
                  margin: "1em 0",
                  padding: "0",
                }}
              />
            ) : null}

            <p id="beneficial_owner_subtitle">
              Beneficial Owner's {outerIndex + 1}
            </p>

            {beneficialDocumentsToRender.map((key, index) => {
              key = key.replace("1", outerIndex + 1);
              return (
                <UploadInputV2
                  id={key}
                  label={orgDocumentState[key].label}
                  required={true}
                  object={entityDocuments}
                  setValue={setEntityDocuments}
                  imageSizeLimit={15} //required if note is provided
                  note={orgDocumentState[key].note}
                  errorObject={entityDocumentsError}
                  setErrorObject={setEntityDocumentsError}
                  isError={entityDocumentsError[key]}
                  additionalPath={props.userID}
                  requiredFileTypes={orgDocumentState[key]?.requiredFileTypes}
                  acceptedFormat={orgDocumentState[key]?.acceptedFormat}
                />
              );
            })}
          </>
        ))}

        <button
          className="add_benefifcial_button"
          onClick={addBenefcialComponent}
        >
          Add more
        </button>
      </div>

      <h4 id="add_client_proprietor_detail_subtitle">Registered Address</h4>

      {/* current  address */}
      {currentDocumentsToRender.map((key, index) => {
        if (key.includes("state")) {
          return (
            <StateDropdown
              label={orgDocumentState[key].label}
              id={key}
              type={orgDocumentState[key].type}
              dataArray={config.state_array}
              maxLength="255"
              value={entityDocuments[key]}
              setValue={setEntityDocuments}
              object={entityDocuments}
              specialCharCheck={
                orgDocumentState[key].hasOwnProperty("specialCharCheck")
                  ? orgDocumentState[key].specialCharCheck
                  : false
              }
              required={orgDocumentState[key]["required"]}
              errorObject={entityDocumentsError}
              setErrorObject={setEntityDocumentsError}
              isError={entityDocumentsError[key]} //props.errorState.hasOwnProperty("address")?props.errorState.address:
              errorText={
                entityDocuments[key]
                  ? "Invalid State"
                  : "This field cannot be empty."
              }
            />
          );
        } else {
          return (
            <InputBoxV2
              key={key}
              label={orgDocumentState[key].label}
              id={key}
              type={orgDocumentState[key].type}
              maxLength={key.includes("pin") ? "6" : "255"}
              value={entityDocuments[key]}
              setValue={setEntityDocuments}
              specialCharCheck={
                orgDocumentState[key].hasOwnProperty("specialCharCheck")
                  ? orgDocumentState[key].specialCharCheck
                  : false
              }
              object={entityDocuments}
              required={orgDocumentState[key]["required"]}
              errorObject={entityDocumentsError}
              setErrorObject={setEntityDocumentsError}
              isError={entityDocumentsError[key]}
              errorText={
                entityDocuments[key]
                  ? `Invalid ${orgDocumentState[key].label}`
                  : "This field cannot be empty."
              }
            />
          );
        }
      })}

      {/* registered Address proof */}
      <UploadInputV2
        id="entity_address_proof"
        label={"Current Address Proof"}
        object={entityDocuments}
        setValue={setEntityDocuments}
        required={orgDocumentState["entity_address_proof"]["required"]}
        imageSizeLimit={15} //required if note is provided
        note={
          "Upload image in JPG/JPEG/PNG/PDF format. Max. file size allowed is 15 MB."
        }
        errorObject={entityDocumentsError}
        setErrorObject={setEntityDocumentsError}
        isError={entityDocumentsError["entity_address_proof"]}
      />
    </div>
  );
};

export default EntityDocuments;
