import * as React from "react";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import "../client-freedom-error-modal/client-freedom-error.css";

export default function FreedomPlanErrorModal(props) {
  function createMarkup(text) {
    return { __html: props.errorMessage };
  }

  return (
    <Modal
      open={props.openModel}
      onClose={props.handleClose}
      style={{
        backgroundColor: "rgba(102, 102, 102, 0.72)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className="client_freedom_plan_error_div">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            backgroundColor: "#f2f2f2",
          }}
        >
          <div></div>
          {/* HEADER */}
          <div
            style={{
              fontSize: 28,
              paddingTop: 20,
              paddingRight: 40,
              paddingBottom: 15,
            }}
          >
            <p
              style={{
                cursor: "pointer",
              }}
              onClick={props.handleClose}
            >
              <CloseIcon />
            </p>
          </div>
        </div>
        <div dangerouslySetInnerHTML={createMarkup()} style={{
           paddingLeft: 80,
           paddingRight: 80,
           paddingTop : 20,
        }} />
      </div>
    </Modal>
  );
}
