import React, { useEffect, useState } from "react";
import "./transactionRequests.css";
import { Redirect, useHistory } from "react-router-dom";
import { observer } from "mobx-react-lite";
import {
  SampleToast,
  FullScreenLoader,
  WithdrawalRequests,
  InvestmentRequests,
  IfaSwitchPlanRequest,
} from "../../components";
import WalletLinks from "../../components/Walletlinks/walletlinks";
import { Auth } from "aws-amplify";
import { kycStatus_API } from "../../services/api/kycstatus/kycstatus-api";
import {
  SwitchPlanAPI,
  User_API,
  WalletApi,
  WithdrawalHistory_Api,
} from "../../services/api";
import { errorToast } from "../../utils/helper";

const TransactionRequests = observer(function TransactionRequests(props) {
  const [isLogin, setIsLogin] = useState();
  const [loginRender, setLoginRender] = useState(false);
  const [render, setRender] = useState(false);
  const navigate = useHistory();
  const [loader, setLoader] = useState(false);
  const [requestType, setRequestType] = useState("investment_request");
  const user_api = new User_API();
  const wallet_api = new WalletApi();
  const withdrawal_api = new WithdrawalHistory_Api();
  const switch_plan_api = new SwitchPlanAPI();
  const [filterData, setFilterData] = useState([]);
  const [paymentData, setPaymentData] = useState([]);
  const [switchPlanFilterData, setSwitchPlanFilterData] = useState([]);
    const [switchPlanData, setSwitchPlanData] = useState([]);
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    props.showCommonHeader(true);
    //props.showCommonSidebar(true);
    let checkLogin = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        let status = localStorage.getItem("kyc_status");
        if (status != 2) {
          setIsLogin(false);
        }
        else
        {
        fetchIfakycStatus();
        }
      } catch {
        errorToast(
          "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@arthmatedirect.com"
        );
        setIsLogin(false);
        errorToast(
          "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@arthmatedirect.com"
        );
      }
    };
    return checkLogin();
    // eslint-disable-next-line
  }, []);

  // fetch ifa kyc status
  const fetchIfakycStatus = () => {
    setLoader(true);
    // const api = new kycStatus_API();

    // fetch data
    // api
    //   .getkycStatusData()
    //   .then((res) => {
    //     if (res.kind === "ok") {
        
    //     } else {
    //       setLoader(false);
    //       navigate.push("/");
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     setLoader(false);
    //     errorToast(
    //       "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@arthmatedirect.com"
    //     );
    //   });
    props.showCommonSidebar(true);
    setLoader(false);
    setIsLogin(true);
    setLoginRender(true);
  };
  // fetch the investment requests
  const fetchPaymentLinks = () => {
    user_api.getIFAPaymentsLinks().then((res) => {
      // console.log(res, "response payement investment")
      if (res.kind === "ok") {
        setPaymentData(res.data);
        setFilterData(res.data);
        setRender(true);
      } else {
        errorToast(res.error);
        console.log(res.error);
        setRender(true);
      }
    });
  };
  // fetch all wallet requests of IFA
  // fetch all wallet requests of IFA
  const fetchWalletRequests = () => {
    wallet_api.getWalletRequests().then((res) => {
      if (res.kind === "ok") {
        setPaymentData(res.data);
        setFilterData(res.data);
        setRender(true);
      } else {
        errorToast(res.error);
        console.log(res.error);
        setRender(true);
      }
    });
  };
  //fetch all withdrawal requests of IFA
  const fetchWithdrawalRequests = () => {
    withdrawal_api.getIfaWithdrawalHistory().then((res) => {
      if (res.kind === "ok") {
        setPaymentData(res.data);
        setFilterData(res.data);
        setRender(true);
      } else {
        errorToast(res.error);
        console.log(res.error);
        setRender(true);
      }
    });
  };
  //fetch all switch plan requests lists
  const fetchSwitchPlanRequestLists = () => {
    switch_plan_api.getSwitchPlansList().then((res) => {
      if (res.kind === "ok") {
        setSwitchPlanData(res.data);
        setSwitchPlanFilterData(res.data);
        setRender(true);
      } else {
        errorToast(res.error);
        console.log(res.error);
        setRender(true);
      }
    });
  };

  const handleToggle = (e) => {
    setRequestType(e.target.id);
  };

  useEffect(() => {
    setRender(false);
    setFilterData([]);
    setPaymentData([]);
    if (requestType === "investment_request") {
      fetchPaymentLinks();
    } else if (requestType === "wallet_loading_request") {
      fetchWalletRequests();
    } else if (requestType === "withdrawal_request") {
      fetchWithdrawalRequests();
    } else if (requestType === "switch_plan_request") {
      fetchSwitchPlanRequestLists();
    }
  }, [requestType]);

  useEffect(() => {
    if (clicked == true) {
      fetchPaymentLinks();
    }
  }, [clicked]);

  // if user is not login then redirect to login page
  if (isLogin === false) {
    return <Redirect to="/" />;
  }

  return (
    <div>
      {loginRender === false ? (
        <div />
      ) : (
        <div className="transaction_requests_div_container">
          {/* header */}
          <div className="transaction_requests_div_table_container">
            <div className="transaction_requests_header_div">
              {/* text */}
              <h1 className="transaction_requests_header_div_title">
                Transaction Requests
              </h1>
            </div>
            <div className="toggle_container">
              <div
                className={
                  requestType === "investment_request"
                    ? "toggle_element_active"
                    : "toggle_element"
                }
                id="investment_request"
                onClick={handleToggle}
              >
                Investment Requests
              </div>
              <div
                className={
                  requestType === "wallet_loading_request"
                    ? "toggle_element_active"
                    : "toggle_element"
                }
                id="wallet_loading_request"
                onClick={handleToggle}
              >
                Wallet Loading Requests
              </div>
              <div
                className={
                  requestType === "withdrawal_request"
                    ? "toggle_element_active"
                    : "toggle_element"
                }
                id="withdrawal_request"
                onClick={handleToggle}
              >
                Withdrawal Requests
              </div>
              <div
                className={
                  requestType === "switch_plan_request"
                    ? "toggle_element_active"
                    : "toggle_element"
                }
                id="switch_plan_request"
                onClick={handleToggle}
              >
                Switch Plan Requests
              </div>
            </div>
            <div>
              {requestType === "investment_request" ? (
                <InvestmentRequests
                  render={render}
                  filterData={filterData}
                  setFilterData={setFilterData}
                  paymentData={paymentData}
                  setPaymentData={setPaymentData}
                  clicked={clicked}
                  setClicked={setClicked}
                />
              ) : requestType === "wallet_loading_request" ? (
                <WalletLinks
                  render={render}
                  filterData={filterData}
                  setFilterData={setFilterData}
                  paymentData={paymentData}
                  setPaymentData={setPaymentData}
                />
              ) : requestType === "withdrawal_request" ? (
                <WithdrawalRequests
                  render={render}
                  filterData={filterData}
                  setFilterData={setFilterData}
                  paymentData={paymentData}
                  setPaymentData={setPaymentData}
                />
              ) : requestType === "switch_plan_request" ? (
                <IfaSwitchPlanRequest
                  render={render}
                  filterData={switchPlanFilterData}
                  setFilterData={setSwitchPlanFilterData}
                  paymentData={switchPlanData}
                  setPaymentData={setSwitchPlanData}
                  fetchAgain={() => {
                    setRender(false);
                    fetchSwitchPlanRequestLists();
                  }}
                />
              ) : null}
            </div>
          </div>
          {/* full screen loader */}
          {loader ? <FullScreenLoader /> : <div />}

          {/* toast container */}
          <SampleToast />
        </div>
      )}
    </div>
  );
});
export default TransactionRequests;
