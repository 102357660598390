import Loader from "react-loader-spinner";
import "./loader.css";
export default function LoaderScreen() {
  return (
    <div className="loader_screen_container">
      <Loader type="Oval" color="#3a86ff" height={120} width={120} />
      {/* <div className="loader_screen_text">Processing your payment....</div> */}
      <span style={{ marginTop:"50px" , textAlign: "center"}} className="loader_screen_text">Please stay on this page as your transaction is being processed. </span>
      <span className="loader_screen_subtext">Do not refresh or go back.</span>
    </div>
  );
}
