import React, { useState, useRef, useEffect } from "react";
import Loader from "react-loader-spinner";
import { Kyc_API } from "../../services/api";
import {
  errorToast,
  getFileExtension,
  getFrontendStateObject,
  getUniqueUUID,
  imageCompressor,
  successToast,
} from "../../utils/helper";
import InputInfoText from "../input-info-text/input-info-text";
import { upload_icon } from "../../assets/images/index";
import { TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { Auth, Storage } from "aws-amplify";

import "./upload-input-v3.css";

const { stage } = getFrontendStateObject();

const inputBox = {
  marginBottom: "12px",
  overflow: "hidden",
  borderRadius: "3px",
};

const inputStyle = {
  height: "45px",
  fontSize: "16px",
};

const menuItem = {
  fontSize: "16px",
};

const UploadInputV3 = (props) => {
  let acceptedFormat = props.requiredFileTypes
    ? props.requiredFileTypes
    : ["image/jpeg", "image/png", "image/jpg", "application/pdf"];
  let inputAcceptedFormat = props.acceptedFormat
    ? props.acceptedFormat
    : ".jpg, .jpeg, .png, .pdf";

  const [loader, setLoader] = useState(false);
  const [loader1, setLoader1] = useState(false);
  const fileRef1 = useRef(null);
  const fileRef2 = useRef(null);
  const imageSizeLimit = props.imageSizeLimit ? props.imageSizeLimit : 5;
  const api = new Kyc_API();

  const [fileName1, setFileName1] = useState("");
  const [fileName2, setFileName2] = useState("");
  const [file1Uploaded, setFile1Uploaded] = useState(false);
  const [file2Uploaded, setFile2Uploaded] = useState(false);
  const [docTypeSelected, setDocTypeSelected] = useState(false);

  useEffect(() => {
    if (
      props.object.hasOwnProperty("document_type") &&
      props.object.document_type !== null &&
      props.object.document_type !== ""
    ) {
      setDocTypeSelected(true);
    }
    if (
      props.object.hasOwnProperty("document_front") &&
      props.object.document_front !== null &&
      props.object.document_front !== "" &&
      typeof props.object.document_front === "string"
    ) {
      setFileName1(props.object.document_front);
      setFile1Uploaded(true);
    }
    if (
      props.object.hasOwnProperty("document_back") &&
      props.object.document_back !== null &&
      props.object.document_back !== "" &&
      typeof props.object.document_back === "string"
    ) {
      setFileName2(props.object.document_back);
      setFile2Uploaded(true);
    }
  }, [props]);

  // handle changes into input file
  const onHandleChange = async (e, field_name) => {
    const file = e.target.files[0];

    if (!acceptedFormat.includes(e.target.files[0].type)) {
      errorToast(`Please Upload Accepted File Format`, true);
      setLoader(false);
      return;
    }

    if (e.target.files[0].size > imageSizeLimit * 1024 * 1024) {
      errorToast(
        acceptedFormat.length !== 4
          ? `Invalid file format. Please upload a valid JPG,JPEG or PNG image under ${imageSizeLimit} MB.`
          : `Invalid file format. Please upload a valid JPG,JPEG,PDF or PNG image under ${imageSizeLimit} MB.`,
        true
      );
      setLoader(false);
    } else {
      if (field_name == "document_front") {
        setLoader(true);
      } else {
        setLoader1(true);
      }
      let compressedImage = e.target.files[0];

      if (compressedImage.type.includes("image")) {
        compressedImage = await imageCompressor(e.target.files[0]);
      }

      let uuid = await getUniqueUUID();
      let ext = await getFileExtension(e);
      // let fileNameUUID = `${uuid}.${ext}`;

      let reader = new FileReader(compressedImage);
      reader.readAsDataURL(compressedImage);
      reader.onload = function (evt) {
        //get file name
        let fieldName = field_name;
        let fileName;
        if (field_name == "document_front") {
          fileName = `${props.idForDocumentFront}.${ext}`;
        } else {
          fileName = `${props.idForDocumentBack}.${ext}`;
        }

        //upload file to s3
        uploadFile(fileName, compressedImage, compressedImage.type);

        //setValue
        props.setValue({
          ...props.object,
          [fieldName]: fileName,
        });

        //set file name
        if (field_name == "document_front") {
          setFileName1(fileName);
          setFile1Uploaded(true);
          setLoader(false);
        } else {
          setFileName2(fileName);
          setFile2Uploaded(true);
          setLoader1(false);
        }
      };
    }
  };

  const uploadFile = async (fileName, blob, type) => {
    let file;
    if (
      props.hasOwnProperty("additionalPath") &&
      props.additionalPath &&
      props.additionalPath.length != 0
    ) {
      file = `${props.additionalPath}/${fileName}`;
    } else {
      file = `${fileName}`;
    }

    await Storage.put(`${file}`, blob, {
      contentType: type,
      customPrefix: {
        private: `${stage}/private/`,
      },
      level: "private",
      progressCallback: (progress) => {
        if (progress) {
        }
      },
    })
      .then((res) => {
        console.log("res ::::", res);
        setLoader(false);
        // props.callback(props.id, fileName, true);
      })
      .catch((err) => {
        errorToast(`Failed to upload ${props.label}.Please Try again.`);
        setLoader(false);
      });
  };

  //handle file removale
  const removeFile = async (e, field_name) => {
    //set file name to empty

    if (field_name == "document_front") {
      setFileName1("");
    } else {
      setFileName2("");
    }

    //remove file from object
    props.setValue({
      ...props.object,
      [field_name]: null,
    });

    //set to upload file
    if (field_name == "document_front") {
      setFile1Uploaded(false);
    } else {
      setFile2Uploaded(false);
    }
  };

  //handle dropdown selection
  const handleChange = (e) => {
    let value = e.target.value;

    if (props.object) {
      props.setValue({
        ...props.object,
        ["document_type"]: value,
        document_front: null,
        document_back: null,
      });
      setFileName1("");
      setFileName2("");
      setFile1Uploaded(false);
      setFile2Uploaded(false);
      setDocTypeSelected(true);
    } else {
      props.setValue(value);
      setFileName1("");
      setFileName2("");
      setFile1Uploaded(false);
      setFile2Uploaded(false);
      setDocTypeSelected(true);
    }
  };

  return (
    <div
      style={{
        display:
          props.hasOwnProperty("visibility") && !props.visibility ? "none" : "",
      }}
    >
      <div className="upload_input_v3_main_contianer">
        {/* dropdown section */}
        <div className="input_box_field_container">
          <label
            className="input_box_v2_label_container"
            style={{ marginBottom: "10px" }}
          >
            {props.label}
            <span style={{ color: "red" }}> *</span>
          </label>
          <TextField
            disabled={props.disabled}
            select={!props.disabled ? true : false}
            style={{
              ...inputBox,
              backgroundColor: props.disabled ? "#ececec" : undefined,
              color: props.disabled ? "rgb(119, 119, 119)" : undefined,
              borderColor: props.isError ? "red" : "grey",
            }}
            sx={{
              fieldset: {
                border: props.value.document_type
                  ? "2px thin grey"
                  : props.isError
                  ? "2px solid red"
                  : "2px thin grey",
              },
            }}
            InputProps={{
              style: inputStyle,
            }}
            InputLabelProps={{
              shrink: false,
              sx: {
                fontSize: "16px",
                position: "absolute",
                right: "0",
                left: "0",
                top: "-3px",
                width: "80%", // Need to give it a width so the positioning will work
              },
            }}
            placeholder={props.label}
            value={
              props.value.document_type !== null
                ? props.value.document_type
                : ""
            }
            label={props.value.document_type ? "" : "Select"}
            onChange={handleChange}
          >
            {props.dropDownArray.map((item, index) => (
              <MenuItem key={index} style={menuItem} value={item}>
                {item}
              </MenuItem>
            ))}
          </TextField>
        </div>

        {docTypeSelected ? (
          <>
            <div>
              <span className="upload_input_v3_title">
                {props?.labelForDocumentFront
                  ? props?.labelForDocumentFront
                  : ""}
              </span>
              {!(props.hasOwnProperty("optional") && props.optional) ? (
                <span style={{ color: "red" }}> *</span>
              ) : (
                <></>
              )}

              {file1Uploaded ? (
                <>
                  <div className="upload_input_v3_file_container">
                    <span className="upload_input_v3_file_text">
                      {fileName1}
                    </span>
                    <p
                      onClick={(e) => {
                        removeFile(e, "document_front");
                      }}
                      className="upload_input_v3_remove_file"
                      style={{ display: props.disabled ? "none" : "" }}
                    >
                      Remove
                    </p>
                  </div>
                </>
              ) : (
                <>
                  <input
                    type="file"
                    id={
                      props.idForDocumentFront
                        ? props.idForDocumentFront
                        : props.id
                    }
                    ref={fileRef1}
                    disabled={props.disabled}
                    onChange={(e) => {
                      e.preventDefault();
                      onHandleChange(e, "document_front");
                    }}
                    accept={inputAcceptedFormat}
                    style={{ display: "none" }}
                  />
                  <button
                    className="upload_input_v3_main_container"
                    style={{
                      borderColor: props.isError ? "red" : "#3a86ff",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      fileRef1.current.click();
                    }}
                  >
                    {loader ? (
                      <Loader
                        type="TailSpin"
                        color="#3a86ff"
                        height={18}
                        width={18}
                      />
                    ) : (
                      <>
                        <img id="upload_input_v2_icon" src={upload_icon}></img>
                        <span className="upload_input_v2_text"> Upload </span>
                      </>
                    )}
                  </button>
                </>
              )}
            </div>
            <div className="upload_input_v3_info_text_container">
              {/* info text */}
              {!(props.hasOwnProperty("raw") && props.raw) ? (
                props.hasOwnProperty("note") && props.note ? (
                  <InputInfoText text={props.note} />
                ) : (
                  <InputInfoText
                    text={`Note: The uploaded image file should be in jpg/jpeg/png format (the maximum 
                file size allowed is ${
                  props?.imageSizeLimit ? props?.imageSizeLimit : imageSizeLimit
                } MB.`}
                  />
                )
              ) : (
                ""
              )}
            </div>

            {props.hasOwnProperty("idForDocumentBack") &&
            props.idForDocumentBack ? (
              <>
                <div>
                  <span className="upload_input_v3_title">
                    {props?.labelForDocumentBack
                      ? props?.labelForDocumentBack
                      : "Back Side"}
                  </span>
                  {/* {!(props.hasOwnProperty("optional") && props.optional) ? (
                    <span style={{ color: "red" }}> *</span>
                  ) : (
                    <></>
                  )} */}

                  {file2Uploaded ? (
                    <>
                      <div className="upload_input_v3_file_container">
                        <span className="upload_input_v3_file_text">
                          {fileName2}
                        </span>
                        <p
                          onClick={(e) => {
                            removeFile(e, "document_back");
                          }}
                          className="upload_input_v3_remove_file"
                          style={{ display: props.disabled ? "none" : "" }}
                        >
                          Remove
                        </p>
                      </div>
                    </>
                  ) : (
                    <>
                      <input
                        type="file"
                        id={
                          props.idForDocumentBack
                            ? props.idForDocumentBack
                            : props.id
                        }
                        ref={fileRef2}
                        disabled={props.disabled}
                        onChange={(e) => {
                          e.preventDefault();
                          onHandleChange(e, "document_back");
                        }}
                        accept={inputAcceptedFormat}
                        style={{ display: "none" }}
                      />
                      <button
                        className="upload_input_v2_main_container"
                        onClick={(e) => {
                          e.preventDefault();
                          fileRef2.current.click();
                        }}
                      >
                        {loader1 ? (
                          <Loader
                            type="TailSpin"
                            color="#3a86ff"
                            height={18}
                            width={18}
                          />
                        ) : (
                          <>
                            <img
                              id="upload_input_v2_icon"
                              src={upload_icon}
                            ></img>
                            <span className="upload_input_v2_text">
                              {" "}
                              Upload{" "}
                            </span>
                          </>
                        )}
                      </button>
                    </>
                  )}
                </div>
                <div className="upload_input_v3_info_text_container">
                  {/* info text */}
                  {!(props.hasOwnProperty("raw") && props.raw) ? (
                    props.hasOwnProperty("note") && props.note ? (
                      <InputInfoText text={props.note} />
                    ) : (
                      <InputInfoText
                        text={`Note: The uploaded image file should be in jpg/jpeg/png format (the maximum 
                file size allowed is ${
                  props?.imageSizeLimit ? props?.imageSizeLimit : imageSizeLimit
                } MB.`}
                      />
                    )
                  ) : (
                    ""
                  )}
                </div>
              </>
            ) : null}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default UploadInputV3;
