import * as React from "react";
import "./client_otp_model_v2.css";
import Loader from "react-loader-spinner";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";

export default function ClientOTPModelV2(props) {
  // prevent only digit
  const onKeyPress = (e) => {
    const re = /[^0-9]/g;
    if (re.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <Modal
      open={props.openModel}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.699)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className="client_otpenter_model_v2_div">
        <div className="client_otpenter_model_v2_header">
          <p className="client_otpenter_model_v2_header_title">
            {props.hasOwnProperty("headerTitle")
              ? props.headerTitle
              : "OTP sent to client"}
          </p>

          <div
            style={{ float: "right", cursor: "pointer" }}
            onClick={props.handleClose}
          >
            <p>
              <CloseIcon />
            </p>
          </div>
        </div>

        <div className="client_otpenter_model_v2_body">
          <div className="client_otpenter_model_v2_title_div">
            <p
              className="client_otpenter_model_v2_title"
              style={{
                paddingTop:
                  props.hasOwnProperty("hideHeader") && props.hideHeader
                    ? "0px"
                    : "12px",
              }}
            >
              {!props.hasOwnProperty("hideHeader") ||
              (props.hasOwnProperty("hideHeader") && !props.hideHeader)
                ? "The client will receive an email with the Terms & Conditions. Once they accept the Terms & Conditions, we'll send an OTP to their registered mobile number."
                : null}
            </p>
          </div>

          {/* amount input */}
          <input
            type="text"
            placeholder={props.hasOwnProperty("inputPlaceHolder")
            ? props.inputPlaceHolder
            : "Enter OTP"}
            maxLength={6}
            className="client_otpenter_model_v2_input"
            onKeyPress={onKeyPress}
            onChange={(event) => {
              props.setOtp(event.target.value);
            }}
            style={{
              marginTop:
                props.hasOwnProperty("hideHeader") && props.hideHeader
                  ? "0px"
                  : "20px",
            }}
          />

          {/* send button */}
          <button
            className="client_otpenter_model_v2_button"
            onClick={props.onSubmit}
          >
            {props.loading ? (
              <Loader type="TailSpin" color="#fff" height={15} width={15} />
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </div>
    </Modal>
  );
}
