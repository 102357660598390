import { API } from "aws-amplify";
import * as Types from "../api.types";

/**
 * Manages all requests to the API.
 */
export class Kyc_API {
  /**
   * @description Method to validate pan number
   * @param data
   * @returns success or faliure
   */
  async checkPanNumber(data: any): Promise<Types.GetKycResult> {
    // payload
    const params = {
      body: data,
    };

    // call the api try and catch
    try {
      const response: any = await API.post("baseurl", "/users/kyc/pan", params);
      return { kind: "ok", data: response.data || response.message || {} };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description Method to validate aadhaar card number
   * @param data
   * @returns success or faliure
   */
  async validateAadhaarCard(data: any): Promise<Types.GetKycResult> {
    // payload
    const params = {
      body: data,
    };

    // call the api try and catch
    try {
      const response: any = await API.post(
        "baseurl",
        "/users/kyc/aadhaar",
        params
      );
      return { kind: "ok", data: response.data || response.message || {} };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description Method to verify aadhaar card number
   * @param data
   * @returns success or faliure
   */
  async verifyAadhaarCard(data: any): Promise<Types.GetKycResult> {
    // payload
    const params = {
      body: data,
    };

    // call the api try and catch
    try {
      const response: any = await API.put(
        "baseurl",
        "/users/kyc/aadhaar",
        params
      );
      return { kind: "ok", data: response.data || response.message || {} };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description Method to validate ifsc code and account number
   * @param data
   * @returns success or faliure
   */
  async validateIfscAndAccount(data: any): Promise<Types.GetKycResult> {
    // payload
    const params = {
      body: data,
    };

    // call the api try and catch
    try {
      const response: any = await API.post(
        "baseurl",
        "/users/kyc/penny_drop",
        params
      );
      return { kind: "ok", data: response.data || response.message || {} };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description Method to fetch data from gst
   * @param data
   * @returns success or faliure
   */
  async gstCheckApi(data: any): Promise<Types.GetGstDetails> {
    // payload
    const params = {
      body: data,
    };

    // call the api try and catch
    try {
      const response: any = await API.post(
        "baseurl",
        "/users/kyc/gst_number",
        params
      );
      return { kind: "ok", data: response.data || response.message || {} };
    } catch (err: any) {
      if (err) {
        console.log("err for gstcheck api", err);
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description Method to fetch data from ocr
   * @param data
   * @returns success or faliure
   */
  async ocrCheckApi(data: any): Promise<Types.GetKycResult> {
    // payload
    const params = {
      body: data,
    };

    // call the api try and catch
    try {
      const response: any = await API.post("baseurl", "/users/kyc/ocr", params);
      return { kind: "ok", data: response.data || response.message || {} };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  async nameMatchApi(data: any): Promise<Types.verifyNameMatching> {
    // payload
    const params = {
      body: data,
    };

    // call the api try and catch
    try {
      const response: any = await API.post(
        "baseurl",
        "/users/kyc/name_matching",
        params
      );
      return { kind: "ok", data: response.data || response.message || {} };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }
}
