import React, { useState, useRef, useEffect } from "react";
import Loader from "react-loader-spinner";
import { Kyc_API } from "../../services/api";
import { Auth, Storage } from "aws-amplify";
import {
  errorToast,
  getFileExtension,
  getFrontendStateObject,
  getUniqueUUID,
  imageCompressor,
  successToast,
} from "../../utils/helper";
import { upload_icon } from "../../assets/images/index";
import InputInfoText from "../input-info-text/input-info-text";
import "./upload-input-v2.css";

const { stage } = getFrontendStateObject();

const UploadInputV2 = (props) => {
  const [loader, setLoader] = useState(false);
  const fileRef = useRef(null);

  const imageSizeLimit = props.imageSizeLimit ? props.imageSizeLimit : 5;

  const [fileName, setFileName] = useState("");
  const [fileUploaded, setFileUploaded] = useState(false);
  const api = new Kyc_API();

  let acceptedFormat = props.requiredFileTypes
    ? props.requiredFileTypes
    : ["image/jpeg", "image/png", "image/jpg", "application/pdf"];
  let inputAcceptedFormat = props.acceptedFormat
    ? props.acceptedFormat
    : ".jpg, .jpeg, .png, .pdf";

  useEffect(() => {
    if (
      props.object[props.id] &&
      props.object[props.id] !== "" &&
      typeof props.object[props.id] === "string"
    ) {
      setFileUploaded(true);
      setFileName(props.object[props.id]);
    }
  }, [props]);

  // handle changes into input file
  const onHandleChange = async (e) => {
    const file = e.target.files[0];
    setLoader(true);

    if (!acceptedFormat.includes(e.target.files[0].type)) {
      errorToast(`Please Upload Accepted File Format`, true);
      setLoader(false);
      return;
    }

    if (e.target.files[0].size > imageSizeLimit * 1024 * 1024) {
      errorToast(
        acceptedFormat.length !== 4
          ? `Invalid file format. Please upload a valid JPG,JPEG or PNG image under ${imageSizeLimit} MB.`
          : `Invalid file format. Please upload a valid JPG,JPEG,PDF or PNG image under ${imageSizeLimit} MB.`,
        true
      );
      setLoader(false);
    } else {
      let compressedImage = e.target.files[0];

      if (compressedImage.type.includes("image")) {
        compressedImage = await imageCompressor(e.target.files[0]);
      }

      // let uuid = await getUniqueUUID();
      let ext = await getFileExtension(e);

      let reader = new FileReader(compressedImage);
      reader.readAsDataURL(compressedImage);
      reader.onload = function (evt) {
        //get file name
        let fieldName = props.id;

        let fileName = `${props.id}.${ext}`;

        //upload file to s3
        uploadFile(fileName, compressedImage, compressedImage.type);

        //setValue
        props.setValue({
          ...props.object,
          [fieldName]: fileName,
        });

        props.setErrorObject({
          ...props.errorObject,
          [props.id]: false,
        });

        //set file name
        setFileName(fileName);
        setFileUploaded(true);
        setLoader(false);
      };
    }
  };

  //handle file removale
  const removeFile = async (e) => {
    //set file name to empty
    setFileName("");

    //remove file from object
    props.setValue({
      ...props.object,
      [props.id]: null,
    });

    //set to upload file
    setFileUploaded(false);
  };

  const uploadFile = async (fileName, blob, type) => {
    let file;
    if (
      props.hasOwnProperty("additionalPath") &&
      props.additionalPath &&
      props.additionalPath.length != 0
    ) {
      file = `${props.additionalPath}/${fileName}`;
    } else {
      file = `${fileName}`;
    }

    await Storage.put(`${file}`, blob, {
      contentType: type,
      customPrefix: {
        private: `${stage}/private/`,
      },
      level: "private",
      progressCallback: (progress) => {
        if (progress) {
        }
      },
    })
      .then((res) => {
        setLoader(false);
        // props.callback(props.id, fileName, true);
      })
      .catch((err) => {
        errorToast(`Failed to upload ${props.label}.Please Try again.`);
        setLoader(false);
      });
  };

  return (
    <div
      style={{
        display:
          props.hasOwnProperty("visibility") && !props.visibility ? "none" : "",
      }}
    >
      <div
        className="upload_input_v2_main_contianer"
        style={{
          marginBottom:
            props.hasOwnProperty("showAttemptLeft") && props.showAttemptLeft
              ? "0px"
              : "20px",
        }}
      >
        <span className="upload_input_v2_title">{props.label}</span>
        {props.hasOwnProperty("required") && props.required == true ? (
          <span style={{ color: "red" }}> *</span>
        ) : (
          <></>
        )}

        {props.hasOwnProperty("rightLabel") && props.rightLabel ? (
          <span style={{ float: "right" }}>
            <a
              href={props.fileLink}
              target="_blank"
              style={{
                color: "#3A86FF",
                fontFamily: "Poppins",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
                cursor: "pointer",
                display: "inline",
                padding: 0,
                border: "none",
              }}
            >
              {props.rightLabel}
            </a>
          </span>
        ) : null}

        <div className="upload_input_v2_container">
          {fileUploaded ? (
            <div className="upload_input_v2_file_container">
              <span className="upload_input_v2_file_text">
                {fileName === "" ? props.object[props.id] : fileName}
              </span>
              <p
                onClick={removeFile}
                className="upload_input_v2_remove_file"
                style={{ display: props.disabled ? "none" : "" }}
              >
                Remove
              </p>
            </div>
          ) : (
            <>
              <input
                type="file"
                id={props.id}
                ref={fileRef}
                disabled={props.disabled}
                onChange={(e) => {
                  e.preventDefault();
                  onHandleChange(e);
                }}
                accept={inputAcceptedFormat}
                style={{ display: "none" }}
              />
              <button
                className="upload_input_v2_main_container"
                style={{
                  borderColor: props.isError
                    ? "red"
                    : props.disabled
                    ? "#494949"
                    : "3a86ff",
                  borderWidth: props.disabled ? "1px" : "2px",
                  backgroundColor: props.disabled ? "#EDEDED" : "#ffffff",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  fileRef.current.click();
                }}
              >
                {loader ? (
                  <Loader
                    type="TailSpin"
                    color="#3a86ff"
                    height={18}
                    width={18}
                  />
                ) : (
                  <>
                    <img id="upload_input_v2_icon" src={upload_icon}></img>
                    <span
                      className="upload_input_v2_text"
                      style={{ color: props.disabled ? "#494949" : "3a86ff" }}
                    >
                      {" "}
                      Upload{" "}
                    </span>
                  </>
                )}
              </button>
            </>
          )}
        </div>

        <div className="upload_input_v2_info_text_container">
          {/* info text */}
          {!(props.hasOwnProperty("raw") && props.raw) ? (
            props.hasOwnProperty("note") && props.note ? (
              <InputInfoText text={`Note : ${props.note}`} />
            ) : (
              <InputInfoText
                text={`Note: The uploaded image file should be in jpg/jpeg/png/pdf format (the maximum 
                file size allowed is ${imageSizeLimit} MB.`}
              />
            )
          ) : (
            ""
          )}
        </div>
      </div>
      {/* 
      {props.hasOwnProperty("showAttemptLeft") && props.showAttemptLeft ? (
        <p
          style={{
            marginBottom: "20px",
            marginTop: "-5px",
            textAlign: "left",
            fontSize: "14px",
            color: "grey",
          }}
        >
          Attempts left: {props.attemptLeft}
        </p>
      ) : (
        ""
      )} */}
    </div>
  );
};

export default UploadInputV2;
