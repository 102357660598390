import React, { useState, useRef, useEffect } from "react";
import Loader from "react-loader-spinner";
import { Kyc_API } from "../../services/api";
import { Auth, Storage } from "aws-amplify";
import {
  errorToast,
  getFileExtension,
  getFrontendStateObject,
  getUniqueUUID,
  imageCompressor,
  successToast,
} from "../../utils/helper";
import InputInfoText from "../input-info-text/input-info-text";

import "./upload-input-pan.css";

const { stage } = getFrontendStateObject();
const imageSizeLimit = 15;
let acceptedFormat = ["image/jpeg", "image/png", "image/jpg"];

const UploadInputPan = (props) => {
  const [loader, setLoader] = useState(false);
  const fileRef = useRef(null);

  const api = new Kyc_API();

  // handle changes into input file
  const onHandleChange = async (e) => {
    const type = e.target.files[0].type;

    if (e.target.files[0].size > imageSizeLimit * 1024 * 1024) {
      errorToast(`Invalid file format. Please upload a valid JPEG or PNG image under ${imageSizeLimit} MB.`);
      setLoader(false);
    } else {
      setLoader(true);
      let compressedImage = e.target.files[0];

      if (!(props.hasOwnProperty("raw") && props.raw)) {
        if (!acceptedFormat.includes(type)) {
          errorToast(`Invalid file format. Please upload a valid JPEG or PNG image under ${imageSizeLimit} MB.`);
          setLoader(false);
          return;
        }

        compressedImage = await imageCompressor(e.target.files[0]);
      }

      let uuid = await getUniqueUUID();
      let ext = await getFileExtension(e);
      let fileName = `${uuid}.${ext}`;

      let reader = new FileReader(compressedImage);
      reader.readAsDataURL(compressedImage);
      reader.onload = function (evt) {
        ocrImageCheck(evt, fileName, compressedImage, type);
      };
    }
  };

  // verify image with ocr
  const ocrImageCheck = async (evt, fileName, blob, type) => {
    if (props.hasOwnProperty("shouldRunOcr") && props.shouldRunOcr) {
      const user = await Auth.currentCredentials();

      // payload
      let payload = {
        field_type: props.sourceType,
        base64: evt.target.result,
        loan_app_id: user.identityId,
      };

      // call the api
      api
        .ocrCheckApi(payload)
        .then(async (res) => {
          if (res.kind == "ok") {
            let data = res.data;
            console.log(fileName,blob,type,data,"checkk");
            uploadFile(fileName, blob, type, data);
          } else {
            setLoader(false);
            errorToast(res.error);
            props.setAttemptLeft(props.attemptLeft - 1);
          }
        })
        .catch((error) => {
          setLoader(false);
          errorToast(`Could not validate ${props.label}.`);
          props.setAttemptLeft(props.attemptLeft - 1);
        });
    } else {
      uploadFile(fileName, blob, type);
    }
  };

  const uploadFile = (fileName, blob, type, ocrResponse) => {
    let ext=fileName.split(".");
    let extension=ext[ext.length-1];
    if (props.hasOwnProperty("uploadFileToS3") && props.uploadFileToS3) {
      Storage.put(
        `${
          props.hasOwnProperty("additionalPath") &&
          props.additionalPath &&
          props.additionalPath.length != 0
            ? props.additionalPath
            : ""
        }`+"panImage."+extension,
        blob,
        {
          contentType: type,
          customPrefix: {
            private: `${stage}/private/`,
          },
          level: "private",
          progressCallback: (progress) => {
            if (progress) {
            }
          },
        }
      )
        .then((res) => {
          setLoader(false);
          props.callback(ocrResponse,extension, props.id, fileName, true);
        })
        .catch((err) => {
          errorToast(`Failed to upload ${props.label}.Please Try again.`);
          setLoader(false);
        });
    } else {
      setLoader(false);
      props.callback(ocrResponse, props.id, fileName, false);
    }
  };

  return (
    <div
      style={{
        display:
          props.hasOwnProperty("visibility") && !props.visibility ? "none" : "",
        paddingTop:"16px",
      }}
    >
      <div
        className="upload_input_main_contianer"
        style={{
          marginBottom:
            props.hasOwnProperty("showAttemptLeft") && props.showAttemptLeft
              ? "0px"
              : "20px",
        }}
      >
        <span className="upload_input_title">{props.label}</span>
        {!(props.hasOwnProperty("optional") && props.optional) ? (
          <span style={{ color: "red" }}> *</span>
        ) : (
          <></>
        )}

        <div className="upload_input_container">
          <div
            className={
              props.isFileUploaded
                ? "upload_input_uploaded"
                : "upload_input_upload"
            }
          >
            <label for="file-input">
              <span className="file_placeholder">
                {props.fileName ? `${props.fileName}` : ` `}
              </span>
            </label>
          </div>
          <button
            className="upload_input_button"
            style={{
              cursor:
                props.hasOwnProperty("attemptLeft") && props.attemptLeft == 0
                  ? "not-allowed"
                  : "pointer",
              color:
                props.hasOwnProperty("attemptLeft") && props.attemptLeft == 0
                  ? "#a9a9a9"
                  : "#3a86ff",
              borderColor:
                props.hasOwnProperty("attemptLeft") && props.attemptLeft == 0
                  ? "#a9a9a9"
                  : "#3a86ff",
            }}
            onClick={(e) => {
              e.preventDefault();
              fileRef.current.click();
            }}
            disabled={
              props.hasOwnProperty("attemptLeft") && props.attemptLeft == 0
                ? true
                : false
            }
          >
            {loader ? (
              <Loader type="TailSpin" color="#3a86ff" height={18} width={18} />
            ) : props.fileName && props.fileName != "" ? (
              "Change"
            ) : (
              "Upload"
            )}
          </button>
          <input
            type="file"
            id={props.id}
            ref={fileRef}
            disabled={props.disabled}
            onChange={(e) => {
              e.preventDefault();
              onHandleChange(e);
            }}
            accept="image/*,.pdf"
            style={{ display: "none" }}
          />
        </div>
        <div className="upload_input_info_text_container">
          {/* info text */}
          {!(props.hasOwnProperty("raw") && props.raw) ? (
            <InputInfoText
              text={`Please upload the image in jpeg/png format. The maximum file size should not exceed ${imageSizeLimit} MB.`}
            />
          ) : (
            ""
          )}
        </div>
      </div>

      {props.hasOwnProperty("showAttemptLeft") && props.showAttemptLeft ? (
        <p
          style={{
            marginBottom: "20px",
            marginTop: "-5px",
            textAlign: "left",
            fontSize: "14px",
            color: "grey",
          }}
        >
          Attempts left: {props.attemptLeft}
        </p>
      ) : (
        ""
      )}
    </div>
  );
};

export default UploadInputPan;
