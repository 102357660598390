import React, { useRef, useState, useEffect } from "react";
import "./resend-otp.css";

const  ResendOtp = (props)=> {

  useEffect(() => {
    let myTime = setInterval(() => {
      if (props.resendOtpCount <= (props.maxOtpResendLimit-1) && props.secondLeft > 0) {
        props.setSecondLeft(props.secondLeft - 1);
      }
      if (props.secondLeft === 0) {
        clearInterval(myTime);
      }
    }, 1000);
    return () => {
      clearInterval(myTime);
    };
  });

  return (
    <React.Fragment>
      <label
        onClick={props.resendOtp}
        className="resend_otp_container"
        style={{
          display:props.resendOtpCount > 0  && props.secondLeft === 0 ? "flex" : "none",
          color: "#3a86ff",
          marginLeft: "5px",
          fontWeight: "500",
          cursor: "pointer",
          fontSize: "14px",
        }}
      >
        Resend
      </label>

      <p
        className="resend_otp_label_container"
        style={{
          display: props.secondLeft > 0 ? "flex" : "none",
          color: "#1c1c1c",
          marginLeft: "auto",
          marginRight: 0,
          fontWeight: "500",
          fontSize: "14px",
          alignSelf: "left",
        }}
      >
        00:{props.secondLeft < 10 ? `0${props.secondLeft}` : props.secondLeft}
      </p>
    </React.Fragment>
  );
}

export default ResendOtp;
