import React, { useEffect, useState } from "react";
import "./clientinvestmentcertificate.css";
import { API, Auth } from "aws-amplify";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Loader from "react-loader-spinner";
import DownloadIcon from "@mui/icons-material/Download";
import { Client_API } from "../../services/api";
import {
  convertAmount,
  errorToast,
  sorting,
  warningToast,
} from "../../utils/helper";
import { LabelWithSortComponent } from "../../components";

const tableContainer = {
  marginTop: "20px",
  borderTopLeftRadius: "5px",
  borderTopRightRadius: "5px",
  border: "1px solid #c7c7c7",
  alignSelf: "center",
  overflow: "hidden",
};

const tableHeader = {
  fontSize: 16,
  fontFamily: "Asap",
  fontWeight: "600",
  color: "#060606",
  backgroundColor: "#f6f6f6",
  width: 200,
  height: "25px",
};

const tableRow = {
  fontSize: 14,
  fontFamily: "Asap",
  fontWeight: "400",
  color: "#1F1F1F",
  height: "25px",
  border: "none",
};

const genericMsg =
  "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in";

const ClientInvestmentCertificate = (props) => {
  const navigate = useHistory();
  const param = useLocation();
  const api = new Client_API();
  const [loginRender, setLoginRender] = useState(false);
  const [isLogin, setIsLogin] = useState();
  const [investmentCertificate, setInvestmentCertificate] = useState([]);
  const [loaderIndex, setLoaderIndex] = useState(-1);
  const [downloadLoader, setDownloadLoader] = useState(false);
  const rowsPerPage = 10;
  const [page, setPage] = useState(0);
  const [totalCertificate, setTotalCertificate] = useState(null);
  const [searchByPlan, setSearchByPlan] = useState("");
  const asc_label_sort = {
    created_at: false,
    investment_amount: false,
  };
  const [asc, setAsc] = useState(asc_label_sort);
  const [selected, setSelected] = useState("created_at");
  const [selectedType, setSelectedType] = useState("timestamp");

  // check if user login or not
  useEffect(() => {
    props.showCommonHeader(true);
    props.showCommonSidebar(true);
    let checkLogin = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        let status = localStorage.getItem("kyc_status");
        if (status != 2) {
          setIsLogin(false);
        } 
        else
        {
        setIsLogin(true);
        fetchClientInvestmentCertificateList(1, searchByPlan);
        }
      } catch {
        setIsLogin(false);
      }
    };
    return checkLogin();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isLogin) {
      setPage(0);
      fetchClientInvestmentCertificateList(1, searchByPlan);
    }
  }, [asc]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      requestSearch();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchByPlan]);

  // fetch client investment certificate list
  const fetchClientInvestmentCertificateList = (page, search) => {
    api
      .getClientInvestmentCertificateList(
        param.state.uuid,
        page,
        rowsPerPage,
        search,
        selected,
        asc[selected]
      )
      .then((res) => {
        if (res.kind === "ok") {
          if (res.meta && res.meta.pagination && res.meta.pagination.count) {
            setTotalCertificate(res.meta.pagination.count);
          }
          setInvestmentCertificate(res.data);
          setLoginRender(true);
        } else {
          setLoginRender(false);
        }
      })
      .catch((error) => {
        console.log(
          "error while fetching investment certificate list ::: ",
          error
        );
        errorToast(genericMsg);
        setLoginRender(false);
      });
  };

  // handle request search
  const requestSearch = () => {
    setPage(0);
    fetchClientInvestmentCertificateList(1, searchByPlan);
  };

  // handle page change function
  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
    fetchClientInvestmentCertificateList(newPage, searchByPlan);
  };

  // amount key press handler
  const onKeyPress = (e) => {
    const re = /[^0-9\.]/g;
    if (re.test(e.key)) {
      e.preventDefault();
    }
  };

  // convert base64 to blob
  const base64toBlob = (base64Data, contentType) => {
    contentType = contentType || "";
    var sliceSize = 1024;
    var byteCharacters = atob(base64Data);
    var bytesLength = byteCharacters.length;
    var slicesCount = Math.ceil(bytesLength / sliceSize);
    var byteArrays = new Array(slicesCount);

    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      var begin = sliceIndex * sliceSize;
      var end = Math.min(begin + sliceSize, bytesLength);
      var bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  };

  // downlaod all files of user
  const downloadAllFiles = async () => {
    // get all file key
    const all_file_key = investmentCertificate.map((e) => {
      return e.invest_cert_file;
    });

    // get all uuid and pool type
    const all_uuid = investmentCertificate.map((e) => {
      let obj = {
        uuid: e.uuid,
        pool_type: e.pool_type,
      };
      return obj;
    });

    // define request info and url
    const endPoint = `/ifa/clients/${param.state.uuid}/investment-certificate`;
    const requestInfo = {
      body: {
        file_key: all_file_key,
        all_uuid: all_uuid,
        mode: "zip",
      },
    };

    // call the api
    try {
      const res = await API.post("baseurl", endPoint, requestInfo);

      // if res code is 200
      if (res.code == 200) {
        const blob = base64toBlob(res.data, "application/zip");
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "InvestmentCertificate.zip";
        link.click();
        setDownloadLoader(false);
      }

      // else res code is not 200
      else {
        errorToast(res.message);
        setDownloadLoader(false);
      }
    } catch (error) {
      console.log("found error while downloading single pdf file :::: ", error);
      errorToast(genericMsg);
      setDownloadLoader(false);
    }
  };

  // downlaod single file
  const downloadSingleFile = async (row) => {
    // define request info and url
    const endPoint = `/ifa/clients/${row.user_uuid}/investment-certificate`;
    const requestInfo = {
      body: {
        file_key: row.invest_cert_file,
        uuid: row.uuid,
        pool_type: row.pool_type,
      },
    };

    // call the api
    try {
      const res = await API.post("baseurl", endPoint, requestInfo);

      // if res code is 200
      if (res.code == 200) {
        const blob = base64toBlob(res.data, "application/pdf");
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "InvestmentCertificate.pdf";
        link.click();
        setLoaderIndex(-1);
      }

      // else res code is not 200
      else {
        errorToast(res.message);
        setLoaderIndex(-1);
      }
    } catch (error) {
      console.log("found error while downloading single pdf file :::: ", error);
      errorToast(genericMsg);
      setLoaderIndex(-1);
    }
  };

  // if user is not login then redirect to login page
  if (isLogin === false) {
    return <Redirect to="/" />;
  }

  // if the parameter is undefined
  if (param.state === undefined) {
    return <Redirect to="/clients" />;
  }

  return (
    <div>
      {loginRender === false ? (
        <div />
      ) : (
        <div className="clientinvestmentcertificate_div_container">
          <div className="clientinvestmentcertificate_div_tablecontainer">
            {/* header */}
            <div className="clientinvestmentcertificate_header_div">
              <div className="clientinvestmentcertificate_header_div_title_container">
                <div className="clientinvestmentcertificate_header_button_div_container">
                  <h1
                    className="clientinvestmentcertificate_header_div_subtitle"
                    onClick={() => navigate.push("/clients")}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    Clients
                  </h1>
                  <ArrowForwardIosIcon
                    style={{
                      color: "#969494",
                      fontSize: "15px",
                      paddingLeft: "5px",
                      paddingRight: "5px",
                    }}
                  />
                  <h1
                    className="clientinvestmentcertificate_header_div_subtitle"
                    onClick={() =>
                      navigate.push("/clients/investments", {
                        name: param.state.name,
                        uuid: param.state.uuid,
                        net_worth: param.state.net_worth,
                      })
                    }
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    {param.state.name}
                  </h1>
                </div>
                <h1 className="clientinvestmentcertificate_header_div_title">
                  Investment Certificates
                </h1>
              </div>
            </div>

            {/* upper container */}
            <div className="clientinvestmentcertificate_download_all_container">
              {/* search_bar */}
              <div className="clientinvestmentcertificate_search_bar_div">
                <div className="clientinvestmentcertificate_search_bar">
                  {/* icon */}
                  <SearchIcon
                    style={{
                      fontSize: "20px",
                      paddingRight: "10px",
                      color: "#555555",
                    }}
                  />
                  {/* input box */}
                  <input
                    type="text"
                    placeholder="Search by amount"
                    onKeyPress={onKeyPress}
                    className="clientinvestmentcertificate_search_bar_input"
                    value={searchByPlan}
                    onChange={(e) => {
                      setSearchByPlan(e.target.value);
                    }}
                  />
                </div>
              </div>

              {/* download all button */}
              <div
                className="clientinvestmentcertificate_download_all_button"
                onClick={() => {
                  if (!downloadLoader) {
                    setDownloadLoader(true);
                    downloadAllFiles();
                  }
                }}
              >
                {downloadLoader ? (
                  <Loader type="TailSpin" color="#fff" height={22} width={22} />
                ) : (
                  <p className="clientinvestmentcertificate_download_all_button_text">
                    Download All
                  </p>
                )}
              </div>
            </div>

            {/* table */}
            <div className="clientinvestmentcertificate_table_container">
              <TableContainer style={tableContainer}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" style={tableHeader}>
                        <LabelWithSortComponent
                          label="Investment Date | Time"
                          id="created_at"
                          type="timestamp"
                          asc={asc}
                          selected={selected}
                          setAsc={setAsc}
                          setSelected={setSelected}
                          setSelectedType={setSelectedType}
                        />
                      </TableCell>
                      <TableCell align="left" style={tableHeader}>
                        Investment Plan
                      </TableCell>
                      <TableCell align="left" style={tableHeader}>
                        <LabelWithSortComponent
                          label="Investment Amount"
                          id="investment_amount"
                          type="number"
                          asc={asc}
                          selected={selected}
                          setAsc={setAsc}
                          setSelected={setSelected}
                          setSelectedType={setSelectedType}
                        />
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          ...tableHeader,
                          borderRight: "none",
                        }}
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {investmentCertificate.map((row, index) => {
                      return (
                        <TableRow
                          key={row.uuid}
                          style={{
                            backgroundColor:
                              index % 2 === 0 ? "#fafafa" : "#fff",
                          }}
                        >
                          <TableCell style={tableRow} align="left">
                            {row.investment_datetime}
                          </TableCell>
                          <TableCell style={tableRow} align="left">
                            {row.investment_plan}
                          </TableCell>
                          <TableCell style={tableRow} align="left">
                            {`₹ ${convertAmount(row.investment_amount)}`}
                          </TableCell>
                          <TableCell style={tableRow} align="left">
                            {loaderIndex == index ? (
                              <Loader
                                type="TailSpin"
                                color="#000"
                                height={15}
                                width={15}
                              />
                            ) : (
                              <DownloadIcon
                                className="download-icon"
                                onClick={() => {
                                  if (loaderIndex == -1) {
                                    setLoaderIndex(index);
                                    downloadSingleFile(row);
                                  } else {
                                    warningToast(
                                      "Please wait while the file is being downloaded."
                                    );
                                  }
                                }}
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                {/* if no data available */}
                {investmentCertificate.length === 0 ? (
                  <div className="no_row_found_div">
                    <FindInPageIcon
                      style={{
                        fontSize: "100px",
                        color: "#555555",
                      }}
                    />
                    <p className="no_row_found">No row found</p>
                  </div>
                ) : (
                  <div />
                )}
              </TableContainer>
              {/* pagination */}
              <div className="pagination_div_client_v2">
                <Pagination
                  color="standard"
                  count={Math.ceil(totalCertificate / rowsPerPage)}
                  page={page + 1}
                  variant="outlined"
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                  onChange={handleChangePage}
                  shape="rounded"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClientInvestmentCertificate;
