import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { useStores } from "../../models";
import { observer } from "mobx-react-lite";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { ContentLoadingLoader,LabelWithSortComponent } from "../../components";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import { WithdrawalHistory_Api } from "../../services/api/WithdrawalHistory/WithdrawalHistoryApi";
import WithdrawalHistoryFunctionCss from "./withdrawalHistoryFunctionCss.css";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import {
  errorToast,
  convertAmount,
  sorting,
  successToast,
  getFormattedDate,
} from "../../utils/helper";

const tableContainer = {
  marginTop: "20px",
  width: "92%",
  borderTopLeftRadius: "5px",
  borderTopRightRadius: "5px",
  borderTop: "1px solid #c7c7c7",
  borderLeft: "1px solid #c7c7c7",
  borderRight: "1px solid #c7c7c7",
  alignSelf: "center",
  overflow: "none",
};

const tableHeader = {
  backgroundColor: "#f6f6f6",
  textAlign: "start",
  fontWeight: "600",
  fontSize: 16,
  fontFamily: "Asap",
  color: "#1f1f1f",
  width: "fit-content",
  // border:"1px solid blue",
  paddingTop: "16px",
  paddingBottom: "16px",
  paddingLeft: "10px",
  paddingRight: "5px",

  borderRight: "1px solid #c7c7c7",
};

const tableRow = {
  fontSize: 14,
  fontFamily: "Asap",
  fontWeight: "400",
  color: "#1c1c1c",
  borderRight: "1px solid #c7c7c7",
  paddingTop: "10px",
  paddingLeft: "10px",
  paddingRight: "2px",
  paddingBottom: "10px",
};

const WithdrawalHistoryFunction = observer(function Clientlist(props) {
  const withdrawalHistoryApi = new WithdrawalHistory_Api();
  const rootStore = useStores();
  const navigate = useHistory();

  const param = useLocation();

  const [isLogin, setIsLogin] = useState();
  const [loginRender, setLoginRender] = useState(false);
  const [render, setRender] = useState(false);
  const [clientData, setClientData] = useState([]);
  const [page, setPage] = useState(0);
  const rowsPerPage = 25;

  //   console.log(param.state.uuid, "uuid")

  const asc_label_sort = {
    name: true,
    amount: true,
    created_at: false,
    updated_at: false,
    status: false,
  };

  const [asc, setAsc] = useState(asc_label_sort);
  const [selected, setSelected] = useState("created_at");
  const [selectedType, setSelectedType] = useState("timestamp");


  // if user is not login then redirect to login page

  // check if user login or not
  useEffect(() => {
    // getHistory()
    props.showCommonHeader(true);
    props.showCommonSidebar(true);
    let checkLogin = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        let status = localStorage.getItem("kyc_status");
        if (status != 2) {
          setIsLogin(false);
        }
        else
        {
        setIsLogin(true);
        setLoginRender(true);
        }
        if (param.state !== undefined) {
          if (param.state.uuid !== "") {
            //   fetchKycData();
            getHistory();
          } else {
            // setPreData(sampleData);
            setRender(true);
            //   console.log("in 1st else")
          }
        } else {
          // setPreData(sampleData);
          setRender(true);
          // console.log("in second else")
        }
      } catch {
        setIsLogin(false);
      }
    };
    return checkLogin();
    // eslint-disable-next-line
  }, []);

  //   console.log(clientData,"clientdata")

  const getHistory = () => {
    // console.log("here in get history")

    let payload = {
      user_id: param.state.uuid,
    };
    rootStore.withdrawalHistory.getWithdrawalHistory(payload).then((res) => {
      if (res.kind === "ok") {
        // console.log(res.data, "response")
        setClientData(rootStore.withdrawalHistory.withdrawalHistory);

        // setRender(false)
      } else {
        console.log(res.error, "error");
      }
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  if (isLogin === false) {
    return <Redirect to="/" />;
  }

  return (
    <div>
      {loginRender == false ? (
        <div />
      ) : (
        <div className="withdrawalHistory_div_container">
          <div>
            {render == true ? (
              <ContentLoadingLoader />
            ) : (
              <div className="withdrawalHistory_div_tablecontainer">
                <div className="withdrawalHistory_header_div">
                  <div className="withdrawalHistory_header_div_title_container">
                    <div className="withdrawalHistory_header_button_div_container">
                      <h1
                        className="withdrawalHistory_header_div_subtitle"
                        onClick={() => navigate.push("/clients")}
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        Clients
                      </h1>
                      <ArrowForwardIosIcon
                        style={{
                          color: "#969494",
                          fontSize: "15px",
                          paddingLeft: "5px",
                          paddingRight: "5px",
                        }}
                      />
                      <h1 className="withdrawalHistory_header_div_subtitle">
                        {param.state.name}
                      </h1>
                    </div>
                    <h1 className="withdrawalHistory_header_div_title">
                      Withdrawal History of {param.state.name}
                    </h1>
                  </div>
                </div>

                {/* table */}
                <TableContainer style={tableContainer}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="left"
                          padding="none"
                          style={tableHeader}
                        >
                          <LabelWithSortComponent
                            label="Name"
                            id="name"
                            type="string"
                            asc={asc}
                            selected={selected}
                            setAsc={setAsc}
                            setSelected={setSelected}
                            setSelectedType={setSelectedType}
                          />
                        </TableCell>

                        <TableCell
                          align="left"
                          padding="none"
                          style={tableHeader}
                        >
                          <LabelWithSortComponent
                            label="Amount"
                            id="amount"
                            type="number"
                            asc={asc}
                            selected={selected}
                            setAsc={setAsc}
                            setSelected={setSelected}
                            setSelectedType={setSelectedType}
                          />
                        </TableCell>
                        <TableCell
                          align="left"
                          padding="none"
                          style={tableHeader}
                        >
                          <LabelWithSortComponent
                            label="Created At"
                            id="created_at"
                            type="timestamp"
                            asc={asc}
                            selected={selected}
                            setAsc={setAsc}
                            setSelected={setSelected}
                            setSelectedType={setSelectedType}
                          />
                        </TableCell>
                        <TableCell
                          align="left"
                          padding="none"
                          style={tableHeader}
                        >
                          <LabelWithSortComponent
                            label="Updated at"
                            id="updated_at"
                            type="timestamp"
                            asc={asc}
                            selected={selected}
                            setAsc={setAsc}
                            setSelected={setSelected}
                            setSelectedType={setSelectedType}
                          />
                        </TableCell>
                        <TableCell
                          align="left"
                          padding="none"
                          style={tableHeader}
                        >
                          <LabelWithSortComponent
                            label="Status"
                            id="status"
                            type="string"
                            asc={asc}
                            selected={selected}
                            setAsc={setAsc}
                            setSelected={setSelected}
                            setSelectedType={setSelectedType}
                          />
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {clientData
                        .slice()
                        .sort((a,b)=> asc[selected] ? sorting(a,b,selected,selectedType,"asc") : sorting(a,b,selected,selectedType,"desc"))
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => (
                          <TableRow
                            key={row.uuid}
                            style={{
                              backgroundColor:
                                index % 2 === 0 ? "#fafafa" : "#fff",
                            }}
                          >
                            <TableCell style={tableRow} align="left">
                              <p
                                className="withdrawalHistory_client_name"
                                // onClick={() =>
                                //   navigate.push("/clients/investments", {
                                //     name: row.name,
                                //     uuid: row.uuid,
                                //     net_worth: row.net_worth,
                                //   })
                                // }
                              >
                                {row.name}
                              </p>
                            </TableCell>
                            {/* <TableCell style={tableRow} align="left">
                              {row.mobile}
                            </TableCell> */}
                            <TableCell style={tableRow} align="left">
                              {`₹ ${convertAmount(row.amount)}`}
                            </TableCell>
                            <TableCell style={tableRow} align="left">
                              {row.created_at}
                            </TableCell>
                            <TableCell style={tableRow} align="left">
                              {row.updated_at}
                            </TableCell>
                            <TableCell
                              style={{
                                ...tableRow,
                                borderRight: "none",
                              }}
                              align="left"
                            >
                              <p
                                className={
                                  row.status === 0
                                    ? "withdrawalHistory_status_text_0"
                                    : "withdrawalHistory_status_text_1"
                                }
                              >
                                {row.action}
                              </p>
                            </TableCell>
                            {/* <TableCell style={tableRow} align="left">
                              {convertAmount(row.received_amount+ row.available_funds)}
                            </TableCell> */}
                            {/* {console.log(row,"row")} */}
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                  {/* if no data available */}
                  {clientData.length === 0 ? (
                    <div className="no_row_found_div">
                      <FindInPageIcon
                        style={{
                          fontSize: "100px",
                          color: "#555555",
                        }}
                      />
                      <p className="no_row_found">No row found</p>
                    </div>
                  ) : (
                    <div />
                  )}
                </TableContainer>

                {/* pagination */}
                <div className="pagination_div">
                  <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    count={clientData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
});

export default WithdrawalHistoryFunction;
