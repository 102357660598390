import { types } from "mobx-state-tree";
import { OpenPoolStoreModel } from "../Pool/open-pool-store/open-pool-store";
import { ClientlistStoreModel } from "../Clientlist/client-list-store/client-list-store";
import { UserDataStoreModel } from "../User/user-data-store/user-data-store";
import { GetPoolByIdStoreModel } from "../PoolById/get-pool-by-id-store/get-pool-by-id-store";
import {WithdrawalHistoryStoreModel} from '../WithdrawalHistory/withdrawal-history-store/withdrawal-history-store'

/**
 * A RootStore model.
 */
export const RootStoreModel = types.model("RootStore").props({
  userData: types.optional(UserDataStoreModel, {}),
  clientList: types.optional(ClientlistStoreModel, {}),
  openPool: types.optional(OpenPoolStoreModel, {}),
  getPoolById: types.optional(GetPoolByIdStoreModel, {}),
  withdrawalHistory:types.optional(WithdrawalHistoryStoreModel,{})
});

export const useStores = () => RootStoreModel.create();
