import React, { useEffect, useState } from "react";
import "./clientkyc.css";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Loader from "react-loader-spinner";
import { Auth } from "aws-amplify";
import {
  convertIntoDefaultState,
  convertIntoErrorState,
  checkValidEmail,
  checkValidMobileNumber,
  errorToast,
  infoToast,
  successToast,
  checkValidPan,
  getAge,
  yearsBefore,
  convertInvestorType,
} from "../../utils/helper";
import { InputBox, InputBoxV2 } from "../../components/index.js";
import { clientKycBasicDetail } from "../../constant/clientKycState";
import config from "../../config/settings";
import { KYC_LINK_API_V2, User_API } from "../../services/api";

const ClientKycProfile = function (props) {
  const [startDate, setStartDate] = useState("");
  const [maxDate, setMaxDate] = useState("");
  const [addClientBasicDetails, setAddClientBasicDetails] = useState(
    props.basicKycDetails
  );
  const [update, setUpdate] = useState(false);
  const [addClientBasicDetailsError, setAddClientBasicDetailsError] = useState(
    convertIntoErrorState(clientKycBasicDetail)
  );
  const [invalidGender, setInvalidGender] = useState(false);
  const [invalidInvestorCategory, setInvalidInvestorCategory] = useState(false);
  const [ctaLoader, setCtaLoader] = useState(false);
  const api = new KYC_LINK_API_V2();
  const userApi = new User_API();

  var mail_format =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  // send profile value
  useEffect(() => {
    const { sDate, mDate } = yearsBefore(100);
    setStartDate(sDate);
    setMaxDate(mDate);
  }, []);


  useEffect(() => {
    if (update) {
      props.setClientKycDetails(addClientBasicDetails);
      setUpdate(false)
    }
  }, [update]);

  // set investor Category
  useEffect(() => {
    setAddClientBasicDetails({
      ...addClientBasicDetails,
      investor_type: props.investorType,
      referral_code: props.ifa_referral_code,
    });
  }, [props]);

  useEffect(() => {
    if (!mail_format.test(addClientBasicDetails["email_id"])) {
      setAddClientBasicDetailsError({
        ...addClientBasicDetailsError,
        email_id: true,
      });
    }
  }, [addClientBasicDetails["email_id"]]);

  const onSubmit = async () => {
    setCtaLoader(true);
    if (
      props.investorType === "" ||
      props.investorType === "Individual or Sole Proprietorship"
    ) {
      // errorToast("Please Select Investor Category.", true);
      setInvalidInvestorCategory(true);
      setCtaLoader(false);
      return;
    }
    // check for date of birth
    else if (
      !addClientBasicDetails["dob"] &&
      addClientBasicDetails["investor_type"] == "Individual"
    ) {
      setAddClientBasicDetailsError({
        ...addClientBasicDetailsError,
        dob: true,
      });
      setCtaLoader(false);
      return;
      // check for age
    } else if (
      getAge(addClientBasicDetails["dob"]) < 18 &&
      addClientBasicDetails["investor_type"] == "Individual"
    ) {
      errorToast("The age of the investor must be more than 18 years.", true);
      setCtaLoader(false);
      return;
    } else if (getAge(addClientBasicDetails["dob"]) > getAge(startDate)) {
      // errorToast("Invalid Date of Birth.", true);
      setAddClientBasicDetailsError({
        ...addClientBasicDetailsError,
        dob: true,
      });

      setCtaLoader(false);
      return;
    }
    //check for gender
    else if (
      !addClientBasicDetails["gender"] &&
      addClientBasicDetails["investor_type"] == "Individual"
    ) {
      // errorToast("Please Select Gender.", true);
      setInvalidGender(true);
      setCtaLoader(false);
      return;
    } else if (
      (addClientBasicDetails["investor_type"] == "private_limited_company" ||
        addClientBasicDetails["investor_type"] == "public_limited_company") &&
      !addClientBasicDetails["company_type"]
    ) {
      setAddClientBasicDetailsError({
        ...addClientBasicDetailsError,
        company_type: true,
      });
      setCtaLoader(false);
      return;
    }
    // call the pan check api
    await Auth.signOut()
      .then(() => {
        Auth.signIn(addClientBasicDetails["pan_number"])
          .then(async (response) => {
            Auth.sendCustomChallengeAnswer(response, config.dummy_otp)
              .then(async (res) => {
                const newUser = await Auth.currentCredentials();
                addClientBasicDetails.user_id = newUser.identityId;
                const body = {
                  mobile: addClientBasicDetails["mobile_number"],
                  newUserId: newUser.identityId,
                  isIfa: false,
                };

                if (
                  addClientBasicDetails["company_type"] != null &&
                  addClientBasicDetails["company_type"] != ""
                ) {
                  addClientBasicDetails["investor_type"] =
                    addClientBasicDetails["company_type"];
                }
                props.setUseridValue(body.newUserId);
                await userApi
                  .updateAndDeleteDummyId(body)
                  .then(async (res) => {
                    if (res.kind == "ok") {
                      if (
                        addClientBasicDetails["investor_type"] ==
                        "sole_proprietorship"
                      ) {
                        addClientBasicDetails["investor_type"] = "Sole Proprietorship";
                      } else if (addClientBasicDetails == "trust") {
                        addClientBasicDetails["investor_type"] = "Trust";
                      } else if (addClientBasicDetails == "huf") {
                        addClientBasicDetails["investor_type"] = "HUF";
                      } else if (addClientBasicDetails == "partnership_firm") {
                        addClientBasicDetails["investor_type"] = "Partnership Firm";
                      }
                      await api
                        .userRegistration(addClientBasicDetails)
                        .then((res) => {
                          if (res.kind == "ok") {
                            const data = res.data;
                            if (data.is_ckyc == 0) {
                              props.setCkyc(false);
                              props.setProfileCompleted(true);
                              setCtaLoader(false);
                            } else {
                              setCtaLoader(false);
                              props.setCkyc(true);
                              props.setProfileCompleted(true);
                            }
                            props.setBasicKycDetails({
                              ...addClientBasicDetails,
                              user_id: data.user_id,
                              investor_type: convertInvestorType(
                                data.investor_type
                              ),
                            });
                          } else {
                            errorToast(res.error, true);
                            setCtaLoader(false);
                            return;
                          }
                        });
                    } else {
                      errorToast(
                        "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
                      );
                      setCtaLoader(false);
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                    setCtaLoader(false);
                  });
              })
              .catch((error) => {
                console.log(error);
                setCtaLoader(false);
              });
          })
          .catch((error) => {
            console.log("Error while creating user ::: ", error);
            errorToast(
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
            );
            setCtaLoader(false);
          });
      })
      .catch((err) => {
        errorToast(
          "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
        );
        setCtaLoader(false);
      });
  };

  return (
    <div>
      <InputBoxV2
        label={"Mobile Number"}
        id="mobile_number"
        type={clientKycBasicDetail["mobile_number"].type}
        disabled={false}
        maxLength="10"
        autoCapitalize={true}
        value={addClientBasicDetails["mobile_number"]}
        setValue={(value) => {
          setUpdate(true);
          setAddClientBasicDetails(value);
        }}
        object={addClientBasicDetails}
        required={clientKycBasicDetail["mobile_number"]["required"]}
        errorObject={addClientBasicDetailsError}
        setErrorObject={setAddClientBasicDetailsError}
        isError={addClientBasicDetailsError["mobile_number"]}
        errorText={
          addClientBasicDetails["mobile_number"]
            ? "Please enter a valid 10-digit mobile number."
            : "This field cannot be empty."
        }
      />
      <InputBoxV2
        label={"Email ID"}
        id="email_id"
        type={clientKycBasicDetail["email_id"].type}
        disabled={false}
        value={addClientBasicDetails["email_id"]}
        setValue={(value) => {
          setUpdate(true);
          setAddClientBasicDetails(value);
        }}
        object={addClientBasicDetails}
        required={clientKycBasicDetail["email_id"]["required"]}
        errorObject={addClientBasicDetailsError}
        setErrorObject={setAddClientBasicDetailsError}
        isError={addClientBasicDetailsError["email_id"]}
        errorText={
          addClientBasicDetails["email_id"]
            ? "Incorrect email ID format. Please use a valid email ID (example@email.com)."
            : "This field cannot be empty."
        }
      />
      {/* pan number */}
      <InputBoxV2
        label={"PAN"}
        id="pan_number"
        type={clientKycBasicDetail["pan_number"].type}
        disabled={true}
        maxLength="10"
        autoCapitalize={true}
        value={addClientBasicDetails["pan_number"]}
        setValue={setAddClientBasicDetails}
        object={addClientBasicDetails}
        required={clientKycBasicDetail["pan_number"]["required"]}
        errorObject={addClientBasicDetailsError}
        setErrorObject={setAddClientBasicDetailsError}
        isError={addClientBasicDetailsError["pan_number"]}
        errorText={
          addClientBasicDetails["pan_number"].length > 0
            ? "Invalid PAN. Please try again."
            : "This field cannot be empty."
        }
      />
      {/* investor Category */}
      {props.investorType !== "Individual" &&
      props.investorType !== "sole_proprietorship" &&
      props.investorType !== "Individual or Sole Proprietorship" &&
      props.investorType !== "" ? (
        <InputBoxV2
          label={"Investor Category"}
          id="investor_type"
          type={clientKycBasicDetail["investor_type"].type}
          disabled={true}
          //autoCapitalize={true}
          value={config.entity_name[props.investorType]}
          setValue={setAddClientBasicDetails}
          object={addClientBasicDetails}
          required={clientKycBasicDetail["investor_type"]["required"]}
          errorObject={addClientBasicDetailsError}
          setErrorObject={setAddClientBasicDetailsError}
          isError={addClientBasicDetailsError["investor_type"]}
          errorText="Invalid Investor Category"
        />
      ) : (
        <></>
      )}

      <InputBoxV2
        dropdown={true}
        dropDownArray={["Private Limited", "Public Limited"]}
        id="company_type"
        type={clientKycBasicDetail["company_type"].type}
        visibility={
          ["private_limited_company"].includes(props.investorType)
            ? true
            : false
        }
        value={addClientBasicDetails["company_type"]}
        setValue={setAddClientBasicDetails}
        object={addClientBasicDetails}
        // required={clientKycBasicDetail["company_type"]["required"]}
        errorObject={addClientBasicDetailsError}
        setErrorObject={setAddClientBasicDetailsError}
        isError={addClientBasicDetailsError["company_type"]}
        errorText="Please select company type"
      />

      {/* pan name */}
      <InputBoxV2
        label={"Name as per PAN"}
        id="pan_name"
        type={clientKycBasicDetail["pan_name"].type}
        disabled={true}
        autoCapitalize={true}
        value={addClientBasicDetails["pan_name"]}
        visibility={
          addClientBasicDetails["investor_type"] === "sole_proprietorship"
            ? false
            : true
        }
        setValue={setAddClientBasicDetails}
        object={addClientBasicDetails}
        required={clientKycBasicDetail["pan_name"]["required"]}
        errorObject={addClientBasicDetailsError}
        setErrorObject={setAddClientBasicDetailsError}
        isError={addClientBasicDetailsError["pan_name"]}
        errorText={
          !addClientBasicDetails["pan_name"]
            ? "This field cannot be empty."
            : "Invalid PAN. Please try again."
        }
      />
      {/* select investor mode */}
      {props.investorType === "Individual" ||
      props.investorType === "sole_proprietorship" ||
      props.investorType === "Individual or Sole Proprietorship" ||
      props.investorType === "" ? (
        <div className="input_box_field_container">
          <label className="clientkyc_main_input_label">
            Select Investor Category <span style={{ color: "red" }}> *</span>
          </label>

          <div className="clientkyc_main_input_label">
            <label id="Individual" style={{ marginRight: "10%" }}>
              <input
                id="Individual"
                type="radio"
                name="Investor_type"
                value={"Individual"}
                className="radioClass"
                onChange={(e) => {
                  setUpdate(true);
                  setAddClientBasicDetails({
                    ...addClientBasicDetails,
                    investor_type: e.target.value,
                  });
                  setInvalidInvestorCategory(false);
                  setInvalidInvestorCategory(false);
                  props.setInvestorType(e.target.value);
                  props.setIsEntity(false);
                }}
                disabled={false}
              />
              Individual
            </label>

            <label id="sole_proprietorship">
              <input
                id="sole_proprietorship"
                type="radio"
                name="Investor_type"
                value={"sole_proprietorship"}
                className="radioClass"
                onChange={(e) => {
                  setUpdate(true);
                  setAddClientBasicDetails({
                    ...addClientBasicDetails,
                    investor_type: e.target.value,
                  });
                  setInvalidInvestorCategory(false);
                  setInvalidInvestorCategory(false);
                  props.setInvestorType(e.target.value);
                  props.setIsEntity(true);
                }}
                disabled={false}
              />
              Sole Proprietorship
            </label>
          </div>
          <p
            className="input_box_v2_invalid_text"
            style={{
              opacity: invalidInvestorCategory ? 1 : 0,
              marginTop: "2px",
            }}
          >
            {invalidInvestorCategory ? "Please Select Investor Category." : ""}
          </p>
        </div>
      ) : (
        <></>
      )}
      {/* Date of Birth. */}
      <InputBoxV2
          label={
            addClientBasicDetails["pan_number"] &&
            addClientBasicDetails["pan_number"][3] === "P"
              ? "Date of Birth"
              : "Date of Incorporation"
          }
        id="dob"
        type={clientKycBasicDetail["dob"].type}
        disabled={true}
        maxLength="10"
        autoCapitalize={true}
        value={addClientBasicDetails["dob"]}
        setValue={(value) => {
          setUpdate(true);
          setAddClientBasicDetails(value);
        }}
        object={addClientBasicDetails}
        required={clientKycBasicDetail["dob"]["required"]}
        errorObject={addClientBasicDetailsError}
        setErrorObject={setAddClientBasicDetailsError}
        isError={!addClientBasicDetailsError["dob"] || (addClientBasicDetailsError["dob"] && (getAge(addClientBasicDetails["dob"]) < 18))}
        errorText={
          addClientBasicDetails["dob"]
            ? getAge(addClientBasicDetails["dob"]) < 18
              ? "Age must exceed 18 years."
              : null
            : "This field cannot be empty."
        }
        max={maxDate}
        min={startDate}
        visibility={addClientBasicDetails["investor_type"] === "Individual"}
      />
      {/* select geneder  */}
      {addClientBasicDetails["investor_type"] === "Individual" ? (
        <div className="add_clientkyclink_investor_type">
          <label>
            Gender<span style={{ color: "red" }}> *</span>
          </label>

          <div className="add_client_link_gender_type">
            <label id="male" style={{ marginRight: "10%" }}>
              <input
                id="male"
                type="radio"
                name="Gender"
                value={"male"}
                className="radioClass"
                onChange={(e) => {
                  setUpdate(true);
                  setAddClientBasicDetails({
                    ...addClientBasicDetails,
                    gender: e.target.value,
                  });
                  setInvalidGender(false);
                  setInvalidGender(false);
                }}
                disabled={false}
              />
              Male
            </label>

            <label id="female" style={{ marginRight: "10%" }}>
              <input
                id="female"
                type="radio"
                name="Gender"
                value={"female"}
                className="radioClass"
                onChange={(e) => {
                  setUpdate(true);
                  setAddClientBasicDetails({
                    ...addClientBasicDetails,
                    gender: e.target.value,
                  });
                  setInvalidGender(false);
                }}
                disabled={false}
              />
              Female
            </label>

            <label id="others">
              <input
                id="others"
                type="radio"
                name="Gender"
                value={"others"}
                className="radioClass"
                onChange={(e) => {
                  setUpdate(true);
                  setAddClientBasicDetails({
                    ...addClientBasicDetails,
                    gender: e.target.value,
                  });
                  setInvalidGender(false);
                }}
                disabled={false}
              />
              Others
            </label>
          </div>
          <div>
            <p
              className="input_box_v2_invalid_text"
              style={{
                opacity: invalidGender ? 1 : 0,
                marginTop: "2px",
                opacity: invalidGender ? 1 : 0,
                marginTop: "2px",
              }}
            >
              {invalidGender ? "Please select appropriate gender." : ""}
            </p>
          </div>
        </div>
      ) : (
        <></>
      )}
      {/* verify pan */}
      {!props.profileCompleted ? (
        <div
          className="clientkyc_main_input_form"
          style={{ marginTop: "20px" }}
        >
          <button
            onClick={onSubmit}
            className="clientkyc_ckyc_button"
            disabled={ctaLoader}
          >
            {ctaLoader ? (
              <Loader type="TailSpin" color="#fff" height={15} width={15} />
            ) : (
              "Proceed"
            )}
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default ClientKycProfile;
