import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import {
  convertIntoDefaultState,
  convertIntoErrorState,
  errorToast,
  infoToast,
  successToast,
  checkValidPan,
} from "../../utils/helper";
import { InputBox, UploadInputV2, InputBoxV2 } from "../../components/index.js";
import { clientBankDetails } from "../../constant/clientKycState";
import Checkbox from "@mui/material/Checkbox";
import { KYC_LINK_API_V2 } from "../../services/api";
import config from "../../config/settings";
import { eventLog } from "../../utils/firebase";

const ClientKycBankDetails = (props) => {
  const [addClientBankDetails, setAddClientBankDetails] = useState(
    convertIntoDefaultState(clientBankDetails)
  );
  let maxRetries = 2;
  const [addClientBankDetailsError, setAddClientBankDetailsError] = useState(
    convertIntoErrorState(clientBankDetails)
  );
  const [loader, setLoader] = useState(false);
  const [bankData, setBankData] = useState("");
  const [pennyDropStatus, setPennyDropStatus] = useState(0);
  const api = new KYC_LINK_API_V2();

  const bank_consent_msg =
    config.documents.consent_msg.send_kyc_bank_consent_msg(
      props.ifaName ? props.ifaName : "IFA"
    );

  const ifscRegex = /^[A-Za-z]{4}\d{7}$/;

  useEffect(() => {
    props.setBankDetails({
      ...addClientBankDetails,
      bank_data: bankData,
      bank_verify: pennyDropStatus == 1 ? true : false,
    });

    //remove error
    props.setErrorState({
      ...addClientBankDetailsError,
    });
  }, [addClientBankDetails, pennyDropStatus]);

  useEffect(() => {
    if (
      addClientBankDetails['ifsc_code'] && 
      addClientBankDetails['ifsc_code'].length && 
      !ifscRegex.test(addClientBankDetails['ifsc_code'])
    ) {
          props.setErrorState({
          ...addClientBankDetailsError,
          'ifsc_code': true,
        });
    }
  }, [addClientBankDetails['ifsc_code']])

  // useEffect(() => {
  //   if (props.investorType && props.investorType != "Individual") {
  //     setPennyDropStatus(2);
  //   }
  // }, [props.investorType]);

  //check for error
  useEffect(() => {
    if (props.errorState && props.errorState != null) {
      setAddClientBankDetailsError({
        ...addClientBankDetailsError,
        ...props.errorState,
      });
    }
  }, [props]);

  const verifyBankDetail = async (e) => {
    e.preventDefault();

    setLoader(true);
    //check for bank consent
    if (!addClientBankDetails["is_bank_consent_accepted"]) {
      errorToast("Please provide consent in order to move ahead.", true);
      setLoader(false);
      return;
    }

    let payload = {
      ...addClientBankDetails,
      user_id: props?.basicKycDetails?.user_id,
    };

    // call the penny drop check api
    await api.validateIfscAndAccount(payload).then((res) => {
      if (res.kind == "ok") {
        eventLog({
          type: "kyc_link_bank_verify",
          action: {
            trigger: "true",
          },
        });
        const data = res.data;
        setBankData(data.bank_data);
        setPennyDropStatus(1);
        successToast(data.message);
        setLoader(false);
      } else {
        if (res.error == "Account number already exists in our system. Please provide a different account number.")
          setPennyDropStatus(0);
        else
          setPennyDropStatus(2);
        setLoader(false);
        errorToast(res.error, true);
        setLoader(false);
        return;
      }
    });
  };

  return (
    <div>
      {/* Account number */}
      <InputBoxV2
        label={"Account Number"}
        id="account_number"
        type={clientBankDetails["account_number"].type}
        disabled={
          props.investorType == "Individual"
            ? pennyDropStatus == 1 || pennyDropStatus == 2
              ? true
              : false
            : false
        }
        maxLength="20"
        value={addClientBankDetails["account_number"]}
        setValue={setAddClientBankDetails}
        object={addClientBankDetails}
        required={clientBankDetails["account_number"]["required"]}
        errorObject={addClientBankDetailsError}
        setErrorObject={setAddClientBankDetailsError}
        isError={addClientBankDetailsError["account_number"]}
        errorText={addClientBankDetails["account_number"] ? "Invalid Account Number" : "This field cannot be empty."}
      />

      {/* IFSC  code */}
      <InputBox
        label={"IFSC"}
        id="ifsc_code"
        type={clientBankDetails["ifsc_code"].type}
        disabled={
          props.investorType == "Individual"
            ? pennyDropStatus == 1 || pennyDropStatus == 2
              ? true
              : false
            : false
        }
        maxLength="11"
        autoCapitalize={true}
        value={addClientBankDetails["ifsc_code"]}
        setValue={setAddClientBankDetails}
        object={addClientBankDetails}
        required={clientBankDetails["ifsc_code"]["required"]}
        errorObject={addClientBankDetailsError}
        setErrorObject={setAddClientBankDetailsError}
        isError={addClientBankDetailsError["ifsc_code"]}
        errorText={addClientBankDetails["ifsc_code"] ? "Invalid IFSC. Please check and re-enter." : "This field cannot be empty."}
      />

      {/**upload cancel cheque image */}
      {pennyDropStatus == 2 ? (
        <UploadInputV2
          id="cancel_cheque"
          label={"Cancelled Cheque/Bank Statement"}
          optional={true}
          object={addClientBankDetails}
          setValue={setAddClientBankDetails}
          imageSizeLimit={15} //required if note is provided
          note={
            "Upload image in JPG/JPEG/PNG/PDF format. Max. file size allowed is 15 MB."
          }
          required={clientBankDetails["cancel_cheque"]["required"]}
          errorObject={addClientBankDetailsError}
          setErrorObject={setAddClientBankDetailsError}
          isError={addClientBankDetailsError["cancel_cheque"]}
        />
      ) : null}

      {/*  bank consent */}
      {pennyDropStatus == 0 ? (
        <div className="add_cientkycv2_bank_consent_checkbox">
          <Checkbox
            checked={addClientBankDetails["is_bank_consent_accepted"]}
            onChange={() =>
              setAddClientBankDetails({
                ...addClientBankDetails,
                is_bank_consent_accepted:
                  !addClientBankDetails["is_bank_consent_accepted"],
              })
            }
          />

          <div className="add_cientkycv2_bank_consent_checkbox_condition">
            {bank_consent_msg}
          </div>
        </div>
      ) : null}

      {/* verify bank cta */}
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-end",
        }}
      >
        <div
          className="add_client_kyc_bank_verify_cta"
          style={{
            display:
              pennyDropStatus == 2 || pennyDropStatus == 1
                ? "none"
                : addClientBankDetails["account_number"] &&
                  addClientBankDetails["ifsc_code"]?.length == 11
                ? "flex"
                : "none",
          }}
          onClick={(e) => {
            verifyBankDetail(e);
          }}
        >
          {loader ? (
            <Loader type="TailSpin" color="#FFFFFF" height={25} width={25} />
          ) : (
            <p className="add_client_kyc_bank_verify_cta_text">Verify</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ClientKycBankDetails;
