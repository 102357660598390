import React, { useRef, useState, useEffect } from "react";
import "./kyc-details.css";
import { Auth } from "aws-amplify";
import {
  InputBox,
  Dropdown,
  SignupButton,
  ConsentText,
  UploadInput,
  OtpEnterModel,
} from "../../index";
import { useHistory } from "react-router-dom";
import Switch from "@mui/material/Switch";
import {
  checkSignupDisabled,
  checkSignupErrorDisabled,
  checkValidAadhaar,
  checkValidEmail,
  checkValidPan,
  convertDateFormat,
  convertIntoDefaultState,
  convertIntoErrorState,
  errorToast,
  fillKycData,
  infoToast,
  successToast,
  verifyNameMatch,
  warningToast,
  yearsBefore,
} from "../../../utils/helper";
import { documents } from "../../../config/settings";
import { eventLog } from "../../../utils/firebase";
import { useStores } from "../../../models";
import { Kyc_API, User_API } from "../../../services/api";
import { kycDetailsState } from "../../../constant/signupState";
import AgreementGenerate from "../../agreement-generate/agreement-generate";
import { InputSharp } from "@mui/icons-material";
import config from "../../../config/settings";

const maxRetries = 2;

export default function KycDetails(props) {
  const [kycDetails, setKycDetails] = useState(
    convertIntoDefaultState(kycDetailsState)
  );
  const [kycDetailsError, setkycDetailsError] = useState(
    convertIntoErrorState(kycDetailsState)
  );
  const [loader, setLoader] = useState(false);
  const [hasButtonDisabled, sethasButtonDisabled] = useState(true);
  const [isConsentChecked, setIsConsentChecked] = useState(false);
  const [panAttemptLeft, setPanAttempLeft] = useState(maxRetries);
  const [addressFrontAttemptLeft, setAddressFrontAttempLeft] =
    useState(maxRetries);
  const [addressBackAttemptLeft, setAddressBackAttempLeft] =
    useState(maxRetries);
  const [aadhaarErrorText, setAadhaarErrorText] = useState("");
  const [aadhaarReqId, setAadhaarReqId] = useState("");
  const [aadhaarOtp, setAadhaarOtp] = useState("");
  const [aadhaarOtpAttemptLeft, setAadhaarOtpAttemptLeft] =
    useState(maxRetries);
  const [showAadhaarOtpModel, setShowAadhaarOtpModel] = useState(false);
  const [aadhaarLoading, setAadhaarLoading] = useState(false);
  const [panName, setPanName] = useState();
  const [startDate, setStartDate] = useState("");
  const [maxDate, setMaxDate] = useState("");
  const [currDate, setCurrDate] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [markedAgreement, setMarkedAgreement] = useState(false);
  const [AttemptCount, setAttemptCount] = useState(false);
  const [panAttempts, setPanAttempts] = useState(0);

  /**
   * status 0 will show two inputs, pan number and date of birth
   * status 1 will show pan image including above ones
   * status 2 will show aadhaar number including above ones
   * status 3 will show aadhaar front image and aadhaar back image including above ones
   */
  const [status, setStatus] = useState(0);

  const rootStore = useStores();
  const navigate = useHistory();

  const api = new Kyc_API();
  const userApi = new User_API();

  useEffect(() => {
    const { cDate, sDate, mDate } = yearsBefore(100);
    setCurrDate(cDate);
    setStartDate(sDate);
    setMaxDate(mDate);
  }, []);

  useEffect(() => {
    //set has bank
    setKycDetails({
      ...props.kycDetails,
      has_bank: props.ifa && props.ifa.has_bank == 1 ? 1 : 0,
    });
  }, []);

  useEffect(() => {
    //fill data in kyc input
    fillKycData(props.ifa, kycDetails, setKycDetails);
  }, [props.ifa]);

  useEffect(() => {
    //check for disablity of signup button
    let kycDetailsTempState = kycDetailsState;
    sethasButtonDisabled(checkSignupDisabled(kycDetails, kycDetailsTempState));
    sethasButtonDisabled(
      checkSignupErrorDisabled(kycDetailsError, kycDetailsTempState)
    );
  }, [kycDetails, kycDetailsError]);

  //veriting pan and dob
  //veriting pan and dob
  const verifyPan = async () => {
    //if pan is not valid upload pan image
    setLoader(true);
    sethasButtonDisabled(true);
    if (!checkValidPan(kycDetails["pan_number"])) {
      // setUploadPan(true);
      setkycDetailsError({
        ...kycDetailsError,
        pan_number: true,
      });
      setLoader(false);
      return;
    }

    if (
      !kycDetails["pan_number"].length ||
      kycDetails["pan_number"].length == 0
    ) {
      setkycDetailsError({
        ...kycDetailsError,
        date_of_birth: true,
      });
      setLoader(false);
      return;
    }
    if (
      !kycDetails["agreement_signatory"].length ||
      kycDetails["agreement_signatory"].length == 0
    ) {
      setkycDetailsError({
        ...kycDetailsError,
        agreement_signatory: true,
      });
      setLoader(false);
      return;
    }
    

    const curr_user = await Auth.currentUserCredentials();
    // payload
    let payload = {
      pan: String(kycDetails["pan_number"]).toUpperCase(),
      dob: convertDateFormat(kycDetails["date_of_birth"]),
      mode: "ifa_onboarding",
      pan_name: String(kycDetails["agreement_signatory"]),
      loan_app_id: curr_user.identityId,
    };
    api.checkPanNumber(payload).then(async (res) => {
      if (res.kind == "ok") {
        let data = res.data;
        setLoader(false);

        


        //append pan resp file
        setKycDetails((kycDetails) => ({
          ...kycDetails,
          pan_resp_file: data.pan_res,
        }));

        if (props.isGstRequired && props.firmName != "Sole Proprietorship") {
          const isMatched = await verifyNameMatch(
            data?.pan_name,
            props.ifa.name
          );

          if (!isMatched) {
            errorToast(
              `Your PAN doesn't match with your GST number. Please upload PAN image`
            );
            setStatus(1);
            return;
          }
        }

        if (data.ckyc) {
          const payload = {
            ...kycDetails,
            pan_resp_file: data?.pan_resp,
            pan_name: data?.pan_name,
            father_name: data?.father_name,
            ckyc_number: data?.ckyc_number,
            address: data?.address,
            is_ckyc: true,
            ckyc_data : data.ckyc_data
          };

          setKycDetails(payload);
          setLoader(false);
          sethasButtonDisabled(false);
          onSuccess(payload);
          //moving to bank page
        }
         else {
         
          setStatus(2);
          errorToast(
            "Your PAN could not be verified. Please upload an image of your PAN card to proceed."
          );
          setLoader(false);
        }
      }  else if(res.error.includes("name provided by you doesn’t match") && (panAttempts==0 )) { 
        setLoader(false);
        setPanAttempts(panAttempts + 1);
        errorToast(
          "Your PAN verification is failing since the name provided by you doesn’t match with NSDL records. Please ensure that you are providing the complete name including the middle name(if any)."
        );
      }
      else if (res.error == "Could not validate PAN.") {
        setLoader(false);
        sethasButtonDisabled(false);
        setStatus(2);
        setIsConsentChecked(false);
        setLoader(false);
        errorToast(
          "Your PAN could not be verified. Please upload an image of your PAN card to proceed."
        );
      } else if (res.error == "PAN alredy exist in the system") {
        sethasButtonDisabled(false);
        setIsConsentChecked(false);
        setLoader(false);
        errorToast("This PAN has already been registered with us");
      } else {
        sethasButtonDisabled(false);
        setStatus(2);
        setIsConsentChecked(false);
        setLoader(false);
        errorToast("Could not validate PAN.");
      }
    });
  };

  //validate aadhaar card number
  const validateAaadhar = async () => {
    if (kycDetails["address_number"] == "") {
      setkycDetailsError({
        ...kycDetailsError,
        address_number: true,
      });
      return setAadhaarErrorText("Aadhaar Number cannot be empty");
    } else if (!checkValidAadhaar(kycDetails["address_number"])) {
      // setAadhaarInvalid(true);
      setkycDetailsError({
        ...kycDetailsError,
        address_number: true,
      });
      return setAadhaarErrorText("Aadhaar Card number is invalid.");
    }

    // if no error found
    else {
      sethasButtonDisabled(true);
      setkycDetailsError({
        ...kycDetailsError,
        address_number: false,
      });
      const curr_user = await Auth.currentUserCredentials();
      // payload
      setLoader(true);
      let payload = {
        aadhaar_no: kycDetails["address_number"],
        loan_app_id: curr_user.identityId,
      };
      // call the api
      api
        .validateAadhaarCard(payload)
        .then((res) => {
          if (res.kind == "ok") {
            let data = res.data;
            setAadhaarReqId(data ? data?.reqId : "123456");
            setShowAadhaarOtpModel(true);
            setLoader(false);
            sethasButtonDisabled(false);
            successToast(
              "OTP has been sent to your registered mobile number associated with aadhaar."
            );
          } else {
            // setHideButton(1);
            setAadhaarReqId("");
            setLoader(false);
            sethasButtonDisabled(false);
            setIsConsentChecked(false);
            setStatus(3);
            errorToast(res.error);
          }
        })
        .catch((error) => {
          setLoader(false);
          sethasButtonDisabled(false);
          setIsConsentChecked(false);
          setStatus(3);
        });
    }
  };

  // verify aadhar card number
  const verifyAadhaarCard = async () => {
    setAadhaarLoading(true);
    const user = await Auth.currentCredentials();

    if (!isNaN(parseFloat(aadhaarOtp))) {
      // if otp empty
      if (!aadhaarOtp || aadhaarOtp == "") {
        setAadhaarLoading(false);
        warningToast("OTP is required.");
      }

      // if no error found
      else {
        // payload
        let payload = {
          request_id: aadhaarReqId,
          aadhaar_no: kycDetails["address_number"],
          otp: parseInt(aadhaarOtp),
          loan_app_id: user.identityId,
        };

        setAadhaarOtpAttemptLeft(aadhaarOtpAttemptLeft - 1);
        // call the api
        await api.verifyAadhaarCard(payload).then(async (res) => {
          if (res.kind == "ok") {
            let data = res.data;

            const addhaarName = data?.document_data?.dataFromAadhaar?.name;

            const isMatched = await verifyNameMatch(addhaarName, panName);

            if (!isMatched) {
              errorToast(
                `Your Aadhaar doesn't match with your PAN. Please use aadhaar associated with your PAN.`
              );
              setShowAadhaarOtpModel(false);
              setAadhaarLoading(false);
              return;
            }

            setShowAadhaarOtpModel(false);
            setAadhaarLoading(false);
            successToast(data?.msg);
            const payload = {
              ...kycDetails,
              address: data?.address,
              father_name: data?.father_name,
              is_aadhaar_json_success: true,
            };
            setKycDetails(payload);
            onSuccess(payload);
          } else {
            setAadhaarLoading(false);
            if (aadhaarOtpAttemptLeft == 0) {
              setShowAadhaarOtpModel(false);
              infoToast(res.error);
            } else {
              errorToast("Invalid OTP");
              setAadhaarOtp("");
            }
          }
        });
      }
    } else {
      setTimeout(() => {
        setAadhaarLoading(false);
        errorToast("Invalid OTP.");
      }, 2000);
    }
  };

  const handleOcr = async (res, id, fileName, isFileUploaded) => {
    const documentData = res.document_data;
    if (!documentData) {
      return errorToast("Could not validate Pan Image. Please try again.");
    }

    if (id == "pan_image") {
      if (props.isGstRequired && props.firmName != "Sole Proprietorship") {
        const isMatched = await verifyNameMatch(
          documentData.name,
          props.ifa.name
        );

        if (!isMatched) {
          setPanAttempLeft(panAttemptLeft - 1);
          errorToast(
            `Please upload PAN which has been registered against the GST number.`
          );
          return;
        }
        setKycDetails({
          ...kycDetails,
          pan_name: documentData.name,
          pan_image: fileName,
        });
      } else {
        setKycDetails({
          ...kycDetails,
          pan_name: props.ifa && props.ifa.name!="" ? props.ifa.name : documentData.name,
          pan_image: fileName,
          secondary_pan_name:documentData.name
        });
      }
      setPanName(documentData.name);
    } else if (id == "address_front_image") {
      const isMatched = await verifyNameMatch(documentData.name, panName);

      if (!isMatched) {
        setAddressFrontAttempLeft(addressFrontAttemptLeft - 1);
        errorToast(
          `Please enter Aadhaar image which has been registered against the PAN.`
        );
        return;
      }

      setKycDetails({
        ...kycDetails,
        father_name: documentData.father_name,
        address_front_image: fileName,
      });
    } else if (id == "address_back_image") {
      setKycDetails({
        ...kycDetails,
        address: documentData.address,
        address_back_image: fileName,
      });
    }

    if (isFileUploaded) {
      successToast("File uploaded successfully.");
    }
  };

  const handleSubmit = async () => {
    console.log("status--->", status);
    if (status == 0) {
      //verify pan
      await verifyPan();
    } else if (status == 1) {
      //function for pan uplpad
    } else if (status == 2 && !props.isExcludedFirm && !props.isGstRequired) {
      await validateAaadhar();
      //verify aadhaar
    } else if (
      (status == 2 && props.isGstRequired) ||
      (status == 2 && props.isExcludedFirm) ||
      status == 3
    ) {
      onSuccess(kycDetails);
      //submit
    }
  };

  const onSuccess = (payload) => {
    setLoader(true);
    if (props?.ifa?.is_old_user || props?.ifa?.kyc_status == 3) {
      kycDocumentUpdate(payload);
    } else {
      Auth.signOut()
        .then(() => {
          Auth.signIn(kycDetails["pan_number"].toUpperCase())
            .then((response) => {
              Auth.sendCustomChallengeAnswer(response, config.dummy_otp)
                .then(async (res) => {
                  const newUser = await Auth.currentCredentials();
                  console.log("newUserId--->", newUser.identityId);
                  const body = {
                    mobile: localStorage.getItem("mobile"),
                    newUserId: newUser.identityId,
                    isIfa: true,
                  };
                  console.log("body", body);
                  await userApi
                    .updateAndDeleteDummyId(body)
                    .then((res) => {
                      if (res.kind == "ok") {
                        kycDocumentUpdate(payload);
                      } else {
                        errorToast(
                          "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
                        );
                        setLoader(false);
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                      setLoader(false);
                    });
                })
                .catch((error) => {
                  console.log(error);
                  setLoader(false);
                });
            })
            .catch((error) => {
              console.log("Error while creating user ::: ", error);
              errorToast(
                "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
              );
              setLoader(false);
            });
        })
        .catch((err) => {
          errorToast(
            "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
          );
          setLoader(false);
        });
    }
  };

  const kycDocumentUpdate = (payload) => {
    rootStore.userData
      .updateKycDocument(payload)
      .then((res) => {
        if (res.kind == "ok") {
          let data = res.data;
          if (props.skipOrgDocument) {
            props.setCurrentStep(3);
          } else {
            props.setCurrentStep(2);
          }
        } else {
          errorToast(res.error);
        }
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        errorToast(
          "Failed to update KYC due to api failure. Please try again or else contact support."
        );
      });
  };

  return (
    <>
      <form className="kyc_details_form_container">
        <InputBox
          label={props.isExcludedFirm ? "Name of Authorised Signatory" : "Name as per PAN"}
          id="agreement_signatory"
          type={kycDetailsState["agreement_signatory"]["type"]}
          // visibility={props.isExcludedFirm ? true : false}
          value={kycDetails["agreement_signatory"]}
          setValue={setKycDetails}
          object={kycDetails}
          required={kycDetailsState["agreement_signatory"]["required"]}
          errorObject={kycDetailsError}
          setErrorObject={setkycDetailsError}
          isError={kycDetailsError["agreement_signatory"]}
          errorText="Invalid Signatory."
        />

        <InputBox
          label={props.isExcludedFirm ? "PAN of Entity" : "PAN"}
          id="pan_number"
          type={kycDetailsState["pan_number"]["type"]}
          disabled={status > 0 ? true : false}
          maxLength="10"
          autoCapitalize={true}
          value={kycDetails["pan_number"]}
          setValue={setKycDetails}
          object={kycDetails}
          required={kycDetailsState["pan_number"]["required"]}
          errorObject={kycDetailsError}
          setErrorObject={setkycDetailsError}
          isError={kycDetailsError["pan_number"]}
          errorText="Invalid PAN."
        />

        <InputBox
          label={
            props.isExcludedFirm ? "Date of Incorporation" : "Date of Birth"
          }
          id="date_of_birth"
          type={kycDetailsState["date_of_birth"]["type"]}
          value={kycDetails["date_of_birth"]}
          setValue={setKycDetails}
          object={kycDetails}
          required={kycDetailsState["date_of_birth"]["required"]}
          errorObject={kycDetailsError}
          setErrorObject={setkycDetailsError}
          isError={kycDetailsError["date_of_birth"]}
          errorText="Invalid Date"
          min={!props.isExcludedFirm ? startDate : "1850-01-01"}
          max={
            !props.isExcludedFirm
              ? maxDate
              : (() => {
                  const d = new Date();
                  d.setDate(d.getDate() - 1);
                  return d.toISOString().slice(0, 10);
                })()
          }
        />

        {/**upload pan */}
        <UploadInput
          label={"PAN Image"}
          id="pan_image"
          fileName={kycDetails["pan_image"]}
          visibility={status > 0 ? true : false}
          disabled={status > 2 ? true : false}
          setFileName={setKycDetails}
          object={kycDetails}
          showAttemptLeft={panAttemptLeft < maxRetries ? true : false}
          attemptLeft={panAttemptLeft}
          setAttemptLeft={setPanAttempLeft}
          uploadFileToS3={true}
          shouldRunOcr={true}
          source="pan" //required with shouldRunOcr paramter
          sourceType="pan_image" //required with shouldRunOcr paramter
          callback={handleOcr}
        />

        {!props.isGstRequired ? (
          <React.Fragment>
            <InputBox
              label="Address"
              id="address"
              type={kycDetailsState["address"]["type"]}
              visibility={status > 0 && props.isExcludedFirm ? true : false}
              disabled={status > 2 ? true : false}
              value={kycDetails["address"]}
              maxLength="255"
              setValue={setKycDetails}
              object={kycDetails}
              required={kycDetailsState["address"]["required"]}
              errorObject={kycDetailsError}
              setErrorObject={setkycDetailsError}
              isError={kycDetailsError["address"]}
              errorText="Invalid PAN."
            />

            <InputBox
              label="Aadhaar Number"
              id="address_number"
              type={kycDetailsState["address_number"]["type"]}
              visibility={status > 1 && !props.isExcludedFirm ? true : false}
              disabled={status > 2 ? true : false}
              maxLength="12"
              value={kycDetails["address_number"]}
              setValue={setKycDetails}
              object={kycDetails}
              required={kycDetailsState["address_number"]["required"]}
              errorObject={kycDetailsError}
              setErrorObject={setkycDetailsError}
              isError={kycDetailsError["address_number"]}
              errorText={aadhaarErrorText}
            />

            {/**upload aadhaar front image */}

            <UploadInput
              label={"Aadhaar Front Image"}
              id="address_front_image"
              fileName={kycDetails["address_front_image"]}
              visibility={status > 2 && !props.isExcludedFirm ? true : false}
              disabled={status > 3 ? true : false}
              setFileName={setKycDetails}
              object={kycDetails}
              showAttemptLeft={
                addressFrontAttemptLeft < maxRetries ? true : false
              }
              attemptLeft={addressFrontAttemptLeft}
              setAttemptLeft={setAddressFrontAttempLeft}
              uploadFileToS3={true}
              shouldRunOcr={true}
              sourceType="Aadhaar Card Front" //required with shouldRunOcr paramter
              callback={handleOcr} // required with shouldRunOcr paramter
            />

            {/**upload aadhaar back image */}

            <UploadInput
              label={"Aadhaar Back Image"}
              id="address_back_image"
              fileName={kycDetails["address_back_image"]}
              visibility={status > 2 && !props.isExcludedFirm ? true : false}
              disabled={status > 3 ? true : false}
              setFileName={setKycDetails}
              object={kycDetails}
              showAttemptLeft={
                addressBackAttemptLeft < maxRetries ? true : false
              }
              attemptLeft={addressBackAttemptLeft}
              setAttemptLeft={setAddressBackAttempLeft}
              uploadFileToS3={true}
              shouldRunOcr={true}
              sourceType="Aadhaar Card Back" //required with shouldRunOcr paramter
              callback={handleOcr} // required with shouldRunOcr paramter
            />
          </React.Fragment>
        ) : (
          <></>
        )}

        <ConsentText
          isConsentChecked={isConsentChecked}
          setIsConsentChecked={setIsConsentChecked}
        />

        {/* {console.log("kyc details---->", kycDetails)} */}
        <AgreementGenerate
          isUploading={isUploading}
          setIsUploading={setIsUploading}
          ifa={props.ifa}
          setKycDetails={setKycDetails}
          kycDetails={kycDetails}
          setHasButtonDisabled={sethasButtonDisabled}
          status={status}
          isExcludedFirm={props.isExcludedFirm}
          setMarkedAgreement={setMarkedAgreement}
          stage={"kyc"}
        />

        <SignupButton
          loader={loader}
          disabled={
            hasButtonDisabled ||
            !isConsentChecked ||
            (markedAgreement && kycDetails["is_agreement_accepted"] == false)
          }
          text="Verify"
          onSubmit={handleSubmit}
        />
      </form>
      {showAadhaarOtpModel ? (
        <OtpEnterModel
          openModel={showAadhaarOtpModel}
          setOtp={(value) => setAadhaarOtp(value)}
          loading={aadhaarLoading}
          uploadDocument={() => {
            setShowAadhaarOtpModel(false);
            setStatus(3);
          }}
          onSubmit={verifyAadhaarCard}
          onResendOtp={() => null}
          resendLoader={false}
          startTimer={showAadhaarOtpModel}
          setStartTimer={() => showAadhaarOtpModel(false)}
          otp={aadhaarOtp}
        />
      ) : (
        <div />
      )}
    </>
  );
}
