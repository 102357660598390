
import Modal from "@mui/material/Modal";
import "./list-to-send.css"

export default function ListToSendModel(props) {
    var clients = [];
    if(Array.isArray(props.client)){
        clients = props.client;
    }else {
        clients = [props.client];
    }
 
    const handleSelection = (client) => {
        props.handleMaterialSelection(client);
        // props.setSelectedClients(...props.selectedClients,client);
      };

    return(<>
    <Modal open={props.openModel} onClose={props.handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    style={{
      backgroundColor: "rgba(0, 0, 0, 0.699)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}>
    <div className="parent_div">
    <div style={{display:"flex",float:"right",backgroundColor:"white",padding:"1%"}}>
    <button onClick={props.handleClose}>Cancel</button>
    </div>
    <div className="list_client_model_div">
    <p style={{color:"grey",fontWeight:"bold"}}>Select Clients To Send</p>
    <div style={{display: "flex",
      justifyContent: "center",}}>
      {/* <p style={{float:"top"}} onClick={()=>props.onClose}>X</p> */}
      {clients.length>0?(
         <div className="client_list" style={{overflow:"auto"}}>
         {clients.map((client) => (
           <div key={client.id} style={{padding:"2%"}}>
             <label>
               <input
                 type="checkbox"
                 onChange={() => handleSelection(client)}
               />
               {client.name}
             </label>
           </div>
         ))}
         </div>
      ):<p>No clients available</p>}
      
      </div>
      <textarea
                    type="text"
                    placeholder="Type your message *"
                    className="text_input_box_message"
                    value={props.message}
                    onChange={(e) => props.setMessage(e.target.value)}
                  />
      <div style={{display: "flex",
      justifyContent: "center",}}>
      <div style={{justifyContent:"center"}}>
       {/* <div className="text_input_box_message"style={{ marginTop: "10px" }}> */}
            
       {console.log("selected",props.selectedClients)}
       <div style={{display: "flex",
      justifyContent: "center",}}>
       <button className="send_submit_container" onClick={props.sendLinks} style={{ padding: "5px 10px",cursor:"pointer"}}>Send</button>
       </div>
       </div>
    </div>
    
  </div>
  </div>
    </Modal>
    </>)}