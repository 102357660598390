import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import "./clientkyclink.css";
import { useHistory, useLocation } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import { ContactsOutlined } from "@mui/icons-material";
import { Client_API } from "../../../services/api/Clients/clients-api";
import { successToast, errorToast } from "../../../utils/helper";
import checkMark from "../../../assets/images/checkMark.svg";
import Loader from "react-loader-spinner";
import {
  FullScreenLoader,
  InfoAlert,
  SampleToast,
  AlertBox,
  ClientOTPModel,
  PdfViewer,
} from "../../../components";
import {
  ClientKycBank,
  ClientKycDocument,
  ClientKycNominee,
  ClientKycProfile,
  ClientNetWorth,
  ClientPreKyc,
  InvestorTypeComponent,
  EntityKyc,
} from "./";
import Modal from "@mui/material/Modal";
import config from "../../../config/settings";

const ClientKycLink = (props) => {
  let ifa_referral_code = props.match.params.ifa_referral_code;
  const navigate = useHistory();
  const param = useLocation();
  const api = new Client_API();
  const [errors, setErrors] = useState([]);
  const [preData, setPreData] = useState({});
  const [submit, setSubmit] = useState(false);
  const [showNetInfo, setShowNetInfo] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTNCModalOpen, setIsTNCModalOpen] = useState(false);
  const [showForm, setShowForm] = useState(0);
  let document_net_worth = {};
  let raw_data = {
    profile: {},
    documents: {},
    bank_details: {},
    nominee: {},
    entity_documents: {},
    entity_flow: false,
    networth: {
      document_net_worth_image: "",
    },
  };

  const [dob, setDob] = useState("");
  const [panName, setPanName] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const [panValidateFailed, setPanValidateFailed] = useState(false);
  const [addressFailed, setAddressFailed] = useState(false);
  const [disableNameDob, setDisableNameDob] = useState(false);
  const [name, setName] = useState("");
  const [preCheck, setPreCheck] = useState(true);
  const [pan, setPan] = useState("");
  const [mobile, setMobile] = useState("");
  const [showExistModel, setShowExistModel] = useState(false);
  const [userExistLoader, setUserExistLoader] = useState(false);
  const [clientConsentModel, setClientConsentModel] = useState(false);
  const [clientConsentModelLoader, setClientConsentModelLoader] =
    useState(false);
  const [clientTNCModel, setClientTNCModel] = useState(false);
  const [clientTNCModelLoader, setClientTNCModelLoader] = useState(false);

  const [clientOTP, setClientOTP] = useState("");
  const [acceptTerm, setAcceptTerm] = useState(false);
  const [flow, setFlow] = useState("");
  const [panVerified, setPanVerified] = useState(false);
  const [bankValidated, setBankValidated] = useState(false);
  const [aadhaarValidated, setAadhaarValidated] = useState(true);
  const [thankYouPage, setThankYouPage] = useState(false);
  const [autoApproved, setAutoApproved] = useState(false);
  const [clientPreCheck, setClientPreCheck] = useState(false);
  const [investorType, setInvestorType] = useState("");
  const [doNotFetchCkyc, setDoNotFetchCkyc] = useState(false);
  const [entityType, setEntityType] = useState(false);
  const [entityDocuments, setEntityDocuments] = useState({});
  const [ifaCognitoid, setIfaCognitoid] = useState("");
  const [hideUserSelfie, setHideUserSelfie] = useState(true);
  const [ckycVerifiedFailed, setCkycVerifiedFailed] = useState(false);
  const [doNotCheckSelfie, setDoNotCheckSelfie] = useState(true);
  const [entityPan, setEntityPan] = useState("");
  const [entityFlow, setEntityFLow] = useState(false);
  const [entityPanName, setEntityPanName] = useState("");

  // verify the referral code
  useEffect(() => {
    //setIsTNCModalOpen(true);
    let getData = async () => {
      await api.checkReferralCode(ifa_referral_code).then((res) => {
        if (res.kind === "ok") {
          setShowForm(2);
        } else {
          setShowForm(1);
        }
      });
    };
    return getData();
    // eslint-disable-next-line
  }, []);

  // get cognito id
  useEffect(() => {
    let getCredentials = async () => {
      const user = await Auth.currentCredentials();
      setIfaCognitoid(user.identityId);
    };
    return getCredentials();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (ckycVerifiedFailed == true) {
      setDoNotCheckSelfie(false);
    }
  }, [ckycVerifiedFailed]);

  useEffect(() => {
    setPanName(raw_data.documents.document_pan_name);
  }, [raw_data]);

  entityDocuments["entity_document_pan_name"] = entityPanName;
  entityDocuments["entity_document_pan_number"] = entityPan;

  useEffect(() => {
    raw_data.entity_documents = entityDocuments;
    raw_data.entity_flow = entityFlow;
  }, [raw_data, entityDocuments, entityFlow]);

  const checkOcrData = () => {
    let ocrError = false;
    //check for individual
    if (doNotFetchCkyc == false) {
      if (raw_data.documents.document_pan_name == "pan_name") {
        ocrError = true;
        errorToast("Please provide valid Name");
      }
      if (raw_data.documents.document_address_number == "address_number") {
        ocrError = true;
        errorToast("Please provide valid Address Number");
      }
      if (raw_data.documents.document_data.father_name == "father_name") {
        ocrError = true;
        errorToast("Please provide valid Father Name");
      }
      if (raw_data.documents.document_data.address == "full_address") {
        ocrError = true;
        errorToast("Please provide valid Address");
      }
      // if no error found
      setTimeout(() => {
        if (!ocrError) {
          checkData();
        }
      }, 100);
    } else {
      raw_data.documents["entity_document_pan_number"] =
        entityDocuments?.entity_document_pan_number;
      setTimeout(() => {
        checkData();
      }, 100);
    }
  };

  // check the insert kyc data
  const checkData = () => {
    // if pan number
    if (raw_data.documents.document_pan_number.length != 10) {
      if (pan.length == 10) {
        raw_data.documents.document_pan_number = pan;
      }
    }
    // if mobile number
    if (raw_data.profile.profile_mobile.length != 10) {
      if (mobile.length == 10) {
        raw_data.profile.profile_mobile = mobile;
      }
    }
    // upper case ifsc code
    raw_data.bank_details.bank_ifsc_code = String(
      raw_data.bank_details.bank_ifsc_code
    ).toUpperCase();

    raw_data.documents["document_pan_number"] = String(pan).toUpperCase();
    raw_data.documents["document_pan_name"] =
      raw_data.documents.document_pan_name;
    raw_data.documents["entity_document_pan_number"] =
      entityDocuments?.entity_document_pan_number
        ? entityDocuments?.entity_document_pan_number
        : entityPan;
    raw_data.documents["entity_document_pan_name"] =
      entityDocuments?.entity_document_pan_name
        ? entityDocuments?.entity_document_pan_name
        : entityPanName;

    // payload
    let payload_data = {
      ...raw_data.profile,
      ...raw_data.documents,
      ...raw_data.bank_details,
      ...raw_data.nominee,
      ...raw_data.networth,
      ...(raw_data.entity_documents = entityDocuments),
      mode: "create",
    };
    let key_name = Object.getOwnPropertyNames(payload_data);
    let arr = [];
    let found_err = false;
    // map errors array
    key_name.map((name) => {
      return (arr[name] = []);
    });
    // check the validation
    for (let key in payload_data) {
      if (payload_data.hasOwnProperty(key)) {
        // variable define
        let value = String(payload_data[key]);
        var number_format = /^[6-9][0-9]{9}$/;
        var mail_format =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var pan_format = /(^([a-zA-Z]{5})([0-9]{4})([a-zA-Z]{1})$)/;
        var today = new Date();
        var birthDate = new Date(value);
        var age = today.getFullYear() - birthDate.getFullYear();
        var mon = today.getMonth() - birthDate.getMonth();
        // check the case
        switch (key) {
          // case "profile_name":
          //   if (value.length > 255) {
          //     found_err = true;
          //     arr[key].push("Name cannot be greater than 255 characters");
          //   } else if (value.length < 1) {
          //     found_err = true;
          //     arr[key].push("Name cannot be less than 1 character");
          //   }
          //   break;
          case "profile_mobile":
            if (value.length !== 10 || !number_format.test(value)) {
              found_err = true;
              arr[key].push("Invalid mobile number");
            }
            break;
          case "profile_email":
            if (!mail_format.test(String(value).toLowerCase())) {
              found_err = true;
              arr[key].push("Invalid email");
            }
            break;
          // case "profile_father_name":
          //   if (value) {
          //     if (value.length > 255) {
          //       found_err = true;
          //       arr[key].push("Name cannot be greater than 255 characters");
          //     } else if (value.length < 1) {
          //       found_err = true;
          //       arr[key].push("Name cannot be less than 1 character");
          //     }
          //   }
          //   break;
          case "profile_dob":
            if (
              mon < 0 ||
              (mon === 0 && today.getDate() < birthDate.getDate())
            ) {
              age--;
            }
            if (age < 18) {
              found_err = true;
              arr[key].push("Invalid, age must be at least 18 years");
            } else if (value.length < 10) {
              found_err = true;
              arr[key].push("Invalid, age must be at least 18 years");
            }
            break;
          // case "profile_permanent_address":
          //   if (value) {
          //     if (value.length > 255) {
          //       found_err = true;
          //       arr[key].push("Address cannot be greater than 255 characters");
          //     } else if (value.length < 1) {
          //       found_err = true;
          //       arr[key].push("Address cannot be less than 1 character");
          //     }
          //   }
          //   break;
          // case "profile_pincode":
          //   if (value) {
          //     if (value.length !== 6) {
          //       found_err = true;
          //       arr[key].push("Invalid, pin code must be of 6 numbers");
          //     }
          //   }
          //   break;

          case "profile_gender":
            if (value == "" || value == undefined) {
              found_err = true;
              arr[key].push("Please select gender");
            }
            break;
          case "profile_selfie_image":
            if (!doNotCheckSelfie) {
              if (value) {
                if (value.length > 255) {
                  found_err = true;
                  arr[key].push(
                    "File Name cannot be greater than 255 characters"
                  );
                } else if (value.length < 1) {
                  found_err = true;
                  arr[key].push("Please upload the required document");
                }
              } else {
                found_err = true;
                arr[key].push("Please upload the required document");
              }
            }
            break;
          case "document_pan_number":
            if (!doNotFetchCkyc && !pan_format.test(String(value))) {
              found_err = true;
              arr[key].push("Invalid PAN number");
            }
            break;
          case "document_pan_image":
            if (panValidateFailed) {
              if (value) {
                if (value.length > 255) {
                  found_err = true;
                  arr[key].push(
                    "File Name cannot be greater than 255 characters"
                  );
                } else if (value.length < 1) {
                  found_err = true;
                  arr[key].push("Please upload the required document");
                }
              } else {
                found_err = true;
                arr[key].push("Please upload the required document");
              }
            }
            break;
          case "document_address_type":
            if (raw_data.documents.ckyc != true) {
              if (value != 0) {
                if (value > 4) {
                  found_err = true;
                  arr[key].push("Invalid address type");
                }
              }
            }
            break;

          case "document_address_number":
            if (
              raw_data.documents.ckyc != true &&
              raw_data.documents.document_address_type == 4
            ) {
              if (value) {
                if (value.length > 30) {
                  found_err = true;
                  arr[key].push(
                    "Address number cannot be greater than 30 characters"
                  );
                } else if (value.length == 0) {
                  found_err = true;
                  arr[key].push("This field cannot be empty.");
                } else if (value.length < 1) {
                  found_err = true;
                  arr[key].push("Invalid Address number");
                } else if (value == "address_number") {
                  found_err = true;
                  arr[key].push("Invalid Address number");
                } else if (!aadhaarValidated) {
                  found_err = true;
                }
              } else {
                found_err = true;
                arr[key].push("Invalid Address number");
              }
            }
            break;
          case "document_address_front_image":
            if (raw_data.documents.ckyc != true) {
              if (raw_data.documents.document_address_type == 4) {
                if (addressFailed) {
                  if (value) {
                    if (value.length > 255) {
                      found_err = true;
                      arr[key].push(
                        "File Name cannot be greater than 255 characters"
                      );
                    } else if (value.length < 1) {
                      found_err = true;
                      arr[key].push("Please upload the required document");
                    }
                  } else {
                    found_err = true;
                    arr[key].push("Please upload the required document");
                  }
                }
              } else {
                if (value) {
                  if (value.length > 255) {
                    found_err = true;
                    arr[key].push(
                      "File Name cannot be greater than 255 characters"
                    );
                  } else if (value.length < 1) {
                    found_err = true;
                    arr[key].push("Please upload the required document");
                  }
                } else {
                  found_err = true;
                  arr[key].push("Please upload the required document");
                }
              }
            }
            break;
          case "document_address_back_image":
            if (raw_data.documents.ckyc != true) {
              if (raw_data.documents.document_address_type == 4) {
                if (addressFailed) {
                  if (value) {
                    if (value.length > 255) {
                      found_err = true;
                      arr[key].push(
                        "File Name cannot be greater than 255 characters"
                      );
                    } else if (value.length < 1) {
                      found_err = true;
                      arr[key].push("Please upload the required document");
                    }
                  } else {
                    found_err = true;
                    arr[key].push("Please upload the required document");
                  }
                }
              } else {
                if (value) {
                  if (value.length > 255) {
                    found_err = true;
                    arr[key].push(
                      "File Name cannot be greater than 255 characters"
                    );
                  } else if (value.length < 1) {
                    found_err = true;
                    arr[key].push("Please upload the required document");
                  }
                } else if (raw_data.documents.document_address_type != 3) {
                  found_err = true;
                  arr[key].push("Please upload the required document");
                }
              }
            }
            break;
          // case "document_net_worth_image":
          //   if (value) {
          //     if (value.length > 255) {
          //       found_err = true;
          //       arr[key].push(
          //         "File Name cannot be greater than 255 characters"
          //       );
          //     } else if (value.length < 1) {
          //       found_err = true;
          //       arr[key].push("Please upload the required document");
          //     }
          //   }
          //   break;
          // case "bank_account_holder_name":
          //   if (value) {
          //     if (value.length > 255) {
          //       found_err = true;
          //       arr[key].push("Name cannot be greater than 255 characters");
          //     } else if (value.length < 1) {
          //       found_err = true;
          //       arr[key].push("Name cannot be less than 1 character");
          //     }
          //   }
          //   break;
          case "bank_account_number":
            if (value.length < 9 || value.length > 18) {
              found_err = true;
              arr[key].push("Invalid account number");
            }
            break;
          case "bank_ifsc_code":
            if (value.length !== 11) {
              found_err = true;
              arr[key].push("Invalid IFSC. Please check and re-enter.");
            }
            break;
          case "bank_cancel_cheque":
            if (value) {
              if (value.length > 255) {
                found_err = true;
                arr[key].push(
                  "File Name cannot be greater than 255 characters"
                );
              } else if (value.length < 1) {
                found_err = true;
                arr[key].push("Please upload the required document");
              }
            } else {
              if (!isVerified) {
                found_err = true;
                arr[key].push("Please upload the required document");
              }
            }
            break;
          // case "nominee_full_name":
          //   if (value.length > 255) {
          //     found_err = true;
          //     arr[key].push("Name cannot be greater than 255 characters");
          //   } else if (value.length < 1) {
          //     found_err = true;
          //     arr[key].push("Name cannot be less than 1 character");
          //   }
          //   break;
          // case "nominee_relationship":
          //   if (value.length > 255) {
          //     found_err = true;
          //     arr[key].push(
          //       "Relationship cannot be greater than 255 characters"
          //     );
          //   } else if (value.length < 1) {
          //     found_err = true;
          //     arr[key].push("Relationship is required");
          //   }
          //   break;
          // case "nominee_phone_number":
          //   if (value) {
          //     if (value.length !== 10 || !number_format.test(value)) {
          //       found_err = true;
          //       arr[key].push("Invalid mobile number");
          //     }
          //   }
          //   break;
          // case "nominee_email_id":
          //   if (value) {
          //     if (!mail_format.test(String(value).toLowerCase())) {
          //       found_err = true;
          //       arr[key].push("Invalid email");
          //     }
          //   }
          //   break;
          // case "nominee_pan_number":
          //   if (value) {
          //     if (!pan_format.test(String(value))) {
          //       found_err = true;
          //       arr[key].push("Invalid PAN number");
          //     }
          //   }
          //   break;
          // case "nominee_dob":
          //   if (
          //     mon < 0 ||
          //     (mon === 0 && today.getDate() < birthDate.getDate())
          //   ) {
          //     age--;
          //   }
          //   if (age < 18) {
          //     found_err = true;
          //     arr[key].push("Invalid, age must be at least 18 years");
          //   } else if (value.length < 10) {
          //     found_err = true;
          //     arr[key].push("Invalid, age must be at least 18 years");
          //   }
          //   break;
          default:
            break;
        }
      }
    }
    console.log(found_err);
    // set the errors
    setErrors(arr);
    // if no error founds
    if (!found_err) {
      // data
      let get_client_consent_payload = {
        name: raw_data.documents.document_pan_name
          ? raw_data.documents.document_pan_name
          : raw_data.documents.document_data.name == ""
          ? "User"
          : raw_data.documents.document_data.name,
        email: raw_data.profile.profile_email,
        mobile: raw_data.profile.profile_mobile,
        ifa_id: ifaCognitoid,
        mode: "self",
        ifa_referral_code: ifa_referral_code,
      };

      if (param.state !== undefined) {
        if (param.state.update !== undefined) {
          setSubmit(true);
          onUpdate(payload_data);
        } else {
          setSubmit(true);
          setTimeout(() => {
            setFlow("create");
            generateOTP(get_client_consent_payload);
          }, 1000);
        }
      } else {
        setSubmit(true);
        setTimeout(() => {
          setFlow("create");
          generateOTP(get_client_consent_payload);
        }, 1000);
      }
    } else {
      if (
        arr["profile_mobile"].length != 0 ||
        arr["profile_dob"].length != 0 ||
        arr["profile_email"].length != 0 ||
        arr["profile_gender"].length != 0 ||
        arr["profile_selfie_image"].length != 0
      ) {
        errorToast("There is some invalid data in the form.");
        window.scrollTo(0, 0);
      } else if (!panVerified && !doNotFetchCkyc) {
        errorToast("Please validate your PAN");
        window.scrollTo(0, 500);
      } else if (!aadhaarValidated) {
        errorToast("Please validate your Aadhaar number");
        window.scrollTo(0, 500);
      } else if (
        arr["document_address_back_image"].length != 0 ||
        arr["document_pan_number"].length != 0 ||
        arr["document_address_front_image"].length != 0 ||
        arr["document_pan_image"].length != 0
      ) {
        errorToast("There is some invalid data in the form.");
        window.scrollTo(0, 500);
      } else if (
        arr["bank_account_number"].length != 0 ||
        arr["bank_ifsc_code"].length != 0
      ) {
        errorToast("There is some invalid data in the form.");
      } else if (!bankValidated) {
        errorToast("Please validate your bank details");
      } else {
        errorToast("There is some invalid data in the form.");
      }
    }
  };

  const onAcceptTNC = () => {
    setIsTNCModalOpen(false);
    setAcceptTerm(true);
    setClientTNCModelLoader(false);
  };

  // on clicking yes in Client Submit Modal
  const onClickYes = () => {
    setIsModalOpen(false);
    setSubmit(true);

    // data
    const get_client_consent_payload = {
      name: raw_data.documents.document_pan_name
        ? raw_data.documents.document_pan_name
        : raw_data.documents.document_data.name == ""
        ? "User"
        : raw_data.documents.document_data.name,
      email: raw_data.profile.profile_email,
      mobile: raw_data.profile.profile_mobile,
      ifa_referral_code: ifa_referral_code,
      mode: "self",
      tc_url: config.tc_url,
    };
    setTimeout(() => {
      setFlow("create");
      generateOTP(get_client_consent_payload);
    }, 1000);
  };

  // generate otp
  const generateOTP = async (data) => {
    // payload
    let payload;
    // assign the payload
    if (data) {
      payload = data;
    } else {
      setFlow("link");
      payload = {
        pan: String(pan).toUpperCase(),
        ifa_referral_code: ifa_referral_code,
        mobile: mobile,
        mode: "self",
      };
    }
    await api.getClientConsent(payload).then((res) => {
      if (res.kind === "ok") {
        successToast("Successfully sent the OTP");
        setTimeout(() => {
          setSubmit(false);
          setUserExistLoader(false);
          setClientConsentModel(true);
        }, 2000);
      } else if (res.error.hasOwnProperty("profile_mobile")) {
        errorToast("User already exist in our system.");
        window.scrollTo(0, 0);
        setErrors(res.error);
        setSubmit(false);
      } else {
        setTimeout(() => {
          errorToast(res.error);
          setSubmit(false);
          setUserExistLoader(false);
        }, 2000);
      }
    });
  };

  // verify otp
  const verifyOTP = async () => {
    // payload
    let payload = {
      otp: clientOTP,
      pan: String(pan).toUpperCase(),
      ifa_referral_code: ifa_referral_code,
      mobile: raw_data.profile.profile_mobile,
      email: raw_data.profile.profile_email,
      mode: "self",
    };

    await api.verifyClientConsent(payload).then((res) => {
      if (res.kind === "ok") {
        // if flow is create
        if (flow == "create") {
          let payload_data_for_create = {
            ...raw_data.profile,
            ...raw_data.documents,
            ...raw_data.bank_details,
            ...raw_data.nominee,
            ...raw_data.networth,
            document_pan_number: pan,
            document_pan_name: raw_data.documents?.document_pan_name,
            entity_type: investorType != "Individual" ? true : false,
            investor_type: investorType,
            mode: "create",
            ifa_referral_code: ifa_referral_code,
            ip_address: res.data.ip_address,
            datetime: res.data.datetime,
            tc_url: res.data.tc_url,
          };
          setTimeout(() => {
            setClientConsentModelLoader(false);
            setClientConsentModel(false);
            setSubmit(true);
            onSubmit(payload_data_for_create);
          }, 2000);
        }
        // if flow is link
        else if (flow == "link") {
          setTimeout(() => {
            setClientConsentModelLoader(false);
            setClientConsentModel(false);
            setUserExistLoader(true);
            onLink();
          }, 2000);
        }
        // if no flow found
        else {
          setTimeout(() => {
            errorToast(
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
            );
            navigate.go(0);
          }, 2000);
        }
      } else {
        setTimeout(() => {
          errorToast(res.error);
          setClientConsentModelLoader(false);
        }, 2000);
      }
    });
  };

  // on submit the kyc data
  const onSubmit = async (data) => {
    await api
      .addClientKycByReferral(data)
      .then((res) => {
        if (res.kind === "ok") {
          successToast("Successfully saved");
          if (res.data.kyc_status == 2) {
            setAutoApproved(true);
          }
          setThankYouPage(true);
          setTimeout(() => {
            setSubmit(false);
          }, 2000);
        } else {
          if (typeof res.error === "string") {
            errorToast(res.error);
            setSubmit(false);
          } else if (res.error.hasOwnProperty("profile_email")) {
            errorToast("The email you provided already exist in our system.");
            setErrors(res.error);
            setSubmit(false);
            window.scrollTo(0, 0);
          } else if (res.error.hasOwnProperty("profile_mobile")) {
            errorToast("User already exist in our system.");
            setErrors(res.error);
            setSubmit(false);
            window.scrollTo(0, 0);
          } else {
            errorToast("There is some invalid data in the form");
            setErrors(res.error);
            setSubmit(false);
            window.scrollTo(0, 0);
          }
        }
      })
      .then((res) => {
        if (investorType != "Individual") {
          entityDocuments.mobile =
            mobile.length > 0 ? mobile : raw_data.profile.profile_mobile;
          entityDocuments.ifa_referral_code = ifa_referral_code;
          api.selfClientKycEntityDocument(entityDocuments).then((res) => {
            console.log("res ::::", res);
          });
        }
      })
      .catch((error) => {
        console.log("add client entity doc error :::", error);
      });
  };

  // link user with ifa
  const onLink = async () => {
    // payload
    let payload = {
      pan: String(pan).toUpperCase(),
      mode: "link",
      ifa_referral_code: ifa_referral_code,
    };
    // call the api
    await api.addClientKycByReferral(payload).then((res) => {
      if (res.kind == "ok") {
        successToast("Successfully link user with your account");
        setTimeout(() => {
          setUserExistLoader(false);
          navigate.go(0);
        }, 2000);
      } else {
        errorToast(res.error);
        setTimeout(() => {
          setUserExistLoader(false);
          navigate.go(0);
        }, 2000);
      }
    });
  };

  // on update the kyc data
  const onUpdate = (data) => {
    api.updateClientKyc(data, param.state.uuid).then((res) => {
      if (res.kind === "ok") {
        successToast("Successfully saved.");
        setTimeout(() => {
          setSubmit(false);
        }, 2000);
      } else {
        if (typeof res.error === "string") {
          errorToast(res.error);
          setSubmit(false);
        } else {
          errorToast("There is some invalid data in the form.");
          setErrors(res.error);
          setSubmit(false);
        }
      }
    });
  };

  // show form container
  const showFormContainer = (
    param,
    raw_data,
    errors,
    setErrors,
    preData,
    setShowNetInfo,
    checkData,
    dob,
    setDob,
    setIsVerified,
    name,
    setName,
    disableNameDob,
    setDisableNameDob,
    pan,
    mobile,
    ifa_referral_code,
    checkOcrData,
    panName,
    setAddressFailed,
    setPanValidateFailed,
    acceptTerm,
    setAcceptTerm,
    setIsTNCModalOpen,
    setPanVerified,
    setAadhaarValidated,
    setBankValidated
  ) => {
    return (
      <div className="client_kyc_link_container">
        {/* image div */}
        <div className="client_kyc_link_body_container">
          {/* title */}
          <h4
            className="clientkyclink_main_container_main_title"
            style={{ marginTop: "120px" }}
          >
            New Client KYC
          </h4>
        </div>
        <div
          className="clientkyclink_main_container"
          style={{
            display: param.pathname === "/clients/networth" ? "none" : "flex",
          }}
        >
          {/* profile */}
          <div id="profile" className="clientkyclink_main_container_div1">
            <h4 className="clientkyc_main_container_title">
              {investorType != "Individual"
                ? "Profile of Authorised Signatory"
                : "Profile"}
            </h4>
            <ClientKycProfile
              raw_data={raw_data}
              value={(data) => {
                raw_data.profile = data;
                setDob(data.profile_dob);
                setName(data.profile_name);
              }}
              error={errors}
              setErrors={setErrors}
              data={preData}
              disable={param.state === undefined ? false : param.state.disable}
              disableNameDob={disableNameDob}
              mobile={mobile}
              referral={ifa_referral_code}
              panVerified={panVerified}
              hideUserSelfie={ckycVerifiedFailed}
            />
          </div>

          {/* document */}
          {/* hide component incase of entity other than individual*/}
          <div id="document" className="clientkyclink_main_container_div1">
            <h4 className="clientkyc_main_container_title">
              {investorType != "Individual"
                ? "Documents of Authorised Signatory"
                : "Documents"}
            </h4>
            <ClientKycDocument
              raw_data={raw_data}
              value={(data) => {
                raw_data.documents = data;
              }}
              error={errors}
              setErrors={setErrors}
              data={preData}
              disable={param.state === undefined ? false : param.state.disable}
              dob={dob}
              name={name}
              onDisableNameDob={(value) => setDisableNameDob(value)}
              pan={pan}
              referral={ifa_referral_code}
              setPan={setPan}
              panValidateFailed={(data) => setPanValidateFailed(data)}
              addressFailed={(data) => setAddressFailed(data)}
              setPanVerified={(data) => setPanVerified(data)}
              setAadhaarValidated={(data) => setAadhaarValidated(data)}
              setCkycVerifiedFailed={(data) => setCkycVerifiedFailed(data)}
              investorType={investorType}
            />
          </div>

          {/* bank */}
          <div id="bank" className="clientkyclink_main_container_div1">
            <h4 className="clientkyc_main_container_title">
              {investorType != "Individual"
                ? "Bank Details of Entity"
                : "Bank Details"}
            </h4>
            <ClientKycBank
              raw_data={raw_data}
              value={(data) => (raw_data.bank_details = data)}
              error={errors}
              setErrors={setErrors}
              data={preData}
              disable={param.state === undefined ? false : param.state.disable}
              isVerified={(data) => setIsVerified(data)}
              referral={ifa_referral_code}
              panName={panName}
              setValidateBank={(data) => {
                setBankValidated(data);
              }}
            />
          </div>
          {/* nominee */}
          {/* <div id="nominee" className="clientkyclink_main_container_div1">
          <h4 className="clientkyc_main_container_title">Nominee</h4>
          <ClientKycNominee
            value={(data) => (raw_data.nominee = data)}
            error={errors}
            data={preData}
            disable={param.state === undefined ? false : param.state.disable}
          />
        </div> */}

          {/* net worth certificate */}

          {/* net worth */}
          {/* <div id="networth" className="clientkyclink_main_container_div1">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <h4 className="clientkyc_main_container_title">
              Net Worth Certificate{" "}
              {param.pathname === "/clients/networth" ? "" : "(optional)"}
            </h4>
            <InfoIcon
              style={{
                fontSize: "18px",
                marginLeft: "10px",
                cursor: "pointer",
                color: "#4287f5",
              }}
              onClick={() => setShowNetInfo(true)}
            />
          </div>
          <ClientNetWorth
            value={(data) => {
              raw_data.networth = data;
            }}
            error={errors}
            data={preData}
          />
        </div> */}
        </div>
        {/* checkbox */}
        {param.state === undefined || param.state.disable === false ? (
          <div className="kyc_link_checkbox_container">
            <input
              type="checkbox"
              id="term"
              name="term"
              defaultChecked={acceptTerm}
              checked={acceptTerm}
              onClick={() => setIsTNCModalOpen(true)}
            />
            <label for="term" style={{ marginLeft: "3px" }}>
              I have read Terms & Conditions and will abide to it
            </label>
          </div>
        ) : (
          <div />
        )}
        {/* submit button for kyc */}
        {param.state === undefined || param.state.disable === false ? (
          <div className="kyc_link_button_container">
            <div
              className="kyc_link_submit_button"
              style={{
                backgroundColor: acceptTerm ? "#559364" : "#ebedf0",
                color: acceptTerm ? "white" : "black",
              }}
              onClick={() => {
                if (acceptTerm) {
                  checkOcrData();
                }
              }}
            >
              {param.state === undefined
                ? "Submit"
                : param.state.update === true
                ? "Update"
                : "Submit"}
            </div>
          </div>
        ) : (
          <div />
        )}
      </div>
    );
  };

  // show page not found
  const showContentLoader = () => {
    <div className="client_kyc_link_container">
      {/* image div */}
      <div className="client_kyc_link_body_container">
        <Loader
          type="TailSpin"
          color="#414141"
          height={80}
          width={80}
          style={{
            marginTop: "150px",
          }}
        />
      </div>
    </div>;
  };

  // show page not found
  const showPageNotFound = () => {
    <div className="client_kyc_link_container">
      {/* image div */}
      <div className="client_kyc_link_body_container">
        {/* title */}
        <p
          style={{
            marginTop: "120px",
            fontSize: "80px",
            color: "#3A86FF",
            fontWeight: "500",
            textAlign: "center",
          }}
        >
          404
          <br />
          <p
            style={{
              fontSize: "30px",
              color: "#424242",
              fontWeight: "400",
            }}
          >
            Page Not Found
          </p>
        </p>
      </div>
    </div>;
  };

  // pre check pan Number
  const preCheckPanNumber = (
    param,
    setShowExistModel,
    setPreCheck,
    setPan,
    setMobile,
    setClientPreCheck,
    clientPreCheck,
    setInvestorType,
    investorType,
    entityType,
    setEntityType,
    raw_data,
    setEntityDocuments,
    setDoNotFetchCkyc,
    setEntityPan,
    setEntityFLow,
    setEntityPanName
  ) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignSelf: "center",
        }}
      >
        <div className="client_kyc_link_body_container">
          {/* title */}
          <h4
            className="clientkyclink_main_container_main_title"
            style={{ marginTop: "120px" }}
          >
            New Client KYC
          </h4>
        </div>
        <div
          className="clientkyc_main_container"
          style={{
            display: param.pathname === "/clients/networth" ? "none" : "flex",
          }}
        >
          <InvestorTypeComponent
            data={(value) => {
              setClientPreCheck(true);
              setInvestorType(value.investortype);
              setEntityType(value.entity_type);
              setEntityFLow(true);
            }}
          />

          {investorType === "Individual" ? (
            <div id="precheck" className="clientkyc_main_container_div1">
              <ClientPreKyc
                data={(value) => {
                  if (value.exist == true) {
                    if (value.mobile) {
                      setPreCheck(false);
                      setPan(value.pan);
                      setMobile(value.mobile);
                    } else {
                      setShowExistModel(true);
                      setPreCheck(false);
                      setPan(value.pan);
                    }
                  } else {
                    setPreCheck(false);
                    setPan(value.pan);
                  }
                }}
                referral={ifa_referral_code}
              />
            </div>
          ) : null}
          {entityType == true ? (
            <EntityKyc
              investorType={investorType}
              setEntityPanName={setEntityPanName}
              document={(data) => {
                setEntityFLow = setEntityFLow(true);
                raw_data.entity_documents = data;
                setEntityDocuments(data);
                setPreCheck(false);
                setShowExistModel(false);
              }}
              data={(value) => {
                setEntityFLow = setEntityFLow(true);
                raw_data.documents["entity_document_pan_name"] = value.pan;
                raw_data.documents["entity_document_pan_number"] =
                  value.pan_name;
                setEntityPan(value.pan);
                setDoNotFetchCkyc(value.doNotFetchCkyc);
              }}
              setEntityType={setEntityType}
            />
          ) : null}
        </div>
      </div>
    );
  };

  // user exist model
  const userExistModel = (param, setUserExistLoader, generateOTP) => {
    return (
      <div className="client_kyc_link_container">
        <div className="client_kyc_link_body_container">
          {/* title */}
          <h4
            className="clientkyclink_main_container_main_title"
            style={{ marginTop: "120px" }}
          >
            New Client KYC
          </h4>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignSelf: "center",
          }}
        >
          <div
            className="clientkyc_main_container"
            style={{
              display: param.pathname === "/clients/networth" ? "none" : "flex",
            }}
          >
            <div id="userexist" className="clientkyc_main_container_div1">
              <div className="clientkyc_user_exist_div">
                <p className="clientkyc_user_exist_text">
                  Account with this PAN already exists in the system. Please
                  proceed to link this account with your IFA account.
                </p>
                <button
                  onClick={() => {
                    setUserExistLoader(true);
                    setTimeout(() => {
                      generateOTP();
                    }, 1000);
                  }}
                  className="clientkyc_user_exist_button"
                >
                  Proceed
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const showThankYouPage = (type) => {
    if (type === "approved") {
      return (
        <div className="thank_you_main_container">
          <div className="thank_you_check_icon">
            <img src={checkMark} alt="check_mark_icon" className="check_mark" />
          </div>
          <div className="thank_you_text">
            <h3>Thank You for registering with us.</h3>
          </div>
        </div>
      );
    } else {
      return (
        <div className="thank_you_main_container">
          <div className="thank_you_check_icon">
            <img src={checkMark} alt="check_mark_icon" className="check_mark" />
          </div>
          <div className="thank_you_text">
            <h3>Thank You for registering with us.</h3>
          </div>
          <p className="thank_you_subtext">
            Your <strong>KYC</strong> is in review. We will review your
            documents in the next <strong>24 hours.</strong> Please check your
            email for further updates.
          </p>
        </div>
      );
    }
  };

  return (
    <>
      <div>
        {/* header */}
        <header className="auth_header" style={{ zIndex: 1000 }}>
          <img
            src="https://arthmate-p2p-public-assets.s3.ap-south-1.amazonaws.com/images/1invest-logo-color-dark.png"
            alt="logo"
            className="auth_logo_image"
            onClick={() =>
              window.open("https://www.arthmatedirect.com/", "_self")
            }
          />
        </header>
      </div>
      <div className="client_kyc_link_main_container">
        {/* body */}
        {thankYouPage && !autoApproved
          ? showThankYouPage()
          : showThankYouPage && autoApproved
          ? showThankYouPage("approved")
          : showForm === 0
          ? // if page is loading
            showContentLoader()
          : showForm === 1
          ? // if page not found
            showPageNotFound()
          : preCheck && !showExistModel
          ? // if precheck true and showexistmodel false
            preCheckPanNumber(
              param,
              setShowExistModel,
              setPreCheck,
              setPan,
              setMobile,
              setClientPreCheck,
              clientPreCheck,
              setInvestorType,
              investorType,
              entityType,
              setEntityType,
              raw_data,
              setEntityDocuments,
              setDoNotFetchCkyc,
              setEntityPan,
              setEntityFLow,
              setEntityPanName
            )
          : !preCheck && showExistModel && !entityType
          ? // if precheck false and showexistmodel true
            userExistModel(param, setUserExistLoader, generateOTP)
          : !preCheck && !showExistModel && !entityType
          ? // if precheck false and showexistmodel false
            showFormContainer(
              param,
              raw_data,
              errors,
              setErrors,
              preData,
              setShowNetInfo,
              checkData,
              dob,
              setDob,
              setIsVerified,
              name,
              setName,
              disableNameDob,
              setDisableNameDob,
              pan,
              mobile,
              ifa_referral_code,
              checkOcrData,
              panName,
              setAddressFailed,
              setPanValidateFailed,
              acceptTerm,
              setAcceptTerm,
              setIsTNCModalOpen,
              setPanVerified,
              setAadhaarValidated,
              setBankValidated,
              doNotFetchCkyc
            )
          : ""}

        {/* alert box */}
        {isModalOpen ? (
          <AlertBox
            show={isModalOpen}
            title="Client KYC Confirmation!"
            content="Are you sure that the information you provided are correct?"
            positivebutton="Yes"
            negativebutton="No"
            yes={() => {
              onClickYes();
            }}
            no={() => setIsModalOpen(false)}
          />
        ) : (
          <div />
        )}

        {/* net worth info */}
        {showNetInfo ? (
          <InfoAlert
            show={showNetInfo}
            title="Net Worth"
            content="In accordance with RBI norms, in order to invest more than ₹10,00,000 across P2P Platforms the investor needs to produce a certificate from a practicing Chartered Accountant certifying minimum net-worth of ₹50,00,000."
            okbutton="OK"
            ok={() => setShowNetInfo(false)}
          />
        ) : (
          <div />
        )}

        {/* full screen loader */}
        {submit || userExistLoader ? (
          <Modal
            open={submit || userExistLoader}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
              backgroundColor: "transparent",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FullScreenLoader />
          </Modal>
        ) : (
          <div />
        )}

        {/* toast container */}
        <SampleToast />

        {/* client consent model */}
        <ClientOTPModel
          openModel={clientConsentModel}
          setOtp={(value) => setClientOTP(value)}
          loading={clientConsentModelLoader}
          onSubmit={() => {
            setClientConsentModelLoader(true);
            verifyOTP();
          }}
          resendAgain={() => (showExistModel ? generateOTP() : onClickYes())}
        />

        {/* 3rd party t&c */}
        <PdfViewer
          openModel={isTNCModalOpen}
          header={"Terms & Conditions"}
          setOtp={(value) => setClientOTP(value)}
          loading={clientTNCModelLoader}
          isTNCModalOpen={isTNCModalOpen}
          setIsTNCModalOpen={setIsTNCModalOpen}
          url={config.tc_url}
          onSubmit={() => {
            setClientTNCModelLoader(true);
            onAcceptTNC();
          }}
        />
      </div>
    </>
  );
};

export default ClientKycLink;
