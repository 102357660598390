import React, { useEffect, useState } from "react";
import { close } from "../../assets/images/index";
import { convertAmount } from "../../utils/helper";

export default function OverviewAumModal(props) {
  const [aumTab, setAumTab] = useState("invested_amount");
  const handleTabChange = (value) => {
    setAumTab(value);
  };

  const data = props.data


  return (
    <div className="overview-model-div-aum">
      {/* header */}
      <div className="overview-model-header-div-aum">
        <h1 className="overview-model-header-text-aum">
          Assets Under Management
        </h1>
        <div>
          {" "}
          <img
            style={{ cursor: "pointer" }}
            onClick={props.handleClose}
            src={close}
          />
        </div>
      </div>
      <div className="overview-tab-container">
        <div className="overview-tab-navbar">
          <div
            className={
              aumTab == "invested_amount"
                ? "overview_tab_selected"
                : "overview_tab"
            }
            onClick={() => {
              handleTabChange("invested_amount");
            }}
          >
            Invested Amount
          </div>
          <div
            className={
              aumTab == "in_process_amount"
                ? "overview_tab_selected"
                : "overview_tab"
            }
            onClick={() => {
              handleTabChange("in_process_amount");
            }}
          >
            In-Process Amount
          </div>
        </div>
        {aumTab == "invested_amount" ? (
          ///for invested amount
          <div className="overview_tab_panel_container">
            <div className="overview_total_invested_amount">
              <span className="overview_total_invested_amount_text">
                Total Invested Amount :{" "}
              </span>
              <div className="overview_total_invested_amount_amount">
                ₹ {convertAmount(data.total_invested_amount.toFixed(2))}
              </div>
            </div>
            <hr />
            <div className="overview_aum_split_container">
              <div className="overview_aum_invested_plan">
                <span className="overview_aum_invested_plan_text">
                  Freedom Plan{" "}
                </span>
                <span className="overview_aum_invested_plan_amount">
                  ₹ {convertAmount(data.freedom_plan_invested_amount.toFixed(2))}
                </span>
              </div>

              <div className="overview_aum_invested_plan_2">
                <span className="overview_aum_invested_plan_text">
                  Growth Plan{" "}
                </span>
                <span className="overview_aum_invested_plan_amount">
                  ₹ {convertAmount(data.growth_plan_invested_amount.toFixed(2))}
                </span>
              </div>
            </div>
          </div>
        ) : (
          ///for in process amount
          <div className="overview_tab_panel_container">
            <div className="overview_total_invested_amount">
              <span className="overview_total_invested_amount_text">
                Total In-Process Amount :{" "}
              </span>
              <span className="overview_total_invested_amount_amount">
                ₹ {convertAmount(data.total_inprocess_amount.toFixed(2))}
              </span>
            </div>
            <hr />
            <div className="overview_aum_split_container">
              <div className="overview_aum_invested_plan">
                <span className="overview_aum_invested_plan_text">
                  Freedom Plan{" "}
                </span>
                <span className="overview_aum_invested_plan_amount">
                  ₹ {convertAmount(data.freedom_plan_inprocess_amount.toFixed(2))}
                </span>
              </div>

              <div className="overview_aum_invested_plan_2">
                <span className="overview_aum_invested_plan_text">
                  Growth Plan{" "}
                </span>
                <span className="overview_aum_invested_plan_amount">
                  ₹ {convertAmount(data.growth_plan_inprocess_amount.toFixed(2))}
                </span>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="overview-model-footer-div-aum">
        <button
          className="overview-model-footer-button-aum"
          onClick={props.handleClose}
        >
          Close
        </button>
      </div>
    </div>
  );
}
