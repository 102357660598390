import { API } from "aws-amplify";
import { checkHasOrgDocuments } from "../../../utils/helper";
import * as Types from "../api.types";

const orgDocuments = (raw: any) => {
  return {
    copy_of_amfi_certificate: raw.copy_of_amfi_certificate
      ? raw.copy_of_amfi_certificate
      : "",
    copy_of_arn_card: raw.copy_of_arn_card ? raw.copy_of_arn_card : "",
    memorandom_and_article_of_assosiation:
      raw.memorandom_and_article_of_assosiation
        ? raw.memorandom_and_article_of_assosiation
        : "",
    article_of_association: raw.article_of_association
      ? raw.article_of_association
      : "",
    autherised_signatory_list: raw.autherised_signatory_list
      ? raw.autherised_signatory_list
      : "",
    partnership_deed_and_resolution: raw.partnership_deed_and_resolution
      ? raw.partnership_deed_and_resolution
      : "",
    kyd_acknowledgement: raw.kyd_acknowledgement ? raw.kyd_acknowledgement : "",
    deeds_of_declaration: raw.deeds_of_declaration
      ? raw.deeds_of_declaration
      : "",
    trade_license: raw.trade_license ? raw.trade_license : "",
    other_document: raw.other_document ? raw.other_document : "",
  };
};

/**
 * Manages all requests to the API.
 */

export class User_API {
  /**
   * @description This function is for converting raw data into the actual data
   * @param raw
   * @returns it return the actual data that can be displayed
   */
  convertUserData = (raw: any) => {
    return {
      uuid: raw.uuid ? raw.uuid : "",
      mobile: raw.mobile ? raw.mobile : "",
      name: raw.name ? raw.name : "",
      email: raw.email ? raw.email : "",
      kyc_status: raw.kyc_status ? raw.kyc_status : 0,
      referral_code: raw.referral_code ? raw.referral_code : "",
      has_profile: raw.mobile && raw.email && raw.firm_name ? 1 : 0,
      gst_number: raw.gst_number ? raw.gst_number : "",
      agreement: raw.agreement ? raw.agreement : "",
      agreement_signatory: raw.agreement_signatory
        ? raw.agreement_signatory
        : "",
      pan_number: raw.pan_number ? raw.pan_number : "",
      pan_name: raw.pan_name ? raw.pan_name : "",
      pan_image: raw.pan_image ? raw.pan_image : "",
      date_of_birth: raw.date_of_birth ? raw.date_of_birth : "",
      address: raw.address ? raw.address : "",
      address_type: raw.address_type ? raw.address_type : "",
      address_number: raw.address_number ? raw.address_number : "",
      address_front_image: raw.address_front_image
        ? raw.address_front_image
        : "",
      address_back_image: raw.address_back_image ? raw.address_back_image : "",
      net_worth_image: raw.net_worth_image ? raw.net_worth_image : "",
      firm_name: raw.firm_name ? raw.firm_name : "",
      has_document: raw.pan_number && raw.pan_name ? 1 : 0,
      ...orgDocuments(raw),
      has_org_document: checkHasOrgDocuments(
        raw.firm_name ? raw.firm_name : "",
        orgDocuments(raw)
      )
        ? 1
        : 0,
      is_ckyc: raw.is_ckyc ? raw.is_ckyc : 0,
      account_number: raw.account_number ? raw.account_number : "",
      account_holder_name: raw.account_holder_name
        ? raw.account_holder_name
        : "",
      ifsc_code: raw.ifsc_code ? raw.ifsc_code : "",
      cancel_cheque_image: raw.cancel_cheque ? raw.cancel_cheque : "",
      has_bank: raw.account_number && raw.ifsc_code ? 1 : 0,
      flow_type: raw.flow_type ? raw.flow_type : "login",
      is_old_user: raw.is_old_user ? raw.is_old_user : 0,
    };
  };

  /**
   * @description This function is to get logged in user data
   * @param number
   * @returns it returns user overall data.
   */
  async getUserData(data: any): Promise<Types.GetUserDataResult> {
    // payload
    const params = {
      body: data,
    };

    //call the api try and catch
    try {
      const response: any = await API.post("baseurl", "/ifa/user", params);
      const rawData = response.data;
      console.log("RRaw", rawData);
      const convertedData: any = this.convertUserData(rawData);
      return { kind: "ok", userdata: convertedData };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description This function is to get ifa
   * @param data
   * @returns it returns user overall data.
   */
  async getIfaData(data: any): Promise<Types.GetUserDataResult> {
    // payload
    const params = {
      body: data,
    };

    //call the api try and catch
    try {
      const response: any = await API.post("baseurl", "/v2/ifa/info", params);
      const rawData = response.data;
      const convertedData: any = this.convertUserData(rawData);
      return { kind: "ok", userdata: convertedData };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description This function is to get logged in user overall summary
   * @param number
   * @returns it returns user overall summary.
   */
  async getUserSummary(): Promise<Types.GetUserSummary> {
    //call the api try and catch
    try {
      const response: any = await API.get("baseurl", "/ifa/summary", {});
      return { kind: "ok", data: response.data };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description This function is to get logged in user aum datewise data
   * @param page
   * @param pageSize
   * @returns it returns user overall aum datewise data.
   */
  async getUserAumDetails(
    pageSize: any,
    page: any
  ): Promise<Types.GetUserAUMResult> {
    //call the api try and catch
    try {
      const response: any = await API.get("baseurl", "/ifa/summary/AUM", {
        queryStringParameters: {
          pageSize: pageSize,
          page: page,
        },
        headers: {
          "Content-type": "application/json",
        },
      });
      return { kind: "ok", data: response.data, meta: response.meta };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description This function is to get logged in user aum datewise data
   * @param page
   * @param pageSize
   * @param sort_by
   * @returns it returns user overall aum datewise data.
   */
  async getUserInvestmentMaturity(
    pageSize: any,
    page: any,
    sort_by : string,
    sorting_order : string,
  ): Promise<Types.GetUserInvestmentMaturity> {
    //call the api try and catch
    try {
      const response: any = await API.get("baseurl", "/ifa/clients/maturity", {
        queryStringParameters: {
          pageSize: pageSize,
          page: page,
          sort_by : sort_by,
          sorting_order : sorting_order
        },
        headers: {
          "Content-type": "application/json",
        },
      });
      console.log(response);
      return { kind: "ok", data: response.data, meta: response.meta };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description This function is to get logged in user aum datewise data
   * @param page
   * @param pageSize
   * @returns it returns user overall aum datewise data.
   */
  async fetchMaturedInvestmentList(
    pageSize: any,
    page: any
  ): Promise<Types.GetUserAUMResult> {
    //call the api try and catch
    try {
      const response: any = await API.get("baseurl", "/ifa/clients", {
        queryStringParameters: {
          pageSize: pageSize,
          page: page,
        },
        headers: {
          "Content-type": "application/json",
        },
      });
      return { kind: "ok", data: response.data, meta: response.meta };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description this function is for get the data of ifa payment links
   * @returns this return the payment links data
   */
  async getIFAPaymentsLinks(): Promise<Types.GetIFAPaymentLinkResult> {
    //call the api try and catch
    try {
      const response: any = await API.get(
        "baseurl",
        "/v2/ifa/paymentlinks",
        {}
      );

      console.log(response, "response payement");

      return { kind: "ok", data: response.data };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description this function is for get ifa client withdrawal list
   * @returns this return the list of clients
   */
  async getIFAClientWithdrawalList(): Promise<Types.GetIFAClientWithdrawalList> {
    //call the api try and catch
    try {
      const response: any = await API.get(
        "baseurl",
        "/ifa/clientwithdrawal",
        {}
      );
      return { kind: "ok", data: response.data };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description this function is to send maturity email to clients
   * @returns success or faliure
   */
  async sendMaturityEmail(data: any): Promise<Types.SendMaturityEmail> {
    // payload
    const params = {
      body: {
        client_name: data.name,
        client_email: data.email,
        pool_template_title: data.title,
        ifa_name: data.ifa_name,
        ifa_email: data.ifa_email,
        amount: data.amount,
      },
    };

    //call the api try and catch
    try {
      const response: any = await API.post(
        "baseurl",
        "/ifa/clients/maturity/mail",
        params
      );
      return { kind: "ok", data: response.data };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description this function is to send maturity email to clients
   * @returns success or faliure
   */
  async addIfa(data: any): Promise<Types.SendMaturityEmail> {
    // payload
    const params = {
      body: data,
    };

    //call the api try and catch
    try {
      const response: any = await API.post("baseurl", "/v2/ifa/kyc", params);
      return { kind: "ok", data: response.data };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description this function is to update kyc documents
   * @returns success or faliure
   */
  async updateKycDocument(data: any): Promise<Types.SendMaturityEmail> {
    // payload
    const params = {
      body: data,
    };

    //call the api try and catch
    try {
      const response: any = await API.post(
        "baseurl",
        "/v2/ifa/kyc/document",
        params
      );
      return { kind: "ok", data: response.data };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description this function is to update bank
   * @returns success or faliure
   */
  async updateOrgDocument(data: any): Promise<Types.SendMaturityEmail> {
    // payload
    const params = {
      body: data,
    };

    //call the api try and catch
    try {
      const response: any = await API.post(
        "baseurl",
        "/v2/ifa/kyc/org-document",
        params
      );
      return { kind: "ok", data: response.data };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description this function is to update bank
   * @returns success or faliure
   */
  async updateKycBank(data: any): Promise<Types.SendMaturityEmail> {
    // payload
    const params = {
      body: data,
    };

    //call the api try and catch
    try {
      const response: any = await API.post(
        "baseurl",
        "/v2/ifa/kyc/bank",
        params
      );
      return { kind: "ok", data: response.data };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description this function is to update kyc status
   * @returns success or faliure
   */
  async updateKycStatus(data: any): Promise<Types.SendMaturityEmail> {
    // payload
    const params = {
      body: data,
    };

    //call the api try and catch
    try {
      const response: any = await API.post(
        "baseurl",
        "/v2/ifa/kyc/success",
        params
      );
      return { kind: "ok", data: response.data };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description this function is to send the email verification
   * @returns success or faliure
   */
  async sendEmailVerification(data: any): Promise<Types.SendMaturityEmail> {
    // payload
    const params = {
      body: data,
    };

    //call the api try and catch
    try {
      const response: any = await API.post(
        "baseurl",
        "/users/request/email",
        params
      );
      return { kind: "ok", data: response.data };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.errors };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }
  /**
   * @description this function is to send the mobile verification
   * @returns success or faliure
   */
  async sendMobileVerification(data: any): Promise<Types.SendMaturityEmail> {
    // payload
    const params = {
      body: data,
    };

    //call the api try and catch
    try {
      const response: any = await API.post(
        "baseurl",
        "/users/verify/mobile",
        params
      );
      return { kind: "ok", data: response.data };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.errors };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description this function is to update and delete dummy cognitoId
   * @returns success or faliure
   */
  async updateAndDeleteDummyId(data: any): Promise<Types.SendMaturityEmail> {
    // payload
    const params = {
      body: data,
    };

    //call the api try and catch
    try {
      const response: any = await API.post(
        "baseurl",
        "/users/update-dummyId",
        params
      );
      return { kind: "ok", data: response.data };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.errors };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }
}
