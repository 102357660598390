import * as React from "react";
import "./sales-model.css";
import Modal from "@mui/material/Modal";
import { send } from "../../assets/images/index";


export default function SalesModel(props) {
    
    var flag=false;
    var materials = [];
    if(Array.isArray(props.salesData)){
      materials = props.salesData;
    }else {
        materials = [props.salesData];
    }
    if(props.clients.length>1){
      flag=true;
    }
    return (
       <div className="item1">
                {materials.map((i,index) => (
                  <div className="container">
                    <div>
                        {console.log("materials",i)}
                      <p className="containertext">{i}</p>
                      <span className="msg">Message {index+1}</span>
                      <img className= "fa-download"onClick={() => {if (props.clients.length>0){
                                                                  props.setOpenModel(true) 
                                                                  props.setSelectedPara(i)
                                                                }
                                                                  else{
                                          
                                                                    props.errorToast("No clients Present")}
                                                                  
                                                                    } } src={send} />
                    </div>
                  </div>
                ))}
              </div>
    )
}