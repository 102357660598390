import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Forms_API } from "../../services/api";
import "./form.css";

/* eslint-disable react/prop-types */
function BorrowerForm(props) {
  const [partnerId, setPartnerId] = useState(
    "4b41454d-fe8d-11eb-875f-02a64dc07394"
  );
  const api = new Forms_API();
  const emptyProfile = {
    name: "",
    date_of_birth: "",
    gender: "",
    father_name: "",
    employment: "",
    residential: "",
    monthly_income: "",
    address: "",
    city: "",
    state: "",
    pin_code: "",
    email: "",
    mobile: "",
    aadhar_number: "",
    pan_number: "",
    bureau_score: "",
    bank_name: "",
    account_number: "",
    ifsc_code: "",
    is_pan_json: 0,
    is_aadhar_front_xml: 0,
    is_aadhar_back_xml: 0,
    pan_image: "",
    aadhar_front_image: "",
    aadhar_back_image: "",
    credit_score: "",
    tds_certification_name: "",
  };
  const defaultProfile = {
    name: "Nityam Singhe",
    date_of_birth: "15-12-1993",
    gender: "Male",
    father_name: "Test Father Name",
    employment: "salaried",
    residential: "owned",
    monthly_income: 70000,
    address: "The Circle HUDA City Center",
    city: "Gurgaon",
    state: "Haryana",
    pin_code: "122002",
    email: "Demo@gmail.com",
    mobile: "9609000091",
    aadhar_number: "270916313906",
    pan_number: "LCDPS2214Q",
    bureau_score: 700,
    bank_name: "State Bank of India",
    account_number: 50100303377495,
    ifsc_code: "HDFC0000563",
    is_pan_json: 0,
    is_aadhar_front_xml: 0,
    is_aadhar_back_xml: 0,
    pan_image:
      "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
    aadhar_front_image:
      "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
    aadhar_back_image:
      "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
    credit_score: 40,
    tds_certification_name: "Random Value 273",
  };
  const [profile, setProfile] = useState(defaultProfile);
  const emptyLoan = {
    interest_rate: "",
    borrowed_amount: "",
    tenure: "",
    tenure_type: "",
    repayment_type: "",
    processing_fees_amount: "",
    additional_charges: "",
    disbursement_amount: "",
    sanction_letter: "",
    agreement: "",
    ip_stamp: "",
  };
  const defaultLoan = {
    interest_rate: 22.0,
    borrowed_amount: 1000,
    tenure: "3",
    tenure_type: 1,
    repayment_type: 3,
    processing_fees_amount: 322,
    additional_charges: 14,
    disbursement_amount: 3521,
    sanction_letter:
      "https://arthmate-p2p-data.s3.ap-south-1.amazonaws.com/readable/Agreements.pdf",
    agreement:
      "https://arthmate-p2p-data.s3.ap-south-1.amazonaws.com/readable/Agreements.pdf",
    ip_stamp: "192.183.0.0",
  };
  const [loan, setLoan] = useState(defaultLoan);
  const handleProfileChange = (e) => {
    setProfile({ ...profile, [`${e.target.name}`]: e.target.value });
  };
  const handleLoanChange = (e) => {
    setLoan({ ...loan, [`${e.target.name}`]: e.target.value });
  };

  //   useEffect(() => {
  //     props.showCommonHeader(true);
  //     props.showCommonSidebar(true);
  //     // eslint-disable-next-line
  //   }, []);
  const navigate = useHistory();
  const handleSubmit = (e) => {
    const data = {
      partner_id: partnerId,
      borrower: {
        profile: profile,
        loan: loan,
      },
    };
    console.log(data, "borrower");
    api.borrowers(data).then((res) => {
      console.log(res, "borrowerresponse");
      //   setLoanId(res.data.unique_loan_id)
      // window.history.pushState(res, null, "/uploadDocument");
      res.kind == "ok" &&
        navigate.push({
          pathname: "/uploadDocument",
          state: { loan_id: res.data.unique_loan_id },
        });
    });
    e.preventDefault();
  };
  const isLoanValid =
    loan.interest_rate == emptyLoan.interest_rate ||
    loan.borrowed_amount == emptyLoan.borrowed_amount ||
    loan.tenure == emptyLoan.tenure ||
    loan.tenure_type == emptyLoan.tenure_type ||
    loan.repayment_type == emptyLoan.repayment_type ||
    loan.processing_fees_amount == emptyLoan.processing_fees_amount ||
    loan.additional_charges == emptyLoan.additional_charges ||
    loan.disbursement_amount == emptyLoan.disbursement_amount ||
    loan.sanction_letter == emptyLoan.sanction_letter ||
    loan.agreement == emptyLoan.agreement ||
    loan.ip_stamp == emptyLoan.ip_stamp;
  const isProfileFilled =
    profile.name == emptyProfile.name ||
    profile.date_of_birth == emptyProfile.date_of_birth ||
    profile.gender == emptyProfile.gender ||
    profile.father_name == emptyProfile.father_name ||
    profile.employment == emptyProfile.employment ||
    profile.residential == emptyProfile.residential ||
    profile.monthly_income == emptyProfile.monthly_income ||
    profile.address == emptyProfile.address ||
    profile.city == emptyProfile.city ||
    profile.state == emptyProfile.state ||
    profile.pin_code == emptyProfile.pin_code ||
    profile.email == emptyProfile.email ||
    profile.mobile == emptyProfile.mobile ||
    profile.aadhar_number == emptyProfile.aadhar_number ||
    profile.pan_number == emptyProfile.pan_number ||
    profile.bureau_score == emptyProfile.bureau_score ||
    profile.bank_name == emptyProfile.bank_name ||
    profile.account_number == emptyProfile.account_number ||
    profile.ifsc_code == emptyProfile.ifsc_code ||
    profile.pan_image == emptyProfile.pan_image ||
    profile.aadhar_front_image == emptyProfile.aadhar_front_image ||
    profile.aadhar_back_image == emptyProfile.aadhar_back_image ||
    profile.credit_score == emptyProfile.credit_score ||
    profile.tds_certification_name == emptyProfile.tds_certification_name;

  return (
    <div className="forms">
      <header className="form-header">
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <div className="textFieldDiv">
            <label>Partner Id:</label>
            <input
              name="partner_id"
              type="text"
              value={partnerId}
              required
              onChange={(e) => {
                setPartnerId(e.target.value);
              }}
            />
            <br />
          </div>
          <div className="profile">
            <h3> Borrower Profile </h3>

            <div className="textFieldDiv">
              <label>Name:</label>
              <input
                name="name"
                type="text"
                value={profile.name}
                required
                onChange={(e) => {
                  handleProfileChange(e);
                }}
              />
              <br />
            </div>
            <div className="textFieldDiv">
              <label>Date of birth:</label>
              <input
                name="date_of_birth"
                type="text"
                value={profile.date_of_birth}
                required
                onChange={(e) => {
                  handleProfileChange(e);
                }}
              />
              <br />
            </div>
            <div className="textFieldDiv">
              <label>Gender:</label>
              <input
                name="gender"
                type="text"
                value={profile.gender}
                required
                onChange={(e) => {
                  handleProfileChange(e);
                }}
              />
              <br />
            </div>
            <div className="textFieldDiv">
              <label>Father name:</label>
              <input
                name="father_name"
                type="text"
                value={profile.father_name}
                required
                onChange={(e) => {
                  handleProfileChange(e);
                }}
              />
              <br />
            </div>
            <div className="textFieldDiv">
              <label>Employment:</label>
              <input
                name="employment"
                type="text"
                value={profile.employment}
                required
                onChange={(e) => {
                  handleProfileChange(e);
                }}
              />
              <br />
            </div>
            <div className="textFieldDiv">
              <label>Residential:</label>
              <input
                name="residential"
                type="text"
                value={profile.residential}
                required
                onChange={(e) => {
                  handleProfileChange(e);
                }}
              />
              <br />
            </div>
            <div className="textFieldDiv">
              <label>Monthly Income:</label>
              <input
                name="monthly_income"
                type="text"
                value={profile.monthly_income}
                required
                onChange={(e) => {
                  handleProfileChange(e);
                }}
              />
              <br />
            </div>
            <div className="textFieldDiv">
              <label>Address:</label>
              <input
                name="address"
                type="text"
                value={profile.address}
                required
                onChange={(e) => {
                  handleProfileChange(e);
                }}
              />
              <br />
            </div>
            <div className="textFieldDiv">
              <label>City:</label>
              <input
                name="city"
                type="text"
                value={profile.city}
                required
                onChange={(e) => {
                  handleProfileChange(e);
                }}
              />
              <br />
            </div>
            <div className="textFieldDiv">
              <label>State:</label>
              <input
                name="state"
                type="text"
                value={profile.state}
                required
                onChange={(e) => {
                  handleProfileChange(e);
                }}
              />
              <br />
            </div>
            <div className="textFieldDiv">
              <label>Pin Code:</label>
              <input
                name="pin_code"
                type="text"
                value={profile.pin_code}
                required
                onChange={(e) => {
                  handleProfileChange(e);
                }}
              />
              <br />
            </div>
            <div className="textFieldDiv">
              <label>Email:</label>
              <input
                name="email"
                type="text"
                value={profile.email}
                required
                onChange={(e) => {
                  handleProfileChange(e);
                }}
              />
              <br />
            </div>
            <div className="textFieldDiv">
              <label>Mobile:</label>
              <input
                name="mobile"
                type="text"
                value={profile.mobile}
                required
                onChange={(e) => {
                  handleProfileChange(e);
                }}
              />
              <br />
            </div>
            <div className="textFieldDiv">
              <label>Aadhar Number:</label>
              <input
                name="aadhar_number"
                type="text"
                value={profile.aadhar_number}
                required
                onChange={(e) => {
                  handleProfileChange(e);
                }}
              />
              <br />
            </div>
            <div className="textFieldDiv">
              <label>PAN:</label>
              <input
                name="pan_number"
                type="text"
                value={profile.pan_number}
                required
                onChange={(e) => {
                  handleProfileChange(e);
                }}
              />
              <br />
            </div>
            <div className="textFieldDiv">
              <label>Bureau Score:</label>
              <input
                name="bureau_score"
                type="text"
                value={profile.bureau_score}
                required
                onChange={(e) => {
                  handleProfileChange(e);
                }}
              />
              <br />
            </div>
            <div className="textFieldDiv">
              <label>Bank Name:</label>
              <input
                name="bank_name"
                type="text"
                value={profile.bank_name}
                required
                onChange={(e) => {
                  handleProfileChange(e);
                }}
              />
              <br />
            </div>
            <div className="textFieldDiv">
              <label>Account number:</label>
              <input
                name="account_number"
                type="text"
                value={profile.account_number}
                required
                onChange={(e) => {
                  handleProfileChange(e);
                }}
              />
              <br />
            </div>
            <div className="textFieldDiv">
              <label>IFSC Code:</label>
              <input
                name="ifsc_code"
                type="text"
                value={profile.ifsc_code}
                required
                onChange={(e) => {
                  handleProfileChange(e);
                }}
              />
              <br />
            </div>
            <div className="textFieldDiv">
              <label>is_pan_json:</label>
              <input
                name="is_pan_json"
                type="text"
                value={profile.is_pan_json}
                required
                onChange={(e) => {
                  handleProfileChange(e);
                }}
              />
              <br />
            </div>
            <div className="textFieldDiv">
              <label>is_aadhar_front_xml:</label>
              <input
                name="is_aadhar_front_xml"
                type="text"
                value={profile.is_aadhar_front_xml}
                required
                onChange={(e) => {
                  handleProfileChange(e);
                }}
              />
              <br />
            </div>
            <div className="textFieldDiv">
              <label>is_aadhar_back_xml:</label>
              <input
                name="is_aadhar_back_xml"
                type="text"
                value={profile.is_aadhar_back_xml}
                required
                onChange={(e) => {
                  handleProfileChange(e);
                }}
              />
              <br />
            </div>
            <div className="textFieldDiv">
              <label>PAN image:</label>
              <input
                name="pan_image"
                type="text"
                value={profile.pan_image}
                required
                onChange={(e) => {
                  handleProfileChange(e);
                }}
              />
              <br />
            </div>
            <div className="textFieldDiv">
              <label>Aadhar Front Image:</label>
              <input
                name="aadhar_front_image"
                type="text"
                value={profile.aadhar_front_image}
                required
                onChange={(e) => {
                  handleProfileChange(e);
                }}
              />
              <br />
            </div>
            <div className="textFieldDiv">
              <label>Aadhar Back Image:</label>
              <input
                name="aadhar_back_image"
                type="text"
                value={profile.aadhar_back_image}
                required
                onChange={(e) => {
                  handleProfileChange(e);
                }}
              />
              <br />
            </div>
            <div className="textFieldDiv">
              <label>Credit Score:</label>
              <input
                name="credit_score"
                type="text"
                value={profile.credit_score}
                required
                onChange={(e) => {
                  handleProfileChange(e);
                }}
              />
              <br />
            </div>
            <div className="textFieldDiv">
              <label>TDS Certification name:</label>
              <input
                name="tds_certification_name"
                type="text"
                value={profile.tds_certification_name}
                required
                onChange={(e) => {
                  handleProfileChange(e);
                }}
              />
              <br />
            </div>
          </div>
          <div className="loan">
            <h3>Borrower Loan</h3>
            <div className="textFieldDiv">
              <label>interest_rate:</label>
              <input
                name="interest_rate"
                type="text"
                value={loan.interest_rate}
                required
                onChange={(e) => {
                  handleLoanChange(e);
                }}
              />
              <br />
            </div>
            <div className="textFieldDiv">
              <label>Borrowed Amount:</label>
              <input
                name="borrowed_amount"
                type="text"
                value={loan.borrowed_amount}
                required
                onChange={(e) => {
                  handleLoanChange(e);
                }}
              />
              <br />
            </div>
            <div className="textFieldDiv">
              <label>Tenure:</label>
              <input
                name="tenure"
                type="text"
                value={loan.tenure}
                required
                onChange={(e) => {
                  handleLoanChange(e);
                }}
              />
              <br />
            </div>
            <div className="textFieldDiv">
              <label>Tenure Type:</label>
              <input
                name="tenure_type"
                type="text"
                value={loan.tenure_type}
                required
                onChange={(e) => {
                  handleLoanChange(e);
                }}
              />
              <br />
            </div>
            <div className="textFieldDiv">
              <label>Repayment Type:</label>
              <input
                name="repayment_type"
                type="text"
                value={loan.repayment_type}
                required
                onChange={(e) => {
                  handleLoanChange(e);
                }}
              />
              <br />
            </div>
            <div className="textFieldDiv">
              <label>Processing Fees Amount:</label>
              <input
                name="processing_fees_amount"
                type="text"
                value={loan.processing_fees_amount}
                required
                onChange={(e) => {
                  handleLoanChange(e);
                }}
              />
              <br />
            </div>
            <div className="textFieldDiv">
              <label>Additional Charges:</label>
              <input
                name="additional_charges"
                type="text"
                value={loan.additional_charges}
                required
                onChange={(e) => {
                  handleLoanChange(e);
                }}
              />
              <br />
            </div>
            <div className="textFieldDiv">
              <label>Disbursement Amount:</label>
              <input
                name="disbursement_amount"
                type="text"
                value={loan.disbursement_amount}
                required
                onChange={(e) => {
                  handleLoanChange(e);
                }}
              />
              <br />
            </div>
            <div className="textFieldDiv">
              <label>Sanction Letter:</label>
              <input
                name="sanction_letter"
                type="text"
                value={loan.sanction_letter}
                required
                onChange={(e) => {
                  handleLoanChange(e);
                }}
              />
              <br />
            </div>
            <div className="textFieldDiv">
              <label>Agreement:</label>
              <input
                name="agreement"
                type="text"
                value={loan.agreement}
                required
                onChange={(e) => {
                  handleLoanChange(e);
                }}
              />
              <br />
            </div>
            <div className="textFieldDiv">
              <label>IP Stamp:</label>
              <input
                name="ip_stamp"
                type="text"
                value={loan.ip_stamp}
                required
                onChange={(e) => {
                  handleLoanChange(e);
                }}
              />
              <br />
            </div>
          </div>
          <input
            disabled={isProfileFilled || isLoanValid || partnerId == ""}
            className="submit"
            type="submit"
            value="Submit"
          />
        </form>
      </header>
    </div>
  );
}

export default BorrowerForm;
