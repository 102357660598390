import React, { useRef, useState, useEffect } from "react";
import "./bank-verify.css";
import Loader from "react-loader-spinner";
import InfoIcon from "@mui/icons-material/Info";
import Checkbox from "@mui/material/Checkbox";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Auth, Storage } from "aws-amplify";
import { IfaAgreement } from "../../../templates";
import { eventLog } from "../../../utils/firebase";
import AgreementGenerate from "../../agreement-generate/agreement-generate";
import config from "../../../config/settings";
import {
  PdfViewer,
  GenerateHtmlPdf,
  LinearProgressWithLabel,
  InputBox,
  SignupButton,
  ConsentText,
  UploadInput,
} from "../../index";
import {
  checkSignupDisabled,
  checkValidIfsc,
  convertIntoDefaultState,
  convertIntoErrorState,
  errorToast,
  fillKycData,
  generateUUID,
  getBase64FromUrl,
  getFrontendStateObject,
  handleDownload,
  successToast,
  verifyNameMatch,
} from "../../../utils/helper";
import { head_signature, privacy_policy_url } from "../../../config/settings";
import { Kyc_API } from "../../../services/api";
import { useStores } from "../../../models";
import { bankVerifyState } from "../../../constant/signupState";
import TcViewer from "../../tc-viewer/tc-viewer";

const { stage } = getFrontendStateObject();
const imageSizeLimit = 1;

let maxRetries = 2;

export default function BankVerify(props) {
  const [bankVerify, setBankVerify] = useState(
    convertIntoDefaultState(bankVerifyState)
  );
  const [bankVerifyError, setBankVerifyError] = useState(
    convertIntoErrorState(bankVerifyState)
  );
  const [openIfaModel, setOpenIfaModel] = useState(false);
  const [isConsentChecked, setIsConsentChecked] = useState(false);
  const [loader, setLoader] = useState(false);
  const [hasButtonDisabled, sethasButtonDisabled] = useState(false);
  const [openTermsAndConditionsModel, setOpenTermsAndConditionsModel] =
    useState(false);
  const [initiateAgreementPdf, setInitiateAgreementPdf] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [disableAgreementCheckbox, setDisableAgreementCheckbox] = useState(false);
  const [termsConditions,setTermsConditions] = useState(false);
  const [agreementBase64, setAgreementBase64] = useState("");
  const [agreementBlob, setAgreementBlob] = useState("");
  const [agreementUploadProgess, setAgreementUploadProgess] = useState(0);
  const [signatureBase64, setSignatureBase64] = useState("");
  const [markedAgreement, setMarkedAgreement] = useState(false);

  /**
   * 0 indictates penny drop has not been initiated
   * 1 indictates that penny drop has been initiated but is success
   * 2 indictaes that penny drop has been failed
   */
  const [pennyDropStatus, setPennyDropStatus] = useState(0);

  const api = new Kyc_API();
  const rootStore = useStores();

  useEffect(() => {
    setSignatureBase64(head_signature);
  }, []);

  useEffect(() => {
    if (props.isExcludedFirm) {
      setPennyDropStatus(2);
    }
  }, [props.isExcludedFirm]);

  useEffect(() => {
    //fill data in kyc input

    fillKycData(props.ifa, bankVerify, setBankVerify);
  }, [props.ifa]);

  useEffect(() => {
    //check for disablity of signup button
    let bankVerifyTempState = bankVerifyState;
    if (props.ifa && props.ifa.kyc_status == 0) {
      bankVerifyTempState["is_term_and_condition_accepted"]["required"] = true;
      bankVerifyTempState["is_agreement_accepted"]["required"] = true;
    }
    sethasButtonDisabled(checkSignupDisabled(bankVerify, bankVerifyTempState));
  }, [bankVerify, pennyDropStatus]);

  // verify ifsc code and account number
  const verifyIfscAndAccount = async () => {
    // if ifsc code is less than 11 character
    setLoader(true);
    sethasButtonDisabled(true);
  if (!bankVerify || bankVerify.account_number === null || bankVerify.account_number === undefined) {
    setBankVerifyError({
      ...bankVerifyError,
      account_number: true,
    });
    setLoader(false);
    return;
  }
  else if (
    bankVerify["account_number"] && 
   bankVerify["account_number"].length > 18 ||
   bankVerify["account_number"].length < 9 
 ) {
   setBankVerifyError({
     ...bankVerifyError,
     account_number: true,
   });
   setLoader(false);
   sethasButtonDisabled(false);
   return;
 }

   else if (!checkValidIfsc(bankVerify["ifsc_code"])) {
      setBankVerifyError({
        ...bankVerifyError,
        ifsc_code: true,
      });
      setLoader(false);
      sethasButtonDisabled(false);
      return;
    } 
   
  

    // if there is no error
    else {
      eventLog({
        type: "bank_verify",
        action: {
          trigger: "true",
        },
      });

      setBankVerifyError({
        ...bankVerifyError,
        ifsc_code: false,
      });
      const curr_user = await Auth.currentUserCredentials();
      // payload
      let payload = {
        ifsc: bankVerify["ifsc_code"],
        loan_app_id: curr_user.identityId,
        account_number: bankVerify["account_number"],
        mode: "ifa_onboarding",
      };
      // call the validate ifsc account
      api
        .validateIfscAndAccount(payload)
        .then(async (res) => {
          if (res.kind == "ok") {
            const data = res.data;
            const accountHolderName = data?.bank_data?.accountName;

            const isMatched = await verifyNameMatch(
              props.ifa && props.ifa.name ? props.ifa.name : "",
              accountHolderName
            );

            if (!isMatched) {
              errorToast(
                `Your bank account doesn't match with your PAN. Please use bank account associated with your PAN.`
              );
              if (maxRetries == 0) {
                setPennyDropStatus(2);
              } else {
                maxRetries -= 1;
              }
              setLoader(false);
              sethasButtonDisabled(false);
              return;
            }

            const payload = {
              ...bankVerify,
              account_holder_name: accountHolderName,
              is_penny_drop_success: true,
            };
            setBankVerify(payload);
            props.setIsPennyDrop(true);
            setPennyDropStatus(1);
            onSuccess(payload);
            setLoader(false);
            successToast("Documents has been uploaded for kyc");
            setLoader(false);
            sethasButtonDisabled(false);
          } else {
            setPennyDropStatus(2);
            errorToast(res.error);
            setLoader(false);
            sethasButtonDisabled(false);
          }
        })
        .catch((error) => {
          // console.log("error :::", error);x
          console.log("error :::", error);
          setPennyDropStatus(2);
          setLoader(false);
          sethasButtonDisabled(false);
          errorToast("There is error due to api failure");
        });
    }
  };

  const handleUploadCallback = (res, id, fileName, isFileUploaded) => {
    setBankVerify({
      ...bankVerify,
      [id]: fileName,
    });
    if (isFileUploaded) {
      successToast("File uploaded successfully.");
    }
  };
  
  const handleSubmit = async () => {
    if (pennyDropStatus == 0) {
      await verifyIfscAndAccount();
    } else if (pennyDropStatus == 2) {
      onSuccess(bankVerify);
    }
  };

  const onSuccess = (payload) => {
    if (!checkValidIfsc(bankVerify["ifsc_code"])) {
      setBankVerifyError({
        ...bankVerifyError,
        ifsc_code: true,
      });
      setLoader(false);
      sethasButtonDisabled(false);
      return;
    }
    setLoader(true);
    sethasButtonDisabled(true);
    rootStore.userData
      .updateKycBank(payload)
      .then((res) => {
        if (res.kind == "ok") {
          // successToast(
          //   "Your KYC has been uploaded successfully. Be patient, while we are reviewing your documents."
          // );
          eventLog({
            type: "bank_submit",
            action: {
              trigger: "true",
            },
          });

          setLoader(false);
          props.setCurrentStep(4);
          localStorage.clear();
          sethasButtonDisabled(false);
        } else {
          errorToast(res.error);
          setPennyDropStatus(2);
          setLoader(false);
          sethasButtonDisabled(false);
        }
      })
      .catch((error) => {
        errorToast(
          "Failed to update KYC due to api failure. Please try again or else contact support."
        );
        setPennyDropStatus(2);
        setLoader(false);
        sethasButtonDisabled(false);
      });
  };

  const handletcSubmit = () => {
    
    if(!bankVerify["is_term_and_condition_accepted"])
    {
      setBankVerify({
        ...bankVerify,
        is_term_and_condition_accepted:
          !bankVerify["is_term_and_condition_accepted"],
      });
    }
    setTermsConditions(true);
    setOpenTermsAndConditionsModel(false);

    eventLog({
      type: "accept_agree_terms_and_conditions",
      action: {
        trigger: "true",
      },
    });
  };

  return (
    <React.Fragment>
      <div>
        <form className="bank_verify_form_container">
          {/*account number input box*/}
          <InputBox
            label="Account Number"
            id="account_number"
            type={bankVerifyState["account_number"]["type"]}
            value={bankVerify["account_number"]}
            setValue={setBankVerify}
            object={bankVerify}
            required={bankVerifyState["account_number"]["required"]}
            errorObject={bankVerifyError}
            setErrorObject={setBankVerifyError}
            isError={bankVerifyError["account_number"]}
            errorText="Account Number is not valid"
          />

          {/*ifsc code input box*/}
          <InputBox
            label="IFSC Code"
            id="ifsc_code"
            maxLength="11"
            autoCapitalize={true}
            type={bankVerifyState["ifsc_code"]["type"]}
            value={bankVerify["ifsc_code"]}
            setValue={setBankVerify}
            object={bankVerify}
            required={bankVerifyState["ifsc_code"]["required"]}
            errorObject={bankVerifyError}
            setErrorObject={setBankVerifyError}
            isError={bankVerifyError["ifsc_code"]}
            errorText="Invalid IFSC. Please check and re-enter."
          />

          {/**upload cancel cheque image */}

          <UploadInput
            label={"Cancelled Cheque"}
            id="cancel_cheque_image"
            visibility={pennyDropStatus == 2 ? true : false}
            fileName={bankVerify["cancel_cheque_image"]}
            setFileName={setBankVerify}
            object={bankVerify}
            uploadFileToS3={true}
            shouldRunOcr={false}
            sourceType="Cancel Cheque" //required with shouldRunOcr paramter
            callback={handleUploadCallback} // required with shouldRunOcr paramter
          />

          <ConsentText
            isConsentChecked={isConsentChecked}
            setIsConsentChecked={setIsConsentChecked}
          />

          <div
            className="bank_verify_checkbox"
            style={{
              display: props.ifa && props.ifa.kyc_status == 0 ? "flex" : "none",
            }}
            onClick={() => {
              setOpenTermsAndConditionsModel(true);
              eventLog({
                type: "open_agree_terms_and_conditions",
                action: {
                  trigger: "true",
                },
              });
            }}
          >
            <Checkbox
              checked={bankVerify["is_term_and_condition_accepted"]}
              sx={{ "& .MuiSvgIcon-root": { fontSize: 15 } }}
            />

            <div className="bank_verify_checkbox_condition">
              I agree to
              <p
                style={{
                  display: "inline-block",
                  paddingLeft: "4px",
                  padding: "0px",
                  color: "#3a86ff",
                  fontSize: "11px",
                  cursor: "pointer",
                  marginTop: "-2px",
                }}
             
              >
                Terms and Conditions.
              </p>
            </div>
          </div>

          {/* the new agreement */}
          {/* <AgreementGenerate
            isUploading={isUploading}
            setIsUploading={setIsUploading}
            ifa={props.ifa}
            bankVerify={bankVerify}
            setBankVerify={setBankVerify}
            isExcludedFirm={props.isExcludedFirm}
            setMarkedAgreement={setMarkedAgreement}
            stage={"bank"}
          /> */}
        
          {/* submit */}
          <SignupButton
            loader={loader}
            visibility={true}
            disabled={
              props.ifa.kyc_status == 3 ? !isConsentChecked 
             : !hasButtonDisabled || !termsConditions || !isConsentChecked 
            }
            text={"Verify"}
            onSubmit={handleSubmit}
          />
        </form>

        <TcViewer
          openModel={openTermsAndConditionsModel}
          onDownload={() => handleDownload(config.tc_url, "terms&conditions.pdf")}
          showDownload={true} 
          url={config.tc_url}
          setOpenModel={setOpenTermsAndConditionsModel}
          onSubmit={handletcSubmit}
    
          header={"Terms And Conditions"}
        />
      </div>
    </React.Fragment>
  );
}