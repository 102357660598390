import { Instance, SnapshotOut, types } from "mobx-state-tree";

export const User_Data_Model = types
  .model("UserDataModel")
  .props({
    uuid: types.optional(types.string, ""),
    name: types.optional(types.string, ""),
    email: types.optional(types.string, ""),
    mobile: types.optional(types.string, ""),
    kyc_status: types.optional(types.number, 0),
    referral_code: types.optional(types.string, ""),
    address: types.optional(types.string, ""),
    gst_number: types.optional(types.string, ""),
    agreement: types.optional(types.string, ""),
    pan_number: types.optional(types.string, ""),
    pan_name: types.optional(types.string, ""),
    pan_image: types.optional(types.string, ""),
    address_type: types.optional(types.string, ''),
    address_number: types.optional(types.string, ""),
    address_front_image: types.optional(types.string, ""),
    address_back_image: types.optional(types.string, ""),
    net_worth_image: types.optional(types.string, ""),
    firm_name: types.optional(types.string, ""),
    copy_of_amfi_certificate: types.optional(types.string, ""),
    copy_of_arn_card: types.optional(types.string, ""),
    memorandom_and_article_of_assosiation: types.optional(types.string, ""),
    autherised_signatory_list: types.optional(types.string, ""),
    partnership_deed_and_resolution: types.optional(types.string, ""),
    kyd_acknowledgement: types.optional(types.string, ""),
    other_document: types.optional(types.string, ""),
    is_ckyc: types.optional(types.number, 0),
    trade_license: types.optional(types.string, ""),
    account_number: types.optional(types.string, ""),
    account_holder_name: types.optional(types.string, ""),
    ifsc_code: types.optional(types.string, ""),
    cancel_cheque_image: types.optional(types.string, ""),
    is_old_user: types.optional(types.number, 0)
  })
  .views((self) => ({}))
  .actions((self) => ({}));

type User_Data_Model_DataType = Instance<typeof User_Data_Model>;
export interface User_Data extends User_Data_Model_DataType {}

type User_Data_Model_SnapShotType = SnapshotOut<typeof User_Data_Model>;
export interface User_Data_SnapShot extends User_Data_Model_SnapShotType {}
