import React, { useState, useEffect, useRef } from "react";
import "./entityKyc.css";
import Loader from "react-loader-spinner";

import {
  errorToast,
  infoToast,
  successToast,
  convertIntoDefaultState,
} from "../../utils/helper";
import { kycDetailsState } from "../../constant/signupState";
import { FileInput, ErrorValue } from "../../components/index";
import { Kyc_API } from "../../services/api";
import Auth from "@aws-amplify/auth";
import config from "../../config/settings";

const EntityKyc = props => {
  const api = new Kyc_API();
  const [loader, setLoader] = useState(false);
  const board_resolution_link = config.documents.board_resolution_link;
  const [pan, setPan] = useState("");
  const [panCtaLoader, setPanCtaLoader] = useState(false);
  const [panVerified, setPanVerified] = useState(false);
  const [panValidationFailed, setPanValidationFailed] = useState(false);
  const [panName, setPanName] = useState("");
  const [document, setDocument] = useState(
    // convertIntoDefaultState(kycDetailsState)
    {
      entity_document_pan_number:
        props.data.entity_document_pan_number === undefined
          ? ""
          : props.data.entity_document_pan_number,
      investor_type: props.investorType === undefined ? "" : props.investorType,
      entity_document_pan_image_data: {
        base64: null,
        size: null,
        content_type: null,
      },
      entity_document_pan_name: "",
      entity_document_pan_image:
        props.data.document_pan_image === undefined
          ? ""
          : props.data.document_pan_image,
      entity_document_address_front_image:
        props.data.entity_document_address_front_image === undefined
          ? ""
          : props.data.entity_document_address_front_image,
      entity_document_address_front_image_data:
        props.data.entity_document_address_front_image === undefined
          ? ""
          : props.data.entity_document_address_front_image,

      entity_document_address_back_image:
        props.data.entity_document_address_back_image === undefined
          ? ""
          : props.data.entity_document_address_back_image,
      entity_document_address_back_image_data:
        props.data.entity_document_address_back_image === undefined
          ? ""
          : props.data.entity_document_address_back_image,

      board_resolution:
        props.data.board_resolution === undefined
          ? ""
          : props.data.board_resolution,
      board_resolution_data:
        props.data.board_resolution === undefined
          ? ""
          : props.data.board_resolution,

      gst_certificate:
        props.data.gst_certificate === undefined
          ? ""
          : props.data.gst_certificate,
      gst_certificate_data:
        props.data.gst_certificate_data === undefined
          ? ""
          : props.data.gst_certificate,
    }
  );
  const [errors, setErrors] = useState({});
  const [ifaAlreadyExist, setIFAAlreadyExist] = useState(false);
  const [panVerificationButton, setPanVerificationButton] = useState(false);

  useEffect(() => {}, [document]);

  // prevent only digit and alphabet
  const onKeyPress = e => {
    const re = /[ `!@#$%^&*()_+\-={};':"|,.<>?~]/g;
    if (re.test(e.key)) {
      e.preventDefault();
    }
  };

  console.log("document======", document);
  const OnProceed = () => {
    setLoader(true);
    let arr = {};
    let found_err = false;
    var pan_format = /(^([a-zA-Z]{5})([0-9]{4})([a-zA-Z]{1})$)/;
    //if pan_number is empty
    if (pan === "") {
      found_err = true;
      arr["pan_number"] = "Please enter PAN number";
      setLoader(false);
    }
    if (ifaAlreadyExist) {
      found_err = true;
      arr["pan_number"] =
        "The client you are trying to add has already been registered by another Independent Financial Advisor (IFA).";
      setLoader(false);
    }
    if (pan !== "" && !panVerificationButton) {
      found_err = true;
      arr["pan_number"] = "Please validate your PAN.";
      setLoader(false);
    }
    ///if pan validation fails check for pan image
    if (panValidationFailed == true) {
      if (
        !document["entity_document_pan_image"] ||
        document["entity_document_pan_image"] == "" ||
        document["entity_document_pan_image"] == null
      ) {
        found_err = true;
        arr["entity_document_pan_image"] =
          "Please upload document for PAN Image";
        setLoader(false);
      }
    }

    ///check for Investor type
    if (
      props.investorType &&
      // props.investorType === "Partnership Firm" ||
      (props.investorType === "Public Limited" ||
        props.investorType === "Private Limited")
      // props.investorType === "HUF" ||
      // props.investorType === "NBFC"
    ) {
      if (!document["board_resolution"] || document["board_resolution"] == "") {
        found_err = true;
        arr["board_resolution"] = "Please upload document for Board Resolution";
        setLoader(false);
      }
    }

    if (
      !document["entity_document_address_front_image"] ||
      document["entity_document_address_front_image"] == "" ||
      document["entity_document_address_front_image"] == null
    ) {
      found_err = true;
      arr["entity_document_address_front_image"] =
        "Please upload document for Address Front Image";
      setLoader(false);
    }

    if (found_err) {
      setErrors(arr);
      return;
    }
    if (ifaAlreadyExist) {
      errorToast(
        "The client you are trying to add has already been registered by another Independent Financial Advisor (IFA)."
      );
      return;
    }
    props.document(document);
    props.setEntityType(false);
    props.setEntityPanName(panName);
    setDocument({
      ...document,
      investor_type: props.investorType,
      entity_document_pan_number: pan.toUpperCase(),
      entity_document_pan_name: panName,
    });
  };

  // validate pan number
  const onPanCheck = async () => {
    var pan_format = /(^([a-zA-Z]{5})([0-9]{4})([a-zA-Z]{1})$)/;
    const user = await Auth.currentCredentials();
    // validate pan format
    if (pan_format.test(String(pan))) {
      // payload
      let payload = {
        pan: String(pan).toUpperCase(),
        mode: "duplicate",
        ifa_user_entity: true,
        loan_app_id: props.referral ? props.referral : user.identityId,
      };
      setPanVerificationButton(true);
      let obj = {};
      obj["pan_number"] = "";
      setErrors(obj);
      // call the pan check api
      await api.checkPanNumber(payload).then(res => {
        if (res.kind == "ok") {
          const data = res.data;
          if (data.msg == "PAN has been verified successfully.") {
            setPanVerified(true);
            setDocument({
              ...document,
              entity_document_pan_number: pan.toUpperCase(),
              entity_document_pan_name: data.pan_name,
            });
            // setTimeout(() => {
            successToast(data.msg);
            setPanCtaLoader(false);
            props.setEntityPanName(data.pan_name);
            setPanName(data.pan_name);
            props.data({
              doNotFetchCkyc: true,
              verified: true,
              exist: false,
              pan: String(pan).toUpperCase(),
              pan_name: data.pan_name,
            });
            // }, 2000);
          } else if (data.mobile) {
            setTimeout(() => {
              infoToast(`${data.msg}, but KYC is incomplete.`);
              props.setEntityPanName(data.pan_name);
              setPanCtaLoader(false);
              props.data({
                doNotFetchCkyc: true,
                exist: true,
                pan: String(pan).toUpperCase(),
                mobile: data.mobile,
                pan_name: data.pan_name,
              });
            }, 2000);
          } else {
            setTimeout(() => {
              infoToast(data.msg);
              setPanCtaLoader(false);
              props.data({
                doNotFetchCkyc: true,
                verified: true,
                exist: true,
                pan: String(pan).toUpperCase(),
                pan_name: data.pan_name,
              });
            }, 2000);
          }
        } else if (res.error == "PAN does not exist in the system") {
          setTimeout(() => {
            infoToast(res.error);
            setPanCtaLoader(false);
            props.data({
              doNotFetchCkyc: true,
              exist: false,
              pan: String(pan).toUpperCase(),
            });
          }, 2000);
        } else {
          if (
            res.error ==
            "The client you are trying to add has already been registered by another Independent Financial Advisor (IFA)."
          ) {
            setIFAAlreadyExist(true);
          }
          setPanValidationFailed(true);
          errorToast(res.error);
          setPanCtaLoader(false);
          props.data({
            doNotFetchCkyc: true,
            exist: false,
            pan: String(pan).toUpperCase(),
          });
        }
      });
    } else {
      setPanCtaLoader(false);
      errorToast("Invalid PAN");
      props.data({
        doNotFetchCkyc: true,
        exist: false,
        pan: String(pan).toUpperCase(),
      });
    }
  };

  return (
    <>
      <div className="entity_type_container">
        {/* pan number */}
        <div
          className="entity_kyc_main_input_form"
          style={{ marginTop: "20px" }}
        >
          <div className="entity_details">
           <h4 className="clientkyc_main_container_title">
              Entity Details and Documents
            </h4>
          </div>
          <label className="entity_clientkyc_main_input_label">
            PAN<span style={{ color: "red" }}> *</span>
          </label>
          <input
            id="entity_document_pan_number"
            type="text"
            maxLength={10}
            value={pan}
            className="entity_clientkyc_main_input_field"
            style={{
              textTransform: "uppercase",
              borderColor: errors.hasOwnProperty("pan_number")
                ? errors["pan_number"].length > 0
                  ? "#d43939"
                  : undefined
                : undefined,
            }}
            disabled={panVerified}
            onKeyPress={onKeyPress}
            onChange={event => {
              setErrors({ ...errors, pan_number: "" });
              setPan(event.target.value);
              setPanVerificationButton(false);
              setPanValidationFailed(false);
              setIFAAlreadyExist(false);
              document.entity_document_pan_image = "";
              document.entity_document_pan_name = "";
              document.entity_document_pan_number = event.target.value;
              setDocument({ ...document });
            }}
          />
          {errors.hasOwnProperty("pan_number") ? (
            <ErrorValue text={errors["pan_number"]} />
          ) : (
            <div />
          )}
        </div>

        {/* verify pan */}
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          <button
            onClick={() => {
              setPanCtaLoader(true);
              setTimeout(() => {
                onPanCheck();
              }, 2000);
            }}
            className="clientkyc_ckyc_button"
            style={{
              display: panValidationFailed
                ? "none"
                : pan.length == 10 && !panVerified
                ? "flex"
                : "none",
            }}
            disabled={panCtaLoader}
          >
            {panCtaLoader ? (
              <Loader type="TailSpin" color="#fff" height={15} width={15} />
            ) : (
              "Verify PAN"
            )}
          </button>
        </div>

        {/* pan image */}
        {(panValidationFailed && !ifaAlreadyExist) == true ? (
          <FileInput
            id="entity_document_pan_image"
            setDocument={setDocument}
            document={document}
            label={"PAN Image"}
            placeholder={"Front side"}
            fileName={document["entity_document_pan_image"]}
            source="pan" //required with shouldRunOcr paramter
            sourceType="entity_document_pan_image" //required with shouldRunOcr paramter
            mandatory={true}
            infoText={true}
            setPanName={setPanName}
            error={errors}
            setError={setErrors}
          />
        ) : null}

        {/* address front image */}
        <FileInput
          id="entity_document_address_front_image"
          setDocument={setDocument}
          document={document}
          label={"Address Proof"}
          placeholder={"Front side*"}
          fileName={document["entity_document_address_front_image"]}
          mandatory={true}
          error={errors}
          setError={setErrors}
        />

        {/* address back image */}
        <FileInput
          id="entity_document_address_back_image"
          document={document}
          setDocument={setDocument}
          placeholder={"Back side"}
          fileName={document["entity_document_address_back_image"]}
          infoText={true}
          mandatory={false}
          error={errors}
          setError={setErrors}
        />

        {/* GST */}
        <FileInput
          id="gst_certificate"
          document={document}
          setDocument={setDocument}
          placeholder={" "}
          fileName={document["gst_certificate"]}
          label={"GST Certificate"}
          infoText={true}
          mandatory={false}
          error={errors}
          setError={setErrors}
        />

        {/* Board Resolution */}
        <FileInput
          id="board_resolution"
          document={document}
          setDocument={setDocument}
          placeholder={" "}
          fileName={document["board_resolution"]}
          label={"Board Resolution"}
          infoText={true}
          mandatory={
            props.investorType === "Partnership Firm" ||
            props.investorType === "HUF" ||
            props.investorType === "NBFC"
              ? false
              : true
          }
          error={errors}
          setError={setErrors}
        />

        <div>
          <span>
            Download template
            <a
              className="board_resolution_template"
              rel="noreferrer"
              href={board_resolution_link}
              target="_blank"
            >
              here
            </a>
          </span>
        </div>

        <div className="investor_type_proceed">
          <div className="investor_type_proceed_button" onClick={OnProceed}>
            {loader ? (
              <Loader type="TailSpin" color="#FFFFFF" height={20} width={20} />
            ) : (
              <p className="investor_type_proceed_button_text">Proceed</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EntityKyc;
