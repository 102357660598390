import React, { useState, useEffect, useRef } from "react";
import "./file-input.css";
import Loader from "react-loader-spinner";
import InputInfoText from "../../components/input-info-text/input-info-text";
import {
  errorToast,
  getFileExtension,
  getFrontendStateObject,
  getUniqueUUID,
  imageCompressor,
  warningToast,
} from "../../utils/helper";
import { ErrorValue } from "../../components/index";
import { Kyc_API } from "../../services/api";
import Auth from "@aws-amplify/auth";

const FileInput = (props) => {
  const api = new Kyc_API();
  const [loader, setLoader] = useState(false);
  const imageSizeLimit = 15;
  const fileRef = useRef(null);
  const [document, setDocument] = useState({});

  useEffect(() => {}, [props]);

  // handle changes into input file
  const onHandleChange = async (e, document_type) => {
    //const type = e.target.files[0].type;
    const ext = await getFileExtension(e)
    if (e.target.files[0].size > imageSizeLimit * 1024 * 1024) {
      errorToast(`Invalid file format. Please upload a valid JPG or PNG image under ${imageSizeLimit} MB.`);
      setLoader(false);
    } else if (ext != "jpeg" && ext != "png" && ext != "pdf" && ext != 'jpg') {
      errorToast(`Invalid file format. Please upload a valid JPG or PNG image under ${imageSizeLimit} MB.`);
      setLoader(false);
    } else {
      setLoader(true);
      let compressedImage = e.target.files[0];
      if(compressedImage.type.includes("image")){
        compressedImage = await imageCompressor(e.target.files[0]);
      }
      
      

      let uuid = await getUniqueUUID();
      let ext = await getFileExtension(e);
      let fileName = `${uuid}.${ext}`;

      let reader = new FileReader(compressedImage);
      reader.readAsDataURL(compressedImage);
      reader.onload = function (evt) {
        if (document_type == "entity_document_pan_image") {
          ocrImageCheck(
            "pan",
            "entity_document_pan_image",
            evt,
            fileName,
            compressedImage
          );
        } else {
          props.setDocument({
            ...props.document,
            [document_type]: fileName, //filename
            [`${document_type}_data`]: {
              base64: evt.target.result,
              size: compressedImage.size,
              content_type: compressedImage.type,
              [document_type]: fileName, //filename
            },
          });
          setLoader(false);
        }

        if (props.mandatory && props.error.hasOwnProperty(document_type)) {
          props.setError({ ...props.error, [document_type]: "" });
        }
      };
    }
  };

  // verify image with ocr
  const ocrImageCheck = async (
    source,
    type,
    evt,
    fileName,
    compressedImage
  ) => {
    const user = await Auth.currentCredentials();

    // payload
    let payload = {
      field_type: type,
      base64: evt.target.result,
      loan_app_id: props.referral ? props.referral : user.identityId,
      source:"pan"
    };
    

    await api.ocrCheckApi(payload).then(async (res) => {
      if (res.kind == "ok") {
        const data = res.data;
        props.setPanName(data.document_data.name);
        props.setDocument({
          ...props.document,
          entity_document_pan_name: data.document_data.name,
          entity_document_pan_image: fileName,
          [`${type}_data`]: {
            base64: evt.target.result,
            size: compressedImage.size,
            content_type: compressedImage.type,
            [type]: fileName,
          },
        });
        setLoader(false);
      } else {
        setTimeout(() => {
          errorToast(res.error);
          setLoader(false);
        }, 2000);
      }
    });
    //}
  };

  return (
    <div className="input_container">
      <div className="input_div">
        <span className="file_input_title">
          {props.label}{" "}
          <span style={{ color: "red" }}>
            {" "}
            {props.mandatory == true ? "*" : null}
          </span>
        </span>
        <div className="file_input_container">
          <div
            className="file_input_upload"
            style={{
              borderColor:
                props.mandatory && props.error.hasOwnProperty(`${props.id}`)
                  ? props.error[`${props.id}`].length > 0
                    ? "#d43939"
                    : undefined
                  : undefined,
            }}
          >
            <label for="file-input">
              <span
                className={
                  props.fileName
                    ? "file_placeholder_uploaded"
                    : "file_placeholder"
                }
              >
                {props.fileName ? `${props.fileName}` : `${props.placeholder}`}
              </span>
            </label>
          </div>
          <button
            className="file_input_button"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              e.preventDefault();
              fileRef.current.click();
            }}
          >
            {loader ? (
              <Loader type="TailSpin" color="#fff" height={15} width={15} />
            ) : props.fileName === "" ||
              props.fileName === null ||
              props.fileName === undefined ? (
              "Upload"
            ) : (
              "Change"
            )}
          </button>
          <input
            type="file"
            id={props.id}
            ref={fileRef}
            onChange={(e) => {
              e.preventDefault();
              onHandleChange(e, props.id);
            }}
            accept="image/*,.pdf"
            style={{
              display: "none",
            }}
          />
        </div>
        <div className="file_input_info_text_container">
          {props.mandatory && props.error.hasOwnProperty(`${props.id}`) ? (
            <ErrorValue text={props.error[`${props.id}`]} />
          ) : (
            <div />
          )}
          {props.infoText && props.infoText == true ? (
            <InputInfoText
              text={`Please upload the image in jpeg/png/pdf format. The maximum file size should not exceed ${imageSizeLimit} MB.`}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default FileInput;
