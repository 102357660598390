import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import {
  convertIntoDefaultState,
  convertIntoErrorState,
  errorToast,
  infoToast,
  successToast,
  checkValidPan,
  yearsBefore,
  getAge
} from "../../utils/helper";
import { InputBox, UploadInputV2, InputBoxV2 } from "../../components/index.js";
import { clientNomineeDetails } from "../../constant/clientKycState";

const ClientNomineeDetail = (props) => {
  const [addClientNomineeDetails, setAddClientNomineeDetails] = useState(
    convertIntoDefaultState(clientNomineeDetails)
  );
  const [addClientNomineeDetailsError, setAddClientNomineeDetailsError] =
    useState(convertIntoErrorState(clientNomineeDetails));
  const [startDate, setStartDate] = useState("");
  const [maxDate, setMaxDate] = useState("");

  useEffect(() => {
    const { sDate, mDate } = yearsBefore(100);
    setStartDate(sDate);
    setMaxDate(mDate);
  }, []);

  const relationship = [
    "Father",
    "Mother",
    "Brother",
    "Sister",
    "Wife",
    "Husband",
    "Son",
    "Daughter",
    "Nephew",
    "Niece"
  ];

  useEffect(() => {
    props.setNomineeDetails(addClientNomineeDetails);
    //remove error
    props.setErrorState({
      ...addClientNomineeDetailsError,
    });
  }, [addClientNomineeDetails]);

  //check for error
  useEffect(() => {
    if (props.errorState && props.errorState != null) {
      setAddClientNomineeDetailsError({
        ...addClientNomineeDetailsError,
        ...props.errorState,
      });
    }
  }, [props]);

  return (
    <div>
      {/* Nominee Name. */}
      <InputBoxV2
        label={"Name"}
        id="nominee_name"
        type={clientNomineeDetails["nominee_name"].type}
        // disabled={otpVerify}
        autoCapitalize={true}
        value={addClientNomineeDetails["nominee_name"]}
        setValue={setAddClientNomineeDetails}
        object={addClientNomineeDetails}
        required={clientNomineeDetails["nominee_name"]["required"]}
        errorObject={addClientNomineeDetailsError}
        setErrorObject={setAddClientNomineeDetailsError}
        isError={addClientNomineeDetailsError["nominee_name"]}
        errorText={
          !addClientNomineeDetails["nominee_name"]
            ? "This field cannot be empty."
            : "Invalid Nominee Name."
        }
        maxLength="255"
        inputType={"string"}
      />
      {/* Nominee Relationship */}
      <InputBoxV2
        label={"Relationship"}
        id="nominee_relationship"
        type={clientNomineeDetails["nominee_relationship"].type}
        //   disabled={true}
        dropdown={true}
        dropDownArray={relationship}
        value={addClientNomineeDetails["nominee_relationship"]}
        setValue={setAddClientNomineeDetails}
        object={addClientNomineeDetails}
        required={clientNomineeDetails["nominee_relationship"]["required"]}
        errorObject={addClientNomineeDetailsError}
        setErrorObject={setAddClientNomineeDetailsError}
        isError={addClientNomineeDetailsError["nominee_relationship"]}
        errorText={
          !addClientNomineeDetails["nominee_relationship"]
            ? "This field cannot be empty."
            : "Invalid Value"
        }
      />
      {/* Date of Birth. */}
      <InputBoxV2
        label={"Date of Birth"}
        id="nominee_dob"
        type={clientNomineeDetails["nominee_dob"].type}
        // disabled={true}
        maxLength="10"
        autoCapitalize={true}
        value={addClientNomineeDetails["nominee_dob"]}
        setValue={setAddClientNomineeDetails}
        object={addClientNomineeDetails}
        required={clientNomineeDetails["nominee_dob"]["required"]}
        errorObject={addClientNomineeDetailsError}
        setErrorObject={setAddClientNomineeDetailsError}
        isError={addClientNomineeDetailsError["nominee_dob"]}
        errorText={
          !addClientNomineeDetails["nominee_dob"]
            ? "This field cannot be empty."
            : getAge(addClientNomineeDetails["nominee_dob"]) > 18
            ? "Invalid Date of Birth."
            : "Age must exceed 18 years."
        }
        max={maxDate}
        min={startDate}
      />
      <InputBoxV2
        label={"Mobile Number"}
        id="nominee_mobile_number"
        type={clientNomineeDetails["nominee_mobile_number"].type}
        //disabled={true}
        maxLength="10"
        autoCapitalize={true}
        value={addClientNomineeDetails["nominee_mobile_number"]}
        setValue={setAddClientNomineeDetails}
        object={addClientNomineeDetails}
        required={clientNomineeDetails["nominee_mobile_number"]["required"]}
        errorObject={addClientNomineeDetailsError}
        setErrorObject={setAddClientNomineeDetailsError}
        isError={addClientNomineeDetailsError["nominee_mobile_number"]}
        errorText={clientNomineeDetails["nominee_mobile_number"] ? "Please enter a valid 10-digit mobile number." : "This field cannot be empty."}
      />
      {/* Nominee email */}
      <InputBoxV2
        label={"Email ID"}
        id="nominee_email_id"
        type={clientNomineeDetails["nominee_email_id"].type}
        //disabled={true}
        value={addClientNomineeDetails["nominee_email_id"]}
        setValue={setAddClientNomineeDetails}
        object={addClientNomineeDetails}
        required={clientNomineeDetails["nominee_email_id"]["required"]}
        errorObject={addClientNomineeDetailsError}
        setErrorObject={setAddClientNomineeDetailsError}
        isError={addClientNomineeDetailsError["nominee_email_id"]}
        errorText="Incorrect email ID format. Please use a valid email ID (example@email.com)."
        maxLength="255"
      />
      {/* pan number */}
      <InputBoxV2
        label={"PAN"}
        id="nominee_pan"
        type={clientNomineeDetails["nominee_pan"].type}
        //disabled={true}
        maxLength="10"
        autoCapitalize={true}
        value={addClientNomineeDetails["nominee_pan"]}
        setValue={setAddClientNomineeDetails}
        object={addClientNomineeDetails}
        required={clientNomineeDetails["nominee_pan"]["required"]}
        errorObject={addClientNomineeDetailsError}
        setErrorObject={setAddClientNomineeDetailsError}
        validatePan={true}
        isError={addClientNomineeDetailsError["nominee_pan"]}
        errorText={addClientNomineeDetails["nominee_pan"] ? "Invalid PAN. Please try again." : "This field cannot be empty."}
      />
    </div>
  );
};

export default ClientNomineeDetail;
