import { Instance, SnapshotOut, types } from "mobx-state-tree";

export const WithdrawalHistoryModel = types
  .model("WithdrawalHistoryModel")
  .props({
    uuid: types.optional(types.string, ""),
    name: types.optional(types.string, ""),
    amount: types.optional(types.number, 0),
    status: types.optional(types.number, 0),
    action:types.optional(types.string,""),
    mobile: types.optional(types.string,""),
    created_at: types.optional(types.string, ""),
    updated_at: types.optional(types.string, ""),
    display_modal :types.optional(types.number, 1),
    approved_at: types.optional(types.string, ""),
    processed_at: types.optional(types.string, ""),
    utr:types.optional(types.string, ""),
  })
  .views((self) => ({}))
  .actions((self) => ({}));

type WithdrawalHistoryModelDataType = Instance<typeof WithdrawalHistoryModel>;
export interface WithdrawalHistory extends WithdrawalHistoryModelDataType {}

type WithdrawalHistoryModelSnapshotType = SnapshotOut<typeof WithdrawalHistoryModel>;
export interface WithdrawalHistorySnapshot extends WithdrawalHistoryModelSnapshotType {}
