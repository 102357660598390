import React, { useEffect, useState } from "react";
import "./SwitchPlanRequest.css";
import { useParams, useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import { User_API, SwitchPlanAPI } from "../../services/api";
import InfoIcon from "@mui/icons-material/Info";
import Loader from "react-loader-spinner";
import { convertAmount, errorToast, successToast } from "../../utils/helper";
import { FullScreenLoader, InfoAlert, SampleToast } from "../../components";
import { eventLog } from "../../utils/firebase";
import config from "../../config/settings";

const SwitchPlanRequest = (props) => {
  const { uuid } = useParams();
  const navigate = useHistory();
  const api = new SwitchPlanAPI();
  const userApi = new User_API();
  const [linkData, setLinkData] = useState(null);
  const [render, setRender] = useState(false);
  const [err, setErr] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [fullLoader, setFullLoader] = useState(false);
  const [otpView, setOtpView] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [infoMsg, setInfoMsg] = useState("");
  const [verified, setVerified] = useState(false);
  const [user, setUser] = useState(null);
  const [otp, setOtp] = useState("");
  const [otpInvalid, setOtpInvalid] = useState(false);
  const [otpInvalidText, setOtpInvalidText] = useState("");

  // get the switch plan data
  useEffect(() => {
    props.showCommonHeader(false);
    props.showCommonSidebar(false);
    const getData = async () => {
      api.getSwitchPlansDetail(uuid).then((res) => {
        if (res.kind === "ok") {
          eventLog({
            type: "switch_plan_request_page",
            action: {
              trigger: "true",
            },
          });
          setLinkData(res.data);
          onSendOtp(res.data);
        } else {
          setLinkData(null);
          setRender(true);
          setErr(true);
          setErrMsg("The page you are looking for is not available.");
        }
      });
    };
    return getData();
    // eslint-disable-next-line
  }, []);

  // number key press handler
  const onKeyPress = (e) => {
    const re = /[^0-9]/g;
    if (re.test(e.key)) {
      e.preventDefault();
    }
  };

  // on send otp
  const onSendOtp = async (data) => {
    setFullLoader(true);

    // prepare payload
    const payload = {
      mobile: data.user_mobile,
      isIfa: false,
    };

    // call mobile verification api
    userApi
      .sendMobileVerification(payload)
      .then((response) => {
        if (response.kind == "ok") {
          // assign userName
          let userName = response.data.pan_number;

          // update userName
          if (response.data.oldExistingUser) {
            userName = `+91${data.user_mobile}`;
          }

          // if user exists then sign in the user
          if (response.data.isExistingUser) {
            Auth.signIn(userName)
              .then((response) => {
                setUser(response);
                setFullLoader(false);
                setRender(true);
                setOtpView(true);
                successToast("OTP has been sent successfully.");
                eventLog({
                  type: "switch_plan_request_otp",
                  action: {
                    trigger: "true",
                  },
                });
              })
              .catch((err) => {
                console.log("Error while sign in ::: ", err);
                setFullLoader(false);
                setRender(true);
                setErr(true);
                setErrMsg("The page you are looking for is not available.");
                errorToast(config.generic_msg);
                setTimeout(() => {
                  window.location.reload();
                }, 3000);
              });
          } else {
            setFullLoader(false);
            setRender(true);
            setErr(true);
            setErrMsg("The page you are looking for is not available.");
            errorToast("User doesn't exist.");
          }
        } else {
          setFullLoader(false);
          setRender(true);
          setErr(true);
          setErrMsg("The page you are looking for is not available.");
          errorToast(config.generic_msg);
        }
      })
      .catch((error) => {
        console.log("Error while sending OTP ::: ", error);
        setFullLoader(false);
        setRender(true);
        setErr(true);
        setErrMsg("The page you are looking for is not available.");
        errorToast(config.generic_msg);
      });
  };

  // on get auth
  const onGetAuth = async () => {
    setFullLoader(true);

    if (otp.length === 0) {
      setOtpInvalid(true);
      setOtpInvalidText("OTP cannot be empty.");
    } else if (otp.length !== 4) {
      setOtpInvalid(true);
      setOtpInvalidText("Invalid OTP.");
    } else if (isNaN(parseInt(otp))) {
      setOtpInvalid(true);
      setOtpInvalidText("OTP must be a number.");
    } else {
      setFullLoader(true);
      Auth.sendCustomChallengeAnswer(user, otp)
        .then(async (res) => {
          if (res && !res.Session) {
            eventLog({
              type: "switch_plan_request_otp_verified",
              action: {
                trigger: "true",
              },
            });
            setFullLoader(false);
            setOtpView(false);
            setVerified(true);
          } else {
            setFullLoader(false);
            setOtpInvalid(true);
            setOtpInvalidText(
              "Wrong OTP. Please check and enter the correct OTP."
            );
          }
        })
        .catch((err) => {
          setFullLoader(false);
          console.log("Error while verifing OTP ::: ", err);
          errorToast(config.generic_msg);
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        });
    }
  };

  // on confirm
  const onConfirm = async (status) => {
    setFullLoader(true);

    const info =
      status == 1
        ? "The request to switch plan has been approved successfully."
        : "The request to switch plan has been declined.";

    // call the api
    api.updateSwitchPlansStatus({ status: status }, uuid).then((res) => {
      if (res.kind == "ok") {
        eventLog({
          type:
            status == 1
              ? "switch_plan_request_accepted"
              : "switch_plan_request_decline",
          action: {
            trigger: "true",
          },
        });
        setFullLoader(false);
        setInfoMsg(info);
        setShowInfo(true);
      } else {
        setFullLoader(false);
        errorToast(res.error);
      }
    });
  };

  // on sign out
  const onSignOut = async () => {
    await Auth.signOut()
      .then(() => {
        navigate.push("/");
      })
      .catch((err) => {
        console.log(err);
        navigate.push("/");
      });
  };

  // switch plan details view
  const switchPlanDetailView = () => {
    return (
      <div className="switch_plan_request_div" style={{ paddingBottom: "0px" }}>
        {/* header */}
        <header className="switch_plan_request_header">
          <h1 className="switch_plan_request_header_title">
            Complete Switch Plan Request
          </h1>
        </header>

        {/* investor name */}
        <div className="switch_plan_request_div_container">
          <p className="switch_plan_request_data_title">
            {linkData.channel_partner_name} has requested to switch your
            existing investments from Freedom Plan to{" "}
            <strong>{linkData.plan_name}</strong>
          </p>
        </div>

        {/* amount */}
        <div className="switch_plan_request_div_container_2">
          <h1 className="switch_plan_request_data_title">Amount :</h1>
          <h1 className="switch_plan_request_data_subtitle">
            ₹ {convertAmount(linkData.amount)}
          </h1>
        </div>

        {/* interest rate */}
        <div className="switch_plan_request_div_container_2">
          <h1 className="switch_plan_request_data_title">Interest Rate :</h1>
          <h1 className="switch_plan_request_data_subtitle">
            Up to {parseFloat(linkData.interest_rate).toFixed(2)}%
          </h1>
        </div>

        {/* approve div */}
        <div className="switch_plan_request_button_div">
          <button
            onClick={() => onConfirm(5)}
            style={{
              backgroundColor: "transparent",
              cursor: "pointer",
              width: "45%",
              color: "#cc3a2f",
              border: "1px solid #cc3a2f",
            }}
            className="switch_plan_request_button"
          >
            Decline
          </button>

          <button
            onClick={() => onConfirm(1)}
            style={{
              backgroundColor: "#3a86ff",
              cursor: "pointer",
              width: "45%",
            }}
            className="switch_plan_request_button"
          >
            Approve
          </button>
        </div>
      </div>
    );
  };

  // otp enter view
  const otpEnterView = () => {
    return (
      <div className="switch_plan_request_div">
        {/* header */}
        <header className="switch_plan_request_header">
          <h1 className="switch_plan_request_header_title">
            Complete Switch Plan Request
          </h1>
        </header>
        {/* info */}
        <div className="otp_info_text_div">
          <p className="otp_info_text">
            {" "}
            Please enter the 4-digit OTP sent via SMS on your registered mobile
            number (+91-XXXXXX
            {linkData.user_mobile.substr(linkData.user_mobile.length - 4)})
          </p>
        </div>
        {/* otp input */}
        <input
          type="text"
          maxLength={4}
          placeholder="Enter OTP"
          className="switch_plan_request_input"
          onKeyPress={onKeyPress}
          onChange={(event) => {
            setOtpInvalid(false);
            setOtpInvalidText("");
            setOtp(event.target.value);
          }}
          style={{
            borderColor: otpInvalid ? "#ff2e2e" : undefined,
          }}
        />

        {/*validation error */}
        <div className="switch_plan_request_invalid_div">
          {/* icon */}
          <InfoIcon
            style={{
              fontSize: "14px",
              paddingRight: "5px",
              color: "#ff2e2e",
              opacity: otpInvalid ? 1 : 0,
            }}
          />
          {/* text */}
          <p
            className="switch_plan_request_invalid_text"
            style={{
              opacity: otpInvalid ? 1 : 0,
            }}
          >
            {otpInvalid ? otpInvalidText : "Invalid"}
          </p>
        </div>

        {/* button */}
        <button
          onClick={() => onGetAuth()}
          className="switch_plan_request_otp_button"
        >
          Submit
        </button>
      </div>
    );
  };

  // page not available view
  const pageNotAvailable = () => {
    return (
      <div className="switch_plan_request_page_expire">
        <h1 className="switch_plan_request_header_page_expire_title">
          {errMsg}
        </h1>
      </div>
    );
  };

  // loading
  const Loading = () => {
    return (
      <div
        style={{
          display: "flex",
          position: "fixed",
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          height: "100%",
          width: "100%",
          backgroundColor: "#fff",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 300,
        }}
      >
        <Loader type="TailSpin" color="#000" height={50} width={50} />
      </div>
    );
  };

  return (
    <div>
      {render === false ? (
        <Loading />
      ) : (
        <div className="switch_plan_request_container">
          {/* logo */}
          <img
            src="https://arthmate-p2p-public-assets.s3.ap-south-1.amazonaws.com/images/1invest-logo-color-dark.png"
            alt="logo"
            className="switch_plan_request_logo_image"
          />

          {/* main render */}
          {linkData == null || err ? (
            // page not found
            pageNotAvailable()
          ) : otpView ? (
            // otp enter view
            otpEnterView()
          ) : !otpView && verified ? (
            // switch plan detail view
            switchPlanDetailView()
          ) : (
            <></>
          )}

          {/* info alert */}
          <InfoAlert
            show={showInfo}
            ok={() => {
              setShowInfo(false);
              onSignOut();
            }}
            title="Alert"
            content={infoMsg}
            okbutton="OK"
          />

          {/* full screen loader */}
          {fullLoader ? <FullScreenLoader /> : <div />}

          {/* toast container */}
          <SampleToast />
        </div>
      )}
    </div>
  );
};

export default SwitchPlanRequest;
