import { flow, Instance, SnapshotOut, types } from "mobx-state-tree";
import {
  Clientlist,
  ClientlistModel,
  ClientlistSnapshot,
} from "../client-list/client-list";
import { Client_API } from "../../../services/api";

// make the instance of api
const api = new Client_API();

export const ClientlistStoreModel = types
  .model("ClientlistStore")
  .props({
    clientlist: types.optional(types.array(ClientlistModel), []),
  })
  .views((self) => ({}))
  .actions((self) => ({
    /**
     * @description this function is for save the client list
     * @param clientlistSnapshot
     */
    saveClientlist: (clientlistSnapshot: ClientlistSnapshot[]) => {
      //console.log(clientlistSnapshot, "snapshot client list");
      const clientlistModel: Clientlist[] = clientlistSnapshot.map((c) =>
        ClientlistModel.create(c)
      );
      self.clientlist.replace(clientlistModel);
    },
  }))
  .actions((self) => ({
    /**
     * @description this function is for get the client list
     */
    getClientlist: flow(function* (
      matured: any,
      min_investment: number = 0,
      is_kyc: any = false,
      page,
      pageSize,
      searchByName,
      sortingBy,
      isAsc,
      isIdle
    ) {
      const result = yield api.getClientlist(matured, min_investment, is_kyc,page,pageSize,searchByName,sortingBy,isAsc,isIdle);
      if (result.kind === "ok") {
        self.saveClientlist(result.clientlist);
        return { kind: "ok", data: result.clientlist,meta:result.meta };
      } else {
        return { kind: "bad-data", error: result.error };
      }
    }),
  }));

type ClientlistStoreType = Instance<typeof ClientlistStoreModel>;
export interface ClientlistStore extends ClientlistStoreType {}

type ClientlistStoreSnapshotType = SnapshotOut<typeof ClientlistStoreModel>;
export interface ClientlistStoreSnapshot extends ClientlistStoreSnapshotType {}
