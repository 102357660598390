import React, { useEffect, useState } from "react";
import "./otp-enter-model.css";
import Loader from "react-loader-spinner";
import Modal from "@mui/material/Modal";
import { InputInfoText } from "../../components";
import {
  errorToast,
  successToast
} from "../../utils/helper";
import { fontSize } from "@mui/system";


export default function OtpEnterModel(props) {
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(60);
  const [count, setCount] = useState(2);
  const [err, setErr] = useState(false);

  function Exit(props){
    errorToast("Sorry, The number of attempts exhausted!")
    props.uploadDocument()
  }

  function Attempts(){
    return(<div>
      {count<2?((<div class="otpenter_model_info_attempt_div">
          <p
            className="otpenter_model_info_atmpt"
            style={{
              // marginLeft: "5px",
              color: "#696969",
              alignContent:"left",
              justifySelf:"flex-start",
              textAlign: "left",
              alignSelf:"left",
              float:"left",
              // marginRight:"70%"
            }}
          >
          Attempts Left:{count}
          </p>
          </div>)):null}
    </div>)
  }
  // prevent only digit
  const onKeyPress = (e) => {
    const re = /[^0-9]/g;
    if (re.test(e.key)) {
      e.preventDefault();
    }
  };

  // otp timer function
  useEffect(() => {
    if (minutes == 0 && seconds == 0) {
      if (props.startTimer) {
        setMinutes(0);
        setSeconds(60);
        props.setStartTimer();
      }
    }
  });

  // otp timer function
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <Modal
      open={props.openModel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.699)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        className="otpenter_model_div"
        style={{lineHeight: "30px" }}
      >
        {/* title */}
        <div className="otpenter_model_title_div">
          <h1 className="otpenter_model_title">
           OTP has been sent to the registered mobile number linked to your Aadhaar.
          </h1>
        </div>

        {/* amount input */}
        <input
          type="text"
          placeholder="Enter OTP"
          maxLength={6}
          className="otpenter_model_input"
          onKeyPress={onKeyPress}
          value= {props.otp}
          onChange={(event) => {
            props.setOtp(event.target.value);
          }}
        />
        {Attempts()}
        {/* {count<2?(<InputInfoText text={`Attempts Left:${count}`}/>:""} */}
        {/* info */}
        {err ? (
          <div className="otpenter_model_info_div">
            <p className="otpenter_model_info">
              Sorry, Number of attempts exhausted.
            </p>
            {Exit(props)}
            {/* <p
              className="otpenter_model_info"
              style={{
                marginLeft: "5px",
                color: "#3a86ff",
                cursor: "pointer",
                fontWeight: "600",
              }}
              onClick={() => props.uploadDocument()}
            >
              Upload Documents
            </p> */}
          </div>
        ) : (
          <div className="otpenter_model_info_div">
            <p className="otpenter_model_info">Didn't receive OTP ?</p>
            {minutes === 0 && seconds === 0 ? (
              props.resendLoader ? (
                <div
                  style={{
                    marginLeft: "10px",
                  }}
                >
                  <Loader
                    type="TailSpin"
                    color="#3a86ff"
                    height={13}
                    width={13}
                  />
                </div>
              ) : (
                <p
                  className="otpenter_model_info"
                  style={{
                    marginLeft: "5px",
                    color: "#3a86ff",
                    cursor: "pointer",
                    fontWeight: "600",
                  }}
                  onClick={() => {
                    if (count == 0) {
                      setErr(true);
                    } else {
                      setCount(count - 1);
                      props.onResendOtp();
                    }
                  }}
                >
                  Resend
                </p>
              )
            ) : (
              <p
                className="otpenter_model_info"
                style={{
                  marginLeft: "5px",
                  color: "#3a86ff",
                  fontWeight: "600",
                }}
              >
                {minutes < 10 ? `0${minutes}` : minutes}:
                {seconds < 10 ? `0${seconds}` : seconds}
              </p>
            )}
            {/* <p style={{marginTop:"0px",textAlign:"end",fontSize:"12px",color:"red",marginLeft:"63%"}}>Attempts left: {count}</p> */}
          </div>
        )}
        
        
        {/* {skip button} */}
        {!err?
        (<p
          className="otpenter_model_info"
          style={{
            // marginLeft: "5px",
            color: "#3a86ff",
            cursor: "pointer",
            fontWeight: "600",
          }}
          onClick={() => props.uploadDocument()}
        >
          Didn't receive OTP? Upload Aadhaar card image instead.
        </p>):null}

        {/* send button */}
        <button className="otpenter_model_button" onClick={props.onSubmit}>
          {props.loading ? (
            <Loader type="TailSpin" color="#fff" height={15} width={15} />
          ) : (
            "Submit"
          )}
        </button>
      </div>
    </Modal>
  );
}
