import { API } from "aws-amplify";
import * as Types from "../api.types";

const bad_request: Types.GetSwitchPlanResult = {
  kind: "bad-data",
  error:
    "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
};

export class SwitchPlanAPI {
  /**
   * @description update switch plan request
   * @param data
   * @returns success or failure
   */
  async addSwitchPlansRequest(data: any): Promise<Types.GetSwitchPlanResult> {
    // payload
    const params = {
      body: data,
    };

    try {
      const response: any = await API.post(
        "baseurl",
        `/channel-partners/switch-plans`,
        params
      );
      return { kind: "ok", data: response.data };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return bad_request;
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description get switch plan list
   * @returns switch plan list
   */
  async getSwitchPlansList(): Promise<Types.GetSwitchPlanResult> {
    try {
      const response: any = await API.get(
        "baseurl",
        `/channel-partners/switch-plans`,
        {}
      );
      return { kind: "ok", data: response.data };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return bad_request;
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description get switch plan detail
   * @param uuid
   * @returns switch plan detail
   */
  async getSwitchPlansDetail(uuid: any): Promise<Types.GetSwitchPlanResult> {
    try {
      const response: any = await API.get(
        "baseurl",
        `/channel-partners/switch-plans/${uuid}`,
        {}
      );
      return { kind: "ok", data: response.data };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return bad_request;
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description update switch plan status
   * @param data
   * @param uuid
   * @returns success or faliure
   */
  async updateSwitchPlansStatus(
    data: any,
    uuid: any
  ): Promise<Types.GetSwitchPlanResult> {
    // payload
    const params = {
      body: data,
    };

    try {
      const response: any = await API.post(
        "baseurl",
        `/channel-partners/switch-plans/${uuid}`,
        params
      );
      return { kind: "ok", data: response.message };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return bad_request;
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description resend switch plan request
   * @param uuid
   * @returns success or faliure
   */
  async resendSwitchPlanRequest(uuid: any): Promise<Types.GetSwitchPlanResult> {
    try {
      const response: any = await API.post(
        "baseurl",
        `/channel-partners/switch-plans/${uuid}/resend`,
        {}
      );
      return { kind: "ok", data: response.message };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return bad_request;
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }
}
