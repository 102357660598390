import React, { useRef, useState, useEffect } from "react";
import InfoIcon from "@mui/icons-material/Info";
import "./input-box-v2.css";
import { Checkbox } from "@mui/material";
import { TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { getAge, checkValidPan, checkValidIfsc } from "../../utils/helper";

const onKeyPress = (e) => {
  const re = /[^0-9]/g;
  if (re.test(e.key)) {
    e.preventDefault();
  }
};

const onKeyPressCharacter = (e) => {
  const re = /^[a-zA-Z\s]*$/;
  if (re.test(e.key) == false) {
    e.preventDefault();
  }
};

const inputBox = {
  marginBottom: "12px",
  overflow: "hidden",
  borderRadius: "3px",
};

const inputStyle = {
  height: "45px",
  fontSize: "16px",
};

const menuItem = {
  fontSize: "16px",
};

export const InputBoxV2 = (props) => {
  // prevent key press only numbers

  const { RightLabelComponent } = props;

  useEffect(() => {
    valueUpdate(props.value);
  }, []);

  const valueUpdate = (value) => {
    if (props.hasOwnProperty("autoCapitalize") && props.autoCapitalize) {
      value = value ? value.toUpperCase() : null;
    }

    if (props.hasOwnProperty("errorObject") && props.errorObject) {
      if (
        props.label == "IFSC" &&
        props.hasOwnProperty("checkForIFSC") &&
        value != null &&
        !checkValidIfsc(value)
      ) {
        props.setErrorObject({
          ...props.errorObject,
          [props.id]: true,
        });
      } else if (
        props.type == "date" &&
        props.checkForAge &&
        getAge(new Date(value)) < 18
      ) {
        props.setErrorObject({
          ...props.errorObject,
          [props.id]: true,
        });
      } else if (
        props.label == "PAN" &&
        props.hasOwnProperty("validatePan") &&
        value != null &&
        !checkValidPan(value)
      ) {
        props.setErrorObject({
          ...props.errorObject,
          [props.id]: true,
        });
      } else if (props.id == "address" && value != null && value == "") {
        props.setErrorObject({
          ...props.errorObject,
          [props.id]: true,
        });
      } else if (
        props.type === "date" &&
        (value > props.max || value < props.min)
      ) {
        props.setErrorObject({
          ...props.errorObject,
          [props.id]: true,
        });
      } else {
        props.setErrorObject({
          ...props.errorObject,
          [props.id]: false,
        });
      }
    } else if (props.hasOwnProperty("error")) {
      props.setError(false);
    }

    if (props.object) {
      props.setValue({
        ...props.object,
        [props.id]: value,
      });
    } else {
      props.setValue(value);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();
      return;
    }
  };

  const handleChange = (e) => {
    if (e.target.value && e.target.value.length > props.maxLength) {
      return;
    }

    const sentence = e.target.value;

    let specialCharRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-\d]/;
   

    if (props.hasOwnProperty("specialCharCheck") && props.specialCharCheck) {
      //value should not contain special character
      const hasSpecialChar = specialCharRegex.test(sentence);
      if (hasSpecialChar) {
        return;
      }
    }

    if (sentence.length > 0) {
      //The sentence should not start with a space.
      const noLeadingSpaceRegex = /^[^\s]/;
      const isValidLeadingSpace = noLeadingSpaceRegex.test(sentence);

      //Only one space allowed between each word in the sentence.
      const singleSpaceRegex = /^(?!.*\s{2})\S*(?:\s\S*)*$/;
      const isValidSingleSpace = singleSpaceRegex.test(sentence);

      if (!(isValidLeadingSpace && isValidSingleSpace)) {
        return;
      }
    }

    valueUpdate(e.target.value);
      };

  return (
    <div
      style={{
        display:
          props.hasOwnProperty("visibility") && !props.visibility ? "none" : "",
      }}
    >
      <div
        className="input_box_field_container"
        style={{
          marginTop: "12px",
        }}
      >
        {props.hasOwnProperty("dropdown") && props.dropdown == true ? (
          <>
            <label
              className="input_box_v2_label_container"
              style={{ marginBottom: "10px" }}
            >
              {props.label ? props.label : ""}
              {props.required ? <span style={{ color: "red" }}> *</span> : null}
            </label>
            <TextField
              disabled={props.disabled}
              select={!props.disabled ? true : false}
              style={{
                ...inputBox,
                backgroundColor: props.disabled ? "#ececec" : undefined,
                color: props.disabled ? "rgb(119, 119, 119)" : undefined,
              }}
              InputProps={{
                style: inputStyle,
              }}
              InputLabelProps={{
                shrink: false,
                sx: {
                  fontSize: "16px",
                  position: "absolute",
                  right: "0",
                  left: "0",
                  top: "-3px",
                  width: "80%", // Need to give it a width so the positioning will work
                },
              }}
              value={props.value ? props.value : ""}
              label={props.value ? "" : "Select"}
              onChange={handleChange}
            >
              {props.dropDownArray.map((item, index) => (
                <MenuItem key={index} style={menuItem} value={item}>
                  {item}
                </MenuItem>
              ))}
            </TextField>
          </>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <label className="input_box_v2_label_container">
                {props.label}
              </label>
              {props.hasOwnProperty("required") && props.required == true ? (
                <span style={{ color: "red" }}> *</span>
              ) : (
                ""
              )}
              {props.hasOwnProperty("RightLabelComponent") && (
                <RightLabelComponent />
              )}
            </div>
            <input
              value={props.value ? props.value : ""}
              type={props.type}
              disabled={props.disabled}
              className="input_box_input_container"
              id={props.id}
              maxLength={props.maxLength ? props.maxLength : null}
              onKeyDown={handleKeyDown}
              onChange={handleChange}
              onKeyPress={
                props.hasOwnProperty("inputType") && props.inputType
                  ? onKeyPressCharacter
                  : props.type == "number"
                  ? onKeyPress
                  : null
              }
              style={{
                borderColor: props.invalid ? "#ff2e2e" : undefined,
              }}
              min={props.hasOwnProperty("min") && props.min ? props.min : ""}
              max={props.hasOwnProperty("max") && props.max ? props.max : ""}
              onWheel={(e) => e.target.blur()}
            />
          </>
        )}
      </div>

      {/*validation error */}
      <div
        className="input_box_invalid_div"
        style={{
          opacity: 1,
        }}
      >
        {/* icon */}
        <InfoIcon
          style={{
            fontSize: "14px",
            paddingRight: "5px",
            color: "#ff2e2e",
            opacity: props.isError ? 1 : 0,
          }}
        />
        {/* text */}
        <p
          className="input_box_v2_invalid_text"
          style={{
            opacity: props.isError ? 1 : 0,
          }}
        >
          {props.isError ? props.errorText : "Invalid"}
        </p>
      </div>
    </div>
  );
};

export default InputBoxV2;
