import { flow, Instance, SnapshotOut, types } from "mobx-state-tree";
import {
  WithdrawalHistory,
  WithdrawalHistoryModel,
  WithdrawalHistorySnapshot,
} from "../withdrawal-history/withdrawal-history";
import { WithdrawalHistory_Api } from "../../../services/api/WithdrawalHistory/WithdrawalHistoryApi";

// make the instance of api
const api = new WithdrawalHistory_Api();

export const WithdrawalHistoryStoreModel = types
  .model("WithdrawalStore")
  .props({
    withdrawalHistory: types.optional(types.array(WithdrawalHistoryModel), []),
  })
  .views((self) => ({}))
  .actions((self) => ({
    /**
     * @description this function is to save the client list
     * @param withdrawalHistorySnapshot
     */
    saveWithdrawalHistory: (withdrawalHistorySnapshot: WithdrawalHistorySnapshot[]) => {
        // console.log(withdrawalHistorySnapshot, "snapshot withdraw")
      const withdrawalHistoryModel: WithdrawalHistory[] = 
      
      withdrawalHistorySnapshot.map((c) =>
      WithdrawalHistoryModel.create(c)
      );
      self.withdrawalHistory.replace(withdrawalHistoryModel);
    },
  }))
  .actions((self) => ({
    /**
     * @description this function is for get the client list
     */
     getWithdrawalHistory: flow(function* (data) {
      const result = yield api.getWithdrawalHistory(data);
      // console.log(result, "result")
      if (result.kind === "ok") {
        self.saveWithdrawalHistory(result.data);
        return { kind: "ok" };
      } else {
        return { kind: "bad-data", error: result.error };
      }
    }),
  }));

type WithdrawalHistoryStoreType = Instance<typeof WithdrawalHistoryStoreModel>;
export interface WithdrawalHistoryStore extends WithdrawalHistoryStoreType {}

type WithdrawalHistoryStoreSnapshotType = SnapshotOut<typeof WithdrawalHistoryStoreModel>;
export interface WithdrawalHistoryStoreSnapshot extends WithdrawalHistoryStoreSnapshotType {}
