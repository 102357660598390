import React, { useEffect, useState } from "react";
import checkMark from "../../assets/images/checkMark.svg";
import failureIcon from "../../assets/images/failure.svg";
import { eventLog } from "../../utils/firebase";
import { convertTimestampFormat, errorToast, dateTimeFormat } from "../../utils/helper";
import { IFA_Payment_API } from "../../services/api";
import { LoaderScreen, LoaderScreenTimeOut } from "../../components/index";
import "./walletrequeststatus.css";
import config from "../../config/settings";


const WalletRequestStatus = (props) => {
  const [params, setParams] = useState({});
  const api = new IFA_Payment_API();
  const [render, setRender] = useState(false);
  const [status, setStatus] = useState("");
  const [polling, setPolling] = useState(false);
  const [pollingFailed, setPollingFailed] = useState(false);
  const [data, setData] = useState({
    amount: 0,
    payment_id: "",
    status: "NOT DEFINE",
    txn_date: "",
  });
  let counter = 5;

  useEffect(() => {
    props.showCommonHeader(false);
    props.showCommonSidebar(false);
    setParams(props.match.params);
  }, []);

  useEffect(async () => {
    if (params.user_id && params.transaction_id && status == "") {
      //poll on function
      for (var i = counter; i > 0; i--) {
        setPolling(true);
        //delay
        await sleep(5000);
        counter = counter - 1;

        const poll = await fetchPaymentStatus();
        
        if (poll && poll.code == "PAYMENT_SUCCESS") {
          console.log("reached success");
          setPolling(false);
          setStatus(poll.code);
          setRender(true);
          setData(poll.data);
          break;
        } else if (poll && poll.code == "PAYMENT_ERROR") {
          setPolling(false);
          setStatus(poll.code);
          setRender(true);
          setData(poll.data);
          break;
        }

        if (counter == 0) {
          setPolling(false);
          setPollingFailed(true);
        }
      }
    }
  }, [params, counter]);

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const fetchPaymentStatus = async () => {
    const data = {
      user_id: params.user_id,
      transaction_id: params.transaction_id,
      mode: "IFA"
    };

    return await api
      .checkPhonepePaymentStatus(data)
      .then((res) => {
        if (res.kind == "ok") {
          eventLog({
            type: "payment_success",
            action: {
              trigger: "true",
            },
          });
          return res.data;
        } else {
          errorToast("Error while fetching order details");
          return null;
        }
      })
      .catch((err) => {
        console.log("err", err);
        return null;
      });
  };

  return (
    <React.Fragment>
      {polling == true ? (
        <LoaderScreen />
      ) : pollingFailed == true ? (
        <LoaderScreenTimeOut />
      ) : render === false ? (
        <div />
      ) : (
        <React.Fragment>
          <div className="wallet_request_success_header">
            <img
              src="https://arthmate-p2p-public-assets.s3.ap-south-1.amazonaws.com/images/1invest-logo-color-dark.png"
              alt="logo"
              className="auth_logo_img"
              onClick={() =>
                window.open("https://www.arthmatedirect.com/", "_self")
              }
            />
          </div>

          {status == "PAYMENT_SUCCESS" ? (
            <div className="wallet_request_success_page_container">
              <br />
              <br />
              <div className="wallet_request_success_head_grid_container">
                <div style={{ textAlign: "center" }}>
                  <img
                    src={checkMark}
                    alt="check_mark_icon"
                    className="wallet_request_success_check_mark"
                  />
                  <p className="wallet_request_success_main_title_text">
                    Transaction Successful
                  </p>
                </div>
                <br />
                <div className="wallet_request_success_grid_container"></div>
                <div className="wallet_request_success_grid_container">
                  <div className="wallet_request_success_item_left">
                    <p className="wallet_request_success_item_text">
                      Order ID
                    </p>
                  </div>
                  <div className="wallet_request_success_item_right">
                    <p className="wallet_request_success_item_text">
                      {params.transaction_id}
                    </p>
                  </div>
                </div>
                <div className="wallet_request_success_grid_container">
                  <div className="wallet_request_success_item_left">
                    <p className="wallet_request_success_item_text">Amount</p>
                  </div>
                  <div className="wallet_request_success_item_right">
                    <p className="wallet_request_success_item_text">
                      ₹ {data.amount}
                    </p>
                  </div>
                </div>
                <div className="wallet_request_success_grid_container">
                  <div className="wallet_request_success_item_left">
                    <p className="wallet_request_success_item_text">
                      Transaction ID
                    </p>
                  </div>
                  <div className="wallet_request_success_item_right">
                    <p className="wallet_request_success_item_text">
                      {data.transactionId}
                    </p>
                  </div>
                </div>
                <div className="wallet_request_success_grid_container">
            <div className="wallet_request_success_item_left">
              <p className="wallet_request_success_item_text">TXN Date</p>
            </div>
            <div className="wallet_request_success_item_right">
              <p className="wallet_request_success_item_text">
                {dateTimeFormat()}
              </p>
            </div>
          </div>
              </div>
              <div className="wallet_request_success_main_title">
                <p>
                  Congratulations! Your funds have been added to your wallet.
                </p>
                <div className="wallet_request_success_grid_container"></div>
              </div>
            </div>
          ) : (
            <div className="wallet_request_failure_page_container">
              <>
                <div className="wallet_request_failure_head_grid_container">
                  <div style={{ textAlign: "center" }}>
                    <img
                      src={failureIcon}
                      alt="check_mark_icon"
                      className="wallet_request_failure_check_mark"
                    />
                    <p className="wallet_request_failure_main_title_text">
                      Transaction Failed
                    </p>
                  </div>
                  <br />
                  <div className="wallet_request_failure_grid_container"></div>
                  <div className="wallet_request_success_grid_container">
                    <div className="wallet_request_success_item_left">
                      <p className="wallet_request_success_item_text">
                        Order ID
                      </p>
                    </div>
                    <div className="wallet_request_success_item_right">
                      <p className="wallet_request_success_item_text">
                        {params.transaction_id}
                      </p>
                    </div>
                  </div>
                  <div className="wallet_request_failure_grid_container">
                    <div className="wallet_request_failure_item_left">
                      <p className="wallet_request_failure_item_text">Amount</p>
                    </div>
                    <div className="wallet_request_failure_item_right">
                      <p className="wallet_request_failure_item_text">
                        ₹ {data.amount}
                      </p>
                    </div>
                  </div>
                  <div className="wallet_request_failure_grid_container">
                    <div className="wallet_request_failure_item_left">
                      <p className="wallet_request_failure_item_text">
                        Transaction ID
                      </p>
                    </div>
                    <div className="wallet_request_failure_item_right">
                      <p className="wallet_request_failure_item_text">
                        {data.transactionId}
                      </p>
                    </div>
                  </div>
                  <div className="wallet_request_failure_grid_container">
                    <div className="wallet_request_failure_item_left">
                      <p className="wallet_request_failure_item_text">
                        TXN Date
                      </p>
                    </div>
                    <div className="wallet_request_failure_item_right">
                      <p className="wallet_request_failure_item_text">
                        {dateTimeFormat()}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="wallet_request_failure_main_title">
                  <p style={{ color: "#4E4E4E" }}>
                    If the amount has been debited from your bank, it will take
                    2-3 business days to refund.
                  </p>
                  <p style={{ color: "#4E4E4E" }}>
                    Please contact us at{" "}
                    <strong style={{ color: "#3a86ff" }}>
                      {config.support.email}
                    </strong>{" "}
                    with any questions about support.
                  </p>
                  <div className="wallet_request_failure_grid_container"></div>
                </div>
              </>
            </div>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default WalletRequestStatus;
