import React, { useEffect, useState, useRef } from "react";
import MenuItem from "@mui/material/MenuItem";
// import { state_array } from "../../config/settings";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import InfoIcon from "@mui/icons-material/Info";
import "./state-dropdown.css";
import { TextField } from "@mui/material";

const StateDropdown = (props) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(props.dataArray);
  const [selectedOption, setSelectedOption] = useState("");
  const [showDropDown, setShowDropdown] = useState(false);

  const textInput = useRef(null);

  useEffect(() => {
    textInput.current?.focus();
  }, [showDropDown]);

  useEffect(() => {
    // Filter options based on the search term
    const filtered = props.dataArray.filter((option) =>
      option.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredOptions(filtered);
  }, [searchTerm, props.dataArray]);

  //handle change
  const handleInputChange = (event) => {
    let specialCharRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-\d]/;

    if (props.hasOwnProperty("specialCharCheck") && props.specialCharCheck) {
      //value should not contain special character
      const hasSpecialChar = specialCharRegex.test(event.target.value);
      if (hasSpecialChar) {
        return;
      }
    }

    if (props.hasOwnProperty("isError")) {
      props.setErrorObject({
        ...props.errorObject,
        [props.id]: false,
      });
    }

    //set value
    props.setValue({
      ...props.object,
      [props.id]: event.target.value,
    });

    setSearchTerm(event.target.value);
    setSelectedOption(event.target.value);
    setShowDropdown(true);
  };

  //handle dropdown options
  const handleOptionSelect = (option) => {
    //error
    if (props.hasOwnProperty("isError")) {
      props.setErrorObject({
        ...props.errorObject,
        [props.id]: false,
      });
    }

    //set value
    props.setValue({
      ...props.object,
      [props.id]: option,
    });

    setSelectedOption(option);
    setShowDropdown(false);
    setSearchTerm("");
  };

  //handle input click
  const handleClick = () => {
    setShowDropdown(!showDropDown);
  };

  const menuItem = {
    fontSize: "16px",
  };

  const inputBox = {
    marginBottom: "12px",
    overflow: "hidden",
    borderRadius: "3px",
  };

  const inputStyle = {
    height: "45px",
    fontSize: "16px",
  };

  return (
    <>
      <div className="state_dropdown_main_contianer">
        <label for="state_drop_down">
          <span className="state_drop_down_label">
            {props.label ? props.label : ""}
          </span>
        </label>
        {props.hasOwnProperty("required") && props.required == true ? (
          <span style={{ color: "red" }}> *</span>
        ) : (
          ""
        )}

        {showDropDown ? (
          <div
            className={`state-dropdown-container${
              props.disabled ? "-disabled" : ""
            }`}
          >
            {/* icon */}
            <>
              <SearchIcon
                style={{
                  fontSize: "20px",
                  padding: "5px",
                  paddingRight: "10px",
                  color: "#555555",
                  backgroundColor: "#eeeeee",
                  borderRadius: "4px 0px 0px 4px",
                  display: props.disabled ? "none" : "",
                }}
              />

              <input
                ref={textInput}
                type="text"
                id={props.id ? props.id : "state_drop_down"}
                placeholder="Search state"
                disabled={props.disabled}
                value={selectedOption || props.value}
                className="state_dropdown_input_field"
                onChange={handleInputChange}
                onClick={handleClick}
              />
            </>
          </div>
        ) : (
          <div
            className="dropdown-select"
            onClick={props.disabled ? "" : handleClick}
          >
            <span className="dropdown-select-text">
              {props.value ? props.value : "Select"}
            </span>
            <KeyboardArrowDownIcon />
          </div>
        )}

        <div className="state-dropdown-list">
          {showDropDown &&
            filteredOptions.map((option, index) => (
              <MenuItem
                key={index}
                style={menuItem}
                value={option}
                onClick={() => {
                  handleOptionSelect(option);
                }}
              >
                {option}
              </MenuItem>
            ))}
        </div>
      </div>

      {/*validation error */}
      <div
        className="input_box_invalid_div"
        style={{
          opacity: 1,
        }}
      >
        {/* icon */}
        <InfoIcon
          style={{
            fontSize: "14px",
            paddingRight: "5px",
            color: "#ff2e2e",
            opacity: props.isError ? 1 : 0,
          }}
        />
        {/* text */}
        <p
          className="input_box_v2_invalid_text"
          style={{
            opacity: props.isError ? 1 : 0,
          }}
        >
          {props.isError ? props.errorText : "Invalid"}
        </p>
      </div>
    </>
  );
};

export default StateDropdown;
