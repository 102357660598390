import React, { useEffect, useState } from "react";
import "./prePanValidationV2.css";
import Loader from "react-loader-spinner";
import {
  convertIntoDefaultState,
  convertIntoErrorState,
  errorToast,
  infoToast,
  successToast,
  checkValidPan,
} from "../../utils/helper";
import { InputBox } from "../../components/index.js";
import { clientKycBasicDetail } from "../../constant/signupState";
import Checkbox from "@mui/material/Checkbox";
import timeoutfailed from "../../assets/images/timeoutfailed.svg";
import { KYC_API_V2 } from "../../services/api";
import { eventLog } from "../../utils/firebase";
import config from "../../config/settings";

const PrePanValidationV2 = (props) => {
  const add_client_pan_consent_msg =
    config.documents.consent_msg.add_client_pan_consent_msg;

  const api = new KYC_API_V2();

  let [maxTries, setMaxTries] = useState(2); //trials for pan validation

  const [addClientBasicDetails, setAddClientBasicDetails] = useState(
    convertIntoDefaultState(clientKycBasicDetail)
  );

  const [addClientBasicDetailsError, setAddClientBasicDetailsError] = useState(
    convertIntoErrorState(clientKycBasicDetail)
  );

  const [loader, setLoader] = useState(false);

  const [prePanValidationFailed, setPrePanValidationFailed] = useState(false);

  // console.log("maxTries ::: ", maxTries);

  const prePanValidationCall = async () => {
    setLoader(true);
    // check for pan validation
    if (!checkValidPan(addClientBasicDetails["pan"])) {
      setAddClientBasicDetailsError({
        ...addClientBasicDetailsError,
        pan: true,
      });
      setLoader(false);
      return;
    }

    if (!addClientBasicDetails["is_pan_consent_accepted"]) {
      errorToast("Please provide consent in order to move ahead.", true);
      setLoader(false);
      return;
    }

    props.setPrePanValidation(1);
    props.setPreValidateData({
      pan: addClientBasicDetails["pan"],
      is_pan_consent_accepted:
        addClientBasicDetails["is_pan_consent_accepted"],
    });

    setLoader(false);
     
  //  // payload
  //   let payload = {
  //     pan: String(addClientBasicDetails["pan"]).toUpperCase(),
  //     dob: String(addClientBasicDetails["dob"]),
  //     pan_name: String(addClientBasicDetails["pan_name"]),
  //   };

  //   // call the pan check api
  //   await api.checkPanNumber(payload).then((res) => {nallama

  //     if (res.kind == "ok") {
  //       const data = res.data;
  //       if (data.message == "PAN verified successfully") {
  //         eventLog({
  //           type: "add_client_pan_verify",
  //           action: {
  //             trigger: "true",
  //           },
  //         });
  //         successToast(data.message);
  //         props.setPrePanValidation(1);
  //         props.setPreValidateData({
  //           ...data,
  //           pan:addClientBasicDetails["pan"],
  //           email:addClientBasicDetails["email"],
  //           mobile:addClientBasicDetails["mobile"],
  //           gender:addClientBasicDetails["gender"],
  //           dob:addClientBasicDetails["dob"],
  //           is_pan_consent_accepted:
  //             addClientBasicDetails["is_pan_consent_accepted"],
  //         });

  //         setLoader(false);
  //       } else {
  //         eventLog({
  //           type: "add_client_pan_verify",
  //           action: {
  //             trigger: "false",
  //           },
  //         });
  //         successToast(data.message);
  //         setLoader(false);
  //       }
  //     } else {
  //       //trials
  //       if (maxTries == 0) {
  //         setPrePanValidationFailed(true);
  //         props.setPrePanValidation(2);
  //         eventLog({
  //           type: "add_client_pan_verify",
  //           action: {
  //             trigger: "false",
  //           },
  //         });
  //         return;
  //       } else {
  //         setMaxTries((maxTries = maxTries - 1));
  //       }

  //       errorToast(res.error, true);
  //       setLoader(false);
  //     }
  //   });
  };

  return (
    <>
      <div className="add_client_kyc_pre_pan_container">
        <div className="add_client_kyc_pre_pan_main_container">
          <h4 className="add_client_kyc_pre_pan_main_container_title">
            Client details
          </h4>
          {/* pan number */}
          <InputBox
            label={"PAN"}
            id="pan"
            type={clientKycBasicDetail["pan"].type}
            // disabled={status > 0 ? true : false}
            maxLength="10"
            autoCapitalize={true}
            value={addClientBasicDetails["pan"]}
            setValue={setAddClientBasicDetails}
            object={addClientBasicDetails}
            required={clientKycBasicDetail["pan"]["required"]}
            errorObject={addClientBasicDetailsError}
            setErrorObject={setAddClientBasicDetailsError}
            isError={addClientBasicDetailsError["pan"]}
            errorText={
              !addClientBasicDetailsError["pan"]
                ? "This field cannot be empty."
                : "Invalid PAN. Please try again."
            }
          />

          {/* pan consent */}
          <div className="add_cientkycv2_pan_consent_checkbox">
            <Checkbox
              checked={addClientBasicDetails["is_pan_consent_accepted"]}
              onChange={() =>
                setAddClientBasicDetails({
                  ...addClientBasicDetails,
                  is_pan_consent_accepted:
                    !addClientBasicDetails["is_pan_consent_accepted"],
                })
              }
            />

            <div className="add_cientkycv2_pan_consent_checkbox_condition">
              {add_client_pan_consent_msg}
            </div>
          </div>
        </div>

        {/* cta */}
        <div
          className="add_client_kyc_pre_pan_submit_cta"
          onClick={(e) => {
            prePanValidationCall();
          }}
        >
          {loader ? (
            <Loader type="TailSpin" color="#FFFFFF" height={25} width={25} />
          ) : (
            <p className="add_client_kyc_pre_pan_cta_text">Proceed</p>
          )}
        </div>
      </div>
    </>
  );
};

export default PrePanValidationV2;
