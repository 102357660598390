import React, { useEffect, useState } from "react";
import "./clientkyc.css";
import Loader from "react-loader-spinner";
import {
  errorToast,
  handleDownload,
  infoToast,
  queryStringToJSON,
  successToast,
} from "../../utils/helper";
import { Client_API } from "../../services/api/Clients/clients-api";
import Auth from "@aws-amplify/auth";
import { eventLog } from "../../utils/firebase";
import { PdfViewer, SampleToast, AlreadyAcceptedModal } from "../../components";
import checkMark from "../../assets/images/checkMark.svg";
import { createFalse } from "typescript";
import { ContactSupportOutlined } from "@mui/icons-material";
import { Redirect } from "react-router-dom";
import config from "../../config/settings";
import DoneSharpIcon from "@mui/icons-material/DoneSharp";
import TcViewer from "../../components/tc-viewer/tc-viewer";

const ClientTc = function (props) {
  const api = new Client_API();
  const [pan, setPan] = useState("");
  const [panCtaLoader, setPanCtaLoader] = useState(false);
  const [render, setRender] = useState(false);
  const [isTNCModalOpen, setIsTNCModalOpen] = useState(false);
  const [clientTNCModelLoader, setClientTNCModelLoader] = useState(false);
  const [acceptTerm, setAcceptTerm] = useState(false);
  const [clientOTP, setClientOTP] = useState("");
  const [query, setQuery] = useState({
    mobile: null,
    email: null,
    name: null,
    referral_code: null,
  });
  const [isError, setIsError] = useState(false);
  const [accept, setAccept] = useState(false);
  const [alreadyAccepted, setAlreadyAccepted] = useState(true);
  const [ifaName, setIfaName] = useState("select club");
  const [ppCheck, setPpCheck] = useState(false);
  const [tcCheck, setTcCheck] = useState(false);
  const [personalCheck, setPersonalCheck] = useState(false);
  const [reportsCheck, setReportsCheck] = useState(false);
  const [fundsCheck, setFundsCheck] = useState(false);
  const [kycCheck, setKycCheck] = useState(false);
  const [bankCheck, setBankCheck] = useState(false);
  const [showAcceptCta, setShowAcceptCta] = useState(false);
  const [nomineeDetails, setNomineeDetails] = useState("");
  const [openPrivacyPolicyModal, setOpenPrivacyPolicyModal] = useState(false);
  const [acceptedModelOpen, setAcceptedModelOpen] = useState(false);

  // send pan value
  useEffect(async () => {
    const search = queryStringToJSON(props.location.search);
    const params = props.match.params;

    if (
      !search.mobile ||
      !search.email ||
      !search.name ||
      !params.referral_code
    ) {
      setIsError(true);
      setRender(true);
    } else {
      let payload = {
        referral_code: params?.referral_code,
        mobile: search?.mobile,
      };
      await api
        .getNomineeDetails(payload)
        .then((res) => {
          if (res.kind == "ok") {
            if (
              res.data.hasOwnProperty("nominee_exist") &&
              res.data.nominee_exist === 1
            ) {
              setNomineeDetails(res.data?.nominee_details);
              setIfaName(res.data?.ifa_name);
              setRender(true);
            } else {
              setIfaName(res.data?.ifa_name);
              setRender(true);
            }
          } else {
            setIsError(true);
            setRender(true);
          }
        })
        .catch((err) => {
          setIsError(true);
          setRender(true);
        });
      setQuery({
        mobile: search?.mobile,
        email: search?.email,
        name: search?.name,
        referral_code: params?.referral_code,
      });
    }
  }, []);

  // show accept CTA
  useEffect(() => {
    if (ppCheck && tcCheck && personalCheck && kycCheck && bankCheck) {
      setShowAcceptCta(true);
    } else {
      setShowAcceptCta(false);
    }
  }, [ppCheck, tcCheck, personalCheck, kycCheck, bankCheck]);

  // generate otp
  const generateOTP = async () => {
    if (!showAcceptCta) {
      errorToast("To continue, please provide your consent.");
      return;
    }
    setClientTNCModelLoader(true);
    // payload
    let payload = {
      name: query.name,
      mobile: query.mobile,
      email: query.email,
      ifa_referral_code: query.referral_code,
      tc_url: config.tc_url,
      mode: "ifa",
      invest_consent: fundsCheck,
      report_consent: reportsCheck,
    };

    await api.getClientConsent(payload).then((res) => {
      if (res.kind === "ok") {
        eventLog({
          type: "accept_tnc",
          action: {
            trigger: "true",
          },
        });
        setClientTNCModelLoader(false);
        setAccept(true);
        setTimeout(() => { }, 2000);
      } else if (res.error.hasOwnProperty("alreadyAccepted")) {
        console.log("reached");
        setClientTNCModelLoader(false);
        setAlreadyAccepted(true);
        errorToast("Terms & Conditions are already accepted for this user");
      } else {
        setTimeout(() => {
          errorToast(res.error);
          setClientTNCModelLoader(false);
        }, 2000);
      }
    });
  };

  const nomineeDetail = (key, value) => {
    return (
      <div className="tc_nominee_row">
        <div className="dot"></div>
        <div className="nominee_key">{key} :</div>
        <div className="nominee_value">
          {!value || value === "" ? "Not Provided" : value}
        </div>
      </div>
    );
  };

  const checkbox_icon = (checked, setChecked) => {
    return (
      <div
        className={checked ? "consent_checked" : "consent_unchecked"}
        onClick={() => setChecked(!checked)}
      >
        {checked ? (
          <DoneSharpIcon fontSize="inherit" sx={{ color: "white" }} />
        ) : null}
      </div>
    );
  };

  return (
    <div>
      {/* header */}
      <div className="client_tc_header" style={{ zIndex: 1000 }}>
        <img
          src="https://arthmate-p2p-public-assets.s3.ap-south-1.amazonaws.com/images/1invest-logo-color-dark.png"
          alt="logo"
          className="client_tc_logo_image"
          onClick={() =>
            window.open("https://www.arthmatedirect.com/", "_self")
          }
        />
      </div>

      {/* 3rd party t&c */}
      {!render ? (
        <div className="tc_loader">
          <Loader
            type="TailSpin"
            color="#414141"
            height={80}
            width={80}
            style={{
              marginTop: "200px",
            }}
          />
        </div>
      ) : !isError ? (
        <React.Fragment>
          {!accept ? (
            <>
              {/* <PdfViewer
                header={"Terms & Conditions"}
                openModel={isTNCModalOpen}
                setOpenModel={setIsTNCModalOpen}
                onSubmit={() => {
                  setIsTNCModalOpen(false);
                  setTcCheck(true);
                }}
                url={config.tc_url}
              /> */}
              
              <TcViewer
                openModel={isTNCModalOpen}
                onDownload={() => handleDownload(config.tc_url, "terms&conditions.pdf")}
                showDownload={true}
                url={config.tc_url}
                setOpenModel={setIsTNCModalOpen}
                onSubmit={() => {
                  setIsTNCModalOpen(false);
                  setTcCheck(true);
                }}
                header={"Terms And Conditions"}
              />

              <PdfViewer
                openModel={openPrivacyPolicyModal}
                header={"Privacy Policy"}
                setOpenModel={setOpenPrivacyPolicyModal}
                url={config.privacy_policy_url}
              />
              <div className="tc_main_container">
                <div className="tc_main_content">
                  <div className="tc_title">
                    IFA {ifaName} has requested to add you as an investor with
                    1Invest.
                  </div>
                  {nomineeDetails !== "" ? (
                    <div className="tc_nominee_details">
                      <div className="tc_nominee_header">
                        Nominee Details submitted by IFA are:
                      </div>
                      <div className="tc_nominee_content">
                        {nomineeDetail(
                          "Nominee Name",
                          nomineeDetails?.nominee_name
                        )}
                        {nomineeDetail(
                          "Nominee Relationship",
                          nomineeDetails?.nominee_relationship
                        )}
                        {nomineeDetail(
                          "Nominee DOB",
                          nomineeDetails?.nominee_dob
                        )}
                        {nomineeDetail(
                          "Nominee Mobile",
                          nomineeDetails?.nominee_mobile
                        )}
                        {nomineeDetail(
                          "Nominee Email",
                          nomineeDetails?.nominee_email
                        )}
                        {nomineeDetail(
                          "Nominee PAN",
                          nomineeDetails?.nominee_pan
                        )}
                      </div>
                    </div>
                  ) : null}
                  <div className="checkbox_content">
                    {checkbox_icon(ppCheck, setPpCheck)}
                    <div className="consent_text">
                      I have read and accept the{" "}
                      <span
                        style={{ color: "#00376F", cursor: "pointer" }}
                        className="consent_text"
                        onClick={() => setOpenPrivacyPolicyModal(true)}
                      >
                        Privacy Policy
                      </span>{" "}
                      of 1Invest and hereby consent to use my KYC for any
                      verification.*
                    </div>
                  </div>
                  <div className="checkbox_content">
                    {checkbox_icon(tcCheck, setIsTNCModalOpen)}
                    <div className="consent_text">
                      I have read and agree to the{" "}
                      <span
                        style={{ color: "#00376F", cursor: "pointer" }}
                        className="consent_text"
                        onClick={() => setIsTNCModalOpen(true)}
                      >
                        Terms and Conditions
                      </span>
                      .*
                    </div>
                  </div>
                  <div className="checkbox_content">
                    {checkbox_icon(kycCheck, setKycCheck)}
                    <div className="consent_text">
                      I hereby give my consent and authorize {ifaName} for
                      onboarding with 1Invest. I further authorize {ifaName} to
                      share my KYC and related information with 1Invest and also
                      give my consent to {ifaName} and 1Invest to retrieve my
                      PAN details from NSDL and KYC from CKYC Registry for the
                      purpose of validation and verification.*
                    </div>
                  </div>
                  <div className="checkbox_content">
                    {checkbox_icon(bankCheck, setBankCheck)}
                    <div className="consent_text">
                      I hereby give my consent and authorize {ifaName} for
                      onboarding with 1Invest. I further authorize {ifaName} to
                      share my bank details and related information with 1Invest
                      and also give my consent to {ifaName} and 1Invest to
                      verify my bank details for the purpose of verification.*
                    </div>
                  </div>
                  <div className="checkbox_content">
                    {checkbox_icon(personalCheck, setPersonalCheck)}
                    <div className="consent_text">
                      I hereby give my consent to {ifaName} to access my
                      personal and financial information for the purpose of
                      managing my account on 1Invest.*
                    </div>
                  </div>
                  <div className="checkbox_content">
                    {checkbox_icon(reportsCheck, setReportsCheck)}
                    <div className="consent_text">
                      I hereby give my consent to {ifaName} to access any
                      reports, certificates or statements linked to my
                      investments on 1Invest.
                    </div>
                  </div>
                  <div className="checkbox_content">
                    {checkbox_icon(fundsCheck, setFundsCheck)}
                    <div className="consent_text">
                      I hereby authorize {ifaName} to act on my behalf for
                      selecting the 1Invest plan and investing funds from my
                      escrow account maintained with RNVP Technology Private
                      Limited. This authorization is revocable at any time, for
                      any reason.
                    </div>
                  </div>
                  {/* {showAcceptCta ? ( */}
                  <div className="tc_agree_cta" onClick={generateOTP}>
                    {clientTNCModelLoader ? (
                      <Loader
                        type="TailSpin"
                        color="#FFFFFF"
                        height={25}
                        width={25}
                      />
                    ) : (
                      <p className="tc_cta_text">Authorize via OTP</p>
                    )}
                  </div>
                  {/* ) : null} */}
                  {acceptedModelOpen ? (
                    <AlreadyAcceptedModal
                      closeModel={setAcceptedModelOpen}
                      header={props.header}
                    ></AlreadyAcceptedModal>
                  ) : (
                    <div />
                  )}
                </div>
              </div>
            </>
          ) : (
            <div style={{ marginTop: "8vh" }}>
              <div className="client_thank_page_container">
                <div className="client_thank_image_container">
                  <img
                    src={checkMark}
                    alt="check_mark_icon"
                    className="check_mark"
                  />
                </div>
                <div className="client_thank_main_title">
                  <h3>OTP Sent</h3>
                  <p>
                    We've sent an OTP to your registered mobile number, provided
                    by {ifaName}. Please share this OTP with {ifaName} to
                    complete your KYC and begin investing with 1Invest.
                  </p>
                </div>
              </div>
            </div>
          )}
        </React.Fragment>
      ) : (
        <div className="clientpagenotfound_container">
          <div className="clientpagenotfound_rest_container">
            <div>
              <p className="clientpagenotfound_container_title">404</p>
              <p className="clientpagenotfound_container_subtitle">
                Sorry, the page you are looking for is not available.
              </p>
            </div>
          </div>
        </div>
      )}

      {/* toast container */}
      <SampleToast />
    </div>
  );
};

export default ClientTc;
