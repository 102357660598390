import React, { useEffect, useState } from "react";
import "./clientkyc.css";
import Loader from "react-loader-spinner";
import {
  convertIntoDefaultState,
  convertIntoErrorState,
  errorToast,
  infoToast,
  successToast,
  checkValidPan,
  checkValidEmail,
  checkPermittedEmail,
  checkValidMobileNumber,
  convertDateFormat,
  yearsBefore,
  getAge

} from "../../utils/helper";
import { Auth } from "aws-amplify";
import moment from 'moment';
import { eventLog } from "../../utils/firebase";
import { InputBox, InputBoxV2 } from "../../components/index.js";
import { clientKycBasicDetail } from "../../constant/clientKycState";
import Checkbox from "@mui/material/Checkbox";
import { KYC_LINK_API_V2, User_API } from "../../services/api";
import config from "../../config/settings";

const ClientPreKyc = function (props) {
  const send_kyc_pan_consent_msg =
    config.documents.consent_msg.send_kyc_pan_consent_msg;
  const [panFourthLetter, setPanFourthLetter] = useState("");
  const [name, setName] = useState("");
  const [otp, setOtp] = useState("");
  const [ctaLoader, setCtaLoader] = useState(false);
  const [panVerify, setPanVerify] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [panName, setPanName] = useState("");
  const [panAttempts, setPanAttempts] = useState(0);
  const [panVerifiedSuccessful, setPanVerifiedSuccessful] = useState(false);
  const [otpVerifiedSuccessful, setOtpVerifiedSuccessful] = useState(false);
  const [addClientBasicDetails, setAddClientBasicDetails] = useState(
    convertIntoDefaultState(clientKycBasicDetail)
  );
  const [addClientBasicDetailsError, setAddClientBasicDetailsError] = useState(
    convertIntoErrorState(clientKycBasicDetail)
  );
  const [user, setUser] = useState("");
  const [permittedEmailError, setPermittedEmailError] = useState(false);
  const [panType_IS, SetPanType_IS] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [maxDate, setMaxDate] = useState("");

  const prohibitedEmails = ["@outlook.com", "@hotmail.com"];
  const api = new KYC_LINK_API_V2();
  const userApi = new User_API();

  let maxTries = 2;

  var mail_format =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  useEffect(() => {
    console.log("addClientBasicDetails", addClientBasicDetails);
  }, [addClientBasicDetails]);
  //for dob
  useEffect(() => {
    const { sDate, mDate } = yearsBefore(100);
    setStartDate(sDate);
    setMaxDate(mDate);
  }, []);

  useEffect(() => {
    if (props.data && props.data.name && props.data.name != "") {
      setName(props.data.name);
    }
    if (
      props.data &&
      props.data.email &&
      props.data.email != "" &&
      props.data.mobile &&
      props.data.mobile != ""
    ) {
      setAddClientBasicDetails({
        ...addClientBasicDetails,
        email_id: props.data.email,
        mobile_number: props.data.mobile,
      });
    }

    eventLog({
      type: "add_kyc_link_page",
      action: {
        trigger: "true",
      },
    });
  }, []);
  useEffect(() => {
    if (addClientBasicDetails.pan_number) {
      props.setPan(addClientBasicDetails.pan_number);
      if (addClientBasicDetails.pan_number[3] !== "P") {
        SetPanType_IS(true);
        setStartDate("1850-01-01");
        setMaxDate(moment().subtract(1, "days").format("YYYY-MM-DD"));
      }
    }
  }, [addClientBasicDetails]);

  useEffect(() => {
    if (
      addClientBasicDetails["email_id"] != null &&
      !mail_format.test(addClientBasicDetails["email_id"])
    ) {
      setPermittedEmailError(false);
      setAddClientBasicDetailsError({
        ...addClientBasicDetailsError,
        email_id: true,
      });
    }
  }, [addClientBasicDetails["email_id"]]);

  const sendOTP = async () => {
    if (!checkValidMobileNumber(addClientBasicDetails["mobile_number"])) {
      setCtaLoader(false);
      if (!mail_format.test(addClientBasicDetails["email_id"])) {
        setAddClientBasicDetailsError({
          ...addClientBasicDetailsError,
          mobile_number: true,
          email_id: true,
        });
      } else {
        setAddClientBasicDetailsError({
          ...addClientBasicDetailsError,
          mobile_number: true,
        });
      }
      return;
    } else if (
      addClientBasicDetails["email_id"] != null &&
      !mail_format.test(addClientBasicDetails["email_id"])
    ) {
      setAddClientBasicDetailsError({
        ...addClientBasicDetailsError,
        email_id: true,
      });
      setCtaLoader(false);
      return;
    }
    const number = "+91" + addClientBasicDetails["mobile_number"];
    const payload = {
      mobile: addClientBasicDetails["mobile_number"],
      isIfa: false,
    };
    await userApi
      .sendMobileVerification(payload)
      .then((response) => {
        if (response.kind == "ok") {
          let userName = response.data.pan_number;
          if (
            !response.data.isExistingUser ||
            (response.data.isExistingUser && response.data.oldExistingUser)
          )
            userName = number;
          Auth.signIn(userName)
            .then((res) => {
              setUser(res);
              setCtaLoader(false);
              setShowOtp(true);
              successToast(
                "OTP has been sent successfully to your mobile number."
              );
              eventLog({
                type: "auth_otp_send",
                action: {
                  trigger: "true",
                },
              });
            })
            .catch((error) => {
              console.log("Error while sending OTP ::: ", error);
              setCtaLoader(false);
              errorToast(
                "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
              );
            });
        } else {
          setCtaLoader(false);
          errorToast(
            "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
          );
        }
      })
      .catch((error) => {
        console.log("Error while sending OTP ::: ", error);
        setCtaLoader(false);
        errorToast(
          "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
        );
        eventLog({
          type: "auth_otp_send",
          action: {
            trigger: "false",
          },
        });
      });
  };

  const verifyOTP = async () => {
    if (otp.length === 0 || otp.length !== 4) {
      errorToast(otp.length > 0 ? `Please Enter Valid OTP` : ` Please Enter OTP`, true);
      setCtaLoader(false);
      return;
    }
    await Auth.sendCustomChallengeAnswer(user, otp)
      .then(async (res) => {
        if (res && !res.Session) {
          const curr_user = await Auth.currentUserCredentials();

          api.checkUserExist(curr_user.identityId).then(async (res) => {
            if (res.kind === "ok") {
              if (res.data.user_exists === 0) {
                eventLog({
                  type: "kyc_link_auth_otp_verify",
                  action: {
                    trigger: "true",
                  },
                });
                setCtaLoader(false);
                setShowOtp(false);
                setOtpVerifiedSuccessful(true);
                setPanVerify(true);
                props.setUseridValue(curr_user.identityId)
                setAddClientBasicDetails({
                  ...addClientBasicDetails,
                  user_id: curr_user.identityId,
                });
              } else if (res.data.user_exists === 1) {
                await Auth.signOut()
                  .then(() => {
                    errorToast("User already exist with this mobile number");
                    setCtaLoader(false);
                    setShowOtp(false);
                    setOtp("");
                  })
                  .catch((err) => {
                    errorToast(
                      "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
                    );
                    setCtaLoader(false);
                  });
              }
            } else {
              await Auth.signOut()
                .then(() => {
                  setCtaLoader(false);
                })
                .catch((err) => {
                  errorToast(
                    "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
                  );
                  setCtaLoader(false);
                });
            }
          });
        } else {
          errorToast("Wrong OTP. Please check and enter the correct OTP.");
          setCtaLoader(false);
        }
      })
      .catch((err) => {
        errorToast(
          "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
        );
        setCtaLoader(false);
      });
  };
  const pan_identifier = {
    P: "Individual or Sole Proprietorship",
    H: "huf",
    C: "private_limited_company",
    F: "partnership_firm",
    T: "trust",
    B: "trust",
    A: "trust"
  }

  const identify_pan = (pan) => {
    if (pan in pan_identifier) {
      return pan_identifier[pan];
    } else {
      return "Unknown Type";
    }
  };
  const verifyPan = async () => {
    //check for email id
    if (!checkValidEmail(addClientBasicDetails["email_id"])) {
      setPermittedEmailError(false);
      setAddClientBasicDetailsError({
        ...addClientBasicDetailsError,
        email_id: true,
      });
      setCtaLoader(false);
      return;
    }
    // check for pan validation
    if (!checkValidPan(addClientBasicDetails["pan_number"])) {
      setAddClientBasicDetailsError({
        ...addClientBasicDetailsError,
        pan_number: true,
      });
      setCtaLoader(false);
      return;
    }
    if (!addClientBasicDetails["pan_name"]) {
      setAddClientBasicDetailsError({
        ...addClientBasicDetailsError,
        pan_name: true,
      });
      setCtaLoader(false);
      return;
    }
    if (!addClientBasicDetails["dob"] || (panType_IS && maxDate ===
      moment(addClientBasicDetails["dob"]).format("YYYY-MM-DD"))) {
      setAddClientBasicDetailsError({
        ...addClientBasicDetailsError,
        dob: true,
      });
      setCtaLoader(false);
      return;
    }
    if (!panType_IS && getAge(addClientBasicDetails["dob"]) > getAge(startDate)) {
      setAddClientBasicDetailsError({
        ...addClientBasicDetailsError,
        dob: true,
      });
      setCtaLoader(false);
      return;
    }
    // check for pan consent
    if (!addClientBasicDetails["is_pan_consent_accepted"]) {
      errorToast("Please provide consent in order to move ahead.", true);
      setCtaLoader(false);
      return;
    }
    // payload
    let payload = {
      pan: String(addClientBasicDetails["pan_number"]).toUpperCase(),
      email: String(addClientBasicDetails["email_id"]).toLowerCase(),
      dob: convertDateFormat(addClientBasicDetails["dob"]),
      pan_name: String(addClientBasicDetails["pan_name"])

    };
    await api.checkPanNumber(payload).then((res) => {
      if (res.kind == "ok") {
        const data = res.data;
        if (data.message == "PAN verified successfully") {
          eventLog({
            type: "kyc_link_pan_verify",
            action: {
              trigger: "true",
            },
          });
          successToast(data.message);
          setCtaLoader(false);
          setAddClientBasicDetails({
            ...addClientBasicDetails,
            investor_type: data.investor_type,
          });
          props.setInvestorType(data.investor_type);
          setPanVerifiedSuccessful(true);
          props.setBasicKycDetails(addClientBasicDetails);
          props.proceedFurther(true);

        } else {
          if (maxTries == 0) {
            props.setPanValidationFailed(true);
            return;
          } else {
            maxTries = maxTries - 1;
            errorToast(data.message);
            setCtaLoader(false);
          }
        }
      } else {
        if(res.error.includes('PAN already linked to another client') || res.error.includes("unexpected error")){
          errorToast(res.error, true);
        }
        else if (res.error.includes("name provided by you doesn’t match") && panAttempts === 0) {
          setPanAttempts((panAttempts) => panAttempts + 1);
          errorToast(res.error);
          setCtaLoader(false);
          console.log("attempt increased");
        }
        else if (panAttempts > 0 || (panAttempts==0 && !res.error.includes("name provided by you doesn’t match"))) {
          console.log("inside else if block");
          setPanAttempts((panAttempts) => panAttempts + 1);
          let investor_type = identify_pan(addClientBasicDetails["pan_number"][3]);
          console.log(investor_type);
          setAddClientBasicDetails((addClientBasicDetails) => {
            let newData = {
              ...addClientBasicDetails,
              ignorePan: true,
              investor_type: investor_type,
            };
            props.setBasicKycDetails(newData);
            return ({
              ...addClientBasicDetails,
              ignorePan: true,
              investor_type: investor_type,
            })
          });
          props.setInvestorType(investor_type);
          setPanVerifiedSuccessful(false);
          props.setPanSuccessful(false);
          // props.setBasicKycDetails({...addClientBasicDetails,ignorePan:true});
          props.proceedFurther(true);
          errorToast(res.error);
          setCtaLoader(false);
        }
      }
    });
  };


  // Submit button actions
  const onSubmit = async () => {
    if (otpVerifiedSuccessful && !panFourthLetter) {

      if (!checkValidPan(addClientBasicDetails["pan_number"])) {
        setAddClientBasicDetailsError({
          ...addClientBasicDetailsError,
          pan_number: true,
        });
        setCtaLoader(false);
        return;
      }
      else {
        setPanFourthLetter(addClientBasicDetails["pan_number"] && addClientBasicDetails["pan_number"][3]);
      }
    }
    else if (panVerifiedSuccessful && panFourthLetter) {
      setCtaLoader(true);
      // setTimeout(() => {
      props.setBasicKycDetails(addClientBasicDetails);
      props.proceedFurther(true);
      // }, 5000);
    } else if (otpVerifiedSuccessful && !panVerifiedSuccessful) {
      setCtaLoader(true);
      eventLog({
        type: "verify_pan_cta",
        action: {
          trigger: "true",
        },
      });
      //  setTimeout(() => {
      verifyPan();
      // }, 2000);
    } else if (!otpVerifiedSuccessful && showOtp) {
      setCtaLoader(true);
      // setTimeout(() => {
      await verifyOTP();
      // }, 2000);
    } else {
      setCtaLoader(true);
      // setTimeout(async () => {
      await sendOTP();
      // }, 2000);
    }
  };

  const ChangeCta = () => {
    return (
      <span
        className="add_clientkyclink_change_cta"
        style={{ display: showOtp ? "flex" : "none" }}
        onClick={() => {
          setShowOtp(false);
        }}
      >
        Change
      </span>
    );
  };
  const changePan = () => {
    return (
      <span
        className="add_clientkyclink_change_cta"
        style={{ display: (!panVerifiedSuccessful && panFourthLetter) ? "flex" : "none" }}
        onClick={() => {
          panFourthLetter ? setPanFourthLetter("") : setPanVerifiedSuccessful(false);
          setAddClientBasicDetails({ ...addClientBasicDetails, pan_name: null, dob: null })
        }}
      >
        Change
      </span>
    );
  };
  return (
    <div>
      {/* name */}
      {name && name != "" ? (
        <InputBox
          label={"Name"}
          id="name"
          type="text"
          disabled={showOtp || otpVerifiedSuccessful}
          value={name}
          setValue={setName}
          required={false}
        />
      ) : null}
      {/* Mobile No. */}
      <InputBoxV2
        label={"Mobile Number"}
        id="mobile_number"
        type={clientKycBasicDetail["mobile_number"].type}
        disabled={showOtp || otpVerifiedSuccessful}
        maxLength="10"
        autoCapitalize={true}
        value={addClientBasicDetails["mobile_number"]}
        setValue={setAddClientBasicDetails}
        object={addClientBasicDetails}
        required={clientKycBasicDetail["mobile_number"]["required"]}
        errorObject={addClientBasicDetailsError}
        setErrorObject={setAddClientBasicDetailsError}
        isError={addClientBasicDetailsError["mobile_number"]}
        errorText={
          !addClientBasicDetails["mobile_number"]
            ? "This field cannot be empty."
            : "Please enter a valid 10-digit mobile number."
        }
        RightLabelComponent={ChangeCta}
      />
      {/* Email ID */}
      {(props.data.email && props.data.email != "") || otpVerifiedSuccessful ? (
        <InputBoxV2
          label={"Email ID"}
          id="email_id"
          type={clientKycBasicDetail["email_id"].type}
          disabled={panVerifiedSuccessful}
          value={addClientBasicDetails["email_id"]}
          setValue={setAddClientBasicDetails}
          object={addClientBasicDetails}
          required={clientKycBasicDetail["email_id"]["required"]}
          errorObject={addClientBasicDetailsError}
          setErrorObject={setAddClientBasicDetailsError}
          isError={addClientBasicDetailsError["email_id"]}
          errorText={
            addClientBasicDetails["email_id"]
              ? !permittedEmailError
                ? "Incorrect email ID format. Please use a valid email ID (example@email.com)."
                : `Please provide an alternate email ID. Email IDs ending in ${prohibitedEmails.join(
                  " or "
                )} will not be accepted.`
              : "This field cannot be empty."
          }
        />
      ) : null}
      {/* pan number */}
      <InputBoxV2
        label={"PAN"}
        id="pan_number"
        type={clientKycBasicDetail["pan_number"].type}
        disabled={panFourthLetter || panVerifiedSuccessful}
        maxLength="10"
        autoCapitalize={true}
        value={addClientBasicDetails["pan_number"]}
        setValue={setAddClientBasicDetails}
        object={addClientBasicDetails}
        required={clientKycBasicDetail["pan_number"]["required"]}
        errorObject={addClientBasicDetailsError}
        setErrorObject={setAddClientBasicDetailsError}
        isError={addClientBasicDetailsError["pan_number"]}
        errorText={
          addClientBasicDetails["pan_number"]
            ? "Invalid PAN. Please try again."
            : "This field cannot be empty."
        }
        visibility={otpVerifiedSuccessful}
        RightLabelComponent={changePan}
      />
      {/* Name on PAN */}
      {panFourthLetter &&
        <>
          <InputBoxV2
            label={"Name as per PAN"}
            id="pan_name"
            type={clientKycBasicDetail["pan_name"].type}
            disabled={false}
            maxLength="250"
            autoCapitalize={true}
            value={addClientBasicDetails["pan_name"]}
            setValue={setAddClientBasicDetails}
            object={addClientBasicDetails}
            required={clientKycBasicDetail["pan_name"]["required"]}
            errorObject={addClientBasicDetailsError}
            setErrorObject={setAddClientBasicDetailsError}
            isError={addClientBasicDetailsError["pan_name"]}
            errorText="Invalid PAN Name"
            visibility={otpVerifiedSuccessful}
          />
          {/* DOB */}
          <InputBoxV2
            label={panFourthLetter && panFourthLetter == "P" ? "Date of birth" : "Date of incorporation"}
            id="dob"
            type={clientKycBasicDetail["dob"].type}
            disabled={false}
            value={addClientBasicDetails["dob"]}
            setValue={setAddClientBasicDetails}
            object={addClientBasicDetails}
            required={clientKycBasicDetail["dob"]["required"]}
            errorObject={addClientBasicDetailsError}
            setErrorObject={setAddClientBasicDetailsError}
            isError={addClientBasicDetailsError["dob"]}
            errorText={
              !addClientBasicDetails["dob"]
                ? "This field cannot be empty."
                : !panType_IS ? (getAge(addClientBasicDetails["dob"]) > 18
                  ? "Invalid Date of Birth."
                  : "Age must exceed 18 years.") : (
                    maxDate ===
                      moment(addClientBasicDetails["dob"]).format("YYYY-MM-DD")
                      ? "Date of incorporation cannot be the same as today's date. Please enter a different date."
                      : "Invalid Date of Incorporation"
                  )

            }
            min={startDate}
            max={maxDate}
            visibility={otpVerifiedSuccessful}
          />
        </>
      }
      {/* OTP */}
      <div
        className="clientkyc_main_input_form"
        style={{ marginTop: "20px", display: showOtp ? "flex" : "none" }}
      >
        <label className="clientkyc_main_input_label">
          Enter OTP<span style={{ color: "red" }}> *</span>
        </label>
        <input
          type="text"
          maxLength={6}
          value={otp}
          className="clientkyc_main_input_field"
          onChange={(event) => setOtp(event.target.value)}
        />
      </div>
      {/* pan consent */}
      <div
        className="add_clientkyclinkv2_pan_consent_checkbox"
        style={{
          display:
            otpVerifiedSuccessful && !panVerifiedSuccessful ? "flex" : "none",
        }}
      >
        <Checkbox
          checked={addClientBasicDetails["is_pan_consent_accepted"]}
          onChange={() =>
            setAddClientBasicDetails({
              ...addClientBasicDetails,
              is_pan_consent_accepted:
                !addClientBasicDetails["is_pan_consent_accepted"],
            })
          }
        />

        <div className="add_clientkyclinkv2_pan_consent_checkbox_condition">
          {send_kyc_pan_consent_msg}
        </div>
      </div>
      {/* verify pan */}
      <div className="clientkyc_main_input_form" style={{ marginTop: "20px" }}>
        <button
          onClick={onSubmit}
          className="clientkyc_ckyc_button"
          disabled={ctaLoader}
        >
          {ctaLoader ? (
            <Loader type="TailSpin" color="#fff" height={15} width={15} />
          ) : panFourthLetter ? (
            "Proceed"
          ) : panVerifiedSuccessful ? (
            "Proceed"
          ) : otpVerifiedSuccessful || showOtp ? (
            "Verify"
          ) : (
                    "Send OTP"
                  )}
        </button>
      </div>
    </div>
  );
};

export default ClientPreKyc;
