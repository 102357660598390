import "./generate-html-pdf.css";
import React, { useEffect, useState } from "react";
import { blobToBase64,  compressPdfBlob } from "../../utils/helper";
import pako from 'pako';
import Backdrop from "@mui/material/Backdrop";
import html2pdf from 'html2pdf.js';


const GenerateHtmlPdf = (props) => {
  const { HTML, fileName, download } = props;

  // console.log("props >>", props)
  const [start, setStart] = useState(false);

  useEffect(() => {
    if (props.initiate) {
        setTimeout(()=>{
            setStart(true);
        },500)
      
    } else {
      setStart(false);
    }
  }, [props.initiate]);

  useEffect(() => {
    if (start) {
      startGeneration();
    }
  }, [start]);

  const callback = (base64, blob) => {
    props.onSuccess(base64, blob);
  };

  const startGeneration = async() => {

    const options = {
        margin: 1,
        filename: `${fileName}.pdf`,
        image: { type: 'jpeg', quality: 0.5 },
        //pagebreak: { avoid: "tr", mode: "css", before: "#nextpage1", after: "1cm" },
        html2canvas: { scale: 3,useCORS: true, dpi: 192, letterRendering: true },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait',putTotalPages: true },
      };
  
      const html = document.querySelector(`#divToPrint_${fileName}`);
  
      html2pdf().set(options).from(html).toPdf().get('pdf').then(function(pdf) {
        const blob = pdf.output("blob");
        blobToBase64(blob, callback);

        if (download) {
            console.log('losd'.download);
          pdf.save(`${fileName}.pdf`);
        }
      });
  };

  return (
    <div style={{ position: "relative" }}>
      <Backdrop
        sx={{ backgroundColor: "white", color: "black", position: "absolute" }}
        open={props.initiate}
        onClick={() => null}
      />

      <div
        id={`divToPrint_${fileName}`}
        className="mt4"
        style={{ display: start ? "flex" : "none" }}
      >
        <HTML />
      </div>
    </div>
  );
};

export default GenerateHtmlPdf;