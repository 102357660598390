import React, { useState } from "react";
import "./clientOverallEarningsModel.css";
import Modal from "@mui/material/Modal";
import Loader from "react-loader-spinner";
import CloseIcon from "@mui/icons-material/Close";
import { convertAmount } from "../../utils/helper";
import { overallEarnings } from "../../assets/images";

// client overall earnings breakup model
export default function ClientOverallEarningsModel(props) {
  return (
    <Modal
      open={props.openModel}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{
        backgroundColor: "rgba(102, 102, 102, 0.40)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className="client_overall_earnings_request_model_div">
        {/* header */}
        <div className="client_overall_earnings_request_model_header">
          <div>
            <h1 className="client_overall_earnings_request_model_header_title">
              Overall Earnings
            </h1>
          </div>
          <div style={{ cursor: "pointer" }} onClick={props.handleClose}>
            <h1 className="client_overall_earnings_request_model_header_title">
              <CloseIcon />
            </h1>
          </div>
        </div>

        {/* content */}
        <div className="client_overall_earnings_request_model_main_div">
          <div className="client_overall_earnings_investments_container">
            <div className="client_overall_earnings_investments_content">
              <div className="client_overall_earnings_investments_text">
                Growth Plan - Earnings at Maturity
              </div>
              <div className="client_overall_earnings_investments_value">
                ₹ {convertAmount(props.overallEarnings.earnings_at_maturity)}
              </div>
            </div>
            <div className="client_overall_earnings_investments_content">
              <div className="client_overall_earnings_investments_text">
                Growth Plan - Monthly Earnings
              </div>
              <div className="client_overall_earnings_investments_value">
                ₹ {convertAmount(props.overallEarnings.monthly_earnings)}
              </div>
            </div>
            <div className="client_overall_earnings_investments_content">
              <div className="client_overall_earnings_investments_text">
                Freedom Plan
              </div>
              <div className="client_overall_earnings_investments_value">
                ₹ {convertAmount(props.overallEarnings.freedom_plan)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
