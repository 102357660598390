import React, { useEffect, useState } from "react";
import InfoIcon from "@mui/icons-material/Info";
import Loader from "react-loader-spinner";
import Switch from "@mui/material/Switch";
import {
  convertIntoDefaultState,
  convertIntoErrorState,
  errorToast,
  infoToast,
  successToast,
  checkValidPan,
  checkForAddressType,
  setDisableState,
  yearsBefore,
  getAge,
  getCurrentAddressDocuments,
  getPermanentAddressDocuments,
} from "../../utils/helper";
import {
  InputBox,
  InputBoxV2,
  UploadInputV2,
  UploadInputV3,
} from "../../components/index.js";
import { clientProprietorDetails } from "../../constant/signupState";
import "./addClientProprietorDetails.css";
import Checkbox from "@mui/material/Checkbox";
import { KYC_API_V2 } from "../../services/api";
import config from "../../config/settings";
import { StateDropdown } from "../../components/index.js";
import moment from "moment";

let maxRetries = 2;

const AddClientProprietorDetails = (props) => {
  const api = new KYC_API_V2();

  const add_client_pan_consent_msg =
    config.documents.consent_msg.add_client_pan_consent_msg;

  const [addClientProprietorDetails, setAddClientProprietorDetails] = useState(
    convertIntoDefaultState(clientProprietorDetails)
  );

  const [addClientProprietorDetailsError, setAddClientProprietorDetailsError] =
    useState(convertIntoErrorState(clientProprietorDetails));

  const [disableProprietorDetails, setDisableProprietorDetails] = useState(
    convertIntoErrorState(clientProprietorDetails)
  );

  let [maxTries, setMaxTries] = useState(2); //trials for pan validation

  const [loader, setLoader] = useState(false);
  const [document, setDocument] = useState({
    document_type: null,
    document_front: null,
    document_back: null,
  });

  const [panVerified, setPanVerified] = useState(false);

  const [startDate, setStartDate] = useState("");
  const [maxDate, setMaxDate] = useState("");

  const [currentDocumentsToRender, setCurrentDocumentsToRender] = useState([]);
  const [permanentDocumentsToRender, setPermanentDocumentsToRender] = useState(
    []
  );

  useEffect(() => {
    if (props.investorType === "sole_proprietorship") {
      setAddClientProprietorDetails({
        ...addClientProprietorDetails,
        authoriser_pan_name: props.basicKycDetails["pan_name"],
        authoriser_dob: props.basicKycDetails["dob"],
      });
    }
  }, []);


  useEffect(() => {
    if (
      props.userData &&
      props.userData !== "" &&
      props.userData.kyc_status === 3 &&
      !props.userData.has_authoriser
    ) {
      setDisableState(
        props.userData.authoriser_details,
        disableProprietorDetails,
        setDisableProprietorDetails
      );
    }
    if (props.userData && props.userData != "") {
      if (
        props.userData.authoriser_details &&
        props.userData.authoriser_details !== "" &&
        props.userData.authoriser_details.authoriser_pan
      ) {
        setPanVerified(true);
        setAddClientProprietorDetails({
          ...addClientProprietorDetails,
          ...props.userData.authoriser_details,
          has_authoriser: props.userData.has_authoriser,
          is_pan_consent_accepted: true,
        });
      }
      setDocument({
        document_type: checkForAddressType(
          props.userData.authoriser_details.authoriser_address_type
        ),
        document_front:
          props.userData.authoriser_details.authoriser_address_front_image,
        document_back:
          props.userData.authoriser_details.authoriser_address_back_image,
      });
    } else if (props.investorType == "sole_proprietorship") {
      setAddClientProprietorDetails({
        ...addClientProprietorDetails,
        ...props.userData.authoriser_details,
        authoriser_pan: props.basicKycDetails.pan,
        authoriser_pan_name: props.basicKycDetails.pan_name,
        authoriser_dob: props.basicKycDetails.dob,
        is_pan_consent_accepted: true,
      });
    }
  }, []);

  useEffect(() => {
    //update kyc details for parent
    props.setProprietorDetails({
      ...addClientProprietorDetails,
      authoriser_address_type: checkForAddressType(document.document_type),
      authoriser_address_front_image: document.document_front,
      authoriser_address_back_image: document.document_back,
    });

    //remove error
    props.setErrorState({
      ...addClientProprietorDetailsError,
    });
  }, [document, addClientProprietorDetails]);

  //check for error
  useEffect(() => {
    if (props.errorState && props.errorState != null) {
      setAddClientProprietorDetailsError({
        ...addClientProprietorDetailsError,
        ...props.errorState,
      });
    }
  }, [props]);

  //for dob
  useEffect(() => {
    const { cDate, sDate, mDate } = yearsBefore(100);
    setStartDate(sDate);
    setMaxDate(mDate);

    //gets the address field to render
    setCurrentDocumentsToRender(
      getCurrentAddressDocuments(clientProprietorDetails)
    );

    setPermanentDocumentsToRender(
      getPermanentAddressDocuments(clientProprietorDetails)
    );
  }, []);

  //array for address types
  const addressProofArray = ["Aadhaar Card", "Passport", "Driving License"];

  const verifyPAN = async () => {
    setLoader(true);
    // check for pan validation
    if (!checkValidPan(addClientProprietorDetails["authoriser_pan"])) {
      setAddClientProprietorDetailsError({
        ...addClientProprietorDetailsError,
        pan: true,
      });
      setLoader(false);
      return;
    }
   // check for pan validation
    if (!addClientProprietorDetails["authoriser_pan_name"]) {
      errorToast("Please provide pan name in order to move ahead.", true);

      setLoader(false);
      return;
    }

    // check for pan validation
    if (!addClientProprietorDetails["authoriser_dob"]) {
      errorToast("Please provide date of birth in order to move ahead.", true);
      setLoader(false);
      return;
    }
    if (!addClientProprietorDetails["is_pan_consent_accepted"]) {
      errorToast("Please provide consent in order to move ahead.", true);
      setLoader(false);
      return;
    }

    // payload
    let payload = {
      pan: String(addClientProprietorDetails["authoriser_pan"]).toUpperCase(),
      pan_name: String(addClientProprietorDetails["authoriser_pan_name"]).toUpperCase(),
      dob: moment(addClientProprietorDetails["authoriser_dob"]).format("DD-MM-YYYY"),
      is_secondary: true,
    };

    // call the pan check api
    await api.checkPanNumber(payload).then((res) => {
      if (res.kind == "ok") {
        const data = res.data;
        if (data.message == "PAN verified successfully") {
          successToast(data.message);
          setPanVerified(true);
          setAddClientProprietorDetails({
            ...addClientProprietorDetails,
            authoriser_pan_res: data.pan_res,
            authoriser_pan_name: data.pan_name,
            is_pan_consent_accepted:
              addClientProprietorDetails["is_pan_consent_accepted"],
          });

          setLoader(false);
        } else {
          successToast(data.message);
          setLoader(false);
        }
      } else {
        if (maxTries == 0) {
          props.setPrePanValidation(2);
          return;
        } else {
          setMaxTries((maxTries = maxTries - 1));
        }
        errorToast(res.error, true);
        setLoader(false);
      }
    });
  };

  //handles gender change radio
  const handleChangeGender = (value) => {
    //set value
    setAddClientProprietorDetails({
      ...addClientProprietorDetails,
      authoriser_gender: value,
    });

    //reomve error
    setAddClientProprietorDetailsError({
      ...addClientProprietorDetailsError,
      authoriser_gender: false,
    });
  };

  //handle toggle switch
  const handleSwitchChange = (e) => {
    setAddClientProprietorDetails((prevState) => ({
      ...prevState,
      authoriser_current_address_flag: e.target.checked,
    }));
  };

  return (
    <div
      className="add_client_kyc_basic_details_container"
      style={{ display: !props.visible ? "none" : "" }}
    >
      <div className="add_client_kyc_basic_details_main_container">
        <h4 className="add_client_kyc_pre_pan_main_container_title">
          {props.investorType == "sole_proprietorship"
            ? "Proprietor Details & Documents"
            : props.investorType == "huf"
            ? "Karta Details & Documents"
            : props.investorType == "trust"
            ? "Trustee Details & Documents"
            : props.investorType == "partnership_firm"
            ? "Authorised Partner Details & Documents"
            : "Authorised Signatory Details & Documents"}
        </h4>

        {props.investorType != "sole_proprietorship" ? (
          <>
            {/* pan number */}
            <InputBoxV2
              label={"PAN"}
              id="authoriser_pan"
              type={clientProprietorDetails["authoriser_pan"].type}
              disabled={
                panVerified ||
                props.totalDisable ||
                disableProprietorDetails["authoriser_pan"]
              }
              maxLength="10"
              autoCapitalize={true}
              value={addClientProprietorDetails["authoriser_pan"]}
              setValue={setAddClientProprietorDetails}
              object={addClientProprietorDetails}
              required={clientProprietorDetails["authoriser_pan"]["required"]}
              errorObject={addClientProprietorDetailsError}
              setErrorObject={setAddClientProprietorDetailsError}
              isError={addClientProprietorDetailsError["authoriser_pan"]}
              errorText={
                !addClientProprietorDetails["authoriser_pan"]
                  ? "This field cannot be empty."
                  : "Invalid PAN. Please try again."
              }
            />

            {/* pan name */}
            <InputBoxV2
              label={"Name as per PAN"}
              id="authoriser_pan_name"
              type={clientProprietorDetails["authoriser_pan_name"].type}
              autoCapitalize={true}
              disabled={
                panVerified 
              }
              value={addClientProprietorDetails["authoriser_pan_name"]}
              setValue={setAddClientProprietorDetails}
              object={addClientProprietorDetails}
              required={
                clientProprietorDetails["authoriser_pan_name"]["required"]
              }
              errorObject={addClientProprietorDetailsError}
              setErrorObject={setAddClientProprietorDetailsError}
              isError={addClientProprietorDetailsError["authoriser_pan_name"]}
              errorText={
                !addClientProprietorDetails["authoriser_pan_name"]
                  ? "This field cannot be empty."
                  : "Invalid PAN. Please try again."
              }
            />
              {/* Date of Birth. */}
            <InputBoxV2
              label={"Date of Birth"}
              id="authoriser_dob"
              type={clientProprietorDetails["authoriser_dob"].type}
              checkForAge={true}
              min={startDate}
              max={maxDate}
              value={addClientProprietorDetails["authoriser_dob"]}
              setValue={setAddClientProprietorDetails}
              object={addClientProprietorDetails}
              required={clientProprietorDetails["authoriser_dob"]["required"]}
              errorObject={addClientProprietorDetailsError}
              setErrorObject={setAddClientProprietorDetailsError}
              isError={addClientProprietorDetailsError["authoriser_dob"]}
              errorText={
                !addClientProprietorDetails["authoriser_dob"]
                  ? "This field cannot be empty."
                  : getAge(addClientProprietorDetails["authoriser_dob"]) > 18
                  ? "Invalid Date of Birth."
                  : "Age must exceed 18 years."
              }
              disabled={
                props.totalDisable || disableProprietorDetails["authoriser_dob"] || props.investorType == "sole_proprietorship" ||panVerified
              }
            />

            {/* pan consent */}
            <div
              className="add_cientkycv2_pan_consent_checkbox"
              style={{
                display: panVerified ? "none" : "",
              }}
            >
              <Checkbox
                checked={addClientProprietorDetails["is_pan_consent_accepted"]}
                onChange={() =>
                  setAddClientProprietorDetails({
                    ...addClientProprietorDetails,
                    is_pan_consent_accepted:
                      !addClientProprietorDetails["is_pan_consent_accepted"],
                  })
                }
              />

              <div className="add_cientkycv2_pan_consent_checkbox_condition">
                {add_client_pan_consent_msg}
              </div>
            </div>

            {/* cta */}
            <div
              className="add_client_propritor_pan_verify_cta"
              style={{
                display: panVerified
                  ? "none"
                  : addClientProprietorDetails["authoriser_pan"]?.length == 10
                  ? ""
                  : "none",
              }}
              onClick={(e) => {
                e.preventDefault();
                verifyPAN(e);
              }}
            >
              {loader ? (
                <Loader
                  type="TailSpin"
                  color="#FFFFFF"
                  height={25}
                  width={25}
                />
              ) : (
                <p className="add_client_kyc_pre_pan_cta_text">Verify</p>
              )}
            </div>
          </>
        ) : null}

        {props.investorType == "sole_proprietorship" ? (
          <>
          
          <InputBoxV2
            label={"Name as per PAN"}
            id="authoriser_pan_name"
            type={clientProprietorDetails["authoriser_pan_name"].type}
            disabled={true}
            visibility={
              props.investorType == "sole_proprietorship" ? true : false
            }
            required={
              clientProprietorDetails["authoriser_pan_name"]["required"]
            }
            autoCapitalize={true}
            setValue={setAddClientProprietorDetails}
            value={props.basicKycDetails.pan_name}
            object={addClientProprietorDetails}
          />
          <InputBoxV2
          label={"Date of Birth"}
          id="dob"
          type={clientProprietorDetails["authoriser_dob"].type}
          // visibility={
          //   props.investorType != "sole_proprietorship" ? true : false
          // }
          checkForAge={true}
          min={startDate}
          max={maxDate}
          value={addClientProprietorDetails["authoriser_dob"]}
          setValue={setAddClientProprietorDetails}
          object={addClientProprietorDetails}
          // errorObject={addClientProprietorDetailsError}
          // setErrorObject={setAddClientProprietorDetailsError}
          // isError={addClientProprietorDetailsError["authoriser_dob"]}
          // errorText={
          //   !addClientProprietorDetails["authoriser_dob"]
          //     ? "This field cannot be empty."
          //     : getAge(addClientProprietorDetails["authoriser_dob"]) > 18
          //     ? "Invalid Date of Birth."
          //     : "Age must exceed 18 years."
          // }
          disabled={
            props.totalDisable || disableProprietorDetails["dob"] || props.investorType == "sole_proprietorship"
          }
        />
        </>
        ) : null}

        {/* Date of Birth.
        <InputBoxV2
          label={"Date of Birth"}
          id="authoriser_dob"
          type={clientProprietorDetails["authoriser_dob"].type}
          // visibility={
          //   props.investorType != "sole_proprietorship" ? true : false
          // }
          checkForAge={true}
          min={startDate}
          max={maxDate}
          // value={addClientProprietorDetails["authoriser_dob"]}
          value={props.basicKycDetails["dob"]}
          setValue={setAddClientProprietorDetails}
          object={addClientProprietorDetails}
          // required={clientProprietorDetails["authoriser_dob"]["required"]}
          // errorObject={addClientProprietorDetailsError}
          // setErrorObject={setAddClientProprietorDetailsError}
          // isError={addClientProprietorDetailsError["authoriser_dob"]}
          // errorText={
          //   !addClientProprietorDetails["authoriser_dob"]
          //     ? "This field cannot be empty."
          //     : getAge(addClientProprietorDetails["authoriser_dob"]) > 18
          //     ? "Invalid Date of Birth."
          //     : "Age must exceed 18 years."
          // }
          disabled={
            props.totalDisable || disableProprietorDetails["dob"] || props.investorType == "sole_proprietorship"
          }
        /> */}

        {/* select gender */}
        <div
          // className="add_client_gender_type_container"
          style={{
            // display: props.investorType == "sole_proprietorship" ? "none" : "",
            marginBottom: "25px",
          }}
        >
          <h4>
            Gender<span style={{ color: "red" }}> *</span>
          </h4>

          <div className="add_client_gender_type">
            <label id="male" style={{ marginRight: "10%" }}>
              <input
                id="male"
                type="radio"
                name="Gender"
                value={"male"}
                className="radioClass"
                // disabled={disabled}
                onChange={(e) => {
                  handleChangeGender(e.target.value);
                }}
                checked={
                  addClientProprietorDetails["authoriser_gender"] === "male"
                }
                disabled={
                  props.totalDisable ||
                  disableProprietorDetails["authoriser_gender"]
                }
              />
              Male
            </label>

            <label id="female" style={{ marginRight: "10%" }}>
              <input
                id="female"
                type="radio"
                name="Gender"
                value={"female"}
                className="radioClass"
                // disabled={disabled}
                onChange={(e) => {
                  handleChangeGender(e.target.value);
                }}
                checked={
                  addClientProprietorDetails["authoriser_gender"] === "female"
                }
                disabled={
                  props.totalDisable ||
                  disableProprietorDetails["authoriser_gender"]
                }
              />
              Female
            </label>

            <label id="others">
              <input
                id="others"
                type="radio"
                name="Gender"
                value={"others"}
                className="radioClass"
                // disabled={disabled}
                onChange={(e) => {
                  handleChangeGender(e.target.value);
                }}
                checked={
                  addClientProprietorDetails["authoriser_gender"] === "others"
                }
                disabled={
                  props.totalDisable ||
                  disableProprietorDetails["authoriser_gender"]
                }
              />
              Others
            </label>
          </div>
          {/*validation error */}
          <div
            className="input_box_invalid_div"
            style={{
              opacity: 1,
            }}
          >
            {/* icon */}
            <InfoIcon
              style={{
                fontSize: "14px",
                paddingRight: "5px",
                color: "#ff2e2e",
                opacity: addClientProprietorDetailsError["authoriser_gender"]
                  ? 1
                  : 0,
              }}
            />
            {/* text */}
            <p
              className="input_box_v2_invalid_text"
              style={{
                opacity: addClientProprietorDetailsError["authoriser_gender"]
                  ? 1
                  : 0,
              }}
            >
              {addClientProprietorDetailsError["authoriser_gender"]
                ? "Please select appropriate gender"
                : ""}
            </p>
          </div>
        </div>

        {/* selfie */}
        <UploadInputV2
          id="authoriser_selfie"
          label={"Upload picture"}
          required={clientProprietorDetails["authoriser_selfie"]["required"]}
          object={addClientProprietorDetails}
          setValue={setAddClientProprietorDetails}
          imageSizeLimit={15} //required if note is provided
          note={
            "Ensure the client's face is clearly visible in the picture. (Upload image in JPG/JPEG/PNG format. Max. file size allowed is 15 MB)"
          }
          errorObject={addClientProprietorDetailsError}
          setErrorObject={setAddClientProprietorDetailsError}
          isError={addClientProprietorDetailsError["authoriser_selfie"]}
          disabled={
            props.totalDisable || disableProprietorDetails["authoriser_selfie"]
          }
          requiredFileTypes={["image/jpeg", "image/png", "image/jpg"]}
          acceptedFormat=".jpg, .jpeg, .png"
          additionalPath={props.userID}
        />

        <h4 id="add_client_proprietor_detail_subtitle">Current Address</h4>

        {currentDocumentsToRender.map((key, index) => {
          if (key.includes("state")) {
            return (
              <StateDropdown
                label={clientProprietorDetails[key].label}
                id={key}
                type={clientProprietorDetails[key].type}
                disabled={props.totalDisable || disableProprietorDetails[key]}
                dataArray={config.state_array}
                maxLength="255"
                value={addClientProprietorDetails[key]}
                specialCharCheck={
                  clientProprietorDetails[key].hasOwnProperty(
                    "specialCharCheck"
                  )
                    ? clientProprietorDetails[key].specialCharCheck
                    : false
                }
                setValue={setAddClientProprietorDetails}
                object={addClientProprietorDetails}
                required={clientProprietorDetails[key]["required"]}
                errorObject={addClientProprietorDetailsError}
                setErrorObject={setAddClientProprietorDetailsError}
                isError={addClientProprietorDetailsError[key]} //props.errorState.hasOwnProperty("address")?props.errorState.address:
                errorText={
                  addClientProprietorDetails[key]
                    ? "Invalid State"
                    : "This field cannot be empty."
                }
              />
            );
          } else {
            return (
              <InputBoxV2
                key={key}
                label={clientProprietorDetails[key].label}
                id={key}
                type={clientProprietorDetails[key].type}
                disabled={props.totalDisable || disableProprietorDetails[key]}
                maxLength={key.includes("pin") ? "6" : "255"}
                value={addClientProprietorDetails[key]}
                setValue={setAddClientProprietorDetails}
                object={addClientProprietorDetails}
                specialCharCheck={
                  clientProprietorDetails[key].hasOwnProperty(
                    "specialCharCheck"
                  )
                    ? clientProprietorDetails[key].specialCharCheck
                    : false
                }
                required={clientProprietorDetails[key]["required"]}
                errorObject={addClientProprietorDetailsError}
                setErrorObject={setAddClientProprietorDetailsError}
                isError={addClientProprietorDetailsError[key]}
                errorText={
                  addClientProprietorDetails[key]
                    ? `Invalid ${key.replace(/^[^_]+_/, "")}`
                    : "This field cannot be empty."
                }
              />
            );
          }
        })}

        {/* dropdown document upload */}
        <UploadInputV3
          id="authoriser_address_type"
          label={clientProprietorDetails["authoriser_address_type"].label}
          dropDownArray={addressProofArray}
          object={document}
          value={document}
          setValue={setDocument}
          imageSizeLimit={15} //required if note is provided
          labelForDocumentFront={"Front Side"}
          idForDocumentFront={"authoriser_address_front_image"}
          idForDocumentBack={"authoriser_address_back_image"}
          labelForDocumentBack={"Back Side"}
          note={
            "Upload image in JPG/JPEG/PNG/PDF format. Max. file size allowed is 15 MB"
          }
          errorObject={addClientProprietorDetailsError}
          setErrorObject={setAddClientProprietorDetailsError}
          isError={addClientProprietorDetailsError["authoriser_address_type"]}
          disabled={
            props.totalDisable ||
            disableProprietorDetails["authoriser_address_type"]
          }
          additionalPath={props.userID}
        />

        {/* PERMANENT ADDRESS SECTION */}
        <div className="address_toggle">
          {/* premament details */}
          <p id="address_toggle_label">
            Permanent address is the same as current address
          </p>

          {/* address togge */}
          <Switch
            checked={
              addClientProprietorDetails["authoriser_current_address_flag"]
            }
            onChange={handleSwitchChange}
            inputProps={{ "aria-label": "controlled" }}
            sx={{
              "& .MuiSwitch-thumb": {
                color: addClientProprietorDetails[
                  "authoriser_current_address_flag"
                ]
                  ? "#3a86ff"
                  : "#FFFFFF",
              },
            }}
            disabled={props?.userData?.has_authoriser}
          />
        </div>

        {!addClientProprietorDetails["authoriser_current_address_flag"] && (
          <h4 id="add_client_proprietor_detail_subtitle">Permanent Address</h4>
        )}

        {!addClientProprietorDetails["authoriser_current_address_flag"] &&
          permanentDocumentsToRender.map((key, index) => {
            if (key.includes("state")) {
              return (
                <StateDropdown
                  label={clientProprietorDetails[key].label}
                  id={key}
                  type={clientProprietorDetails[key].type}
                  disabled={props.totalDisable || disableProprietorDetails[key]}
                  dataArray={config.state_array}
                  maxLength="255"
                  value={addClientProprietorDetails[key]}
                  setValue={setAddClientProprietorDetails}
                  object={addClientProprietorDetails}
                  specialCharCheck={
                    clientProprietorDetails[key].hasOwnProperty(
                      "specialCharCheck"
                    )
                      ? clientProprietorDetails[key].specialCharCheck
                      : false
                  }
                  required={clientProprietorDetails[key]["requiredCheck"]}
                  errorObject={addClientProprietorDetailsError}
                  setErrorObject={setAddClientProprietorDetailsError}
                  isError={addClientProprietorDetailsError[key]} //props.errorState.hasOwnProperty("address")?props.errorState.address:
                  errorText={
                    addClientProprietorDetails[key]
                      ? "Invalid State"
                      : "This field cannot be empty."
                  }
                />
              );
            } else {
              return (
                <InputBoxV2
                  key={key}
                  label={clientProprietorDetails[key].label}
                  id={key}
                  type={clientProprietorDetails[key].type}
                  disabled={props.totalDisable || disableProprietorDetails[key]}
                  maxLength={key.includes("pin") ? "6" : "355"}
                  value={addClientProprietorDetails[key]}
                  setValue={setAddClientProprietorDetails}
                  object={addClientProprietorDetails}
                  required={clientProprietorDetails[key]["requiredCheck"]}
                  errorObject={addClientProprietorDetailsError}
                  specialCharCheck={
                    clientProprietorDetails[key].hasOwnProperty(
                      "specialCharCheck"
                    )
                      ? clientProprietorDetails[key].specialCharCheck
                      : false
                  }
                  setErrorObject={setAddClientProprietorDetailsError}
                  isError={addClientProprietorDetailsError[key]}
                  errorText={
                    addClientProprietorDetails[key]
                      ? `Invalid ${key.replace(/^[^_]+_/, "")}`
                      : "This field cannot be empty."
                  }
                />
              );
            }
          })}
      </div>
    </div>
  );
};

export default AddClientProprietorDetails;
