import * as React from "react";
import "./client-investment-request-model.css";
import Modal from "@mui/material/Modal";
import Loader from "react-loader-spinner";
import CloseIcon from "@mui/icons-material/Close";
import { convertAmount, handleKeyDown } from "../../utils/helper";
import config from "../../config/settings";

export default function ClientInvestmentRequestModel(props) {
  return (
    <Modal
      open={props.openModel}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{
        backgroundColor: "rgba(102, 102, 102, 0.40)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className="client_investment_request_model_div">
        <div className="client_investment_request_model_header">
          <div>
            <h1 className="client_investment_request_model_header_title">
              Investment Request
            </h1>
          </div>
          <div style={{ cursor: "pointer" }} onClick={props.handleClose}>
            <div className="client_investment_request_model_header_title">
              <CloseIcon />
            </div>
          </div>
        </div>

        {/* title */}
        <div className="client_investment_request_model_main_div">
          <div className="client_investment_request_model_title_div">
            <div className="client_investment_request_model_heading">
              {/* getting title based on pool type */}
              {config.pool_templates
                .filter((item) => item.type === props.type)
                .map((item) => item.title)
                .find(Boolean)}
            </div>
            <h1 className="client_investment_request_model_title">
              Minimum Investment :{" "}
              <span
                className="client_investment_request_model_title"
                style={{
                  fontFamily: "'Open Sans', sans-serif",
                  fontWeight: 400,
                  fontSize: "14px",
                }}
              >
                ₹
              </span>
              {props.alreadyInvested && parseFloat(props.alreadyInvested) > 0
                ? convertAmount("1000", 0)
                : convertAmount(props.minimumInvestment, 0)}
            </h1>
            <div className="client_investment_request_content">
              <div className="client_investment_request_model_title">
                Interest Rate -{" "}
                <span style={{ fontWeight: "600", color: "#757575" }}>
                  {props.interest_rate}% Per Annum
                </span>
              </div>
              <div className="client_investment_request_model_title">|</div>
              <div className="client_investment_request_model_title">
                Tenure -{" "}
                <span style={{ fontWeight: "600", color: "#757575" }}>
                  {props.type == 1
                    ? props.tenure
                    : props.tenure == 1
                    ? `${
                        props.tenureType == 1 || props.tenureType == "Months"
                          ? `${props.tenure} Month`
                          : `${props.tenure} Day`
                      }`
                    : `${
                        props.tenureType == 1 || props.tenureType == "Months"
                          ? `${props.tenure} Months`
                          : `${props.tenure} Days`
                      }`}
                </span>
              </div>
            </div>
          </div>

          {/* amount input */}
          <input
            type="number"
            placeholder="Enter Amount"
            className="client_investment_request_model_input"
            min="0"
            max="5000000"
            onWheel={() => document.activeElement.blur()}
            onKeyDown={handleKeyDown}
            onKeyPress={props.onKeyPress}
            onChange={(event) => {
              props.setAmount(event.target.value);
              if (event.target.value > 5000000) {
                props.setAmountInvalid(true);
                props.setAmountInvalidText(
                  "Amount should be lesser than ₹ 50,00,000"
                );
              } else if (event.target.value == "") {
                props.setAmountInvalid(false);
                props.setAmountInvalidText("");
              } else if (event.target.value < 1000) {
                props.setAmountInvalid(true);
                props.setAmountInvalidText(
                  `Amount should be greater than ₹ ${
                    props.alreadyInvested &&
                    parseFloat(props.alreadyInvested) > 0
                      ? convertAmount("1000", 0)
                      : convertAmount(props.minimumInvestment, 0)
                  }`
                );
              } else {
                props.setAmountInvalid(false);
              }
              console.log(event.target.value);
            }}
          />
          {props.amountInvalid ? (
            <p
              style={{
                marginTop: "10px",
                textAlign: "left",
                fontSize: "14px",
                color: "red",
                marginLeft: "0px",
              }}
            >
              {props.amountInvalidText}
            </p>
          ) : (
            ""
          )}

          {/* send button */}
          <div style={{ textAlign: "center" }}>
            <button
              className="client_investment_request_model_button"
              onClick={() => props.onProceed()}
              disabled={props.amountInvalid}
              style={{
                backgroundColor: props.amountInvalid ? "gray" : "#3A86FF",
              }}
            >
              {props.loading ? (
                <Loader type="TailSpin" color="#fff" height={15} width={15} />
              ) : (
                "Invest"
              )}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
