import { API } from "aws-amplify";
import * as Types from "../api.types";

export class WalletApi {
  /**
   * @description this function is for initiate wallet loading request
   * @param data
   * @returns success or faliure
   */
  async postWalletRequest(
    data: any
  ): Promise<Types.GetUpdateWithdrawalRequest> {
    // payload
    const params = {
      body: {
        amount: data.amount,
      },
    };

    // call the api try and catch
    try {
      const response: any = await API.post(
        "baseurl",
        `/ifa/clients/${data.user_id}/wallet-requests`,
        params
      );
      return { kind: "ok", data: response.message };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description this function is for fetching wallet loading requests for IFA
   * @param data
   * @returns success or faliure
   */
  async getWalletRequests(
    data: any
  ): Promise<Types.GetUpdateWithdrawalRequest> {
    // payload
    const params = {
      body: data,
    };

    // call the api try and catch
    try {
      const response: any = await API.get(
        "baseurl",
        "/ifa/wallet-requests",
        params
      );
      return { kind: "ok", data: response.data };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description this function is for fetching wallet loading requests details for IFA
   * @param data
   * @returns wallet request
   */
  async getWalletRequestData(
    uuid: any
  ): Promise<Types.GetUpdateWithdrawalRequest> {
    // payload
    const params = {};

    // call the api try and catch
    try {
      const response: any = await API.get(
        "baseurl",
        `/ifa/wallet-requests/${uuid}`,
        params
      );

      return { kind: "ok", data: response.data };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description this function is for initiate wallet loading request
   * @param data
   * @returns success or faliure
   */
  async updateWalletRequestStatus(
    data: any,
    uuid: any
  ): Promise<Types.GetUpdateWithdrawalRequest> {
    // payload
    const params = {
      body: data,
    };

    // call the api try and catch
    try {
      const response: any = await API.post(
        "baseurl",
        `/ifa/wallet-requests/${uuid}`,
        params
      );
      return { kind: "ok", data: response.message };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description this function is for fetching wallet loading requests details for IFA
   * @param data
   * @returns wallet request
   */
  async getOrderData(order_uuid: any): Promise<Types.GetOrderData> {
    // payload
    const params = {};

    // call the api try and catch
    try {
      const response: any = await API.get(
        "baseurl",
        `/orders/order/${order_uuid}`,
        params
      );

      return { kind: "ok", data: response.data };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }
}
