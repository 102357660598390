import { API } from "aws-amplify";
import * as Types from "../api.types";

export class Support_API {
  /**
   * @description this function is for updating the support query
   * @param data
   * @returns success or faliure
   */
  async updateSupportRequest(data: any): Promise<Types.GetIFASupportData> {
    // payload
    const params = {
      body: data,
    };

    //call the api try and catch
    try {
      const response: any = await API.post("baseurl", "/support", params);
      return { kind: "ok", data: response.data };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }
}
