import * as React from "react";
import "./wallet-loading-model.css";
import Modal from "@mui/material/Modal";
import Loader from "react-loader-spinner";
import CloseIcon from "@mui/icons-material/Close";
import { eventLog } from "../../utils/firebase";
import { handleKeyDown } from "../../utils/helper";

export default function WalletLoadingModel(props) {
  return (
    <Modal
      open={props.openModel}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{
        backgroundColor: "rgba(102, 102, 102, 0.72)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className="wallet_loading_model_div">
        <div className="wallet_loading_model_header">
        <div style={{ float: "left"}}>
          <h1 className="wallet_loading_model_header_title">
           Wallet Loading Request
          </h1>
          </div>
          <div style={{ float: "right",cursor:'pointer' }} onClick={props.handleClose}>
            <h1 className="wallet_loading_model_header_title">
              <CloseIcon/>
            </h1>
          </div>
        </div>

        {/* title */}
        <div className="wallet_loading_model_main_div">
          <div className="wallet_loading_model_title_div">
            <h1 className="wallet_loading_model_title">
              Amount (INR) to be loaded in wallet for
            </h1>
            <h1
              className="wallet_loading_model_title"
              style={{
                marginLeft: "5px",
                // color: "#3a86ff",
                marginRight: "5px",
              }}
            >
              {
                props.clientData
                ? props.clientData[props.selectedIndex]
                  ? props.clientData[props.selectedIndex].name
                    ? props.clientData[props.selectedIndex].name.toLowerCase().replace(/\b\w/g, function (char) {
                      return char.toUpperCase();
                    })
                    : ""
                  : ""
                : ""
              }
            </h1>
          </div>

          {/* amount input */}
          <input
            type="number"
            onWheel={() => document.activeElement.blur()}
            onKeyDown={handleKeyDown}
            placeholder="Enter Amount"
            className="wallet_loading_model_input"
            min="0"
            max="5000000"
            onKeyPress={props.onKeyPress}
            onChange={(event) => {
              props.setAmount(event.target.value);
              if (event.target.value > 5000000) {
                props.setAmountInvalid(true);
                props.setAmountInvalidText(
                  "Amount should be lesser than 50,00,000"
                );
              } else if (event.target.value == "") {
                props.setAmountInvalid(false);
                props.setAmountInvalidText("");
              } else if (event.target.value < 1000) {
                props.setAmountInvalid(true);
                props.setAmountInvalidText(
                  "Amount should be greater than 1,000"
                );
              } else {
                props.setAmountInvalid(false);
              }
              eventLog({
                type: "entered_wallet_loading_amount",
                action: {
                  trigger: "false",
                },
              });
              console.log(event.target.value);
            }}
          />
          {props.amountInvalid ? (
            <p
              style={{
                marginTop: "10px",
                textAlign: "left",
                fontSize: "14px",
                color: "red",
                marginLeft: "0px",
              }}
            >
              {props.amountInvalidText}
            </p>
          ) : (
            ""
          )}

          {/* send button */}
          <div style={{ textAlign: "center" }}>
            <button
              className="wallet_loading_model_button"
              onClick={() => props.onProceed()}
              disabled={props.amountInvalid}
              style={{
                backgroundColor: props.amountInvalid ? "gray" : "#3A86FF",
              }}
            >
              {props.loading ? (
                <Loader type="TailSpin" color="#fff" height={15} width={15} />
              ) : (
                "Proceed"
              )}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
