import { Instance, SnapshotOut, types } from "mobx-state-tree";

const openpooldata = types.model("OpenPoolData").props({
  id: types.optional(types.string, ""),
  pool_title: types.optional(types.string, ""),
  risk_rate: types.optional(types.string, ""),
  badge_title: types.optional(types.string, ""),
  tenure: types.optional(types.string, ""),
  tenure_type: types.optional(types.string, ""),
  interest_rate: types.optional(types.string, ""),
  minimum_investment: types.optional(types.string, ""),
  start_date: types.optional(types.string, ""),
  end_date: types.optional(types.string, ""),
  totle_investment: types.optional(types.string, ""),
  is_shielded: types.optional(types.string, ""),
  description: types.optional(types.string, ""),
  logo_color: types.optional(types.string, ""),
  badge_color: types.optional(types.string, ""),
  risk_color: types.optional(types.string, ""),
});

export const OpenPoolModel = types
  .model("OpenPool")
  .props({
    pools: types.optional(types.array(openpooldata), []),
    pagination: types.optional(
      types.model({
        current_page: types.optional(types.number, 0),
        count: types.optional(types.number, 0),
        starts_from: types.optional(types.number, 0),
        per_page: types.optional(types.number, 0),
      }),
      {}
    ),
  })
  .views((self) => ({}))
  .actions((self) => ({}));

type OpenPoolType = Instance<typeof OpenPoolModel>;
export interface OpenPool extends OpenPoolType {}

type OpenPoolSnapshotType = SnapshotOut<typeof OpenPoolModel>;
export interface OpenPoolSnapshot extends OpenPoolSnapshotType {}
