import * as React from "react";
import Loader from "react-loader-spinner";
import Modal from "@mui/material/Modal";
import { AlreadyAcceptedModal } from "..";
import CloseIcon from "@mui/icons-material/Close";
import { closeicon, download } from "../../assets/images/index";
import "./tc-viewer.css";
const styles = (theme) => ({
  modalStyle1: {},
});

export default function TcViewer(props) {
  // prevent only digit

  const [bottom, setBottom] = React.useState(false);
  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [acceptedModelOpen, setAcceptedModelOpen] = React.useState(
    props.alreadyAccepted
  );

 
  React.useEffect(() => {
    setAcceptedModelOpen(props.alreadyAccepted);
  }, [props.alreadyAccepted]);

  const handleScroll = (e) => {
    const element = e.target;
    const isBottom = element.scrollHeight - element.scrollTop === element.clientHeight;
   
    setBottom(isBottom);
    console.log(bottom);
  };
  

  return (
    <Modal
      open={props.openModel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      hideBackdrop={true}
      style={{
        backgroundColor: "#e1e3e6",
        alignItems: "center",
        justifyContent: "center",
        margin: "0px",
        padding: "0px",
      }}
      disableScrollLock={false}
    >
      <div>
        {/* Header */}
        <div style={{ backgroundColor: "white", justifyContent: "center", display: "flex", padding: 10 }}>
          <img
            src="https://arthmate-p2p-public-assets.s3.ap-south-1.amazonaws.com/images/1invest-logo-color-dark.png"
            alt="logo"
            className="auth_logo_img"
          />
        </div>

        {/* Body */}
        <div className="terms-conditions-container" style={{ height: 'calc(100vh - 150px)', overflowY: 'auto' }} onScroll={handleScroll}>
          <div className="terms-conditions-header">
            <div className="header-content">
              <div className="header-title">Terms & Conditions</div>
              <div
          
                onClick={() => props.setOpenModel(false)}
              >
              <img
                src={closeicon}
              />
              </div>
            </div>
        
            <embed
              id="pdfEmbed"
              src={`${props.url}#toolbar=0&navpanes=0&scrollbar=0&view=FitH&border=0`}
              type="application/pdf"
              className="embed-frame"
            />
          
          </div>

         {/* Footer */}
             <div className="footer">
            <div className="download-button-container">
              <img
                className="download-button-icon"
                src={download}
                alt="download"
              />
              <div
                className={`download-button ${props.showDownload ? '' : 'hidden'}`}
                onClick={props.onDownload}
              >
                {props.loading ? (
                  <Loader type="TailSpin" color="#3a86ff" height={15} width={15} />
                ) : (
                  "Download PDF"
                )}
              </div>
            </div>
            <button
              disabled={props.alreadyAccepted}
              className={`custom-button ${props.alreadyAccepted ? 'disabled' : ''}`}
              onClick={props.onSubmit}
            >
              {props.loading ? (
                <Loader type="TailSpin" color="#fff" height={15} width={15} />
              ) : (
                "Agree & continue"
              )}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}