import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Forms_API } from "../../services/api";
/* eslint-disable react/prop-types */

const data = {
  partner_id: "{{partner_one_id}}",
  loan_id: "{{loan_id}}",
  repayment_number: "2",
  amount_repaid: 17282,
};

function Repayment(props) {
  const [loanId, setLoanId] = useState(props.location.state.loan_id);
  const [repaymentNumber, setRepaymentNumber] = useState(1);
  const [amount, setAmount] = useState(17282);
  const navigate = useHistory();
  const api = new Forms_API();

  const [partnerId, setPartnerId] = useState(
    "4b41454d-fe8d-11eb-875f-02a64dc07394"
  );

  const handleSubmit = (e) => {
    const data = {
      partner_id: partnerId,
      loan_id: loanId,
      repayment_number: repaymentNumber,
      amount_repaid: amount,
    };
    console.log(data, "repayment");
    api.repayments(data).then((res) => {
      console.log(res, "repaymentresponse");
      res.kind == "ok" &&
        navigate.push({
          pathname: "/borrower",
        });
    });
    e.preventDefault();
  };

  //   useEffect(() => {
  //     props.showCommonHeader(true);
  //     props.showCommonSidebar(true);
  //     // eslint-disable-next-line
  //   }, []);
  const isValid =
    partnerId == "" || loanId == "" || repaymentNumber == "" || amount == "";
  return (
    <div className="forms">
      <header className="form-header">
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <h3> Repayment Form </h3>
          <div className="textFieldDiv">
            <label>Partner Id:</label>
            <input
              name="partner_id"
              type="text"
              value={partnerId}
              required
              onChange={(e) => {
                setPartnerId(e.target.value);
              }}
            />
            <br />
          </div>
          <div className="textFieldDiv">
            <label>Loan Id:</label>
            <input
              name="loan_id"
              type="text"
              value={loanId}
              required
              onChange={(e) => {
                setLoanId(e.target.value);
              }}
            />
            <br />
          </div>
          <div className="textFieldDiv">
            <label>Repayment Number:</label>
            <input
              name="repayment_number"
              type="text"
              value={repaymentNumber}
              required
              onChange={(e) => {
                setRepaymentNumber(e.target.value);
              }}
            />
            <br />
          </div>
          <div className="textFieldDiv">
            <label>Amount Repaid:</label>
            <input
              name="amount_repaid"
              type="text"
              value={amount}
              required
              onChange={(e) => {
                setAmount(e.target.value);
              }}
            />
            <br />
          </div>
          <input
            disabled={isValid}
            className="submit"
            type="submit"
            value="Submit"
          />
        </form>
      </header>
    </div>
  );
}

export default Repayment;
