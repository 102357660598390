import React, { useEffect, useState } from "react";
import "./withdrawalConfirmation.css";
import { useParams, useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import { User_API, WithdrawalApi } from "../../services/api";
import InfoIcon from "@mui/icons-material/Info";
import { convertAmount, errorToast, successToast } from "../../utils/helper";
import {
  FullScreenLoader,
  InfoAlert,
  SampleToast,
  useWindowDimensions,
} from "../../components";
import { eventLog } from "../../utils/firebase";
import { successTick } from "../../assets/images";

const WithdrawalConfirmation = (props) => {
  const { uuid } = useParams();
  const navigate = useHistory();
  const dimension = useWindowDimensions();
  const api = new WithdrawalApi();
  const userApi = new User_API();
  const [linkData, setLinkData] = useState();
  const [render, setRender] = useState(false);
  const [err, setErr] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [fullLoader, setFullLoader] = useState(false);
  const [otpView, setOtpView] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [successHeader , setSuccessHeader] = useState("")
  const [infoMsg, setInfoMsg] = useState("");
  const [verified, setVerified] = useState(false);
  const [user, setUser] = useState(null);
  const [otp, setOtp] = useState("");
  const [otpInvalid, setOtpInvalid] = useState(false);
  const [otpInvalidText, setOtpInvalidText] = useState("");
  const [withdrawSuccess, setWithdrawSuccess] = useState(false);

  // get the payment data
  useEffect(() => {
    props.showCommonHeader(false);
    props.showCommonSidebar(false);
    let getData = async () => {
      await api.getWithdrawalRequestData(uuid).then((res) => {
        if (res.kind === "ok") {
          console.log(res.data);
          eventLog({
            type: "withdrawal_request_page",
            action: {
              trigger: "true",
            },
          });
          setLinkData(res.data);
          setRender(true);
        } else {
          setRender(true);
          setErr(true);
          setErrMsg("The page you are looking for is not available");
        }
      });
    };
    return getData();
    // eslint-disable-next-line
  }, []);

  // amount key press handler
  const onKeyPress = (e) => {
    const re = /[^0-9]/g;
    if (re.test(e.key)) {
      e.preventDefault();
    }
  };

  // on send otp
  const onSendOtp = async () => {
    setFullLoader(true);
    let response = {};
    await api.getWithdrawalRequestData(uuid).then((res) => {
      response = res;
      if (res.kind === "ok") {
        console.log(res.data);
        setLinkData(res.data);
        setRender(true);
      } else {
        setRender(true);
        setErr(true);
        setErrMsg("The page you are looking for is not available");
        return;
      }
    });

    if (response.kind == "bad-data") {
      setFullLoader(false);
      return;
    }
      
    const number = "+91" + linkData.client_mobile;
    const payload = {
      mobile: linkData.client_mobile,
      isIfa: false,
    };
    await userApi
      .sendMobileVerification(payload)
      .then((response) => {
        console.log("response", response);
        if (response.kind == "ok") {
          let userName = response.data.pan_number;
          if (response.data.oldExistingUser)
            userName = `+91${linkData.client_mobile}`;
          if (response.data.isExistingUser) {
            Auth.signIn(userName)
              .then((response) => {
                setUser(response);
                setFullLoader(false);
                setOtpView(true);
                successToast("OTP has been sent successfully.");
                eventLog({
                  type: "withdrawal_request_otp",
                  action: {
                    trigger: "true",
                  },
                });
              })
              .catch((err) => {
                setFullLoader(false);
                console.log(err);
                errorToast(
                  "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
                );
                setTimeout(() => {
                  window.location.reload();
                }, 5000);
              });
          } else {
            setFullLoader(false);
            errorToast("User doesn't exist");
          }
        } else {
          setFullLoader(false);
          errorToast(
            "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
          );
        }
      })
      .catch((error) => {
        console.log("Error while sending OTP ::: ", error);
        setFullLoader(false);
        errorToast(
          "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
        );
      });
  };

  // get authentication
  const onGetAuth = async () => {
    setFullLoader(true);
    let response = {};
    await api.getWithdrawalRequestData(uuid).then((res) => {
      response = res;
      if (res.kind === "ok") {
        console.log(res.data);
        setLinkData(res.data);
        setRender(true);
      } else {
        setRender(true);
        setErr(true);
        setErrMsg("The page you are looking for is not available");
        return;
      }
    });

    if (response.kind == "bad-data") {
      setFullLoader(false);
      return;
    }

    setFullLoader(false);

    if (otp.length === 0) {
      setOtpInvalid(true);
      setOtpInvalidText("OTP cannot be empty");
    } else if (otp.length !== 4) {
      setOtpInvalid(true);
      setOtpInvalidText("Invalid OTP");
    } else if (isNaN(parseInt(otp))) {
      setOtpInvalid(true);
      setOtpInvalidText("OTP must be a number");
    } else {
      setFullLoader(true);
      await Auth.sendCustomChallengeAnswer(user, otp)
        .then(async (res) => {
          if (res && !res.Session) {
            eventLog({
              type: "withdrawal_request_otp_verified",
              action: {
                trigger: "true",
              },
            });

            setTimeout(() => {
              setFullLoader(false);
              setOtpView(false);
              setVerified(true);
            }, 2000);
          } else {
            setFullLoader(false);
            setOtpInvalid(true);
            setOtpInvalidText(
              "Wrong OTP. Please check and enter the correct OTP."
            );
          }
        })
        .catch((err) => {
          setFullLoader(false);
          console.log(err);
          errorToast(
            "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
          );
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        });
    }
  };

  // on confirm or cancel
  const onRequestSubmit = async (status) => {
    setFullLoader(true);
    let response = {};
    await api.getWithdrawalRequestData(uuid).then((res) => {
      response = res;
      if (res.kind === "ok") {
        console.log(res.data);
        setLinkData(res.data);
        setRender(true);
      } else {
        setRender(true);
        setErr(true);
        setErrMsg("The page you are looking for is not available");
        return;
      }
    });

    if (response.kind == "bad-data") {
      setFullLoader(false);
      return;
    }

    // payload
    let payload = {
      status: status,
    };

    // call the api
    await api.updateWithdrawalRequestStatus(payload, uuid).then((res) => {
      if (res.kind === "ok") {
        if(res.data == "Withdrawal request has been successfully cancelled."){
           setSuccessHeader("Withdrawal Failed") 
           setInfoMsg("Withdrawal request has been successfully cancelled.")
          } else{
           setSuccessHeader("Withdrawal request approved") 
           setInfoMsg(res.data);
          } 
        setTimeout(() => {
          eventLog({
            type: "withdrawal_request_accepted",
            action: {
              trigger: "true",
            },
          });
          setFullLoader(false);
          setWithdrawSuccess(true);
        }, 3000);
      } else {
        setFullLoader(false);
        eventLog({
          type: "withdrawal_request_accepted",
          action: {
            trigger: "false",
          },
        });
        setInfoMsg(res.error);
        setShowInfo(true);
      }
    });
  };

  // sign out user
  const onSignOut = async () => {
    await Auth.signOut()
      .then(() => {
        navigate.push("/");
      })
      .catch((err) => {
        console.log(err);
        navigate.push("/");
      });
  };
  // on successfull withdraw
  const withdrawSuccessDivView = () => {
    return (
      <div>
        <div className="payment_success_page_container">
          <div className="payment_success_head_grid_container">
            <div style={{ textAlign: "center" }}>
              <img
                src= {successTick}
                alt="request_initiated_icon"
                className="payment_success_check_mark"
              />
              <p className="payment_success_main_title_text">
                {successHeader}
              </p>
              <p className="payment_success_sub_title_text">{infoMsg}</p>
            </div>
            <br />
            <div className="payment_success_container">
              <div className="payment_success_details">
                <p className="payment_success_details_text">Withdrawal Details</p>
              </div>
              <div className="payment_success_grid_container">
                <div className="payment_success_item_left">
                  <p className="payment_success_item_text"> Client</p>
                </div>
                <div className="payment_success_item_right">
                  <p className="payment_success_item_text">
                    {linkData.client_name}
                  </p>
                </div>
              </div>
              <div className="payment_success_grid_container">
                <div className="payment_success_item_left">
                  <p className="payment_success_item_text">Advisor</p>
                </div>
                <div className="payment_success_item_right">
                  <p className="payment_success_item_text">
                    {linkData.ifa_name}
                  </p>
                </div>
              </div>
              <div className="payment_success_grid_container">
                <div className="payment_success_item_left">
                  <p className="payment_success_item_text">Amount</p>
                </div>
                <div className="payment_success_item_right">
                  <p className="payment_success_item_text">
                    ₹ {convertAmount(linkData.amount)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // payment details view
  const withdrawalDetailsView = () => {
    return (
      <div
        className="withdrawal_confirmation_div"
        style={{ paddingBottom: "0px" }}
      >
        {/* header */}
        <header className="withdrawal_confirmation_header">
          <h1 className="withdrawal_confirmation_header_title">
            Complete Withdrawal
          </h1>
        </header>

        {/* investor name */}
        <div className="withdrawal_confirmation_div_container">
          <div className="withdrawal_confirmation_data_div1">
            <div className="withdrawal_confirmation_data">
              <h1 className="withdrawal_confirmation_data_title">Client</h1>
              <h1 className="withdrawal_confirmation_data_subtitle">
                {linkData.client_name}
              </h1>
            </div>
          </div>
        </div>

        {/* amount */}
        <div
          className="withdrawal_confirmation_div_container"
          style={{ display: !verified ? "flex" : "none" }}
        >
          <div className="withdrawal_confirmation_data_div1">
            <div className="withdrawal_confirmation_data">
              <h1 className="withdrawal_confirmation_data_title">Amount</h1>
              <h1 className="withdrawal_confirmation_data_subtitle">
                ₹ {convertAmount(linkData.amount)}
              </h1>
            </div>
          </div>
        </div>

        {/* ifa name */}
        <div
          className="withdrawal_confirmation_div_container"
          style={{ display: verified ? "flex" : "none" }}
        >
          <div className="withdrawal_confirmation_data_div3">
            {/* ifa name */}
            <div className="withdrawal_confirmation_data">
              <h1 className="withdrawal_confirmation_data_title">Advisor</h1>
              <h1 className="withdrawal_confirmation_data_subtitle">
                {linkData.ifa_name}
              </h1>
            </div>

            {/* amount */}
            <div
              className="withdrawal_confirmation_data"
              style={{ marginLeft: dimension.width > 500 ? "25%" : 0 }}
            >
              <h1 className="withdrawal_confirmation_data_title">Amount</h1>
              <h1 className="withdrawal_confirmation_data_subtitle">
                ₹ {convertAmount(linkData.amount)}
              </h1>
            </div>
          </div>
        </div>

        <div
          style={{
            display: verified ? "flex" : "none",
            paddingTop: "15px",
            paddingLeft: "20px",
          }}
        >
          <h1 className="withdrawal_confirmation_data_title">Withdraw From:</h1>
        </div>

        <div
          className="withdrawal_confirmation_div_container"
          style={{ display: verified ? "flex" : "none" }}
        >
          <div className="withdrawal_confirmation_data_div5">
            {/* ifa name */}
            {linkData.wallet_withdrawals_predictive_deduction &&
            linkData.wallet_withdrawals_predictive_deduction != 0 ? (
              <div className="withdrawal_confirmation_data">
                <h1 className="withdrawal_confirmation_data_title">Wallet</h1>
                <h1 className="withdrawal_confirmation_data_subtitle">
                  ₹{" "}
                  {convertAmount(
                    linkData.wallet_withdrawals_predictive_deduction
                  )}
                </h1>
              </div>
            ) : null}

            {/* amount */}
            {linkData.freedom_plan_earnings_predictive_deduction &&
            linkData.freedom_plan_investment_predictive_deduciton != 0 ? (
              <div className="withdrawal_confirmation_data">
                <h1 className="withdrawal_confirmation_data_title">
                  Freedom Plan Earnings
                </h1>
                <h1 className="withdrawal_confirmation_data_subtitle">
                  ₹{" "}
                  {convertAmount(
                    linkData.freedom_plan_earnings_predictive_deduction
                  )}
                </h1>
              </div>
            ) : null}

            {linkData.freedom_plan_investment_predictive_deduciton &&
            linkData.freedom_plan_investment_predictive_deduciton != 0 ? (
              <div className="withdrawal_confirmation_data">
                <h1 className="withdrawal_confirmation_data_title">
                  Freedom Plan Investment
                </h1>
                <h1 className="withdrawal_confirmation_data_subtitle">
                  ₹{" "}
                  {convertAmount(
                    linkData.freedom_plan_investment_predictive_deduciton
                  )}
                </h1>
              </div>
            ) : null}
          </div>
        </div>

        {/* proceed div */}
        <div
          className="withdrawal_confirmation_data_div4"
          style={{
            marginTop: "10px",
            marginBottom: "10px",
            display: verified ? "none" : "flex",
          }}
        >
          <button
            onClick={onSendOtp}
            style={{
              backgroundColor: "#3a86ff",
              cursor: "pointer",
            }}
            className="withdrawal_confirmation_button"
          >
            Proceed
          </button>
        </div>

        {/* approve div */}
        <div
          className="withdrawal_confirmation_data_div4"
          style={{
            marginTop: "10px",
            marginBottom: "10px",
            justifyContent: "space-between",
            display: !verified ? "none" : "flex",
          }}
        >
          <button
            onClick={() => onRequestSubmit(0)}
            style={{
              backgroundColor: "transparent",
              cursor: "pointer",
              width: "45%",
              color: "#cc3a2f",
              border: "1px solid #cc3a2f",
            }}
            className="withdrawal_confirmation_button"
          >
            Cancel
          </button>

          <button
            onClick={() => onRequestSubmit(3)}
            style={{
              backgroundColor: "#3a86ff",
              cursor: "pointer",
              width: "45%",
            }}
            className="withdrawal_confirmation_button"
          >
            Confirm
          </button>
        </div>
      </div>
    );
  };

  // otp enter view
  const otpEnterView = () => {
    return (
      <div className="withdrawal_confirmation_div">
        {/* header */}
        <header className="withdrawal_confirmation_header">
          <h1 className="withdrawal_confirmation_header_title">
            Complete Withdrawal
          </h1>
        </header>
        {/* info */}
        <div className="otp_info_text_div">
          <p className="otp_info_text">
            {" "}
            Please enter the 4-digit OTP sent via SMS on your registered mobile
            number (+91-XXXXXX
            {linkData.client_mobile.substr(linkData.client_mobile.length - 4)})
          </p>
        </div>
        {/* otp input */}
        <input
          type="text"
          maxLength={4}
          placeholder="Enter OTP"
          className="withdrawal_confirmation_input"
          onKeyPress={onKeyPress}
          onChange={(event) => {
            setOtpInvalid(false);
            setOtpInvalidText("");
            setOtp(event.target.value);
          }}
          style={{
            borderColor: otpInvalid ? "#ff2e2e" : undefined,
          }}
        />

        {/*validation error */}
        <div className="withdrawal_confirmation_invalid_div">
          {/* icon */}
          <InfoIcon
            style={{
              fontSize: "14px",
              paddingRight: "5px",
              color: "#ff2e2e",
              opacity: otpInvalid ? 1 : 0,
            }}
          />
          {/* text */}
          <p
            className="withdrawal_confirmation_invalid_text"
            style={{
              opacity: otpInvalid ? 1 : 0,
            }}
          >
            {otpInvalid ? otpInvalidText : "Invalid"}
          </p>
        </div>

        {/* button */}
        <button
          onClick={onGetAuth}
          className="withdrawal_confirmation_otp_button"
        >
          Submit
        </button>
      </div>
    );
  };

  // page not available view
  const pageNotAvailable = () => {
    return (
      <div className="withdrawal_confirmation_page_expire">
        <h1 className="withdrawal_confirmation_header_page_expire_title">
          {errMsg}
        </h1>
      </div>
    );
  };

  return (
    <div>
      {render === false ? (
        <div />
      ) : (
        <div className="withdrawal_confirmation_container">
          {/* logo */}
          <img
            src="https://arthmate-p2p-public-assets.s3.ap-south-1.amazonaws.com/images/1invest-logo-color-dark.png"
            alt="logo"
            className="withdrawal_confirmation_logo_image"
          />

          {linkData === undefined || err
            ? // page not found
              pageNotAvailable()
            : otpView
            ? // otp enter view
              otpEnterView()
            : !otpView && !withdrawSuccess
            ? // withdrawal details view with confirm and cancel
              withdrawalDetailsView()
            : // withdrawal details view
              withdrawSuccessDivView()}
        
          {/* info alert */}
          <InfoAlert
            show={showInfo}
            ok={() => {
              setShowInfo(false);
              onSignOut();
            }}
            title="Alert"
            content={infoMsg}
            okbutton="OK"
          />

          {/* full screen loader */}
          {fullLoader ? <FullScreenLoader /> : <div />}

          {/* toast container */}
          <SampleToast />
        </div>
      )}
    </div>
  );
};

export default WithdrawalConfirmation;
