import React, { useRef, useState, useEffect } from "react";
import InfoIcon from "@mui/icons-material/Info";
import "./input-box.css";
import { Checkbox } from "@mui/material";

const onKeyPress = (e) => {
  const re = /[^0-9]/g;
  if (re.test(e.key)) {
    e.preventDefault();
  }
};

export const InputBox = (props) => {
  // prevent key press only numbers

  const { RightLabelComponent } = props;

  const [secondLeft, setSecondLeft] = useState(59);

  useEffect(() => {
    valueUpdate(props.value);
  }, []);

  const valueUpdate = (value) => {
    if (props.hasOwnProperty("autoCapitalize") && props.autoCapitalize) {
      value = value ? value.toUpperCase() : null;
    }

    if (props.object) {
      props.setValue({
        ...props.object,
        [props.id]: value,
      });
    } else {
      props.setValue(value);
    }

    if (props.hasOwnProperty("errorObject") && props.errorObject) {
      if (props.type === "date" && (value > props.max || value < props.min)) {
        props.setErrorObject({
          ...props.errorObject,
          [props.id]: true,
        });
      } else {
        props.setErrorObject({
          ...props.errorObject,
          [props.id]: false,
        });
      }
    } else if (props.hasOwnProperty("error")) {
      props.setError(false);
    }
  };

  const handleChange = (e) => {
    if (e.target.value && e.target.value.length > props.maxLength) {
      return;
    }

    const sentence = e.target.value;

    if (sentence.length > 0) {
      //The sentence should not start with a space.
      const noLeadingSpaceRegex = /^[^\s]/;
      const isValidLeadingSpace = noLeadingSpaceRegex.test(sentence);
  
      //Only one space allowed between each word in the sentence.
      const singleSpaceRegex = /^(?!.*\s{2})\S*(?:\s\S*)*$/;
      const isValidSingleSpace = singleSpaceRegex.test(sentence);
  
      if (!(isValidLeadingSpace && isValidSingleSpace)) {
        return;
      }
    }

    valueUpdate(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();
      return;
    }
  };

  return (
    <div
      style={{
        display:
          props.hasOwnProperty("visibility") && !props.visibility ? "none" : "",
      }}
    >
      <div
        className="input_box_field_container"
        style={{
          marginTop: props.label === "GST Number" ? 0 : "12px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <label className="input_box_label_container">{props.label}</label>
          <span style={{ color: "red" }}> *</span>
          {props.hasOwnProperty("RightLabelComponent") && (
            <RightLabelComponent />
          )}
        </div>
        <input
          value={props.value ? props.value : ""}
          type={props.type}
          disabled={props.disabled}
          className="input_box_input_container"
          id={props.id}
          maxLength={props.maxLength ? props.maxLength : null}
          onChange={handleChange}
          onKeyPress={props.type == "number" ? onKeyPress : null}
          onKeyDown={handleKeyDown}
          onWheel={(e) => e.target.blur()}
          style={{
            borderColor: props.invalid ? "#ff2e2e" : undefined,
          }}
          min={props.hasOwnProperty("min") && props.min ? props.min : ""}
          max={props.hasOwnProperty("max") && props.max ? props.max : ""}
        />
      </div>
      {/*validation error */}
      <div
        className="input_box_invalid_div"
        style={{
          opacity: 1,
        }}
      >
        {/* icon */}
        <InfoIcon
          style={{
            fontSize: "14px",
            paddingRight: "5px",
            color: "#ff2e2e",
            opacity: props.isError ? 1 : 0,
          }}
        />
        {/* text */}
        <p
          className="input_box_invalid_text"
          style={{
            opacity: props.isError ? 1 : 0,
          }}
        >
          {props.isError ? props.errorText : "Invalid"}
        </p>
      </div>
    </div>
  );
};

export default InputBox;
