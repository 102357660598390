import "./hideIFA.css";
import config from "../../config/settings";

const hideIFA = () => {
  return (
    <div className="banner_container">
      <header className="banner_header">
        <img
          src="https://arthmate-p2p-public-assets.s3.ap-south-1.amazonaws.com/images/1invest-logo-color-dark.png"
          alt="logo"
          className="banner_logo_img"
          onClick={() => window.open(config.website.url, "_self")}
        />
      </header>
      <div className="banner">
        <img
          src="https://arthmate-p2p-public-assets.s3.ap-south-1.amazonaws.com/images/dimension.png"
          alt="banner image"
          className="banner_image"
        />
        <p className="banner_text">
          We apologize for any inconvenience this may cause and encourage you to
          visit our website on a desktop or laptop for the best experience.
        </p>
      </div>
    </div>
  );
};

export default hideIFA;
