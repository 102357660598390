import "./dropdown.css";
import React, { useRef, useState, useEffect } from "react";
import dropdownArrow from "../../assets/images/dropdownArrow.svg";
import dropdownDisable from "../../assets/images/dropdowndisabled.svg";
import successTick from "../../assets/images/successTick.svg";
import MenuItem from "@mui/material/MenuItem";
import config from "../../config/settings";

const menuItem = {
  fontSize: "16px",
};

const Dropdown = ({
  id,
  dropdownList,
  isActive,
  setIsActive,
  value,
  setValue,
  object,
  label,
  required,
  placeholder,
  disabled
}) => {
  useEffect(() => {});

  const onDropdownSelect = (currentValue) => {
    if (object) {
      setValue({
        ...object,
        [id]: currentValue,
      });
    } else {
      setValue(currentValue);
    }
    setIsActive(false);
  };

  return (
    <div className="dropdown_select_dropdown">
      <h1 className="dropdown_select_firm_text">
        {label} {required && <span style={{ color: "red" }}> *</span>}
      </h1>
      <div className="dropdown">
        <div
          className={isActive ? "dropdow_disable" : "dropdown_button"}
          onClick={() => {
            if(disabled){
              setIsActive(false);
            }else{
              setIsActive(!isActive);
            }
          }}
          // onChange={()=>{handleOptionChange()}}
        >
          {!value ? (
            placeholder
          ) : (
            <span style={{ color: "black" }}> {value}</span>
          )}
          {/* {<img src={selectedType ? successTick : (isActive ? dropdownArrow :  dropdownDisable)}/>} */}
          {<img src={isActive ? dropdownArrow : dropdownDisable} />}
        </div>

        {isActive && (
          <div className="dropdown_content">
            {dropdownList.map((item, index) => (
              <MenuItem
                key={index}
                style={menuItem}
                value={item.value}
                onClick={(e) => {
                  onDropdownSelect(item.value);
                }}
              >
                <div>{item.label}</div>
              </MenuItem>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Dropdown;
