import React, { useEffect, useState } from "react";
import "./withdrawal_requests.css";
import InfoIcon from "@mui/icons-material/Info";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Pagination,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import { convertAmount, errorToast, sorting } from "../../utils/helper";
import {
  ContentLoadingLoader,
  SampleToast,
  LabelWithSortComponent,
} from "../../components";
import { convertUTCDateToLocalDate,convertedAsLocal } from "../../utils/helper";
import AdditionalInfo from "../additional_info/additional_info";
import AmountLockModel from "../model-amount-locked/model-amount-locked";
import WithdrawalDetailsModal from "../withdrawal-details-model/withdrawal-details-model";

const tableContainer = {
  marginTop: "20px",
  width: "92%",
  borderTopLeftRadius: "5px",
  borderTopRightRadius: "5px",
  borderTop: "1px solid #c7c7c7",
  borderLeft: "1px solid #c7c7c7",
  borderRight: "1px solid #c7c7c7",
  alignSelf: "center",
  overflow: "scroll",
};

const tableHeader = {
  backgroundColor: "#f6f6f6",
  fontWeight: "600",
  fontSize: 16,
  fontFamily: "Asap",
  color: "#1f1f1f",
  borderRight: "1px solid #c7c7c7",
  paddingTop: "15px",
  paddingLeft: "10px",
  paddingRight: "0px",
  paddingBottom: "15px",
};

const tableRow = {
  fontSize: 14,
  fontFamily: "Asap",
  fontWeight: "400",
  color: "#1c1c1c",
  borderRight: "1px solid #c7c7c7",
  paddingTop: "10px",
  paddingLeft: "10px",
  paddingRight: "0px",
  paddingBottom: "10px",
};

const WithdrawalRequests = (props) => {
  const [page, setPage] = useState(0);
  const rowsPerPage = 15;
  /* sorting */
  const asc_label_sort = {
    sent_at: false,
    name: false,
    amount: false,
  };
  const [ismodelOpen,setIsModelOpen]=useState(false);
  const [asc, setAsc] = useState(asc_label_sort);
  const [selected, setSelected] = useState("sent_at");
  const [selectedType, setSelectedType] = useState("timestamp");
  const [openWithdrawalDetailsModel, setOpenWithdrawalDetailsModel] = useState(false);
  const [selectedData, setSelectedData] = useState(null)
  // request search function
  const requestSearch = (event) => {
    setPage(0);
    const filter_rows = props.filterData.filter((row) => {
      return row.name.toLowerCase().includes(event.target.value.toLowerCase());
    });
    props.setPaymentData(filter_rows);
  };

  // handle page change function
  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };
  return (
    <div>
      <div className="withdraw_requests_div_container">
        {/* table */}
        <div>
          {props.render === false ? (
            <ContentLoadingLoader />
          ) : (
            <div className="withdraw_requests_div_tablecontainer">
              {/* search_bar */}
              <div className="withdraw_requests_search_bar_div">
                <div className="withdraw_requests_search_bar">
                  {/* icon */}
                  <SearchIcon
                    style={{
                      fontSize: "20px",
                      paddingRight: "10px",
                      color: "#555555",
                    }}
                  />
                  {/* input box */}
                  <input
                    type="text"
                    placeholder="Search by Name"
                    className="withdraw_requests_search_bar_input"
                    onChange={requestSearch}
                  />
                </div>
              </div>

              {/* table */}
              <TableContainer style={tableContainer}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" style={tableHeader}>
                        <LabelWithSortComponent
                          label="Request Date & Time"
                          id="sent_at"
                          type="timestamp"
                          asc={asc}
                          selected={selected}
                          setAsc={setAsc}
                          setSelected={setSelected}
                          setSelectedType={setSelectedType}
                        />
                      </TableCell>
                      <TableCell align="left" style={tableHeader}>
                        <LabelWithSortComponent
                          label="Client Name"
                          id="name"
                          type="string"
                          asc={asc}
                          selected={selected}
                          setAsc={setAsc}
                          setSelected={setSelected}
                          setSelectedType={setSelectedType}
                        />
                      </TableCell>
                      <TableCell align="left" style={tableHeader}>
                        Mobile No.
                      </TableCell>
                      <TableCell align="left" style={tableHeader}>
                        <LabelWithSortComponent
                          label="Amount (₹)"
                          id="amount"
                          type="number"
                          asc={asc}
                          selected={selected}
                          setAsc={setAsc}
                          setSelected={setSelected}
                          setSelectedType={setSelectedType}
                        />
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          ...tableHeader,
                          borderRight: "none",
                        }}
                      >
                        Status
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.paymentData
                    .sort((a, b) =>
                        asc[selected]
                          ? sorting(a, b, selected, selectedType, "asc")
                          : sorting(a, b, selected, selectedType, "desc")
                      )
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => (
                        <TableRow
                          key={index}
                          style={{
                            backgroundColor:
                              index % 2 === 0 ? "#fafafa" : "#fff",
                          }}
                        >
                          <TableCell style={tableRow} align="left">
                            {convertedAsLocal(row.sent_at)}
                          </TableCell>
                          <TableCell style={tableRow} align="left">
                            {row.name}
                          </TableCell>
                          <TableCell style={tableRow} align="left">
                            {row.mobile}
                          </TableCell>
                          <TableCell style={tableRow} align="left">
                            {convertAmount(row.amount)}
                          </TableCell>
                          <TableCell
                            style={{
                              ...tableRow,
                              borderRight: "none",
                            }}
                            align="left"
                          >
                            <p onClick={() =>{ setSelectedData(row)
                            }}>
                              { row.display_modal === 1 ? 
                              (
                                row.status === 2 ? 
                                <span 
                                style={{ display: "flex", alignItems: "center" , cursor :"pointer" , textDecoration: "underline" , color: "#E2730D" }} 
                                onClick={() => { setOpenWithdrawalDetailsModel(!openWithdrawalDetailsModel)
                                }}>
                                Pending </span> 
                                : row.status === 3? 
                                <span 
                                style={{ display: "flex", alignItems: "center" , cursor :"pointer" , textDecoration: "underline" , color: "#2DAE18" }} 
                                onClick={() => { setOpenWithdrawalDetailsModel(!openWithdrawalDetailsModel)}}>
                                Approved </span>
                                : row.status === 9 ?   
                                <span 
                                style={{ display: "flex", alignItems: "center" , cursor :"pointer" , textDecoration: "underline" , color: "#0685E1" }} 
                                onClick={() => { setOpenWithdrawalDetailsModel(!openWithdrawalDetailsModel)}}>
                                Processed </span> 
                                : row.status === 8 ?  
                                <span style={{ display: "flex", alignItems: "center" , color: "red" }} 
                                >Declined </span> :
                                <span style={{ display: "flex", alignItems: "center" , color: "red" }} 
                                >Expired </span> ) 
                                : row.status === 3 ? <span 
                                style={{ display: "flex", alignItems: "center"  , color: "#0685E1" }}>
                                Processed </span> : <span style={{ display: "flex", alignItems: "center" , color: "red" }} 
                                >Expired </span>
                              }
                            </p>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                  {/* <AmountLockModel openModel={ismodelOpen} handleClose={() => { setIsModelOpen(!ismodelOpen) }} /> */}
                  { selectedData && <WithdrawalDetailsModal
                    openModel={openWithdrawalDetailsModel}
                    selectedData={selectedData}
                    handleClose={() => {
                      setSelectedData(null)
                      setOpenWithdrawalDetailsModel(false);
                    }}
                  />}
                {/* if no data available */}
                {props.paymentData?.length === 0 ? (
                  <div className="no_row_found_div">
                    <FindInPageIcon
                      style={{
                        fontSize: "100px",
                        color: "#555555",
                      }}
                    />
                    <p className="no_row_found">No row found</p>
                  </div>
                ) : (
                  <div />
                )}
              </TableContainer>
              {/* pagination */}
              <div className="pagination_div">
                <Pagination
                  color="standard"
                  count={Math.ceil(props.paymentData.length / rowsPerPage)}
                  page={page + 1}
                  variant="outlined"
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                  onChange={handleChangePage}
                  shape="rounded"
                />
              </div>
            </div>
          )}
        </div>

        {/* toast container */}
        <SampleToast />
      </div>
    </div>
  );
};
export default WithdrawalRequests;
