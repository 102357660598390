import React, { useEffect, useState, useRef } from "react";
import "./clientkyc.css";
import Loader from "react-loader-spinner";
import MenuItem from "@mui/material/MenuItem";
import { TextField } from "@mui/material";
import { useLocation, useHistory } from "react-router-dom";
import {
  errorToast,
  getFileExtension,
  getUniqueUUID,
  imageCompressor,
  infoToast,
  successToast,
  warningToast,
} from "../../utils/helper";
import { ErrorValue, InputInfoText, OtpEnterModel } from "../../components";
import config from "../../config/settings";
import { Kyc_API } from "../../services/api";
import Auth from "@aws-amplify/auth";

const imageSizeLimit = 15;

const inputBox = {
  marginBottom: "12px",
  overflow: "hidden",
  borderRadius: "3px",
};

const inputStyle = {
  height: "45px",
  fontSize: "16px",
};

const menuItem = {
  fontSize: "16px",
};

const ClientKycDocument = function (props) {
  const param = useLocation();
  const api = new Kyc_API();
  const panFileRef = useRef(null);
  const addFrontRef = useRef(null);
  const addBackRef = useRef(null);
  const addressTypeArray = Object.values(config.documents.address.types);
  const [panLoader, setPanLoader] = useState(false);
  const [addFrontLoader, setAddFrontLoader] = useState(false);
  const [ckyc, setCkyc] = useState(false);
  const navigate = useHistory();
  const [addBackLoader, setAddBackLoader] = useState(false);
  const [document, setDocument] = useState({
    ckyc: ckyc,
    document_pan_number:
      props.data.document_pan_number === undefined
        ? ""
        : props.data.document_pan_number,
    full_address:
      props.data.full_address === undefined
        ? ""
        : props.data.full_address,
    document_pan_image_data: {
      base64: null,
      size: null,
      content_type: null,
    },
    document_pan_image:
      props.data.document_pan_image === undefined
        ? ""
        : props.data.document_pan_image,
    document_address_front_image:
      props.data.document_address_front_image === undefined
        ? ""
        : props.data.document_address_front_image,
    document_address_front_image_data: {
      base64: null,
      size: null,
      content_type: null,
    },
    document_address_back_image:
      props.data.document_address_back_image === undefined
        ? ""
        : props.data.document_address_back_image,
    document_address_back_image_data: {
      base64: null,
      size: null,
      content_type: null,
    },
    document_address_type:
      props.data.document_address_type == undefined
        ? 0
        : props.data.document_address_type,
    document_address_number:
      props.data.document_address_number == undefined
        ? ""
        : props.data.document_address_number,
    document_pan_name:
      props.data.document_pan_name == undefined
        ? ""
        : props.data.document_pan_name,
    document_data:
      props.data.document_data == undefined ? {} : props.data.document_data,
    ckyc_data: props.data.ckyc_data == undefined ? {} : props.data.ckyc_data,
    pan_resp: props.data.pan_resp == undefined ? {} : props.data.pan_resp,
    verified_aadhaar:
      props.verified_aadhaar == undefined ? 0 : props.verified_aadhaar,
    ckyc_number: props.ckyc_number == undefined ? "" : props.ckyc_number,
  });
  const [panValidateFailed, setPanValidateFailed] = useState(false);
  const [panAddressFailed, setPanAddressFailed] = useState(false);
  const [addressFailed, setAddressFailed] = useState(false);
  const [addressLoader, setAddressLoader] = useState(false);
  const [showOtpModel, setShowOtpModel] = useState(false);
  const [reqId, setReqId] = useState("");
  const [otp, setOtp] = useState("");
  const [otpLoading, setOtpLoading] = useState(false);
  const [hideButton, setHideButton] = useState(
    param?.state?.uuid == "" ? 0 : param.pathname.startsWith("/ifa") ? 0 : 2
  );
  const [dob, setDob] = useState("");
  const [hidePanButton, setHidePanButton] = useState(
    param?.state?.uuid == "" ? 0 : param.pathname.startsWith("/ifa") ? 0 : 2
  );
  const [panCtaLoader, setPanCtaLoader] = useState(false);
  const [showErr, setShowErr] = useState(0);
  const [count, setCount] = useState(0);
  const [countPan, setCountPan] = useState(2);
  const [showAadharAttemptLeft, setshowAadharAttemptLeft] = useState(true);
  const [showPanImageAttemptLeft, setshowPanImageAttemptLeft] = useState(true);
  const [showAadharFrontAttemptLeft, setshowAadharFrontAttemptLeft] =
    useState(true);
  const [showAadharBackAttemptLeft, setshowAadharBackAttemptLeft] =
    useState(true);
  const [countAddressFront, setCountAddressFront] = useState(2);
  const [countAddressBack, setCountAddressBack] = useState(2);
  const [countAddressFrontFour, setCountAddressFrontFour] = useState(3);
  const [countAddressBackFour, setCountAddressBackFour] = useState(3);
  const [fullAddress, setFullAddress] = useState(
    props.data.document_data == undefined
      ? ""
      : props.data.document_data.address == undefined
      ? ""
      : props.data.document_data.address
  );
  const [fatherName, setFatherName] = useState(
    props.data.document_data == undefined
      ? ""
      : props.data.document_data.father_name == undefined
      ? ""
      : props.data.document_data.father_name
  );
  const [resendLoader, setResendLoader] = useState(false);
  const [startTimer, setStartTimer] = useState(false);
  const [countAdharCheck, setCountAdharCheck] = useState(3);
  const [countAadhaarOTP, setCountAadhaarOTP] = useState(2);
  // send document value
  useEffect(() => {
    if (props.dob || props.dob == "") {
      setDob(props.dob);
      setShowErr(0);
    }
    if (hidePanButton == 1) {
      props.onDisableNameDob(true);
    }

    if (panValidateFailed) {
      props.panValidateFailed(true);
    }

    if (addressFailed) {
      props.addressFailed(true);
    }
    props.value(document);
  }, [
    props,
    document,
    dob,
    hidePanButton,
    panValidateFailed,
    panAddressFailed,
  ]);

  // handle changes into input
  const onHandleChange = async (e, label) => {
    // pan number

    let ext;
    if (label === "document_pan_number") {
      props.setPan(e.target.value);
      setDocument({
        ...document,
        document_pan_number: e.target.value,
        document_pan_image: "",
        document_pan_image_data: {
          base64: null,
          size: null,
          content_type: null,
        },
      });
    }
    // pan image
    if (label === "document_pan_image" && e.target.files[0]) {
      ext = await getFileExtension(e);
      if (e.target.files[0].size > imageSizeLimit * 1024 * 1024) {
        errorToast(
          `PAN image size should not be more than ${imageSizeLimit} MB`
        );
      } else if (ext != "jpeg" && ext != "png" && ext != "jpg") {
        props.error["document_pan_image"] = "Invalid image format";
        errorToast(`Please upload a valid format for the pan image.`);
        setDocument({
          ...document,
          document_pan_image: "",
          document_pan_image_data: {
            base64: null,
            size: null,
            content_type: null,
          },
        });
      } else {
        setPanLoader(true);
        let compressedImage = await imageCompressor(e.target.files[0]);
        let uuid = await getUniqueUUID();
        let ext = await getFileExtension(e);
        let fileName = `${uuid}.${ext}`;
        let reader = new FileReader(compressedImage);
        reader.readAsDataURL(compressedImage);
        reader.onload = function (evt) {
          ocrImageCheck("pan", "pan_image", evt, fileName, e, compressedImage);
        };
        reader.onerror = function (error) {
          console.log("Error while uploading PAN image : ", error);
          setTimeout(() => {
            errorToast(`PAN image could not upload, Try again`);
            setPanLoader(false);
          }, 2000);
        };
        if (props.error.hasOwnProperty("document_pan_image")) {
          props.error["document_pan_image"] = "";
        }
      }
    }

    console.log("Label", label);
    // address type
    if (label === "document_address_type") {
      let value = Object.keys(config.documents.address.types).find(
        (key) => config.documents.address.types[key] === e.target.value
      );
      if (value == 4) {
        props.setAadhaarValidated(false);
      } else {
        props.setAadhaarValidated(true);
      }
      setHideButton(0);
      setDocument({
        ...document,
        document_address_type: parseInt(value),
        document_address_number: "",
        document_address_front_image: "",
        document_address_front_image_data: {
          base64: null,
          size: null,
          content_type: null,
        },
        document_address_back_image: "",
        document_address_back_image_data: {
          base64: null,
          size: null,
          content_type: null,
        },
      });
      setFatherName("");
      setFullAddress("");
    }
    // address number
    if (label === "document_address_number") {
      if (e.target.value.length == 0) {
        setDocument({
          ...document,
          document_address_number: "address_number",
        });
      } else {
        setDocument({
          ...document,
          document_address_number: e.target.value,
        });
      }
      if (document.document_address_type == 4) {
        setAddressFailed(false);
        setHideButton(0);
        setFatherName("");
        setFullAddress("");
      }
      if (props.error.hasOwnProperty("document_address_number")) {
        props.error["document_address_number"] = "";
      }
    }
    // address front image
    if (label === "document_address_front_image" && e.target.files[0]) {
      ext = await getFileExtension(e);
      if (e.target.files[0].size > imageSizeLimit * 1024 * 1024) {
        errorToast(
          `Address front image size should not be more than ${imageSizeLimit} MB`
        );
      } else if (ext != "jpeg" && ext != "png" && ext != "jpg") {
        props.error["document_address_front_image"] = "Invalid image format";
        errorToast(`Please upload a valid format for the address front image.`);
        setDocument({
          ...document,
          document_address_front_image: "",
          document_address_front_image_data: {
            base64: null,
            size: null,
            content_type: null,
          },
        });
      } else {
        let compressedImage = await imageCompressor(e.target.files[0]);
        setAddFrontLoader(true);
        let uuid = await getUniqueUUID();
        let ext = await getFileExtension(e);
        let fileName = `${uuid}.${ext}`;
        let reader = new FileReader(compressedImage);
        reader.readAsDataURL(compressedImage);
        reader.onload = function (evt) {
          ocrImageCheck(
            "address_front",
            config.documents.address.types[document.document_address_type] +
              " Front",
            evt,
            fileName,
            e,
            compressedImage
          );
        };
        reader.onerror = function (error) {
          console.log("Error while uploading Address front image : ", error);
          setTimeout(() => {
            errorToast(`Address front image could not upload, Try again`);
            setAddFrontLoader(false);
          }, 2000);
        };
        if (props.error.hasOwnProperty("document_address_front_image")) {
          props.error["document_address_front_image"] = "";
        }
      }
    }
    // address back image
    if (label === "document_address_back_image" && e.target.files[0]) {
      console.log("back iamge >>>", e.target.files[0]);
      ext = await getFileExtension(e);
      if (e.target.files[0].size > imageSizeLimit * 1024 * 1024) {
        errorToast(
          `Address back image size should not be more than ${imageSizeLimit} MB`
        );
      } else if (ext != "jpeg" && ext != "png" && ext != "jpg") {
        props.error["document_address_back_image"] = "Invalid image format";
        errorToast(`Please upload a valid format for the address back image.`);
        setDocument({
          ...document,
          document_address_back_image: "",
          document_address_back_image_data: {
            base64: null,
            size: null,
            content_type: null,
          },
        });
      } else {
        setAddBackLoader(true);
        let compressedImage = await imageCompressor(e.target.files[0]);
        let uuid = await getUniqueUUID();
        let ext = await getFileExtension(e);
        let fileName = `${uuid}.${ext}`;
        let reader = new FileReader(compressedImage);
        reader.readAsDataURL(compressedImage);
        reader.onload = function (evt) {
          ocrImageCheck(
            "address_back",
            config.documents.address.types[document.document_address_type] +
              " Back",
            evt,
            fileName,
            e,
            compressedImage
          );
        };
        reader.onerror = function (error) {
          console.log("Error while uploading Address back image : ", error);
          setTimeout(() => {
            errorToast(`Address back image could not upload, Try again`);
            setAddBackLoader(false);
          }, 2000);
        };

        if (props.error.hasOwnProperty("document_address_back_image")) {
          props.error["document_address_back_image"] = "";
        }
      }
    }
  };

  console.log(document);

  // prevent only digit and alphabet
  const onKeyPress = (e) => {
    const re = /[ `!@#$%^&*()_+\-={};':"|,.<>?~]/g;
    if (re.test(e.key)) {
      e.preventDefault();
    }
  };

  // prevent key press only numbers
  const onKeyPressNumber = (e) => {
    const re = /[^0-9]/g;
    if (re.test(e.key)) {
      e.preventDefault();
    }
  };

  // check dob 18 year
  const checkDob = async (dob) => {
    const dateParts = dob.split("-");
    const d = dateParts[2];
    const m = dateParts[1];
    const y = dateParts[0];
    var today = new Date();
    if (
      parseInt(d) > 31 ||
      parseInt(m) > 12 ||
      parseInt(y) > today.getFullYear()
    ) {
      return false;
    } else {
      var birthDate = new Date(dob);
      var age = today.getFullYear() - birthDate.getFullYear();
      var mon = today.getMonth() - birthDate.getMonth();
      if (mon < 0 || (mon === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      if (age < 18) {
        return false;
      }
      return true;
    }
  };

  // validate pan number
  const onPanCheck = async () => {
    var pan_format = /(^([a-zA-Z]{5})([0-9]{4})([a-zA-Z]{1})$)/;
    const user = await Auth.currentCredentials();

    // if dob is not available
    if (dob == "") {
      setPanCtaLoader(false);
      setPanValidateFailed(false);
      infoToast("Date of Birth is required in Profile");
    }

    // if all good
    else {
      const pan =
        props.pan.length == 10 ? props.pan : document.document_pan_number;

      // validate pan format
      if (pan_format.test(String(pan))) {
        const checkDate = await checkDob(dob);

        if (checkDate) {
          var dateParts = dob.split("-");
          var actual_dob = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;

          let is_authorised_person = false;
          if (props.investorType && props.investorType != "Individual") {
            is_authorised_person = true;
          }
          // payload
          let payload = {
            pan: String(pan).toUpperCase(),
            dob: actual_dob,
            mode: "actual",
            is_authorised_person: is_authorised_person,
            loan_app_id: props.referral ? props.referral : user.identityId,
          };

          // call the pan check api
          await api.checkPanNumber(payload).then((res) => {
            if (res.kind == "ok") {
              // let ckyc_data=res.hasOwnProperty("ckyc_json")?res.data.ckyc_json:null;
              // delete res.data.ckyc_json;
              let data = res.data;
              if (data.document_data == undefined) {
                setTimeout(() => {
                  props.setPanVerified(true);
                  setPanCtaLoader(false);
                  props.setCkycVerifiedFailed(true);
                  setPanAddressFailed(true);
                  setDocument({
                    ...document,
                    document_pan_name: data?.pan_name,
                    document_pan_number: pan,
                    ckyc: false,
                    pan_resp: data?.pan_res,
                  });
                  props.setPan(pan);
                  successToast(data?.msg);
                  setHidePanButton(1);
                }, 2000);
              } else {
                setTimeout(() => {
                  props.setPanVerified(true);
                  props.setCkycVerifiedFailed(false);
                  setPanCtaLoader(false);
                  setDocument({
                    ...document,
                    document_pan_name: data?.pan_name,
                    document_pan_number: pan,
                    document_data: data?.document_data,
                    ckyc: true,
                    ckyc_data: data?.ckyc_data,
                    ckyc_number: data?.ckyc_number,
                    pan_resp: data?.pan_res,
                    full_address : data?.address
                  });
                  setFullAddress(data?.address);
                  setFatherName(data?.father_name);
                  successToast(data?.msg);
                  props.setPan(pan);
                  setHidePanButton(1);
                }, 2000);
              }
            } else {
              if (res.error == "PAN already exist in the system") {
                setTimeout(() => {
                  setPanCtaLoader(false);
                  infoToast("This PAN has already been registered with us");
                }, 2000);
              } else {
                setTimeout(() => {
                  props.setPanVerified(true);
                  setPanCtaLoader(false);
                  props.setCkycVerifiedFailed(true);
                  setPanValidateFailed(true);
                  infoToast(res.error);
                  setHidePanButton(1);
                }, 2000);
              }
            }
          });
        } else {
          setPanCtaLoader(false);
          setPanValidateFailed(false);
          errorToast("Invalid, age must be at least 18 years");
        }
      } else {
        setPanCtaLoader(false);
        setPanValidateFailed(false);
        errorToast("Invalid Pan Number");
      }
    }
  };

  // validate aadhar card number
  const validateAadhaarCard = async () => {
    setAddressLoader(true);
    const user = await Auth.currentCredentials();

    // if aadhar number is invalid
    if (
      document.document_address_type == 4 &&
      document.document_address_number.length != 12
    ) {
      setTimeout(() => {
        setAddressLoader(false);
        infoToast("Aadhaar Card number is invalid.");
      }, 2000);
    }

    // if no error found
    else {
      // payload
      let payload = {
        aadhaar_no: document.document_address_number,
        loan_app_id: props.referral ? props.referral : user.identityId,
      };
      if (countAdharCheck == 0) {
        errorToast("number of trials exhausted !");
        setTimeout(() => {
          navigate.go(0);
        }, 2000);
      } else {
        setCountAdharCheck(countAdharCheck - 1);

        // call the api
        await api.validateAadhaarCard(payload).then((res) => {
          if (res.kind == "ok") {
            setshowAadharAttemptLeft(false);
            let data = res.data;
            setTimeout(() => {
              props.setAadhaarValidated(true);
              setHideButton(1);
              setShowOtpModel(true);
              setAddressLoader(false);
              setAddressFailed(false);
              setReqId(data?.reqId);
              successToast(data?.msg);
            }, 2000);
          } else {
            setshowAadharAttemptLeft(true);
            setTimeout(() => {
              props.setAadhaarValidated(true);
              setHideButton(1);
              setAddressLoader(false);
              setAddressFailed(true);
              infoToast(res.error);
            }, 2000);
          }
        });
      }
    }
  };

  // verify aadhar card number
  const verifyAadhaarCard = async () => {
    setOtpLoading(true);
    const user = await Auth.currentCredentials();

    if (!isNaN(parseFloat(otp))) {
      // if otp empty
      if (!otp || otp == "") {
        setTimeout(() => {
          setOtpLoading(false);
          errorToast("OTP is required.");
        }, 2000);
      }

      // if no error found
      else {
        // payload
        let payload = {
          request_id: reqId,
          aadhaar_no: document.document_address_number,
          otp: parseInt(otp),
          loan_app_id: props.referral ? props.referral : user.identityId,
        };

        setCountAadhaarOTP(countAadhaarOTP - 1);
        // call the api
        await api.verifyAadhaarCard(payload).then(async (res) => {
          if (res.kind == "ok") {
            let data = res.data;
            let match = false;
            match = await nameMatch(
              document.document_pan_name,
              data.document_data.name
            );
            if (match) {
              setTimeout(() => {
                setShowOtpModel(false);
                setOtpLoading(false);
                setAddressFailed(false);
                successToast(data?.msg);
                setDocument({
                  ...document,
                  document_data: data?.document_data.dataFromAadhaar,
                  verified_aadhaar: 1,
                  full_address : data?.address
                });
                setFullAddress(data?.address);
                setFatherName(data?.father_name);
              }, 2000);
            } else {
              setTimeout(() => {
                setOtpLoading(false);
                setAddressFailed(true);
                setShowOtpModel(false);
                // setshowAadharFrontAttemptLeft(false);
              }, 2000);
            }
          } else {
            if (countAadhaarOTP == 0) {
              setTimeout(() => {
                setOtpLoading(false);
                setAddressFailed(true);
                setShowOtpModel(false);
                infoToast(res.error);
              }, 2000);
            } else {
              setTimeout(() => {
                setOtpLoading(false);
                errorToast("Invalid OTP");
                setOtp("");
              }, 2000);
            }
          }
        });
      }
    } else {
      setTimeout(() => {
        setOtpLoading(false);
        errorToast("Invalid OTP.");
      }, 2000);
    }
  };

  // name matching
  const nameMatch = async (pan_name, other_name) => {
    //const user = await Auth.currentCredentials();

    console.log({
      pan_name,
      other_name,
    });

    if (!pan_name || !other_name) return true;

    // payload
    let payload = {
      pan_name: pan_name,
      other_name: other_name,
    };

    return await api.nameMatchApi(payload).then((res) => {
      if (res.kind == "ok") {
        if (res.data.document_data) {
          // successToast(res.data.msg);
          return true;
        } else {
          setshowAadharFrontAttemptLeft(true);
          console.log(addFrontLoader, "adhaar front loader");
          errorToast("Please upload the documents of the same person.");
          return false;
        }
      } else {
        setshowAadharFrontAttemptLeft(true);
        errorToast("Please upload the documents of the same person.");
        return false;
      }
    });
  };

  // verify image with ocr
  const ocrImageCheck = async (
    source,
    type,
    evt,
    fileName,
    e,
    compressedImage
  ) => {
    const user = await Auth.currentCredentials();

    // payload
    let payload = {
      field_type: type,
      base64: evt.target.result,
      loan_app_id: props.referral ? props.referral : user.identityId,
    };

    // call the api
    // if (countPan==0||countAddressFront==0||countAddressBack==0||countAddressFrontFour==0||countAddressBackFour==0){
    //   errorToast("Number of retrials exceeded !");
    //             setTimeout(() => {
    //               // setFile(false);
    //               // setDisable(false);
    //               // localStorage.clear();
    //               navigate.go(0);

    //             }, 2000);
    // } else{
    await api.ocrCheckApi(payload).then(async (res) => {
      if (res.kind == "ok") {
        const data = res.data;

        if (source == "pan") {
          if (countPan == 0) {
            errorToast("Number of retrials exhausted");
            setTimeout(() => {
              // setFile(false);
              // setDisable(false);
              // localStorage.clear();
              // localStorage.setItem("signup",false);
              // props.show("Login");
              // localStorage.setItem("signup",true);
              // props.show("Signup");

              // navigate.goBack();
              // navigate.push("/kyc");
              navigate.go(0);
              // NavigateBefore.apply();

              // navigate.
              // NavigateBefore
            }, 2000);
          } else {
            setshowPanImageAttemptLeft(false);
            setCountPan(countPan - 1);
            let match = false;
            match = await nameMatch(
              data.document_data.name,
              document.document_data.name
            );
            if (match) {
              setTimeout(() => {
                setDocument({
                  ...document,
                  document_pan_name: data.document_data.name,
                  document_pan_image: fileName,
                  document_pan_image_data: {
                    base64: evt.target.result,
                    size: compressedImage.size,
                    content_type: compressedImage.type,
                  },
                });
                setPanLoader(false);
              }, 2000);
            } else {
              setDocument({
                ...document,
                document_pan_name: data.document_data.name,
                document_pan_image: fileName,
                document_pan_image_data: {
                  base64: evt.target.result,
                  size: compressedImage.size,
                  content_type: compressedImage.type,
                },
              });
            }
            if (props.error.hasOwnProperty("document_pan_image")) {
              props.error["document_pan_image"] = "";
            }
          }
        }
        if (source == "address_front" && document.document_address_type != 4) {
          if (countAddressFront == 0) {
            errorToast("number of retrials exhausted");
            //fresh start
            setTimeout(() => {
              // setFile(false);
              // setDisable(false);
              // localStorage.clear();
              // localStorage.setItem("signup",false);
              // props.show("Login");
              // localStorage.setItem("signup",true);
              // props.show("Signup");
              // navigate.push("/clients/kyc");
              // navigate.goBack();
              // navigate.push("/kyc");
              // navigate.goBack();
              navigate.go(0);
            }, 2000);
          } else {
            setshowAadharFrontAttemptLeft(false);
            setCountAddressFront(countAddressFront - 1);
            let match = false;
            match = await nameMatch(
              document.document_pan_name,
              data.document_data.name
            );
            if (match) {
              setTimeout(() => {
                setDocument({
                  ...document,
                  document_address_number: data.document_data.address_number,
                  document_data: data.document_data,
                  document_address_front_image: fileName,
                  document_address_front_image_data: {
                    base64: evt.target.result,
                    size: compressedImage.size,
                    content_type: compressedImage.type,
                  },
                });

                setFullAddress(
                  data.document_data.address !== ""
                    ? data.document_data.address
                    : ""
                );
                setFatherName(
                  data.document_data.father_name !== ""
                    ? data.document_data.father_name
                    : ""
                );
                setAddFrontLoader(false);
              }, 2000);
            } else {
              setDocument({
                ...document,
                document_address_front_image: "",
              });
              setAddFrontLoader(false);
            }
          }
        }
        if (source == "address_front" && document.document_address_type == 4) {
          if (countAddressFrontFour == 0) {
            errorToast("Number of retrials exhausted");
            setTimeout(() => {
              // setFile(false);
              // setDisable(false);
              // localStorage.clear();
              // localStorage.setItem("signup",false);
              // props.show("Login");
              // localStorage.setItem("signup",true);
              // props.show("Signup");
              // navigate.push("/clients/kyc");
              // navigate.goBack();
              // navigate.push("/kyc");
              // navigate.goBack();
              navigate.go(0);
            }, 2000);
          } else {
            setshowAadharFrontAttemptLeft(false);
            setCountAddressFrontFour(countAddressFrontFour - 1);

            let match = await nameMatch(
              document.document_pan_name,
              data.document_data.name
            );
            console.log(match);
            setTimeout(() => {
              if (match) {
                setDocument({
                  ...document,
                  // document_data: data.document_data,
                  document_data: {
                    ...document.document_data,
                    name: data.document_data.name,
                    aadhaar_number: data.document_data.aadhaar_number,
                  },
                  document_address_front_image: fileName,
                  document_address_front_image_data: {
                    base64: evt.target.result,
                    size: compressedImage.size,
                    content_type: compressedImage.type,
                  },
                });
              } else {
                setDocument({
                  ...document,
                  document_address_front_image: "",
                });
              }
              setAddFrontLoader(false);
            }, 2000);
          }
        }
        if (source == "address_back" && document.document_address_type == 4) {
          if (countAddressBackFour == 0) {
            errorToast("Number of retrials exhausted");
            setTimeout(() => {
              // setFile(false);
              // setDisable(false);
              // navigate.push("/clients/kyc");
              // navigate.goBack();
              // navigate.push("/kyc");
              // navigate.goBack();
              navigate.go(0);
              // localStorage.clear();
              // localStorage.setItem("signup",false);
              // props.show("Login");
              // localStorage.setItem("signup",true);
              // props.show("Signup");
            }, 2000);
          } else {
            setshowAadharBackAttemptLeft(false);
            setCountAddressBackFour(countAddressBackFour - 1);
            setTimeout(() => {
              setDocument({
                ...document,
                // document_data: data.document_data,
                document_data: {
                  ...document.document_data,
                  father_name: data.document_data.father_name,
                  address: data.document_data.address,
                },
                document_address_back_image: fileName,
                document_address_back_image_data: {
                  base64: evt.target.result,
                  size: compressedImage.size,
                  content_type: compressedImage.type,
                },
                full_address : data?.document_data?.address
              });
              setFullAddress(
                data.document_data.address !== ""
                  ? data.document_data.address
                  : ""
              );
              setFatherName(
                data.document_data.father_name !== ""
                  ? data.document_data.father_name
                  : ""
              );
              setAddBackLoader(false);
            }, 2000);
          }
        }
        if (source == "address_back" && document.document_address_type != 4) {
          if (countAddressBack == 0) {
            errorToast("Number of retrials exhausted");
            setTimeout(() => {
              // setFile(false);
              // setDisable(false);
              // navigate.push("/clients/kyc");
              // navigate.goBack();
              // navigate.push("/kyc");
              // navigate.goBack();
              navigate.go(0);
              // localStorage.clear();
              // localStorage.setItem("signup",false);
              // props.show("Login");
              // localStorage.setItem("signup",true);
              // props.show("Signup");
            }, 2000);
          } else {
            setshowAadharBackAttemptLeft(false);
            setCountAddressBack(countAddressBack - 1);
            setTimeout(() => {
              setDocument({
                ...document,
                document_data: data.document_data,
                document_address_back_image: fileName,
                document_address_back_image_data: {
                  base64: evt.target.result,
                  size: compressedImage.size,
                  content_type: compressedImage.type,
                },
                full_address : data?.document_data?.address
              });
              setFullAddress(
                data.document_data.address !== ""
                  ? data.document_data.address
                  : ""
              );
              setFatherName(
                data.document_data.father_name !== ""
                  ? data.document_data.father_name
                  : ""
              );
              setAddBackLoader(false);
            }, 2000);
          }
        }
      } else {
        setTimeout(() => {
          errorToast(res.error);
          setPanLoader(false);
          setAddFrontLoader(false);
          setAddBackLoader(false);
          if (source == "pan") {
            if (countPan <= 1) {
              errorToast("Number of retrials exhausted ");
              setTimeout(() => {
                navigate.go(0);
              }, 2000);
            } else {
              setCountPan(countPan - 1);
              // errorToast(res.error);
            }
          } else if (
            source == "address_back" &&
            document.document_address_type == 4
          ) {
            if (countAddressBackFour <= 1) {
              errorToast("Number of retrials exhausted");
              setTimeout(() => {
                navigate.go(0);
              }, 2000);
            } else {
              setCountAddressBackFour(countAddressBackFour - 1);
              // errorToast(res.error);
            }
          } else if (
            source == "address_back" &&
            document.document_address_type != 4
          ) {
            if (countAddressBack <= 1) {
              errorToast("Number of retrials exhausted");
              setTimeout(() => {
                navigate.go(0);
              }, 2000);
            } else {
              setCountAddressBack(countAddressBack - 1);
              // errorToast(res.error);
            }
          } else if (
            source == "address_front" &&
            document.document_address_type == 4
          ) {
            if (countAddressFrontFour <= 1) {
              errorToast("Number of retrials exhausted");
              setTimeout(() => {
                navigate.go(0);
              }, 2000);
            } else {
              setCountAddressFrontFour(countAddressFrontFour - 1);
              // errorToast(res.error);
            }
          } else {
            if (countAddressFront <= 1) {
              errorToast("Number of retrials exhausted");
              setTimeout(() => {
                navigate.go(0);
              }, 2000);
            } else {
              setCountAddressFront(countAddressFront - 1);
              // errorToast(res.error);
            }
          }
        }, 2000);
      }
    });
    //}
  };

  // resend otp
  const resendOTP = async () => {
    setResendLoader(true);
    const user = await Auth.currentCredentials();

    // payload
    let payload = {
      aadhaar_no: document.document_address_number,
      loan_app_id: props.referral ? props.referral : user.identityId,
    };

    // call the api
    await api.validateAadhaarCard(payload).then((res) => {
      if (res.kind == "ok") {
        let data = res.data;
        setTimeout(() => {
          setResendLoader(false);
          setReqId(data?.reqId);
          successToast(data?.msg);
          setStartTimer(true);
        }, 2000);
      } else {
        setTimeout(() => {
          setResendLoader(false);
          setShowOtpModel(false);
          setAddressFailed(true);
          errorToast(res.error);
        }, 2000);
      }
    });
  };

  return (
    <div>
      {/* pan number */}
      <div className="clientkyc_main_input_form" style={{ marginTop: "20px" }}>
        <label className="clientkyc_main_input_label">
          PAN<span style={{ color: "red" }}> *</span>
        </label>
        <input
          type="text"
          disabled={
            props.investorType
              ? props.disable || hidePanButton == 1
              : props.disable || hidePanButton == 1 || props.pan.length == 10
          }
          maxLength={10}
          value={
            props.pan.length == 10 ? props.pan : document.document_pan_number
          }
          className="clientkyc_main_input_field"
          style={{
            borderColor: props.error.hasOwnProperty("document_pan_number")
              ? props.error["document_pan_number"].length > 0
                ? "#d43939"
                : undefined
              : undefined,
            textTransform: "uppercase",
          }}
          onChange={(event) => {
            // if dob are not available
            if (dob == "") {
              if (showErr == 0) {
                let msg =
                  dob == "" ? "Date of Birth is required in Profile." : "";
                errorToast(msg);
                setShowErr(1);
                setTimeout(() => {
                  setCount(0);
                }, 5000);
              }
            }
            // if all good
            else {
              setPanValidateFailed(false);
              setHidePanButton(0);
              onHandleChange(event, "document_pan_number");
            }
          }}
          onKeyPress={onKeyPress}
        />
        {props.error.hasOwnProperty("document_pan_number") ? (
          <ErrorValue text={props.error["document_pan_number"]} />
        ) : (
          <div />
        )}
      </div>

      {/* validate pan number cta */}
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-end",
        }}
      >
        <button
          onClick={() => {
            if (!(panCtaLoader || dob == "")) {
              setPanCtaLoader(true);
              setTimeout(() => {
                onPanCheck();
              }, 2000);
            }
          }}
          className={
            dob == ""
              ? "clientkyc_ckyc_button_pan_disable"
              : "clientkyc_ckyc_button_pan_enable"
          }
          style={{
            display:
              hidePanButton == 0
                ? hidePanButton == 0 &&
                  (document.document_pan_number.length == 10 ||
                    props.pan.length == 10) &&
                  !props.disable
                  ? "flex"
                  : "none"
                : "none",
          }}
          onMouseEnter={() => {
            if (dob == "") {
              if (count == 0) {
                let msg =
                  dob == "" ? "Date of Birth is required in Profile." : "";
                errorToast(msg);
                setCount(1);
                setTimeout(() => {
                  setCount(0);
                }, 5000);
              }
            }
          }}
        >
          {panCtaLoader ? (
            <Loader type="TailSpin" color="#fff" height={15} width={15} />
          ) : (
            "Fetch KYC Details"
          )}
        </button>
      </div>

      {/* pan image */}
      <div
        className="clientkyc_main_input_form"
        style={{
          display:
            panValidateFailed ||
            (props.disable && document.document_pan_image != "")
              ? "flex"
              : "none",
        }}
      >
        <label className="clientkyc_main_input_label">
          PAN Image<span style={{ color: "red" }}> *</span>
        </label>
        <div
          className="clientkyc_main_image_div_container"
          style={{
            borderColor: props.error.hasOwnProperty("document_pan_image")
              ? props.error["document_pan_image"].length > 0
                ? "#d43939"
                : undefined
              : undefined,
          }}
        >
          <div
            className="clientkyc_main_image_div"
            style={{
              backgroundColor: props.disable === true ? "#ececec" : undefined,
              color: props.disable === true ? "rgb(119, 119, 119)" : undefined,
            }}
          >
            <p className="clientkyc_main_image_text">
              {document.document_pan_image}
            </p>
          </div>
          {props.disable === true ? (
            <div />
          ) : (
            <button
              onClick={() => {
                panFileRef.current.value = "";
                panFileRef.current.click();
              }}
              className="clientkyc_main_image_button"
              style={{
                cursor: panLoader ? "not-allowed" : "pointer",
                backgroundColor: !showPanImageAttemptLeft
                  ? "#a9a9a9"
                  : "#3a86ff",
              }}
              disabled={panLoader || !showPanImageAttemptLeft}
            >
              {panLoader ? (
                <Loader type="TailSpin" color="#fff" height={18} width={18} />
              ) : document.document_pan_image === "" ||
                document.document_pan_image === null ? (
                "Upload"
              ) : (
                "Change"
              )}
            </button>
          )}
          <input
            type="file"
            ref={panFileRef}
            style={{ display: "none" }}
            onChange={(event) => onHandleChange(event, "document_pan_image")}
          />
        </div>
        {/* info text */}
        {!props.disable && showPanImageAttemptLeft === true ? (
          <InputInfoText
            text={`Please upload the image in jpeg/png format. The maximum file size should not exceed ${imageSizeLimit} MB.`}
          />
        ) : (
          ""
        )}
        {countPan < 2 && showPanImageAttemptLeft === true ? (
          <InputInfoText
            text={`Attempts left: ${countPan}`}
            style={{ textAlign: "start" }}
          />
        ) : (
          ""
        )}
        {/* error text */}
        {props.error.hasOwnProperty("document_pan_image") ? (
          <ErrorValue text={props.error["document_pan_image"]} />
        ) : (
          <div />
        )}
      </div>

      {/* pan name */}
      <div
        className="clientkyc_main_input_form"
        // style={{ display: document.document_pan_name == "" ? "none" : "flex" }}
        style={{ display: "none" }}
      >
        <label className="clientkyc_main_input_label">
          Name<span style={{ color: "red" }}> *</span>
        </label>
        <input
          type="text"
          disabled={props.disable}
          value={
            document.document_pan_name == "pan_name"
              ? ""
              : document.document_pan_name
          }
          className="clientkyc_main_input_field"
          onChange={(e) => {
            if (e.target.value.length == 0) {
              setDocument({
                ...document,
                document_pan_name: "pan_name",
              });
            } else {
              setDocument({
                ...document,
                document_pan_name: e.target.value,
              });
            }
          }}
        />
      </div>

      {/* address Type */}
      <div
        className="clientkyc_main_input_form"
        style={{
          display:
            panValidateFailed || panAddressFailed
              ? "flex"
              : !panValidateFailed &&
                props.disable &&
                document.document_address_type != 0
              ? "flex"
              : !panValidateFailed &&
                !props.disable &&
                document.document_address_type != 0
              ? "flex"
              : "none",
        }}
      >
        <label className="clientkyc_main_input_label">
          Address Type<span style={{ color: "red" }}> *</span>
        </label>
        <TextField
          error={
            props.error.hasOwnProperty("document_address_type") &&
            props.error["document_address_type"].length > 0
              ? true
              : false
          }
          disabled={props.disable}
          select={!props.disable ? true : false}
          style={{
            ...inputBox,
            backgroundColor: props.disable ? "#ececec" : undefined,
            color: props.disable ? "rgb(119, 119, 119)" : undefined,
          }}
          InputProps={{
            style: inputStyle,
          }}
          value={config.documents.address.types[document.document_address_type]}
          onChange={(event) => {
            setAddressFailed(false);
            setAddressLoader(false);
            onHandleChange(event, "document_address_type");
          }}
          InputLabelProps={{ shrink: false }}
        >
          {addressTypeArray.map((item, index) => (
            <MenuItem key={index} style={menuItem} value={item}>
              {item}
            </MenuItem>
          ))}
        </TextField>
        {props.error.hasOwnProperty("document_address_type") ? (
          <ErrorValue text={props.error["document_address_type"]} />
        ) : (
          <div />
        )}
      </div>

      {/* address number */}
      <div
        className="clientkyc_main_input_form"
        style={{
          display:
            document.document_address_type == 4
              ? "flex"
              : document.document_address_number != ""
              ? "flex"
              : "none",
        }}
      >
        <label className="clientkyc_main_input_label">
          {config.documents.address.types[document.document_address_type]}{" "}
          Number
          <span style={{ color: "red" }}> *</span>
        </label>
        <input
          type="text"
          disabled={props.disable || countAdharCheck == 0}
          maxLength={document.document_address_type == 4 ? 12 : undefined}
          value={
            document.document_address_number == "address_number"
              ? ""
              : document.document_address_number
          }
          className="clientkyc_main_input_field"
          style={{
            borderColor: props.error.hasOwnProperty("document_address_number")
              ? props.error["document_address_number"].length > 0
                ? "#d43939"
                : undefined
              : undefined,
          }}
          onChange={(event) => onHandleChange(event, "document_address_number")}
          onKeyPress={(e) => {
            if (document.document_address_type == 4) {
              onKeyPressNumber(e);
            }
          }}
        />
        {props.error.hasOwnProperty("document_address_number") ? (
          <ErrorValue text={props.error["document_address_number"]} />
        ) : (
          <div />
        )}
      </div>
      {countAdharCheck < 3 && showAadharAttemptLeft === true ? (
        <InputInfoText text={`Attempts Left:${countAdharCheck}`} />
      ) : (
        ""
      )}

      {/* validate address number cta */}
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-end",
        }}
      >
        <button
          onClick={() => validateAadhaarCard()}
          className="clientkyc_ckyc_button"
          style={{
            display:
              hideButton == 0
                ? hideButton == 0 &&
                  document.document_address_number.length == 12 &&
                  document.document_address_type == 4 &&
                  !props.disable
                  ? "flex"
                  : "none"
                : "none",
          }}
          disabled={addressLoader}
        >
          {addressLoader ? (
            <Loader type="TailSpin" color="#fff" height={15} width={15} />
          ) : (
            "Verify Aadhaar Number"
          )}
        </button>
      </div>

      {/* address front image */}
      <div
        className="clientkyc_main_input_form"
        style={{
          display:
            !addressFailed &&
            document.document_address_type != 0 &&
            document.document_address_type != 4
              ? "flex"
              : addressFailed && document.document_address_type == 4
              ? "flex"
              : !addressFailed &&
                props.disable &&
                document.document_address_front_image != ""
              ? "flex"
              : !addressFailed && document.document_address_front_image != ""
              ? "flex"
              : "none",
        }}
      >
        <label className="clientkyc_main_input_label">
          {config.documents.address.types[document.document_address_type]} Front
          Image<span style={{ color: "red" }}> *</span>
        </label>
        <div
          className="clientkyc_main_image_div_container"
          style={{
            borderColor: props.error.hasOwnProperty(
              "document_address_front_image"
            )
              ? props.error["document_address_front_image"].length > 0
                ? "#d43939"
                : undefined
              : undefined,
          }}
        >
          <div
            className="clientkyc_main_image_div"
            style={{
              backgroundColor: props.disable === true ? "#ececec" : undefined,
              color: props.disable === true ? "rgb(119, 119, 119)" : undefined,
            }}
          >
            <p className="clientkyc_main_image_text">
              {document.document_address_front_image}
            </p>
          </div>
          {props.disable === true ? (
            <div />
          ) : (
            <button
              onClick={() => {
                addFrontRef.current.value = "";
                addFrontRef.current.click();
              }}
              className="clientkyc_main_image_button"
              style={{
                cursor: addFrontLoader ? "not-allowed" : "pointer",
                backgroundColor: !showAadharFrontAttemptLeft
                  ? "#a9a9a9"
                  : "#3a86ff",
              }}
              disabled={addFrontLoader || !showAadharFrontAttemptLeft}
            >
              {addFrontLoader ? (
                <Loader type="TailSpin" color="#fff" height={18} width={18} />
              ) : document.document_address_front_image === "" ||
                document.document_address_front_image === null ? (
                "Upload"
              ) : (
                "Change"
              )}
            </button>
          )}
          <input
            type="file"
            ref={addFrontRef}
            style={{ display: "none" }}
            onChange={(event) =>
              onHandleChange(event, "document_address_front_image")
            }
          />
        </div>
        {/* info text */}
        {!props.disable && showAadharFrontAttemptLeft === true ? (
          <InputInfoText
            text={`Please upload the image in jpeg/png format. The maximum file size should not exceed ${imageSizeLimit} MB.`}
          />
        ) : (
          ""
        )}
        {(countAddressFront < 2 &&
          document.document_address_type != 4 &&
          showAadharFrontAttemptLeft === true) ||
        (countAddressFrontFour < 3 &&
          document.document_address_type == 4 &&
          showAadharFrontAttemptLeft === true) ? (
          <InputInfoText
            text={`Attempts left: ${
              document.document_address_type != 4
                ? countAddressFront
                : countAddressFrontFour
            }`}
          />
        ) : (
          ""
        )}
        {/* error text */}
        {props.error.hasOwnProperty("document_address_front_image") ? (
          <ErrorValue text={props.error["document_address_front_image"]} />
        ) : (
          <div />
        )}
      </div>

      {/* address back image */}
      <div
        className="clientkyc_main_input_form"
        style={{
          display:
            !addressFailed &&
            document.document_address_type != 0 &&
            document.document_address_type != 4
              ? "flex"
              : addressFailed && document.document_address_type == 4
              ? "flex"
              : !addressFailed &&
                props.disable &&
                document.document_address_front_image != ""
              ? "flex"
              : !addressFailed && document.document_address_front_image != ""
              ? "flex"
              : "none",
        }}
      >
        <label className="clientkyc_main_input_label">
          {config.documents.address.types[document.document_address_type]} Back
          Image{" "}
          <span
            style={{
              color: "red",
              display: document.document_address_type != 3 ? "inline" : "none",
            }}
          >
            {" "}
            *
          </span>
        </label>

        <div
          className="clientkyc_main_image_div_container"
          style={{
            borderColor: props.error.hasOwnProperty(
              "document_address_back_image"
            )
              ? props.error["document_address_back_image"].length > 0
                ? "#d43939"
                : undefined
              : undefined,
          }}
        >
          <div
            className="clientkyc_main_image_div"
            style={{
              backgroundColor: props.disable === true ? "#ececec" : undefined,
              color: props.disable === true ? "rgb(119, 119, 119)" : undefined,
            }}
          >
            <p className="clientkyc_main_image_text">
              {document.document_address_back_image}
            </p>
          </div>
          {props.disable === true ? (
            <div />
          ) : (
            <button
              onClick={() => {
                addBackRef.current.value = "";
                addBackRef.current.click();
              }}
              className="clientkyc_main_image_button"
              style={{
                cursor: addBackLoader ? "not-allowed" : "pointer",
                backgroundColor: !showAadharBackAttemptLeft
                  ? "#a9a9a9"
                  : "#3a86ff",
              }}
              disabled={addBackLoader || !showAadharBackAttemptLeft}
            >
              {addBackLoader ? (
                <Loader type="TailSpin" color="#fff" height={18} width={18} />
              ) : document.document_address_back_image === "" ||
                document.document_address_back_image === null ? (
                "Upload"
              ) : (
                "Change"
              )}
            </button>
          )}
          <input
            type="file"
            ref={addBackRef}
            style={{ display: "none" }}
            onChange={(event) =>
              onHandleChange(event, "document_address_back_image")
            }
          />
        </div>
        {/* info text */}
        {!props.disable && showAadharBackAttemptLeft === true ? (
          <InputInfoText
            text={`Please upload the image in jpeg/png format. The maximum file size should not exceed ${imageSizeLimit} MB.`}
          />
        ) : (
          ""
        )}
        {(countAddressBack < 2 &&
          document.document_address_type != 4 &&
          showAadharBackAttemptLeft === true) ||
        (countAddressBackFour < 3 &&
          document.document_address_type == 4 &&
          showAadharBackAttemptLeft === true) ? (
          <InputInfoText
            text={`Attempts left: ${
              document.document_address_type != 4
                ? countAddressBack
                : countAddressBackFour
            }`}
          />
        ) : (
          ""
        )}

        {/* error text */ console.log(props.error)}
        {props.error.hasOwnProperty("document_address_back_image") ? (
          <ErrorValue text={props.error["document_address_back_image"]} />
        ) : (
          <div />
        )}
      </div>

      {/* father name */}
      <div
        className="clientkyc_main_input_form"
        // style={{ display: fatherName == "" ? "none" : "flex" }}
        style={{ display: "none" }}
      >
        <label className="clientkyc_main_input_label">
          Father Name<span style={{ color: "red" }}> *</span>
        </label>
        <input
          type="text"
          disabled={props.disable}
          value={fatherName == "father_name" ? "" : fatherName}
          className="clientkyc_main_input_field"
          onChange={(e) => {
            if (e.target.value.length == 0) {
              setFatherName("father_name");
              setDocument({
                ...document,
                document_data: {
                  ...document.document_data,
                  father_name: "father_name",
                },
              });
            } else {
              setFatherName(e.target.value);
              setDocument({
                ...document,
                document_data: {
                  ...document.document_data,
                  father_name: e.target.value,
                },
              });
            }
          }}
        />
      </div>

      {/* full address */}
      <div
        className="clientkyc_main_input_form"
        // style={{ display: fullAddress == "" ? "none" : "flex" }}
        style={{ display: "none" }}
      >
        <label className="clientkyc_main_input_label">
          Address<span style={{ color: "red" }}> *</span>
        </label>
        <input
          type="text"
          disabled={props.disable}
          value={fullAddress == "full_address" ? "" : fullAddress}
          className="clientkyc_main_input_field"
          onChange={(e) => {
            if (e.target.value.length == 0) {
              setFullAddress("full_address");
              setDocument({
                ...document,
                document_data: {
                  ...document.document_data,
                  address: "full_address",
                },
              });
            } else {
              setFullAddress(e.target.value);
              setDocument({
                ...document,
                document_data: {
                  ...document.document_data,
                  address: e.target.value,
                },
              });
            }
          }}
        />
      </div>

      {/* otp enter model */}
      {showOtpModel ? (
        <OtpEnterModel
          openModel={showOtpModel}
          setOtp={(value) => setOtp(value)}
          loading={otpLoading}
          uploadDocument={() => {
            setShowOtpModel(false);
            setAddressFailed(true);
          }}
          onSubmit={verifyAadhaarCard}
          onResendOtp={() => resendOTP()}
          resendLoader={resendLoader}
          startTimer={startTimer}
          setStartTimer={() => setStartTimer(false)}
          otp={otp}
        />
      ) : (
        <div />
      )}
    </div>
  );
};

export default ClientKycDocument;
