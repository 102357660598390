module.exports = {
  /**
   * account Creation State
   */
  accountCreationState: {
    gst_number: {
      defaultValue: null,
      required: false,
      type: "text",
    },
    email: {
      defaultValue: "",
      required: true,
      type: "email",
    },
    mobile: {
      defaultValue: null,
      required: true,
      type: "number",
    },
    firm_name: {
      defaultValue: "",
      required: true,
      type: "string",
    },
    name: {
      defaultValue: null,
      type: "string",
    },
  },

  /**
   * KYC Details State
   */

  kycDocumentsState: {
    selfie: {
      defaultValue: null,
      required: true,
      type: "object",
      associatedFirm: ["individual"],
    },
    pan_image:{
      defaultValue: null,
      required: false,
      type: "object",
      associatedFirm: ["individual",
      "sole_proprietorship",
      "huf",
      "trust",
      "partnership_firm",
      "private_limited_company",],
    },
    current_address: {
      defaultValue: null,
      required: true,
      type: "text",
      label: "Address",
      associatedFirm: ["individual"],
    },
    address_type: {
      defaultValue: null,
      required: false,
      type: "number",
      associatedFirm: ["individual"],
    },
    address_front_image: {
      defaultValue: null,
      required: false,
      type: "text",
      associatedFirm: ["individual"],
    },
    address_back_image: {
      defaultValue: null,
      required: false,
      type: "text",
      associatedFirm: ["individual"],
    },
    current_locality: {
      defaultValue: null,
      required: true,
      type: "text",
      associatedFirm: ["individual"],
    },
    current_city: {
      defaultValue: null,
      required: true,
      type: "text",
      associatedFirm: ["individual"],
    },
    current_state: {
      defaultValue: null,
      required: true,
      type: "text",
      associatedFirm: ["individual"],
    },
    current_pin_code: {
      defaultValue: null,
      required: true,
      type: "number",
      associatedFirm: ["individual"],
    },
    permanent_current_address_flag: {
      defaultValue: false,
      required: true,
      type: "boolean",
      associatedFirm: ["individual"],
    },
    permanent_address: {
      defaultValue: null,
      required: false,
      type: "text",
      label: "Address",
      associatedFirm: ["individual"],
    },
    permanent_locality: {
      defaultValue: null,
      required: false,
      type: "text",
      associatedFirm: ["individual"],
    },
    permanent_city: {
      defaultValue: null,
      required: false,
      type: "text",
      associatedFirm: ["individual"],
    },
    permanent_state: {
      defaultValue: null,
      required: false,
      type: "text",
      associatedFirm: ["individual"],
    },
    permanent_pin: {
      defaultValue: null,
      required: false,
      type: "number",
      associatedFirm: ["individual"],
    },
  },

  /**
   * Basic details for client kyc State
   */

  clientKycBasicDetail: {
    investor_type: {
      defaultValue: null,
      required: true,
      type: "text",
    },
    company_type: {
      defaultValue: "",
      required: true,
      type: "text",
      associatedFirm: ["private_limited_company", "public_limited_company"],
    },
    pan_name: {
      defaultValue: null,
      required: true,
      type: "text",
    },
    pan_number: {
      defaultValue: null,
      required: true,
      type: "text",
    },

    is_pan_consent_accepted: {
      defaultValue: false,
      required: true,
      type: "boolean",
    },
    email_id: {
      defaultValue: null,
      required: true,
      type: "email",
    },

    mobile_number: {
      defaultValue: null,
      required: true,
      type: "number",
    },

    dob: {
      defaultValue: null,
      required: true,
      type: "date",
    },

    gender: {
      defaultValue: null,
      required: true,
      type: "text",
    },
    name: {
      defaultValue: null,
      required: true,
      type: "text",
    },
    ckyc_number: {
      defaultValue: null,
      required: false,
      type: "text",
    },
    is_ckyc: {
      defaultValue: false,
      required: false,
      type: "boolean",
    },
  },

  /**
   * Bank details for add client
   */

  clientBankDetails: {
    ifsc_code: {
      defaultValue: null,
      required: true,
      type: "text",
    },
    account_number: {
      defaultValue: null,
      required: true,
      type: "number",
    },
    cancel_cheque: {
      defaultValue: null,
      required: true,
      type: "object",
    },

    is_bank_consent_accepted: {
      defaultValue: false,
      required: true,
      type: "boolean",
    },
  },

  /**
   * Org Document State
   */
  orgDocumentState: {
    entity_name: {
      defaultValue: null,
      required: true,
      type: "text",
      associatedFirm: ["sole_proprietorship"],
    },
    entity_document_1: {
      defaultValue: null,
      required: true,
      type: "object",
      associatedFirm: ["sole_proprietorship"],
    },

    entity_document_2: {
      defaultValue: null,
      required: true,
      type: "object",
      associatedFirm: ["sole_proprietorship"],
    },
    date_of_incorporation: {
      defaultValue: null,
      required: true,
      type: "date",
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
    },
    entity_registered_address: {
      defaultValue: null,
      required: true,
      type: "text",
      label: "Address",
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
    },
    entity_current_locality: {
      defaultValue: null,
      required: true,
      type: "text",
      label: "Locality",
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
    },
    entity_current_city: {
      defaultValue: null,
      required: true,
      type: "text",
      label: "City",
      specialCharCheck: true,
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
    },
    entity_current_state: {
      defaultValue: null,
      required: true,
      type: "text",
      label: "State",
      specialCharCheck: true,
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
    },
    entity_current_pin: {
      defaultValue: null,
      required: true,
      type: "number",
      label: "Pincode",
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
    },

    beneficial_owner_1_pan: {
      defaultValue: null,
      required: true,
      type: "object",
      label: "PAN",
      associatedFirm: ["trust", "partnership_firm", "private_limited_company"],
      note: "Upload image in JPG/JPEG/PNG/PDF format. Max. file size allowed is 15 MB.",
    },
    beneficial_owner_1_address_proof: {
      defaultValue: null,
      required: true,
      type: "object",
      label: "Address Proof",
      associatedFirm: ["trust", "partnership_firm", "private_limited_company"],
      note: "Upload image in JPG/JPEG/PNG/PDF format. Max. file size allowed is 15 MB.",
    },
    beneficial_owner_1_selfie: {
      defaultValue: null,
      required: true,
      type: "object",
      label: "Selfie",
      associatedFirm: ["trust", "partnership_firm", "private_limited_company"],
      note: "Ensure the client's face is clearly visible in the picture. (Upload image in JPG/JPEG/PNG format. Max. file size allowed is 15 MB).",
      requiredFileTypes: ["image/jpeg", "image/png", "image/jpg"],
      acceptedFormat: ".jpg, .jpeg, .png",
    },

    beneficial_owner_2_pan: {
      defaultValue: null,
      required: false,
      type: "object",
      label: "PAN",
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
      note: "Upload image in JPG/JPEG/PNG/PDF format. Max. file size allowed is 15 MB.",
    },
    beneficial_owner_2_address_proof: {
      defaultValue: null,
      required: false,
      type: "object",
      label: "Address Proof",
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
      note: "Upload image in JPG/JPEG/PNG/PDF format. Max. file size allowed is 15 MB.",
    },
    beneficial_owner_2_selfie: {
      defaultValue: null,
      required: false,
      type: "object",
      label: "Selfie",
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
      note: "Ensure the client's face is clearly visible in the picture. (Upload image in JPG/JPEG/PNG format. Max. file size allowed is 15 MB).",
      requiredFileTypes: ["image/jpeg", "image/png", "image/jpg"],
      acceptedFormat: ".jpg, .jpeg, .png",
    },

    beneficial_owner_3_pan: {
      defaultValue: null,
      required: false,
      type: "object",
      label: "PAN",
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
      note: "Upload image in JPG/JPEG/PNG/PDF format. Max. file size allowed is 15 MB.",
    },
    beneficial_owner_3_address_proof: {
      defaultValue: null,
      required: false,
      type: "object",
      label: "Address Proof",
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
      note: "Upload image in JPG/JPEG/PNG/PDF format. Max. file size allowed is 15 MB.",
    },
    beneficial_owner_3_selfie: {
      defaultValue: null,
      required: false,
      type: "object",
      label: "Selfie",
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
      note: "Ensure the client's face is clearly visible in the picture. (Upload image in JPG/JPEG/PNG format. Max. file size allowed is 15 MB).",
      requiredFileTypes: ["image/jpeg", "image/png", "image/jpg"],
      acceptedFormat: ".jpg, .jpeg, .png",
    },

    beneficial_owner_4_pan: {
      defaultValue: null,
      required: false,
      type: "object",
      label: "PAN",
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
      note: "Upload image in JPG/JPEG/PNG/PDF format. Max. file size allowed is 15 MB.",
    },
    beneficial_owner_4_address_proof: {
      defaultValue: null,
      required: false,
      type: "object",
      label: "Address Proof",
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
      note: "Upload image in JPG/JPEG/PNG/PDF format. Max. file size allowed is 15 MB.",
    },
    beneficial_owner_4_selfie: {
      defaultValue: null,
      required: false,
      type: "object",
      label: "Selfie",
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
      note: "Ensure the client's face is clearly visible in the picture. (Upload image in JPG/JPEG/PNG format. Max. file size allowed is 15 MB).",
      requiredFileTypes: ["image/jpeg", "image/png", "image/jpg"],
      acceptedFormat: ".jpg, .jpeg, .png",
    },

    beneficial_owner_5_pan: {
      defaultValue: null,
      required: false,
      type: "object",
      label: "PAN",
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
      note: "Upload image in JPG/JPEG/PNG/PDF format. Max. file size allowed is 15 MB.",
    },
    beneficial_owner_5_address_proof: {
      defaultValue: null,
      required: false,
      type: "object",
      label: "Address Proof",
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
      note: "Upload image in JPG/JPEG/PNG/PDF format. Max. file size allowed is 15 MB.",
    },
    beneficial_owner_5_selfie: {
      defaultValue: null,
      required: false,
      type: "object",
      label: "Selfie",
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
      note: "Ensure the client's face is clearly visible in the picture. (Upload image in JPG/JPEG/PNG format. Max. file size allowed is 15 MB).",
      requiredFileTypes: ["image/jpeg", "image/png", "image/jpg"],
      acceptedFormat: ".jpg, .jpeg, .png",
    },

    beneficial_owner_6_pan: {
      defaultValue: null,
      required: false,
      type: "object",
      label: "PAN",
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
      note: "Upload image in JPG/JPEG/PNG/PDF format. Max. file size allowed is 15 MB.",
    },
    beneficial_owner_6_address_proof: {
      defaultValue: null,
      required: false,
      type: "object",
      label: "Address Proof",
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
      note: "Upload image in JPG/JPEG/PNG/PDF format. Max. file size allowed is 15 MB.",
    },
    beneficial_owner_6_selfie: {
      defaultValue: null,
      required: false,
      type: "object",
      label: "Selfie",
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
      note: "Ensure the client's face is clearly visible in the picture. (Upload image in JPG/JPEG/PNG format. Max. file size allowed is 15 MB).",
      requiredFileTypes: ["image/jpeg", "image/png", "image/jpg"],
      acceptedFormat: ".jpg, .jpeg, .png",
    },

    entity_address_proof: {
      defaultValue: null,
      required: true,
      type: "object",
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
    },
    registration_certificate: {
      defaultValue: null,
      required: true,
      type: "object",
      label: "Registration Certificate",
      associatedFirm: ["trust", "partnership_firm"],
      template: null,
    },
    trust_deed: {
      defaultValue: null,
      required: true,
      type: "object",
      label: "Trust Deed",
      associatedFirm: ["trust"],
      template: null,
    },
    partnership_deed_and_resolution: {
      defaultValue: null,
      required: true,
      type: "object",
      label: "Partnership Deed",
      associatedFirm: ["partnership_firm"],
      template: null,
    },
    certificate_of_incorporation: {
      defaultValue: null,
      required: true,
      type: "object",
      label: "Certificate of Incorporation",
      associatedFirm: ["private_limited_company"],
    },
    memorandum_and_articales_of_association: {
      defaultValue: null,
      required: true,
      type: "object",
      label: "Memorandum & Articles of Association",
      associatedFirm: ["private_limited_company"],
      template: null,
    },
    board_resolution: {
      defaultValue: null,
      required: true,
      type: "object",
      label: "Board Resolution",
      associatedFirm: ["private_limited_company"],
      template: "Download Template",
      template_link:
        "https://docs.google.com/document/d/16OMYBygI2QCZKccG-E9PLxN7U8VO27mt/edit",
    },
    list_of_beneficiaries: {
      defaultValue: null,
      required: true,
      type: "object",
      label: {
        trust:
          "List of Beneficiaries, Trustees, Settlor & Authors of the Trust",
        private_limited_company:
          "List of relevant persons holding senior management positions",
      },
      associatedFirm: ["trust", "private_limited_company"],
      template: null,
    },
    beneficial_ownership_document: {
      defaultValue: null,
      required: true,
      type: "object",
      label: "Beneficial Ownership Document",
      associatedFirm: ["trust", "partnership_firm", "private_limited_company"],
      template: "Download Template",
      template_link:
        "https://docs.google.com/document/d/1NJzMQDSlO0VAd5y_VUAhv1IFAMBB_O461qZfOOC716A/edit",
    },
    // relevant_persons_list:{
    //   defaultValue: null,
    //   required: true,
    //   type: "object",
    //   label: "List of relevant persons having senior management positions",
    //   associatedFirm: ["private_limited_company"],
    //   template: null
    // },
    huf_deed: {
      defaultValue: null,
      required: true,
      type: "object",
      label: "HUF Deed",
      associatedFirm: ["huf"],
      template: null,
    },
    power_of_attorney: {
      defaultValue: null,
      required: false,
      type: "object",
      label: "Power of Attorney",
      associatedFirm: ["huf"],
      template: null,
    },
    affidavit_for_huf: {
      defaultValue: null,
      required: false,
      type: "object",
      label: "Affidavit For HUF",
      associatedFirm: ["huf"],
      template: null,
    },
  },

  bankVerifyState: {
    account_number: {
      defaultValue: null,
      required: true,
      type: "number",
    },
    account_holder_name: {
      defaultValue: null,
      required: false,
      type: "text",
    },
    ifsc_code: {
      defaultValue: null,
      required: true,
      type: "text",
    },
    agreement: {
      defaultValue: null,
      required: false,
      type: "text",
    },
    is_penny_drop_success: {
      defaultValue: false,
      required: false,
      type: "text",
    },
    is_term_and_condition_accepted: {
      defaultValue: false,
      required: false,
      type: "boolean",
    },
    is_agreement_accepted: {
      defaultValue: false,
      required: false,
      type: "boolean",
    },
    cancel_cheque_image: {
      defaultValue: null,
      required: false,
      type: "string",
    },
  },
  clientNomineeDetails: {
    nominee_name: {
      defaultValue: null,
      required: true,
      type: "text",
    },
    nominee_mobile_number: {
      defaultValue: null,
      required: false,
      type: "number",
    },
    nominee_dob: {
      defaultValue: null,
      required: true,
      type: "date",
    },
    nominee_pan: {
      defaultValue: null,
      required: false,
      type: "text",
    },
    nominee_email_id: {
      defaultValue: null,
      required: false,
      type: "email",
    },
    nominee_relationship: {
      defaultValue: null,
      required: true,
      type: "string",
    },
  },
  authoriserKycDetailsState: {
    authoriser_pan: {
      defaultValue: null,
      required: true,
      type: "string",
      associatedFirm: [
        "huf",
        "private_limited_company",
        "trust",
        "partnership_firm",
      ],
    },
    authoriser_pan_name: {
      defaultValue: null,
      required: true,
      type: "text",
      associatedFirm: [],
    },
    authoriser_dob: {
      defaultValue: null,
      required: true,
      type: "date",
      associatedFirm: [
        "huf",
        "private_limited_company",
        "trust",
        "partnership_firm",
        "sole_proprietorship",
      ],
    },
    authoriser_gender: {
      defaultValue: null,
      required: true,
      type: "string",
      associatedFirm: [
        "huf",
        "private_limited_company",
        "trust",
        "partnership_firm",
        "sole_proprietorship",
      ],
    },
    authoriser_selfie: {
      defaultValue: null,
      required: true,
      type: "string",
      associatedFirm: [
        "huf",
        "private_limited_company",
        "trust",
        "partnership_firm",
        "sole_proprietorship",
      ],
    },
    authoriser_address_type: {
      defaultValue: null,
      required: true,
      type: "number",
      associatedFirm: [
        "huf",
        "private_limited_company",
        "trust",
        "partnership_firm",
        "sole_proprietorship",
      ],
    },
    authoriser_address_front_image: {
      defaultValue: null,
      required: true,
      type: "text",
      associatedFirm: [
        "huf",
        "private_limited_company",
        "trust",
        "partnership_firm",
        "sole_proprietorship",
      ],
    },
    authoriser_address_back_image: {
      defaultValue: null,
      required: false,
      type: "text",
      associatedFirm: [],
    },
    is_authoriser_pan_consent_accepted: {
      defaultValue: null,
      required: true,
      type: "boolean",
      associatedFirm: [
        "huf",
        "private_limited_company",
        "trust",
        "partnership_firm",
      ],
    },
    authoriser_pan_res: {
      defaultValue: null,
      required: true,
      type: "object",
      associatedFirm: [],
    },

    authoriser_registered_address: {
      defaultValue: null,
      required: true,
      type: "text",
      label: "Address",
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
    },

    authoriser_current_locality: {
      defaultValue: null,
      required: true,
      type: "text",
      label: "Locality",
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
    },
    authoriser_current_city: {
      defaultValue: null,
      required: true,
      type: "text",
      label: "City",
      specialCharCheck: true,
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
    },
    authoriser_current_state: {
      defaultValue: null,
      required: true,
      type: "text",
      label: "State",
      specialCharCheck: true,
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
    },
    authoriser_current_pin: {
      defaultValue: null,
      required: true,
      type: "number",
      label: "Pincode",
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
    },

    authoriser_current_address_flag: {
      defaultValue: true,
      required: false,
      type: "boolean",
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
    },
    authoriser_permanent_address: {
      defaultValue: null,
      required: false,
      requiredCheck: true,
      type: "text",
      label: "Address",
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
    },

    authoriser_permanent_locality: {
      defaultValue: null,
      required: false,
      type: "text",
      label: "Locality",
      requiredCheck: true,
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
    },
    authoriser_permanent_city: {
      defaultValue: null,
      required: false,
      type: "text",
      label: "City",
      requiredCheck: true,
      specialCharCheck: true,
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
    },

    authoriser_permanent_state: {
      defaultValue: null,
      required: false,
      type: "text",
      label: "State",
      requiredCheck: true,
      specialCharCheck: true,
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
    },

    authoriser_permanent_pin: {
      defaultValue: null,
      required: false,
      type: "number",
      label: "Pincode",
      requiredCheck: true,
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
    },
  },
};
