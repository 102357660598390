import { API } from "aws-amplify";
import * as Types from "../api.types";
import { convertInvestorType } from "../../../utils/helper";

/**
 * Manages all requests to the API.
 */
export class KYC_API_V2 {
  /**
   * @description Method to validate pan number
   * @param data
   * @returns success or faliure
   */
  async checkPanNumber(data: any): Promise<Types.GetKycResult> {
    // payload
    const params = {
      body: data,
    };

    // call the api try and catch
    try {
      const response: any = await API.post(
        "baseurl",
        "/ifa_add_client/pan_verify",
        params
      );
      return { kind: "ok", data: response.data || response.message || {} };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }
  /**
   * @description Method to register user in the system
   * @param data
   * @returns success or faliure
   */
  async userRegistration(data: any): Promise<Types.GetKycResult> {
    // payload
    const params = {
      body: data,
    };

    // call the api try and catch
    try {
      const response: any = await API.post(
        "baseurl",
        "/ifa_add_client/user_profile",
        params
      );
      return { kind: "ok", data: response.data || response.message || {} };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }
   
  /**
   * @description Method to validate ifsc code and account number
   * @param data
   * @returns success or faliure
   */
  async validateIfscAndAccount(data: any): Promise<Types.GetKycResult> {
    // payload
    const params = {
      body: data,
    };

    // call the api try and catch
    try {
      const response: any = await API.post(
        "baseurl",
        "/ifa_add_client/penny_drop",
        params
      );
      return { kind: "ok", data: response.data || response.message || {} };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description Method to save client data
   * @param data
   * @returns success or faliure
   */
  async clientSave(data: any): Promise<Types.GetKycResult> {
    // payload
    const params = {
      body: data,
    };

    // call the api try and catch
    try {
      const response: any = await API.post(
        "baseurl",
        "/ifa_add_client/client_save",
        params
      );
      return { kind: "ok", data: response.data || response.message || {} };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description Method to send terms and condition
   * @param data
   * @returns success or faliure
   */
  async sentTermsAndCondtionMail(data: any): Promise<Types.GetKycResult> {
    // payload
    const params = {
      body: data,
    };

    // call the api try and catch
    try {
      const response: any = await API.post(
        "baseurl",
        "/ifa_add_client/tc",
        params
      );
      return { kind: "ok", data: response.data || response.message || {} };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  async nameMatchApi(data: any): Promise<Types.verifyNameMatching> {
    // payload
    const params = {
      body: data,
    };

    // call the api try and catch
    try {
      const response: any = await API.post(
        "baseurl",
        "/users/kyc/name_matching",
        params
      );
      return { kind: "ok", data: response.data || response.message || {} };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description Method to OTP
   * @param data
   * @returns success or faliure
   */
  async verifyClientConsent(data: any): Promise<Types.GetKycResult> {
    // payload
    const params = {
      body: data,
    };

    // call the api try and catch
    try {
      const response: any = await API.put(
        "baseurl",
        "/ifa_add_client/otp",
        params
      );
      return { kind: "ok", data: response.data || response.message || {} };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description Method to UPLOAD entity related documents
   * @param data
   * @returns success or faliure
   */
  async saveEntityDetails(data: any): Promise<Types.GetKycResult> {
    // payload
    const params = {
      body: data,
    };

    // call the api try and catch
    try {
      const response: any = await API.post(
        "baseurl",
        "/ifa_add_client/entity_doc_upload",
        params
      );
      return { kind: "ok", data: response.data || response.message || {} };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }
  /**
   * @description This function is for converting raw data into the actual data
   * @param raw
   * @returns it return the actual data that can be displayed
   */
  convertClientData = (raw: any) => {
    return {
      // profile data
      user_id: raw.user_id === null ? "" : raw.user_id,
      mobile: raw.mobile === null ? "" : raw.mobile,
      profile_name: raw.name === null ? "" : raw.name,
      email: raw.email === null ? "" : raw.email,
      dob: raw.dob === null ? "" : raw.dob,
      address: {
        current_address:
          raw.current_address === null ? "" : raw.current_address,
        current_pin_code: raw.current_pin === null ? "" : raw.current_pin,
        current_city: raw.current_city === null ? "" : raw.current_city,
        current_locality:
          raw.current_locality === null ? "" : raw.current_locality,
        current_state: raw.current_state === null ? "" : raw.current_state,
        permanent_address:
          raw.permanent_address === null ? "" : raw.permanent_address,
        permanent_locality:
          raw.permanent_locality === null ? "" : raw.permanent_locality,
        permanent_city: raw.permanent_city === null ? "" : raw.permanent_city,
        permanent_pin: raw.permanent_pin === null ? "" : raw.permanent_pin,
        permanent_state:
          raw.permanent_state === null ? "" : raw.permanent_state,
        permanent_current_address_flag:
          raw.permanent_current_address_flag === null
            ? ""
            : raw.permanent_current_address_flag,
      },

      gender: raw.gender === null ? "" : raw.gender,
      selfie: raw.selfie === null ? "" : raw.selfie,
      investor_type:
        raw.investor_type === null || raw.investor_type === ""
          ? "Individual"
          : convertInvestorType(raw.investor_type),
      company_type:
        raw.investor_type != null || raw.investor_type != ""
          ? raw.investor_type
          : null,
      has_profile: raw.has_profile === null ? false : raw.has_profile,
      kyc_status: raw.kyc_status === null ? "" : raw.kyc_status,
      has_document: raw.has_document === null ? false : raw.has_document,
      has_bank: raw.has_bank === null ? false : raw.has_bank,
      has_nominee: raw.has_nominee === null ? false : raw.has_nominee,
      has_entity: raw.has_entity === null ? false : raw.has_entity,
      has_authoriser: raw.has_authoriser === null ? false : raw.has_authoriser,
      tc_status: raw.tc_status === null ? "" : raw.tc_status,

      // document data
      pan: raw.documents.pan_number === null ? "" : raw.documents.pan_number,
      address_front_image:
        raw.documents.address_front_image === null
          ? ""
          : raw.documents.address_front_image,
      address_back_image:
        raw.documents.address_back_image === null
          ? ""
          : raw.documents.address_back_image,
      address_type:
        raw.documents.address_type == undefined
          ? ""
          : raw.documents.address_type,
      pan_name:
        raw.documents.pan_name == undefined ? "" : raw.documents.pan_name,
      ckyc: raw.documents.is_ckyc == undefined ? false : raw.documents.is_ckyc,
      document_data:
        raw.documents.document_data == undefined
          ? {}
          : raw.documents.document_data,
      pennyDrop_isSuccessful: raw?.penny_drop_is_successful ? raw.penny_drop_is_successful : 0,
      pennyDropTriedCounts: raw?.penny_drop_tried_counts ? raw.penny_drop_tried_counts : 0,
      // bank data
      account_number:
        raw.bank.account_number === null ? "" : raw.bank.account_number,
      bank_account_holder_name:
        raw.bank.account_name === null ? "" : raw.bank.account_name,
      ifsc_code: raw.bank.ifsc_code === null ? "" : raw.bank.ifsc_code,
      cancel_cheque:
        raw.bank.cancel_cheque === null ? "" : raw.bank.cancel_cheque,
      bank_data: raw.bank.bank_data == undefined ? {} : raw.bank.bank_data,

      // nominee data
      nominee_full_name: raw.nominee.name === null ? "" : raw.nominee.name,
      nominee_phone_number:
        raw.nominee.phone_number === null ? "" : raw.nominee.phone_number,
      nominee_email_id:
        raw.nominee.email_id === null ? "" : raw.nominee.email_id,
      nominee_pan_number:
        raw.nominee.pan_number === null ? "" : raw.nominee.pan_number,
      nominee_dob: raw.nominee.dob === null ? "" : raw.nominee.dob,
      nominee_relationship:
        raw.nominee.relationship === null ? "" : raw.nominee.relationship,
      // entity data
      entity_documents: raw.entity === null ? "" : raw.entity,
      //authoriser_details
      authoriser_details:
        raw.authoriser_details === null ? "" : raw.authoriser_details,
       
    };
  };
  /**
   * @description Method to fetch user details
   * @param data
   * @returns success or faliure
   */
  async fetchUserDetails(uuid: any): Promise<Types.GetKycResult> {
    // call the api try and catch
    try {
      const response: any = await API.get(
        "baseurl",
        `/ifa_add_client/clients/${uuid}`,
        {}
      );
      console.log(response,response.data,"Response from fetchUserDetails")
      const rawData = response.data;
      const convertedData: any = this.convertClientData(rawData);
      console.log(convertedData,"convertedData from fetchUserDetails")
      return { kind: "ok", data: convertedData };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }
}
