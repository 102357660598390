import React, { useEffect, useState, useRef } from "react";
import "./ifacommissions.css";
import { Redirect, useHistory } from "react-router-dom";
import { observer } from "mobx-react-lite";
import {
  SampleToast,
  FullScreenLoader,
  ContentLoadingLoader,
  LabelWithSortComponent,
} from "../../components";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Pagination,
} from "@mui/material";

import { Auth } from "aws-amplify";
import { kycStatus_API } from "../../services/api/kycstatus/kycstatus-api";
import InfoIcon from "@mui/icons-material/Info";
import {
  errorToast,
  convertAmount,
  downloadBase64File,
  generateHTMLPDFFile,
  generateHTMLPDFBase64,
  imageCompressor,
  getUniqueUUID,
  getFileExtension,
  successToast,
  convertedAsLocal,
} from "../../utils/helper";
import { CustomTooltip } from "../../components";
import { noSelection } from "../../assets/images/index";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Profile_API } from "../../services/api/Profile/profile-api";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import Loader from "react-loader-spinner";
import { fileUpload } from "../../assets/images/index";

const tableContainer = {
  marginTop: "20px",
  width: "100%",
  alignSelf: "center",
};

const tableHeader = {
  backgroundColor: "#f0f0f0",
  width: "25%",
  fontWeight: "600",
  height: "16px",
  fontSize: "14px",
  fontFamily: "Asap",
  color: "#1c1c1c",
  paddingLeft: "3%",
  border: "none",
};

const tableRow = {
  fontSize: "14px",
  fontFamily: "Asap",
  fontWeight: "500",
  height: "16px",
  color: "#1f1f1f",
  paddingLeft: "3%",
  border: "none",
};

const IfaCommissionsSummary = observer(function IfaCommissionsSummary(props) {
  const [isLogin, setIsLogin] = useState();
  const [loginRender, setLoginRender] = useState(false);
  const [render, setRender] = useState(false);
  const navigate = useHistory();
  const [loader, setLoader] = useState(false);
  const [requestType, setRequestType] = useState("freedom_plan");
  const [month, setMonth] = useState("");
  const [fy, setFy] = useState("");
  const [financialYear, setFinancialYear] = useState("");
  const [commissionData, setCommissionData] = useState([]);
  const [invoiceStatus, setInvoiceStatus] = useState(-1);
  const [referenceNo, setReferenceNo] = useState("");
  const [page, setPage] = useState(0);
  const [invoiceLoader, setInvoiceLoader] = useState(false);
  const [isGst, setIsGst] = useState(false);
  const [earningsPlan, setEarningsPlan] = useState("earnings_monthly");
  const fileRef = useRef(null);
  const imageSizeLimit = 15;
  const rowsPerPage = 6;

  const months = {
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December",
    1: "January",
    2: "February",
    3: "March",
  };
  const profile_api = new Profile_API();

  useEffect(() => {
    props.showCommonHeader(true);
    //props.showCommonSidebar(true);
    let checkLogin = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        let status = localStorage.getItem("kyc_status");
        if (status != 2) {
          setIsLogin(false);
        }
        else
        {
          fetchIfakycStatus();
        }
  
      } catch {
        setIsLogin(false);
      }
    };
    return checkLogin();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchFinancialYears();

    profile_api.getIFAProfileData().then((res) => {
      if (res.kind == "ok") {
        console.log(res.data);
        if (res.data.gst_number) {
          setIsGst(true);
        }
      }
    });
  }, []);

  //fetch ifa commissions data
  useEffect(() => {
    setRender(false);
    if (fy != "" && month != "") {
      const data = {
        financial_year: fy,
        month: month,
      };
      profile_api.getIfaCommissionsData(data).then((res) => {
        if (res.kind == "ok") {
          setRender(true);
          setCommissionData(res.data.ifa_commissions);
          setInvoiceStatus(res.data.invoice_status);
          setReferenceNo(res.data.invoice_reference);
        } else {
          errorToast(res.error);
          console.log(res.error);
          setRender(true);
        }
      });
    }
  }, [fy, month]);

  //fetch all the financial year till current date
  const fetchFinancialYears = () => {
    const current_date = new Date();
    const month = current_date.getMonth();
    const year = current_date.getFullYear();
    let allFY = [];

    for (let i = 2023; i <= year; i++) {
      if (i == year) {
        if (month > 3) {
          allFY.push(`${i}-${i + 1}`);
        }
      } else {
        allFY.push(`${i}-${i + 1}`);
      }
    }
    setFinancialYear(allFY);
  };
  // fetch ifa kyc status
  const fetchIfakycStatus = () => {
    setLoader(true);
    const api = new kycStatus_API();

    // fetch data
    api
      .getkycStatusData()
      .then((res) => {
        if (res.kind === "ok") {
          props.showCommonSidebar(true);
          setLoader(false);
          setIsLogin(true);
          setLoginRender(true);
        } else {
          setLoader(false);
          navigate.push("/");
        }
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
        errorToast(
          "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
        );
      });
  };
  const handleToggle = (e) => {
    setRequestType(e.target.id);
    setEarningsPlan("earnings_monthly");
    setPage(0);
  };

  const handleEarningsToggle = (e) => {
    setEarningsPlan(e.target.id);
    setPage(0);
  };
  //info text
  const infoText = () => {
    return "Overall commission is a sum of all the commissions earned in the selected month across all the plans. The commissions are calculated on the active investments every day for each plan.";
  };

  const generateInvoiceText = () => {
    return "Please review the commission calculation carefully before generating the invoice. Once generated, invoices shall remain unchanged under any circumstances. Payouts for commissions earned in the preceding month will be issued by the 15th of the current month, provided that the invoice is generated before the 12th of that month.";
  };

  const uploadInvoiceText = () => {
    return `Invoices uploaded by the 12th of each month will be processed by the 15th of the same month. Any invoices uploaded after the 12th will be processed in the following month.`;
  };
  //handle month
  const handleMonth = (e) => {
    setMonth(e.target.value);
  };
  //handle fy
  const handleFy = (e) => {
    setFy(e.target.value);
  };
  //convert to string FY
  const convertFY = (value) => {
    const years = value.split("-");
    const lastTwo = years[1].slice(-2);

    return `${years[0]}-${lastTwo}`;
  };
  //handle click on contact us
  const handleClickContact = () => {
    navigate.push("/support?subject=Commission Related");
  };

  //filter data
  const filterData = (item) => {
    if (requestType == "growth_plan_12") {
      if (earningsPlan == "earnings_maturity") {
        return item.tenure == 12 && item.type == 0;
      }
      return item.tenure == 12 && item.type == 2;
    } else if (requestType == "growth_plan_24") {
      if (earningsPlan == "earnings_maturity") {
        return item.tenure == 24 && item.type == 0;
      }
      return item.tenure == 24 && item.type == 2;
    } else if (requestType == "growth_plan_36") {
      if (earningsPlan == "earnings_maturity") {
        return item.tenure == 36 && item.type == 0;
      }
      return item.tenure == 36 && item.type == 2;
    } else {
      return item.tenure == null;
    }
  };
  //get total commission
  const totalCommmission = () => {
    let total = 0;
    let tenures = [12, 24, 36, null];
    for (let i = 0; i < tenures.length; i++) {
      if (
        commissionData.filter((item) => item.tenure === tenures[i]).length > 0
      ) {
        const data = commissionData.filter(
          (item) => item.tenure === tenures[i]
        );
        data.sort((a, b) => b.id - a.id);
        total += data[0].calculated_commision;
      }
    }

    return total;
  };
  //get plan wise total commission
  const totalPlanCommmission = () => {
    if (commissionData.filter(filterData).length > 0) {
      const data = commissionData.filter(filterData);
      data.sort((a, b) => b.id - a.id);
      return data[0].calculated_commision;
    }
    return 0;
  };
  // handle page change function
  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };
  // if user is not login then redirect to login page
  if (isLogin === false) {
    return <Redirect to="/" />;
  }

  //handle invoice cta
  const handleInvoiceCta = () => {
    setInvoiceLoader(true);
    if (
      month == null ||
      month == "" ||
      financialYear == null ||
      financialYear == ""
    ) {
      errorToast("Please select commission period");
      setInvoiceLoader(false);
      return;
    }

    if (month >= new Date().getMonth() + 1) {
      errorToast("Invoice cannot be generated for the selected month");
      setInvoiceLoader(false);
      return;
    }

    const amount = totalCommmission();

    if (amount == null || amount == 0 || amount < 0) {
      errorToast("Unable to generate invoice: Invalid commission amount");
      setInvoiceLoader(false);
      return;
    }

    const payload = {
      month: months[month].toUpperCase(),
      year: fy,
      commission: totalCommmission(),
      type: "generate",
    };

    profile_api.getIFACommissionPdf(payload).then(async (res) => {
      if (res.kind == "ok") {
        setInvoiceLoader(true);
        console.log(res.data);
        if (res.data && res.data.already_generated == true) {
          downloadBase64File(res.data.base64, res.data.duplicate_invoice_key);
          setInvoiceLoader(false);
        } else {
          let duplicate_html_pdf;
          let original_html_pdf;

          if (res.data.duplicate_template) {
            const duplicateHtml = window.atob(res.data.duplicate_template);
            generateHTMLPDFFile(
              duplicateHtml,
              res.data.duplicate_invoice_document
            );
            setInvoiceLoader(false);
            //generate pdf base64 for s3
            duplicate_html_pdf = await generateHTMLPDFBase64(duplicateHtml);
          }

          if (res.data.original_template) {
            const originalHtml = window.atob(res.data.original_template);
            //generate pdf base64 for s3
            original_html_pdf = await generateHTMLPDFBase64(originalHtml);
          }

          ///prepare payload to upload base64 to s3
          const dataToUpload = {
            duplicate_invoice_key: res.data.duplicate_invoice_document,
            duplicate_html_pdf: duplicate_html_pdf,
            original_html_pdf: original_html_pdf,
            original_invoice_key: res.data.original_invoice_document,
            month: months[month].toUpperCase(),
          };

          profile_api.uploadIFACommissionPdf(dataToUpload).then((res) => {
            if (res.kind == "ok") {
              setInvoiceLoader(false);
              console.log("res", res);
            }
          });
        }
      } else {
        errorToast(res.error);
        console.log(res.error);
        setInvoiceLoader(false);
      }
    });
  };

  const handleUploadInvoiceCta = async (e) => {
    if (
      month == null ||
      month == "" ||
      financialYear == null ||
      financialYear == ""
    ) {
      errorToast("Please select commission period");
      setInvoiceLoader(false);
      return;
    }

    if (month >= new Date().getMonth() + 1) {
      errorToast("Invoice cannot be uploaded for the selected month");
      setInvoiceLoader(false);
      return;
    }

    if (e.target.files[0].size > imageSizeLimit * 1024 * 1024) {
      errorToast(`Uploaded file should not exceed ${imageSizeLimit} MB`);
      setLoader(false);
    } else {
      setInvoiceLoader(true);

      let compressedImage = e.target.files[0];

      let uuid = await getUniqueUUID();
      let ext = await getFileExtension(e);
      let fileName = `${uuid}.${ext}`;

      let reader = new FileReader(compressedImage);
      reader.readAsDataURL(compressedImage);
      reader.onload = function (evt) {
        const blob = evt.target.result;

        const data = {
          original_invoice_key: fileName,
          base64data: blob,
          month: months[month].toUpperCase(),
          year: fy,
          type: "upload",
          commission: totalCommmission(),
        };
        profile_api.getIFACommissionPdf(data).then(async (res) => {
          if (res.kind == "ok") {
            successToast("File uploaded Sucessfully");
            setInvoiceLoader(false);
          }
        });
      };
    }
  };

  console.log("isgst", isGst);
  return (
    <div>
      {loginRender === false ? (
        <div />
      ) : (
        <div className="commissions_summary_div_container">
          {/* header */}
          <div className="commissions_summary_div_table_container">
            <div className="commissions_summary_header_div">
              {/* text */}
              <div className="commissions_summary_header_div_title">
                Commissions Summary
              </div>
              <div>
                <FormControl sx={{ m: 1, minWidth: 168, height: 42 }}>
                  <Select
                    value={fy}
                    onChange={handleFy}
                    displayEmpty
                    IconComponent={KeyboardArrowDownIcon}
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{ fontFamily: "Asap" }}
                  >
                    <MenuItem
                      sx={{ fontFamily: "Asap", display: "none" }}
                      value=""
                    >
                      Financial Year
                    </MenuItem>
                    {financialYear.map((item, index) => {
                      return (
                        <MenuItem
                          key={index}
                          sx={{ fontFamily: "Asap" }}
                          value={item}
                        >
                          {convertFY(item)}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

                <FormControl sx={{ m: 1, minWidth: 168, height: 42 }}>
                  <Select
                    value={month}
                    onChange={handleMonth}
                    displayEmpty
                    IconComponent={KeyboardArrowDownIcon}
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{ fontFamily: "Asap" }}
                  >
                    <MenuItem
                      sx={{ fontFamily: "Asap", display: "none" }}
                      value=""
                    >
                      Month
                    </MenuItem>
                    {Object.keys(months).map((item, index) => {
                      return (
                        <MenuItem
                          key={index}
                          sx={{ fontFamily: "Asap" }}
                          value={item}
                        >
                          {months[item]}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="commissions_summary_info_container">
              <div className="commissions_summary_info">
                <div
                  className="commissions_summary_info_heading"
                  style={{ whiteSpace: "nowrap" }}
                >
                  Overall commission
                  <CustomTooltip info={infoText()}>
                    <InfoIcon
                      style={{
                        fontSize: "17px",
                        marginLeft: "2px",
                        color: "#3A86FF",
                        marginTop: "0px",
                        // border:"1px solid yellow"
                      }}
                    />
                  </CustomTooltip>
                </div>
                <div className="commissions_summary_info_value">
                  {fy == "" || month == ""
                    ? "N/A"
                    : `₹ ${convertAmount(totalCommmission())}`}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  marginLeft: isGst ? "2%" : "",
                }}
              >
                <div className="commissions_summary_info">
                  <div className="commissions_summary_info_heading">Status</div>
                  <div
                    className={
                      invoiceStatus == 1
                        ? "paid_value"
                        : invoiceStatus == 0
                        ? "unpaid_value"
                        : "commissions_summary_info_value"
                    }
                  >
                    {invoiceStatus == 1
                      ? "Paid"
                      : invoiceStatus == 0
                      ? "Unpaid"
                      : "N/A"}
                  </div>
                </div>

                {invoiceStatus == 1 ? (
                  <div className="commissions_summary_info">
                    <div
                      className="commissions_summary_info_heading"
                      style={{ width: "100%", whiteSpace: "nowrap" }}
                    >
                      Reference Number
                    </div>
                    <div className="commissions_summary_info_value_1">
                      {referenceNo}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>

              {new Date().getDate() >= 2 ? (
                <div className="invoice_section">
                  {fy != "" && month != "" && isGst == false ? (
                    <CustomTooltip info={generateInvoiceText()}>
                      <button
                        onClick={() => {
                          handleInvoiceCta();
                        }}
                        className="ifa_commissions_generate_invoice"
                      >
                        {invoiceLoader ? (
                          <Loader
                            type="TailSpin"
                            color="#fff"
                            height={18}
                            width={18}
                          />
                        ) : (
                          "Generate Invoice"
                        )}
                      </button>
                    </CustomTooltip>
                  ) : null}

                  {fy != "" && month != "" && isGst == true ? (
                    <div style={{ display: "flex" }}>
                      <p style={{ whiteSpace: "nowrap", marginTop: "4%" }}>
                        Download invoice format{" "}
                        <a
                          className="invoice_link"
                          href="https://arthmate-p2p-public-assets.s3.ap-south-1.amazonaws.com/images/Format+of+Invoice.pdf"
                          target="_blank"
                        >
                          here
                        </a>
                      </p>

                      <CustomTooltip info={uploadInvoiceText()}>
                        <button
                          onClick={() => {
                            fileRef.current.click();
                          }}
                          className="ifa_commissions_generate_invoice"
                        >
                          <img src={fileUpload} />
                          {invoiceLoader ? (
                            <Loader
                              type="TailSpin"
                              color="#fff"
                              height={18}
                              width={18}
                            />
                          ) : (
                            <p
                              style={{
                                padding: "5px",
                                margin: "5px",
                                whiteSpace: "pre",
                                fontSize: "13px",
                              }}
                            >
                              Upload Invoice
                            </p>
                          )}
                        </button>
                      </CustomTooltip>
                    </div>
                  ) : (
                    <></>
                  )}
                  <input
                    type="file"
                    id="invoice_file"
                    ref={fileRef}
                    onChange={(e) => {
                      e.preventDefault();
                      handleUploadInvoiceCta(e);
                    }}
                    accept="image/*,.pdf"
                    style={{ display: "none" }}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="toggle_container">
              <div
                className={
                  requestType === "freedom_plan"
                    ? "toggle_element_active"
                    : "toggle_element"
                }
                id="freedom_plan"
                onClick={handleToggle}
              >
                Freedom Plan
              </div>
              <div
                className={
                  requestType === "growth_plan_12"
                    ? "toggle_element_active"
                    : "toggle_element"
                }
                id="growth_plan_12"
                onClick={handleToggle}
              >
                Growth Plan - 12 Months
              </div>
              <div
                className={
                  requestType === "growth_plan_24"
                    ? "toggle_element_active"
                    : "toggle_element"
                }
                id="growth_plan_24"
                onClick={handleToggle}
              >
                Growth Plan - 24 Months
              </div>
              <div
                className={
                  requestType === "growth_plan_36"
                    ? "toggle_element_active"
                    : "toggle_element"
                }
                id="growth_plan_36"
                onClick={handleToggle}
              >
                Growth Plan - 36 Months
              </div>
            </div>
            <div className="table_container">
              {fy == "" || month == "" ? (
                <div className="no_selection_container">
                  <img
                    style={{ height: "54px", width: "54px" }}
                    src={noSelection}
                  ></img>
                  <div className="no_selection_text">
                    Please select the financial year and month to see your
                    commissions summary.
                  </div>
                </div>
              ) : (
                <div className="table_data_container">
                  {render == false ? (
                    <ContentLoadingLoader></ContentLoadingLoader>
                  ) : (
                    <div>
                      <div className="total_commissions_earnings_header">
                        <div className="total_commission">
                          Total Commission :{" "}
                          <span
                            style={{ fontWeight: "600px", fontSize: "18px" }}
                          >
                            ₹ {convertAmount(totalPlanCommmission())}
                          </span>{" "}
                        </div>
                        {requestType == "freedom_plan" ? (
                          <div />
                        ) : (
                          <div className="earnings_toggle_container">
                            <div
                              className={
                                earningsPlan == "earnings_monthly"
                                  ? "monthly earnings_toggle_element_active"
                                  : "monthly earnings_toggle_element"
                              }
                              id="earnings_monthly"
                              onClick={handleEarningsToggle}
                            >
                              Monthly Earnings
                            </div>
                            <div
                              className={
                                earningsPlan == "earnings_maturity"
                                  ? "maturity earnings_toggle_element_active"
                                  : "maturity earnings_toggle_element"
                              }
                              id="earnings_maturity"
                              onClick={handleEarningsToggle}
                            >
                              Earnings at maturity
                            </div>
                          </div>
                        )}
                      </div>
                      {/* table */}
                      <TableContainer style={tableContainer}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              <TableCell align="left" style={tableHeader}>
                                Date
                              </TableCell>
                              <TableCell align="left" style={tableHeader}>
                                Active Investments
                              </TableCell>
                              <TableCell align="left" style={tableHeader}>
                                Commission Rate
                              </TableCell>
                              <TableCell align="left" style={tableHeader}>
                                Earned Commission
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {commissionData
                              .filter(filterData)
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((row, index) => (
                                <TableRow
                                  key={index}
                                  style={{
                                    backgroundColor:
                                      index % 2 === 0 ? "#f9f9f9" : "#f1f1f1",
                                  }}
                                >
                                  <TableCell style={tableRow} align="left">
                                    {
                                      convertedAsLocal(row.created_at).split(
                                        " "
                                      )[0]
                                    }
                                  </TableCell>
                                  <TableCell style={tableRow} align="left">
                                    {`₹ ${convertAmount(
                                      row.outstanding_principal
                                    )}`}
                                  </TableCell>
                                  <TableCell style={tableRow} align="left">
                                    {`${row.fixed_commision_rate}%`}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      ...tableRow,
                                      borderRight: "none",
                                    }}
                                    align="left"
                                  >
                                    {`₹ ${convertAmount(
                                      row.calculated_commision
                                    )}`}
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                        {/* if no data available */}
                        {commissionData.filter(filterData)?.length === 0 ? (
                          <div className="no_row_found_div">
                            <FindInPageIcon
                              style={{
                                fontSize: "100px",
                                color: "#555555",
                              }}
                            />
                            <p className="no_row_found">No row found</p>
                          </div>
                        ) : (
                          <div />
                        )}
                      </TableContainer>
                      {/* pagination */}
                      {commissionData.filter(filterData).length <=
                      rowsPerPage ? (
                        <div></div>
                      ) : (
                        <div
                          className="pagination_div"
                          style={{ width: "100%", border: "none" }}
                        >
                          <Pagination
                            color="standard"
                            count={Math.ceil(
                              commissionData.filter(filterData).length /
                                rowsPerPage
                            )}
                            page={page + 1}
                            variant="outlined"
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              "& .MuiPagination-ul": {
                                marginTop: "0.5%",
                              },
                              "& .MuiPaginationItem-root": {
                                fontWeight: "bold",
                              },
                            }}
                            onChange={handleChangePage}
                            shape="rounded"
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
              <div className="table_footer">
                <span className="contact_us">
                  If you need any help, please{" "}
                </span>
                <span
                  className="contact_us"
                  style={{
                    color: "#3A86FF",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={handleClickContact}
                >
                  contact us
                </span>
              </div>
            </div>
          </div>
          {/* full screen loader */}
          {loader ? <FullScreenLoader /> : <div />}

          {/* toast container */}
          <SampleToast />
        </div>
      )}
    </div>
  );
});

export default IfaCommissionsSummary;
