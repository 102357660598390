import React, { useEffect, useState } from "react";
import { dateIntoWords, getBase64FromUrl } from "../../utils/helper";
import "./ifa-agreement.css";

const IfaAgreement = (props) => {
  const OrderList = (propsList) => {
    return (
      <div className="template_ifa_agreement_bullet_container">
        <div className="template_ifa_agreement_bullet_numbering">
          <p className="template_ifa_agreement_p_justify">{propsList.bullet}</p>
        </div>
        <div className="template_ifa_agreement_bullet_text">
          {propsList.children ? propsList.children : null}
        </div>
      </div>
    );
  };

  return (
    <div className="template_ifa_agreement_container">
      <div className="template_ifa_agreement_page">
        <div className="template_ifa_agreement_heading">
          <span className="template_ifa_agreement_underline">
            <span className="template_ifa_agreement_heading_text">
              LEAD GENERATION AGREEMENT
            </span>
          </span>
        </div>
        <div className="template_ifa_agreement_sub_heading">
          <p className="template_ifa_agreement_sub_heading_text">
            THIS LEAD GENERATION AGREEMENT (“Agreement”) made and entered at
            Gurugram on{" "}
            <span
              className="template_ifa_agreement_sub_heading_text"
              dangerouslySetInnerHTML={{ __html: dateIntoWords(new Date()) }}
            />{" "}
            (“Effective Date / Agreement Date”)
          </p>
        </div>
        <p>Between</p>
        <p className="template_ifa_agreement_p_justify">
          <b>Arthmatetech Private Limited,</b> a company incorporated in India
          under Companies Act, 2013 and having its having its Registered office
          at EM 03, Bengal Eco Intelligent Park, 11th Floor, Unit 11 D1&D2, Salt
          Lake, Kolkata 700091 and Corporate Office at The Circle, 3rd Floor,
          Huda City Centre Metro station, Sector 29, Gurugram, Haryana 122002,
          signing this Agreement through its authorized signatory (hereinafter
          referred to as <b>“Company”</b> which expression shall include its
          successors and assigns), of the First Part;
        </p>
        <p>And</p>
        <p className="template_ifa_agreement_p_justify">
          <b>{props.name}</b> and having its registered office and/or place of
          business at <span style={{ fontSize: "13px" }}>{props.address}</span>
        </p>
        <p className="template_ifa_agreement_p_justify">
          an individual signing this agreement for self /<br />a proprietary
          firm signing this agreement through its proprietor/ <br />
          a partnership firm signing this Agreement through its principal or
          managing partner / <br />
          a Hindu Undivided Family signing this agreement through its Karta/
          <br />a company incorporated under the Companies Act, 1956 or 2013 (as
          the case maybe) <br />
        </p>
        <p className="template_ifa_agreement_p_justify">
          signing this Agreement through its authorized signatory and having its
          office at the address stated above (hereinafter referred to as{" "}
          <b>“the Service Provider/Referrer”</b> which expression shall include,
          to the context permits, its successors in business and permitted
          assigns) of the Other Part.
        </p>
        <p className="template_ifa_agreement_p_justify">
          <b>Company</b> and the <b>Service Provider/Referrer</b> are
          hereinafter referred together as <b>“the Parties”</b> and each
          individually <b>“a Party”</b>.
        </p>
        <p className="template_ifa_agreement_p_justify">WHEREAS</p>

        <div className="template_ifa_agreement_p_justify">
          <OrderList bullet={"A."}>
            <p>
              The Company is, inter-alia, engaged in the business of providing
              technology solutions and servicesand is desirous to engage or
              source the potential Lenders for further introducing them with the
              Lending or Finance Companies (<b>“NBFCs”</b>)..
            </p>
          </OrderList>

          <OrderList bullet={"B."}>
            <p>
              The Service Provider/Referrer is inter-alia engaged in the
              business of rendering of direct marketing services and has
              represented to the Company that it has the necessary expertise,
              staff, infrastructure, business premises, and facilities for
              performing the said Services detailed in the Scope of Services in
              Annexure II., which is to generate leads to track potential
              Lenders.
            </p>
          </OrderList>

          <OrderList bullet={"C."}>
            <p>
              The Company and the Service Provider/Referrer are desirous of
              entering into this Agreement to enter into business transaction
              for mutual benefits and the Parties by this Agreement intend to
              supersede and nullify any earlier Referral Agreement; if any, in
              accordance with the terms and conditions stipulated hereinafter.
            </p>
          </OrderList>
        </div>

        {/* page break */}
        <div className="template_ifa_agreement_page_break" />

        <br />

        <div style={{ textAlign: "left" }}>
          <span className="template_ifa_agreement_underline">
            <span className="template_ifa_agreement_p_justify ">
              NOW IT IS HEREBY AGREED BY AND BETWEEN THE PARTIES AS FOLLOWS:
            </span>
          </span>
        </div>

        <div className="template_ifa_agreement_p_justify">
          <OrderList bullet={"1."}>
            <p className="template_ifa_agreement_main_point_heading">
              DEFINITIONS:
            </p>
            <p>
              In this Agreement, the following expressions shall have the
              meaning hereinafter assigned to them:
            </p>
            <p>
              <b>“Agreement”</b> means this Referral / Lead Generation Agreement
              and any amendments thereto made in accordance with the provisions
              of this Agreement.
            </p>
            <p>
              <b>"Borrower"</b> means any person who wishes to be listed as a
              borrower on the Website and in respect of whom the Service
              Provider has obtained Leads;
            </p>

            <p>
              <b>"Confidential Information"</b> of Company shall mean and
              include any non-public information whether or not designated as
              being confidential or which under the circumstances surrounding
              disclosure ought to be treated as confidential. “Confidential
              Information” for purposes of confidentiality includes, but is not
              limited to, the following types of information with respect to the
              business and operations of Company, its database and other
              information of a similar nature (whether or not reduced to
              writing): marketing techniques and materials, marketing and
              development plans, information pertaining to the customers,
              information about the employees (including, addresses, phone
              numbers, e-mail addresses, and all other information relating to
              the employees), price list, brokerage/commission structure,
              pricing policies and financial information, discoveries, ideas,
              concepts, software in various stages of development, drawings,
              specifications, techniques, models, data, mark-up languages (HTML,
              XML), documentation, diagrams, flowcharts, research, development,
              formulas, computer programs, processes (both business and
              technical), technical specifications, data, ideas, inventions,
              algorithms, source code, object code, know-how, software listings,
              schematics and all verbal and written discussions between Company
              and the Service Provider/Referrer. Confidential Information also
              includes any information described above which Company treats as
              proprietary whether or not owned or developed by Company. The
              Confidential Information further includes, without limitation,
              information relating to Company’s released or unreleased services
              or products, the marketing or promotion of any of Company’s
              business policies or practices, and information received from
              others that the Company is obligated to treat as confidential.
              Confidential Information disclosed to the Service
              Provider/Referrer by any employee of Company shall also be
              considered as Confidential Information. Further, all of Company’s
              financial projections, data and other related information and
              documents (including any verbal indication) as well as results and
              conclusion arising from the scrutiny process of any Leads
              submitted by the Service Provider and examination of such data and
              information which is acquired by the Company, documents as well as
              standardized product/instrument concerning the work assigned to
              the Service Provider shall also constitute Confidential
              Information. And in each case whether such information was
              disclosed before or after the date of this Agreement, either in
              writing, in disk or electronic form or orally or visually and
              whether directly or indirectly by the Company or its advisers.
            </p>
            <p>
              <b>“Lead(s)”</b> means information about potential Lenders
              referred by the Service Provider/Referrer to the Company, who are
              interested in being listed as Lenders on the Website.
            </p>
            <p>
              <b>“Lender”</b> means any person who wishes to be listed as a
              lender on the Website and in respect of whom the Service Provider
              has obtained Leads.
            </p>
            <p>
              <b>“Loan Disbursed”</b> shall mean the actual sum borrowed by a
              Borrower or offered/provided/disbursed by a Lender.
            </p>
            <p>
              <b>“Premises”</b> shall mean the premises being held by the
              Service Provider/Referrer in the Territories.
            </p>

            {/* page break */}
            <div className="template_ifa_agreement_page_break" />

            <p>
              <b>“Services”</b> shall mean generating Leads and referring
              potential Lenders to the Company and includes the services more
              particularly specified in <b>Annexure II</b> hereof as amended
              from time to time and shall include any processes/procedures
              thereof.
            </p>
            <p>
              <b>“Sourcing Fees”</b> shall mean, the fees payable by the Company
              as specified in <b>Annexure I</b> annexed hereto.
            </p>
            <p>
              <b>“Territories”</b> shall mean India.
            </p>
            <p>
              <b>“Website”</b> shall mean the website of the NBFCs or Financial
              / Lending Institutions.
            </p>
          </OrderList>
          <OrderList bullet={"2."}>
            <p className="template_ifa_agreement_main_point_heading">
              INTERPRETATION:
            </p>
            <p>In this Agreement, unless the context otherwise requires:</p>
            <div className="template_ifa_agreement_p_justify">
              <OrderList bullet={"1."}>
                <p>
                  'Headings' are for convenience only and do not govern or
                  affect the construction or interpretation of this Agreement.
                </p>
              </OrderList>
              <OrderList bullet={"2."}>
                <p>
                  Any 'Explanation' is to be treated as an integral part of this
                  Agreement.
                </p>
              </OrderList>
              <OrderList bullet={"3."}>
                <p>
                  Reference to any statute or statutory provision shall include
                  a reference to that statute or statutory provision for the
                  time being in force and also to amendment, extension,
                  modification, re-enactment or repeal thereof.
                </p>
              </OrderList>
              <OrderList bullet={"4."}>
                <p>
                  All references in this Agreement to any provision of any
                  statute shall be deemed also to refer to the statute,
                  modification or re-enactment thereof or any statutory rule,
                  order or regulation made thereunder or under such re-enactment
                  in force in India at the time of entering into this Agreement;
                </p>
              </OrderList>
              <OrderList bullet={"5."}>
                <p>
                  All references in the Agreement to Clauses, Sub-Clauses and
                  Annexure shall be construed as references respectively to the
                  Clauses, Sub-Clauses and Annexure.
                </p>
              </OrderList>
              <OrderList bullet={"6."}>
                <p>
                  The Recitals constituted herein shall form an integral
                  operative part of this Agreement.
                </p>
              </OrderList>
              <OrderList bullet={"7."}>
                <p>
                  Words importing singular shall include the plural and vice
                  versa, and words importing the male sex shall include for its
                  purposes the female sex and vice versa.
                </p>
              </OrderList>
            </div>
          </OrderList>
          <OrderList bullet={"3."}>
            <p className="template_ifa_agreement_main_point_heading">TERM:</p>
            <p>
              This Agreement shall commence from the Agreement Date and shall
              continue for a term of <b>2 [Two] years(“Term”)</b>, unless
              otherwise terminated in accordance with Clause 9, and will be
              renewed as per mutual consent of both parties post expiry.
            </p>
          </OrderList>

          {/* page break */}
          <div className="template_ifa_agreement_page_break" />

          <OrderList bullet={"4."}>
            <p className="template_ifa_agreement_main_point_heading">
              APPOINTMENT:
            </p>
            <div className="template_ifa_agreement_p_justify">
              <OrderList bullet={"4.1"}>
                <p>
                  Subject to the terms and conditions contained in this
                  Agreement, Company hereby appoints the Service
                  Provider/Referrer, on a non-exclusive basis and in its
                  capacity as an independent Service Provider/Referrer, for
                  providing the Service to the Company and for performing the
                  functions, services, operations mentioned herein and the
                  Service Provider/Referrer accepts the said appointment and
                  agrees that during the term of this Agreement, it shall render
                  to Company the Services on a non-exclusive basis in terms of
                  this Agreement.
                </p>
              </OrderList>
              <OrderList bullet={"4.2"}>
                <p>
                  Notwithstanding anything contained herein nothing shall
                  restrict the right of Company to appoint any other Service
                  Provider/Referrer in relation to the same or similar Services
                  and on the same or similar or on any other terms and
                  conditions as the Company may deem fit and proper, which terms
                  and conditions may be at variance with the terms and
                  conditions contained herein.
                </p>
              </OrderList>
            </div>
          </OrderList>
          <OrderList bullet={"5."}>
            <p className="template_ifa_agreement_main_point_heading">
              RESPONSIBILITIES OF SERVICE PROVIDER/REFERRER
            </p>
            <p>
              The Service provider/Referrer agrees and undertakes to generate
              and provide Leads to the Company and provide the following
              Services to Company:
            </p>
            <div className="template_ifa_agreement_p_justify">
              <OrderList bullet={"a."}>
                <p>
                  Identify the potential Lenders in the Territory and procure
                  the necessary relevant information about such Lenders and
                  refer/direct them to the Company.
                </p>
              </OrderList>
              <OrderList bullet={"b."}>
                <p>Assist the Company in making proposals to generate Leads.</p>
              </OrderList>
              <OrderList bullet={"c."}>
                <p>
                  Service Provider/Referrer shall provide such financial and
                  business information concerning the Lender as requested by
                  Company in this regard.
                </p>
              </OrderList>
              <OrderList bullet={"d."}>
                <p>
                  Upon any Lender positively meeting the criteria prescribed by
                  the Company, to compile and ensure completion of all
                  documentation required by the Company to list such Lender on
                  the Website.
                </p>
              </OrderList>
              <OrderList bullet={"e."}>
                <p>
                  All such other services and functions as intimated to the
                  Service Provider/Referrer by Company from time to time.
                </p>
              </OrderList>
              <OrderList bullet={"f."}>
                <p>
                  If there is an event of any change in the constitution; or
                  management of the Service Provider/Referrer whether or not
                  involving a change in the ownership structure, the Service
                  Provider/Referrer shall inform the Company within 7 days of
                  such change.
                </p>
              </OrderList>
              <OrderList bullet={"g."}>
                <p>
                  The Service Provider shall prepare and submit a continuity
                  business plan acceptable to the Company, in the event the
                  Service provider is unable to provide the Services or for any
                  reason it has to suspend the provision of the Services. The
                  Company will ensure that it assists the Service Provider in
                  the event that the Service Provider is compelled for any
                  reason to activate the continuity business plan.
                </p>
              </OrderList>
            </div>
          </OrderList>

          {/* page break */}
          <div className="template_ifa_agreement_page_break" />

          <OrderList bullet={"6."}>
            <p className="template_ifa_agreement_main_point_heading">
              OPERATING PROCEDURES AND COVENANTS:
            </p>
            <div className="template_ifa_agreement_p_justify">
              <OrderList bullet={"6.1"}>
                <p>
                  The Service Provider/Referrer agrees and understands that the
                  Service Provider/Referrer shall not have any power or
                  authority to bind the Company in any way hereunder, including
                  but not limited to, (i) inducing or committing Company,
                  directly or indirectly, to list the Lenders on the Website;
                  (ii) to take any action contrary to those actions expressly
                  authorized hereunder.{" "}
                </p>
              </OrderList>
              <OrderList bullet={"6.2"}>
                <p>
                  The Service Provider/Referrer shall have no power to make
                  representations, promises, agreements or commitments for or on
                  behalf of the Company, and Service Provider/Referrer agrees it
                  shall take any and all actions necessary to advise each Lender
                  accordingly. Nothing contained herein shall be deemed to
                  authorize Service Provider/Referrer to enter into any
                  arrangement or provide any warranty or any guarantee to any
                  Lender or any other third party for and on behalf of Company,
                  about any Loan disbursements.
                </p>
              </OrderList>
              <OrderList bullet={"6.3"}>
                <p>
                  The Service Provider/Referrer hereby acknowledges that the
                  Company may in its sole discretion refuse or reject the
                  application of any Lender without assigning any reason in
                  respect thereof and shall not be liable in this regard, in any
                  manner whatsoever.
                </p>
              </OrderList>
              <OrderList bullet={"6.4"}>
                <p>
                  For each Lead, Service Provider/Referrer will warrant and
                  represent that; (i) all names, addresses, dates set out in the
                  Documentation are true and correct and the signatures therein
                  have been duly affixed in the presence of Service
                  Provider/Referrer, if any and, (ii) Documentation has not been
                  amended, changed, settled or compromised, in any manner
                  whatsoever without the prior written consent of Company.
                </p>
              </OrderList>
              <OrderList bullet={"6.5"}>
                <p>
                  The Service Provider/Referrer shall maintain highest degree of
                  probity, discretion and business competence in its dealings
                  and shall use its best endeavors to source Leads for the
                  Company and shall act only in accordance with the operating
                  procedures and other instructions of Company as intimated to
                  the Service Provider/Referrer from time to time.
                </p>
              </OrderList>
              <OrderList bullet={"6.6"}>
                <p>
                  The Service Provider/Referrer covenants that the Service
                  Provider will not enter into any agreement or collude with the
                  Lender in any manner or submit false or fraudulent documents
                  in order to get any Lead approved by the Company and or NBFCs
                  and shall procure applications from Lenders only for listing
                  on the Website of Company and shall not discriminate in any
                  manner against any Lenders in respect to the terms and
                  conditions of any Leads.
                </p>
              </OrderList>
              <OrderList bullet={"6.7"}>
                <p>
                  The Service Provider/Referrer agrees and undertakes not to
                  charge any fee or other remuneration from any Lender in order
                  to get the Leads approved from Company.
                </p>
              </OrderList>
              <OrderList bullet={"6.8"}>
                <p>
                  The Service Provider/Referrer shall submit such reports
                  regarding Leads and Lenders as may be specified by the Company
                  from time to time.
                </p>
              </OrderList>
              <OrderList bullet={"6.9"}>
                <p>
                  The Service Provider/Referrer shall not have any authority to
                  enter into any agreement(s) on behalf of the Company, draw,
                  accept, or endorse any bill of the Company or to use the name
                  of the Company except to the extent authorized by the Company
                  in writing.
                </p>
              </OrderList>
              <OrderList bullet={"6.10"}>
                <p>
                  The Service Provider/Referrer shall not use any of the
                  Company’s trade name, trade mark, symbol, logo or the words
                  <b>“Company”</b> either individually or in conjunction or any
                  other name(s) used by the Company, on any stationery,
                  letterhead, nameboard or otherwise, except to the extent and
                  in the form and manner approved by the Company in writing.
                </p>
              </OrderList>

              {/* page break */}
              <div className="template_ifa_agreement_page_break" />

              <OrderList bullet={"6.11"}>
                <p>
                  The Service Provider/Referrer agrees and undertakes that in
                  performance of its obligations under this Agreement it will
                  not directly or indirectly pay, offer or authorize payment for
                  anything of value (either in the form of compensation, gift
                  contribution or even otherwise) to any person (in particular,
                  government officials/employees), organization, or political
                  party, contrary to any law in force in India. The Service
                  Provider/Referrer will use good judgment to avoid even the
                  appearance of any improper payment and will further ensure
                  that business entertainment is of a perfectly lawful,
                  legitimate and decent nature, and permissible by the procedure
                  and business principle of integrity.
                </p>
              </OrderList>
              <OrderList bullet={"6.12"}>
                <p>
                  The Service Provident /Referrer undertakes that no unfair,
                  monopolistic or restrictive trade practices as defined under
                  the Monopolistic and Restrictive Trade Practices Act, 1969, or
                  unfair labour practices as defined under the Prevention of
                  Unfair Labour Practices Act, 1971, shall be adopted and it
                  shall not collude/communicate with any other service provider,
                  providing the same or similar services to the Company to fix
                  prices for the Leads or Services or for any purpose
                  whatsoever.
                </p>
              </OrderList>
              <OrderList bullet={"6.13"}>
                <p>
                  The Service Provider/Referrer shall avoid any conflict of
                  interest with the Company and also undertakes that each and
                  every person who is an employee, agent, consultant, or
                  representative of Service Provider/Referrer, will avoid any
                  conflict of interest with the Company, and if any person,
                  including any of those mentioned above, is in any way,
                  connected with the performance of obligation under this
                  Agreement, then such person will also take care to ensure
                  avoidance of conflict of interest with the Company.{" "}
                </p>
              </OrderList>
              <OrderList bullet={"6.14"}>
                <p>
                  The Service Provider/Referrer shall allow the Company or its
                  representatives reasonable opportunity to inspect its premises
                  during business hours for verification of all records,
                  employee related or otherwise which are maintained by the
                  Service Provider/Referrer in connection with the Services
                  rendered by it. This inspection shall not be construed as the
                  statutory/internal audit of the Service Provider/Referrer as
                  may be required under any law.
                </p>
              </OrderList>
              <OrderList bullet={"6.15"}>
                <p>
                  The Service Provider/Referrer agrees, accepts and acknowledges
                  that the Company is and shall be the sole and exclusive owner
                  of all Leads and is and shall be solely entitled to all the
                  information and Documentation concerning the Lenders and that
                  the Service Provider/Referrer has no right, title or interest
                  in the Leads.
                </p>
              </OrderList>
              <OrderList bullet={"6.16"}>
                <p>
                  The Service Provider/Referrer shall follow at all stages,
                  proper accounting, reporting and control procedure, including
                  keeping all financial and non-financial records accurate, up
                  to date and complete.
                </p>
              </OrderList>
            </div>
          </OrderList>
          <OrderList bullet={"7."}>
            <p className="template_ifa_agreement_main_point_heading">
              FEES, EXPENSES AND REMUNERATION:
            </p>
            <div className="template_ifa_agreement_p_justify">
              <OrderList bullet={"7.1"}>
                <p>
                  In consideration of the services being rendered by the Service
                  Provider/Referrer under this Agreement, Company agrees to pay
                  the Service Provider/Referrer the remuneration, which is more
                  particularly specified in <b>Annexure I</b>, hereto.
                </p>
              </OrderList>
              <OrderList bullet={"7.2"}>
                <p>
                  The Service Provider/Referrers understands and agrees that the
                  terms and conditions to receive Fees and the Fee structure
                  under Annexure 1 and other benefits; if any, are subject to
                  change at the sole discretion of the Company including due to
                  changes in any applicable law, rule or regulation.
                </p>
              </OrderList>
              <OrderList bullet={"7.3"}>
                <p>
                  All payments shall be made to the Service Provider/Referrer
                  after making statutory deductions, if any.
                </p>
              </OrderList>

              {/* page break */}
              <div className="template_ifa_agreement_page_break" />

              <OrderList bullet={"7.4"}>
                <p>
                  The Company shall also have right to deduct and recover from
                  the Service Provider/Referrer, the additional/excess sourcing
                  fees paid to the Service Provider/Referrer from the sourcing
                  fees payable in the next month, provided the same is intimated
                  in writing to the Service Provider/Referrer.
                </p>
              </OrderList>
              <OrderList bullet={"7.5"}>
                <p>
                  Under Goods and Service Tax regime Input Tax credit will be
                  available based on matching concepts between two parties.
                  Hence, it is the responsibility of Service Provider/Referrer
                  to make proper discharge and disclosure of taxes recovered
                  from the Company. In the event of loss of credit to the
                  Company due to non payment of taxes, non filing of GST
                  returns, mismatches in details etc. then the Company reserves
                  the right to recover the same from service provider/ referrer.
                </p>
              </OrderList>
              <OrderList bullet={"7.6"}>
                <p>
                  The payouts once release will be deemed correct, if no
                  communication is received within 15 working days from the date
                  of payout release. The Service Provider/Referrer must revert
                  in writing to the Company within 15 working days of the date
                  of payment of sourcing fees for any reconciliation of short
                  payment of the sourcing fees, failing which any representation
                  from the Service Provider/Referrer in this regard will not be
                  entertained.
                </p>
              </OrderList>
            </div>
          </OrderList>
          <OrderList bullet={"8."}>
            <p className="template_ifa_agreement_main_point_heading">
              TDS CERTIFICATE CLAUSE:
            </p>
            <div className="template_ifa_agreement_p_justify">
              <OrderList bullet={"8.1"}>
                <p>
                  The Service Provider/Referrer shall furnish its/their Income
                  Tax Permanent Account Number (PAN number) to the Company along
                  with the first bill raised by Service Provider/Referrer to the
                  Company. In the event of non-receipt of the Permanent Account
                  Number along with the first bill, TDS will be deducted at 20%
                  or rates in force whichever is higher.
                </p>
              </OrderList>
              <OrderList bullet={"8.2"}>
                <p>
                  The Company will issue a consolidated TDS Certificate to the
                  Service Provider/Referrer reflecting the total amount of TDS
                  (with break up figures) deducted from time to time while
                  making payments to the Service Provider/Referrer during the
                  relevant financial year. This consolidated TDS Certificate
                  will be Page 6 of 15issued within 15 days from the date of
                  filing of TDS return for the respective quarter. However, the
                  Company will ensure timely deposit of TDS (deducted from the
                  payments made to the Service Provider/Referrer) as per the
                  requirements under the relevant and applicable provisions of
                  the Income Tax Act.
                </p>
              </OrderList>
            </div>
          </OrderList>
          <OrderList bullet={"9."}>
            <p className="template_ifa_agreement_main_point_heading">
              TERMINATION:
            </p>
            <div className="template_ifa_agreement_p_justify">
              <OrderList bullet={"9.1"}>
                <p>
                  Either Party to this Agreement shall have a right to terminate
                  this Agreement for convenience, with or without assigning any
                  reasons, on giving not less than 30 (Thirty) days' prior
                  written notice of intention to do so, to the other Party.
                </p>
              </OrderList>
              <OrderList bullet={"9.2"}>
                <p>
                  Notwithstanding anything herein contained, the Company may by
                  giving 15 (Fifteen) days' notice in writing to the Service
                  Provider/Referrer terminate this Agreement in the event of any
                  default by the Service Provider/Referrer, if the default is
                  curable and is not cured by the service provider/referrer
                  within the 15 (Fifteen) days' notice period stipulated by
                  Company.
                </p>
              </OrderList>
              <OrderList bullet={"9.3"}>
                <p>
                  Notwithstanding anything herein contained, the Company may
                  forthwith terminate this Agreement under any one or more of
                  the following conditions, without penalty or compensation:
                </p>

                {/* page break */}
                <div className="template_ifa_agreement_page_break" />

                <div className="template_ifa_agreement_p_justify">
                  <OrderList bullet={"9.3.1"}>
                    <p>
                      If in the reasonable opinion of the Company, performance
                      of any of the Services under this Agreement by the Service
                      Provider/Referrer or its employees, is not acceptable as
                      being in contravention of any laws as may be applicable
                      from time to time or industry practice or under any
                      circumstances which, would amount to objectionable
                      service.{" "}
                    </p>
                  </OrderList>
                  <OrderList bullet={"9.3.2"}>
                    <p>
                      If Company is informed or information comes to the
                      Company's attention that the Service Provider/Referrer is
                      or may be in the violation of any law/s, ordinance/s,
                      regulation/s, code/s or court or quasi-judicial order/s or
                      there is an explicit and formal objection raised by the
                      regulators/ auditors with regard to continuation of
                      provision of Services by the Service Provider/Referrer.
                    </p>
                  </OrderList>
                  <OrderList bullet={"9.3.3"}>
                    <p>
                      If the Service Provider/Referrer and/or any individual
                      assigned by it for the performance of the Services under
                      this Agreement fails to perform the Services under this
                      Agreement or to observe any of its obligations, or
                      breaches all or any of the terms of this Agreement.
                    </p>
                  </OrderList>
                  <OrderList bullet={"9.3.4"}>
                    <p>
                      If in the opinion of the Company, the interests of Company
                      are jeopardized in any manner whatsoever.
                    </p>
                  </OrderList>
                  <OrderList bullet={"9.3.5"}>
                    <p>
                      If the Service Provider/Referrer discontinues its
                      business.
                    </p>
                  </OrderList>
                  <OrderList bullet={"9.3.6"}>
                    <p>
                      {" "}
                      If there is an event of any change in the constitution; or
                      management of the Service Provider/Referrer whether or not
                      involving a change in the ownership structure, unless the
                      Service Provider/Referrer informs Company and obtains the
                      prior written consent from Company of such change.
                    </p>
                  </OrderList>
                  <OrderList bullet={"9.3.7"}>
                    <p>
                      If a petition for insolvency is filed against the Service
                      Provider/Referrer and such petition is not dismissed
                      within 90 (Ninety) days after filing and/or of the Service
                      Provider/Referrer makes an arrangement for the benefit of
                      its creditors or, if the Court Receiver is appointed as
                      receiver of all/any of the Service Provider/Referrer's
                      properties.
                    </p>
                  </OrderList>
                </div>
              </OrderList>
              <OrderList bullet={"9.4"}>
                <p>
                  The Company shall be entitled to terminate this Agreement if
                  there is any deficiency in the Service provide by the Service
                  Provider/Referrer which the Service Provider fails to rectify
                  within 15 days of being notified by the Company.
                </p>
              </OrderList>
              <OrderList bullet={"9.5"}>
                <p>
                  Upon the termination or sooner determination of this Agreement
                  for any reason, the Service Provider/Referrer shall promptly
                  return all Confidential Information in its possession or
                  control, and shall destroy or, if applicable, erase any
                  remaining copies of all such Confidential Information,
                  including any electronically stored copies. An authorized
                  person of the Service Provider/Referrer shall certify in
                  writing to such return and/or destruction and that no
                  Confidential Information has been retained.
                </p>
              </OrderList>
              <OrderList bullet={"9.6"}>
                <p>
                  In the event that this Agreement is terminated for any
                  reasons, the Service Provider/Referrer shall forthwith
                  hand-over to Company all documents, material and any other
                  property belonging to Company that may be in the possession of
                  the Service Provider/Referrer or any of its employees, agents
                  or individuals assigned by the Service Provider/Referrer to
                  perform the Services under this Agreement.
                </p>
              </OrderList>

              {/* page break */}
              <div className="template_ifa_agreement_page_break" />

              <OrderList bullet={"9.7"}>
                <p>
                  It is hereby agreed and understood between the Parties that
                  the provisions of this Clause shall not limit, restrict or
                  preclude the Company from pursuing such further and other
                  legal actions, against the Service Provider/Referrer for any
                  breach or non-compliance of the terms of this Agreement.
                </p>
              </OrderList>

              <OrderList bullet={"9.8"}>
                <p>
                  Company shall not be liable for any damages, claims of loss of
                  profit, loss of investment or any such claims of a similar
                  nature, in the case of termination of the Agreement by any
                  mode or for whatever reasons.
                </p>
              </OrderList>
            </div>
          </OrderList>
          <OrderList bullet={"10."}>
            <p className="template_ifa_agreement_main_point_heading">
              CONSEQUENCES OF TERMINATION:
            </p>
            <p>
              Without prejudice to the above, in the event of the expiry or
              prior termination of the Agreement as aforesaid, the Service
              Provider/Referrer shall unless otherwise agreed upon, do the
              following forthwith;
            </p>
            <div className="template_ifa_agreement_p_justify">
              <OrderList bullet={"(a)"}>
                <p>
                  Ensure that all employees or other persons engaged by it and
                  who are deputed to perform the Services , return all
                  identity/visiting cards available in their possession, if any;
                </p>
              </OrderList>
              <OrderList bullet={"(b)"}>
                <p>
                  cease to use the name, trademark, symbol or logo of the
                  Company on any stationery, letterhead, document, nameplate,
                  furniture, representation or in any other manner;
                </p>
              </OrderList>
              <OrderList bullet={"(c)"}>
                <p>
                  return to the Company all stocks of stationery, promotional
                  material of any kind, sales literature, brochures and all
                  other items of similar nature; and
                </p>
              </OrderList>
              <OrderList bullet={"(d)"}>
                <p>
                  cease to represent Company or source Leads for Company, in any
                  manner whatsoever.
                </p>
              </OrderList>
              <OrderList bullet={"(e)"}>
                <p>
                  Return or destroy all Confidential Information in its
                  possession or control, and shall destroy or, if applicable,
                  erase any remaining copies of all such Confidential
                  Information, including any electronically stored copies. An
                  authorized person of the Service Provider/Referrer shall
                  certify in writing to such return and/or destruction and that
                  no copies of the Confidential Information have been retained.
                </p>
              </OrderList>
              <OrderList bullet={"(f)"}>
                <p>
                  hand-over to Company all documents, material and any other
                  property belonging to Company that may be in the possession of
                  the Service Provider/Referrer or any of its employees, agents
                  or individuals assigned by the Service Provider/Referrer to
                  perform the Services under this Agreement.
                </p>
              </OrderList>
              <OrderList bullet={"(g)"}>
                <p>
                  The Service Provider/Referrer shall preserve all the documents
                  necessary, relating to the Services, beyond any expiry or
                  termination of this Agreement in accordance with applicable
                  law.
                </p>
              </OrderList>
            </div>
          </OrderList>
          <OrderList bullet={"11."}>
            <p className="template_ifa_agreement_main_point_heading">
              {" "}
              CONFIDENTIALITY:
            </p>
            <div className="template_ifa_agreement_p_justify">
              <OrderList bullet={"(a)"}>
                <p>
                  The Service Provider/Referrer acknowledges and agrees that it
                  is aware of the sensitivity and secrecy obligations of the
                  Company in keeping the Lender information and transaction
                  records and ensures that neither the Service Provider/Referrer
                  nor any of its personnel will do any act violating the same.
                </p>
              </OrderList>

              {/* page break */}
              <div className="template_ifa_agreement_page_break" />

              <OrderList bullet={"(b)"}>
                <p>
                  It is hereby agreed by and between the Parties hereto that all
                  Confidential Information shall be kept confidential and shall
                  not be disclosed, except with the written permission of the
                  Company, to third parties or otherwise use, except in
                  connection with this Agreement and the performance of its
                  duties and obligations thereunder, except for disclosures
                  required by any law or required to be made to Governmental
                  agencies. All such data compiled by the Service
                  Provider/Referrer shall be the exclusive property of the
                  Company and the Service Provider/Referrer shall not have any
                  rights over the data whatsoever.
                </p>
              </OrderList>

              <OrderList bullet={"(c)"}>
                <p>
                  In the event of a breach or threatened breach by the Service
                  Provider/Referrer for the aforesaid clause, monetary damages
                  may not be an adequate remedy; therefore, Company shall be
                  entitled to injunctive relief in addition to monetary damages
                  to restrain the Service Provider/Referrer from any such
                  breach, threatened or actual.
                </p>
              </OrderList>
              <OrderList bullet={"(d)"}>
                <p>
                  The Service Provider/Referrer agrees to: (i) take all
                  necessary action to protect the Confidential Information
                  against misuse, loss, destruction, deletion and/or alteration;
                  (ii) Not to misuse or permit misuse directly or indirectly,
                  commercially exploit the Confidential Information for economic
                  or other benefit; (iii) Not to make or retain any copies or
                  record of any Confidential Information submitted by Company
                  other than as may be required for the performance of the
                  Service Provider/Referrer's obligation under this Agreement;
                  (iv) Notify Company promptly of any unauthorized or improper
                  use or disclosure of the Confidential Information; (v) Notify
                  Company promptly of any court order to disclose information
                  recorded on documents or disclose any confidential
                  information, it shall notify Company in writing, in sufficient
                  detail, immediately upon receipt of such court order, subpoena
                  or other legal or regulatory direction/ request or similar
                  process, in order to permit Company to make an application for
                  an appropriate protective order. Such notice shall be
                  accompanied by a copy of the Court order, subpoena or other
                  legal or regulatory direction/request or similar process. The
                  Service Provider/Referrer shall give an opportunity to Company
                  to move the appropriate court in appeal to obtain a stay order
                  if Company so desires; (vi) Return all the Confidential
                  Information that is in the custody of the Service
                  Provider/Referrer upon termination/expiry of this Agreement
                  along with all copies and replicas and facsimiles thereof;
                  (vii) Ensure that the Do Not Call Registry numbers are not
                  passed on to any unauthorized person/s or misused in any
                  manner.
                </p>
              </OrderList>
              <OrderList bullet={"(e)"}>
                <p>
                  The Service Provider/Referrer hereby unconditionally agrees
                  and undertakes that it shall not and that its personnel shall
                  not disclose the terms and conditions of this Agreement or
                  disclose the information submitted by the Company under this
                  Agreement to any third party unless such disclosure is
                  required by law or for the purpose of performing the Service
                  Provider/Referrer's obligations under this Agreement, provided
                  that prior to any such disclosure, Company's written consent
                  shall be obtained.
                </p>
              </OrderList>
              <OrderList bullet={"(f)"}>
                <p>
                  It shall be incumbent upon the Service Provider/Referrer to
                  undertake not to disclose any business related information of
                  Company to any third person and the Service Provider/Referrer
                  shall keep all knowledge of the business activities and
                  affairs of the Company strictly confidential and also to
                  ensure that neither the Service Provider/Referrer nor any of
                  its personnel directly or indirectly assist any third person
                  with the promotion of activities which may be prejudicial to
                  the interest or in competition to the activities of the
                  Company.
                </p>
              </OrderList>
              <OrderList bullet={"(g)"}>
                <p>
                  Access to Lender's information by staff of the Service
                  Provider/Referrer shall be limited to those areas where the
                  information is required in order to perform the services.
                </p>
              </OrderList>
              <OrderList bullet={"(h)"}>
                <p>
                  The provisions of this Section shall survive the termination
                  of this Agreement.
                </p>
              </OrderList>
            </div>
          </OrderList>

          {/* page break */}
          <div className="template_ifa_agreement_page_break" />

          <OrderList bullet={"12."}>
            <p className="template_ifa_agreement_main_point_heading">
              {" "}
              INTELLECTUAL PROPERTY RIGHT:{" "}
            </p>
            <p>
              Each Party acknowledges that nothing contained in this Agreement
              transfers to the other Party any right, title or proprietary
              interest (including without limitation any intellectual property
              rights), in any part which are the subject matter hereof, or any
              proprietary information (including without limitation any
              trademarks, service marks, trade names, or logos (<b>“Marks”</b>),
              trade secrets, knowhow, inventions, patents (including any
              applications, extensions, continuations, renewals and re-issues
              thereof), copyrights, designs and industrial designs).
            </p>
            <p>
              Parties agree that they shall use the proprietary marks of each
              other in relation to this Agreement. Service Provider/Referrer
              shall furnish to the Company all suchmaterials and advertisements
              prior to circulating or publishing and obtain prior written
              consent of the Company which consent shall not be unreasonably
              withheld.
            </p>
            <p>
              The Parties acknowledge and accept that any use of the
              intellectual property rights of the Company and/or Service
              Provider/Referrer on the materials, advertisements etc. relating
              to the Services and other matters incidental thereto or related
              therewith shall not constitute an infringement of the intellectual
              property rights of the Company and/or Service Provider/Referrer
              under applicable laws.
            </p>
            <p>
              The Parties further agree that neither Party shall acquire any
              right whatsoever through use in commerce in the intellectual
              property rights of the other Party on account of permitted use in
              terms of the above and all such rights pertaining to use and title
              of all intellectual property rights shall exclusively vest with
              such Party.
            </p>
            <p>
              The Parties covenant that they shall forthwith upon learning of
              any unauthorized reproduction, use, or modifications of the
              intellectual property rights of the other Party inform such Party
              of the same and will assist the said Party in taking all actions
              deemed necessary against such acts.
            </p>
            <p>
              The Parties covenant that the said usage of the Marks shall be
              during the tenure of the present arrangement and shall cease upon
              termination of the same. It is clearly understood between the
              Parties that there shall be no consideration for the use of the
              trademark/ tradename of the Service Provider/Referrer and/or the
              Company and the usage of the Service Provider/Referrer’s and/or
              Company’s Marks does not give any right in the information on the
              application form to the Service Provider/Referrer. Upon
              termination or dis-continuation of this arrangement on any grounds
              whatsoever, Service Provider/Referrer and/or Company shall
              forthwith: cease to use the name, trademark, symbol or logo of the
              Company and/or the Service Provider/Referrer on any stationery,
              letterhead, document, nameplate, furniture, marketing material,
              product brochures, representation or in any other manner;
            </p>
          </OrderList>
          <OrderList bullet={"13."}>
            <p className="template_ifa_agreement_main_point_heading">
              AUDIT AND INSPECTION RIGHTS{" "}
            </p>
            <div className="template_ifa_agreement_p_justify">
              <OrderList bullet="13.1">
                <p>
                  {" "}
                  Company shall be entitled to direct its internal auditors or
                  any other reputable, independent certified public auditing
                  firm or any authorised representative to inspect and audit all
                  the information, books and records of only those extracts
                  which relate to the Service provided by the Service Provider
                  under this Agreement in order to determine the compliance of
                  Service Provider with the company policies and applicable law,
                  rule and regulations.
                </p>
              </OrderList>
              <OrderList bullet="13.2">
                <p>
                  {" "}
                  Service Provider understands and agrees that upon receipt of a
                  notice from the Reserve Bank of India or any other Statutory /
                  Government Agency or any Representative thereof{" "}
                  <b>(“Regulator”)</b>, the Company shall allow Regulator to
                  access its records and other documents maintained by the
                  Company and which have been provided to the Service Provider
                  in order to provide the Services under this Agreement.
                </p>
              </OrderList>

              {/* page break */}
              <div className="template_ifa_agreement_page_break" />

              <OrderList bullet="13.3">
                <p>
                  Service Provider undertakes that it shall allow all the
                  persons authorized by Regulator to inspect and audit all the
                  information, books and records upon receipt of a notice from
                  the Company or Regulator.
                </p>
              </OrderList>
              <OrderList bullet="13.4">
                <p>
                  Service Provider shall on quarterly basis or as required by
                  the Company, provide a compliance report to the Company,
                  indicating its compliance with all the applicable laws, rules
                  and regulations in connection with the Services.
                </p>
              </OrderList>
            </div>
          </OrderList>
          <OrderList bullet="14.">
            <p className="template_ifa_agreement_main_point_heading">
              FORCE MAJEURE:
            </p>
            <p>
              Notwithstanding anything to the contrary in this Agreement, a
              Party hereto shall not be liable to the other Party for any loss,
              injury, delay, damages or other injury suffered or incurred by
              such other Party due to strikes, lock-outs, riots, storms, fire,
              explosions, acts of God, war whether declared or not, Government
              or police action or any other cause which is beyond the reasonable
              control of such Party nor shall any failure or delay by either
              Party hereto in the performance of any of its obligations under
              this Agreement due to one or more of such causes be construed as a
              breach of this Agreement or any provision of this Agreement, as
              the case may be, for the purpose of it being considered a default
              under the termination clause or for any other purpose.
            </p>
          </OrderList>
          <OrderList bullet="15.">
            <p className="template_ifa_agreement_main_point_heading">
              {" "}
              NON- WAIVER:
            </p>
            <p>
              No admission or delay on the part of any Party hereto in requiring
              the due and punctual fulfillment by any other Party or Parties
              hereto of the obligations of such other Party hereunder shall be
              deemed to constitute a waiver by omitting or delaying Party of any
              of its rights to require such due and punctual fulfillment.
            </p>
          </OrderList>
          <OrderList bullet="16.">
            <p className="template_ifa_agreement_main_point_heading">
              REPRESENTATIONS AND WARRANTIES:
            </p>
            <div className="template_ifa_agreement_p_justify">
              <OrderList bullet="a.">
                <p>
                  The Service Provider/Referrer hereby represents that it has
                  the authority, skill, experience and resources to render
                  services hereunder and shall so render the services in an
                  ethical and bona fide manner and in compliance with all laws
                  and regulations.
                </p>
              </OrderList>
              <OrderList bullet="b.">
                <p>
                  The Service Provider/Referrer shall comply with all applicable
                  laws including the laws in relation to the “National Do Not
                  Call Registry” (the <b>“DNC Registry”</b>).
                </p>
              </OrderList>
              <OrderList bullet="c.">
                <p>
                  The Service Provider/Referrer is duly organized, validly
                  existing, and in good standing under the laws of India and has
                  full power and authority to enter into this Agreement and to
                  perform its obligations under this Agreement.
                </p>
              </OrderList>
              <OrderList bullet="d.">
                <p>
                  The Service Provider/Referrer has obtained all necessary
                  licenses, permissions, registrations (as applicable) including
                  but not limited to Shops and Establishment Act, for all its
                  office locations and Service Tax registration. In addition,
                  the Service Provider/Referrer shall make necessary nominations
                  under the Payment of Gratuity Act, 1972, Employees Provident
                  Fund & Miscellaneous Provisions Act, 1952, Employee State
                  Insurance Act, 1948, etc. for all his employees with the
                  necessary authorities.
                </p>
              </OrderList>
              <OrderList bullet="e.">
                <p>
                  The Service Provider/Referrer shall take and has appropriate
                  mechanisms and means to ensure adequate precautions to protect
                  the privacy and confidentiality of all data pertaining to
                  Company, the Lenders, any other third party in relation to
                  this Agreement or the services and shall take appropriate
                  precautions not to breach the privacy of Company, the Lenders,
                  or any third party during the course of performance of its
                  obligations herein.
                </p>
              </OrderList>
            </div>
          </OrderList>

          {/* page break */}
          <div className="template_ifa_agreement_page_break" />

          <OrderList bullet="17.">
            <p className="template_ifa_agreement_main_point_heading">
              INDEMNIFICATION
            </p>
            <div className="template_ifa_agreement_p_justify">
              <OrderList bullet="17.1">
                <p>
                  The Service Provider/Referrer shall at their own expense,
                  indemnify, defend and hold harmless Company and its officers,
                  directors, personnel, representatives, consultants, nominees,
                  designees from and against any and all liability suffered or
                  incurred (including but not limited to liabilities,
                  judgments,Page 10 of 15damages, losses, claims, costs and
                  expenses, including attorney’s fees and expenses) or any other
                  loss that may occur, arising from or relating to: (i)
                  Non-performance, inadequate performance, provision of
                  deficient services, breach, misconduct or negligence by the
                  Service Provider/Referrer and/ or its personnel of any of the
                  terms, conditions, covenants, representations, undertakings,
                  obligations or warranties under this Agreement, including but
                  not limited to the obligations of the Service
                  Provider/Referrer pertaining to data protection,
                  confidentiality and intellectual property rights of Company ,
                  or any guidelines issued by Company to the Service
                  Provider/Referrer from time to time; (ii) Acts, omissions,
                  errors, representations, misrepresentations, misconduct,
                  negligence of the Service Provider/Referrer and/or its
                  personnel in performance of its obligations under this
                  Agreement; (iii) Loss, misappropriations, misuse or damage to
                  the documents or instruments/things that are in the possession
                  of the Service Provider/Referrer or its personnel or any other
                  persons engaged by the Service Provider/Referrer or within the
                  control of the Service Provider/Referrer; (iv) Any loss,
                  damages to the Lenders through any acts or omissions of the
                  Service Provider/Referrer and/or its personnel; (v)
                  Contravention of any law, as may be applicable from time to
                  time, or industry practice, or violation of any policies of
                  the Company to the extent it is applicable to the Service
                  Provider/Referrer to perform its obligation under this
                  Agreement.
                </p>
              </OrderList>
              <OrderList bullet="17.2">
                <p>
                  In the event of any claims being made on Company, the Service
                  Provider/Referrer undertakes to pay on first demand made by
                  Company of any amount on this account without any demur,
                  reservation, contest, protest whatsoever within 2 (Two)
                  working days of the demand being made. The Company shall also
                  be entitled to settle any or all Claims made on it and recover
                  the amount so paid from the Service Provider/Referrer.
                </p>
              </OrderList>
              <OrderList bullet="17.3">
                <p>
                  The Service Provider/Referrer hereby authorizes Company to
                  make deductions to any sum, being the cost, expenses, loss or
                  damage suffered by it on account of any default or breach
                  committed by the Service Provider/Referrer or its employees,
                  agents, representatives, as mentioned hereinabove or
                  hereinafter. The decision of the Company as to the amount of
                  cost, expenses, loss or damage suffered shall be final and
                  binding on the Service Provider/Referrer and the Service
                  Provider/Referrer shall not protest, contest the same on any
                  ground whatsoever.
                </p>
              </OrderList>
              <OrderList bullet="17.4">
                <p>
                  The Service Provider/Referrer shall not set up defense or
                  claim in any suit, plaint, petition, complaint, written
                  statement, application to the effect that the employees of the
                  Service Provider/Referrer are the employees of the Company and
                  in the event any defense is set up the same shall be a fraud
                  upon the court or authority where such defense or claim is set
                  up.
                </p>
              </OrderList>
            </div>
          </OrderList>
          <OrderList bullet="18.">
            <p className="template_ifa_agreement_main_point_heading">
              CONSTRUCTION:
            </p>
            <div className="template_ifa_agreement_p_justify">
              <OrderList bullet="18.1">
                <p>
                  This Agreement sets forth the entire Agreement and
                  understanding among the Parties with respect to the subject
                  matter hereof and merges all discussions and negotiations
                  among them, and none of the Parties shall be bound by any
                  conditions, understandings or representations with respect to
                  such subject matter other than those expressly provided
                  therein or duly set forth on or subsequent to the date hereof
                  in writing and signed by a proper and duly authorized officers
                  of the Parties to be bound thereby.
                </p>
              </OrderList>

              {/* page break */}
              <div className="template_ifa_agreement_page_break" />

              <OrderList bullet="18.2">
                <p>
                  The Articles, Sections, paragraphs and other headings
                  contained in this Agreement are for general reference and
                  guidance and shall not be conclusive as to the meaning or
                  interpretation of this Agreement.
                </p>
              </OrderList>
            </div>
          </OrderList>
          <OrderList bullet="19.">
            <p className="template_ifa_agreement_main_point_heading">
              COUNTERPARTS:
            </p>
            <p>
              This Agreement shall be executed simultaneously in 2 (Two) or more
              counterparts each of which shall be deemed to be an original but
              all of which together shall constitute 1 (One) instrument.
            </p>
          </OrderList>
          <OrderList bullet="20.">
            <p className="template_ifa_agreement_main_point_heading">
              ENTIRE AGREEMENT AND AMENDMENTS:{" "}
            </p>
            <div className="template_ifa_agreement_p_justify">
              <OrderList bullet="20.1">
                <p>
                  Save as provided herein, this Agreement (including Annexures
                  and any Addendum) supersedes and nullifies all previous
                  arrangements/discussions/understandings between the Parties in
                  relation to the Services or otherwise. However, the Parties
                  understands and agrees that all the transactions, discussions
                  and commitments entered into on the basis of any earlier
                  executed agreement between the Company and the Service
                  Provider/Referrer shall be governed by the earlier agreement
                  only till the date of execution of this Agreement and the
                  transactions entered into after the execution of this Master
                  Service Agreement shall be governed by this Agreement from the
                  date of its execution.
                </p>
              </OrderList>
              <OrderList bullet="20.2">
                <p>
                  This Agreement shall not be amended, altered or modified
                  except by an instrument in writing expressly referring to this
                  Agreement and signed by the authorized officers of the
                  Parties.
                </p>
              </OrderList>
            </div>
          </OrderList>
          <OrderList bullet="21.">
            <p className="template_ifa_agreement_main_point_heading">
              ASSIGNMENT
            </p>
            <div className="template_ifa_agreement_p_justify">
              <OrderList bullet="21.1">
                <p>
                  The Company may at any time, at its sole discretion assign,
                  cause to assign, and/or transfer any of its rights, benefits,
                  obligations or duties accruing under this Agreement to any of
                  its affiliates, subsidiaries, group Companies or holding
                  Company and the Service Provider/Referrer hereby expressly
                  agrees that in that event, the Company is not required to
                  obtain any prior permission from the Service
                  Provider/Referrer.
                </p>
              </OrderList>
              <OrderList bullet="21.2">
                <p>
                  The Service Provider/Referrer shall not assign, transfer or
                  subcontract any of its rights and responsibilities contained
                  in this Agreement to any third-party without the prior written
                  permission of Company, which Company may deny at its absolute
                  discretion and if Company gives such prior written permission
                  (which permission may be of a general or specific nature), it
                  shall not be construed as waiver of any accrued rights and/or
                  liabilities and the Service Provider/Referrer shall be fully
                  responsible for all acts and omissions of its sub-contractors
                  or sub-agents.
                </p>
              </OrderList>
            </div>
          </OrderList>
          <OrderList bullet="22.">
            <p className="template_ifa_agreement_main_point_heading">
              ADDITIONAL DOCUMENTS:
            </p>
            <p>
              Each Party hereto shall promptly execute and deliver such
              additional documents as are reasonably required by any other Party
              hereto for the purpose of implementing this Agreement. Provided
              that no such document shall be inconsistent with the provisions
              hereof.
            </p>
          </OrderList>

          <OrderList bullet="23.">
            <p className="template_ifa_agreement_main_point_heading">
              INDEPENDENT PARTIES:
            </p>

            {/* page break */}
            <div className="template_ifa_agreement_page_break" />

            <div className="template_ifa_agreement_p_justify">
              <OrderList bullet="23.1">
                <p>
                  The Parties shall act in all matters pertaining to this
                  Agreement as independent Parties. The Agreement is being
                  entered into on a principal-to-principal basis and does not
                  and shall not be deemed to make either Party an agent, partner
                  or joint venture partner of the other or any analogous
                  relationship. No contention to the contrary will be raised at
                  any time by either Party. It is expressly agreed by the
                  Parties that Company shall not be liable or responsible in any
                  manner nor be liable to pay any monetary compensation in the
                  event of the death of, or in the event of any injury sustained
                  by any employee, agent, representative of the Service
                  Provider/Referrer during performance of their functions or
                  rendering services under this Agreement.
                </p>
              </OrderList>
              <OrderList bullet="23.2">
                <p>
                  The Service Provider/Referrer shall ensure that all visiting
                  cards of its employee mention the name/entity of the Service
                  Provider/Referrer clearly and that all identity cards issued
                  to its employees clearly display the name/entity of the
                  Service Provider/Referrer and the designation or work
                  profile/nature of work of the relevant employee in the Company
                  /Business entity of the Service Provider/Referrer. The
                  requirements of this clause shall, so far as engagement of
                  non-employees by the Service Provider/Referrer is concerned,
                  be applicable in like manner as it would have applied in the
                  context of employees.
                </p>
              </OrderList>
              <OrderList bullet="23.3">
                <p>
                  All bills/invoices and other communications from the Service
                  Provider/Referrer to any party must necessarily carry/show the
                  name/entity of the Service Provider/Referrer only and not
                  mention the name of Company.
                </p>
              </OrderList>
              <OrderList bullet="23.4">
                <p>
                  It is agreed between the Parties that this Agreement is on a
                  principal-to-principal basis and does not create any
                  employer-employee or principal-agent relationship between the
                  Company and the Service Provider/Referrer.
                </p>
              </OrderList>
            </div>
          </OrderList>
          <OrderList bullet="24.">
            <p className="template_ifa_agreement_main_point_heading">
              {" "}
              REPRESENTATIONS ON AUTHORITY:{" "}
            </p>
            <p>
              Each signatory to this Agreement represents and warrants that he
              is duly authorized by the Party for and on whose behalf, he is
              signing this Agreement to execute the same in a manner binding
              upon said Party and that all corporate and or legal approvals and
              procedures necessary for vesting such authority in him have been
              duly obtained and complied with.{" "}
            </p>
          </OrderList>
          <OrderList bullet="25.">
            <p className="template_ifa_agreement_main_point_heading">
              {" "}
              PARTIAL INVALIDITY:{" "}
            </p>
            <p>
              It is expressly agreed and declared that if, for any reason
              whatsoever after execution hereof, any Court of competent
              jurisdiction holds any provision hereof to be illegal, void or
              unenforceable, such provision shall be of no force and effect, but
              the illegality or unenforceability of such provision shall have no
              effect upon and shall not impair the legality, validity and
              enforceability of any other provisions of this Agreement.{" "}
            </p>
          </OrderList>
          <OrderList bullet="26.">
            <p className="template_ifa_agreement_main_point_heading">
              {" "}
              GOVERNING LAW:
            </p>
            <p>
              This Agreement shall be governed by and construed in accordance
              with the laws of India.{" "}
            </p>
          </OrderList>

          <OrderList bullet="27.">
            <p className="template_ifa_agreement_main_point_heading">
              ARBITRATION:
            </p>

            {/* page break */}
            <div className="template_ifa_agreement_page_break" />

            <div className="template_ifa_agreement_p_justify">
              <OrderList bullet="27.1">
                <p>
                  Any dispute, controversy or difference which may arise between
                  the Parties out of or in relation to or in connection with
                  this Agreement, shall first be resolved by amicable
                  negotiations within 30 (Thirty) days of the commencement of
                  discussions. If no resolution is reached by the Parties within
                  30 (Thirty) days of commencement of discussions, the dispute
                  shall be submitted for exclusive resolution by arbitration
                  under the Indian Arbitration and Conciliation Act, 1996, or
                  any statutory modification or re-enactment thereof, by a sole
                  arbitrator who is mutually appointed by the Parties. The
                  arbitration shall be held in Delhi only and the language to be
                  used in the arbitration proceedings shall be English. The
                  existence of any dispute shall not release the Parties of
                  their respective obligations under this Agreement.
                </p>
              </OrderList>

              <OrderList bullet="27.2">
                <p>
                  Each Party further agrees that it shall not commence or
                  maintain any suit or legal proceeding concerning a dispute
                  hereunder until such dispute has been finally settled in
                  accordance with the arbitration procedure provided for herein
                  and then only for enforcement of the arbitral award, if any.
                </p>
              </OrderList>
              <OrderList bullet="27.3">
                <p>
                  Notwithstanding the aforesaid, each Party shall have the right
                  to institute judicial proceedings against the other Party or
                  anyone acting through or under such Party in order to enforce
                  the instituting Party’s rights hereunder through amendment of
                  contract, specific performance, injunction or similar
                  equitable relief.
                </p>
              </OrderList>
              <OrderList bullet="27.4">
                <p>
                  The courts of Delhi shall have exclusive jurisdiction to try
                  and entertain any proceedings arising out of or in relation to
                  this Agreement.
                </p>
              </OrderList>
            </div>
          </OrderList>
          <OrderList bullet="28.">
            <p className="template_ifa_agreement_main_point_heading">
              NOTICES:
            </p>
            <p>
              All notices and other communications in respect of this Agreement
              shall be given in writing in English by registered mail, postage
              prepaid, or by email to the Party entitled thereto at its address
              set forth below, or such other address as it shall hereafter
              designate for this purpose:
            </p>
            <p>
              For the <b>Service Provider/Referrer:</b>
            </p>
            <p>Kind Attn :</p>
            <p>Address : {props.address}</p>
            <p>Email : {props.email}</p>
            <br />
            <p>
              For the <b>Company:</b>
            </p>
            <p>Kind Attn : Head - Legal & Compliance</p>
            <p>
              Address : The Circle, 3rd Floor, Huda City Centre Metro station,
              Sector 29, Gurugram, Haryana 122002
            </p>
            <p>Email : statutory.compliance@arthmate.com</p>
            <br />
            <p>
              or such other address as the Party to whom such writing is to be
              given shall have last notified to the Party giving the same in the
              manner provided in this Clause.
            </p>

            {/* page break */}
            <div className="template_ifa_agreement_page_break" />

            <p>
              The notice will be deemed to have been served on the Service
              Provider/Referrer on proof of having posted/delivered the notice
              to the addresses given herein. The notice will be considered to be
              received by Company on actual receipt of the said notice.
            </p>
          </OrderList>
          <OrderList bullet="29.">
            <p className="template_ifa_agreement_main_point_heading">
              COMPLIANCE WITH LAWS
            </p>
            <div className="template_ifa_agreement_p_justify">
              <OrderList bullet="29.1">
                <p style={{fontSize:'11px'}}>
                  At all times Service Provider/Referrer shall keep itself fully
                  informed of applicable laws, ordinances, regulations, rules
                  and orders of the land, in performances of the Services
                  including procurement and renewal of licenses, permits, and
                  certificates and payment of taxes where required.
                </p>
              </OrderList>

              <OrderList bullet="29.2">
                <p style={{fontSize:'11px'}}>
                  The Service Provider/Referrer shall not hold Company liable
                  for any non-compliance as set out in clause 29.1 above and the
                  Service Provider/Referrer shall, at its own expense,
                  indemnify, defend and hold harmless Company and Affiliates of
                  Company, their respective officers, directors, personnel,
                  representatives, consultants, nominees, designees from and
                  against any damages, losses, claims, costs and expenses
                  pertaining to the non-compliance of the clause above.
                </p>
              </OrderList>
              <OrderList bullet="29.3">
                <p style={{fontSize:'11px'}}>
                  The Service Provider/Referrer shall at its own cost, obtain
                  for itself, and renew in accordance with requirement/law,
                  licenses including those under the Contract Labour (Abolition
                  and Regulation) Act, 1970, registrations, permissions, etc.
                  and submit such returns, papers or documents to any government
                  or local authority as may be required by Law to be obtained or
                  submitted. All taxes, fees, charges and other outgoings
                  incurred or to be incurred under any Laws shall be at the
                  complete cost and responsibility of the Service
                  Provider/Referrer. Any loss caused, penalty imposed or legal
                  obligation created on account of the failure to adhere to any
                  Laws in regard to submission of such returns as may be
                  required, shall be borne by the Service Provider/Referrer and
                  the Company shall not be liable for any damages or payments in
                  this regard.
                </p>
              </OrderList>
              <OrderList bullet="29.4">
                <p style={{fontSize:'11px'}}>
                  The Service Provider/Referrer shall ensure to register, obtain
                  and renew in accordance with requirement/law, licenses (as
                  applicable) and comply with the provisions and requirements of
                  the relevant Labour Laws including but not limited to Minimum
                  Wages Act, Provident Fund Act, Employees’ State Insurance Act
                  etc. and other applicable statutory enactment’s including but
                  not limited to the Bombay Shops and Establishment Act, 1948,
                  for all its office locations (which office locations, as well
                  as any other addresses of the Service Provider/Referrer shall
                  be maintained by the Service Provider/Referrer independent of
                  and in addition to the offices of the Company), and will
                  provide Services in full compliance with all laws, ordinances,
                  regulations, rules and order of the land. The Service
                  Provider/Referrer shall ensure that it has its own independent
                  registrations and employer code numbers under the Provident
                  Fund Act and the Employees State Insurance Act.
                </p>
              </OrderList>
            </div>
          </OrderList>
        </div>

        <div className="template_ifa_agreement_p_justify">
          <p style={{fontSize:'11px'}}>
            IN WITNESS WHEREOF, the Parties hereto have set and subscribed their
            hands through their respective duly authorized representatives as of
            the date first above written.
          </p>
          <div className="template_ifa_agreement_authority">
            <div>
              <p style={{fontSize:'11px'}}>
                <b>SIGNED</b> and <b>DELIVERED</b> By the within named,{" "}
                <b>Company</b> through its authorized representative:
              </p>
              <p style={{fontSize:'11px'}}>
                Signature :
                <img
                  src={props.signature}
                  style={{ height: "60px", width: "auto" }}
                />
              </p>
              <p style={{fontSize:'11px'}}>
                Name : <b>Ranjan Kant</b>
              </p>
              <p style={{fontSize:'11px'}}>
                Title : <b>Director</b>
              </p>
            </div>
            <div>
              <p style={{fontSize:'11px'}}>
                <b>SIGNED</b> and <b>DELIVERED</b> By the within named{" "}
                <b>Service Provider / Referrer</b>, through its authorized
                representative:
              </p >
              <p style={{fontSize:'11px'}}>Signature : </p>
              <p style={{fontSize:'11px'}}>Name : {props.nameOfSignatory}</p>
              <p style={{fontSize:'11px'}}>Title : </p>
            </div>
          </div>
        </div>

        {/* page break */}
        <div className="template_ifa_agreement_page_break" />

        <div id="second_last_page">
          <div className="template_ifa_agreement_bottom_heading">
            <span className="template_ifa_agreement_underline">
              <span className="template_ifa_agreement_heading_text">
                ANNEXURE - I
              </span>
            </span>
            <br />
            <br />
            <span className="template_ifa_agreement_underline">
              <span className="template_ifa_agreement_heading_text">
                FEE STRUCTURE
              </span>
            </span>
          </div>

          <p className="template_ifa_agreement_p_justify">
            This <b>ANNEXURE I</b> is annexed to and is made an integral part of
            this Agreement{" "}
          </p>
          <p className="template_ifa_agreement_p_justify">
            <b>FEE STRUCTURE & the applicable Terms & conditions:</b>
          </p>
          <br />
          <p className="template_ifa_agreement_p_justify">
            <b>Company</b> shall pay to <b>the Service Provider/Referrer</b> a
            fixed fee or a variable fee that would be communicated periodically.
            The fee would be paid towards facilitating lender on-boarding and
            investments through the platform:
          </p>
          <br />
          <div className="template_ifa_agreement_p_justify">
            <OrderList bullet="1.">
              <p>
                The Service Provider/Referrer will receive Fees based on the
                Amount mobilized for plans that have a lock-in period and based
                on AUM for any other plan.
              </p>
            </OrderList>
            <OrderList bullet="2.">
              <p>
                The fee calculation for the AUM based plans will be on daily
                average invested value (excluding earnings).
              </p>
            </OrderList>
            <OrderList bullet="3.">
              <p>
                The fee calculation for plans with lock-in would be based on the
                amount invested in the plans excluding the earnings from them.
              </p>
            </OrderList>
            <OrderList bullet="4.">
              <p>
                The payouts, subject to a minimum value of Rs.1,000 will be paid
                on a monthly basis. If the fees payable to the Service Provider
                in the current month is less than INR 1,000/-, then the payment
                will be released in the month where the payout exceeds Rs.1,000.
              </p>
            </OrderList>
            <OrderList bullet="5.">
              <p>
                The said payment of fee shall be subject to deduction of Income
                Tax at source at the applicable rates and other deductions, if
                any under the agreement.
              </p>
            </OrderList>
            <OrderList bullet="6.">
              <p>
                The above fee structure is valid from the effective date of the
                Agreement.
              </p>
            </OrderList>
          </div>

          <div className="template_ifa_agreement_authority">
            <div>
              <img
                src={props.signature}
                style={{ height: "60px", width: "auto" }}
              />
            </div>
            <div>
              <img src={""} />
            </div>
          </div>

          <div className="template_ifa_agreement_authority">
            <div>
              <p>
                Signature on behalf of <b>Company</b>
              </p>
            </div>
            <div>
              <p>
                Signature on behalf of <b>Service Provider / Referrer</b>
              </p>
            </div>
          </div>
        </div>

        {/* page break */}
        <div className="template_ifa_agreement_page_break" />

        <div id="last_page">
          <div className="template_ifa_agreement_bottom_heading">
            <span className="template_ifa_agreement_underline">
              <span className="template_ifa_agreement_heading_text">
                ANNEXURE - II
              </span>
            </span>
            <br />
            <br />
            <span className="template_ifa_agreement_underline">
              <span className="template_ifa_agreement_heading_text">
                SCOPE OF SERVICES
              </span>
            </span>
          </div>

          <p className="template_ifa_agreement_p_justify">
            This <b>ANNEXURE II</b> is annexed to and is made an integral part
            of this Agreement{" "}
          </p>

          <div className="template_ifa_agreement_p_justify">
            <OrderList bullet="1.">
              <p>
                The Service Provider/Referrer shall approach, discuss and
                procure the Lenders for and behalf of the Company. All forms of
                interaction with prospects whether by way of telephone calls,
                personal visits or any other form shall be based on the terms
                communicated to the Service Provider/Referrer from time to time.
              </p>
            </OrderList>
            <OrderList bullet="2.">
              <p>
                The Service Provider/Referrer or any of its employees shall
                maintain the highest professional integrity and ethical code of
                conduct in their dealings with the Lenders.
              </p>
            </OrderList>
            <OrderList bullet="3.">
              <p>
                The Service Provider/Referrer shall assist or initiate customer
                registration process on the platform. The Service
                Provider/Referrer shall ensure the authenticity of the
                registered user and relevant documents wherever applicable.
              </p>
            </OrderList>
            <OrderList bullet="4.">
              <p>
                The Service Provider/Referrer shall perform verification with
                regard to the information provided by the prospective Lender
                including the address.
              </p>
            </OrderList>
            <OrderList bullet="5.">
              <p>
                The Service Provider/Referrer shall not collect cash from any
                prospective Lender.
              </p>
            </OrderList>
            <OrderList bullet="6.">
              <p>
                The Service Provider/Referrer shall procure and provide to
                Company any additional information as and when required.
              </p>
            </OrderList>
            <OrderList bullet="7.">
              <p>
                It is clearly understood by the Service Provider/Referrer, that
                Company may at its sole discretion, accept or reject the Leads
                without any liability/obligation towards the Service
                Provider/Referrer and/or towards the Lead.
              </p>
            </OrderList>
            <OrderList bullet="8.">
              <p>
                It is hereby clarified and agreed that the application form and
                the information contained therein shall not be returned by
                Company to the Service Provider/Referrer and/or the prospective
                Lender in any circumstances, and that the application shall
                become the property of the Company and the Company shall be
                entitled to use the said information in any manner. It shall be
                the responsibility of the Service Provider/Referrer to inform
                the prospective Lender of this fact in advance before receipt of
                the application.
              </p>
            </OrderList>
            <OrderList bullet="9.">
              <p>
                Under no circumstances, the Service Provider/Referrer or any of
                its employees shall make any false promise, commit fraud as
                determined by Company, or statement or offer or representation
                to the prospective Lender including but not limited to fee
                waivers/referrals/gifts etc. The Service Provider/Referrer shall
                be solely responsible for the same and would make good the loss
                suffered by Company on account of the aforesaid representations
                and statements etc. and further agree to indemnify Company in
                this regard.
              </p>
            </OrderList>

            {/* page break */}
            <div className="template_ifa_agreement_page_break" />

            <OrderList bullet="10.">
              <p>
                In the event of any fraud perpetrated by the Service
                Provider/Referrer or any of its employees including but not
                limited to sales executive the Service Provider/Referrer shall
                be solely responsible and liable for all the cost, expenses and
                consequences thereof which Company may incur or sustain in this
                regard. The Service Provider/Referrer agrees and undertakes to
                extend all its co-operation to Company in case, Company decides
                to initiate legal action or ask the Service Provider/Referrer to
                initiate legal action.
              </p>
            </OrderList>
          </div>

          <div className="template_ifa_agreement_authority">
            <div>
              <img
                src={props.signature}
                style={{ height: "60px", width: "auto" }}
              />
            </div>
            <div>
              <img src={""} />
            </div>
          </div>

          <div className="template_ifa_agreement_authority">
            <div>
              <p>
                Signature on behalf of <b>Company</b>
              </p>
            </div>
            <div>
              <p>
                Signature on behalf of <b>Service Provider / Referrer</b>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IfaAgreement;
