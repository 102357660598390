import timeoutfailed from "../../assets/images/timeoutfailed.svg";
import "./pan-validation-failed.css";

const PanValidationFailed = () => {
  return (
    <div className="add_client_pan_not_verified_container">
      <img src={timeoutfailed} alt="Failure" className="check_mark" />
      <p className="add_client_pan_not_verified_text">
        PAN verification failed
      </p>
      <div className="add_client_pan_not_verified_support">
        <p className="add_client_pan_not_verified_support_text">
          To know more, please reach out to us at
        </p>
        <a
          href="mailto: support@1invest.in"
          className="add_client_pan_not_verified_support_email"
        >
          support@1invest.in
        </a>
      </div>
    </div>
  );
};

export default PanValidationFailed;
