import "./paytm.css";
import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import SampleToast from "../sample-toast/sample-toast";
import { errorToast, loadScript } from "../../utils/helper";
import FullScreenLoader from "../full-screen-loader/full-screen-loader";
import { eventLog } from "../../utils/firebase";
import { IFA_Payment_API } from "../../services/api";
const api = new IFA_Payment_API();
const config = require("../../config/settings");

const defaultStyle = {
  //to be replaced by the desired values
  backgroundColor: "#ffffff",
  color: "#3b8794",
  fontFamily: "Lato",
  fontSize: "15px",
  errorColor: "#ff0000",
  theme: "light",
};

const Paytm = (props) => {
  console.log("props::", props);
  const [order, setOrder] = useState({});
  const [render, setRender] = useState(false);
  const [fullLoader, setFullLoader] = useState(false);
  const [amount, setAmount] = useState(props.data.amount);
  const stage =
    process.env.REACT_APP_ENV == "production"
      ? "prod"
      : process.env.REACT_APP_ENV == "development"
      ? "dev"
      : "me";

  useEffect(() => {
    generateOrder();
  }, []);

  useEffect(() => {
    loadScript(
      `https://securegw${
        process.env.REACT_APP_ENV == "production" ? "" : "-stage"
      }.paytm.in/merchantpgpui/checkoutjs/merchants/${
        process.env.REACT_APP_ENV == "production"
          ? "LENRNV71647944499761"
          : "OypeDw73733757219174"
      }.js?${new Date().toDateString()}`
    );

    let element = (
      <meta
        name="viewport"
        content="width=device-width, height=device-height, initial-scale=1.0, maximum-scale=1.0"
      />
    );
    document.head.insertAdjacentHTML("beforeend", element);
  }, []);

  useEffect(() => {
    if (order.order_id) {
      setRender(true);
    }
  }, [order]);

  // useEffect(() => {
  //   if (order.order_id) {
  //     // document.paytm.submit();
  //   }
  // }, [render]);

  console.log("order::", order); 

  const generateOrder = async () => {
    let params = {
      amount: props.data.amount,
      // vpa: props.data.vpa,
      Mode: "IFA",
      UUID: props.data.UUID,
      investment_request_uuid: props.data.investment_request_uuid,
    };

    setFullLoader(true);
    await api.getSessionPaymentId(params).then((res) => {
      console.log("resp of ordr api", res);
      if (res.kind === "ok") {
        console.log("response::", res);
        eventLog({
          type: "payment_gateway_page",
          action: {
            trigger: "true",
          },
        });
        setOrder(res.data);
      } else {
        eventLog({
          type: "payment_gateway_page",
          action: {
            trigger: "false",
          },
        });
        setFullLoader(false);
        errorToast("Order Id not generated");
      }
    });
  };

  //function to call paytm js checkout page
  const onScriptLoad = (txnToken, orderId, amount) => {
    var config = {
      root: "",
      flow: "DEFAULT",

      merchant: {
        name: "Arthmate direct",
        logo: "https://arthmate-p2p-public-assets.s3.ap-south-1.amazonaws.com/images/1invest-logo-color-dark.png",
        redirect: true,
      },
      style: {
        headerBackgroundColor: "#8dd8ff",
        headerColor: "#3f3f40",
      },
      data: {
        orderId: orderId,
        token: txnToken,
        tokenType: "TXN_TOKEN",
        amount: amount,
      },
      handler: {
        transactionStatus: function (data) {
          console.log("payment status ", data);
        },
        notifyMerchant: function (eventName, data) {
          if (eventName == "APP_CLOSED") {
            window.location.reload();
            setRender(false);
            setFullLoader(false);
          }
          
          if (eventName == "SESSION_EXPIRED") {
            // alert("Your session has expired!!");
            errorToast(eventName);
            // window.location.reload()
          }
        },
      },
    };

    if (window.Paytm && window.Paytm.CheckoutJS) {
      // initialze configuration using init method
      // window.Paytm.CheckoutJS.onLoad(function excecuteAfterCompleteLoad() {
      window.Paytm.CheckoutJS.init(config)
        .then(function onSuccess() {
          // after successfully update configuration invoke checkoutjs
          window.Paytm.CheckoutJS.invoke();
        })
        .catch(function onError(error) {
          console.log("Error => ", error);
        });
    }
    //  )}
  };

  return (
    <React.Fragment>
      {render ? (
        <React.Fragment>
          {/* <form
            method="post"
            type="redirect"
            action={
              stage == "me"
                ? `https://securegw-stage.paytm.in/theia/api/v1/processTransaction?mid=${config.paytm.me.mid}&orderId=${order.order_id}`
                : stage == "dev"
                ? `https://securegw-stage.paytm.in/theia/api/v1/processTransaction?mid=${config.paytm.dev.mid}&orderId=${order.order_id}`
                : `https://securegw.paytm.in/theia/api/v1/processTransaction?mid=${config.paytm.prod.mid}&orderId=${order.order_id}`
            }
            name="paytm"
          >
            <input
              type="hidden"
              name="mid"
              value={
                stage == "me"
                  ? config.paytm.me.mid
                  : stage == "dev"
                  ? config.paytm.dev.mid
                  : config.paytm.prod.mid
              }
            />
            <input type="hidden" name="orderId" value={order.order_id} />
            <input
              type="hidden"
              name="txnToken"
              value={order.transaction_token}
            />
            <input type="hidden" name="paymentMode" value="UPI" />
            <input type="hidden" name="payerAccount" value={props.data.vpa} />
          </form> */}
          {/* paytm js checkout */}
          <div
            onLoad={onScriptLoad(
              order.transaction_token,
              order.order_id,
              amount
            )}
          ></div>
          {/* toast container */}
        </React.Fragment>
      ) : (
        <div />
      )}
      {fullLoader ? <FullScreenLoader /> : <div />}
      <SampleToast />
    </React.Fragment>
  );
};

export default Paytm;
