import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Forms_API } from "../../services/api";
import "./form.css";
/* eslint-disable react/prop-types */

function Payment(props) {
  // const [agree, setAgree] = useState(false);

  // const checkboxHandler = () => {
  //     setAgree(!agree);
  // }
  const navigate = useHistory();
  const [loanId, setLoanId] = useState(props.location.state.loan_id);

  useEffect(() => {
    // props.showCommonHeader(true);
    // props.showCommonSidebar(true);
    console.log(props, "props");
    // eslint-disable-next-line
  }, []);

  const [agreement_link, setAgreementLink] = useState(
    "https://arthmate-p2p-data.s3.ap-south-1.amazonaws.com/readable/Agreements.pdf"
  );
  const api = new Forms_API();

  const [partnerId, setPartnerId] = useState(
    "4b41454d-fe8d-11eb-875f-02a64dc07394"
  );
  const handleSubmit = (e) => {
    const data = {
      partner_id: partnerId,
      loan_id: loanId,
      agreement_link: agreement_link,
    };
    console.log(data, "payment");
    api.payment(data).then((res) => {
      console.log(res, "paymentresponse");
      res.kind == "ok" &&
        navigate.push({
          pathname: "/repayment",
          state: { loan_id: loanId },
        });
    });
    e.preventDefault();
  };

  const isValid = partnerId == "" || loanId == "" || agreement_link == "";
  return (
    <div className="forms">
      <header className="form-header">
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <h3> Payment agreement </h3>
          <div className="textFieldDiv">
            <label>Partner Id:</label>
            <input
              name="partner_id"
              type="text"
              value={partnerId}
              required
              onChange={(e) => {
                setPartnerId(e.target.value);
              }}
            />
            <br />
          </div>
          <div className="textFieldDiv">
            <label>Loan Id:</label>
            <input
              name="loan_id"
              type="text"
              value={loanId}
              required
              onChange={(e) => {
                setLoanId(e.target.value);
              }}
            />
            <br />
          </div>{" "}
          <div className="textFieldDiv">
            <label>Agreement Link:</label>
            <input
              name="agreement_link"
              type="text"
              value={agreement_link}
              required
              onChange={(e) => {
                setAgreementLink(e.target.value);
              }}
            />
            <br />
          </div>
          {/* <div>
                        <input type="checkbox" id="agree" onChange={checkboxHandler} />
                        <label htmlFor="agree"> I agree to <b>
                            <a href={prop.link}>terms and conditions</a></b></label>
                    </div> */}
          {/* <input disabled={!agree} className={agree ? "submit" : "disableSubmit"} type="submit" value="Continue" /> */}
          <input
            disabled={isValid}
            className="submit"
            type="submit"
            value="Continue"
          />
        </form>
      </header>
    </div>
  );
}

export default Payment;
