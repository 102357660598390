import * as React from "react";
import "./common-header.css";
import { useHistory, useLocation } from "react-router-dom";
import WorkIcon from "@mui/icons-material/Work";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Profile_API } from "../../services/api/Profile/profile-api";
import { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { observer } from "mobx-react-lite";
import { Redirect } from "react-router-dom";
import config from "../../config/settings";

import { errorToast } from "../../utils/helper";

import { ContentLoadingLoader } from "../../components";

const CommonHeader = observer(function () {
  const navigate = useHistory();
  // const name = localStorage.getItem("name");
  // const mobile = localStorage.getItem("mobile");

  const [isLogin, setIsLogin] = useState();
  const [loginRender, setLoginRender] = useState(false);
  const [render, setRender] = useState(false);
  const [profileName, setProfileName] = useState([]);
  const api = new Profile_API();
  const [mouseEnter, setMouseEnter] = useState(false);

  useEffect(() => {
    let checkLogin = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        setIsLogin(true);
        setLoginRender(true);
        // fetchIFAProfileName();
      } catch {
        setIsLogin(false);
      }
    };
    return checkLogin();
    // eslint-disable-next-line
  }, []);

  // const fetchIFAProfileName = () => {
  //   api.getIFAProfileData().then((res) => {
  //     if (res.kind === "ok") {
  //       setProfileName(res.data.name);
  //       setRender(true);
  //     } else {
  //       setRender(true);
  //       errorToast(res.error);
  //     }
  //   });
  // };

  if (isLogin === false) {
    return <Redirect to="/" />;
  }
  return (
    <div className="common_header_main_container">
      {/* logo */}
      <img
        src="https://arthmate-p2p-public-assets.s3.ap-south-1.amazonaws.com/images/1invest-logo-color-dark.png"
        alt="logo"
        className="common_header_logo"
        onClick={() => window.open(config.website.url, "_self")}
      />

      {/* ifa details */}
      <div className="common_header_ifa_details">
        <div
          className="common_header_ifa_details_part"
          onClick={() => navigate.push("/profile")}
          onMouseEnter={() => {
            setMouseEnter(true);
            document.getElementsByClassName(
              "common_header_ifa_details_part"
            )[0].style.backgroundColor = "#3a86ff";
            document.getElementsByClassName(
              "common_header_text"
            )[0].style.color = "#fff";
          }}
          onMouseLeave={() => {
            setMouseEnter(false);
            document.getElementsByClassName(
              "common_header_ifa_details_part"
            )[0].style.backgroundColor = "#fff";
            document.getElementsByClassName(
              "common_header_text"
            )[0].style.color = "#3a86ff";
          }}
        >
          {/* suitcase icon */}
          <AccountCircleIcon
            style={{
              fontSize: "30px",
              marginRight: "8px",
              color: mouseEnter ? "#fff" : "#3a86ff",
            }}
          />
          {/* name */}
          <p className="common_header_text">My Account</p>
        </div>

        {/* divider */}
        {/* <div className="common_header_divider" /> */}

        {/* <div className="common_header_ifa_details_part"> */}
        {/* phone icon */}
        {/* <LocalPhoneIcon
            style={{
              fontSize: "18px",
              marginRight: "10px",
              color: "#42474F",
            }}
          /> */}

        {/* mobile */}
        {/* <p className="common_header_text">{mobile}</p> */}
      </div>
      {/* </div> */}
    </div>
  );
});

export default CommonHeader;
