import { CheckBox } from "@mui/icons-material";
import React, { useRef, useState, useEffect } from "react";
import { head_signature} from "../../config/settings";
import { Auth, Storage } from "aws-amplify";
import { PdfViewer,GenerateHtmlPdf,
    LinearProgressWithLabel,
    InputBox,
    SignupButton,
    ConsentText,
    UploadInput, } from "../";
import Checkbox from "@mui/material/Checkbox";
import Backdrop from "@mui/material/Backdrop";
import "./agreement-generate.css";
import { IfaAgreement } from "../../templates";
import CircularProgress from "@mui/material/CircularProgress";
import {
    errorToast,
    generateUUID,
    successToast,
    getFrontendStateObject
  } from "../../utils/helper";

const { stage } = getFrontendStateObject();
export default function AgreementGenerate(props) {
  const [openIfaModel, setOpenIfaModel] = useState(false);
  const [agreementBase64, setAgreementBase64] = useState("");
  const [initiateAgreementPdf, setInitiateAgreementPdf] = useState(false);
  const [agreementUploadProgess, setAgreementUploadProgess] = useState(0);
  const [agreementBlob, setAgreementBlob] = useState("");
  const [signatureBase64, setSignatureBase64] = useState("");
  const [disableAgreementCheckbox, setDisableAgreementCheckbox] =
    useState(false);
  const [checked,setChecked]=useState(false)

  useEffect(() => {
    setSignatureBase64(head_signature);
  }, []);

  useEffect(() => {
    if(props.ifa && props.ifa.kyc_status == 3 && props.ifa.has_bank!=0 && ((props.status == 2 && props.isExcludedFirm) || props.status == 3)){
      props.setMarkedAgreement(true);
    }
    console.log("not required agreement")
  }, [props.status]);

  const onAcceptAgreement = async () => {
    setOpenIfaModel(false);
    props.setIsUploading(true);
    setDisableAgreementCheckbox(true);
    // props.setHasDisabled(true)
    const fileName = `${generateUUID()}.pdf`;
    Storage.put(fileName, agreementBlob, {
      contentType: "application/pdf ",
      customPrefix: {
        private: `${stage}/private/`,
      },
      level: "private",
      progressCallback: (progress) => {
        if (progress) {
          setAgreementUploadProgess((progress.loaded / progress.total) * 100);
          /*
          const percentage = (progress.loaded / progress.total) * 100
          setUploadValue(percentage)
          */
        }
      },
    })
      .then((res) => {
        successToast("Agreement accepted successfully.");
        props.setIsUploading(false);
        if(props.stage=='kyc'){
          props.setKycDetails({
            ...props.kycDetails,
            is_agreement_accepted: true,
            agreement: fileName,
          });
        } else if (props.stage=="bank"){
          props.setBankVerify({
            ...props.bankVerify,
            is_agreement_accepted: true,
            agreement: fileName,
          });
        }
        
        setChecked(true)
        // props.setHasDisabled(false)
      })
      .catch((err) => {
        errorToast("Failed to accept Agreement.Please Try again.");
        props.setIsUploading(false);
        if(props.stage=="kyc"){
          props.setKycDetails({
            ...props.kycDetails,
            is_agreement_accepted: false,
            agreement: "",
          });
        } else if (props.stage=='bank'){
          props.setBankVerify({
            ...props.bankVerify,
            is_agreement_accepted: false,
            agreement: "",
          });
        }
        
        setChecked(true)
        setDisableAgreementCheckbox(false);
      });
  };
  return (
    <React.Fragment>
      <div
        className="bank_verify_checkbox_container"
        style={{
          display: props.stage=='kyc' &&props.ifa && props.ifa.kyc_status == 3 && props.ifa.has_bank!=0 && ((props.status == 2 && props.isExcludedFirm) || props.status == 3)? "flex" :props.stage=='bank'?"flex": "none",
          position: "relative",
        }}
      >
        <div className="bank_verify_checkbox">
          <Checkbox
            disabled={disableAgreementCheckbox}
            inputProps={{ "aria-label": "Checkbox demo" }}
            checked={checked}
            sx={{ "& .MuiSvgIcon-root": { fontSize: 15 } }}
            onChange={(e) => {
              if (e.target.value) {
                if (agreementBase64.length != 0) {
                  setOpenIfaModel(true);
                } else {
                  setInitiateAgreementPdf(true);
                }
              } else {
                if(props.stage=="kyc"){
                  props.setKycDetails({
                    ...props.kycDetails,
                    is_agreement_accepted: false,
                  });
                }else if (props.stage=="bank"){
                  props.setBankVerify({
                    ...props.bankVerify,
                    is_agreement_accepted: false,
                  });
                }
                setChecked(false)
                setInitiateAgreementPdf(false);
              }
            }}
          />
          <div className="bank_verify_checkbox_condition">
            I have read the
            <p
              style={{
                display: "inline-block",
                paddingLeft: "4px",
                padding: "0px",
                color: "#3a86ff",
                fontSize: "11px",
                cursor: "pointer",
                marginTop: "-2px",
              }}
              onClick={() => {
                if (agreementBase64.length != 0) {
                  setOpenIfaModel(true);
                } else {
                  setInitiateAgreementPdf(true);
                }
              }}
            >
              Lead Generation Agreement
            </p>
            and consent to it.
          </div>
        </div>
        <Backdrop
          sx={{
            backgroundColor: "white",
            color: "black",
            position: "absolute",
            alignSelf: "center",
          }}
          open={initiateAgreementPdf}
          onClick={() => null}
        >
          <CircularProgress size={20} />
          <p>Generating Agreement...</p>
        </Backdrop>

        <Backdrop
          sx={{
            backgroundColor: "white",
            color: "black",
            position: "absolute",
            marginTop: "5px",
            textAlign: "center",
          }}
          open={props.isUploading}
          onClick={() => null}
        >
          <LinearProgressWithLabel value={agreementUploadProgess}>
            This might take upto 1 min. Please be patient...
          </LinearProgressWithLabel>
        </Backdrop>
      </div>

      <PdfViewer
        openModel={openIfaModel}
        setOpenModel={setOpenIfaModel}
        header={"Lead Generation Agreement"}
        url={
          agreementBlob.length != 0 ? URL.createObjectURL(agreementBlob) : ""
        }
        onSubmit={onAcceptAgreement}
        alreadyAccepted={checked ? true : false}
      />

      <GenerateHtmlPdf
        HTML={() => (
          <IfaAgreement
            name={
              props.ifa && props.ifa.name
                ? props.ifa.name
                : "Service Provider/Referrer"
            }
            address={props.ifa && props.ifa.address ? props.ifa.address : ""}
            signature={signatureBase64}
            nameOfSignatory={
              props.ifa && props.ifa.agreement_signatory
                ? props.ifa.agreement_signatory
                : ""
            }
            email={props.ifa && props.ifa.email ? props.ifa.email : ""}
          />
        )}
        fileName="ifa-agreement"
        initiate={initiateAgreementPdf}
        margin={[10, 15, 10, 15]}
        onSuccess={(base64, blob) => {
          setInitiateAgreementPdf(false);
          setAgreementBase64(base64);
          setAgreementBlob(blob);
          setOpenIfaModel(true);
        }}
      />
    </React.Fragment>
  );
}
