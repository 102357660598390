import React from "react";
import "./step.css";
import tickMark from "../../assets/images/competedDot.svg";
import { IndeterminateCheckBoxOutlined } from "@mui/icons-material";
export default function Step(props) {

  return (
    <div id={`step${props.index}`} className={"stepBlock" + (props.selected ? " selected" : "")}>
      <div className={"circleWrapper"}>
        <div
          className={
            props.status==1 || props.currentStep>props.index
              ? null
              : "step_circle"
          }
        >
          {props.status==1 || props.currentStep>props.index ? (
            <img src={tickMark} />
          ) : null}
        </div>
      </div>
      <span
        className={
          true
            ? null
            : "disableText"
        }
      >
        {props.label}
      </span>
    </div>
  );
}
