import * as React from "react";
import "./pool-clientlist-model.css";
import { alpha } from "@mui/material/styles";
import Modal from "@mui/material/Modal";
import { useLocation } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
  Tooltip,
  IconButton,
  TablePagination,
} from "@mui/material";
import UndoIcon from "@mui/icons-material/Undo";
import CheckIcon from "@mui/icons-material/Check";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import { convertAmount } from "../../utils/helper";
import { AddCircleOutlineOutlined, Close, Delete } from "@mui/icons-material";
import Loader from "react-loader-spinner";
import { errorToast } from "../../utils/helper";
import SampleToast from "../sample-toast/sample-toast";
import { eventLog } from "../../utils/firebase";

const tableContainer = {
  width: "100%",
  borderTopLeftRadius: "5px",
  borderTopRightRadius: "5px",
  // borderTop: "1px solid #c7c7c7",
  // borderLeft: "1px solid #c7c7c7",
  // borderRight: "1px solid #c7c7c7",
  alignSelf: "center",
  overflow: "scroll",
};

const tableHeader = {
  backgroundColor: "#ececec",
  fontWeight: "600",
  fontSize: 15,
  fontFamily: "Asap",
  color: "#1f1f1f",
  borderRight: "1px solid #c7c7c7",
  padding: "15px 0px 13px 40px",
};

const tableCheckboxHeader = {
  width: `5%`,
  backgroundColor: "#ececec",
  fontWeight: "600",
  fontSize: 15,
  fontFamily: "Asap",
  color: "#1f1f1f",
  borderRight: "1px solid #c7c7c7",
  paddingTop: "10px",
  paddingLeft: "10px",
  paddingRight: "0px",
  paddingBottom: "10px",
};

const tableRow = {
  fontSize: 14,
  fontFamily: "Asap",
  fontWeight: "400",
  color: "#1c1c1c",
  borderRight: "1px solid #c7c7c7",
  padding: "15px 0px 13px 40px",
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        minHeight: 0,
        height: 1,
        float: "right",
        width: "35%",
        mt: { sm: -2 },
        mr: { sm: 3 },
        pl: { sm: 2 },
        pr: { sm: 2 },
        border: "1px solid #fff",
        borderRadius: "5px",
        minHeight: "auto !important",
        height: "fit-content",
        ...(numSelected > 0 && {
          color: "#559264",
          border: "1px solid #559264",
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          Invest for selected ({numSelected}) client{numSelected > 1 ? "s" : ""}{" "}
          ?
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        ></Typography>
      )}

      {numSelected > 0 ? (
        <div className="tool_tip">
          <Tooltip title="Proceed">
            <IconButton onClick={props.handleProceed}>
              <CheckIcon htmlColor="#559264" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Undo">
            <IconButton onClick={props.handleUndo}>
              <UndoIcon />
            </IconButton>
          </Tooltip>
        </div>
      ) : (
        ""
      )}
    </Toolbar>
  );
};

export default function PoolClientlistModel(props) {
  const isSelected = (uuid) =>
    selected.findIndex(function (item, i) {
      return item.uuid === uuid;
    }) !== -1;
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const rowCount = props.clientData.length;
  const numSelected = selected.length;
  const rowsPerPage = 100;

  const param = useLocation();

  const handleClick = (event, row) => {
    const selectedIndex = selected.findIndex(function (item, i) {
      return item.uuid === row.uuid;
    });
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, [
        {
          uuid: row.uuid,
          name: row.name,
          available_funds: row.available_funds,
          net_worth: row.net_worth,
        },
      ]);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = props.clientData.map((n) => n);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleUndo = (event) => {
    setSelected([]);
  };

  const handleProceed = (event) => {
    console.log("selected clients :;", selected);
  };

  // handle page change function
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Modal
      open={props.openClientModel}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{
        backgroundColor: "rgba(0,0,0,0.5)",
        overflow: "scroll",
      }}
    >
      <div className="pool_clientlist_model_div">
        {/* title */}
        <div className="pool_clientlist_model_title_div">
          <h1 className="pool_clientlist_model_title">
            {`${param.state.pool.pool_title}`}{" "}
          </h1>
          <Close style={{ cursor: "pointer" }} onClick={props.handleClose} />
        </div>

        {/* search_bar */}
        {/* <div className="pool_clientlist_search_bar_div">
          <div className="pool_clientlist_search_bar"> */}
        {/* icon */}
        {/* <SearchIcon
              style={{
                fontSize: "20px",
                paddingRight: "10px",
                color: "#555555",
              }}
            /> */}
        {/* input box */}
        {/* <input
              type="text"
              placeholder="Search by Name"
              className="pool_clientlist_search_bar_input"
              onChange={props.requestSearch}
            />
          </div>
          <EnhancedTableToolbar numSelected={selected.length} handleUndo={handleUndo} handleProceed={handleProceed}/>
        </div> */}

        {/* table container */}
        <div className="pool_clientlist_table_div">
          {/* table */}
          <TableContainer style={tableContainer}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {/* <TableCell padding="checkbox" align="left" style={tableCheckboxHeader}>
                    <Checkbox
                      color="primary"
                      indeterminate={numSelected > 0 && numSelected < rowCount}
                      checked={rowCount > 0 && numSelected === rowCount}
                      onChange={handleSelectAllClick}
                      inputProps={{
                        'aria-label': 'Select All',
                      }}
                    />
                  </TableCell> */}
                  <TableCell align="left" width="30%" style={tableHeader}>
                    Name
                  </TableCell>
                  <TableCell align="left" width="20%" style={tableHeader}>
                    Suggested Investment Amount
                  </TableCell>
                  {param.state.pool.type == 0 || param.state.pool.type == 2 ? (
                    <TableCell width="20%" align="left" style={tableHeader}>
                      Interest Rate Per Annum
                    </TableCell>
                  ) : null}
                  <TableCell
                    align="left"
                    width="20%"
                    style={{
                      ...tableHeader,
                      borderRight: "none",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.clientData
                  .sort(props.sortByName)
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.uuid);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        key={row.uuid}
                        hover
                        onClick={(event) => handleClick(event, row)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        selected={isItemSelected}
                        style={{
                          backgroundColor: index % 2 === 0 ? "#fafafa" : "#fff",
                        }}
                      >
                        {/* <TableCell style={tableRow} align="left" padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          />
                      </TableCell> */}
                        <TableCell style={tableRow} align="left">
                          <div>
                            <p className="clientlist_client_name">{row.name}</p>
                            {row.consent_status != 0 && (
                              <p
                                className="clientlist_consent_status"
                                style={{
                                  color:
                                    row.consent_status == 1
                                      ? "#FF0000"
                                      : "#008000",
                                }}
                              >
                                {row.consent_status == 1
                                  ? "Consent Pending"
                                  : "Consent Received"}
                              </p>
                            )}
                          </div>
                        </TableCell>
                        <TableCell style={tableRow} align="left">
                          ₹ {convertAmount(row.amount)}
                        </TableCell>
                        {param.state.pool.type == 0 ||
                        param.state.pool.type == 2 ? (
                          <TableCell style={tableRow} align="left">
                            {param.state.pool.interest_rate}
                          </TableCell>
                        ) : null}
                        <TableCell
                          style={{
                            ...tableRow,
                            borderRight: "none",
                          }}
                          align="left"
                        >
                          <Delete
                            style={{
                              cursor: "pointer",
                              fontSize: "18px",
                              fontFamily: "Asap",
                              fontWeight: 400,
                              color: "rgb(245, 51, 91)",
                              padding: "8px",
                              background: "rgb(255, 241, 244)",
                              borderRadius: "4px",
                            }}
                            onClick={(e) => {
                              props.handleValueChange(
                                row.uuid,
                                row.name,
                                row.amount,
                                row.freedom_plan_invested_amount,
                                row.invested_pools,
                                row.consent_status,
                                !row.maturity_payout,
                                !row.monthly_payout,
                                { target: { value: 0 } },
                                true
                              );
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            {/* if no data available */}
            {props.clientData.length === 0 ? (
              <div className="no_row_found_div">
                <FindInPageIcon
                  style={{
                    fontSize: "100px",
                    color: "#555555",
                  }}
                />
                <p className="no_row_found">No row found</p>
              </div>
            ) : (
              <div />
            )}
          </TableContainer>
          {/* pagination */}
          <div className="pagination_div" style={{ width: "99.9%" }}>
            <TablePagination
              rowsPerPageOptions={[]}
              component="div"
              count={props.clientData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
            />
          </div>
          <div className="pool_client_add_more_section">
            <div
              onClick={(e) => {
                props.handleClose(e);
                eventLog({
                  type: "add_more_clients",
                  action: {
                    trigger: "true",
                  },
                });
              }}
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              <AddCircleOutlineOutlined style={{ marginRight: "8px" }} />
              Add more clients
            </div>
          </div>
          <div className="pool_client_list_container_button_div">
            <button
              className="pool_client_bottom_container_button"
              onClick={() => {
                props.handleSubmit();
                eventLog({
                  type: "invest_after_review_and_invest",
                  action: {
                    trigger: "true",
                  },
                });
              }}
            >
              {props.investLoader ? (
                <Loader type="TailSpin" color="#fff" height={15} width={15} />
              ) : (
                "Send Request"
              )}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
