const awsConfig = {
  me: {
    Auth: {
      userPoolId: "ap-south-1_WqISqv6o0",
      userPoolWebClientId: "707fjl9dt92aotbv2g4h5lovmd",
      identityPoolId: "ap-south-1:5718d1c8-b283-4cdb-8d2a-cf9cb4c51a19",
      region: "ap-south-1",
      authenticationFlowType: "CUSTOM_AUTH",
    },
    API: {
      endpoints: [
        {
          name: "baseurl",
          endpoint: "https://backme.arthmatedirect.com",
          region: "ap-south-1",
        },
        {
          name: "baseurlBorrower",
          endpoint: "https://api-me.arthmatedirect.com",
          region: "ap-south-1",
        },
        {
          name: "paytmBaseURL",
          endpoint: "https://securegw-stage.paytm.in",
        },
      ],
    },
    AWSS3: {
      bucket: "arthmate-p2p-data",
      region: "ap-south-1",
    },
  },
  dev: {
    Auth: {
      userPoolId: "ap-south-1_c4R3M7OZA",
      userPoolWebClientId: "1g5bd03b61t4ijb90tsr2n1rva",
      identityPoolId: "ap-south-1:546661d1-2518-4d4c-8816-0f3b2fffc412",
      region: "ap-south-1",
      authenticationFlowType: "CUSTOM_AUTH",
    },
    API: {
      endpoints: [
        {
          name: "baseurl",
          endpoint: "https://backdev.arthmatedirect.com",
          region: "ap-south-1",
        },
        {
          name: "paytmBaseURL",
          endpoint: "https://securegw-stage.paytm.in",
        },
      ],
    },
    AWSS3: {
      bucket: "arthmate-p2p-data",
      region: "ap-south-1",
    },
  },
  prod: {
    Auth: {
      userPoolId: "ap-south-1_06Cqgm0ZC",
      userPoolWebClientId: "2g8kkgguffr4losbjl8vf6mj2p",
      identityPoolId: "ap-south-1:1e1c3d9a-89dc-412d-8cc5-ccce917514c5",
      region: "ap-south-1",
      authenticationFlowType: "CUSTOM_AUTH",
    },
    API: {
      endpoints: [
        {
          name: "baseurl",
          endpoint: "https://backprod.arthmatedirect.com",
          region: "ap-south-1",
        },
        {
          name: "paytmBaseURL",
          endpoint: "https://securegw.paytm.in",
        },
      ],
    },
    AWSS3: {
      bucket: "arthmate-p2p-data",
      region: "ap-south-1",
    },
  },
  uat: {
    Auth: {
      userPoolId: "ap-south-1_YPtinv8hX",
      userPoolWebClientId: "3cnfvols057otne6qst0bm5q96",
      identityPoolId: "ap-south-1:6229cdab-e0fc-481a-9342-8d76f4f571c9",
      region: "ap-south-1",
      authenticationFlowType: "CUSTOM_AUTH",
    },
    API: {
      endpoints: [
        {
          name: "baseurl",
          endpoint: "https://backuat.arthmatedirect.com",
          region: "ap-south-1",
        },
        {
          name: "paytmBaseURL",
          endpoint: "https://securegw-stage.paytm.in",
        },
      ],
    },
    AWSS3: {
      bucket: "arthmate-p2p-data",
      region: "ap-south-1",
    },
  },
};

export default awsConfig;
