import { detach, flow, Instance, SnapshotOut, types } from "mobx-state-tree";
import { User_Data_Model, User_Data_SnapShot } from "../user-data/user-data";
import { User_API } from "../../../services/api";

// make the instance of api
const api = new User_API();

export const UserDataStoreModel = types
  .model("UserDataStore")
  .props({
    userdata: types.optional(types.array(User_Data_Model), []),
  })
  .views((self) => ({}))
  .actions((self) => ({
    /**
     * @description this function update the user overall data in the mst model
     * @param userprofilesnapshot
     */
    updateUserData: (userprofilesnapshot: User_Data_SnapShot[]) => {
      detach(self.userdata);
      self.userdata.push(userprofilesnapshot);
    },
  }))
  .actions((self) => ({
    /**
     * @description this function is to get the logged in users overall data
     * @param number
     * @returns it return the user overall data of the current user
     */
    getUserData: flow(function* (data: any) {
      const result: any = yield api.getUserData(data);
      if (result.kind === "ok") {
        localStorage.setItem("kyc_status", result.userdata.kyc_status);
        self.updateUserData(result.userdata);
        return { kind: "ok", data: result.userdata };
      } else {
        return { kind: "bad-data", error: result.error };
      }
    }),

    /**
     * @description this function is to get the logged in users overall data
     * @param number
     * @returns it return the user overall data of the current user
     */
    getIfaData: flow(function* (data: any) {
      const result = yield api.getIfaData(data);
      if (result.kind === "ok") {
        self.updateUserData(result.userdata);
        return { kind: "ok", data: result.userdata };
      } else {
        return { kind: "bad-data", error: result.error };
      }
    }),

    /**
     * @description this function is to add Ifa
     * @param number
     * @returns it return the user overall
     */
    addIfa: flow(function* (data: any) {
      const result = yield api.addIfa(data);
      if (result.kind === "ok") {
        return { kind: "ok", data: result.userdata };
      } else {
        return { kind: "bad-data", error: result.error };
      }
    }),

    /**
     * @description this function is to update ifa kyc document
     * @param number
     * @returns it return the
     */
    updateKycDocument: flow(function* (data: any) {
      const result = yield api.updateKycDocument(data);
      if (result.kind === "ok") {
        return { kind: "ok", data: result.userData };
      } else {
        return { kind: "bad-data", error: result.error };
      }
    }),

    /**
     * @description this function is to update org document
     * @param number
     * @returns it return the
     */
    updateOrgDocument: flow(function* (data: any) {
      const result = yield api.updateOrgDocument(data);
      if (result.kind === "ok") {
        return { kind: "ok", data: result.userData };
      } else {
        return { kind: "bad-data", error: result.error };
      }
    }),

    /**
     * @description this function is to update ifa bank
     * @param number
     * @returns it return the
     */
    updateKycBank: flow(function* (data: any) {
      const result = yield api.updateKycBank(data);
      if (result.kind === "ok") {
        return { kind: "ok", data: result.userData };
      } else {
        return { kind: "bad-data", error: result.error };
      }
    }),

    /**
     * @description this function is to update kyc status
     * @param number
     * @returns it return the user overall data of the current user
     */
    updateKycStatus: flow(function* (data: any) {
      const result = yield api.updateKycStatus(data);
      if (result.kind === "ok") {
        return { kind: "ok", data: result.data };
      } else {
        return { kind: "bad-data", error: result.error };
      }
    }),
    /**
     * @description this function is to send verification email
     * @param number
     * @returns it return the
     */
    sendEmailVerification: flow(function* (data: any) {
      const result = yield api.sendEmailVerification(data);
      if (result.kind === "ok") {
        return { kind: "ok", data: result.data };
      } else {
        return { kind: "bad-data", error: result.error };
      }
    }),
  }));

type UserDataStoreType = Instance<typeof UserDataStoreModel>;
export interface UserDataStore extends UserDataStoreType {}

type UserDataStoreSnapshotType = SnapshotOut<typeof UserDataStoreModel>;
export interface UserDataStoreSnapshot extends UserDataStoreSnapshotType {}
