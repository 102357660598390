import React, { useEffect, useState } from "react";
import "./walletrequestsuccess.css";
import { useParams, useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import { IFA_Payment_API, WalletApi } from "../../services/api";
import InfoIcon from "@mui/icons-material/Info";
import checkMark from "../../assets/images/checkMark.svg";
import { eventLog } from "../../utils/firebase";
import Tooltip from "../../assets/images/info.svg";
import { convertTimestampFormat, errorToast } from "../../utils/helper";

const WalletRequestSuccess = (props) => {
  const api = new WalletApi();
  const [data, setData] = useState({
    amount: 0,
    payment_id: "",
    status: "NOT DEFINE",
    txn_date: "",
  });

  const [params, setParams] = useState({});

  useEffect(() => {
    props.showCommonHeader(false);
    props.showCommonSidebar(false);
    setParams(props.match.params);
  }, []);

  useEffect(() => {
    if (params.wallet_request_uuid && params.order_uuid) {
      api
        .getOrderData(params.order_uuid)
        .then((res) => {
          if (res.kind == "ok") {
            eventLog({
              type: "payment_success",
              action: {
                trigger: "true",
              },
            });
            setData(res.data);
          } else {
            errorToast("Error while fetching order details");
          }
        })
        .catch((err) => console.log("err", err));
    }
  }, [params]);

  return (
    <React.Fragment>
      <div className="wallet_request_success_header">
        <img
          src="https://arthmate-p2p-public-assets.s3.ap-south-1.amazonaws.com/images/1invest-logo-color-dark.png"
          alt="logo"
          className="auth_logo_img"
          onClick={() =>
            window.open("https://www.arthmatedirect.com/", "_self")
          }
        />
      </div>
      <div className="wallet_request_success_page_container">
        <br />
        <br />
        <div className="wallet_request_success_head_grid_container">
          <div style={{ textAlign: "center" }}>
            <img
              src={checkMark}
              alt="check_mark_icon"
              className="wallet_request_success_check_mark"
            />
            <p className="wallet_request_success_main_title_text">
              Transaction Successful
            </p>
          </div>
          <br />
          <div className="wallet_request_success_grid_container"></div>
          <div className="wallet_request_success_grid_container">
            <div className="wallet_request_success_item_left">
              <p className="wallet_request_success_item_text">Order ID</p>
            </div>
            <div className="wallet_request_success_item_right">
              <p className="wallet_request_success_item_text">
                {params.order_uuid}
              </p>
            </div>
          </div>
          <div className="wallet_request_success_grid_container">
            <div className="wallet_request_success_item_left">
              <p className="wallet_request_success_item_text">Amount</p>
            </div>
            <div className="wallet_request_success_item_right">
              <p className="wallet_request_success_item_text">
                ₹ {data.amount}
              </p>
            </div>
          </div>
          <div className="wallet_request_success_grid_container">
            <div className="wallet_request_success_item_left">
              <p className="wallet_request_success_item_text">Transaction ID</p>
            </div>
            <div className="wallet_request_success_item_right">
              <p className="wallet_request_success_item_text">
                {data.payment_id}
              </p>
            </div>
          </div>
          <div className="wallet_request_success_grid_container">
            <div className="wallet_request_success_item_left">
              <p className="wallet_request_success_item_text">TXN Date</p>
            </div>
            <div className="wallet_request_success_item_right">
              <p className="wallet_request_success_item_text">
                {convertTimestampFormat(data.txn_date)}
              </p>
            </div>
          </div>
        </div>
        <div className="wallet_request_success_main_title">
          <p>Congratulations! Your funds have been added to your wallet.</p>
          <div className="wallet_request_success_grid_container"></div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default WalletRequestSuccess;
