import React, { useEffect, useRef, useState } from "react";
import { Auth } from "aws-amplify";
import "./client-freedom-plan.css";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { convertAmount, errorToast, successToast } from "../../utils/helper";
import ClientInvestmentRequestModel from "../client-investment-request-model/client-investment-request-model";
import { eventLog } from "../../utils/firebase";
import { Pools_API } from "../../services/api";
import ClientEarningsModel from "../client-earnings-model/client-earnings-model";
import config from "../../config/settings";
import FreedomPlanErrorModal from "../client-freedom-error-modal/client-freedom-error";

const defaultFreedomPlanData = {
  freedom_plan_invested_amount: 0,
  freedom_plan_daily_earnings: 0,
  freedom_plan_earnings: 0,
  freedom_plan_interest_rate: 0,
  freedom_plan_total_earnings: 0,
};

const defaultFreedomPlanPool = {
  tenure: null,
  pool_title: "Freedom Plan",
  id: "",
  minimum_investment: 1000,
  interest_rate: 10,
};

const ClientFreedomPlan = (props, ref) => {
  const navigate = useHistory();
  const param = useLocation();
  const [freedomPlanData, setFreedomPlanData] = useState(
    defaultFreedomPlanData
  );
  const [freedomPlanPool, setFreedomPlanPool] = useState(
    defaultFreedomPlanPool
  );
  const [name, setName] = useState("");
  const [
    openClientInvestmentRequestModel,
    setOpenClientInvestmentRequestModel,
  ] = useState(false);
  const [
    openFreedomPlanErrorModal,
    setFreedomPlanErrorModal,
  ] = useState(false);
  const [amountInvalid, setAmountInvalid] = useState(false);
  const [amountInvalidText, setAmountInvalidText] = useState("");
  const [amount, setAmount] = useState(0);
  const [loading, setLoading] = useState(false);

  const [openEarningsModel, setOpenEarningsModel] = useState(false);

  const poolsApi = new Pools_API();

  const dimensions = props.dimensions;

  useEffect(() => {
    fetchClientFreedomPlan();
  }, [props]);

  // amount key press handler
  const onKeyPress = (e) => {
    const re = /[^0-9]/g;
    if (re.test(e.key)) {
      e.preventDefault();
    }
  };

  const fetchClientFreedomPlan = () => {
    setFreedomPlanData({
      freedom_plan_invested_amount:
        props.overviewData["freedom_plan_invested_amount"],
      freedom_plan_daily_earnings:
        props.overviewData["freedom_plan_daily_earnings"],
      freedom_plan_earnings: props.overviewData["freedom_plan_earnings"],
      freedom_plan_interest_rate:
        props.overviewData["freedom_plan_interest_rate"],
      freedom_plan_total_earnings:
        props.overviewData.overall_earnings["freedom_plan"],
    });
    setFreedomPlanPool(props.overviewData?.freedom_plan_pool);
    setName(props.overviewData?.name);
  };

  // on wallet loading proceed click
  const onInvestmentRequestProceed = async () => {
    if (!isNaN(parseFloat(amount))) {
      if (amount == 0) {
        errorToast("Amount cannot be empty.");
      } else if (amount < 0) {
        errorToast("Amount cannot be  negative.");
      } else if (amount % 1000 != 0) {
        errorToast("Amount should be in multiple of 1000.");
      } else {
        setLoading(true);

        const payload = {
          investments: [
            {
              investment_amount: amount,
              pool_template_id: freedomPlanPool["id"],
              investor_id: props.clientUuid,
            },
          ],
          is_profile_flow: 1,
        };

        poolsApi.sendPaymentLink(payload).then((res) => {
          if (res.kind === "ok") {
            if (
              res.data?.data?.profile_flow_investment_consent_success == true
            ) {
              successToast("Investment done successfully.");
              props.onInvestment();
            } else {
              successToast("Investment request sent successfully.");
            }
            setTimeout(() => {
              setLoading(false);
              setOpenClientInvestmentRequestModel(false);
              setAmount(0);
              eventLog({
                type: "investment_request",
                action: {
                  trigger: "true",
                },
              });
            }, 2000);
          } else {
            setLoading(false);
            errorToast(res.error);
            errorToast({
              type: "investment_request",
              action: {
                trigger: "false",
              },
            });
          }
        });
      }
    } else {
      errorToast("Please enter a valid amount.");
    }
  };

  return (
    <div className="client_freedom_plan_div_container">
      <div
        className="client_freedom_card"
        style={{
          flexDirection:
            freedomPlanData["freedom_plan_invested_amount"] == 0
              ? "row"
              : "column",
        }}
      >
        <div className="client_freedom_card_content_container">
          {/* Total Investment */}
          {freedomPlanData["freedom_plan_invested_amount"] != 0 && (
            <div className="client_freedom_card_content">
              <div className="client_freedom_card_content_title">
                Total Invested Amount
              </div>
              <div className="client_freedom_card_content_value">
                ₹{" "}
                {convertAmount(freedomPlanData["freedom_plan_invested_amount"])}
              </div>
            </div>
          )}
          {/* Total Earnings */}
          {freedomPlanData["freedom_plan_invested_amount"] != 0 && (
            <div className="client_freedom_card_content">
              <div className="client_freedom_card_content_title">
                Total Earnings
              </div>
              <div className="client_freedom_card_content_value">
                ₹{" "}
                {convertAmount(freedomPlanData["freedom_plan_total_earnings"])}
              </div>
            </div>
          )}
          {/* Today's Earnings */}
          {freedomPlanData["freedom_plan_invested_amount"] != 0 && (
            <div className="client_freedom_card_content">
              <div className="client_freedom_card_content_title">
                Today's Earnings
              </div>
              <div className="client_freedom_card_content_value">
                ₹{" "}
                {convertAmount(freedomPlanData["freedom_plan_daily_earnings"])}
              </div>
            </div>
          )}
          {/* Unwithdrawn Earnings */}
          {freedomPlanData["freedom_plan_invested_amount"] != 0 && (
            <div className="client_freedom_card_content">
              <div className="client_freedom_card_content_title">
                Unwithdrawn Earnings
              </div>
              <div className="client_freedom_card_content_value">
                ₹ {convertAmount(freedomPlanData["freedom_plan_earnings"])}
              </div>
            </div>
          )}
          {/* Interest Rate */}
          <div className="client_freedom_card_content">
            <div className="client_freedom_card_content_title">
              Interest Rate p.a.
            </div>
            <div className="client_freedom_card_content_value">
              {freedomPlanData["freedom_plan_interest_rate"] != 0 ? "Upto" : ""}{" "}
              {parseFloat(
                freedomPlanData["freedom_plan_interest_rate"]
              ).toFixed(2)}
              %
            </div>
          </div>
          {/* Tenure */}
          {freedomPlanData["freedom_plan_invested_amount"] == 0 && (
            <div className="client_freedom_card_content">
              <div className="client_freedom_card_content_title">Tenure</div>
              <div className="client_freedom_card_content_value">
                Withdraw Anytime
              </div>
            </div>
          )}
          {/* Minimum Investment */}
          {freedomPlanData["freedom_plan_invested_amount"] == 0 && (
            <div className="client_freedom_card_content">
              <div className="client_freedom_card_content_title">
                Minimum Investment
              </div>
              <div className="client_freedom_card_content_value">
                ₹ {convertAmount(freedomPlanPool["minimum_investment"])}
              </div>
            </div>
          )}
        </div>
        {/* Action buttons */}
        <div className="client_freedom_card_button_container">
          {freedomPlanData["freedom_plan_invested_amount"] != 0 && (
            <button
              className="client_freedom_details_investment_main_header_button"
              onClick={() => setOpenEarningsModel(true)}
              disabled={freedomPlanData["freedom_plan_earnings"] == 0}
              style={{
                borderColor:
                  freedomPlanData["freedom_plan_earnings"] == 0
                    ? "#AEAEAE"
                    : "#3A86FF",
                cursor:
                  freedomPlanData["freedom_plan_earnings"] == 0
                    ? "auto"
                    : "pointer",
              }}
            >
              <div
                className="client_freedom_plan_earning_button_text"
                style={{
                  color:
                    freedomPlanData["freedom_plan_earnings"] == 0
                      ? "#AEAEAE"
                      : "#3A86FF",
                }}
              >
                View Earnings History
              </div>
            </button>
          )}
          <button
            className="client_freedom_investment_button"
            onClick={() => {
              config.fp_flag_info.flag_status
                ? setOpenClientInvestmentRequestModel(true)
                : setFreedomPlanErrorModal(true)
            }}
          >
            <p style={{ fontSize: "20px", fontWeight: "500" }}>
              {freedomPlanData["freedom_plan_invested_amount"] == 0
                ? "Invest Now"
                : "Invest More"}
            </p>
          </button>
        </div>
      </div>
      {/* Investment Request link */}
      <ClientInvestmentRequestModel
        openModel={openClientInvestmentRequestModel}
        amountInvalid={amountInvalid}
        amountInvalidText={amountInvalidText}
        setAmountInvalidText={setAmountInvalidText}
        setAmountInvalid={setAmountInvalid}
        handleClose={() => setOpenClientInvestmentRequestModel(false)}
        minimumInvestment={freedomPlanPool["minimum_investment"]}
        interest_rate={freedomPlanPool["interest_rate"].toFixed(2)}
        alreadyInvested={freedomPlanData["freedom_plan_invested_amount"]}
        tenure="Withdraw Anytime"
        type={props.pool_type}
        walletBalance={props.walletBalance}
        selectedIndex={0}
        onKeyPress={onKeyPress}
        setAmount={(value) => setAmount(value)}
        loading={loading}
        onProceed={() => onInvestmentRequestProceed()}
      />
      <FreedomPlanErrorModal
        openModel={openFreedomPlanErrorModal}
        handleClose={() => setFreedomPlanErrorModal(false)}   
        errorMessage = {config.fp_flag_info.flag_note} 
      />

      {/* Earnings Details*/}
      <ClientEarningsModel
        openModel={openEarningsModel}
        handleClose={() => setOpenEarningsModel(false)}
        loading={loading}
        clientUuid={props.clientUuid}
        poolTemplateUuid={freedomPlanPool["id"]}
      />
    </div>
  );
};

export default ClientFreedomPlan;
