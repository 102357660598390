import { API } from "aws-amplify";
// var axios = require('axios');

export class Forms_API {
  async borrowers(data: any): Promise<any> {
    // payload
    const params = {
      headers: {
        "X-API-KEY": "941lKEmG9K2eBaCusllmcQlUDhnozYC5RXI7sAb3",
      },
      body: data,
    };

    //call the api try and catch
    //https://api-me.arthmatedirect.com/partners/borrowers
    try {
      const response = await API.post(
        "baseurlBorrower",
        "/partners/borrowers",
        params
      );
      return { kind: "ok", data: response.data };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }
  async repayments(data: any): Promise<any> {
    // payload
    const params = {
      body: data,
      headers: {
        "X-API-KEY": "941lKEmG9K2eBaCusllmcQlUDhnozYC5RXI7sAb3",
      },
    };

    //call the api try and catch
    //https://api-me.arthmatedirect.com/partners/repayments
    try {
      const response = await API.post(
        "baseurlBorrower",
        "/partners/repayments",
        params
      );
      return { kind: "ok", data: response.data };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }
  async payment(data: any): Promise<any> {
    // payload
    const params = {
      body: data,
      headers: {
        "X-API-KEY": "941lKEmG9K2eBaCusllmcQlUDhnozYC5RXI7sAb3",
      },
    };

    //call the api try and catch
    //https://api-me.arthmatedirect.com/partners/borrowers
    try {
      // try axios method
      // var payload = '{\n    "partner_id":"4b41454d-fe8d-11eb-875f-02a64dc07394",\n    "loan_id":"IU7SID6N33VLJ" ,\n    "agreement_link": "https://arthmate-p2p-data.s3.ap-south-1.amazonaws.com/readable/Agreements.pdf"\n}';

      // var config = {
      //     method: 'put',
      //     url: 'https://api-me.arthmatedirect.com/partners/borrowers',
      //     headers: {},
      //     data: data
      // };

      // axios(config)
      //     .then(function (response: any) {
      //         console.log(JSON.stringify(response.data));
      //     })
      //     .catch(function (error: any) {
      //         console.log(error);
      //     });

      const response = await API.put(
        "baseurlBorrower",
        "/partners/borrowers",
        params
      );
      return { kind: "ok", data: response.data };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }
}
