import { API } from "aws-amplify";
import * as Types from "../api.types";

export class WithdrawalApi {
  /**
   * @description this function is for initiate withdrawal request
   * @param data
   * @returns success or faliure
   */
  async updateWithdrawalRequest(
    data: any
  ): Promise<Types.GetUpdateWithdrawalRequest> {
    // payload
    const params = {
      body: data,
    };

    // call the api try and catch
    try {
      const response: any = await API.post("baseurl", "/withdrawals", params);
      return { kind: "ok", data: response.message };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description this function is for getting withdrawal data
   * @param uuid
   * @returns success or faliure
   */
  async getWithdrawalRequestData(
    uuid: any
  ): Promise<Types.GetUpdateWithdrawalRequest> {
    // call the api try and catch
    try {
      const response: any = await API.get(
        "baseurl",
        `/withdrawals/${uuid}`,
        {}
      );
      return { kind: "ok", data: response.data };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description this function is for updating withdrawals status
   * @param data
   * @returns success or faliure
   */
  async updateWithdrawalRequestStatus(
    data: any,
    uuid: any
  ): Promise<Types.GetUpdateWithdrawalRequest> {
    // payload
    const params = {
      body: data,
    };

    // call the api try and catch
    try {
      const response: any = await API.post(
        "baseurl",
        `/withdrawals/${uuid}`,
        params
      );
      return { kind: "ok", data: response.data };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }
}
