import "./ifaProfile.css";
import { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { observer } from "mobx-react-lite";
import { Redirect } from "react-router-dom";
import { Profile_API } from "../../services/api/Profile/profile-api";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { SendEmail } from "../../components/index";
import { errorToast, successToast } from "../../utils/helper";
import { ContentLoadingLoader, SampleToast } from "../../components";

const IFAProfile = observer(function IFAProfile(props) {
  const [isLogin, setIsLogin] = useState();
  const [loginRender, setLoginRender] = useState(false);
  const [render, setRender] = useState(false);
  const [profileData, setProfileData] = useState([]);
  const api = new Profile_API();

  useEffect(() => {
    props.showCommonHeader(true);
    props.showCommonSidebar(true);
    let checkLogin = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        let status = localStorage.getItem("kyc_status");
        if (status != 2) {
          setIsLogin(false);
        }
        else
        {
        setIsLogin(true);
        setLoginRender(true);
        fetchIFAProfile();
        }
      } catch {
        setIsLogin(false);
      }
    };
    return checkLogin();
    // eslint-disable-next-line
  }, []);

  const fetchIFAProfile = () => {
    api.getIFAProfileData().then((res) => {
      if (res.kind === "ok") {
        setProfileData(res.data);
        setRender(true);
      } else {
        setRender(true);
        errorToast(res.error);
      }
    });
  };

  const getInitials = (fullName) => {
    let nameString = fullName.split(" ");
    let initials = nameString.shift().charAt(0);

    if (nameString.length != 0) {
      initials += nameString.pop().charAt(0);
      return initials.toUpperCase();
    } else {
      return initials.toUpperCase();
    }
  };

  const maskNumber = (number, type) => {
    let numString = "";
    if (typeof number != "string") {
      numString = number.toString();
    } else {
      numString = number;
    }
    let lastFour = numString.slice(numString.length - 4);
    switch (type) {
      case "pan":
        return "XXXXXX" + lastFour;
      case "account_number":
        return "XXXX XXXX " + lastFour;
      case "ifsc_code":
        return "XXXXXXX" + lastFour;
      case "adhaar_number":
        return "XXXX XXXX " + lastFour;
      default:
        return "";
    }
  };

  const casingHandler = (wholestring) => {
    let finalString = "";
    let arr = wholestring.split(" ");
    for (let i = 0; i < arr.length; i++) {
      let st = arr[i];
      finalString +=
        String(st).charAt(0).toUpperCase() +
        String(st).slice(1).toLocaleLowerCase() +
        " ";
    }
    return finalString;
  };

  const onClickReferralCode = async (referralCode) => {
    await navigator.clipboard.writeText(referralCode);
    successToast("Text copied to clipboard", false);
  };

  if (isLogin === false) {
    return <Redirect to="/" />;
  }
  return (
    <div>
      {loginRender === false ? (
        <div />
      ) : (
        <div className="profile_div_container">
          {render === false ? (
            <ContentLoadingLoader />
          ) : (
            <div className="profile_rest_div">
              {/* heading */}

              {/* column container 1 */}

              <div className="profile_border">
                <div className="profile_section">
                  <div className="profile_initials">
                    <span className="profile_circle">
                      <p
                        style={{
                          marginLeft: "auto",
                          fontWeight: 200,
                          fontSize: "37px",
                          marginTop: "40px",
                          textAlign: "center",
                        }}
                      >
                        {profileData !== undefined
                          ? profileData.name
                            ? `${getInitials(profileData.name)}`
                            : "-"
                          : "-"}
                      </p>
                    </span>
                    <span>
                      <h1 className="profile_section_heading">
                        {profileData !== undefined
                          ? profileData.name
                            ? profileData.name
                            : "-"
                          : "-"}
                      </h1>

                      <div className="profile_tag">
                        <p> Independent Financial Advisor </p>
                      </div>
                      <span className="ifa_email_container">
                        <span className="ifa_profile_email_text">
                          {profileData !== undefined
                            ? profileData.email_id
                              ? profileData.email_id
                              : "-"
                            : "-"}
                        </span>
                        <SendEmail
                          profileData={profileData}
                          setProfileData={setProfileData}
                        />
                      </span>
                    </span>
                  </div>

                  <div className="profile_referral_wrapper">
                    <span className="profile_referral_span">
                      <div
                        className="profile_row_container_ref"
                        style={{
                          display: profileData.referral_code
                            ? "inline"
                            : "none",
                        }}
                      >
                        <p>Referral Code</p>
                      </div>
                      <div
                        className="ifa_profile_referral_code"
                        onClick={async () =>
                          await onClickReferralCode(
                            profileData !== undefined
                              ? profileData.referral_code
                              : ""
                          )
                        }
                      >
                        <p style={{ display: "inline", fontWeight: "500" }}>
                          {profileData !== undefined
                            ? profileData.referral_code
                              ? profileData.referral_code
                              : "-"
                            : "-"}
                        </p>
                        <span style={{ marginLeft: "7px" }}>
                          <ContentCopyIcon fontSize={"string"} />
                        </span>
                      </div>
                    </span>
                  </div>
                </div>
                <div className="profile_row">
                  <h1 className="profile_client_heading"> Personal Details</h1>

                  <div className="profile_row_container">
                    <div
                      className="profile_row_container_box"
                      style={{
                        display: profileData.mobile ? "inline" : "none",
                      }}
                    >
                      <div className="profile_row_container_title">
                        <p>Mobile</p>
                      </div>
                      <div className="profile_row_container_subtitle">
                        <p>
                          {profileData !== undefined
                            ? profileData.mobile
                              ? profileData.mobile
                              : "-"
                            : "-"}
                        </p>
                      </div>
                    </div>

                    <div
                      className="profile_row_container_box"
                      style={{
                        display: profileData.address ? "inline" : "none",
                      }}
                    >
                      <div className="profile_row_container_title">
                        <p>Address</p>
                      </div>
                      <div className="profile_row_container_subtitle">
                        <p style={{ marginRight: "20px" }}>
                          {profileData !== undefined
                            ? profileData.address
                              ? casingHandler(profileData.address)
                              : "-"
                            : "-"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="profile_row">
                  <h1 className="profile_client_heading"> Bank Details</h1>
                  <div className="profile_row_container">
                    <div
                      className="profile_row_container_box"
                      style={{
                        display: profileData.account_holder_name
                          ? "inline"
                          : "none",
                      }}
                    >
                      <div className="profile_row_container_title">
                        <p>Account Holder Name</p>
                      </div>
                      <div className="profile_row_container_subtitle">
                        <p>
                          {profileData !== undefined
                            ? profileData.account_holder_name
                              ? profileData.account_holder_name
                              : "-"
                            : "-"}
                        </p>
                      </div>
                    </div>

                    <div
                      className="profile_row_container_box"
                      style={{
                        display: profileData.account_number ? "inline" : "none",
                      }}
                    >
                      <div className="profile_row_container_title">
                        <p>Account Number </p>
                      </div>
                      <div className="profile_row_container_subtitle">
                        <p>
                          {profileData !== undefined
                            ? profileData.account_number
                              ? maskNumber(
                                  profileData.account_number,
                                  "account_number"
                                )
                              : "-"
                            : "-"}
                        </p>
                      </div>
                    </div>

                    <div
                      className="profile_row_container_box"
                      style={{
                        display: profileData.ifsc_code ? "inline" : "none",
                      }}
                    >
                      <div className="profile_row_container_title">
                        <p>IFSC code</p>
                      </div>
                      <div className="profile_row_container_subtitle">
                        <p>
                          {profileData !== undefined
                            ? profileData.ifsc_code
                              ? maskNumber(profileData.ifsc_code, "ifsc_code")
                              : "-"
                            : "-"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="profile_row">
                  <h1 className="profile_client_heading">Documents</h1>
                  <div className="profile_row_container">
                    <div
                      className="profile_row_container_box"
                      style={{
                        width: profileData.pan_number ? "none" : "480px",
                        display: profileData.pan_number ? "inline" : "none",
                      }}
                    >
                      <div className="profile_row_container_title">
                        <p>PAN </p>
                      </div>
                      <div className="profile_row_container_subtitle">
                        <p>
                          {profileData !== undefined
                            ? profileData.pan_number
                              ? maskNumber(profileData.pan_number, "pan")
                              : "-"
                            : "-"}
                        </p>
                      </div>
                    </div>

                    <div
                      className="profile_row_container_box"
                      style={{
                        display: profileData.adhaar_number ? "inline" : "none",
                      }}
                    >
                      <div className="profile_row_container_title">
                        <p>Aadhaar Number</p>
                      </div>
                      <div className="profile_row_container_subtitle">
                        <p>
                          {profileData !== undefined
                            ? profileData.adhaar_number
                              ? maskNumber(
                                  profileData.adhaar_number,
                                  "adhaar_number"
                                )
                              : "-"
                            : "-"}
                        </p>
                      </div>
                    </div>

                    <div
                      className="profile_row_container_box"
                      style={{
                        display: profileData.gst_number ? "inline" : "none",
                      }}
                    >
                      <div className="profile_row_container_title">
                        <p>GST Number</p>
                      </div>
                      <div className="profile_row_container_subtitle">
                        <p>
                          {profileData !== undefined
                            ? profileData.gst_number
                              ? profileData.gst_number
                              : "-"
                            : "-"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <SampleToast />
        </div>
      )}
    </div>
  );
});

export default IFAProfile;
