import { Instance, SnapshotOut, types } from "mobx-state-tree";

const partners = types.model("partners").props({
  title: types.optional(types.string, ""),
  description: types.optional(types.string, ""),
});

export const GetPoolByIdModel = types
  .model("GetPoolById")
  .props({
    id: types.optional(types.string, ""),
    pool_title: types.optional(types.string, ""),
    risk_rate: types.optional(types.string, ""),
    badge_title: types.optional(types.string, ""),
    tenure: types.optional(types.string, ""),
    tenure_type: types.optional(types.string, ""),
    interest_rate: types.optional(types.string, ""),
    minimum_investment: types.optional(types.string, ""),
    start_date: types.optional(types.string, ""),
    end_date: types.optional(types.string, ""),
    total_investments: types.optional(types.string, ""),
    is_shielded: types.optional(types.string, ""),
    description: types.optional(types.string, ""),
    logo_color: types.optional(types.string, ""),
    badge_color: types.optional(types.string, ""),
    risk_color: types.optional(types.string, ""),
    partners: types.optional(types.array(partners), []),
  })
  .views((self) => ({}))
  .actions((self) => ({}));

type GetPoolByIdType = Instance<typeof GetPoolByIdModel>;
export interface GetPoolById extends GetPoolByIdType {}

type GetPoolByIdSnapshotType = SnapshotOut<typeof GetPoolByIdModel>;
export interface GetPoolByIdSnapshot extends GetPoolByIdSnapshotType {}
