import { Instance, SnapshotOut, types } from "mobx-state-tree";

export const ClientlistModel = types
  .model("ClientlistModel")
  .props({
    uuid: types.optional(types.string, ""),
    name: types.optional(types.string, ""),
    mobile: types.optional(types.string, ""),
    available_funds: types.optional(types.string, ""),
    locked_funds: types.optional(types.string, ""),
    invested_amount: types.optional(types.string, ""),
    received_amount: types.optional(types.number, 0),
    withdrawn_amount: types.optional(types.number, 0),
    aum: types.optional(types.number, 0),
    withdrawable: types.optional(types.number, 0),
    freedom_plan_invested_amount: types.optional(types.number, 0),
    freedom_plan_withdrawable: types.optional(types.number, 0),
    kyc_status: types.optional(types.number, 0),
    action: types.optional(types.string, ""),
    net_worth: types.optional(types.number, 0),
    remarks: types.optional(types.string, ""),
    remarks_color: types.optional(types.string, ""),
    daily_growth: types.optional(types.string, ""),
    overall_fund: types.optional(types.string, ""),
    invested_pools: types.optional(
      types.array(
        types.model({ pool_template_uuid: "", investment_amount: 0 })
      ),
      []
    ),
    consent_status: types.optional(types.number, 0),
    soa_consent: types.optional(types.number, 0),
  })
  .views((self) => ({}))
  .actions((self) => ({}));

type ClientlistModelDataType = Instance<typeof ClientlistModel>;
export interface Clientlist extends ClientlistModelDataType {}

type ClientlistModelSnapshotType = SnapshotOut<typeof ClientlistModel>;
export interface ClientlistSnapshot extends ClientlistModelSnapshotType {}
