import React, { useEffect, useState } from "react";
import "./pagenotfound.css";
import { Auth } from "aws-amplify";
import { Redirect } from "react-router-dom";
// import HideIFA from "../HideIFA/hideIFA";

const Pagenotfound = (props) => {
  const [isLogin, setIsLogin] = useState();
  const [loginRender, setLoginRender] = useState(false);
  // const [hideIFA, setHideIFA] = useState(false);

  // function handleWindowSizeChange() {
  //   if (window.innerWidth <= 1080 || window.innerHeight <= 600) {
  //     setHideIFA(true);
  //     props.showCommonHeader(false);
  //     props.showCommonSidebar(false);
  //   } else {
  //     setHideIFA(false);
  //     props.showCommonHeader(true);
  //     props.showCommonSidebar(true);
  //   }
  // }

  // useEffect(() => {
  //   window.addEventListener("resize", handleWindowSizeChange);
  //   if (window.innerWidth <= 1080 || window.innerHeight <= 600) {
  //     setHideIFA(true);
  //     props.showCommonHeader(false);
  //     props.showCommonSidebar(false);
  //   }
  //   return () => {
  //     window.removeEventListener("resize", handleWindowSizeChange);
  //   };
  // }, []);


  // check if user login or not
  useEffect(() => {
    let checkLogin = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        setIsLogin(true);
        setLoginRender(true);
        props.showCommonHeader(true);
        props.showCommonSidebar(true);
      } catch (err) {
        setIsLogin(false);
      }
    };
    return checkLogin();
    // eslint-disable-next-line
  }, []);

  // // if user is not login then redirect to login page
  if (isLogin === false) {
    return <Redirect to="/" />;
  }
  // if (hideIFA) {
  //   return <HideIFA />;
  // }

  return (
    <div>
      {loginRender === false ? (
        <div />
      ) : (
        <div className="pagenotfound_container">
          <div className="pagenotfound_rest_container">
            <div>
              <p className="pagenotfound_container_title">404</p>
              <p className="pagenotfound_container_subtitle">
                Sorry, the page you are looking for is not available.
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Pagenotfound;
