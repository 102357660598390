import * as React from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const LabelWithSortComponent = ({ label, id, type, asc,selected, setAsc, setSelected, setSelectedType,onClick,setSortingOrder}) => {
 
    return (
      <div
        style={{ display: "flex", cursor: "pointer",alignItems: "center" }}
        onClick={() => {
          setAsc({ ...asc, [id]: selected == id ? !asc[id] : asc[id] });
          setSelectedType(type);
          setSelected(id);
          setSortingOrder && setSortingOrder(asc[id]? "ASC" : "DESC")
          onClick && onClick();
       
        }}
      >
        <div style={{marginRight: "4%"}}>{label}</div>
          {asc[id] ? (
            <ArrowUpwardIcon
              sx={{
                fontSize: "21px",
                color: selected == id ? "black" : "#d1dae2",
              }}
            />
          ) : (
            <ArrowDownwardIcon
              style={{
                fontSize: "21px",
                color: selected == id ? "black" : "#d1dae2",
              }}
            />
          )}
        
      </div>
    );
};

export default LabelWithSortComponent;