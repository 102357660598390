import React, { useEffect, useState } from "react";

import Loader from "react-loader-spinner";
import {
  convertIntoDefaultState,
  convertIntoErrorState,
  errorToast,
  infoToast,
  successToast,
  checkValidPan,
  setDisableState,
  checkValidIfsc,
} from "../../utils/helper";
import { InputBoxV2, UploadInputV2 } from "../../components/index.js";
import { clientBankDetails } from "../../constant/signupState";
import "./addClientKycBankDetails.css";
import Checkbox from "@mui/material/Checkbox";
import { KYC_API_V2 } from "../../services/api";
import { eventLog } from "../../utils/firebase";
import config from "../../config/settings";


import {penny_drop_max_retry} from "../../config/settings"
/**
 * 0 indictates penny drop has not been initiated
 * 1 indictates that penny drop has been initiated but is success
 * 2 indictaes that penny drop has been failed
 */

const AddClientKycBankDetails = (props) => {
  const maxRetries = 2;
  const api = new KYC_API_V2();
console.log(maxRetries,"maxretries");
  const [addClientBankDetails, setAddClientBankDetails] = useState(
    convertIntoDefaultState(clientBankDetails)
  );

  const [addClientBankDetailsError, setAddClientBankDetailsError] = useState(
    convertIntoErrorState(clientBankDetails)
  );

  const [disableBankDetails, setDisableBankDetails] = useState(
    convertIntoErrorState(clientBankDetails)
  );

  const [uploadBankImage, setUploadBankImage] = useState(false);
  const [bankVerify, setBankVerified] = useState(false);
  const [pennyDropStatus, setPennyDropStatus] = useState(0);
  const [loader, setLoader] = useState(false);
  const [bankData, setBankData] = useState("");

  useEffect(() => {
    if (
      props.userData &&
      props.userData !== "" &&
      props.userData.kyc_status === 3 &&
      !props.userData.has_bank
    ) {
      setDisableState(
        props.userData,
        disableBankDetails,
        setDisableBankDetails
      );  
      if (props.investorType === "Individual" && !props?.userData?.pennyDrop_isSuccessful && props?.userData?.pennyDropTriedCounts<maxRetries) {
        setPennyDropStatus(0);
      } else {
                setPennyDropStatus(2);
      }
    }
    if (props.userData && props.userData != "") {
      setAddClientBankDetails({
        account_number: props.userData.account_number,
        ifsc_code: props.userData.ifsc_code,
        cancel_cheque: props.userData.cancel_cheque,
      });
      if (props.investorType === "Individual" && !props.userData.has_bank && props?.userData?.pennyDropTriedCounts<2) {
        setPennyDropStatus(0);
      } else if (
        props.investorType === "Individual" &&
        props.userData.has_bank &&
        props.userData.cancel_cheque === ""
      ) {
        setPennyDropStatus(1);
      } else {
        setPennyDropStatus(2);
      }
    }
  }, []);
 
  useEffect(() => {
    if (props.userData.pennyDropTriedCounts == 2 && props.userData.pennyDrop_isSuccessful == 0) {
      setPennyDropStatus(2);
    }
  }, [props.userData.pennyDropTriedCounts, props.userData.pennyDrop_isSuccessful])
  useEffect(() => {
    //update bank details for parent
    props.setBankDetails({
      ...addClientBankDetails,
      bank_data: bankData,
      bank_verify: pennyDropStatus == 1 ? true : false,
    });

    //remove error
    props.setErrorState({
      ...addClientBankDetailsError,
    });
  }, [addClientBankDetails, pennyDropStatus, bankData]);

  // useEffect(() => {
  //   if (props.investorType && props.investorType != "Individual") {
  //     setPennyDropStatus(2);
  //   }
  // }, [props.investorType]);
  

  //check for error
  useEffect(() => {
    if (props.errorState && props.errorState != null) {
      setAddClientBankDetailsError({
        ...addClientBankDetailsError,
        ...props.errorState,
      });
    }
  }, [props]);

  const verifyBankDetail = async (e) => {
    e.preventDefault(); 

    setLoader(true);

    //check for bank ifsc code
    if (!checkValidIfsc(addClientBankDetails["ifsc_code"])) {
      errorToast("Please provide valid ifsc code.", true);
      setLoader(false);
      return;
    }
    //check for bank consent
    if (!addClientBankDetails["is_bank_consent_accepted"]) {
      errorToast("Please provide consent in order to move ahead.", true);
      setLoader(false);
      return;
    }

    let payload = {
      ...addClientBankDetails,
      user_id: props?.basicKycDetails?.user_id,
    };
    

    // call the penny drop check api
    await api.validateIfscAndAccount(payload).then((res) => {
      if (res.kind == "ok") {
        eventLog({
          type: "bank_verify",
          action: {
            trigger: "true",
          },
        });
        const data = res.data;
        setBankData(data.bank_data);
        setPennyDropStatus(1);
        successToast(data.message);
        setBankVerified(true);
        setLoader(false);
      } else if (
        res.error ==
        "Account number already exists in our system. Please provide a different account number."
      ) {
        errorToast(res.error, true);
        setLoader(false);
      }
      else if(props.userData.pennyDropTriedCounts<maxRetries){
        errorToast("Couldn't verify account number and IFSC please provide valid data", true);
        setLoader(false);
        props.setUserData({...props.userData,pennyDropTriedCounts:props.userData.pennyDropTriedCounts+1});
      }
       else {
        eventLog({
          type: "bank_verify",
          action: {
            trigger: "false",
          },
        });
        setUploadBankImage(true);
        setPennyDropStatus(2);
        errorToast(res.error, true);
        setLoader(false);
        return;
      }
    });
  };
  return (
    <div
      className="add_client_kyc_basic_details_container"
      style={{ display: !props.visible ? "none" : "" }}
    >
      <div className="add_client_kyc_basic_details_main_container">
        <h4 className="add_client_kyc_pre_pan_main_container_title">
          {props.investorType != "Individual"
            ? "Entity Bank Details"
            : "Bank Details"}
        </h4>
        {/* Account number */}
        <InputBoxV2
          label={"Account Number"}
          id="account_number"
          type={
            props.userData.account_number
              ? "text"
              : clientBankDetails["account_number"].type
          }
          disabled={
            props.totalDisable || disableBankDetails["account_number"]
              ? true
              : props.investorType === "Individual"
              ? pennyDropStatus == 1 
                ? true
                : false
              : false
          }
          maxLength="20"
          accountCheck={true}
          value={addClientBankDetails["account_number"]}
          setValue={setAddClientBankDetails}
          object={addClientBankDetails}
          required={clientBankDetails["account_number"]["required"]}
          errorObject={addClientBankDetailsError}
          setErrorObject={setAddClientBankDetailsError}
          isError={addClientBankDetailsError["account_number"]}
          errorText={
            addClientBankDetails["account_number"]
              ? "Invalid Account Number"
              : "This field cannot be empty."
          }
        />

        {/* IFSC  code */}
        <InputBoxV2
          label={"IFSC"}
          id="ifsc_code"
          type={clientBankDetails["ifsc_code"].type}
          disabled={
            props.totalDisable || disableBankDetails["ifsc_code"]
              ? true
              : props.investorType === "Individual"
              ? pennyDropStatus == 1 
                ? true
                : false
              : false
          }
          maxLength="11"
          autoCapitalize={true}
          value={addClientBankDetails["ifsc_code"]}
          setValue={setAddClientBankDetails}
          object={addClientBankDetails}
          checkForIFSC={true}
          required={clientBankDetails["ifsc_code"]["required"]}
          errorObject={addClientBankDetailsError}
          setErrorObject={setAddClientBankDetailsError}
          isError={addClientBankDetailsError["ifsc_code"]}
          errorText={
            addClientBankDetails["ifsc_code"]
              ? "Invalid IFSC. Please check and re-enter."
              : "This field cannot be empty."
          }
        />
       { props.userData.pennyDropTriedCounts>0 &&
            <span style={{marginBottom:"10px"}}>Attempts left: {maxRetries-props.userData.pennyDropTriedCounts}</span>
        }
        {/**upload cancel cheque image */}
        {pennyDropStatus == 2 ? (
          <UploadInputV2
            id="cancel_cheque"
            label={"Cancelled Cheque/Bank Statement"}
            required={true}
            object={addClientBankDetails}
            setValue={setAddClientBankDetails}
            errorObject={addClientBankDetailsError}
            setErrorObject={setAddClientBankDetailsError}
            isError={addClientBankDetailsError["cancel_cheque"]}
            disabled={props.totalDisable || disableBankDetails["cancel_cheque"]}
            imageSizeLimit={15} //required if note is provided
            note={
              "Upload image in JPG/JPEG/PNG/PDF format. Max. file size allowed is 15 MB"
            }
            additionalPath={props.userID}
          />
        ) : null}

        {/*  bank consent */}
        {pennyDropStatus == 0 ? (
          <div className="add_cientkycv2_bank_consent_checkbox">
            <Checkbox
              checked={addClientBankDetails["is_bank_consent_accepted"]}
              onChange={() =>
                setAddClientBankDetails({
                  ...addClientBankDetails,
                  is_bank_consent_accepted:
                    !addClientBankDetails["is_bank_consent_accepted"],
                })
              }
            />

            <div className="add_cientkycv2_bank_consent_checkbox_condition">
              I/we have specific consent from the Investor for onboarding with
              1Invest and sharing their bank details and related information,
              and verifying their bank details for the purpose of validation and
              verification.
            </div>
          </div>
        ) : null}

        {/* verify bank cta */}
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          <div
            className="add_client_kyc_bank_verify_cta"
            style={{
              display:
                pennyDropStatus == 2 || pennyDropStatus == 1
                  ? "none"
                  : addClientBankDetails["account_number"] &&
                    addClientBankDetails["ifsc_code"]?.length == 11
                  ? "flex"
                  : "none",
            }}
            onClick={(e) => {
              verifyBankDetail(e);
            }}
          >
            {loader ? (
              <Loader type="TailSpin" color="#FFFFFF" height={25} width={25} />
            ) : (
              <p className="add_client_kyc_bank_verify_cta_text">Verify</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddClientKycBankDetails;
