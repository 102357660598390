import React, { useEffect, useState } from "react";
import "./investmentrequests.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Pagination,
  Modal,
  Stepper,
  StepLabel,
  Step,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Redirect, useHistory } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Auth } from "aws-amplify";
import SearchIcon from "@mui/icons-material/Search";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import {
  convertAmount,
  errorToast,
  successToast,
  convertUTCDateToLocalDate,
  convertedAsLocal,
} from "../../utils/helper";
import { ContentLoadingLoader, SampleToast, LabelWithSortComponent } from "..";
import { User_API, Pools_API } from "../../services/api";
import { timeStampHelper, sorting } from "../../utils/helper";
import Button from "@mui/material/Button";
import ReplayIcon from "@mui/icons-material/Replay";
import TelegramIcon from "@mui/icons-material/Telegram";
import { kycStatus_API } from "../../services/api/kycstatus/kycstatus-api";
import moment from "moment";
// import HideIFA from "../HideIFA/hideIFA";
import {
  withdrawModalInfo,
  withdrawModalTick,
} from "../../assets/images/index";

const tableContainer = {
  marginTop: "20px",
  width: "92%",
  borderTopLeftRadius: "5px",
  borderTopRightRadius: "5px",
  borderTop: "1px solid #c7c7c7",
  borderLeft: "1px solid #c7c7c7",
  borderRight: "1px solid #c7c7c7",
  alignSelf: "center",
  overflow: "scroll",
};

const tableHeader = {
  backgroundColor: "#f6f6f6",
  fontWeight: "600",
  fontSize: 16,
  fontFamily: "Asap",
  color: "#1f1f1f",
  borderRight: "1px solid #c7c7c7",
  paddingTop: "15px",
  paddingLeft: "10px",
  paddingRight: "0px",
  paddingBottom: "15px",
};

const tableRow = {
  fontSize: 14,
  fontFamily: "Asap",
  fontWeight: "400",
  color: "#1c1c1c",
  borderRight: "1px solid #c7c7c7",
  paddingTop: "10px",
  paddingLeft: "10px",
  paddingRight: "0px",
  paddingBottom: "10px",
};

const steps = [
  "Request sent to client",
  "Request approved by client",
  "Investment Activated",
];

const linkStatuses = ["Unpaid", "Paid", "In-Process"];

const statusMapping = {
  Unpaid: "Pending",
  Paid: "Success",
  "In-Process": "Activated",
};

const InvestmentRequests = function PaymentLinks(props) {
  const api = new User_API();
  const apiPayment = new Pools_API();
  const navigate = useHistory();
  const [page, setPage] = useState(0);
  const rowsPerPage = 15;
  const [statusUpdate, setStatusUpdate] = useState(false);

  /* sorting */
  const asc_label_sort = {
    sent_at: false,
    name: false,
    amount: false,
  };

  const renderIcon = (currStep) => {
    return (
      <>
        {linkStatuses.indexOf(transaction.status) >= currStep ? (
          <img className="fa-download" src={withdrawModalTick} />
        ) : (
          <img className="fa-download" src={withdrawModalInfo} />
        )}
      </>
    );
  };

  const [asc, setAsc] = useState(asc_label_sort);
  const [selected, setSelected] = useState("sent_at");
  const [selectedType, setSelectedType] = useState("timestamp");
  const [investmentModal, setInvestmentStatusModal] = useState(false);
  const [transaction, setTransaction] = useState(null);

  useEffect(() => {
    if (statusUpdate) {
      setStatusUpdate(false); // Reset statusUpdate to false
      props.setClicked(false);
    }
  }, [statusUpdate]);

  // resending the payment link when the time expires
  const handleSubmit = async (row, index) => {
    if (props.clicked === false) {
      var data = [];
      data.push({
        investor_id: row.investor_id,
        pool_template_id: row.pool_id,
        investment_amount: row.amount,
      });
      let inputs = {
        investments: data,
      };
      console.log("Final data", inputs);
      await apiPayment.sendPaymentLink(inputs).then((res) => {
        if (res.kind === "ok") {
          successToast("Investment request(s) sent successfully.");
          props.setClicked(true);
          setStatusUpdate(true); // Set statusUpdate to true
          navigate.push("/transaction-requests");
        } else {
          errorToast(res.error);
        }
      });
    }
  };

  // request search function
  const requestSearch = (event) => {
    setPage(0);
    const filter_rows = props.filterData.filter((row) => {
      return row.name.toLowerCase().includes(event.target.value.toLowerCase());
    });
    props.setPaymentData(filter_rows);
  };

  // handle page change function
  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  const getDate = (index) => {
    const active = linkStatuses.indexOf(transaction.status);
    if (index === 0) {
      return moment(transaction.sent_at).format("YYYY/MM/DD | hh:mm A");
    } else if (index === 1 && active >= index && transaction.approval_at) {
      return moment(transaction.approval_at).format("YYYY/MM/DD | hh:mm A");
    } else if (index === 2 && active >= index && transaction.investment_start_date) {
      return moment(transaction.investment_start_date).format("YYYY/MM/DD | hh:mm A");
    }
    return "";
  };
  

  return (
    <div>
      <div className="paymentlinks_div_container">
        {/* table */}
        <div>
          {props.render === false ? (
            <ContentLoadingLoader />
          ) : (
            <div className="paymentlinks_div_tablecontainer">
              {/* header */}

              {/* search_bar */}
              <div className="paymentlinks_search_bar_div">
                <div className="paymentlinks_search_bar">
                  {/* icon */}
                  <SearchIcon
                    style={{
                      fontSize: "20px",
                      paddingRight: "10px",
                      color: "#555555",
                    }}
                  />
                  {/* input box */}
                  <input
                    type="text"
                    placeholder="Search by Name"
                    className="paymentlinks_search_bar_input"
                    onChange={requestSearch}
                  />
                </div>
              </div>

              {/* table */}
              <TableContainer style={tableContainer}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" style={tableHeader}>
                        <LabelWithSortComponent
                          label="Request Date & Time"
                          id="sent_at"
                          type="timestamp"
                          asc={asc}
                          selected={selected}
                          setAsc={setAsc}
                          setSelected={setSelected}
                          setSelectedType={setSelectedType}
                        />
                      </TableCell>

                      <TableCell align="left" style={tableHeader}>
                        <LabelWithSortComponent
                          label="Client Name"
                          id="name"
                          type="string"
                          asc={asc}
                          selected={selected}
                          setAsc={setAsc}
                          setSelected={setSelected}
                          setSelectedType={setSelectedType}
                        />
                      </TableCell>
                      <TableCell align="left" style={tableHeader}>
                        Mobile No.
                      </TableCell>
                      <TableCell align="left" style={tableHeader}>
                        Investment Plan
                      </TableCell>
                      <TableCell align="left" style={tableHeader}>
                        <LabelWithSortComponent
                          label="Investment Amount"
                          id="amount"
                          type="number"
                          asc={asc}
                          selected={selected}
                          setAsc={setAsc}
                          setSelected={setSelected}
                          setSelectedType={setSelectedType}
                        />
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          ...tableHeader,
                        }}
                      >
                        Status
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          ...tableHeader,
                          borderRight: "none",
                        }}
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.paymentData
                      .sort((a, b) =>
                        asc[selected]
                          ? sorting(a, b, selected, selectedType, "asc")
                          : sorting(a, b, selected, selectedType, "desc")
                      )
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => (
                        <TableRow
                          key={index}
                          style={{
                            backgroundColor:
                              index % 2 === 0 ? "#fafafa" : "#fff",
                          }}
                        >
                          <TableCell style={tableRow} align="left">
                            {convertedAsLocal(row.unformatted_sent_at)}
                          </TableCell>

                          <TableCell style={tableRow} align="left">
                            {row.name}
                          </TableCell>
                          <TableCell style={tableRow} align="left">
                            {row.mobile}
                          </TableCell>
                          <TableCell style={tableRow} align="left">
                            {row.pool_issue}
                          </TableCell>
                          <TableCell style={tableRow} align="left">
                            {`₹ ${convertAmount(row.amount)}`}
                          </TableCell>
                          <TableCell
                            style={{
                              ...tableRow,
                            }}
                            align="left"
                          >
                            <p
                              className={`${
                                row.status === "In-Process"
                                  ? "paymentlinks_status_text_1"
                                  : row.status === "Paid"
                                  ? "paymentlinks_status_success"
                                  : row.status === "Unpaid"
                                  ? "paymentlinks_status_text_5"
                                  : "paymentlinks_status_text_0"
                              } 
                                    ${
                                      linkStatuses.includes(row.status)
                                        ? "status-link"
                                        : ""
                                    }`}
                              onClick={() => {
                                if (linkStatuses.includes(row.status)) {
                                  setInvestmentStatusModal(true);
                                  setTransaction(row);
                                }
                              }}
                            >
                              {statusMapping[row.status] || row.status}
                            </p>
                          </TableCell>
                          <TableCell
                            style={{
                              ...tableRow,
                              borderRight: "none",
                            }}
                            align="left"
                          >
                            <div>
                              <button
                                className="resend_button"
                                onClick={() => handleSubmit(row, index)}
                                disabled={
                                  row.isdisabled === true ? true : false
                                }
                                style={{
                                  cursor: row.isdisabled
                                    ? "not-allowed"
                                    : "pointer",
                                }}
                              >
                                <TelegramIcon
                                  className={
                                    row.status === "Cancelled"
                                      ? "resend_payment_link_replay_button_disabled"
                                      : row.status === "Expired"
                                      ? "resend_payment_link_replay_button"
                                      : "resend_payment_link_replay_button_disabled"
                                  }
                                />
                              </button>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                {/* if no data available */}
                {props.paymentData.length === 0 ? (
                  <div className="no_row_found_div">
                    <FindInPageIcon
                      style={{
                        fontSize: "100px",
                        color: "#555555",
                      }}
                    />
                    <p className="no_row_found">No row found</p>
                  </div>
                ) : (
                  <div />
                )}
              </TableContainer>
              {/* pagination */}
              <div className="pagination_div">
                <Pagination
                  color="standard"
                  count={Math.ceil(props.paymentData.length / rowsPerPage)}
                  page={page + 1}
                  variant="outlined"
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                  onChange={handleChangePage}
                  shape="rounded"
                />
              </div>
            </div>
          )}
        </div>
        {investmentModal && (
          <Modal
            open={investmentModal}
            onClose={() => setInvestmentStatusModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
              backgroundColor: "rgba(102, 102, 102, 0.40)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="client_investment_status_modal_div">
              <div className="investment_status_modal_header">
                <div style={{ float: "left", width: "60%" }}>
                  <h1 className="investment_status_modal_header_title">
                    Investment Details
                  </h1>
                </div>
                <div
                  style={{ float: "right", cursor: "pointer" }}
                  onClick={props.handleClose}
                >
                  <h1 className="investment_status_modal_header_title">
                    <CloseIcon
                      onClick={() => setInvestmentStatusModal(false)}
                    />
                  </h1>
                </div>
              </div>
              <div className="investment-status-body">
                {transaction.pool_issue}
              </div>
              <div className="investment-amount-body">
                {`₹ ${convertAmount(transaction.amount)}`}
              </div>
              <div className="investment-details-model-slider">
                {renderIcon(0)}
                <div
                  className="investment-details-model-line"
                  style={{
                    border:
                      linkStatuses.indexOf(transaction.status) >= 0
                        ? "1px solid #3A86FF"
                        : "auto",
                  }}
                ></div>
                {renderIcon(1)}
                <div
                  className="investment-details-model-line"
                  style={{
                    border:
                      linkStatuses.indexOf(transaction.status) >= 1
                        ? "1px solid #3A86FF"
                        : "auto",
                  }}
                ></div>
                {renderIcon(2)}
              </div>
              <div className="investment-status">
                {steps.map((label, index) => {
                  const isSelected =
                    linkStatuses.indexOf(transaction.status) >= index;
                  return (
                    <div
                      key={index}
                      style={{
                        margin: "10px",
                        fontSize: "14px",
                        color: isSelected ? "#2E2E2E" : "#757575",
                        marginLeft :"20px",  marginRight: "10px" 
                      }}
                    >
                      {label} <br />
                      <span style={{ fontSize: "11px",fontWeight : 400, marginLeft :"20px",  marginRight: "10px" }}>{getDate(index)}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          </Modal>
        )}
        {/* toast container */}
        <SampleToast />
      </div>
    </div>
  );
};

export default InvestmentRequests;
