import { API } from "aws-amplify";
import * as Types from "../api.types";

/**
 * Manages all requests to the API.
 */

export class kycStatus_API {
  /**
   * @description This function is to check what is the kycStatus of a particular ifa
   * @param raw
   * @returns it return boolean value
   */

  async getkycStatusData(): Promise<Types.GetkycStatusData> {
    //call the api try and catch
    try {
      const response: any = await API.get("baseurl", "/ifa/kycStatus", {});

      return { kind: "ok", data: response.data };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }
}
