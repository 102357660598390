
import "./timeout-failure.css";
import timeoutfailed from "../../assets/images/timeoutfailed.svg";
export default function LoaderScreenTimeOut() {
  return (
    <div className="loader_screen_timeout_container">
      <img src={timeoutfailed} />
      {/* <div className="loader_screen_timeout_text">Oops..</div> */}
      <span className="loader_screen_timeout_subtext">This is taking longer than expected. </span>
      <span className="loader_screen_timeout_subtext" style={{marginTop:"10px"}}>We’ll notify you when the transaction is completed.</span>
    </div>
  );
}
