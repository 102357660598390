const firebaseConfig = {
  me: {
    googleAnalytics: {
      apiKey: "AIzaSyCiF8NaiOdV34_Y4ExwTaYssKIdtQUSmMI",
      authDomain: "arthmate-direct-me.firebaseapp.com",
      projectId: "arthmate-direct-me",
      storageBucket: "arthmate-direct-me.appspot.com",
      messagingSenderId: "1093476158861",
      appId: "1:1093476158861:web:c0ae0bb2270777f51dc1c4",
      measurementId: "G-N1SGF4074X",
    },
  },
  dev: {
    googleAnalytics: {
      apiKey: "AIzaSyDxnhxJ6u9cU44r1tfDcSysQFJBCAj4crc",
      authDomain: "arthmate-direct-dev.firebaseapp.com",
      projectId: "arthmate-direct-dev",
      storageBucket: "arthmate-direct-dev.appspot.com",
      messagingSenderId: "111175729429",
      appId: "1:111175729429:web:e63330c27c574e7451291c",
      measurementId: "G-09M5BG1KE5",
    },
  },
  prod: {
    googleAnalytics: {
      apiKey: "AIzaSyCHjfQUwo-tWJCfo0koIaDjq6IFcNe8dTg",
      authDomain: "arthmate-direct.firebaseapp.com",
      projectId: "arthmate-direct",
      storageBucket: "arthmate-direct.appspot.com",
      messagingSenderId: "837600356062",
      appId: "1:837600356062:web:efcb1b08fc7e0393cf444f",
      measurementId: "G-QJSTPR7XL3",
    },
  },
  uat: {
    googleAnalytics: {
      apiKey: "AIzaSyDxnhxJ6u9cU44r1tfDcSysQFJBCAj4crc",
      authDomain: "arthmate-direct-dev.firebaseapp.com",
      projectId: "arthmate-direct-dev",
      storageBucket: "arthmate-direct-dev.appspot.com",
      messagingSenderId: "111175729429",
      appId: "1:111175729429:web:e63330c27c574e7451291c",
      measurementId: "G-09M5BG1KE5",
    },
  },
};

export default firebaseConfig;
