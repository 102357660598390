import React, { useEffect, useState } from "react";
import "./addClientKycNomineeDetails.css";
import Loader from "react-loader-spinner";
import MenuItem from "@mui/material/MenuItem";
import {
  convertIntoDefaultState,
  convertIntoErrorState,
  errorToast,
  infoToast,
  successToast,
  checkValidPan,
  yearsBefore,
  getAge
} from "../../utils/helper";
import { InputBox, InputBoxV2, ErrorValue } from "../../components/index.js";
import { clientNomineeDetails } from "../../constant/signupState";

const relationship = [
  "Father",
  "Mother",
  "Brother",
  "Sister",
  "Wife",
  "Husband",
  "Son",
  "Daughter",
  "Nephew",
  "Niece"
];

const AddClientKycNomineeDetails = (props) => {
  const [addClientNomineeDetails, setAddClientNomineeDetails] = useState(
    convertIntoDefaultState(clientNomineeDetails)
  );

  const [addClientNomineeDetailsError, setAddClientNomineeDetailsError] =
    useState(convertIntoErrorState(clientNomineeDetails));

  const [startDate, setStartDate] = useState("");
  const [maxDate, setMaxDate] = useState("");
  const [currDate, setCurrDate] = useState("");

  //for dob
  useEffect(() => {
    const { cDate, sDate, mDate } = yearsBefore(100);
    setCurrDate(cDate);
    setStartDate(sDate);
    setMaxDate(mDate);
  }, []);

  useEffect(() => {
    props.setNomineeDetails(addClientNomineeDetails);

    //remove error
    props.setErrorState({
      ...addClientNomineeDetailsError,
    });
  }, [addClientNomineeDetails]);

  useEffect(() => {
    if (
      props.userData &&
      props.userData.hasOwnProperty("has_nominee") &&
      props.userData.hasNominee
    ) {
      setAddClientNomineeDetails({
        nominee_name: props.userData.nominee_full_name,
        nominee_mobile_number: props.userData.nominee_phone_number,
        nominee_email_id: props.userData.nominee_email_id,
        nominee_pan: props.userData.nominee_pan_number,
        nominee_relationship: props.userData.nominee_relationship,
        nominee_dob: props.userData.nominee_dob,
      });
    }
  }, []);

  //check for error
  useEffect(() => {
    if (props.errorState && props.errorState != null) {
      setAddClientNomineeDetailsError({
        ...addClientNomineeDetailsError,
        ...props.errorState,
      });
    }
  }, [props]);

  const handleSkipNow = () => {
    //hide nominee screen
    props.setSkippedNominee(true);
  };

  return (
    <div
      className="add_client_kyc_basic_details_container"
      style={{
        display: props.skippedNominee ? "none" : "",
      }}
    >
      <div className="add_client_kyc_basic_details_main_container">
        <div className="add_client_kyc_nominee_main_container_title">
          <span
            style={{
              margin: "0",
              padding: "0",
              fontWeight: "600",
              fontSize: "18px",
              lineHeight: "21px",
            }}
          >
            Nominee Details
          </span>
          {/* <span
            className="add_client_kyc_nominee_text"
            onClick={handleSkipNow}
            style={{ display: props.totalDisable ? "none" : "" }}
          >
            Skip for now
          </span> */}
        </div>
        {/* Nominee name */}
        <InputBoxV2
          label={"Name"}
          id="nominee_name"
          type={clientNomineeDetails["nominee_name"].type}
          disabled={props.totalDisable}
          maxLength="255"
          autoCapitalize={true}
          value={addClientNomineeDetails["nominee_name"]}
          setValue={setAddClientNomineeDetails}
          object={addClientNomineeDetails}
          required={clientNomineeDetails["nominee_name"]["required"]}
          errorObject={addClientNomineeDetailsError}
          setErrorObject={setAddClientNomineeDetailsError}
          isError={addClientNomineeDetailsError["nominee_name"]}
          errorText={
            !addClientNomineeDetails["nominee_name"]
              ? "This field cannot be empty."
              : "Invalid Name"
          }
          inputType={"string"}
        />

        {/* Nominee Relationship */}
        <InputBoxV2
          label={"Relationship"}
          id="nominee_relationship"
          type={clientNomineeDetails["nominee_relationship"].type}
          disabled={props.totalDisable}
          dropdown={true}
          dropDownArray={relationship}
          value={addClientNomineeDetails["nominee_relationship"]}
          setValue={setAddClientNomineeDetails}
          object={addClientNomineeDetails}
          required={clientNomineeDetails["nominee_relationship"]["required"]}
          errorObject={addClientNomineeDetailsError}
          setErrorObject={setAddClientNomineeDetailsError}
          isError={addClientNomineeDetailsError["nominee_relationship"]}
          errorText={
            !addClientNomineeDetails["nominee_relationship"]
              ? "This field cannot be empty."
              : "Invalid Value"
          }
        />

        {/* Nominee DOB */}
        <InputBoxV2
          label={"Date of Birth"}
          id="nominee_dob"
          type={clientNomineeDetails["nominee_dob"].type}
          disabled={props.totalDisable}
          value={addClientNomineeDetails["nominee_dob"]}
          setValue={setAddClientNomineeDetails}
          object={addClientNomineeDetails}
          min={startDate}
          max={maxDate}
          checkForAge={true}
          required={clientNomineeDetails["nominee_dob"]["required"]}
          errorObject={addClientNomineeDetailsError}
          setErrorObject={setAddClientNomineeDetailsError}
          isError={addClientNomineeDetailsError["nominee_dob"]}
          errorText={
            !addClientNomineeDetails["nominee_dob"]
              ? "This field cannot be empty."
              : getAge(addClientNomineeDetails["nominee_dob"]) > 18
              ? "Invalid Date of Birth."
              : "Age must exceed 18 years."
          }
        />

        {/* Nominee Mobile Number */}
        <InputBoxV2
          label={"Mobile Number"}
          id="nominee_mobile_number"
          type={clientNomineeDetails["nominee_mobile_number"].type}
          disabled={props.totalDisable}
          value={addClientNomineeDetails["nominee_mobile_number"]}
          setValue={setAddClientNomineeDetails}
          object={addClientNomineeDetails}
          maxLength="10"
          required={clientNomineeDetails["nominee_mobile_number"]["required"]}
          errorObject={addClientNomineeDetailsError}
          setErrorObject={setAddClientNomineeDetailsError}
          isError={addClientNomineeDetailsError["nominee_mobile_number"]}
          errorText="Please enter a valid 10-digit mobile number."
        />

        {/* Nominee Email ID */}
        <InputBoxV2
          label={"Email ID"}
          id="nominee_email_id"
          type={clientNomineeDetails["nominee_email_id"].type}
          disabled={props.totalDisable}
          value={addClientNomineeDetails["nominee_email_id"]}
          setValue={setAddClientNomineeDetails}
          object={addClientNomineeDetails}
          required={clientNomineeDetails["nominee_email_id"]["required"]}
          errorObject={addClientNomineeDetailsError}
          setErrorObject={setAddClientNomineeDetailsError}
          isError={addClientNomineeDetailsError["nominee_email_id"]}
          errorText="Incorrect email ID format. Please use a valid email ID (example@email.com)."
        />

        {/* Nominee PAN */}
        <InputBoxV2
          label={"PAN"}
          id="nominee_pan"
          type={clientNomineeDetails["nominee_pan"].type}
          disabled={props.totalDisable}
          autoCapitalize={true}
          validatePan={true}
          value={addClientNomineeDetails["nominee_pan"]}
          setValue={setAddClientNomineeDetails}
          maxLength="10"
          object={addClientNomineeDetails}
          required={clientNomineeDetails["nominee_pan"]["required"]}
          errorObject={addClientNomineeDetailsError}
          setErrorObject={setAddClientNomineeDetailsError}
          isError={addClientNomineeDetailsError["nominee_pan"]}
          errorText="Invalid PAN. Please try again."
        />
      </div>
    </div>
  );
};

export default AddClientKycNomineeDetails;
