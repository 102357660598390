import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import "./client-closed-investments.css";
import { useHistory } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import { convertAmount, errorToast } from "../../utils/helper";
import { Client_API } from "../../services/api";
import Loader from "react-loader-spinner";

const tableContainer = {
  width: "92%",
  borderTopLeftRadius: "5px",
  borderTopRightRadius: "5px",
  borderTop: "1px solid #c7c7c7",
  borderLeft: "1px solid #c7c7c7",
  borderRight: "1px solid #c7c7c7",
  alignSelf: "center",
  overflow: "scroll",
};

const tableHeader = {
  backgroundColor: "#ececec",
  fontWeight: "600",
  fontSize: 16,
  fontFamily: "Asap",
  color: "#1f1f1f",
  borderRight: "1px solid #c7c7c7",
  paddingTop: "15px",
  paddingLeft: "10px",
  paddingRight: "0px",
  paddingBottom: "15px",
};

const tableRow = {
  fontSize: 14,
  fontFamily: "Asap",
  fontWeight: "400",
  color: "#1c1c1c",
  borderRight: "1px solid #c7c7c7",
  paddingTop: "10px",
  paddingLeft: "10px",
  paddingRight: "0px",
  paddingBottom: "10px",
};

const ClientClosedInvestments = forwardRef((props, ref) => {
  const navigate = useHistory();
  const api = new Client_API();
  const [render, setRender] = useState(false);
  const [filterClosed, setFilterClosed] = useState([]);
  const [clientClosedInvestments, setClientClosedInvestments] = useState([]);
  const [closedPage, setClosedPage] = useState(0);
  const rowsPerPage = 5;

  useEffect(() => {
    fetchClientClosedInvestments();
    // if (props.closedCall == 0) {
    //   fetchClientClosedInvestments();
    // } else {
    //   setRender(true);
    // }
  }, [props, render]);

  // fetch the client closed investments
  const fetchClientClosedInvestments = async () => {
    // payload
    let payload = {
      client_id: props.param.state.uuid,
    };

    await api.fetchClientInvestments(payload, "closed").then((res) => {
      if (res.kind === "ok") {
        setClientClosedInvestments(res.data);
        setFilterClosed(res.data);
        setRender(true);
        // props.setClosedCall(1);
      } else {
        errorToast(res.error);
        setRender(true);
        // props.setClosedCall(1);
      }
    });
  };

  // handle page of closed investments
  const handleClosedInvestmentPage = (event, newPage) => {
    setClosedPage(newPage);
  };

  // handle search
  useImperativeHandle(ref, () => ({
    async handlerClosedSearch(event) {
      setClosedPage(0);

      // filter closed investments
      const filter_closed_rows = filterClosed.filter((row) => {
        return row.title
          .toLowerCase()
          .includes(event.target.value.toLowerCase());
      });
      setClientClosedInvestments(filter_closed_rows);
    },
  }));

  if (render == false) {
    return (
      <div className="client_closed_investments_loader_div">
        <Loader type="TailSpin" color="#414141" height={80} width={80} />
      </div>
    );
  } else {
    return (
      <div className="client_closed_investments_container">
        {/* <h1 className="client_closed_investments_title">Closed Investments</h1> */}
        <TableContainer style={tableContainer}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell align="left" style={tableHeader}>
                  Investment Plan
                </TableCell>
                <TableCell align="left" style={tableHeader}>
                  Invested Amount
                </TableCell>
                <TableCell align="left" style={tableHeader}>
                  Tenure
                </TableCell>
                <TableCell align="left" style={tableHeader}>
                  Interest Rate
                </TableCell>
                <TableCell align="left" style={tableHeader}>
                  Principal Repaid
                </TableCell>
                <TableCell align="left" style={tableHeader}>
                  Interest Recieved
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    ...tableHeader,
                    borderRight: "none",
                  }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {clientClosedInvestments
                .slice(
                  closedPage * rowsPerPage,
                  closedPage * rowsPerPage + rowsPerPage
                )
                .map((row, index) => {
                  return (
                    <TableRow
                      key={row.uuid}
                      style={{
                        backgroundColor: index % 2 === 0 ? "#fafafa" : "#fff",
                      }}
                    >
                      <TableCell style={tableRow} align="left">
                        {row.title} ({row.investment_start_date})
                      </TableCell>
                      <TableCell style={tableRow} align="left">
                        {`₹ ${convertAmount(row.amount)}`}
                      </TableCell>
                      <TableCell style={tableRow} align="left">
                        {row.tenure} {row.tenure_type == 1 ? "Month" : "Days"}
                      </TableCell>
                      <TableCell style={tableRow} align="left">
                        {row.interest_rate}%
                      </TableCell>
                      <TableCell style={tableRow} align="left">
                        {`₹ ${convertAmount(row.total_principal_received)}`}
                      </TableCell>
                      <TableCell style={tableRow} align="left">
                        {`₹ ${convertAmount(row.total_interest_received)}`}
                      </TableCell>
                      <TableCell
                        style={{
                          ...tableRow,
                          borderRight: "none",
                        }}
                        align="left"
                      >
                        <button
                          className="client_closed_investments_button"
                          onClick={() => {
                            navigate.push("/clients/repayments", {
                              name: props.param.state.name,
                              uuid: props.param.state.uuid,
                              pool_name: `${row.title} (${row.investment_start_date})`,
                              investment_uuid: row.uuid,
                            });
                          }}
                        >
                          View Repayments
                        </button>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          {/* if no data available */}
          {clientClosedInvestments.length === 0 ? (
            <div className="no_row_found_div">
              <FindInPageIcon
                style={{
                  fontSize: "100px",
                  color: "#555555",
                }}
              />
              <p className="no_row_found">No row found</p>
            </div>
          ) : (
            <div />
          )}
        </TableContainer>
        {/* pagination */}
        <div className="pagination_div">
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={clientClosedInvestments.length}
            rowsPerPage={rowsPerPage}
            page={closedPage}
            onPageChange={handleClosedInvestmentPage}
          />
        </div>
      </div>
    );
  }
});

export default ClientClosedInvestments;
