import React, { useEffect, useState } from "react";
import "./clientkyc.css";
import Loader from "react-loader-spinner";
import { errorToast, infoToast, successToast } from "../../utils/helper";
import { Kyc_API } from "../../services/api";
import Auth from "@aws-amplify/auth";
import { eventLog } from "../../utils/firebase";
import { FileInput } from "../../components/index";
const ClientPreKyc = function (props) {
  const api = new Kyc_API();
  const [pan, setPan] = useState("");
  const [panCtaLoader, setPanCtaLoader] = useState(false);

  // send pan value
  useEffect(() => {}, [props]);

  // prevent only digit and alphabet
  const onKeyPress = (e) => {
    const re = /[ `!@#$%^&*()_+\-={};':"|,.<>?~]/g;
    if (re.test(e.key)) {
      e.preventDefault();
    }
  };

  // validate pan number
  const onPanCheck = async () => {
    var pan_format = /(^([a-zA-Z]{5})([0-9]{4})([a-zA-Z]{1})$)/;
    const user = await Auth.currentCredentials();

    // validate pan format
    if (pan_format.test(String(pan))) {
      // payload
      let payload = {
        pan: String(pan).toUpperCase(),
        mode: "duplicate",
        is_entity: props.entityType ? props.entityType : false,
        loan_app_id: props.referral ? props.referral : user.identityId,
      };

      // call the pan check api
      await api.checkPanNumber(payload).then((res) => {
        if (res.kind == "ok") {
          const data = res.data;
          console.log(" data >>>", data);
          if (data.msg == "PAN has been verified successfully.") {
            setTimeout(() => {
              successToast(data.msg);
              setPanCtaLoader(false);
              props.data({
                verified: true,
                exist: false,
                pan: String(pan).toUpperCase(),
              });
            }, 2000);
          } else if (data.mobile) {
            setTimeout(() => {
              infoToast(`${data.msg}, but KYC is incomplete.`);
              setPanCtaLoader(false);
              props.data({
                exist: true,
                pan: String(pan).toUpperCase(),
                mobile: data.mobile,
              });
            }, 2000);
          } else {
            setTimeout(() => {
              successToast(data.msg);
              setPanCtaLoader(false);
              props.data({
                exist: true,
                pan: String(pan).toUpperCase(),
              });
            }, 2000);
          }
        } else if (res.error == "PAN does not exist in the system") {
          setTimeout(() => {
            // infoToast(res.error);
            setPanCtaLoader(false);
            props.data({
              exist: false,
              pan: String(pan).toUpperCase(),
            });
          }, 2000);
        } else {
          errorToast(res.error);
          setPanCtaLoader(false);
        }
      });
    } else {
      setPanCtaLoader(false);
      errorToast("Invalid PAN");
    }
  };

  return (
    <div>
      {/* pan number */}
      <div className="clientkyc_main_input_form" style={{ marginTop: "20px" }}>
        <label className="clientkyc_main_input_label">
          PAN<span style={{ color: "red" }}> *</span>
        </label>
        <input
          type="text"
          maxLength={10}
          value={pan}
          className="clientkyc_main_input_field"
          style={{
            textTransform: "uppercase",
          }}
          onKeyPress={onKeyPress}
          onChange={(event) => setPan(event.target.value)}
        />
      </div>

      {/* verify pan */}
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-end",
        }}
      >
        <button
          onClick={() => {
            setPanCtaLoader(true);
            eventLog({
              type: "verify_pan_cta",
              action: {
                trigger: "true",
              },
            });
            setTimeout(() => {
              onPanCheck();
            }, 2000);
          }}
          className="clientkyc_ckyc_button"
          style={{
            display: pan.length == 10 ? "flex" : "none",
          }}
          disabled={panCtaLoader}
        >
          {panCtaLoader ? (
            <Loader type="TailSpin" color="#fff" height={15} width={15} />
          ) : (
            "Verify PAN"
          )}
        </button>
      </div>
    </div>
  );
};

export default ClientPreKyc;
