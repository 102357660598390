import React, { useState } from "react";
import "./clientPortfolioModel.css";
import Modal from "@mui/material/Modal";
import Loader from "react-loader-spinner";
import CloseIcon from "@mui/icons-material/Close";
import { convertAmount } from "../../utils/helper";

//client Portfoli amount breakup model
export default function ClientPortfolioModel(props) {
  const [requestType, setRequestType] = useState("active_investments");
  return (
    <Modal
      open={props.openModel}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{
        backgroundColor: "rgba(102, 102, 102, 0.40)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className="client_portfolio_request_model_div">
        {/* header */}
        <div className="client_portfolio_request_model_header">
          <div>
            <h1 className="client_portfolio_request_model_header_title">
              Portfolio Value
            </h1>
          </div>
          <div
            style={{cursor: "pointer" }}
            onClick={props.handleClose}
          >
            <h1 className="client_portfolio_request_model_header_title">
              <CloseIcon />
            </h1>
          </div>
        </div>

        {/* title */}
        <div className="client_portfolio_request_model_main_div">
          {/* toggle elements */}
          <div className="client_portfolio_toggle_container">
            <div
              className={
                requestType === "active_investments"
                  ? "client_portfolio_toggle_element_active"
                  : "client_portfolio_toggle_element"
              }
              id="active_investments"
              onClick={() => setRequestType("active_investments")}
            >
              Active Investments
            </div>
            <div
              className={
                requestType === "inProcess_investments"
                  ? "client_portfolio_toggle_element_active"
                  : "client_portfolio_toggle_element"
              }
              id="inProcess_investments"
              onClick={() => setRequestType("inProcess_investments")}
            >
              In-Process Investments
            </div>
          </div>
          <div className="client_portfolio_investments_container">
            <div className="client_portfolio_investments_content">
              <div className="client_portfolio_investments_text">
                Growth Plan - Earnings at Maturity
              </div>
              <div className="client_portfolio_investments_value">
                ₹{" "}
                {requestType == "active_investments"
                  ? convertAmount(props.activeInvestedAmount.earnings_at_maturity)
                  : convertAmount(props.inprocessInvestedAmount.earnings_at_maturity)}
              </div>
            </div>
            <div className="client_portfolio_investments_content">
              <div className="client_portfolio_investments_text">
                Growth Plan - Monthly Earnings
              </div>
              <div className="client_portfolio_investments_value">
                ₹{" "}
                {requestType == "active_investments"
                  ? convertAmount(props.activeInvestedAmount.monthly_earnings)
                  : convertAmount(props.inprocessInvestedAmount.monthly_earnings)}
              </div>
            </div>
            <div className="client_portfolio_investments_content">
              <div className="client_portfolio_investments_text">
                Freedom Plan
              </div>
              <div className="client_portfolio_investments_value">
                ₹{" "}
                {requestType == "active_investments"
                  ? convertAmount(props.activeInvestedAmount.freedom_plan)
                  : convertAmount(props.inprocessInvestedAmount.freedom_plan)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
