import * as React from "react";
import "./action-dropdown.css";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { eventLog } from "../../utils/firebase";
import { Typography } from "@mui/material";

const option_text = {
  fontSize: 14,
  fontFamily: "Asap",
  fontWeight: "400",
  color: "#1c1c1c",
  padding: "7px 7px 7px 10px",
  wordWrap: "break-word",
};

const defaultWidth = 150;

export default function AddNewClient(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    eventLog({
      type: "add_new_client",
      action: {
        trigger: "true",
      },
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        disabled={props.disabled ? props.disabled : false}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        disableElevation={props.disableElevation ? true : false}
        onClick={handleClick}
        sx={{
          border: "1px solid #3a86ff",
          width: props.width ? props.width : defaultWidth,
          height: props.height ? props.height : "",
          textTransform: "none",
          backgroundColor: "#3A86FF",
        }}
        variant="contained"
      >
        {/* text */}
        <p className="action_dropdown_button_text">{props.title}</p>
        {/* Down arrow icon */}
        <KeyboardArrowDownIcon
          style={{
            color: "#FFFFFF",
            fontSize: "25px",
          }}
        />
      </Button>

      {/* add client options */}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          sx: {
            width: props.width ? props.width : defaultWidth,
            "& .MuiMenuItem-root": {
              whiteSpace: "normal",
            },
          },
        }}
      >
        {props.items &&
          props.items.map((item, index) => (
            <React.Fragment key={index}>
              {item.visibility && (
                <MenuItem
                  style={option_text}
                  onClick={() => {
                    setAnchorEl(null);
                    item.action();
                  }}
                >
                  <Typography variant="inherit">{item.label}</Typography>
                </MenuItem>
              )}
            </React.Fragment>
          ))}
      </Menu>
    </div>
  );
}
