import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import "./clientkyclink.css";
import { useHistory, useLocation } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import { ContactsOutlined } from "@mui/icons-material";
import {
  successToast,
  errorToast,
  queryStringToJSON,
  checkForKycDocuments,
  checkForKycDocumentsForErrorState,
  checkForEntityRequiredDetailsForErrorState,
  checkForEntityRequiredDetails,
  checkForNomineeDetails,
  checkForNomineeDetailsForErrorState,
  yearsBefore,
  getAge,
  checkValidPan,
  checkValidIfsc,
  getBeneficialAddressErrorState,
  getErrorStateForAddClient,
  handleDownload,
} from "../../utils/helper";
import checkMark from "../../assets/images/checkMark.svg";
import Loader from "react-loader-spinner";
import {
  FullScreenLoader,
  InfoAlert,
  SampleToast,
  AlertBox,
  ClientOTPModel,
  PdfViewer,
  PanValidationFailed,
} from "../../components";
import {
  ClientPreKyc,
  ClientKycProfile,
  ClientKycBankDetails,
  ClientNomineeDetail,
  ClientKycDocuments,
  EntityKycDetails,
  EntityDocuments,
} from ".";
import Modal from "@mui/material/Modal";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import config from "../../config/settings";
import { KYC_LINK_API_V2 } from "../../services/api";
import {
  authoriserKycDetailsState,
  orgDocumentState,
} from "../../constant/clientKycState";
import { async } from "@firebase/util";
import { eventLog } from "../../utils/firebase";
import DoneSharpIcon from "@mui/icons-material/DoneSharp";
import TcViewer from "../../components/tc-viewer/tc-viewer";

const ClientKycLinkV2 = (props) => {
  let ifa_referral_code = props.match.params.ifa_referral_code;
  const navigate = useHistory();
  const param = useLocation();
  const search = queryStringToJSON(param.search);
  const [showForm, setShowForm] = useState(0);
  const [errors, setErrors] = useState([]);
  const [panSuccessful,setPanSuccessful]=useState(true);
  const [isTNCModalOpen, setIsTNCModalOpen] = useState(false);
  const [clientTNCModelLoader, setClientTNCModelLoader] = useState(false);
  const [clientConsentModel, setClientConsentModel] = useState(false);
  const [clientConsentModelLoader, setClientConsentModelLoader] =
    useState(false);
  const [thankYouPage, setThankYouPage] = useState(false);
  const [autoApproved, setAutoApproved] = useState(false);
  const [ifaName, setIfaName] = useState("");
  const [userId, setUserId] = useState("");
  const [submit, setSubmit] = useState(false);
  const [clientOTP, setClientOTP] = useState("");
  const [investorType, setInvestorType] = useState("");
  const [pan, setPan] = useState("");
  const [basicKycDetails, setBasicKycDetails] = useState("");
  const [entityKycDetails, setEntityKycDetails] = useState("");
  const [kycDocuments, setKycDocuments] = useState("");
  const [bankDetails, setBankDetails] = useState("");
  const [nomineeDetails, setNomineeDetails] = useState("");
  const [entityDocuments, setEntityDocuments] = useState("");
  const [entityDetails, setEntityDetails] = useState("");
  const [documents, setDocuments] = useState("");
  const [profileCompleted, setProfileCompleted] = useState(false);
  const [skipNominee, setSkipNominee] = useState(false);
  const [ckyc, setCkyc] = useState(false);
  const [isEntity, setIsEntity] = useState(false);
  const [loader, setLoader] = useState(false);
  const [panValidationFailed, setPanValidationFailed] = useState(false);
  const [errorState, setErrorState] = useState("");
  const [acceptTerm, setAcceptTerm] = useState(false);
  const [finalSubmitFailed, setFinalSubmitFailed] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [maxDate, setMaxDate] = useState("");
  const [currDate, setCurrDate] = useState("");
  const [ppCheck, setPpCheck] = useState(false);
  const [tcCheck, setTcCheck] = useState(false);
  const [personalCheck, setPersonalCheck] = useState(false);
  const [reportsCheck, setReportsCheck] = useState(false);
  const [fundsCheck, setFundsCheck] = useState(false);
  const [clientKycDetails,   setClientKycDetails] = useState("");
  const [openPrivacyPolicyModal, setOpenPrivacyPolicyModal] = useState(false);

  const api = new KYC_LINK_API_V2();

  // verify the referral code
  useEffect(() => {
    let getData = async () => {
      await api.fetchIfaInfo(ifa_referral_code).then((res) => {
        if (res.kind === "ok") {
          setShowForm(3);
          setIfaName(res.data.name);
        } else {
          setShowForm(1);
        }
      });
    };
    return getData();
    //setShowForm(3);
  }, []);

  //for dob
  useEffect(() => {
    const { cDate, sDate, mDate } = yearsBefore(100);
    setCurrDate(cDate);
    setStartDate(sDate);
    setMaxDate(mDate);
  }, []);

  useEffect(() => {
    if (ppCheck && tcCheck && personalCheck) {
      setAcceptTerm(true);
    } else {
      setAcceptTerm(false);
    }
  }, [ppCheck, tcCheck, personalCheck]);

  useEffect(() => {
    if (
      investorType !== "Individual" &&
      investorType !== "Individual or Sole Proprietorship"
    ) {
      setIsEntity(true);
    }
  }, [investorType]);

  // get cognito id
  useEffect(() => {
    if (
      basicKycDetails.hasOwnProperty("user_id") &&
      basicKycDetails["user_id"]
    ) {
      setUserId(basicKycDetails["user_id"]);
    }
  }, [basicKycDetails]);

  // set entity details
  useEffect(() => {
    setEntityDetails({
      ...entityKycDetails,
      ...entityDocuments,
    });
  }, [entityDocuments, entityKycDetails, errorState]);

  //update main document from each component
  useEffect(() => {
    setDocuments({
      ...documents,
      client_details: basicKycDetails,
      kyc_documents: kycDocuments,
      bank_details: bankDetails,
      nominee_details: nomineeDetails,
    });
    if (
      investorType == "Individual" &&
      ckyc &&
      bankDetails.bank_verify === true
    ) {
      setAutoApproved(true);
    }
  }, [basicKycDetails, bankDetails, nomineeDetails, kycDocuments]);

  const showThankYouPage = (type) => {
    if (type === "approved") {
      return (
        <div className="thank_you_main_container_new">
          <div className="thank_you_check_icon_new">
            <img src={checkMark} alt="check_mark_icon" className="check_mark" />
          </div>
          <div className="thank_you_text_new">
            <h3>Your KYC is approved.</h3>
          </div>
          <p className="thank_you_subtext_new">You can now start investing!</p>
        </div>
      );
    } else {
      return (
        <div className="thank_you_main_container_new">
          <div className="thank_you_check_icon_new">
            <img src={checkMark} alt="check_mark_icon" className="check_mark" />
          </div>
          <div className="thank_you_text_new">
            <h3>Your KYC is under review.</h3>
          </div>
          <p className="thank_you_subtext_new">
            Thank you for sharing all the requested documents. Our team will
            review your KYC details and get back on next steps within 1 business
            day.
          </p>
        </div>
      );
    }
  };

  // show content loader
  const showContentLoader = () => {
    <div className="client_kyc_link_container">
      {/* image div */}
      <div className="client_kyc_link_body_container">
        <Loader
          type="TailSpin"
          color="#414141"
          height={80}
          width={80}
          style={{
            marginTop: "150px",
          }}
        />
      </div>
    </div>;
  };

  // show page not found
  const showPageNotFound = () => {
    <div className="client_kyc_link_container">
      {/* image div */}
      <div className="client_kyc_link_body_container">
        {/* title */}
        <p
          style={{
            marginTop: "120px",
            fontSize: "80px",
            color: "#3A86FF",
            fontWeight: "500",
            textAlign: "center",
          }}
        >
          404
          <br />
          <p
            style={{
              fontSize: "30px",
              color: "#424242",
              fontWeight: "400",
            }}
          >
            Page Not Found
          </p>
        </p>
      </div>
    </div>;
  };

  // show pre filled details if any
  const preCheckPanNumber = (search) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignSelf: "center",
        }}
      >
        <div className="client_kyc_link_body_container">
          {/* title */}
          <p
            className="clientkyclink_main_container_main_title"
            style={{ marginTop: "155px" }}
          >
            IFA {ifaName} has requested to add you as a client. Please fill the
            details below to start the onboarding process.
          </p>
        </div>
        <div className="clientkyclink_main_container_1">
          <div id="precheck" className="clientkyclink_main_container_div2">
            <ClientPreKyc
              data={search}
              proceedFurther={(value) => {
                if (value) setShowForm(2);
              }}
              setBasicKycDetails={setBasicKycDetails}
              setPan={setPan}
              setPanSuccessful={setPanSuccessful}
              setInvestorType={setInvestorType}
              setUseridValue = {setUseridValue}
            />
          </div>
        </div>
      </div>
    );
  };

  const showPanFailedPage = () => {
    return (
      <div className="client_kyc_link_container">
        {/* image div */}
        <div
          className="client_kyc_link_body_container"
          style={{ marginTop: "200px" }}
        >
          <PanValidationFailed />
        </div>
      </div>
    );
  };

  // on accept t & c
  const onAcceptTNC = () => {
    setIsTNCModalOpen(false);
    setTcCheck(true);
    setClientTNCModelLoader(false);
    eventLog({
      type: "kyc_link_tnc_accept",
      action: {
        trigger: "true",
      },
    });
  };
  const setUseridValue = (user_id) => 
  { 
     setUserId(user_id);
  }

  
  //on final submit
  const onSubmit = async (e) => {
    setLoader(true);

    let aggregateData = {
      ...documents.client_details,
      ...documents.bank_details,
      ...documents.kyc_documents,
      ...documents.nominee_details,
      ...entityDetails,
    };


    //error handler
    let errorState = getErrorStateForAddClient(
      aggregateData,
      investorType,
      !ckyc
    );
       //error nomine_errors handler
       let nominee_errors = {};
  
       if(investorType == "Individual" || investorType == "sole_proprietorship")
       {
         nominee_errors = checkForNomineeDetailsForErrorState(nomineeDetails);
       } 

    if (
      errorState &&
      errorState.hasOwnProperty("bank_verify") &&
      errorState.bank_verify
    ) {
      setErrorState({
        ...errorState,
        ...nominee_errors
      });
      errorToast(
        "Please provide all the required information to proceed.",
        true
      );
      setLoader(false);
      return;
    }

    if (errorState && Object.keys(errorState).length > 0) {
      setErrorState({
        ...errorState,
        ...nominee_errors
      });
      errorToast(
        "Please provide all the required information to proceed.",
        true
      );
      setLoader(false);
      return;
    }

    //check for proprietor details
    if (investorType != "Individual") {
      if (
        investorType != "sole_proprietorship" &&
        (!checkValidPan(entityDetails.authoriser_pan) ||
          entityDetails.authoriser_pan_res == null)
      ) {
        errorToast("Please validate or enter appropriate PAN", true);
        setLoader(false);
        return;
      } else if (getAge(entityDetails?.authoriser_dob) > getAge(startDate)) {
        errorToast("Invalid Date of Birth.", true);
        setLoader(false);
        return;
      }
    }
    //check for nominee
    if (
      (investorType == "Individual" || investorType == "sole_proprietorship") &&
      skipNominee == false
    ) {
      if (nominee_errors && Object.keys(nominee_errors).length > 0) {
        //setError
        setErrorState(nominee_errors);
        errorToast(
          "Please provide all the required information to proceed.",
          true
        );
        setLoader(false);
        return;
      }
    }

    //if terms and conditions are not accepted
    if (!acceptTerm) {
      errorToast("Please accept the Terms & conditions");
    }
    setTimeout(() => {
      generateOTP();
    }, 1000);
  };

  const generateOTP = async () => {
    //call generate otp
    const generateOtpPayload = {
      name: clientKycDetails.pan_name,
      pan: clientKycDetails.pan_number,
      email: clientKycDetails.email_id,
      mobile: clientKycDetails.mobile_number,
      ifa_referral_code: ifa_referral_code,
      nominee_obj: !skipNominee && nomineeDetails ? nomineeDetails : null,
    };

    await api.generateOtp(generateOtpPayload).then((resp) => {
      if (resp.kind == "ok") {
        successToast("Successfully sent the OTP");
        setTimeout(() => {
          setLoader(false);
          setClientConsentModel(true);
        }, 2000);
      } else {
        setLoader(false);
        errorToast(resp.error);
      }
    });
  };
  const checkbox_icon = (checked, setChecked) => {
    return (
      <div
        className={
          checked ? "kyc_link_consent_checked" : "kyc_link_consent_unchecked"
        }
        onClick={() => setChecked(!checked)}
      >
        {checked ? (
          <DoneSharpIcon fontSize="inherit" sx={{ color: "white" }} />
        ) : null}
      </div>
    );
  };

  const mainFormContainer = () => {
    return (
      <div className="client_kyc_link_container">
        {/* image div */}
        <div className="client_kyc_link_body_container">
          {/* title */}
          <div className="client_kyc_link_header">
            <h4
              className="clientkyclink_main_container_title"
              style={{ marginTop: "148px" }}
            >
              New Client KYC
            </h4>
          </div>
        </div>
        <div
          className="clientkyclink_main_container"
          style={{
            display: param.pathname === "/clients/networth" ? "none" : "flex",
          }}
        >
          {/* profile */}
          <div
            id="profile"
            className="clientkyclink_main_container_div1"
            style={{ padding: "40px" }}
          >
            <h4 className="clientkyc_main_container_title_1">Basic Details</h4>
            <ClientKycProfile
              data={basicKycDetails}
              pan={pan}
              setClientKycDetails = {setClientKycDetails}
              investorType={investorType}
              setInvestorType={setInvestorType}
              profileCompleted={profileCompleted}
              setProfileCompleted={setProfileCompleted}
              basicKycDetails={basicKycDetails}
              setUseridValue = {setUseridValue}
              setBasicKycDetails={setBasicKycDetails}
              setCkyc={setCkyc}
              setIsEntity={setIsEntity}
              ifa_referral_code={ifa_referral_code}
            />
          </div>
          {/* kyc documents */}
          {profileCompleted && !ckyc && !isEntity ? (
            <div
              id="kyc_documents"
              className="clientkyclink_main_container_div1"
              style={{
                padding: "40px",
              }}
            >
              <h4 className="clientkyc_main_container_title_1">
                KYC Documents
              </h4>
              <ClientKycDocuments
                setKycDocuments={setKycDocuments}
                errorState={errorState}
                panSuccessful={panSuccessful}
                setErrorState={setErrorState}
                userId={userId}
              />
            </div>
          ) : null}
          {/* entity details & documents */}
          {profileCompleted && isEntity ? (
            <div
              id="entity_documents"
              className="clientkyclink_main_container_div1"
              style={{
                padding: "40px",
              }}
            >
              <h4 className="clientkyc_main_container_title_1">
                Entity Details & Documents
              </h4>
              <span className="add_client_link_entity_subtext">
                Please upload certified copies of the documents.
              </span>
              <EntityDocuments
                investorType={investorType}
                setEntityDocuments={setEntityDocuments}
                errorState={errorState}
                setErrorState={setErrorState}
                userId={userId}
              />
            </div>
          ) : null}
          {/* bank details */}
          {profileCompleted ? (
            <div
              id="bank_details"
              className="clientkyclink_main_container_div1"
              style={{
                padding: "40px",
              }}
            >
              <h4 className="clientkyc_main_container_title_1">
                {investorType === "Individual"
                  ? "Bank Details"
                  : "Entity Bank Details"}
              </h4>
              <ClientKycBankDetails
                setBankDetails={setBankDetails}
                errorState={errorState}
                setErrorState={setErrorState}
                basicKycDetails={basicKycDetails}
                investorType={investorType}
                userId={userId}
                ifaName={ifaName}
              />
            </div>
          ) : null}
          {/* entity details */}
          {profileCompleted && isEntity ? (
            <div
              id="bank_details"
              className="clientkyclink_main_container_div1"
              style={{
                padding: "40px",
              }}
            >
              <h4 className="clientkyc_main_container_title_1">
                {config.entity_secondary_name[investorType]} Details & Documents
              </h4>
              <EntityKycDetails
                investorType={investorType}
                basicKycDetails={basicKycDetails}
                setEntityKycDetails={setEntityKycDetails}
                errorState={errorState}
                setErrorState={setErrorState}
                setPanValidationFailed={setPanValidationFailed}
                userId={userId}
              />
            </div>
          ) : null}
          {/* nominee details */}
          {!skipNominee &&
          profileCompleted &&
          (investorType === "Individual" ||
            investorType === "sole_proprietorship") ? (
            <div
              id="nominee_details"
              className="clientkyclink_main_container_div1"
              style={{
                padding: "15px 40px 40px 40px",
              }}
            >
              <div className="nominee_header">
                <h4 className="clientkyc_main_container_title_1">
                  Nominee Details
                </h4>
                {/* <h4
                  className="skip_button"
                  onClick={() => setSkipNominee(true)}
                >
                  Skip for now
                </h4> */}
              </div>
              <ClientNomineeDetail
                setNomineeDetails={setNomineeDetails}
                errorState={errorState}
                setErrorState={setErrorState}
              />
            </div>
          ) : null}
          {/* checkbox */}
          {profileCompleted ? (
            <div
              className="kyc_link_checkbox_container"
              style={{ marginLeft: "0px" }}
            >
              <div className="kyc_link_checkbox_content">
                {checkbox_icon(ppCheck, setPpCheck)}
                <div className="kyc_link_consent_text">
                  I have read and accept the{" "}
                  <span
                    style={{ color: "#00376F", cursor: "pointer" ,fontSize:"13px"}}
                    className="consent_text"
                    onClick={() => setOpenPrivacyPolicyModal(true)}
                  >
                    Privacy Policy
                  </span>{" "}
                  of 1Invest and hereby consent to use my KYC for any
                  verification.*
                </div>
              </div>
              <div className="kyc_link_checkbox_content">
                {checkbox_icon(tcCheck, setIsTNCModalOpen)}
                <div className="kyc_link_consent_text">
                  I have read and agree to the{" "}
                  <span
                    style={{ color: "#00376F", cursor: "pointer" }}
                    className="kyc_link_consent_text"
                    onClick={() => setIsTNCModalOpen(true)}
                  >
                    Terms and Conditions
                  </span>
                  .*
                </div>
              </div>
              <div className="kyc_link_checkbox_content">
                {checkbox_icon(personalCheck, setPersonalCheck)}
                <div className="kyc_link_consent_text">
                  I hereby give my consent to {ifaName} to access my personal
                  and financial information for the purpose of managing my
                  account on 1Invest.*
                </div>
              </div>
              <div className="kyc_link_checkbox_content">
                {checkbox_icon(reportsCheck, setReportsCheck)}
                <div className="kyc_link_consent_text">
                  I hereby give my consent to {ifaName} to access any reports,
                  certificates or statements linked to my investments on
                  1Invest.
                </div>
              </div>
              <div className="kyc_link_checkbox_content">
                {checkbox_icon(fundsCheck, setFundsCheck)}
                <div className="kyc_link_consent_text">
                  I hereby authorize {ifaName} to act on my behalf for selecting
                  the 1Invest plan and investing funds from my escrow account
                  maintained with RNVP Technology Private Limited. This
                  authorization is revocable at any time, for any reason.
                </div>
              </div>
            </div>
          ) : (
            <div />
          )}
          {/* main submit button */}
          {profileCompleted ? (
            <div
              className="add_client_kyc_link_main_submit_cta"
              style={{
                backgroundColor: acceptTerm ? "#3A86FF" : "#ebedf0",
                color: acceptTerm ? "white" : "black",
                pointerEvents: finalSubmitFailed ? "none" : "",
                marginBottom: "50px",
                width: "46%",
              }}
              onClick={(e) => {
                if (acceptTerm) {
                  onSubmit(e);
                }
              }}
            >
              {loader ? (
                <Loader
                  type="TailSpin"
                  color="#FFFFFF"
                  height={25}
                  width={25}
                />
              ) : (
                <p className="add_client_kyc_link_basic_details_cta_text">
                  Submit
                </p>
              )}
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  const verifyOTP = async () => {
    let verifyOtpPayload = {
      pan: clientKycDetails.pan_number,
      email: clientKycDetails.email_id,
      mobile: clientKycDetails.mobile_number,
      ifa_referral_code: ifa_referral_code,
      otp: clientOTP,
      invest_consent: fundsCheck,
      report_consent: reportsCheck,
    };

    await api.verifyOtp(verifyOtpPayload).then(async (res) => {
      if (res.kind == "ok") {
        successToast("OTP has been verified successfully");
        setClientConsentModelLoader(false);
        setSubmit(true);
        //call client save api
        let payload = {
          ...clientKycDetails,
          ...documents.bank_details,
          ...documents.kyc_documents,
          ...documents.nominee_details,
          ifa_referral_code: ifa_referral_code,
        };

        if (
          investorType == "private_limited_company" ||
          investorType == "public_limited_company"
        ) {
          payload["investor_type"] = documents.client_details["company_type"];
        }
        payload["user_id"] = userId;
        await api.clientSave(payload).then(async (res) => {
                    if (res.kind === "ok") {
            if (investorType != "Individual") {
              //call the entity doc upload
              let payloadForEntityDoc = {
                ...entityDetails,
                investor_type: investorType,
                user_id: basicKycDetails.user_id,
                ifa_referral_code: ifa_referral_code,
              };

              if (payload["investor_type"] == "Public Limited") {
                payloadForEntityDoc["investor_type"] = "public_limited_company";
              } else if (payload["investor_type"] == "Private Limited") {
                payloadForEntityDoc["investor_type"] =
                  "private_limited_company";
              }

              await api.saveEntityDetails(payloadForEntityDoc).then((res) => {
                if (res.kind == "ok") {
                  eventLog({
                    type: "kyc_link_entity_documents_saved",
                    action: {
                      trigger: "true",
                    },
                  });
                  setSubmit(false);
                  setThankYouPage(true);
                  setClientConsentModel(false);
                } else {
                  errorToast(res.error, true);
                  setFinalSubmitFailed(true);
                  setSubmit(false);
                  setClientConsentModel(false);
                }
              });
            } else {
              setSubmit(false);
              setThankYouPage(true);
              setClientConsentModel(false);
            }
          } else {
            errorToast(res.error, true);
            setFinalSubmitFailed(true);
            setSubmit(false);
            setClientConsentModel(false);
          }
        });
      } else {
        errorToast(res.error, true);
        setClientConsentModelLoader(false);
      }
    });
  };

  return (
    <>
      <div>
        {/* header */}
        <header className="auth_header" style={{ zIndex: 1000 }}>
          <img
            src="https://arthmate-p2p-public-assets.s3.ap-south-1.amazonaws.com/images/1invest-logo-color-dark.png"
            alt="logo"
            className="auth_logo_image"
            onClick={() =>
              window.open("https://www.arthmatedirect.com/", "_self")
            }
          />
        </header>
      </div>
      <div className="client_kyc_link_main_container">
        {/* body */}
        {panValidationFailed
          ? showPanFailedPage()
          : thankYouPage && !autoApproved
          ? showThankYouPage()
          : thankYouPage && autoApproved
          ? showThankYouPage("approved")
          : showForm === 0
          ? // if page is loading
            showContentLoader()
          : showForm === 1
          ? // if page not found
            showPageNotFound()
          : showForm === 2
          ? mainFormContainer()
          : preCheckPanNumber(search)}

        {/* alert box */}
        {/* {isModalOpen ? (
          <AlertBox
            show={isModalOpen}
            title="Client KYC Confirmation!"
            content="Are you sure that the information you provided are correct?"
            positivebutton="Yes"
            negativebutton="No"
            yes={() => {
              onClickYes();
            }}
            no={() => setIsModalOpen(false)}
          />
        ) : (
          <div />
        )} */}

        {/* full screen loader */}
        {submit ? (
          <Modal
            open={submit}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
              backgroundColor: "transparent",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FullScreenLoader />
          </Modal>
        ) : (
          <div />
        )}

        {/* toast container */}
        <SampleToast />

        {/* client consent model */}
        <ClientOTPModel
          openModel={clientConsentModel}
          setOtp={(value) => setClientOTP(value)}
          loading={clientConsentModelLoader}
          onSubmit={() => {
            setClientConsentModelLoader(true);
            verifyOTP();
          }}
          resendAgain={() => generateOTP()}
        />

        {/* 3rd party t&c */}
        {/* <PdfViewer
          openModel={isTNCModalOpen}
          header={"Terms & Conditions"}
          loading={clientTNCModelLoader}
          setOpenModel={setIsTNCModalOpen}
          url={config.tc_url}
          onSubmit={() => {
            setClientTNCModelLoader(true);
            onAcceptTNC();
          }}
        /> */}

        <TcViewer
          openModel={isTNCModalOpen}
          onDownload={() => handleDownload(config.tc_url, "terms&conditions.pdf")}
          showDownload={true} 
          url={config.tc_url}
          setOpenModel={setIsTNCModalOpen}
          onSubmit={() => {
            setClientTNCModelLoader(true);
            onAcceptTNC();
          }}
          header={"Terms And Conditions"}
        />
        <PdfViewer
          openModel={openPrivacyPolicyModal}
          header={"Privacy Policy"}
          setOpenModel={setOpenPrivacyPolicyModal}
          url={config.privacy_policy_url}
        />
      </div>
    </>
  );
};

export default ClientKycLinkV2;
