import { CheckBox } from "@mui/icons-material";
import React, { useRef, useState, useEffect } from "react";
import { PdfViewer } from "../";
import { privacy_policy_url } from "../../config/settings";
import Checkbox from "@mui/material/Checkbox";
import "./consent-text.css";
import { eventLog } from "../../utils/firebase";

export default function ConsentText(props) {
  const [openPrivacyPolicyModal, setOpenPrivacyPolicyModal] = useState(false);

  return (
    <React.Fragment>
      <div className="consent_text_container">
        <div
          className="consent_text_checkbox"
          style={{
            display:
              props.hasOwnProperty("visibility") && !props.visibility
                ? "none"
                : "flex",
          }}
        >
          <Checkbox
            checked={props.isConsentChecked}
            onChange={() => {
              props.setIsConsentChecked(!props.isConsentChecked);
              eventLog({
                type: "accept_kyc_detail_privacy_policy",
                action: {
                  trigger: "true",
                },
              });
            }}
            sx={{ "& .MuiSvgIcon-root": { fontSize: 15 } }}
          />
          <div className="consent_text_condition">
            I have read and hereby accept the 1Invest &nbsp;
            <span
              className="consent_text_policy_text"
              onClick={() => setOpenPrivacyPolicyModal(true)}
            >
              Privacy Policy
            </span>
            &nbsp; and consent to 1Invest’s use of my KYC details for checking
            and verifying my details.
          </div>
        </div>
      </div>
      <PdfViewer
        openModel={openPrivacyPolicyModal}
        header={"Privacy Policy"}
        setOpenModel={setOpenPrivacyPolicyModal}
        url={privacy_policy_url}
      />
    </React.Fragment>
  );
}
