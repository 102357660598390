import * as React from "react";

const ErrorValue = function (props) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <p
        style={{
          margin: 0,
          padding: 0,
          marginBottom: "12px",
          fontSize: "14px",
          color: "#d43939",
        }}
      >
        {props.text}
      </p>
    </div>
  );
};

export default ErrorValue;
