import React from "react";
import maintenance from "../../assets/images/maintenance.svg";
import "../MaintenanceScreen/maintenance.css";

const MainTenanceScreen = function (props) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <div style={{ textAlign: "center" }}>
        <img
          src={maintenance}
          alt="maintenance image"
          style={{ display: "block", margin: "auto" }}
        />
        {/* <div>We are enhancing your experience!</div> */}
        <div>
          <p className="title">We are enhancing your experience!</p>
        </div>
        <div>
          <p className="subtitle">
            Our team is currently performing scheduled maintenance to improve{" "}
            <br /> the platform's performance. Please login after some time.
          </p>
        </div>
      </div>
    </div>
  );
};

export default MainTenanceScreen;
