import React, { useEffect, useState } from "react";
import "./ifapayment.css";
import { useParams, useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import Checkbox from "@mui/material/Checkbox";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { IFA_Payment_API, User_API } from "../../services/api";
import { Pools_API } from "../../services/api";
import InfoIcon from "@mui/icons-material/Info";
import {
  convertAmount,
  errorToast,
  successToast,
  dateTimeFormat,
  infoToast,
  convertTimestampFormat,
} from "../../utils/helper";
import {
  FullScreenLoader,
  SampleToast,
  useWindowDimensions,
} from "../../components";
import WalletRequestConfirmation from "../WalletRequestConfirmation/walletRequestConfirmation";
import { eventLog } from "../../utils/firebase";
import { LoaderScreen, LoaderScreenTimeOut } from "../../components/index";
import config from "../../config/settings";
import failureIcon from "../../assets/images/failure.svg";

const IFAPayment = (props) => {
  const { uuid } = useParams();
  const dimension = useWindowDimensions();
  const api = new IFA_Payment_API();
  const userApi = new User_API();
  const ifa_api = new Pools_API();
  const history = useHistory();
  const [data, setData] = useState();
  const [checked, setChecked] = useState(false);
  const [walletChecked, setWalletChecked] = useState(false);
  const [render, setRender] = useState(false);
  const [pageExpire, setPageExpire] = useState(false);
  const [fullLoader, setFullLoader] = useState(false);
  const [otpView, setOtpView] = useState(false);
  const [successView, setSuccessView] = useState(false);
  const [user, setUser] = useState(null);
  const [otp, setOtp] = useState("");
  const [otpInvalid, setOtpInvalid] = useState(false);
  const [otpInvalidText, setOtpInvalidText] = useState("");
  const [walletLoadingData, setWalletLoadingData] = useState();
  const [showWalletLoading, setShowWalletLoading] = useState(false);
  const [polling, setPolling] = useState(false);
  const [pollingFailed, setPollingFailed] = useState(false);
  const [startActualPolling, setStartActualPolling] = useState(false);
  const [paymentFailureView, setPaymentFailureView] = useState(false);
  const [paymentData, setPaymentData] = useState({
    amount: 0,
    payment_id: "",
    status: "NOT DEFINE",
    txn_date: "",
  });
  const urlParams = new URLSearchParams(window.location.search);
  const [stopPolling, setStopPolling] = useState(false);
  // let status = urlParams.get("status");
  let user_id = urlParams.get("user_id");
  let transaction_id = urlParams.get("transaction_id");
  const [investmentId, setInvestmentId] = useState("");

  let counter = 5;
  // get the payment data
  useEffect(async () => {
    props.showCommonHeader(false);
    props.showCommonSidebar(false);
    const data = await fetchDataForPolling();
    if (
      transaction_id &&
      transaction_id != null &&
      user_id &&
      user_id != null &&
      !startActualPolling
    ) {
      //poll on function
      for (var i = counter; i > 0; i--) {
        setPolling(true);
        //delay
        await sleep(5000);
        counter = counter - 1;

        const poll = await fetchPaymentStatus();

        if (poll && poll.code == "PAYMENT_SUCCESS") {
          setStartActualPolling(true);
          setPaymentData(poll.data);
          break;
        } else if (poll && poll.code == "PAYMENT_ERROR") {
          setPaymentFailureView(true);
          setPaymentData(poll.data);
          setPolling(false);
          setRender(true);
          break;
        }

        if (counter == 0) {
          setPolling(false);
          setPollingFailed(true);
        }
      }
    } else if (
      startActualPolling &&
      data.invested_amount > data.optional_balance
    ) {
      //poll on function
      for (var i = counter; i > 0; i--) {
        setPolling(true);

        //delay
        await sleep(5000);
        counter = counter - 1;

        const poll = await fetchDataForPolling();

        if (counter == 0) {
          setPolling(false);
          setPollingFailed(true);
        }

        //on wallet update break
        if (poll.invested_amount <= poll.optional_balance) {
          setPolling(false);
          fetchData();
          break;
        }
      }
    } else if (
      startActualPolling &&
      data.invested_amount <= data.optional_balance
    ) {
      setPolling(false);
      fetchData();
    } else {
      fetchData();
    }

    // eslint-disable-next-line
  }, [counter, startActualPolling]);

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  //function for polling
  const fetchDataForPolling = async () => {
    return await api.getPaymentData(uuid).then((res) => {
      if (res.kind == "ok") {
        return res.data;
      }
    });
  };

  // function to get the phonepe payment status
  const fetchPaymentStatus = async () => {
    const data = {
      user_id: user_id,
      transaction_id: transaction_id,
      mode: "IFA",
    };

    return await api
      .checkPhonepePaymentStatus(data)
      .then((res) => {
        if (res.kind == "ok") {
          eventLog({
            type: "payment_success",
            action: {
              trigger: "true",
            },
          });
          return res.data;
        } else {
          errorToast("Error while fetching order details");
          return null;
        }
      })
      .catch((err) => {
        console.log("err", err);
        return null;
      });
  };

  // fetch data
  const fetchData = async () => {
    await api.getPaymentData(uuid).then((res) => {
      if (res.kind === "ok") {
        if (res.data.status == "Expired") {
          setRender(true);
          setPageExpire(true);
        } else if (res.data.status == "Cancelled") {
          setRender(true);
          setPageExpire(true);
        } else if (res.data.status == "Paid") {
          setRender(true);
          setPageExpire(true);
          return;
        }
        if (res.data.invested_amount > res.data.optional_balance) {
          const walletLoadingData = {
            status: 0,
            advised_amount:
              res.data.invested_amount - res.data.optional_balance,
            user_uuid: res.data.investor_id,
            ifa_uuid: res.data.ifa_id,
            client_name: res.data.investor_name,
            client_mobile: res.data.investor_mobile,
            ifa_name: res.data.ifa_name,
            investment_amount: res.data.invested_amount,
            available_amount: res.data.optional_balance,
            investment_request_uuid: uuid,
            consent_status: res.data.consent_status,
          };
          infoToast(
            `Insufficient wallet balance. Please add ₹ ${res.data.invested_amount} to your 1Invest wallet to proceed with the investment.`
          );
          setData(res.data);
          setWalletLoadingData(walletLoadingData);
          setShowWalletLoading(true);
          setFullLoader(false);
          setRender(true);
          eventLog({
            type: "wallet_request_page",
            action: {
              trigger: "true",
            },
          });
        } else {
          setData(res.data);
          setRender(true);
          eventLog({
            type: "investment_page",
            action: {
              trigger: "true",
            },
          });
          if (res.data.consent_status === 2) {
            setFullLoader(true);
            updateAggeement(res.data);
          } else {
            setShowWalletLoading(false);
            setFullLoader(false);
          }
        }
      } else {
        if (res.error === "Payment link has been expired.") {
          setRender(true);
          setPageExpire(true);
        } else {
          setRender(true);
        }
      }
    });
  };

  // handle the checkbox action for agreement
  const handleChange = (event) => {
    console.log(data);
    setChecked(event.target.checked);
  };

  // handle the checkbox action for wallet
  const handleWalletChange = (event) => {
    console.log(data);
    setWalletChecked(event.target.checked);
  };

  // prevent number while press otp
  const onKeyPress = (e) => {
    const re = /[^0-9]/g;
    if (re.test(e.key)) {
      e.preventDefault();
    }
  };

  // on send otp
  const onSendOtp = async () => {
    setFullLoader(true);
    // const number = "+91" + data.investor_mobile;
    const payload = {
      mobile: data.investor_mobile,
      isIfa: false,
    };
    await userApi
      .sendMobileVerification(payload)
      .then((response) => {
        if (response.kind == "ok") {
          let userName = response.data.pan_number;
          if (response.data.oldExistingUser)
            userName = `+91${data.investor_mobile}`;
          if (response.data.isExistingUser) {
            Auth.signIn(userName)
              .then((response) => {
                setUser(response);
                setFullLoader(false);
                setOtpView(true);
                successToast("OTP has been sent successfully.");
                eventLog({
                  type: "investment_request_otp_sent",
                  action: {
                    trigger: "true",
                  },
                });
              })
              .catch((err) => {
                setFullLoader(false);
                console.log(err);
                errorToast(
                  "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
                );
                setTimeout(async () => {
                  await Auth.signOut();
                  setFullLoader(false);
                  setOtpView(true);
                }, 5000);
              });
          } else {
            setFullLoader(false);
            errorToast("User doesn't exist");
          }
        } else {
          setFullLoader(false);
          errorToast(
            "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
          );
        }
      })
      .catch((error) => {
        console.log("Error while sending OTP ::: ", error);
        setFullLoader(false);
        errorToast(
          "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
        );
      });
  };

  // get authentication
  const onGetAuth = async () => {
    if (otp.length === 0) {
      setOtpInvalid(true);
      setOtpInvalidText("OTP cannot be empty");
    } else if (otp.length !== 4) {
      setOtpInvalid(true);
      setOtpInvalidText("Invalid OTP");
    } else if (isNaN(parseInt(otp))) {
      setOtpInvalid(true);
      setOtpInvalidText("OTP must be a number");
    } else {
      setFullLoader(true);
      await Auth.sendCustomChallengeAnswer(user, otp)
        .then(async (res) => {
          if (res && !res.Session) {
            await Auth.currentAuthenticatedUser();
            const user = await Auth.currentCredentials();
            updateAggeement(data);
            eventLog({
              type: "investment_request_otp_verified",
              action: {
                trigger: "true",
              },
            });
          } else {
            setFullLoader(false);
            setOtpInvalid(true);
            setOtpInvalidText(
              "Wrong OTP. Please check and enter the correct OTP."
            );
          }
        })
        .catch((err) => {
          setFullLoader(false);
          console.log(err);
          errorToast(
            "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
          );
          setTimeout(async () => {
            await Auth.signOut();
            setFullLoader(false);
            setOtpView(false);
          }, 5000);
        });
    }
  };

  // update the agreement flag
  const updateAggeement = async (agreementData) => {
    // accept agreement payload
    const payload = {
      investment_amount: agreementData.invested_amount,
      pool_template_id: agreementData.pool_id,
      investor_id: agreementData.investor_id,
      ifa_uuid: agreementData.ifa_id,
      ifa_link_id: uuid,
    };
    await api.updateAgreementFlag(payload).then(async (res) => {
      if (res.kind === "ok") {
          eventLog({
            type: "client_investment_successful",
            action: {
              trigger: "true",
            },
          });
          setInvestmentId(res.data.investment_id);
          await Auth.signOut()
            .then(() => {
              setFullLoader(false);
              setSuccessView(true);
              setRender(true);
              setOtpView(false);
            })
            .catch((err) => {
              setFullLoader(false);
              setSuccessView(true);
              setRender(true);
              setOtpView(false);
              setTimeout(() => {
                window.location.reload();
              }, 5000);
            });
      } else {
        errorToast(res.error);
        setShowWalletLoading(false);
        await Auth.signOut()
          .then(() => {
            setTimeout(() => {
              setFullLoader(false);
              setOtpView(false);
            }, 5000);
          })
          .catch(async (err) => {
            console.log(err);
            errorToast(
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
            );
            await Auth.signOut();
            setTimeout(async () => {
              setFullLoader(false);
              setOtpView(false);
            }, 5000);
          });
      }
    });
  };

  // update the agreement flag
  const getInvest = async () => {
    await api.getInvestment(uuid).then(async (res) => {
      if (res.kind === "ok") {
        eventLog({
          type: "client_investment_successful",
          action: {
            trigger: "true",
          },
        });
        await Auth.signOut()
          .then(() => {
            setFullLoader(false);
            setSuccessView(true);
            setRender(true);
            setOtpView(false);
          })
          .catch((err) => {
            setFullLoader(false);
            setSuccessView(true);
            setRender(true);
            setOtpView(false);
            setTimeout(() => {
              window.location.reload();
            }, 5000);
          });
      } else {
        errorToast(res.error);
        await Auth.signOut()
          .then(() => {
            setTimeout(() => {
              setFullLoader(false);
              setOtpView(false);
            }, 5000);
          })
          .catch((err) => {
            console.log(err);
            errorToast(
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
            );
            setTimeout(() => {
              setFullLoader(false);
              setOtpView(false);
            }, 5000);
          });
      }
    });
  };

  // payment details view
  const paymentDetailsView = () => {
    return (
      <div className="ifa_payment_div">
        {/* header */}
        <header className="ifa_payment_header">
          <h1 className="ifa_payment_header_title">Complete Payment</h1>
        </header>

        {/* pools details */}
        <div className="ifa_payment_div_container">
          <div
            className={
              data.tenure ? "ifa_payment_data_div2" : "ifa_payment_data_div3"
            }
          >
            {/* pool type */}
            <div
              className="ifa_payment_data"
              style={
                data.tenure
                  ? {}
                  : { width: dimension.width > 500 ? "65%" : "100%" }
              }
            >
              <h1 className="ifa_payment_data_title">Plan</h1>
              <h1 className="ifa_payment_data_subtitle">{data.pool_title}</h1>
            </div>

            {/* tenure */}
            {data.tenure ? (
              <div className="ifa_payment_data">
                <h1 className="ifa_payment_data_title">Tenure</h1>
                <h1 className="ifa_payment_data_subtitle">
                  {data.tenure}{" "}
                  {data.tenure_type === 1 && data.tenure == 1
                    ? "Month"
                    : data.tenure_type === 1
                    ? "Months"
                    : "Days"}
                </h1>
              </div>
            ) : (
              <></>
            )}

            {/* interest rate */}
            <div className="ifa_payment_data">
              <h1 className="ifa_payment_data_title">Earn interest up to</h1>
              <h1 className="ifa_payment_data_subtitle">
                {data.avg_interest_rate.toFixed(2)}%
              </h1>
            </div>

            {/* tenure */}
            {/* <div className="ifa_payment_data">
              <h1 className="ifa_payment_data_title">Rating</h1>
              <h1 className="ifa_payment_data_subtitle">{data.risk_rating}</h1>
            </div> */}

            {/* tenure */}
            {/* <div className="ifa_payment_data">
              <h1 className="ifa_payment_data_title">Issue Ends</h1>
              <h1
                className="ifa_payment_data_subtitle"
                style={{ color: "#DB4A1D" }}
              >
                {data.issue_end}
              </h1>
            </div> */}
          </div>
        </div>

        {/* ifa name and amount */}
        <div className="ifa_payment_div_container">
          <div className="ifa_payment_data_div3">
            {/* ifa name */}
            <div
              className="ifa_payment_data"
              style={{ width: dimension.width > 500 ? "65%" : "100%" }}
            >
              <h1 className="ifa_payment_data_title">Advisor</h1>
              <h1 className="ifa_payment_data_subtitle">{data.ifa_name}</h1>
            </div>

            {/* amount */}
            <div className="ifa_payment_data">
              <h1 className="ifa_payment_data_title">Investment amount</h1>
              <h1 className="ifa_payment_data_subtitle">
                ₹ {convertAmount(data.invested_amount)}
              </h1>
            </div>
          </div>
        </div>

        {/* investor name */}
        <div className="ifa_payment_div_container">
          <div className="ifa_payment_data_div3">
            <div
              className="ifa_payment_data"
              style={{ width: dimension.width > 500 ? "65%" : "100%" }}
            >
              <h1 className="ifa_payment_data_title">Client</h1>
              <h1 className="ifa_payment_data_subtitle">
                {data.investor_name}
              </h1>
            </div>
            {/* optional_balance */}
            {data.optional_balance && data.optional_balance > 0 ? (
              <div
                className="ifa_payment_data"
                // style={{ marginLeft: dimension.width > 500 ? "22%" : 0 }}
              >
                <h1 className="ifa_payment_data_title">Wallet Balance</h1>
                <h1 className="ifa_payment_data_subtitle">
                  ₹ {convertAmount(data.optional_balance)}
                </h1>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>

        {/* error if pool issue has ended */}
        <div
          style={{
            paddingTop: "10px",
            display: data.pool_end ? "flex" : "none",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <ErrorIcon
              style={{
                fontSize: "22px",
                color: "#DB4A1D",
              }}
            />
            <h1
              className="ifa_payment_agreement_title"
              style={{
                color: "#DB4A1D",
              }}
            >
              This Investment Plan in no longer open for investments.
            </h1>
          </div>
        </div>

        {/* wallet_agreement */}
        {data.optional_balance && data.optional_balance > 0 ? (
          <div
            className="ifa_payment_data_div4"
            style={{
              display: data.pool_end ? "none" : "flex",
            }}
          >
            <div className="ifa_payment_agreement">
              <Checkbox
                checked={walletChecked}
                onChange={handleWalletChange}
                sx={{ "& .MuiSvgIcon-root": { fontSize: 25 } }}
              />
              <h1 className="ifa_payment_agreement_title">
                I consent to using funds from my 1Invest wallet for this
                investment.
              </h1>
            </div>
          </div>
        ) : (
          ""
        )}

        {/* agreements 
        <div
          className="ifa_payment_data_div4"
          style={{
            display: data.pool_end ? "none" : "flex",
          }}
        >
          <div className="ifa_payment_agreement">
            <Checkbox
              checked={checked}
              onChange={handleChange}
              sx={{ "& .MuiSvgIcon-root": { fontSize: 25 } }}
            />
            <h1 className="ifa_payment_agreement_title">
              I have read the
              <a
                className="agreement_link"
                rel="noreferrer"
                href="https://arthmate-p2p-data.s3.ap-south-1.amazonaws.com/readable/Agreements.pdf"
                target="_blank"
              >
                agreement
              </a>
              and consent to its terms and conditions. 
            </h1>
          </div>
        </div>*/}

        {/* button */}
        <div
          className="ifa_payment_data_div4"
          style={{
            display: data.pool_end ? "none" : "flex",
          }}
        >
          <button
            onClick={onSendOtp}
            style={{
              backgroundColor: !walletChecked ? "#cccccc" : "#3a86ff",
              cursor: !walletChecked ? "not-allowed" : "pointer",
            }}
            disabled={!walletChecked}
            className="ifa_payment_button"
          >
            Invest Now
          </button>
        </div>
      </div>
    );
  };

  // otp enter view
  const otpEnterView = () => {
    return (
      <div className="ifa_payment_div">
        {/* header */}
        <header className="ifa_payment_header">
          <h1 className="ifa_payment_header_title">Complete Payment</h1>
        </header>
        {/* info */}
        <div className="otp_info_text_div">
          <p className="otp_info_text">
            {" "}
            Please enter the 4-digit OTP sent via SMS on your registered mobile
            number (+91-XXXXXX
            {data.investor_mobile.substr(data.investor_mobile.length - 4)})
          </p>
        </div>
        {/* otp input */}
        <input
          type="text"
          maxLength={4}
          placeholder="Enter OTP"
          className="ifa_payment_input"
          onKeyPress={onKeyPress}
          onChange={(event) => {
            setOtpInvalid(false);
            setOtpInvalidText("");
            setOtp(event.target.value);
          }}
          style={{
            borderColor: otpInvalid ? "#ff2e2e" : undefined,
          }}
        />

        {/*validation error */}
        <div className="ifa_payment_invalid_div">
          {/* icon */}
          <InfoIcon
            style={{
              fontSize: "14px",
              paddingRight: "5px",
              color: "#ff2e2e",
              opacity: otpInvalid ? 1 : 0,
            }}
          />
          {/* text */}
          <p
            className="ifa_payment_invalid_text"
            style={{
              opacity: otpInvalid ? 1 : 0,
            }}
          >
            {otpInvalid ? otpInvalidText : "Invalid"}
          </p>
        </div>

        {/* button */}
        <button onClick={onGetAuth} className="ifa_payment_otp_button">
          Submit
        </button>
      </div>
    );
  };

  // success div view
  const successDivView = () => {
    console.log("data", data);
    const timestamp = dateTimeFormat();
    // console.log("Time: ", timestamp);
    return (
      <div>
        <div className="payment_success_page_container">
          <div className="payment_success_head_grid_container">
            <div style={{ textAlign: "center" }}>
              <img
                src="https://arthmate-p2p-public-assets.s3.ap-south-1.amazonaws.com/images/checkMark2.png"
                alt="check_mark_icon"
                className="payment_success_check_mark"
              />
              <p className="payment_success_main_title_text">
                Investment Successful
              </p>
              <p className="payment_success_sub_title_text">
                We have received your investment mandate along with the funds.
                It would take up to 2 business days for your investment to start
                earning returns for you.
              </p>
            </div>
            <br />
            <div className="payment_success_container">
              <div className="payment_success_details">
                <p className="payment_success_details_text">
                  Investment Details
                </p>
              </div>
              <div className="payment_success_grid_container">
                <div className="payment_success_item_left">
                  <p className="payment_success_item_text"> Investment ID</p>
                </div>
                <div className="payment_success_item_right">
                  <p className="payment_success_item_text">
                    {`${investmentId}`}
                  </p>
                </div>
              </div>
              <div className="payment_success_grid_container">
                <div className="payment_success_item_left">
                  <p className="payment_success_item_text">Investment Type</p>
                </div>
                <div className="payment_success_item_right">
                  <p className="payment_success_item_text">
                    {`${data.investment_type}`}
                  </p>
                </div>
              </div>
              <div className="payment_success_grid_container">
                <div className="payment_success_item_left">
                  <p className="payment_success_item_text">Investment Amount</p>
                </div>
                <div className="payment_success_item_right">
                  <p className="payment_success_item_text">
                    {`₹${data.invested_amount}`}
                  </p>
                </div>
              </div>
              <div className="payment_success_grid_container">
                <div className="payment_success_item_left">
                  <p className="payment_success_item_text">
                    Investment Timestamp
                  </p>
                </div>
                <div className="payment_success_item_right">
                  <p className="payment_success_item_text">{`${timestamp}`}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // page expire view
  const PageExpireView = () => {
    return (
      <div className="ifa_payment_page_expire">
        <h1 className="ifa_payment_header_page_expire_title">
          The page you are looking for has expired.
        </h1>
      </div>
    );
  };

  //payment failure view
  const PaymentFailureView = () => {
    return (
      <div className="wallet_request_failure_page_container">
        <>
          <div className="wallet_request_failure_head_grid_container">
            <div style={{ textAlign: "center" }}>
              <img
                src={failureIcon}
                alt="check_mark_icon"
                className="wallet_request_failure_check_mark"
              />
              <p className="wallet_request_failure_main_title_text">
                Transaction Failed
              </p>
            </div>
            <br />
            <div className="wallet_request_failure_grid_container"></div>
            <div className="wallet_request_success_grid_container">
              <div className="wallet_request_success_item_left">
                <p className="wallet_request_success_item_text">Order ID</p>
              </div>
              <div className="wallet_request_success_item_right">
                <p className="wallet_request_success_item_text">
                  {transaction_id}
                </p>
              </div>
            </div>
            <div className="wallet_request_failure_grid_container">
              <div className="wallet_request_failure_item_left">
                <p className="wallet_request_failure_item_text">Amount</p>
              </div>
              <div className="wallet_request_failure_item_right">
                <p className="wallet_request_failure_item_text">
                  ₹ {paymentData.amount}
                </p>
              </div>
            </div>
            <div className="wallet_request_failure_grid_container">
              <div className="wallet_request_failure_item_left">
                <p className="wallet_request_failure_item_text">
                  Transaction ID
                </p>
              </div>
              <div className="wallet_request_failure_item_right">
                <p className="wallet_request_failure_item_text">
                  {paymentData.transactionId}
                </p>
              </div>
            </div>
            <div className="wallet_request_failure_grid_container">
              <div className="wallet_request_failure_item_left">
                <p className="wallet_request_failure_item_text">TXN Date</p>
              </div>
              <div className="wallet_request_failure_item_right">
                <p className="wallet_request_failure_item_text">
                  {dateTimeFormat()}
                </p>
              </div>
            </div>
          </div>
          <div className="wallet_request_failure_main_title">
            <p style={{ color: "#4E4E4E" }}>
              If the amount has been debited from your bank, it will take 2-3
              business days to refund.
            </p>
            <p style={{ color: "#4E4E4E" }}>
              Please contact us at{" "}
              <strong style={{ color: "#3a86ff" }}>
                {config.support.email}
              </strong>{" "}
              with any questions about support.
            </p>
            <div className="wallet_request_failure_grid_container"></div>
          </div>
        </>
      </div>
    );
  };

  return (
    <div>
      {polling == true ? (
        <LoaderScreen />
      ) : pollingFailed == true ? (
        <LoaderScreenTimeOut />
      ) : render === false ? (
        <div />
      ) : showWalletLoading ? (
        <WalletRequestConfirmation
          fromInvest={true}
          walletLoadingData={walletLoadingData}
          onCancel={() => {
            setShowWalletLoading(false);
            setFullLoader(true);
            fetchData();
          }}
          onDone={() => {
            setShowWalletLoading(false);
            setFullLoader(true);
            fetchData();
          }}
        />
      ) : (
        <div className="ifa_payment_container">
          {/* logo */}
          <img
            src="https://arthmate-p2p-public-assets.s3.ap-south-1.amazonaws.com/images/1invest-logo-color-dark.png"
            alt="logo"
            className="ifa_payment_logo_image"
          />

          {/* screen render view */}
          {paymentFailureView
            ? PaymentFailureView()
            : data === undefined || pageExpire || data.status === 1
            ? // page expire
              PageExpireView()
            : otpView
            ? // otp enter view
              otpEnterView()
            : successView
            ? // success view
              successDivView()
            : // payment details view
              paymentDetailsView()}

          {/* full screen loader */}
          {fullLoader ? <FullScreenLoader /> : <div />}

          {/* toast container */}
          <SampleToast />
        </div>
      )}
    </div>
  );
};

export default IFAPayment;
