import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import Switch from "@mui/material/Switch";
import {
  convertIntoDefaultState,
  convertIntoErrorState,
  errorToast,
  infoToast,
  successToast,
  checkValidPan,
  yearsBefore,
  checkForAddressType,
} from "../../utils/helper";
import {
  InputBox,
  InputBoxV2,
  UploadInputV2,
  UploadInputV3,
} from "../../components/index.js";
import { kycDocumentsState } from "../../constant/clientKycState";
import { eventLog } from "../../utils/firebase";
import config from "../../config/settings";
import StateDropdown from "../../components/state-dropdown/state-dropdown";
import { state_array } from "../../config/settings";
import UploadInputPan from "../../components/upload-input-pan/upload-input-pan";

const ClientKycDocuments = (props) => {
  const [kycDocuments, setKycDocuments] = useState(
    convertIntoDefaultState(kycDocumentsState)
  );
  const [kycDocumentsError, setKycDocumentsError] = useState(
    convertIntoErrorState(kycDocumentsState)
  );
  const [document, setDocument] = useState({
    document_type: null,
    document_front: null,
    document_back: null,
  });
  const handleOcr = (isFileUploaded, type) => {
    console.log(isFileUploaded, "isfile uploaded", type, Boolean(isFileUploaded));
    if (isFileUploaded) {
      let temp = { ...kycDocuments };
      temp["pan_image"] = "pan_image." + type;
      console.log("temp", temp);
      setKycDocuments(temp);
      successToast("File uploaded successfully.");
    }
  }
  useEffect(() => {
    //update kyc details for parent
    props.setKycDocuments({
      ...kycDocuments,
      address_type: checkForAddressType(document.document_type),
      address_front_image: document.document_front,
      address_back_image: document.document_back,
    });

    //remove error
    props.setErrorState({
      ...kycDocumentsError,
    });
    console.log(kycDocuments, "kycDocuments");
  }, [kycDocuments, document]);

  //check for error
  useEffect(() => {
    if (props.errorState && props.errorState != null) {
      setKycDocumentsError({
        ...kycDocumentsError,
        ...props.errorState,
      });
    }

    eventLog({
      type: "kyc_link_complete_kyc_doc",
      action: {
        trigger: "true",
      },
    });
  }, [props]);
  const handleSwitchChange = (e) => {
    setKycDocuments((prevState) => ({
      ...prevState,
      permanent_current_address_flag: e.target.checked,
    }));
  };

  //array for address types
  const addressProofArray = ["Aadhaar Card", "Passport", "Driving License"];

  return (
    <div>
      {!props.panSuccessful &&
        <UploadInputPan
          style={{ "paddingTop": "16px" }}
          label={"Pan Image"}
          id="pan_image"
          fileName={kycDocuments["pan_image"]}
          uploadFileToS3={true}
          shouldRunOcr={true}
          source="pan" //required with shouldRunOcr paramter
          sourceType="pan_image" //required with shouldRunOcr paramter
          callback={handleOcr}
        // additionalPath={props.userId + "/"}
        />
      }
      <UploadInputV2
        id="selfie"
        label={"Selfie"}
        optional={false}
        object={kycDocuments}
        setValue={setKycDocuments}
        imageSizeLimit={15} //required if note is provided
        note={
          "Upload image in JPG/JPEG/PNG format. Max. file size allowed is 15 MB."
        }
        required={kycDocumentsState["selfie"]["required"]}
        errorObject={kycDocumentsError}
        setErrorObject={setKycDocumentsError}
        isError={
          props.errorState.hasOwnProperty("selfie")
            ? props.errorState.selfie
            : kycDocumentsError["selfie"]
        }
        requiredFileTypes={["image/jpeg", "image/png", "image/jpg"]}
        acceptedFormat=".jpg, .jpeg, .png"
      />
      {/* address */}
      <h4 id="add_client_proprietor_detail_subtitle">Current Address</h4>
      <InputBoxV2
        label={"Address"}
        id="current_address"
        type={kycDocumentsState["current_address"].type}
        //disabled={true}
        maxLength="255"
        // autoCapitalize={true}
        value={kycDocuments["current_address"]}
        setValue={setKycDocuments}
        object={kycDocuments}
        required={kycDocumentsState["current_address"]["required"]}
        errorObject={kycDocumentsError}
        setErrorObject={setKycDocumentsError}
        isError={
          props.errorState.hasOwnProperty("current_address")
            ? props.errorState.current_address
            : kycDocumentsError["current_address"]
        }
        errorText={
          kycDocuments["current_address"]
            ? "Invalid Address"
            : "This field cannot be empty."
        }
      />
      <InputBoxV2
        label={"Locality"}
        id="current_locality"
        type={kycDocumentsState["current_locality"].type}
        //disabled={true}
        maxLength="255"
        // autoCapitalize={true}
        value={kycDocuments["current_locality"]}
        setValue={setKycDocuments}
        object={kycDocuments}
        required={kycDocumentsState["current_locality"]["required"]}
        errorObject={kycDocumentsError}
        setErrorObject={setKycDocumentsError}
        isError={
          props.errorState.hasOwnProperty("current_locality")
            ? props.errorState.current_locality
            : kycDocumentsError["current_locality"]
        }
        errorText={
          kycDocuments["current_locality"]
            ? "Invalid locality"
            : "This field cannot be empty."
        }
      />
      <InputBoxV2
        label={"City"}
        id="current_city"
        type={kycDocumentsState["current_city"].type}
        //disabled={true}
        maxLength="255"
        // autoCapitalize={true}
        value={kycDocuments["current_city"]}
        setValue={setKycDocuments}
        specialCharCheck={true}
        object={kycDocuments}
        required={kycDocumentsState["current_city"]["required"]}
        errorObject={kycDocumentsError}
        setErrorObject={setKycDocumentsError}
        isError={
          props.errorState.hasOwnProperty("current_city")
            ? props.errorState.current_city
            : kycDocumentsError["current_city"]
        }
        errorText={
          kycDocuments["current_city"]
            ? "Invalid city"
            : "This field cannot be empty."
        }
      />

      <StateDropdown
        label={"State"}
        id={"current_state"}
        type={kycDocumentsState["current_state"].type}
        // disabled={props.totalDisable || disableKycDocuments["current_state"]}
        dataArray={config.state_array}
        maxLength="255"
        value={kycDocuments["current_state"]}
        setValue={setKycDocuments}
        object={kycDocuments}
        required={kycDocumentsState["current_state"]["required"]}
        errorObject={kycDocumentsError}
        setErrorObject={setKycDocumentsError}
        isError={kycDocumentsError["current_state"]} //props.errorState.hasOwnProperty("address")?props.errorState.address:
        errorText={
          kycDocuments["current_state"]
            ? "Invalid State"
            : "This field cannot be empty."
        }
      />
      <InputBoxV2
        label={"Pin code"}
        id="current_pin_code"
        type={kycDocumentsState["current_pin_code"].type}
        //disabled={true}
        maxLength="6"
        // autoCapitalize={true}
        value={kycDocuments["current_pin_code"]}
        setValue={setKycDocuments}
        object={kycDocuments}
        required={kycDocumentsState["current_pin_code"]["required"]}
        errorObject={kycDocumentsError}
        setErrorObject={setKycDocumentsError}
        isError={
          props.errorState.hasOwnProperty("current_pin_code")
            ? props.errorState.current_pin_code
            : kycDocumentsError["current_pin_code"]
        }
        errorText={
          kycDocuments["current_pin_code"]
            ? "Invalid PIN code"
            : "This field cannot be empty."
        }
      />
      {/* <InputBoxV2
        label={"Address"}
        id="address"
        type={kycDocumentsState["address"].type}
        //disabled={true}
        maxLength="255"
        // autoCapitalize={true}
        value={kycDocuments["address"]}
        setValue={setKycDocuments}
        object={kycDocuments}
        required={kycDocumentsState["address"]["required"]}
        errorObject={kycDocumentsError}
        setErrorObject={setKycDocumentsError}
        isError={
          props.errorState.hasOwnProperty("address")
            ? props.errorState.address
            : kycDocumentsError["address"]
        }
        errorText={
          kycDocuments["address"]
            ? "Invalid Address"
            : "This field cannot be empty."
        }
      /> */}
      {/* dropdown document upload */}
      <UploadInputV3
        id="address_type"
        label={"Address Proof"}
        dropDownArray={addressProofArray}
        object={document}
        value={document}
        setValue={setDocument}
        imageSizeLimit={15} //required if note is provided
        labelForDocumentFront={"Front Side"}
        idForDocumentFront={"address_front_image"}
        idForDocumentBack={"address_back_image"}
        labelForDocumentBack={"Back Side"}
        note={
          "Upload image in JPG/JPEG/PNG/PDF format. Max. file size allowed is 15 MB."
        }
        errorObject={kycDocumentsError}
        setErrorObject={setKycDocumentsError}
        isError={
          props.errorState.hasOwnProperty("address_type")
            ? props.errorState.address_type
            : kycDocumentsError["address_type"]
        }
      />
      <div></div>
      <div className="address_toggle">
        {/* premament details */}
        <p id="address_toggle_label2">
          Permanent address is the same as current address
        </p>

        {/* props.isGstRequired */}
        <Switch
          checked={kycDocuments["permanent_current_address_flag"]}
          onChange={handleSwitchChange}
          sx={{ "& .MuiSwitch-thumb": { color: "#3a86ff" } }}
        />
      </div>
      {!kycDocuments["permanent_current_address_flag"] && (
        <div>
          <h4 id="add_client_proprietor_detail_subtitle">Permanent Address</h4>
          <InputBoxV2
            label={"Address"}
            id="permanent_address"
            type={kycDocumentsState["permanent_address"].type}
            //disabled={true}
            maxLength="255"
            // autoCapitalize={true}
            value={kycDocuments["permanent_address"]}
            setValue={setKycDocuments}
            object={kycDocuments}
            required={
              !kycDocuments["permanent_current_address_flag"] ? true : false
            }
            errorObject={kycDocumentsError}
            setErrorObject={setKycDocumentsError}
            isError={
              props.errorState.hasOwnProperty("permanent_address")
                ? props.errorState.permanent_address
                : kycDocumentsError["permanent_address"]
            }
            errorText={
              kycDocuments["permanent_address"]
                ? "Invalid Address"
                : "This field cannot be empty."
            }
          />
          <InputBoxV2
            label={"Locality"}
            id="permanent_locality"
            type={kycDocumentsState["permanent_locality"].type}
            //disabled={true}
            maxLength="255"
            // autoCapitalize={true}
            value={kycDocuments["permanent_locality"]}
            setValue={setKycDocuments}
            object={kycDocuments}
            required={
              !kycDocuments["permanent_current_address_flag"] ? true : false
            }
            errorObject={kycDocumentsError}
            setErrorObject={setKycDocumentsError}
            isError={
              props.errorState.hasOwnProperty("permanent_locality")
                ? props.errorState.permanent_locality
                : kycDocumentsError["permanent_locality"]
            }
            errorText={
              kycDocuments["permanent_locality"]
                ? "Invalid locality"
                : "This field cannot be empty."
            }
          />
          <InputBoxV2
            label={"City"}
            id="permanent_city"
            type={kycDocumentsState["permanent_city"].type}
            //disabled={true}
            maxLength="255"
            // autoCapitalize={true}
            value={kycDocuments["permanent_city"]}
            specialCharCheck={true}
            setValue={setKycDocuments}
            object={kycDocuments}
            required={
              !kycDocuments["permanent_current_address_flag"] ? true : false
            }
            errorObject={kycDocumentsError}
            setErrorObject={setKycDocumentsError}
            isError={
              props.errorState.hasOwnProperty("permanent_city")
                ? props.errorState.permanent_city
                : kycDocumentsError["permanent_city"]
            }
            errorText={
              kycDocuments["permanent_city"]
                ? "Invalid city"
                : "This field cannot be empty."
            }
          />

          <StateDropdown
            label={"State"}
            id={"permanent_state"}
            type={kycDocumentsState["permanent_state"].type}
            // disabled={props.totalDisable || disableKycDocuments["current_state"]}
            dataArray={config.state_array}
            maxLength="255"
            value={kycDocuments["permanent_state"]}
            setValue={setKycDocuments}
            object={kycDocuments}
            required={
              !kycDocuments["permanent_current_address_flag"] ? true : false
            }
            errorObject={kycDocumentsError}
            setErrorObject={setKycDocumentsError}
            isError={kycDocumentsError["permanent_state"]} //props.errorState.hasOwnProperty("address")?props.errorState.address:
            errorText={
              kycDocuments["permanent_state"]
                ? "Invalid State"
                : "This field cannot be empty."
            }
          />
          <InputBoxV2
            label={"Pin code"}
            id="permanent_pin"
            type={kycDocumentsState["permanent_pin"].type}
            //disabled={true}
            maxLength="6"
            // autoCapitalize={true}
            value={kycDocuments["permanent_pin"]}
            setValue={setKycDocuments}
            object={kycDocuments}
            required={
              !kycDocuments["permanent_current_address_flag"] ? true : false
            }
            errorObject={kycDocumentsError}
            setErrorObject={setKycDocumentsError}
            isError={
              props.errorState.hasOwnProperty("permanent_pin")
                ? props.errorState.permanent_pin
                : kycDocumentsError["permanent_pin"]
            }
            errorText={
              kycDocuments["permanent_pin"]
                ? "Invalid PIN code"
                : "This field cannot be empty."
            }
          />
        </div>
      )}
    </div>
  );
};

export default ClientKycDocuments;
