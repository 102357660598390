import * as React from "react";
import { ToastContainer } from "react-toastify";
import { emailVerified } from "../../assets/images/index";
import Loader from "react-loader-spinner";
import { errorToast, successToast } from "../../utils/helper";
import { Profile_API } from "../../services/api/Profile/profile-api";

export default function SendEmail(props) {
  const api = new Profile_API();
  const [loader, setLoader] = React.useState(false);
  const profileData = props.profileData;
  const [hideButton, setHideButton] = React.useState(
    profileData.email_verification_pending
  );
   
  const fetchIFAProfile = () => {
    setLoader(true);
    api.getIFAProfileData().then((res) => {
      if (res.kind === "ok") {
        props.setProfileData(res.data);
        if(res.data.is_email_verified != "Verified"){
          sendEmailLink();
        }else{
          setLoader(false);
        }
      } else {
        errorToast(res.error);
        setLoader(false);
      }
    });
  };
  const sendEmailLink = () => {
    const payload = {
      name: profileData.name,
      email: profileData.email_id,
      mobile: profileData.mobile,
      is_ifa: true,
    };
    api.sendEmailVerification(payload).then((res) => {
      if (res.kind == "ok") {
        setTimeout(() => {
          successToast("Verification email sent successfully.");
          setLoader(false);
          setHideButton(true);
        }, 5000);
      } else {
        setTimeout(() => {
          errorToast(
            "Email verification could not be sent. Please try again later or contact support for assistance."
          );
          setLoader(false);
        }, 3000);
      }
    });
  };

  return (
    <>
      {profileData.is_email_verified ? (
        <>
          {profileData.is_email_verified &&
          profileData.is_email_verified == "Verified" ? (
            <span className="ifa_profile_email_status">
              <span>
                <img className="email_verified_status" src={emailVerified} />
              </span>{" "}
              {profileData.is_email_verified}
            </span>
          ) : (
            <div className={"ifa_email_pending_container"}>
              <span>
                <p
                  className= "ifa_email_pending_text"
                >
                  Email Verification is pending
                </p>
                
                  <p className="ifa_email_pending_subtext">
                    Please click here to send/Resend the verification e-mail to
                    your registered e-mail ID.
                  </p>
    
              </span>
              
                <span>
                  <button
                    className="ifa_email_send_button"
                    onClick={fetchIFAProfile}
                  >
                    {loader ? (
                      <Loader
                        type="TailSpin"
                        color="#fff"
                        height={13}
                        width={13}
                      />
                    ) : (
                      "Send E-mail"
                    )}
                  </button>
                </span>
            </div>
          )}
        </>
      ) : null}
    </>
  );
}
