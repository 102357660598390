import React, { useEffect, useState } from "react";
import "./send-kyc-link-model.css";
import Modal from "@mui/material/Modal";
import Loader from "react-loader-spinner";
import { ErrorValue } from "../../components";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  getFrontendBaseUrl,
  successToast,
  getFrontendStateObject,
} from "../../utils/helper";
import SampleToast from "../sample-toast/sample-toast";
import { eventLog } from "../../utils/firebase";
const { url } = getFrontendStateObject();

export default function SendKycLinkModel(props) {
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [nameErr, setNameErr] = useState("");
  const [mobileErr, setMobileErr] = useState("");
  const [emailErr, setEmailErr] = useState("");

  const prohibitedEmails = ['@outlook.com', '@hotmail.com'];

  // number key press handler
  const onKeyPress = (e) => {
    const re = /[^0-9]/g;
    if (re.test(e.key)) {
      e.preventDefault();
    }
  };

  //name key press handler
  const checkAlphabet = (e) => {
    const re = /^[a-zA-Z\s]*$/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  };

  // validate the data
  const checkData = () => {
    let all_check = true;

    // check name
    if (name.length === 0) {
      all_check = false;
      setNameErr("This field cannot be empty.");
    }

    // check number
    var number_format = /^[6-9][0-9]{9}$/;
    if(mobile.length === 0){
      all_check = false;
      setMobileErr("This field cannot be empty.");
    }
    else if (!number_format.test(mobile)) {
      all_check = false;
      setMobileErr("Invalid mobile");
    }
    

    // check email
    var mail_format =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if(email.length === 0){
        all_check = false;
        setEmailErr("This field cannot be empty.");
      }
      else if (!mail_format.test(String(email).toLowerCase())) {
      all_check = false;
      setEmailErr("Invalid email");
    }
    else {
      var mail_format = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(?!hotmail.com|outlook.com)(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!mail_format.test(String(email).toLowerCase())) {
        all_check = false;
        setEmailErr(`Please provide an alternate email ID. Email IDs ending in ${prohibitedEmails.join(" or ")} will not be accepted.`);
      }
    }

    // if all check true
    if (all_check) {
      let data = {
        name,
        email,
        mobile,
      };
      props.onSubmit(data);

      eventLog({
        type: "generate_kyc_link",
        action: {
          trigger: "true",
        },
      });
    }
  };

  // reset state
  const resetState = () => {
    if (!props.disable) {
      setName("");
      setEmail("");
      setMobile("");
      setNameErr("");
      setEmailErr("");
      setMobileErr("");
      props.linkErrors["mobile"] = "";
      props.linkErrors["email"] = "";
      props.handleClose();
    }
  };

  const onClickCopyLink = async (referralCode, mobile, email) => {
    await navigator.clipboard.writeText(`${url}/ifa/${referralCode}
`);
    successToast("Link copied.", true);
  };

  return (
    <Modal
      open={props.showModel}
      onClose={resetState}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.699)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div>
        <div className="kyclink_model_div">
          {/* title */}
          <div className="kyclink_model_title_div">
            <h1 className="kyclink_model_title">
              Client Details
            </h1>
          </div>
          {/* name input */}
          <div className="kyclink_model_input_div">
            <input
              type="text"
              disabled={props.disable}
              placeholder="Name *"
              className="kyclink_model_input"
              onKeyPress={checkAlphabet}
              onChange={(e) => {
                setName(e.target.value);
                setNameErr("");
              }}
            />
            <p
              className="kyclink_input_err"
              style={{
                color: nameErr.length > 0 ? "#f35656" : "#fff",
              }}
            >
              {nameErr}
            </p>
          </div>
          {/* mobile input */}
          <div className="kyclink_model_input_div">
            <input
              type="text"
              disabled={props.disable}
              maxLength={10}
              placeholder="Mobile Number *"
              className="kyclink_model_input"
              onKeyPress={onKeyPress}
              onChange={(e) => {
                setMobile(e.target.value);
                setMobileErr("");
              }}
            />
            <p
              className="kyclink_input_err"
              style={{
                color: mobileErr.length > 0 ? "#f35656" : "#fff",
              }}
            >
              {mobileErr}
            </p>
            {props.linkErrors.hasOwnProperty("mobile") ? (
              <ErrorValue text={props.linkErrors["mobile"]} />
            ) : (
              <div />
            )}
          </div>
          {/* email input */}
          <div className="kyclink_model_input_div">
            <input
              type="email"
              disabled={props.disable}
              placeholder="Email *"
              className="kyclink_model_input"
              onChange={(e) => {
                setEmail(e.target.value);
                setEmailErr("");
              }}
            />
            <p
              className="kyclink_input_err"
              style={{
                color: emailErr.length > 0 ? "#f35656" : "#fff",
              }}
            >
              {emailErr}
            </p>
            {props.linkErrors.hasOwnProperty("email") ? (
              <ErrorValue text={props.linkErrors["email"]} />
            ) : (
              <div />
            )}
          </div>
          {/* send button */}
          <button
            className="kyclink_model_button"
            style={{ cursor: props.disable ? "not-allowed" : "pointer" }}
            onClick={() => checkData()}
            disabled={props.disable}
          >
            {props.disable ? (
              <Loader type="TailSpin" color="#fff" height={15} width={15} />
            ) : (
              "Send KYC Link"
            )}
          </button>

          <div style={{ marginTop: "15px" }}>
            <p className="circle">OR</p>
          </div>
        </div>

        <div className="kyclink_copy_div">
          <p
            style={{
              padding: "10px",
              fontWeight: "400",
              color: "#414141",
              marginLeft: "20px",
            }}
          >
            You can copy this link and send it to your clients.
          </p>

          <div
            className="ifa_referral_code"
            onClick={async () =>
              await onClickCopyLink(
                props.profileData.referral_code,
                mobile,
                email
              )
            }
          >
            <p
              style={{
                fontWeight: "400",
                color: "#429ef5",
                marginRight: "10px",
              }}
            >
              {"Copy Link" + " "}
            </p>
            <span
              style={{
                marginLeft: "10px",
                marginTop: "10px",
                color: "#429ef5",
              }}
            >
              <ContentCopyIcon fontSize={"string"} />
            </span>
          </div>
        </div>
        <SampleToast />
      </div>
    </Modal>
  );
}
