import * as React from "react";
import "./client-earnings-model.css";
import Modal from "@mui/material/Modal";
import Loader from "react-loader-spinner";
import CloseIcon from "@mui/icons-material/Close";
import { convertAmount, errorToast } from "../../utils/helper";
import { Client_API } from "../../services/api";

export default function ClientEarningsModel(props) {
  const [earningsData, setEarningsData] = React.useState([]);
  const [loader, setLoader] = React.useState(false);

  const api = new Client_API();

  React.useEffect(() => {
    if (props.openModel) {
      fetchGrowthPlanInvestments();
    }
  }, [props.openModel]);

  const fetchGrowthPlanInvestments = () => {
    setLoader(true);

    const payload = {
      user_uuid: props.clientUuid,
    };

    api
      .fetchFreedomPlanEarnings(payload)
      .then((res) => {
        if (res.kind === "ok") {
          setEarningsData(res.data);
          setLoader(false);
        } else {
          errorToast("Error in fetching client transaction history");
          setLoader(false);
        }
      })
      .catch((error) => {
        errorToast(
          "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
        );
        setLoader(false);
      });
  };

  return (
    <Modal
      open={props.openModel}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{
        backgroundColor: "rgba(102, 102, 102, 0.72)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className="client_earnings_model_div">
        <div className="client_earnings_model_header">
          <div style={{ float: "left", width: "80%" }}>
            <h1 className="client_earnings_model_header_title">
              Earnings History
            </h1>
          </div>
          <div
            style={{ float: "right", cursor: "pointer" }}
            onClick={props.handleClose}
          >
            <h1 className="client_earnings_model_header_title">
              <CloseIcon />
            </h1>
          </div>
        </div>

        <div className="client_earnings_model_main_container_div">
          <div className="client_earnings_model_main_div">
            <div>
              <h1 className="client_earnings_model_title">Date</h1>
            </div>
            <div>
              <h1 className="client_earnings_model_title">Principal</h1>
            </div>
            <div>
              <h1 className="client_earnings_model_title">Interest Earned</h1>
            </div>
          </div>
          {/* title */}
          {!loader ? (
            <React.Fragment>
              {earningsData &&
                earningsData.length != 0 &&
                earningsData.map((item, key) => (
                  <React.Fragment index={key}>
                    <div className="client_earnings_model_main_value_div">
                      <div>
                        <h1 className="client_earnings_model_title_value">
                          {item.created_at?.formatted_date}
                        </h1>
                      </div>
                      <div>
                        <h1 className="client_earnings_model_title_value" s>
                          <span
                            className="client_earnings_model_title_value"
                            style={{
                              fontFamily: "'Open Sans', sans-serif",
                            }}
                          >
                            ₹
                          </span>{" "}
                          {convertAmount(item.principal_amount)}
                        </h1>
                      </div>
                      <div>
                        <h1 className="client_earnings_model_title_value">
                          <span
                            className="client_earnings_model_title_value"
                            style={{
                              fontFamily: "'Open Sans', sans-serif",
                            }}
                          >
                            ₹
                          </span>{" "}
                          {convertAmount(item.amount)}
                        </h1>
                      </div>
                    </div>
                    <div className="client_earnings_model_line_div">
                      <hr style={{ border: "1px solid #EFEFEF" }} />
                    </div>
                  </React.Fragment>
                ))}
            </React.Fragment>
          ) : (
            <Loader type="TailSpin" color="#fff" height={15} width={15} />
          )}
        </div>
      </div>
    </Modal>
  );
}
