import React, { useEffect, useState } from "react";
import "./addClientKycBasicDetails.css";
import InfoIcon from "@mui/icons-material/Info";
import Loader from "react-loader-spinner";
import moment from 'moment';
import {
  convertIntoDefaultState,
  convertIntoErrorState,
  checkValidEmail,
  checkPermittedEmail,
  checkValidMobileNumber,
  errorToast,
  infoToast,
  successToast,
  checkValidPan,
  getAge,
  setDisableState,
  yearsBefore,
  convertInvestorType,
  getInvestmentType,
} from "../../utils/helper";
import { InputBox, InputBoxV2 } from "../../components/index.js";
import { clientKycBasicDetail } from "../../constant/signupState";
import { KYC_API_V2 } from "../../services/api";
import { eventLog } from "../../utils/firebase";
import { Dropdown } from "../../components/index.js";

const AddClientKycBasicDetails = (props) => {
  const api = new KYC_API_V2();

  const [addClientBasicDetails, setAddClientBasicDetails] = useState(
    convertIntoDefaultState(clientKycBasicDetail)
  );

  const [addClientBasicDetailsError, setAddClientBasicDetailsError] = useState(
    convertIntoErrorState(clientKycBasicDetail)
  );

  const [disableBasicDetails, setDisableBasicDetails] = useState(
    convertIntoErrorState(clientKycBasicDetail)
  );
  
  const [loader, setLoader] = useState(false);
  const [clickProceed, setClickProceed] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [maxDate, setMaxDate] = useState("");
  const [invalidGender, setInvalidGender] = useState(false);
  const [invalidInvestorCategory, setInvalidInvestorCategory] = useState(false);
  const [currDate, setCurrDate] = useState("");
  const [permittedEmailError, setPermittedEmailError] = useState(false);
  const [panType_IS, SetPanType_IS] = useState(false)
  const [panAttempts,setPanAttempts]=useState(0);
  const prohibitedEmails = ["@outlook.com", "@hotmail.com"];

  var mail_format =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  //for dob
  useEffect(() => {
    const { cDate, sDate, mDate } = yearsBefore(100);
    setCurrDate(cDate);
    setStartDate(sDate);
    setMaxDate(mDate);
  }, []);

  const entity_type_array = [
    "huf",
    "partnership_firm",
    "private_limited_company",
    "trust",
  ];

  const entityFullName = {
    huf: "Hindu Undivided Family",
    partnership_firm: "Partnership Firm",
    private_limited_company: "Company",
    trust: "Trust"
  };


  const pan_identifier = {
    P: "Individual or Sole Proprietorship",
    H: "huf",
    C: "private_limited_company",
    F: "partnership_firm",
    T: "trust",
    B: "trust",
    A: "trust"
  }

  const identify_pan = (pan) => {
    if (pan in pan_identifier) {
      return pan_identifier[pan];
    } else {
      return "Unknown Type";
    }
  };

  const non_entity = ["Individual", "sole_proprietorship"];

  useEffect(() => {
    if (
      props.userData &&
      props.userData !== "" &&
      props.userData.kyc_status === 3 &&
      !props.userData.has_profile
    ) {
      setDisableState(
        props.userData,
        disableBasicDetails,
        setDisableBasicDetails
      );
    }
    if (props.userData && props.userData !== "") {
      setAddClientBasicDetails({
        pan: props.userData.pan,
        pan_name: props.userData.pan_name,
        email: props.userData.email,
        dob: props.userData.dob,
        gender: props.userData.gender,
        investor_type: props.userData.investor_type,
        mobile: props.userData.mobile,
        company_type : props.userData.company_type,
        user_id: props.userData.user_id,
      });
      if (props.userData.has_profile) {
        setDisabled(true);
      }
    }
  }, [props?.userData]);

  useEffect(() => {
    if (!props.userData || props.userData === "") {
      setAddClientBasicDetails({
        ...addClientBasicDetails,
        pan: props?.prePanData?.pan,
        pan_name: props?.prePanData?.pan_name,
        email: props?.prePanData?.email,
        mobile: props?.prePanData?.mobile ,
        dob: props?.prePanData?.dob ,
        gender: props?.prePanData?.gender,
        is_pan_consent_accepted: props?.prePanData?.is_pan_consent_accepted,
        investor_type:
          props.prePanData.investor_type !=="Individual or Sole Proprietorship" ? props.prePanData.investor_type :  props.userData.investor_type || addClientBasicDetails["investor_type"],
      });
    }
  }, [props?.prePanData]);

  useEffect(() => {
    props.setBasicKycDetails(addClientBasicDetails);
  }, [addClientBasicDetails]);

  useEffect(() => {
    if (
      addClientBasicDetails["email"] && addClientBasicDetails["email"].length &&
      !mail_format.test(addClientBasicDetails["email"])
    ) {
      setPermittedEmailError(false);
      setAddClientBasicDetailsError({
        ...addClientBasicDetailsError,
        email: true,
      });
    }
  }, [addClientBasicDetails["email"]]);

  useEffect(() => {
    if (props?.prePanData?.pan && props?.prePanData?.pan[3] !== "P" || (addClientBasicDetails["pan"] && addClientBasicDetails["pan"][3] !=="P")) {
      const pan = props?.prePanData?.pan[3] ||addClientBasicDetails["pan"][3];
      props.prePanData["investor_type"] = identify_pan(pan); // Set Investor type
      SetPanType_IS(true);
      setStartDate("1850-01-01");
      setMaxDate(moment().subtract(1, "days").format("YYYY-MM-DD"));
    }
  }, []);
  const investor_type =
    props.prePanData.investor_type || props.userData.investor_type;

  const handleChangeGender = (value) => {
    //set value
    setAddClientBasicDetails({
      ...addClientBasicDetails,
      gender: value,
    });
    setInvalidGender(false);
  };

  //handle on proceed click
  const onProceed = async (e) => {
    e.preventDefault();
    setLoader(true);
    //check for email id
    if (!addClientBasicDetails["pan_name"]) {
      setPermittedEmailError(false);
      setAddClientBasicDetailsError({
        ...addClientBasicDetailsError,
        pan_name: true,
      });
      setLoader(false);
      return;
      //check for permitted email id
    }  
    else if (!checkPermittedEmail(addClientBasicDetails["email"])) {
      setPermittedEmailError(true);
      setAddClientBasicDetailsError({
        ...addClientBasicDetailsError,
        email: true,
      });
      setLoader(false);
      return;
      //check for mobile number
    } else if (!checkValidMobileNumber(addClientBasicDetails["mobile"])) {
      setAddClientBasicDetailsError({
        ...addClientBasicDetailsError,
        mobile: true,
      });
      setLoader(false);
      return;
      //check for investor Category
    } else if (
      !entity_type_array.includes(investor_type) &&
      !non_entity.includes(addClientBasicDetails["investor_type"])
    ) {
      // errorToast("Please Select Investor Category.", true);
      setInvalidInvestorCategory(true);
      setLoader(false);
      return;
      //check for date of birth
    } else if (
      // addClientBasicDetails["investor_type"] == "Individual" &&
      !addClientBasicDetails["dob"] || (panType_IS && maxDate ===
        moment(addClientBasicDetails["dob"]).format("YYYY-MM-DD"))
    ) {
      // errorToast("Please Select Date of Birth.", true);
      setAddClientBasicDetailsError({
        ...addClientBasicDetailsError,
        dob: true,
      });
      setLoader(false);
      return;
    } else if (
      addClientBasicDetails["investor_type"] == "Individual" &&
      getAge(addClientBasicDetails["dob"]) < 18
    ) {
      errorToast("The age of the investor must be more than 18 years.", true);
      setLoader(false);
      return;
    } else if (!panType_IS && getAge(addClientBasicDetails["dob"]) > getAge(startDate)) {
      errorToast("Invalid Date of Birth.", true);
      setLoader(false);
      return;
    }
    //check for gender
    else if (
      !addClientBasicDetails["gender"] &&
      addClientBasicDetails["investor_type"] == "Individual"
    ) {
      // errorToast("Please Select Gender.", true);
      setInvalidGender(true);
      setLoader(false);
      return;
    } else if (
      (addClientBasicDetails["investor_type"] == "private_limited_company" ||
      addClientBasicDetails["investor_type"] == "public_limited_company") &&
      !addClientBasicDetails["company_type"]
    ) {
      setAddClientBasicDetailsError({
        ...addClientBasicDetailsError,
        company_type: true,
      });
      setLoader(false);
      return;
    } else {
      let payload = "";
      let flowComplete = false;
      if (
        props.rejectionFlow &&
        props.userData.has_bank &&
        props.userData.has_document &&
        (props.userData.investor_type === "Individual" ||
          props.userData.has_authoriser)
      ) {
        flowComplete = true;
      }
      if (props.rejectionFlow) {
        payload = {
          ...addClientBasicDetails,
          rejection_flow: true,
          kyc_status: flowComplete ? 1 : 3,
        };
      } else {
        payload = addClientBasicDetails;
      }

      if (
        (addClientBasicDetails["company_type"] != null &&
        addClientBasicDetails["company_type"] != "")
      ) {
        payload['investor_type'] = addClientBasicDetails["company_type"]
      }
      let payloadPan = {
        pan: String(addClientBasicDetails["pan"]).toUpperCase(),
        dob: moment(addClientBasicDetails["dob"]).format("DD-MM-YYYY"),
        pan_name: String(addClientBasicDetails["pan_name"]),
      };
      payload={
        ...payload,
        ...payloadPan
      }
      // call the pan check api
       // call the pan check api
      await api.checkPanNumber(payloadPan).then(async (res) => {
        if (res.kind == "ok") {
          const data = res.data;
          if (data.message == "PAN verified successfully") {
            eventLog({
              type: "add_client_pan_verify",
              action: {
                trigger: "true",
              },
            });
            setLoader(false);
            props.setPreValidateData({
              ...data,
              pan: addClientBasicDetails["pan"],
              email: addClientBasicDetails["email"],
              mobile: addClientBasicDetails["mobile"],
              gender: addClientBasicDetails["gender"],
              dob: addClientBasicDetails["dob"],
              is_pan_consent_accepted:
                addClientBasicDetails["is_pan_consent_accepted"],
            });
            if(addClientBasicDetails["investor_type"] == "sole_proprietorship")
            {
              payload["investor_type"] =  "Sole Proprietorship"
            }
           else  if(props.prePanData?.investor_type == "trust")
            {
               payload["investor_type"] =  "Trust"
            }
            else if(props.prePanData?.investor_type == "huf")
            {
              payload["investor_type"] =  "HUF"
            }
            else if(props.prePanData?.investor_type == "partnership_firm")
            {
              payload["investor_type"] =  "Partnership Firm"
            }
            await userRegistrationFunc(payload, flowComplete);

          } else {
            eventLog({
              type: "add_client_pan_verify",
              action: {
                trigger: "false",
              },
            });
            successToast(data.message);
            setLoader(false);
          }
        } else {
          //trials
          // props.setPrePanValidation(2);
          console.log('Current pan attempt ', panAttempts)

          if (panAttempts == 0) {
            if (res.error.includes("name provided by you doesn’t match") || res.error.includes('PAN already linked to another client')) {
              errorToast(res.error, true);
            } else {
              errorToast("Pan couldn't be verified, please proceed for uploading pan image", true);
            }
          }

          if (panAttempts > 0) {
            if(panAttempts == 1 && res.error.includes("name provided by you doesn’t match")) {
              errorToast("Pan couldn't be verified, please proceed for uploading pan image", true);
            } else if (!res.error.includes("PAN already linked to another client")) {
              setPanAttempts((panAttempts) => panAttempts + 1);
              let investor_type = identify_pan(addClientBasicDetails["pan"][3]);
              props.setPreValidateData({
                investor_type: investor_type,
                pan_name: addClientBasicDetails["pan_name"],
                pan: addClientBasicDetails["pan"],
                email: addClientBasicDetails["email"],
                mobile: addClientBasicDetails["mobile"],
                gender: addClientBasicDetails["gender"],
                dob: addClientBasicDetails["dob"],
                is_pan_consent_accepted:
                  addClientBasicDetails["is_pan_consent_accepted"],
              });
              if (addClientBasicDetails["investor_type"] == "sole_proprietorship") {
                payload["investor_type"] = "Sole Proprietorship"
              }
              else if (props.prePanData?.investor_type == "trust") {
                payload["investor_type"] = "Trust"
              }
              else if (props.prePanData?.investor_type == "huf") {
                payload["investor_type"] = "HUF"
              }
              else if (props.prePanData?.investor_type == "partnership_firm") {
                payload["investor_type"] = "Partnership Firm"
              }
              payload["ignorePan"] = true;
              console.log('setting pan verified to false ----------------------')
              props.setPanVerified(false);
              const ress = await userRegistrationFunc(payload, flowComplete);
            } else {
              errorToast(res.error, true);
            }
          }

          eventLog({
            type: "add_client_pan_verify",
            action: {
              trigger: "false",
            },
          });
          // return;
          setPanAttempts(panAttempts => panAttempts+1);
          setLoader(false); 
        }
      });
    }
  };

  
  const userRegistrationFunc = async (payload, flowComplete) => {
    setLoader(true);
    await api.userRegistration(payload).then((res) => {
      setClickProceed(true)
      if (res.kind == "ok") {
        const data = res.data;
        eventLog({
          type: "complete_kyc",
          action: {
            trigger: "true",
          },
        });
        if (flowComplete) {
          successToast("Details submitted successfully");
          props.setAddClientSuccess(true);
        } else if (data.is_ckyc == 0) {
          props.setCkycFailed(true);
          props.setClientRegistration(true);
          setLoader(false);
          setDisabled(true);
        } else {
          setLoader(false);
          setDisabled(true);
          props.setClientRegistration(true);
        }
        props.setBasicKycDetails({
          ...addClientBasicDetails,
          user_id: data.user_id,
          investor_type: convertInvestorType(data.investor_type),
        });
        props.setUseridValue(data.user_id)
      } else {
        eventLog({
          type: "complete_kyc",
          action: {
            trigger: "false",
          },
        });
        errorToast(res.error, true);
        setLoader(false);
        return;
      }
    });
  };
  return (
    <div
      className="add_client_kyc_basic_details_container"
      style={{ display: !props.visible ? "none" : "" }}
    >
      <div className="add_client_kyc_basic_details_main_container">
        <h4 className="add_client_kyc_pre_pan_main_container_title">
          Client Details
        </h4>
        {/* pan number */}
        <InputBoxV2
          label={"PAN"}
          id="pan"
          type={clientKycBasicDetail["pan"].type}
          disabled={true}
          maxLength="10"
          autoCapitalize={true}
          validatePan={true}
          value={addClientBasicDetails["pan"]}
          setValue={setAddClientBasicDetails}
          object={addClientBasicDetails}
          required={clientKycBasicDetail["pan"]["required"]}
          errorObject={addClientBasicDetailsError}
          setErrorObject={setAddClientBasicDetailsError}
          isError={addClientBasicDetailsError["pan"]}
          errorText="Invalid PAN. Please try again."
        />

        {/* pan name */}
        <InputBoxV2
          label={"Name as per PAN"}
          id="pan_name"
          type={clientKycBasicDetail["pan_name"].type}
          // disabled={true}
          autoCapitalize={true}
          value={addClientBasicDetails["pan_name"]}
          visibility={
            ( addClientBasicDetails["investor_type"] !== "sole_proprietorship" && clickProceed)
              ? false
              : true
          }
          setValue={setAddClientBasicDetails}
          object={addClientBasicDetails}
          required={clientKycBasicDetail["pan_name"]["required"]}
          errorObject={addClientBasicDetailsError}
          setErrorObject={setAddClientBasicDetailsError}
          isError={addClientBasicDetailsError["pan_name"]}
          errorText="Invalid PAN Name. Please try again."
        />

        {/* investor Category */}
        <InputBoxV2
          label={"Investor Category"}
          id="investor_type"
          type={clientKycBasicDetail["investor_type"].type}
          disabled={true}
          visibility={entity_type_array.includes(investor_type) ? true : false}
          maxLength="10"
          autoCapitalize={true}
          validatePan={true}
          value={entityFullName[investor_type]}
          setValue={setAddClientBasicDetails}
          object={addClientBasicDetails}
          required={clientKycBasicDetail["investor_type"]["required"]}
          errorObject={addClientBasicDetailsError}
          setErrorObject={setAddClientBasicDetailsError}
          isError={addClientBasicDetailsError["investor_type"]}
          errorText="Invalid PAN. Please try again."
        />

        <InputBoxV2
          dropdown={true}
          dropDownArray={["Private Limited", "Public Limited"]}
          id="company_type"
          type={clientKycBasicDetail["company_type"].type}
          disabled={disabled || disableBasicDetails["email"]}
          visibility={["private_limited_company"].includes(investor_type) ? true : false}
          value={addClientBasicDetails["company_type"]}
          setValue={setAddClientBasicDetails}
          object={addClientBasicDetails}
          // required={clientKycBasicDetail["company_type"]["required"]}
          errorObject={addClientBasicDetailsError}
          setErrorObject={setAddClientBasicDetailsError}
          isError={addClientBasicDetailsError["company_type"]}
          errorText="Please select company type"
        />

        {/* Email ID */}
        <InputBoxV2
          label={"Email ID"}
          id="email"
          type={clientKycBasicDetail["email"].type}
          // disabled={true}
          value={addClientBasicDetails["email"]}
          setValue={setAddClientBasicDetails}
          object={addClientBasicDetails}
          required={clientKycBasicDetail["email"]["required"]}
          errorObject={addClientBasicDetailsError}
          setErrorObject={setAddClientBasicDetailsError}
          isError={addClientBasicDetailsError["email"]}
          errorText={
            !addClientBasicDetails["email"]
              ? "This field cannot be empty."
              : !permittedEmailError
              ? "Incorrect email ID format. Please use a valid email ID (example@email.com)."
              : `Please provide an alternate email ID. Email IDs ending in ${prohibitedEmails.join(
                  " or "
                )} will not be accepted.`
          }
        />

        {/* Mobile No. */}
        <InputBoxV2
          label={"Mobile Number"}
          id="mobile"
          type={clientKycBasicDetail["mobile"].type}
          // disabled={true}
          disabled={(props.userData != ""
                ? props.userData.kyc_status === 3 || props.userData.tc_status === 0
                  ? false
                  : true
                : false)}
          maxLength="10"
          value={addClientBasicDetails["mobile"]}
          setValue={setAddClientBasicDetails}
          object={addClientBasicDetails}
          required={clientKycBasicDetail["mobile"]["required"]}
          errorObject={addClientBasicDetailsError}
          setErrorObject={setAddClientBasicDetailsError}
          isError={addClientBasicDetailsError["mobile"]}
          errorText={
            addClientBasicDetails["mobile"]
              ? "Please enter a valid 10-digit mobile number."
              : "This field cannot be empty."
          }
        />

        {/* select investor mode */}
        <div
          className="add_client_investor_type"
          style={{
            display: entity_type_array.includes(investor_type) ? "none" : "",
          }}
        >
          <h4>
            Select Investor Category <span style={{ color: "red" }}> *</span>
          </h4>

          <div className="add_client_investor_select_type">
            <label id="individual" style={{ marginRight: "10%" }}>
              <input
                id="individual"
                type="radio"
                name="Investor_type"
                value={"Individual"}
                className="radioClass"
                disabled={(props.userData != ""
                ? props.userData.kyc_status === 3 || props.userData.tc_status === 0
                  ? false
                  : true
                : false)}
                onChange={(e) => {
                  setAddClientBasicDetails({
                    ...addClientBasicDetails,
                    investor_type: e.target.value,
                  });
                  setInvalidInvestorCategory(false);
                }}
                checked={
                  addClientBasicDetails["investor_type"] === "Individual"
                }
              />
              Individual
            </label>

            <label id="sole_proprietorship">
              <input
                id="sole_proprietorship"
                type="radio"
                name="Investor_type"
                value={"sole_proprietorship"}
                className="radioClass"
                disabled={(props.userData != ""
                ? props.userData.kyc_status === 3 || props.userData.tc_status === 0
                  ? false
                  : true
                : false)}
                onChange={(e) => {
                  setAddClientBasicDetails({
                    ...addClientBasicDetails,
                    investor_type: e.target.value,
                  });
                  setInvalidInvestorCategory(false);
                }}
                checked={
                  addClientBasicDetails["investor_type"] ===
                  "sole_proprietorship"
                }
              />
              Sole Proprietorship
            </label>
          </div>
          <div>
            <p
              className="input_box_v2_invalid_text"
              style={{
                opacity: invalidInvestorCategory ? 1 : 0,
                marginTop: "2px",
              }}
            >
              {invalidInvestorCategory
                ? "Please Select Investor Category."
                : ""}
            </p>
          </div>
        </div>

        {/* Date of Birth. */}
        <InputBoxV2
          label={ !panType_IS ? "Date of Birth" : "Date of Incorporation"}
          id="dob"
          type={clientKycBasicDetail["dob"].type}
          disabled={(disabled || props.userData != ""
          ? props.userData.kyc_status === 3 || props.userData.tc_status === 0
            ? false
            : true
          : false)}
          visibility={
            (addClientBasicDetails["investor_type"] !== "Individual" && clickProceed)
              ? false
              : true
          }
          maxLength="10"
          checkForAge={ !panType_IS ? true : false}
          min={startDate}
          max={maxDate}
          value={addClientBasicDetails["dob"]}
          setValue={setAddClientBasicDetails}
          object={addClientBasicDetails}
          required={clientKycBasicDetail["dob"]["required"]}
          errorObject={addClientBasicDetailsError}
          setErrorObject={setAddClientBasicDetailsError}
          isError={addClientBasicDetailsError["dob"]}
          errorText={
           !addClientBasicDetails["dob"]
              ? "This field cannot be empty."
              : !panType_IS ?(getAge(addClientBasicDetails["dob"]) > 18
              ? "Invalid Date of Birth."
              : "Age must exceed 18 years."):(
              maxDate ===
              moment(addClientBasicDetails["dob"]).format("YYYY-MM-DD")
              ? "Date of incorporation cannot be the same as today's date. Please enter a different date."
              : ""
              )
           
          }
        />

        {/* select gender */}
        <div
          // className="add_client_gender_type_container"
          style={{
            display:
              addClientBasicDetails["investor_type"] == "Individual"
                ? ""
                : "none",
            marginBottom: "25px",
          }}
        >
          <h4>
            Gender<span style={{ color: "red" }}> *</span>
          </h4>

          <div className="add_client_gender_type">
            <label id="male" style={{ marginRight: "10%" }}>
              <input
                id="male"
                type="radio"
                name="Gender"
                value={"male"}
                className="radioClass"
                disabled={(props.userData != ""
                ? props.userData.kyc_status === 3 || props.userData.tc_status === 0
                  ? false
                  : true
                : false)}
                onChange={(e) => {
                  handleChangeGender(e.target.value);
                }}
                checked={addClientBasicDetails["gender"] === "male"}
              />
              Male
            </label>

            <label id="female" style={{ marginRight: "10%" }}>
              <input
                id="female"
                type="radio"
                name="Gender"
                value={"female"}
                className="radioClass"
                disabled={(props.userData != ""
                ? props.userData.kyc_status === 3 || props.userData.tc_status === 0
                  ? false
                  : true
                : false)}
                onChange={(e) => {
                  handleChangeGender(e.target.value);
                }}
                checked={addClientBasicDetails["gender"] === "female"}
              />
              Female
            </label>

            <label id="others">
              <input
                id="others"
                type="radio"
                name="Gender"
                value={"others"}
                className="radioClass"
               disabled={(props.userData != ""
               ? props.userData.kyc_status === 3 || props.userData.tc_status === 0
                 ? false
                 : true
               : false)}
                onChange={(e) => {
                  handleChangeGender(e.target.value);
                }}
                checked={addClientBasicDetails["gender"] === "others"}
              />
              Others
            </label>
          </div>
          <div>
            <p
              className="input_box_v2_invalid_text"
              style={{
                opacity: invalidGender ? 1 : 0,
                marginTop: "2px",
              }}
            >
              {invalidGender ? "Please select appropriate gender" : ""}
            </p>
          </div>
        </div>
      </div>

      {/* cta */}
      {!disabled ? (
        <div
          className="add_client_kyc_basic_details_submit_cta"
          onClick={(e) => {
            onProceed(e);
          }}
        >
          {loader ? (
            <Loader type="TailSpin" color="#FFFFFF" height={25} width={25} />
          ) : (
            <p className="add_client_kyc_basic_details_cta_text">Proceed</p>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default AddClientKycBasicDetails;
