import { API } from "aws-amplify";
import * as Types from "../api.types";

/**
 * Manages all requests to the API.
 */
export class KYC_LINK_API_V2 {
  /**
   * @description this function is for send kyc link to client
   * @param data
   * @returns this return the payment data
   */
  async sendKycLink(data: any): Promise<Types.GetIFAKyc> {
    // payload
    const params = {
      body: data,
    };

    //call the api try and catch
    try {
      const response: any = await API.post(
        "baseurl",
        "/ifa_send_kyc_link/link",
        params
      );
      return { kind: "ok", data: response.data };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
            errors: [],
          };
        } else {
          if (err.response.data.code === 422) {
            return {
              kind: "bad-data",
              error: err.response.data.message,
              errors: err.response.data.errors,
            };
          }
          return {
            kind: "bad-data",
            error: err.response.data.message,
            errors: [],
          };
        }
      }
      return { kind: "bad-data", error: err.response.data.message, errors: [] };
    }
  }
  /**
   * @description Method to validate pan number
   * @param data
   * @returns success or faliure
   */
  async checkPanNumber(data: any): Promise<Types.GetKycResult> {
    // payload
    const params = {
      body: data,
    };

    // call the api try and catch
    try {
      const response: any = await API.post(
        "baseurl",
        "/ifa_send_kyc_link/pan_verify",
        params
      );
      return { kind: "ok", data: response.data || response.message || {} };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }
  /**
   * @description Method to check if user exists
   * @param data
   * @returns success or faliure
   */
  async checkUserExist(uuid: any): Promise<Types.GetKycResult> {
    // call the api try and catch
    try {
      const response: any = await API.get(
        "baseurl",
        `/ifa_send_kyc_link/user_details/${uuid}`,
        {}
      );
      return { kind: "ok", data: response.data || response.message || {} };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }
  /**
   * @description Method to fetch ifa data
   * @param data
   * @returns success or faliure
   */
  async fetchIfaInfo(referralCode: any): Promise<Types.GetKycResult> {
    // call the api try and catch
    try {
      const response: any = await API.get(
        "baseurl",
        `/ifa_send_kyc_link/ifa_info/${referralCode}`,
        {}
      );
      return { kind: "ok", data: response.data || response.message || {} };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }
  /**
   * @description Method to register user in the system
   * @param data
   * @returns success or faliure
   */
  async userRegistration(data: any): Promise<Types.GetKycResult> {
    // payload
    const params = {
      body: data,
    };

    // call the api try and catch
    try {
      const response: any = await API.post(
        "baseurl",
        "/ifa_send_kyc_link/user_profile",
        params
      );
      return { kind: "ok", data: response.data || response.message || {} };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }
  /**
   * @description Method to validate ifsc code and account number
   * @param data
   * @returns success or faliure
   */
  async validateIfscAndAccount(data: any): Promise<Types.GetKycResult> {
    // payload
    const params = {
      body: data,
    };

    // call the api try and catch
    try {
      const response: any = await API.post(
        "baseurl",
        "/ifa_send_kyc_link/penny_drop",
        params
      );
      return { kind: "ok", data: response.data || response.message || {} };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }
  /**
   * @description Method to generate OTP
   * @param data
   * @returns success or faliure
   */
  async generateOtp(data: any): Promise<Types.GetKycResult> {
    // payload
    const params = {
      body: data,
    };

    // call the api try and catch
    try {
      const response: any = await API.post(
        "baseurl",
        "/ifa_send_kyc_link/otp",
        params
      );
      return { kind: "ok", data: response.data || response.message || {} };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }
  /**
   * @description Method to verify OTP
   * @param data
   * @returns success or faliure
   */
  async verifyOtp(data: any): Promise<Types.GetKycResult> {
    // payload
    const params = {
      body: data,
    };

    // call the api try and catch
    try {
      const response: any = await API.put(
        "baseurl",
        "/ifa_send_kyc_link/otp",
        params
      );
      return { kind: "ok", data: response.data || response.message || {} };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }
  /**
   * @description Method to UPLOAD entity related documents
   * @param data
   * @returns success or faliure
   */
  /**
   * @description Method to save client data
   * @param data
   * @returns success or faliure
   */
  async clientSave(data: any): Promise<Types.GetKycResult> {
    // payload
    const params = {
      body: data,
    };

    // call the api try and catch
    try {
      const response: any = await API.post(
        "baseurl",
        "/ifa_send_kyc_link/client_save",
        params
      );
      return { kind: "ok", data: response.data || response.message || {} };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }
  async saveEntityDetails(data: any): Promise<Types.GetKycResult> {
    // payload
    const params = {
      body: data,
    };

    // call the api try and catch
    try {
      const response: any = await API.post(
        "baseurl",
        "/ifa_send_kyc_link/entity_doc_upload",
        params
      );
      return { kind: "ok", data: response.data || response.message || {} };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }
}
