import React, { useEffect, useState } from "react";
import "./index.css";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useHistory,
} from "react-router-dom";
import {
  CommonHeader,
  CommonSidebar,
  ContentLoadingLoader,
  FullScreenLoader,
  useNetwork,
} from "./components";
import {
  AuthPage,
  ClientInvestment,
  ClientKyc,
  ClientList,
  ClientKycLink,
  ClientTc,
  ClientRepayment,
  IFAPayment,
  Overview,
  Pagenotfound,
  InvestmentRequests,
  WalletLinks,
  PoolList,
  Support,
  Withdrawal,
  WithdrawalConfirmation,
  IFAProfile,
  Faq,
  Downloads,
  Marketing,
  WalletRequestSuccess,
  WalletRequestFailure,
  TransactionRequests,
  IfaCommissionsSummary,
  ClientKycLinkV2,
  AddClientKycV2,
  ClientInvestmentCertificate,
  WalletRequestStatus,
  SwitchPlanRequest,
} from "./pages";
import SignalWifiOffIcon from "@mui/icons-material/SignalWifiOff";
import PoolClient from "./pages/Poolclients/poolclient";
import WithdrawalHistoryFunction from "./pages/WithdrawalHistory/withdrawalHistoryFunction";
import WalletRequestConfirmation from "./pages/WalletRequestConfirmation/walletRequestConfirmation";
import InvestmentConsentConfirmation from "./pages/InvestmentConsentConfirmation/InvestmentConsentConfirmation";
import Payment from "./pages/Forms/payment";
import Repayment from "./pages/Forms/repayment";
import BorrowerForm from "./pages/Forms/borrowerForm";
import { APP_RELEASE_API } from "./services/api";
import MainTenanceScreen from "./pages/MaintenanceScreen/maintenanceScreen";
import NetworkErrorScreen from "./pages/NetworkErrorScreen/networkErrorScreen";


const App = () => {
  const [showCommonHeader, setShowCommonHeader] = React.useState(false);
  const [showCommonSidebar, setShowCommonSidebar] = React.useState(false);
  const connection = useNetwork();
  const appReleaseApi = new APP_RELEASE_API();
  const [ismaintenance, setMaintenance] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isNetworkDown, setIsNetworkDown] = React.useState(false);
  const history = useHistory();

  useEffect(() => {
    //fetching app_realse api
    fetch_release_status();
  }, []);

  // handle the sidebar and header
  const handleShowCommonHeader = (value) => {
    setShowCommonHeader(value);
  };

  // handle the sidebar and header
  const handleShowCommonSidebar = (value) => {
    setShowCommonSidebar(value);
  };

  // calling the app_realse api
  const fetch_release_status = async () => {
    try {
      const response = await appReleaseApi.getAppReleaseData();
      if (response.kind === 'ok') {
        setMaintenance(response.data.is_maintenance);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching release status:', error);
      setIsLoading(false); // Set loading to false even on error
    }
  };

  return !connection || isNetworkDown ? (
    <NetworkErrorScreen setIsNetworkDown={setIsNetworkDown} history={history} />
  ) : isLoading ? (
    <FullScreenLoader backgroundColor={"white"} color={"rgba(0,0,0,0.75)"} />
  ) : !ismaintenance && !isNetworkDown ? (
    <Router>
      <div className="route_container">
        {/* header */}
        {showCommonHeader && <CommonHeader />}

        <div className="route_rest_container">
          {/* side bar */}
          {showCommonSidebar && <CommonSidebar />}

          {/* network msg */}
          <div
            className="network_fixed_div_container"
            style={{
              display: connection ? "none" : "flex",
            }}
          >
            <div className="network_warnning_text">
              {/* icon */}
              <SignalWifiOffIcon
                style={{
                  fontSize: "17px",
                  paddingRight: "5px",
                  color: "#fff",
                }}
              />
              {connection
                ? "Online"
                : "You are not connected with the internet."}
            </div>
          </div>

          {/* routes */}
          <div className="route_switch_container">
            <Switch>
              <Route
                exact
                path="/"
                render={(props) => (
                  <AuthPage
                    showCommonHeader={handleShowCommonHeader}
                    showCommonSidebar={handleShowCommonSidebar}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/profile"
                render={(props) => (
                  <IFAProfile
                    showCommonHeader={handleShowCommonHeader}
                    showCommonSidebar={handleShowCommonSidebar}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/overview"
                render={(props) => (
                  <Overview
                    showCommonHeader={handleShowCommonHeader}
                    showCommonSidebar={handleShowCommonSidebar}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/clients"
                render={(props) => (
                  <ClientList
                    showCommonHeader={handleShowCommonHeader}
                    showCommonSidebar={handleShowCommonSidebar}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/clients/idle"
                render={(props) => (
                  <ClientList
                    showCommonHeader={handleShowCommonHeader}
                    showCommonSidebar={handleShowCommonSidebar}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/downloads"
                render={(props) => (
                  <Downloads
                    showCommonHeader={handleShowCommonHeader}
                    showCommonSidebar={handleShowCommonSidebar}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/marketing"
                render={(props) => (
                  <Marketing
                    showCommonHeader={handleShowCommonHeader}
                    showCommonSidebar={handleShowCommonSidebar}
                    {...props}
                  />
                )}
              />
              {/* <Route
                exact
                path="/clients/kyc"
                render={(props) => (
                  <ClientKyc
                    showCommonHeader={handleShowCommonHeader}
                    showCommonSidebar={handleShowCommonSidebar}
                    {...props}
                  />
                )}
              /> */}
              <Route
                exact
                path="/clients/kyc"
                render={(props) => (
                  <AddClientKycV2
                    showCommonHeader={handleShowCommonHeader}
                    showCommonSidebar={handleShowCommonSidebar}
                    {...props}
                  />
                )}
              />

              <Route
                exact
                path="/ifa/:ifa_referral_code"
                render={(props) => (
                  <ClientKycLinkV2
                    showCommonHeader={handleShowCommonHeader}
                    showCommonSidebar={handleShowCommonSidebar}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/clients/networth"
                render={(props) => (
                  <ClientKyc
                    showCommonHeader={handleShowCommonHeader}
                    showCommonSidebar={handleShowCommonSidebar}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/clients/withdrawalHistory"
                render={(props) => (
                  <WithdrawalHistoryFunction
                    showCommonHeader={handleShowCommonHeader}
                    showCommonSidebar={handleShowCommonSidebar}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/clients/investments"
                render={(props) => (
                  <ClientInvestment
                    showCommonHeader={handleShowCommonHeader}
                    showCommonSidebar={handleShowCommonSidebar}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/clients/repayments"
                render={(props) => (
                  <ClientRepayment
                    showCommonHeader={handleShowCommonHeader}
                    showCommonSidebar={handleShowCommonSidebar}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/pools"
                render={(props) => (
                  <PoolList
                    showCommonHeader={handleShowCommonHeader}
                    showCommonSidebar={handleShowCommonSidebar}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/pools/clients"
                render={(props) => (
                  <PoolClient
                    showCommonHeader={handleShowCommonHeader}
                    showCommonSidebar={handleShowCommonSidebar}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/transaction-requests"
                render={(props) => (
                  <TransactionRequests
                    showCommonHeader={handleShowCommonHeader}
                    showCommonSidebar={handleShowCommonSidebar}
                    {...props}
                  />
                )}
              />
              {/* <Route
                exact
                path="/paymentlinks"
                render={(props) => (
                  <InvestmentRequests
                    showCommonHeader={handleShowCommonHeader}
                    showCommonSidebar={handleShowCommonSidebar}
                    {...props}
                  />
                )}
              /> */}
              {/* <Route
                exact
                path="/walletlinks"
                render={(props) => (
                  <WalletLinks
                    showCommonHeader={handleShowCommonHeader}
                    showCommonSidebar={handleShowCommonSidebar}
                    {...props}
                  />
                )}
              /> */}
              <Route
                exact
                path="/withdrawals"
                render={(props) => (
                  <Withdrawal
                    showCommonHeader={handleShowCommonHeader}
                    showCommonSidebar={handleShowCommonSidebar}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/withdrawals/:uuid"
                render={(props) => (
                  <WithdrawalConfirmation
                    showCommonHeader={handleShowCommonHeader}
                    showCommonSidebar={handleShowCommonSidebar}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/borrower"
                render={(props) => (
                  <BorrowerForm
                    showCommonHeader={handleShowCommonHeader}
                    showCommonSidebar={handleShowCommonSidebar}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/uploadDocument"
                render={(props) => (
                  <Payment
                    showCommonHeader={handleShowCommonHeader}
                    showCommonSidebar={handleShowCommonSidebar}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/repayment"
                render={(props) => (
                  <Repayment
                    showCommonHeader={handleShowCommonHeader}
                    showCommonSidebar={handleShowCommonSidebar}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/wallet-requests/:uuid"
                render={(props) => (
                  <WalletRequestConfirmation
                    showCommonHeader={handleShowCommonHeader}
                    showCommonSidebar={handleShowCommonSidebar}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/wallet-requests/:wallet_request_uuid/order/:order_uuid/success"
                render={(props) => (
                  <WalletRequestSuccess
                    showCommonHeader={handleShowCommonHeader}
                    showCommonSidebar={handleShowCommonSidebar}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/wallet-requests/:wallet_request_uuid/order/:order_uuid/failure"
                render={(props) => (
                  <WalletRequestFailure
                    showCommonHeader={handleShowCommonHeader}
                    showCommonSidebar={handleShowCommonSidebar}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/wallet-requests/:user_id/transaction/:transaction_id/status"
                render={(props) => (
                  <WalletRequestStatus
                    showCommonHeader={handleShowCommonHeader}
                    showCommonSidebar={handleShowCommonSidebar}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/support"
                render={(props) => (
                  <Support
                    showCommonHeader={handleShowCommonHeader}
                    showCommonSidebar={handleShowCommonSidebar}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/faq"
                render={(props) => (
                  <Faq
                    showCommonHeader={handleShowCommonHeader}
                    showCommonSidebar={handleShowCommonSidebar}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/ifa/links/:uuid"
                render={(props) => (
                  <IFAPayment
                    showCommonHeader={handleShowCommonHeader}
                    showCommonSidebar={handleShowCommonSidebar}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/ifa/:referral_code/tc"
                render={(props) => (
                  <ClientTc
                    showCommonHeader={handleShowCommonHeader}
                    showCommonSidebar={handleShowCommonSidebar}
                    showCommonHeaderScreen={showCommonHeader}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/ifa/clients/:consent_uuid/investment-consent"
                render={(props) => (
                  <InvestmentConsentConfirmation
                    showCommonHeader={handleShowCommonHeader}
                    showCommonSidebar={handleShowCommonSidebar}
                    {...props}
                  />
                )}
              />
              {/* <Route
                exact
                path="/summary/commissions"
                render={(props) => (
                  <IfaCommissionsSummary
                    showCommonHeader={handleShowCommonHeader}
                    showCommonSidebar={handleShowCommonSidebar}
                    {...props}
                  />
                )}
              /> */}
              <Route
                exact
                path="/clients/investment-certificate"
                render={(props) => (
                  <ClientInvestmentCertificate
                    showCommonHeader={handleShowCommonHeader}
                    showCommonSidebar={handleShowCommonSidebar}
                    {...props}
                  />
                )}
              />

              <Route
                exact
                path="/switch-plans/:uuid"
                render={(props) => (
                  <SwitchPlanRequest
                    showCommonHeader={handleShowCommonHeader}
                    showCommonSidebar={handleShowCommonSidebar}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/404"
                render={(props) => (
                  <Pagenotfound
                    showCommonHeader={handleShowCommonHeader}
                    showCommonSidebar={handleShowCommonSidebar}
                    {...props}
                  />
                )}
              />

              <Redirect to="/404" />
            </Switch>
          </div>
        </div>
      </div>
    </Router>
  ) : (
    <MainTenanceScreen></MainTenanceScreen>
  );
};

export default App;
