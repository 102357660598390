import React, { useEffect, useState } from "react";
import "./walletrequestfailure.css";
import { WalletApi } from "../../services/api";
import failureIcon from "../../assets/images/failure.svg";
import { convertTimestampFormat, errorToast } from "../../utils/helper";
import config from "../../config/settings";

const WalletRequestFailure = (props) => {
  const api = new WalletApi();
  const [params, setParams] = useState({});
  const [data, setData] = useState({
    amount: 0,
    payment_id: "",
    status: "NOT DEFINE",
    txn_date: "",
  });

  useEffect(() => {
    props.showCommonHeader(false);
    props.showCommonSidebar(false);
    setParams(props.match.params);
  }, []);

  useEffect(() => {
    if (params.wallet_request_uuid && params.order_uuid) {
      api
        .getOrderData(params.order_uuid)
        .then((res) => {
          if (res.kind == "ok") {
            setData(res.data);
          } else {
            errorToast("Error while fetching order details");
          }
        })
        .catch((err) => console.log("err", err));
    }
  }, [params]);

  return (
    <React.Fragment>
      <div className="wallet_request_failure_header">
        <img
          src="https://arthmate-p2p-public-assets.s3.ap-south-1.amazonaws.com/images/1invest-logo-color-dark.png"
          alt="logo"
          className="auth_logo_img"
          onClick={() =>
            window.open("https://www.arthmatedirect.com/", "_self")
          }
        />
      </div>
      <div className="wallet_request_failure_page_container">
        <>
          <div className="wallet_request_failure_head_grid_container">
            <div style={{ textAlign: "center" }}>
              <img
                src={failureIcon}
                alt="check_mark_icon"
                className="wallet_request_failure_check_mark"
              />
              <p className="wallet_request_failure_main_title_text">
                Transaction Failed
              </p>
            </div>
            <br />
            <div className="wallet_request_failure_grid_container"></div>
            <div className="wallet_request_success_grid_container">
              <div className="wallet_request_success_item_left">
                <p className="wallet_request_success_item_text">Order ID</p>
              </div>
              <div className="wallet_request_success_item_right">
                <p className="wallet_request_success_item_text">
                  {params.order_uuid}
                </p>
              </div>
            </div>
            <div className="wallet_request_failure_grid_container">
              <div className="wallet_request_failure_item_left">
                <p className="wallet_request_failure_item_text">Amount</p>
              </div>
              <div className="wallet_request_failure_item_right">
                <p className="wallet_request_failure_item_text">
                  ₹ {data.amount}
                </p>
              </div>
            </div>
            <div className="wallet_request_failure_grid_container">
              <div className="wallet_request_failure_item_left">
                <p className="wallet_request_failure_item_text">
                  Transaction ID
                </p>
              </div>
              <div className="wallet_request_failure_item_right">
                <p className="wallet_request_failure_item_text">
                  {data.payment_id}
                </p>
              </div>
            </div>
            <div className="wallet_request_failure_grid_container">
              <div className="wallet_request_failure_item_left">
                <p className="wallet_request_failure_item_text">TXN Date</p>
              </div>
              <div className="wallet_request_failure_item_right">
                <p className="wallet_request_failure_item_text">
                  {convertTimestampFormat(data.txn_date)}
                </p>
              </div>
            </div>
          </div>
          <div className="wallet_request_failure_main_title">
            <p style={{ color: "#4E4E4E" }}>
              If the amount has been debited from your bank, it will take 2-3
              business days to refund.
            </p>
            <p style={{ color: "#4E4E4E" }}>
              Please contact us at{" "}
              <strong style={{ color: "#3a86ff" }}>
                {config.support.email}
              </strong>{" "}
              with any questions about support.
            </p>
            <div className="wallet_request_failure_grid_container"></div>
          </div>
        </>
      </div>
    </React.Fragment>
  );
};

export default WalletRequestFailure;
