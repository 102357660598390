import React, { useEffect, useState, useRef } from "react";
import "./clientkyc.css";
import Loader from "react-loader-spinner";
import { useLocation, useHistory } from "react-router-dom";
import {
  errorToast,
  getFileExtension,
  getUniqueUUID,
  infoToast,
  successToast,
  warningToast,
  imageCompressor,
} from "../../utils/helper";
import { ErrorValue, InputInfoText } from "../../components";
import { Kyc_API } from "../../services/api";

import Auth from "@aws-amplify/auth";
import { NavigateBefore } from "@mui/icons-material";

const imageSizeLimit = 15;

const ClientKycBank = function (props) {
  const param = useLocation();
  const navigate = useHistory();
  const [countBank, setCountBank] = useState(2);
  const api = new Kyc_API();
  const checkFileRef = useRef(null);
  const [loader, setLoader] = useState(false);
  // const [panName , setPanName] = useState("");
  const [bank, setBank] = useState({
    bank_account_number:
      props.data.bank_account_number === undefined
        ? ""
        : props.data.bank_account_number,
    bank_account_holder_name:
      props.data.bank_account_holder_name === undefined
        ? ""
        : props.data.bank_account_holder_name,
    bank_ifsc_code:
      props.data.bank_ifsc_code === undefined ? "" : props.data.bank_ifsc_code,
    bank_cancel_cheque:
      props.data.bank_cancel_cheque === undefined
        ? ""
        : props.data.bank_cancel_cheque,
    bank_cancel_cheque_data: {
      base64: null,
      size: null,
      content_type: null,
    },
    bank_data: props.data.bank_data === undefined ? {} : props.data.bank_data,
  });
  const [isVerified, setIsVerified] = useState(false);
  const [verifyLoader, setVerifyLoader] = useState(false);
  const [verifyFailed, setVerifyFailed] = useState(false);
  const [showAccountNumberAttemptLeft, setshowAccountNumberAttemptLeft] =
    useState(true);
  const [showIfscCodeAttemptLeft, setshowIfscCodeAttemptLeft] = useState(true);
  const [hideButton, setHideButton] = useState(
    param?.state?.uuid == "" ? 0 : param.pathname.startsWith("/ifa") ? 0 : 2
  );

  // send bank value
  useEffect(() => {
    props.value(bank);
    props.isVerified(isVerified);
    // setPanName(props.panName)
  }, [props, bank, isVerified, verifyFailed]);

  // handle changes into input
  const onHandleChange = async (e, label) => {
    // account holder name
    let ext;
    if (label === "bank_account_holder_name") {
      setBank({
        ...bank,
        bank_account_holder_name: e.target.value,
      });
    }
    // account number
    if (label === "bank_account_number") {
      setBank({
        ...bank,
        bank_account_number: e.target.value,
        bank_cancel_cheque: "",
        bank_cancel_cheque_data: {
          base64: null,
          size: null,
          content_type: null,
        },
      });
      setVerifyFailed(false);
      setHideButton(0);
      if (props.error.hasOwnProperty("bank_account_number")) {
        props.error["bank_account_number"] = "";
      }
    }
    // ifsc code
    if (label === "bank_ifsc_code") {
      setBank({
        ...bank,
        bank_ifsc_code: e.target.value,
        bank_cancel_cheque: "",
        bank_cancel_cheque_data: {
          base64: null,
          size: null,
          content_type: null,
        },
      });
      setVerifyFailed(false);
      setHideButton(0);
      if (props.error.hasOwnProperty("bank_ifsc_code")) {
        props.error["bank_ifsc_code"] = "";
      }
    }
    // cancel cheque / bank statement image
    if (label === "bank_cancel_cheque" && e.target.files[0]) {
      ext = await getFileExtension(e);
      if (e.target.files[0].size > imageSizeLimit * 1024 * 1024) {
        errorToast(
          `Cancel Cheque / Bank Statement image size should not be more than ${imageSizeLimit} MB`
        );
      } else if (ext != "jpeg" && ext != "png" && ext != "jpg") {
        props.error["bank_cancel_cheque"] = "Invalid image format";
        errorToast(
          `Please upload a valid format for the cancel cheque/bank statement.`
        );
        setBank({
          ...bank,
          bank_cancel_cheque: "",
          bank_cancel_cheque_data: {
            base64: null,
            size: null,
            content_type: null,
          },
        });
      } else {
        setLoader(true);
        let compressedImage = await imageCompressor(e.target.files[0]);
        let uuid = await getUniqueUUID();
        let ext = await getFileExtension(e);
        let fileName = `${uuid}.${ext}`;
        let reader = new FileReader(compressedImage);
        reader.readAsDataURL(compressedImage);
        reader.onload = function (evt) {
          setTimeout(() => {
            setBank({
              ...bank,
              bank_cancel_cheque: fileName,
              bank_cancel_cheque_data: {
                base64: evt.target.result,
                size: compressedImage.size,
                content_type: compressedImage.type,
              },
            });
            setLoader(false);
            console.log("props", props.error["bank_cancel_cheque"]);
            console.log(props);
            if (props.error.hasOwnProperty("bank_cancel_cheque")) {
              props.setErrors({ ...props.error, bank_cancel_cheque: [] });
            }
            //console.log("props", props.error["bank_cancel_cheque"].length );
          }, 2000);
        };
        reader.onerror = function (error) {
          console.log(
            "Error while uploading Cancel Cheque / Bank Statement image : ",
            error
          );
          setTimeout(() => {
            errorToast(
              `Cancel Cheque / Bank Statement image could not upload, Try again`
            );
            setLoader(false);
          }, 2000);
        };
      }
    }
  };

  // prevent only digit input
  const onKeyPress = (e) => {
    const re = /[^0-9]/g;
    if (re.test(e.key)) {
      e.preventDefault();
    }
  };

  // prevent only digit and alphabet
  const onIFSCKeyPress = (e) => {
    const re = /[ `!@#$%^&*()_+\-={};':"|,.<>?~]/g;
    if (re.test(e.key)) {
      e.preventDefault();
    }
  };

  // verify ifsc code and account number
  const nameMatch = async (panName, otherName) => {
    //const user = await Auth.currentCredentials();

    // payload
    console.log("panName", props.panName);
    let payload = {
      pan_name: panName,
      other_name: otherName,
    };
    console.log(payload);
    return await api.nameMatchApi(payload).then((res) => {
      if (res.kind == "ok") {
        if (res.data.document_data) {
          //successToast(res.data.msg);
          return true;
        } else {
          errorToast("Please upload the documents of the same person.");
          return false;
        }
      } else {
        errorToast("Please upload the documents of the same person.");
        return false;
      }
    });
  };

  const verifyIfscAndAccount = async () => {
    const re = /^[A-Za-z]{4}0[A-Z0-9a-z]{6}$/;
    setVerifyLoader(true);
    const user = await Auth.currentCredentials();
    // if ifsc code is less than 11 character
    if (bank.bank_ifsc_code.length != 11) {
      setTimeout(() => {
        setVerifyLoader(false);
        infoToast("Invalid IFSC. Please check and re-enter.");
      }, 2000);
    }

    else if (!re.test(bank.bank_ifsc_code)) {
      setTimeout(() => {
        setVerifyLoader(false);
        errorToast("Invalid IFSC. Please check and re-enter.");
      }, 2000);
    }

    // if there is no error
    else {
      // payload
      let payload = {
        ifsc: bank.bank_ifsc_code,
        account_number: bank.bank_account_number,
        loan_app_id: props.referral ? props.referral : user.identityId,
        mode: "ifa_client_onboarding"
      };

      // call the api
      await api.validateIfscAndAccount(payload).then(async (res) => {
        if (res.kind == "ok") {
          setshowAccountNumberAttemptLeft(false);
          setshowIfscCodeAttemptLeft(false);
          let data = res.data;
          let match = false;
          match = await nameMatch(props.panName, data.bank_data.accountName);
          setTimeout(() => {
            props.setValidateBank(true);
            setHideButton(1);
            setVerifyLoader(false);

            if (match) {
              setBank({
                ...bank,
                bank_data: data.bank_data,
                bank_account_holder_name: data?.bank_data?.accountName
              });
              setIsVerified(true);
              successToast(data.msg);
            } else {
              // errorToast("names doesn't match in PAN and bank details");
              setHideButton(1);
              setVerifyLoader(false);
              setVerifyFailed(true);
            }
          }, 2000);
        } else {
          if (res.error == "Please enter valid ifsc") {
            setTimeout(() => {
              setVerifyLoader(false);
              infoToast(res.error);
            }, 2000);
          } else if (
            res.error ==
            "Could not validate your bank details. Please upload an image of your cancelled cheque to proceed."
          ) {
            setTimeout(() => {
              setHideButton(1);
              props.setValidateBank(false);
              setVerifyLoader(false);
              setVerifyFailed(true);
              infoToast(res.error);
            }, 2000);
          } else {
            setTimeout(() => {
              setHideButton(1);
              props.setValidateBank(false);
              setVerifyLoader(false);
              // setVerifyFailed(true);
              errorToast(res.error);
            }, 2000);
          }
        }
      });
    }
  };

  return (
    <div>
      {/* account holder name */}
      {/* <div className="clientkyc_main_input_form" style={{ marginTop: "20px" }}>
        <label className="clientkyc_main_input_label">
          Account Holder Name
          <span style={{ color: "red" }}> *</span>
        </label>
        <input
          type="text"
          disabled={props.disable}
          value={bank.bank_account_holder_name}
          className="clientkyc_main_input_field"
          style={{
            borderColor: props.error.hasOwnProperty("bank_account_holder_name")
              ? props.error["bank_account_holder_name"].length > 0
                ? "#d43939"
                : undefined
              : undefined,
          }}
          onChange={(event) =>
            onHandleChange(event, "bank_account_holder_name")
          }
        />
        {props.error.hasOwnProperty("bank_account_holder_name") ? (
          <ErrorValue text={props.error["bank_account_holder_name"]} />
        ) : (
          <div />
        )}
      </div> */}

      {/* account number */}
      <div className="clientkyc_main_input_form" style={{ marginTop: "20px" }}>
        <label className="clientkyc_main_input_label">
          Account Number<span style={{ color: "red" }}> *</span>
        </label>
        <input
          type="text"
          disabled={props.disable}
          value={bank.bank_account_number}
          className="clientkyc_main_input_field"
          style={{
            borderColor: props.error.hasOwnProperty("bank_account_number")
              ? props.error["bank_account_number"].length > 0
                ? "#d43939"
                : undefined
              : undefined,
          }}
          onChange={(event) => onHandleChange(event, "bank_account_number")}
          onKeyPress={onKeyPress}
        />
        {props.error.hasOwnProperty("bank_account_number") ? (
          <ErrorValue text={props.error["bank_account_number"]} />
        ) : (
          <div />
        )}
        {countBank < 2 && showAccountNumberAttemptLeft === true ? (
          <InputInfoText text={`Attempts Left: ${countBank}`} />
        ) : (
          ""
        )}
      </div>

      {/* ifsc code */}
      <div className="clientkyc_main_input_form">
        <label className="clientkyc_main_input_label">
          IFSC Code<span style={{ color: "red" }}> *</span>
        </label>
        <input
          type="text"
          disabled={props.disable}
          maxLength={11}
          value={bank.bank_ifsc_code}
          className="clientkyc_main_input_field"
          style={{
            borderColor: props.error.hasOwnProperty("bank_ifsc_code")
              ? props.error["bank_ifsc_code"].length > 0
                ? "#d43939"
                : undefined
              : undefined,
            textTransform: "uppercase",
          }}
          onChange={(event) => onHandleChange(event, "bank_ifsc_code")}
          onKeyPress={onIFSCKeyPress}
        />
        {props.error.hasOwnProperty("bank_ifsc_code") ? (
          <ErrorValue text={props.error["bank_ifsc_code"]} />
        ) : (
          <div />
        )}
        {countBank < 2 && showIfscCodeAttemptLeft === true ? (
          <InputInfoText text={`Attempts Left: ${countBank}`} />
        ) : (
          ""
        )}
      </div>

      {/* verified cta */}
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-end",
        }}
      >
        <button
          className="clientkyc_ckyc_button"
          style={{
            display:
              hideButton == 0
                ? hideButton == 0 &&
                  bank.bank_ifsc_code.length == 11 &&
                  !props.disable
                  ? "flex"
                  : "none"
                : "none",
            alignSelf: "flex-end",
          }}
          onClick={() => {
            if (countBank == 0) {
              setTimeout(() => {
                errorToast("Number of retrials exhausted !");
                navigate.go(0);
              }, 2000);
            } else {
              setCountBank(countBank - 1);
              verifyIfscAndAccount();
            }
          }}
          disabled={verifyLoader}
        >
          {verifyLoader ? (
            <Loader type="TailSpin" color="#fff" height={15} width={15} />
          ) : (
            "Verify Bank Details"
          )}
        </button>
      </div>

      {/* cheque image / Bank Statement */}
      <div
        className="clientkyc_main_input_form"
        // style={{
        //   display: verifyFailed
        //     ? "flex"
        //     : !verifyFailed && props.disable && bank.bank_cancel_cheque != ""
        //     ? "flex"
        //     : !verifyFailed &&
        //       !isVerified &&
        //       props.error.hasOwnProperty("bank_cancel_cheque")
        //     ? "flex"
        //     : !verifyFailed && bank.bank_cancel_cheque != ""
        //     ? "flex"
        //     : "none",
        // }}

        style={{
          display: verifyFailed ? "flex" : "none",
        }}
      >
        <label className="clientkyc_main_input_label">
          Cancelled Cheque / Bank Statement Image
          <span style={{ color: "red" }}> *</span>
        </label>
        <div
          className="clientkyc_main_image_div_container"
          style={{
            borderColor: props.error.hasOwnProperty("bank_cancel_cheque")
              ? props.error["bank_cancel_cheque"].length > 0
                ? "#d43939"
                : undefined
              : undefined,
          }}
        >
          <div
            className="clientkyc_main_image_div"
            style={{
              backgroundColor: props.disable === true ? "#ececec" : undefined,
              color: props.disable === true ? "rgb(119, 119, 119)" : undefined,
            }}
          >
            <p className="clientkyc_main_image_text">
              {bank.bank_cancel_cheque}
            </p>
          </div>
          {props.disable === true ? (
            <div />
          ) : (
            <button
              onClick={() => checkFileRef.current.click()}
              className="clientkyc_main_image_button"
              style={{
                cursor: loader ? "not-allowed" : "pointer",
              }}
              disabled={loader}
            >
              {loader ? (
                <Loader type="TailSpin" color="#fff" height={18} width={18} />
              ) : bank.bank_cancel_cheque === "" ||
                bank.bank_cancel_cheque === null ? (
                "Upload"
              ) : (
                "Change"
              )}
            </button>
          )}
          <input
            type="file"
            ref={checkFileRef}
            style={{ display: "none" }}
            onChange={(event) => onHandleChange(event, "bank_cancel_cheque")}
          />
        </div>
        {/* info text */}
        {!props.disable ? (
          <InputInfoText
            text={`Please upload the image in jpeg/png format. The maximum file size should not exceed ${imageSizeLimit} MB.`}
          />
        ) : (
          ""
        )}
        {/* error text */}
        {props.error.hasOwnProperty("bank_cancel_cheque") ? (
          <ErrorValue text={props.error["bank_cancel_cheque"]} />
        ) : (
          <div />
        )}
      </div>
    </div>
  );
};

export default ClientKycBank;
