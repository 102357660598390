import React, { useEffect, useState } from "react";
import "./addClientEntityDetails.css";
import Loader from "react-loader-spinner";
import MenuItem from "@mui/material/MenuItem";
import {
  convertIntoDefaultState,
  convertIntoErrorState,
  errorToast,
  infoToast,
  successToast,
  checkValidPan,
  fillKycData,
  setDisableState,
  getCurrentAddressDocuments,
  getBeneficialAddressDocuments,
  getBeneficialAddressFilledDocuments,
  getClientEntityAssociatedOrgDocuments,
  getEntityDocumentsToRender,
} from "../../utils/helper";
import {
  InputBox,
  InputBoxV2,
  UploadInputV2,
  ErrorValue,
  UploadInputV3,
} from "../../components/index.js";
import {
  clientEntityDetails,
  orgDocumentState,
} from "../../constant/signupState";
import config from "../../config/settings";
import moment from "moment";
import StateDropdown from "../../components/state-dropdown/state-dropdown";
import { state_array } from "../../config/settings";
import { label } from "aws-amplify";
const entityDocument = [
  "Udyam Registration Certificate",
  "Shop & Establishment Act License",
  "Shop & Establishment Certificate",
  "CST Certificate",
  "GST Certificate",
  "VAT Certificate",
  "Sales & Income Tax Returns",
  "IEC (Importer Exporter Code) License",
  "Complete Income Tax Returns",
];

const AddClientEntityDetails = (props) => {
  const board_resolution_link = config.documents.board_resolution_link;
  const beneficial_ownership_document_link =
    config.documents.beneficial_ownership_document;

  const [addClientEntityDetails, setAddClientEntityDetails] = useState(
    convertIntoDefaultState(clientEntityDetails)
  );

  const [addClientEntityDetailsError, setAddClientEntityDetailsError] =
    useState(convertIntoErrorState(clientEntityDetails));

  const [disableEntityDetails, setDisableEntityDetails] = useState(
    convertIntoErrorState(clientEntityDetails)
  );

  //entity document 1
  const [enityDocument1, setEnityDocument1] = useState({
    document_type: null,
    document_front: null,
  });

  //entity document 2
  const [enityDocument2, setEnityDocument2] = useState({
    document_type: null,
    document_front: null,
  });

  const [currentDocumentsToRender, setCurrentDocumentsToRender] = useState([]);
  const [beneficialDocumentsToRender, setBeneficialDocumentsToRender] =
    useState([]);

  //entity documents to render
  const [entityDocumentsToRender, setEntityDocumentToRender] = useState([]);

  //beneficial owner current state
  const [beneficialOwner, setBeneficialOwner] = useState(1);

  const maxDate = moment().subtract(1, "days").format("YYYY-MM-DD");

  useEffect(() => {
    if (
      props.userData &&
      props.userData !== "" &&
      props.userData.kyc_status === 3 &&
      props.userData.investorType !== "Individual" &&
      !props.userData.has_document
    ) {
      setDisableState(
        props.userData.entity_documents,
        disableEntityDetails,
        setDisableEntityDetails
      );
    }
    if (props.userData && props.userData !== "") {
      setAddClientEntityDetails(props.userData.entity_documents);
      if (
        props.userData.entity_documents.entity_document_1 &&
        props.userData.entity_documents.entity_document_1 !== null
      ) {
        setEnityDocument1(props.userData.entity_documents.entity_document_1);
      }
      if (
        props.userData.entity_documents.entity_document_2 &&
        props.userData.entity_documents.entity_document_2 !== null
      ) {
        setEnityDocument2(props.userData.entity_documents.entity_document_2);
      }
    }
  }, []);

  useEffect(() => {
    props.setEntityDocument({
      ...addClientEntityDetails,
      entity_document_1: {
        document_type: enityDocument1.document_type,
        document: enityDocument1.document_front,
      },
      entity_document_2: {
        document_type: enityDocument2.document_type,
        document: enityDocument2.document_front,
      },
      beneficial_owner_selected: beneficialOwner,
    });

    //remove error
    props.setErrorState({
      ...addClientEntityDetailsError,
    });
  }, [addClientEntityDetails, enityDocument1, enityDocument2, beneficialOwner]);

  //check for error
  useEffect(() => {
    if (props.errorState && props.errorState != null) {
      setAddClientEntityDetailsError({
        ...addClientEntityDetailsError,
        ...props.errorState,
      });
    }
  }, [props]);

  //check for error
  useEffect(() => {}, [addClientEntityDetailsError]);

  useEffect(() => {
    if (
      maxDate ===
      moment(addClientEntityDetails["date_of_incorporation"]).format("YYYY-MM-DD")
    ) {
      props.setErrorState({
        ...addClientEntityDetailsError,
        date_of_incorporation: true,
      });
    }
  }, [addClientEntityDetails["date_of_incorporation"]]);

  //gets the address field to render
  useEffect(() => {
    //gets the address field to render
    setCurrentDocumentsToRender(
      getCurrentAddressDocuments(clientEntityDetails)
    );

    //gets beneficial owner documents to render
    setBeneficialDocumentsToRender(
      getBeneficialAddressDocuments(clientEntityDetails)
    );

    //gets entity documents to render
    setEntityDocumentToRender(
      getEntityDocumentsToRender(clientEntityDetails, props.investorType)
    );

    ///set the beneficial owner component count
    if (props.userData && props.userData !== "") {
      setBeneficialOwner(
        getBeneficialAddressFilledDocuments(props.userData.entity_documents)
      );
    }
  }, [props.investorType]);

  //add beneficial section on add more
  const addBenefcialComponent = () => {
    if (beneficialOwner < 6) {
      setBeneficialOwner((prevCount) => prevCount + 1);
    }
  };

  return (
    <div
      className="add_client_kyc_basic_details_container"
      style={{ display: !props.visible ? "none" : "" }}
    >
      <div className="add_client_kyc_basic_details_main_container">
        <div className="add_client_kyc_entity_main_container_title">
          <span
            style={{
              margin: "0",
              padding: "0",
              fontWeight: "600",
              fontSize: "18px",
              lineHeight: "21px",
            }}
          >
            Entity Details & Documents
          </span>
          <span className="add_client_entity_subtext">
            Please upload certified copies of the documents.
          </span>
        </div>
        {/* Entity name */}
        <InputBoxV2
          label={"Entity Name"}
          id="entity_name"
          type={clientEntityDetails["entity_name"].type}
          //   disabled={true}
          autoCapitalize={true}
          visibility={
            props.investorType == "sole_proprietorship" ? true : false
          }
          maxLength="255"
          value={addClientEntityDetails["entity_name"]}
          setValue={setAddClientEntityDetails}
          object={addClientEntityDetails}
          required={clientEntityDetails["entity_name"]["required"]}
          errorObject={addClientEntityDetailsError}
          setErrorObject={setAddClientEntityDetailsError}
          isError={addClientEntityDetailsError["entity_name"]}
          errorText={
            !addClientEntityDetails["entity_name"]
              ? "This field cannot be empty."
              : "Invalid Entity Name"
          }
          disabled={
            props.totalDisable ||
            (disableEntityDetails["entity_document_1"] &&
              disableEntityDetails["entity_document_2"])
          }
        />

        {/* Date of Incorporation*/}
        <InputBoxV2
          label={"Date of Incorporation"}
          id="date_of_incorporation"
          type={clientEntityDetails["date_of_incorporation"].type}
          // checkForAge={true}
          max={maxDate}
          min={"1850-01-01"}
          value={addClientEntityDetails["date_of_incorporation"]}
          setValue={setAddClientEntityDetails}
          object={addClientEntityDetails}
          required={clientEntityDetails["date_of_incorporation"]["required"]}
          errorObject={addClientEntityDetailsError}
          setErrorObject={setAddClientEntityDetailsError}
          isError={addClientEntityDetailsError["date_of_incorporation"]}
          errorText={
            !addClientEntityDetails["date_of_incorporation"]
              ? "This field cannot be empty."
              : maxDate ===
              moment(addClientEntityDetails["date_of_incorporation"]).format("YYYY-MM-DD")
              ? "Date of incorporation cannot be the same as today's date. Please enter a different date."
              : "Invalid Date of Incorporation."
          }
          disabled={
            props.totalDisable || disableEntityDetails["date_of_incorporation"]
          }
          maxLength="10"
        />

        {/* dropdown entity document  */}
        <UploadInputV3
          id="entity_document_dropdown_1"
          label={"Entity Document 1"}
          dropDownArray={entityDocument.filter(
            (item) => item !== enityDocument2.document_type
          )}
          visibility={
            props.investorType == "sole_proprietorship" ? true : false
          }
          object={enityDocument1}
          value={enityDocument1}
          setValue={setEnityDocument1}
          imageSizeLimit={15} //required if note is provided
          idForDocumentFront={"entiy_document_1"}
          errorObject={addClientEntityDetailsError}
          setErrorObject={setAddClientEntityDetailsError}
          isError={addClientEntityDetailsError["entity_document_2"]}
          optional={true}
          note={
            "Upload image in JPG/JPEG/PNG/PDF format. Max. file size allowed is 15 MB"
          }
          disabled={
            props.totalDisable || disableEntityDetails["entity_document_1"]
          }
          additionalPath={props.userID}
        />

        <UploadInputV3
          id="entity_document_dropdown_2"
          label={"Entity Document 2"}
          dropDownArray={entityDocument.filter(
            (item) => item !== enityDocument1.document_type
          )}
          visibility={
            props.investorType == "sole_proprietorship" ? true : false
          }
          object={enityDocument2}
          value={enityDocument2}
          setValue={setEnityDocument2}
          imageSizeLimit={15} //required if note is provided
          idForDocumentFront={"entity_document_2"}
          optional={true}
          note={
            "Upload image in JPG/JPEG/PNG/PDF format. Max. file size allowed is 15 MB"
          }
          errorObject={addClientEntityDetailsError}
          setErrorObject={setAddClientEntityDetailsError}
          isError={addClientEntityDetailsError["entity_document_2"]}
          disabled={
            props.totalDisable || disableEntityDetails["entity_document_2"]
          }
          additionalPath={props.userID}
        />

        {entityDocumentsToRender.map((key, index) => {
          return (
            <UploadInputV2
              id={key}
              label={
                typeof clientEntityDetails[key].label === "string"
                  ? clientEntityDetails[key].label
                  : clientEntityDetails[key].label[props.investorType]
              }
              required={clientEntityDetails[key]["required"]}
              object={addClientEntityDetails}
              setValue={setAddClientEntityDetails}
              imageSizeLimit={15} //required if note is provided
              rightLabel={clientEntityDetails[key].template}
              fileLink={clientEntityDetails[key].template_link}
              note={
                "Upload image in JPG/JPEG/PNG/PDF format. Max. file size allowed is 15 MB."
              }
              errorObject={addClientEntityDetailsError}
              setErrorObject={setAddClientEntityDetailsError}
              isError={addClientEntityDetailsError[key]}
              disabled={props.totalDisable || disableEntityDetails[key]}
              additionalPath={props.userID}
            />
          );
        })}

        {/* BENEFICIAL OWNER'S DOCUMENT SECTION */}
        <div
          style={{
            display:
              props.investorType == "private_limited_company" ||
              props.investorType == "trust" ||
              props.investorType == "partnership_firm"
                ? ""
                : "none",
          }}
        >
          <h4 className="beneficial_owner_tile">Beneficial Owner's Kyc</h4>
          <p id="beneficial_owner_subtext">
            Please upload KYC documents of the Beneficial Owners, i.e, person
            with more 15 % stake
          </p>

          {[...Array(beneficialOwner)].map((_, outerIndex) => (
            <>
              {/* //horizontal line */}
              {outerIndex + 1 !== 1 ? (
                <hr
                  style={{
                    display: props.totalDisable ? "none" : "block",
                    height: "1px",
                    border: 0,
                    borderTop: "1px solid #ccc",
                    margin: "1em 0",
                    padding: "0",
                  }}
                />
              ) : null}

              <p id="beneficial_owner_subtitle">
                Beneficial Owner's {outerIndex + 1}
              </p>

              {beneficialDocumentsToRender.map((key, index) => {
                key = key.replace("1", outerIndex + 1);
                return (
                  <UploadInputV2
                    id={key}
                    label={clientEntityDetails[key].label}
                    required={true}
                    object={addClientEntityDetails}
                    setValue={setAddClientEntityDetails}
                    imageSizeLimit={15} //required if note is provided
                    note={clientEntityDetails[key].note}
                    errorObject={addClientEntityDetailsError}
                    setErrorObject={setAddClientEntityDetailsError}
                    isError={addClientEntityDetailsError[key]}
                    disabled={props.totalDisable || disableEntityDetails[key]}
                    additionalPath={props.userID}
                    requiredFileTypes={
                      clientEntityDetails[key]?.requiredFileTypes
                    }
                    acceptedFormat={clientEntityDetails[key]?.acceptedFormat}
                  />
                );
              })}
            </>
          ))}

          <button
            style={{ display: props.totalDisable ? "none" : "block" }}
            className="add_benefifcial_button"
            onClick={addBenefcialComponent}
          >
            Add more
          </button>
        </div>

        <h4 id="add_client_proprietor_detail_subtitle">Registered Address</h4>

        {currentDocumentsToRender.map((key, index) => {
          if (key.includes("state")) {
            return (
              <StateDropdown
                label={clientEntityDetails[key].label}
                id={key}
                type={clientEntityDetails[key].type}
                disabled={props.totalDisable || disableEntityDetails[key]}
                dataArray={config.state_array}
                maxLength="255"
                value={addClientEntityDetails[key]}
                setValue={setAddClientEntityDetails}
                object={addClientEntityDetails}
                specialCharCheck={
                  clientEntityDetails[key].hasOwnProperty("specialCharCheck")
                    ? clientEntityDetails[key].specialCharCheck
                    : false
                }
                required={clientEntityDetails[key]["required"]}
                errorObject={addClientEntityDetailsError}
                setErrorObject={setAddClientEntityDetailsError}
                isError={addClientEntityDetailsError[key]} //props.errorState.hasOwnProperty("address")?props.errorState.address:
                errorText={
                  addClientEntityDetails[key]
                    ? "Invalid State"
                    : "This field cannot be empty."
                }
              />
            );
          } else {
            return (
              <InputBoxV2
                key={key}
                label={clientEntityDetails[key].label}
                id={key}
                type={clientEntityDetails[key].type}
                disabled={props.totalDisable || disableEntityDetails[key]}
                maxLength={key.includes("pin") ? "6" : "255"}
                value={addClientEntityDetails[key]}
                specialCharCheck={
                  clientEntityDetails[key].hasOwnProperty("specialCharCheck")
                    ? clientEntityDetails[key].specialCharCheck
                    : false
                }
                setValue={setAddClientEntityDetails}
                object={addClientEntityDetails}
                required={clientEntityDetails[key]["required"]}
                errorObject={addClientEntityDetailsError}
                setErrorObject={setAddClientEntityDetailsError}
                isError={addClientEntityDetailsError[key]}
                errorText={
                  addClientEntityDetails[key]
                    ? `Invalid ${clientEntityDetails[key].label}`
                    : "This field cannot be empty."
                }
              />
            );
          }
        })}

        {/* entity_address_proof */}
        <UploadInputV2
          id="entity_address_proof"
          label={"Registered Address Proof"}
          required={clientEntityDetails["entity_address_proof"]["required"]}
          object={addClientEntityDetails}
          setValue={setAddClientEntityDetails}
          imageSizeLimit={15} //required if note is provided
          note={
            "Upload image in JPG/JPEG/PNG/PDF format. Max. file size allowed is 15 MB.'"
          }
          errorObject={addClientEntityDetailsError}
          setErrorObject={setAddClientEntityDetailsError}
          isError={addClientEntityDetailsError["entity_address_proof"]}
          disabled={
            props.totalDisable || disableEntityDetails["entity_address_proof"]
          }
          additionalPath={props.userID}
        />
      </div>
    </div>
  );
};

export default AddClientEntityDetails;
