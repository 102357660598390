import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import firebaseConfig from "../constant/firebaseConfig";
import { getFrontendStateObject } from "./helper";

const {stage} = getFrontendStateObject();

export function appInitialize () {
    var app = initializeApp(firebaseConfig[stage]['googleAnalytics']);
    return app;
}

export function eventLog (info){
    // console.log(stage, "analytics ::", info);
    // if(stage != "me"){
        var app = initializeApp(firebaseConfig[stage]['googleAnalytics']);
        const analytics = getAnalytics(app);
        logEvent(analytics, info.type, info.action );
    // }
  
}