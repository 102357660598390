import * as React from "react";
import "./marketing-modal.css";
import Modal from "@mui/material/Modal";
import { download3 } from "../../assets/images/index";

export default function MarketingModel(props) {
  var materials = [];
  if (Array.isArray(props.marketingData)) {
    materials = props.marketingData;
  } else {
    materials = [props.marketingData];
  }
  return (
    <div className="marketitem">
      {materials.map((i, index) => (
        <div className="marketcontainer">
          <div>
            {console.log("materials", i)}
            <object
              className="object"
              data={`data:${i.ContentType};base64,${i.base64}`}
              type={`${i.ContentType}`}
              width="100%"
            />
            {/* <div> */}
            <span className="marketleft">{i.uploaded_file_name ? i.uploaded_file_name : `Doc ${index + 1}`}</span>
            <img
              className="fa-download"
              onClick={() => props.downloadBase64File(i.base64, i.ContentType)}
              src={download3}
            />
            {/* </div> */}
          </div>
        </div>
      ))}
    </div>
    // </Modal>
  );
}
