import React, { useState } from "react";
import './withdrawal-details-model.css'
import Modal from "@mui/material/Modal";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import Close from "@mui/icons-material/Close";
import moment from "moment";
// import withdrawModalTick from '../../assets/images/index'
import {withdrawModalInfo, withdrawModalTick} from '../../assets/images/index'

export default function WithdrawalDetailsModal(props) {
  const [activeState , setActiveState] = useState(props.selectedData.status ) 
  const sent_time= moment(props.selectedData.sent_at).format('YYYY/MM/DD | hh:mm')
  const approved_time= moment(props.selectedData.approved_at).format('YYYY/MM/DD | hh:mm')
  const processed_time=moment(props.selectedData.processed_at).format('YYYY/MM/DD | hh:mm')

  const renderIcon = (currStep) => {
    return (
      <>
      {
        activeState >= currStep ? 
          <img className="fa-download" src={withdrawModalTick}/> 
          : 
          <img className="fa-download" src={withdrawModalInfo}/>
      }
      </>
    )
  }
    return (
      <Modal
        open={props.openModel}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
            backgroundColor: "rgba(102, 102, 102, 0.72)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
      }}
      >  
        <div className="withdrawal-details_model_div">
            <div className="withdrawal-details_model_header">
                <h1 className="withdrawal-details_model_header_title">
                  Withdrawal Details 
                  <Close style={{ float: "right",cursor:'pointer' }} onClick={props.handleClose}/>
                </h1>
            </div>
            <div className="withdrawal-details_model_body">
                <div>
                  <div className="withdrwal-details-model-body-text">
                    Withdrawal Amount: 
                    <CurrencyRupeeIcon style={{fontSize:"large"}} /> {props.selectedData.amount}
                  </div>
                  {activeState==9 && <div className="withdrwal-details-model-body-subtext">
                  UTR Number :  {props.selectedData.utr}
                  </div>}
                </div>
            </div>
            <div className="withdrwal-details-model-slider">
              {renderIcon(2)}
              <div className="withdrwal-details-model-line" style={{border: activeState >= 2 ? "1px solid #3A86FF" : "auto"}}>
              </div>
              {renderIcon(3)}
              <div className="withdrwal-details-model-line"  style={{border: activeState >= 3? "1px solid #3A86FF" : "auto"}}>
              </div>
              {renderIcon(9)}
            </div>  
              <div className="withdrwal-details-model-steps">
                  <div style={{width: "225px", textWrap: "wrap"}}>Request sent to client</div>
                  <div style={{width: "225px", textWrap: "wrap" , color:activeState >= 3 ? "#2E2E2E" : "#757575"}}>Request approved by client & sent to bank</div>
                  <div style={{width: "225px", textWrap: "wrap" , color:activeState == 9 ? "#2E2E2E" : "#757575"}}>Withdrawal processed</div>
                
              </div>

              <div className="withdrwal-details-model-date">
                  <div className="withdrawal-details-sent-time">{sent_time}</div>                
                {
                  activeState > 2 ?
                  <div className="withdrawal-details-approved-time">{approved_time}</div>
                  : ""
                }               
                {
                  activeState == 9 ?
                  <div className="withdrawal-details-processed-time">{processed_time}</div>
                  : ""
                }
                
              </div>
        </div>
      </Modal>
    );
  }