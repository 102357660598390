import {
  Modal,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress
} from "@mui/material";
import { useEffect, useState } from "react";
import { Client_API } from "../../services/api";
import { errorToast } from "../../utils/helper";
import { useLocation } from "react-router-dom";

const BorrowerMapping = (props) => {
  const [showPdf, setShowPdf] = useState(false);
  const [loading, setLoading] = useState(true); 
  const [mappingPDFs, setMappingPDFs] = useState([]);
  const [selectedPDF, setSelectedPDF] = useState({});
  const param = useLocation();
  const api = new Client_API();
  const tableContainer = {
    marginTop: "20px",
    width: "92%",
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
    borderTop: "1px solid #c7c7c7",
    borderLeft: "1px solid #c7c7c7",
    borderRight: "1px solid #c7c7c7",
    alignSelf: "center",
    overflow: "hidden",
  };

  const tableHeader = {
    backgroundColor: "#f6f6f6",
    // fontWeight: "600",
    fontSize: 16,
    fontFamily: "Asap",
    color: "#1f1f1f",
    paddingTop: "15px",
    paddingLeft: "10px",
    paddingRight: "0px",
    paddingBottom: "15px",
    width: 200,
  };

  const tableRow = {
    fontSize: 14,
    fontFamily: "Asap",
    fontWeight: "400",
    color: "#1c1c1c",
    paddingTop: "10px",
    paddingLeft: "10px",
    paddingRight: "0px",
    paddingBottom: "10px",
  };

  const getBorrowerMappingPDF = async () => {
    const payload = {
      user_id: param.state.uuid,
    };
    setLoading(true); // Start loading
    try {
      const res = await api.getBorrowerMappingPDFs(payload);
      if (res.kind === "ok") {
        setMappingPDFs(res.data);
      } else {
        errorToast(res.error);
      }
    } catch (error) {
      errorToast("An error occurred while fetching data."); 
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getBorrowerMappingPDF();
  }, []);

  return (
    <div className="clientkyc_main_div" style={{ marginTop: "10px" }}>
      <Modal
        open={showPdf}
        onClose={() => {
          setSelectedPDF({});
          setShowPdf(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        scrool="paper"
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="virtual-model-div-8">
          <h2 className="virtual-model-header-text">Borrower Mapping</h2>
          <object
            data={selectedPDF?.url}
            height={"450px"}
            type={selectedPDF?.contentType}
          />
        </div>
      </Modal>
      <TableContainer style={tableContainer}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="left" padding="none" style={tableHeader}>
                File Name
              </TableCell>
              <TableCell align="left" padding="none" style={tableHeader}>
                Date
              </TableCell>
            </TableRow>
          </TableHead>
          {loading ? (
        <TableRow>
          <TableCell align="center" colSpan={2}>
            <CircularProgress />
          </TableCell>
        </TableRow>
      ) : mappingPDFs.length ? (
        mappingPDFs.map((item, index) => (
          <TableRow
            key={item.uuid}
            style={{
              backgroundColor: index % 2 === 0 ? "#fafafa" : "#fff",
              cursor: "pointer",
            }}
          >
            <TableCell
              style={{
                ...tableRow,
                position: "sticky",
                left: 0,
                background: index % 2 === 0 ? "#fafafa" : "#fff",
                zIndex: 100,
              }}
              align="left"
              onClick={() => {
                setShowPdf(true);
                setSelectedPDF({
                  url: item?.signedUrl,
                  content_type: item?.contentType,
                });
              }}
            >
              Borrower Mapping
            </TableCell>
            <TableCell
              style={{
                ...tableRow,
                position: "sticky",
                left: 0,
                background: index % 2 === 0 ? "#fafafa" : "#fff",
                zIndex: 100,
              }}
              align="left"
            >
              {item?.date}
            </TableCell>
          </TableRow>
        ))
      ) : (
        <TableRow>
          <TableCell align="center" colSpan={2} style={tableRow}>
            No data found!
          </TableCell>
          </TableRow>
      )}
        </Table>
      </TableContainer>
    </div>
  );
};

export default BorrowerMapping;
