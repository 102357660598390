import * as React from "react";

const InputInfoText = function (props) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
      }}
    >
      <p
        style={{
          margin: 0,
          padding: 0,
          marginBottom: "12px",
          marginTop: "-2px",
          fontSize: "11px",
          // color: "#FF8800",
          color: "#696969"
        }}
      >
        {props.text}
      </p>
    </div>
  );
};

export default InputInfoText;
