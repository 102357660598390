import { API } from "aws-amplify";
import * as Types from "../api.types";

export class IFA_Payment_API {
  /**
   * @description this function is for get the data of ifa payment
   * @param data
   * @returns this return the payment data
   */
  async getPaymentData(uuid: any): Promise<Types.GetIFAPaymentResult> {
    //call the api try and catch
    try {
      const response: any = await API.get(
        "baseurl",
        `/v2/ifa-payment/${uuid}`,
        {}
      );
      return { kind: "ok", data: response.data };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description this function is for mark the agreement flag
   * @param data
   * @returns return the sucess and failure message
   */
  async updateAgreementFlag(data: any): Promise<Types.GetIFAPaymentResult> {
    // payload
    const params = {
      body: data,
    };

    //call the api try and catch
    try {
      const response: any = await API.post("baseurl", "/v2/agreements", params);
      return { kind: "ok", data: response.data };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description this function is for get the data of ifa payment
   * @param data
   * @returns this return the payment data
   */
  async getInvestment(uuid: any): Promise<Types.GetIFAPaymentResult> {
    //call the api try and catch
    try {
      const response: any = await API.post(
        "baseurl",
        `/v2/ifa/links/${uuid}/invest`,
        {}
      );
      return { kind: "ok", data: response.data };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description this function is generate order id and get session payment id
   * @param data
   * @returns this return the payment data
   */
  async getSessionPaymentId(data: any): Promise<Types.GetSessionId> {
    //call the api try and catch

    const params = {
      body: data,
    };

    try {
      const response: any = await API.post("baseurl", `/orders`, params);
      console.log("response data of order api", response);
      return { kind: "ok", data: response.data };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description this function is to callback on payment status
   * @param data
   * @returns this return the payment data
   */
  async paymentCallback(data: any): Promise<Types.GetSessionId> {
    //call the api try and catch

    const params = {
      body: data,
    };

    try {
      const response: any = await API.post(
        "baseurl",
        `/payments/callback`,
        params
      );
      console.log("response data of order api", response);
      return { kind: "ok", data: response.data };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description this function is to cancel  payment
   * @param data
   * @returns this return the payment data
   */
  async cancelPayment(data: any): Promise<Types.GetSessionId> {
    //call the api try and catch
    const params = {
      body: data,
    };

    try {
      const response: any = await API.post(
        "baseurl",
        `/payments/cancel`,
        params
      );
      console.log("response data of order api", response);
      return { kind: "ok", data: response.data };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@arthmatedirect.com",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description this function is check payment status
   * @returns this return the payment data
   */
  async checkPaymentStatus(orderId: any): Promise<Types.CheckPaymentStatus> {
    //call the api try and catch

    try {
      const response: any = await API.get(
        "baseurl",
        `/payments/${orderId}`,
        {}
      );
      console.log(response);
      return { kind: "ok", data: response.message };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@arthmatedirect.com",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description this function is check phonepe payment status
   * @returns this return the payment data
   */
  async checkPhonepePaymentStatus(
    data: any
  ): Promise<Types.CheckPaymentStatus> {
    //call the api try and catch

    const params = {
      body: data,
    };

    try {
      const response: any = await API.post(
        "baseurl",
        `/phonepe-payments/status`,
        params
      );
      console.log(response);
      return { kind: "ok", data: response.data };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@arthmatedirect.com",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }
}
