import React from "react";
import Loader from "react-loader-spinner";

const ContentLoadingLoader = (props) => {
  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        justifyContent: "center",
        marginTop: props.marginTop ? props.marginTop : "100px",
        marginLeft: props.marginLeft ? props.marginLeft : "220px",
      }}
    >
      <Loader
        type="TailSpin"
        color="#414141"
        height={80}
        width={80}
        style={{
          marginTop: "150px",
        }}
      />
    </div>
  );
};

export default ContentLoadingLoader;
