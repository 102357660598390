import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import "./clientrepayment.css";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";
import { Client_API } from "../../services/api/Clients/clients-api";
import { convertAmount, getFormattedDate } from "../../utils/helper";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import { ContentLoadingLoader } from "../../components";

const tableContainer = {
  marginTop: "20px",
  width: "92%",
  borderTopLeftRadius: "5px",
  borderTopRightRadius: "5px",
  borderTop: "1px solid #c7c7c7",
  borderLeft: "1px solid #c7c7c7",
  borderRight: "1px solid #c7c7c7",
  alignSelf: "center",
  overflow: "scroll",
};

const tableHeader = {
  backgroundColor: "#ececec",
  fontWeight: "600",
  fontSize: 16,
  fontFamily: "Asap",
  color: "#1f1f1f",
  borderRight: "1px solid #c7c7c7",
  paddingTop: "15px",
  paddingLeft: "10px",
  paddingRight: "0px",
  paddingBottom: "15px",
};

const tableRow = {
  fontSize: 14,
  fontFamily: "Asap",
  fontWeight: "400",
  color: "#1c1c1c",
  borderRight: "1px solid #c7c7c7",
  paddingTop: "10px",
  paddingLeft: "10px",
  paddingRight: "0px",
  paddingBottom: "10px",
};

const ClientRepayment = (props) => {
  const navigate = useHistory();
  const api = new Client_API();
  const [isLogin, setIsLogin] = useState();
  const param = useLocation();
  const [loginRender, setLoginRender] = useState(false);
  const [render, setRender] = useState(false);
  const [page, setPage] = useState(0);
  const rowsPerPage = 25;
  const [repaymentData, setRepaymentData] = useState([]);

  // check if user login or not
  useEffect(() => {
    props.showCommonHeader(true);
    props.showCommonSidebar(true);
    let checkLogin = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        let status = localStorage.getItem("kyc_status");
        if (status != 2) {
          setIsLogin(false);
        }
        else
        {
        setIsLogin(true);
        setLoginRender(true);
        fetchRepayment();
        }
      } catch {
        setIsLogin(false);
      }
    };
    return checkLogin();
    // eslint-disable-next-line
  }, []);

  // fetch repayment data
  const fetchRepayment = () => {
    const data = {
      client_id: param.state.uuid,
      investment_id: param.state.investment_uuid,
    };
    api.getClientRepayment(data).then((res) => {
      if (res.kind === "ok") {
        setRepaymentData(res.data);
        setRender(true);
      } else {
        alert(res.error);
        setRender(true);
      }
    });
  };

  // handle page change function
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // if user is not login then redirect to login page
  if (isLogin === false) {
    return <Redirect to="/" />;
  }

  // if the parameter is undefined
  if (param.state === undefined) {
    return <Redirect to="/clients" />;
  }

  return (
    <div>
      {loginRender === false ? (
        <div />
      ) : (
        <div className="clientrepayment_div_container">
          <div>
            {render === false ? (
              <ContentLoadingLoader />
            ) : (
              <div className="clientrepayment_div_tablecontainer">
                <div className="clientrepayment_header_div">
                  {/* heading */}
                  <div className="clientrepayment_header_div_title_container">
                    <div className="clientrepayment_header_button_div_container">
                      <h1
                        className="clientrepayment_header_div_subtitle"
                        onClick={() => navigate.push("/clients")}
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        Clients
                      </h1>
                      <ArrowForwardIosIcon
                        style={{
                          color: "#969494",
                          fontSize: "15px",
                          paddingLeft: "5px",
                          paddingRight: "5px",
                        }}
                      />
                      <h1
                        className="clientrepayment_header_div_subtitle"
                        onClick={() => {
                          navigate.push("/clients/investments", {
                            name: param.state.name,
                            uuid: param.state.uuid,
                          });
                        }}
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        {param.state.name}
                      </h1>
                      <ArrowForwardIosIcon
                        style={{
                          color: "#969494",
                          fontSize: "15px",
                          paddingLeft: "5px",
                          paddingRight: "5px",
                        }}
                      />
                      <h1 className="clientrepayment_header_div_subtitle">
                        {param.state.pool_name}
                      </h1>
                    </div>

                    <h1 className="clientrepayment_header_div_title">
                      Client Repayments
                    </h1>
                  </div>
                </div>

                {/* table */}
                <TableContainer style={tableContainer}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left" style={tableHeader}>
                          Date
                        </TableCell>
                        <TableCell align="left" style={tableHeader}>
                          Principal Due
                        </TableCell>
                        <TableCell align="left" style={tableHeader}>
                          Interest Due
                        </TableCell>
                        <TableCell align="left" style={tableHeader}>
                          Status
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            ...tableHeader,
                            borderRight: "none",
                          }}
                        >
                          Repaid Amount
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {repaymentData
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => (
                          <TableRow
                            key={row.id}
                            style={{
                              backgroundColor:
                                index % 2 === 0 ? "#fafafa" : "#fff",
                            }}
                          >
                            <TableCell style={tableRow} align="left">
                              {getFormattedDate(row.date, 1)}
                            </TableCell>
                            <TableCell style={tableRow} align="left">
                              {convertAmount(row.principal_due)}
                            </TableCell>
                            <TableCell style={tableRow} align="left">
                              {convertAmount(row.interest_due)}
                            </TableCell>
                            <TableCell style={tableRow} align="left">
                              <button
                                disabled
                                className={
                                  row.status === "Received"
                                    ? "clientrepayment_status_button_r"
                                    : row.status === "Upcoming"
                                    ? "clientrepayment_status_button_u"
                                    : row.status === "Short"
                                    ? "clientrepayment_status_button_s"
                                    : "clientrepayment_status_button_d"
                                }
                              >
                                {row.status}
                              </button>
                            </TableCell>
                            <TableCell
                              style={{
                                ...tableRow,
                                borderRight: "none",
                              }}
                              align="left"
                            >
                              {convertAmount(row.repaid_amount)}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                  {/* if no data available */}
                  {repaymentData.length === 0 ? (
                    <div className="no_row_found_div">
                      <FindInPageIcon
                        style={{
                          fontSize: "100px",
                          color: "#555555",
                        }}
                      />
                      <p className="no_row_found">No row found</p>
                    </div>
                  ) : (
                    <div />
                  )}
                </TableContainer>
                {/* pagination */}
                <div className="pagination_div">
                  <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    count={repaymentData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ClientRepayment;
