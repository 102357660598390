import React, { useEffect, useState } from "react";
import "./walletRequestConfirmation.css";
import { useParams, useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import { IFA_Payment_API, User_API, WalletApi } from "../../services/api";
import InfoIcon from "@mui/icons-material/Info";
import {
  convertAmount,
  errorToast,
  successToast,
  infoToast,
} from "../../utils/helper";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Tooltip from "../../assets/images/info.svg";
import {
  Cashfree,
  Paytm,
  FullScreenLoader,
  InfoAlert,
  SampleToast,
  useWindowDimensions,
  Phonepe
} from "../../components";
import { eventLog } from "../../utils/firebase";
import { LoaderScreen } from "../../components/index";

const WalletRequestConfirmation = (props) => {
  const { uuid } = useParams();
  const navigate = useHistory();
  const dimension = useWindowDimensions();
  const api = new WalletApi();
  const userApi = new User_API();
  const ifaPaymentApi = new IFA_Payment_API();
  const [linkData, setLinkData] = useState();
  const [render, setRender] = useState(false);
  const [err, setErr] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [fullLoader, setFullLoader] = useState(false);
  const [otpView, setOtpView] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [infoMsg, setInfoMsg] = useState("");
  const [verified, setVerified] = useState(false);
  const [user, setUser] = useState(null);
  const [otp, setOtp] = useState("");
  const [vpa, setvpa] = useState("");
  const [Confirm, setConfirm] = useState(false);
  const [enterVpa, setenterVpa] = useState(false);
  const [showPaymentGateway, setShowPaymentGateway] = useState(false);
  const [otpInvalid, setOtpInvalid] = useState(false);
  const [otpInvalidText, setOtpInvalidText] = useState("");
  const [virtualAccount, setVirtualAccount] = useState("");
  const [value, setValue] = useState("");
  const [option, setOption] = useState("payOnline");
  // get the payment data

  useEffect(() => {
    if (!props.fromInvest) {
      props.showCommonHeader(false);
      props.showCommonSidebar(false);
      let getData = async () => {
        await api.getWalletRequestData(uuid).then((res) => {
          if (res.kind === "ok") {
            eventLog({
              type: "wallet_request_screen",
              action: {
                trigger: "true",
              },
            });
            if (res.data.status == "Expired") {
              setRender(true);
              setErr(true);
              setErrMsg(
                "The request to add funds to your wallet has expired. Please reach out to your Advisor to initiate a request again."
              );
            } else if (res.data.status == "Completed") {
              setRender(true);
              setErr(true);
              setErrMsg(
                "The wallet loading transaction has been successfully completed."
              );
            } else if (res.data.request_revised == true) {
              let tempObj = res.data;
              tempObj.advised_amount = res.data.revised_amount;
              setLinkData(tempObj);
              setVirtualAccount(res.data);
              setRender(true);
              setErr(true);
              setErrMsg(
                `The amount to be loaded in your wallet has been revised by your advisor. The revised amount is ₹ ${res.data.revised_amount}. We are redirecting you to the new request.`
              );
              setTimeout(() => {
                setErr(false);
                navigate.push(
                  `/wallet-requests/${res.data.latest_request_uuid}`
                );
              }, 5000);
            } else if (res.data.request_revised == false) {
              setErr(false);
              navigate.push(`/wallet-requests/${res.data.latest_request_uuid}`);
            }
            setLinkData(res.data);
            setVirtualAccount(res.data);
            setRender(true);
            localStorage.setItem("fromInvest", false);
          } else {
            setRender(true);
            setErr(true);
            setErrMsg("The page you are looking for is not available");
          }
        });
      };
      return getData();
    } else {
      if (
        props.walletLoadingData &&
        props.walletLoadingData.available_amount <
          props.walletLoadingData.advised_amount
      ) {
        InfoAlert(
          "Your wallet Balance is lower than the requested investment amount. Please add the funds first to the wallet"
        );
      }
      setLinkData(props.walletLoadingData);
      setRender(true);
    }
    // eslint-disable-next-line
  }, []);

  // console.log(linkData);

  // amount key press handler
  const onKeyPress = (e) => {
    const re = /[^0-9]/g;
    if (re.test(e.key)) {
      setConfirm(true);
      e.preventDefault();
    }
  };

  // vpa key press handler
  const vpaKeyPress = (e) => {
    console.log("vpa key press called", err);
    const re = /^[a-zA-Z0-9.\-_]{2,49}@[a-zA-Z._]{2,49}$/;
    if (re.test(e.key)) {
      e.preventDefault();
    }
  };

  const checkVpa = () => {
    console.log("enter check vpa");
    const re = /^[a-zA-Z0-9.\-_]{2,49}@[a-zA-Z._]{2,49}$/;
    if (!re.test(vpa) || vpa.length == 0) {
      errorToast("Please enter a valid VPA");
    } else {
      setShowPaymentGateway(true);
    }
  };

  // on send otp
  const onSendOtp = async () => {
    setFullLoader(true);
    eventLog({
      type: "wallet_request_otp_sent",
      action: {
        trigger: "true",
      },
    });
    const payload = {
      mobile: linkData.client_mobile,
      isIfa: false,
    };
    await userApi
      .sendMobileVerification(payload)
      .then((response) => {
        console.log("response", response);
        if (response.kind == "ok") {
          let userName = response.data.pan_number;
          if (response.data.oldExistingUser)
            userName = `+91${linkData.client_mobile}`;
          if (response.data.isExistingUser) {
            Auth.signIn(userName)
              .then((response) => {
                setUser(response);
                setFullLoader(false);
                setOtpView(true);
                successToast("OTP has been sent successfully.");
              })
              .catch((err) => {
                setFullLoader(false);
                console.log(err);
                errorToast(
                  "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
                );
                setTimeout(() => {
                  window.location.reload();
                }, 5000);
              });
          } else {
            setFullLoader(false);
            errorToast("User doesn't exist");
          }
        } else {
          setFullLoader(false);
          errorToast(
            "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
          );
        }
      })
      .catch((error) => {
        console.log("Error while sending OTP ::: ", error);
        setFullLoader(false);
        errorToast(
          "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
        );
      });
  };

  // get authentication
  const onGetAuth = async () => {
    if (otp.length === 0) {
      setOtpInvalid(true);
      setOtpInvalidText("OTP cannot be empty");
    } else if (otp.length !== 4) {
      setOtpInvalid(true);
      setOtpInvalidText("Invalid OTP");
    } else if (isNaN(parseInt(otp))) {
      setOtpInvalid(true);
      setOtpInvalidText("OTP must be a number");
    } else {
      setFullLoader(true);
      await Auth.sendCustomChallengeAnswer(user, otp)
        .then(async (res) => {
          if (res && !res.Session) {
            // console.log("auth");
            await Auth.currentAuthenticatedUser();
            const user = await Auth.currentCredentials();

            eventLog({
              type: "wallet_request_otp_verified",
              action: {
                trigger: "true",
              },
            });

            console.log("user", user.identityId);
            eventLog({
              type: "wallet_request_accepted",
              action: {
                trigger: "true",
              },
            });
            setTimeout(() => {
              setFullLoader(false);
              setOtpView(false);
              setVerified(true);
            }, 2000);
          } else {
            setFullLoader(false);
            setOtpInvalid(true);
            setOtpInvalidText(
              "Wrong OTP. Please check and enter the correct OTP."
            );
          }
        })
        .catch((err) => {
          setFullLoader(false);
          eventLog({
            type: "wallet_request_accepted",
            action: {
              trigger: "false",
            },
          });
          console.log(err);
          errorToast(
            "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
          );
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        });
    }
  };

  // on confirm or cancel
  const onRequestSubmit = async (mode, status) => {
    setFullLoader(true);
    // payload
    let payload = {};

    if (mode == "auto_collect") {
      payload = {
        status: status,
        mode: mode,
      };
    } else if (props.fromInvest) {
      payload = {
        status: status,
        mode: "fromInvest",
        amount: linkData.advised_amount,
      };
    } else {
      payload = {
        status: status,
      };
    }

    console.log("update wallet request calling");
    // call the api
    await api.updateWalletRequestStatus(payload, uuid).then((res) => {
      if (res.kind === "ok") {
        console.log("wallet request called resp::", res);
        setInfoMsg(res.data);
        setTimeout(() => {
          setFullLoader(false);
          setShowInfo(true);
        }, 3000);
      } else {
        setFullLoader(false);
        setInfoMsg(res.error);
      }
    });
  };

  // sign out user
  const onSignOut = async () => {
    await Auth.signOut()
      .then(() => {
        if (props.fromInvest) {
          props.onDone();
        } else {
          navigate.push("/");
        }
      })
      .catch((err) => {
        console.log(err);
        navigate.push("/");
      });
  };

  const handleClick = (e, value) => {
    if (value == "pay_online") {
      setOption("payOnline");
    } else if (value == "paytm") {
      setOption("paytm");
    } else if (value == "vpa") {
      setOption(value);
    }
  };

  const handleSelect = (event) => {
    if (event.target.name == "payOnline") {
      setOption(event.target.name);
    } else if (event.target.name == "vpa") {
      setOption(event.target.name);
    } else if (event.target.name == "paytm") {
      setOption(event.target.name);
    }
  };

  const onClickCode = async (value) => {
    await navigator.clipboard.writeText(value);
    successToast("Text copied to clipboard", false);
  };

  // payment details view
  const withdrawalDetailsView = () => {
    return (
      <div
        className="wallet_request_confirmation_div"
        style={{ paddingBottom: "0px" }}
      >
        {/* header */}
        <header className="wallet_request_confirmation_header">
          <h1 className="wallet_request_confirmation_header_title">
            Complete wallet loading request
          </h1>
        </header>

        {/* investor name */}
        <div className="wallet_request_confirmation_div_container">
          <div className="wallet_request_confirmation_data_div1">
            <div
              className="wallet_request_confirmation_data"
              style={{ width: dimension.width > 500 ? "65%" : "100%" }}
            >
              <h1 className="wallet_request_confirmation_data_title">Client</h1>
              <h1 className="wallet_request_confirmation_data_subtitle">
                {linkData.client_name}
              </h1>
            </div>
            {props.fromInvest && !verified ? (
              <div className="wallet_request_confirmation_data">
                <h1 className="wallet_request_confirmation_data_title">
                  Investment Amount
                </h1>
                <h1 className="wallet_request_confirmation_data_subtitle">
                  ₹ {convertAmount(linkData.investment_amount)}
                </h1>
              </div>
            ) : (
              <div />
            )}
          </div>
        </div>

        {/* amount */}
        <div
          className="wallet_request_confirmation_div_container"
          style={{ display: !verified ? "flex" : "none" }}
        >
          <div className="wallet_request_confirmation_data_div1">
            <div
              className="wallet_request_confirmation_data"
              style={{ width: dimension.width > 500 ? "65%" : "100%" }}
            >
              <h1 className="wallet_request_confirmation_data_title">
                {props.fromInvest ? "Amount to be Added" : "Amount"}
              </h1>
              <h1 className="wallet_request_confirmation_data_subtitle">
                ₹ {convertAmount(linkData.advised_amount)}
              </h1>
            </div>
            {props.fromInvest ? (
              <div className="wallet_request_confirmation_data">
                <h1 className="wallet_request_confirmation_data_title">
                  Wallet Funds
                </h1>
                <h1 className="wallet_request_confirmation_data_subtitle">
                  ₹ {convertAmount(linkData.available_amount)}
                </h1>
              </div>
            ) : (
              <div />
            )}
          </div>
        </div>

        {/* ifa name */}
        <div
          className="wallet_request_confirmation_div_container"
          style={{ display: verified ? "flex" : "none" }}
        >
          <div className="wallet_request_confirmation_data_div3">
            {/* ifa name */}
            <div className="wallet_request_confirmation_data">
              <h1 className="wallet_request_confirmation_data_title">
                Advisor
              </h1>
              <h1 className="wallet_request_confirmation_data_subtitle">
                {linkData.ifa_name}
              </h1>
            </div>

            {/* amount */}
            <div
              className="wallet_request_confirmation_data"
              style={{ marginLeft: dimension.width > 500 ? "25%" : 0 }}
            >
              <h1 className="wallet_request_confirmation_data_title">Amount</h1>
              <h1 className="wallet_request_confirmation_data_subtitle">
                ₹ {convertAmount(linkData.advised_amount)}
              </h1>
            </div>
          </div>
        </div>

        <div
          style={{
            display: !verified ? "none" : "block",
          }}
        >
          {!showPaymentGateway ? (
            <React.Fragment>
              <div
                style={{
                  backgroundColor: "rgb(250 250 250)",
                  margin: "10px",
                  borderRadius: "15px",
                  border: "0.5px solid rgb(233, 232, 232)",
                  cursor: "pointer",
                }}
                onClick={(event) => handleClick(event, "paytm_online")}
              >
                {/* <ul id="pay_online" className="vpaUnorderList">
                  <input
                    type="radio"
                    name="payOnline"
                    className="radioClass"
                    onChange={handleSelect}
                    checked={option == "payOnline" ? true : false}
                  ></input>
                  Pay Online
                </ul> */}
                <ul id="pay_online" className="vpaUnorderList">
                  <input
                    type="radio"
                    name="paytm"
                    className="radioClass"
                    onChange={handleSelect}
                    checked={option == "paytm" ? true : false}
                    onClick={(e) => setenterVpa(!enterVpa)}
                  ></input>
                  Payment Gateway
                </ul>
                {/* <ul id="paytm_online" className="vpaUnorderList">
                  <input
                    type= {enterVpa === true ? "email" : "hidden"}
                    maxLength={50}
                    placeholder="Enter VPA"
                    className="vpa_request_confirmation_input"
                    onKeyPress={vpaKeyPress}
                    onChange={(event) => {
                      setvpa(event.target.value);
                    }}
                  />
                  </ul> */}
              </div>
              {(virtualAccount?.vendor_escrow_ac_no === null ||
                virtualAccount?.vendor_escrow_ac_no === "") &&
              (virtualAccount?.vendor_account_holder_name === null ||
                virtualAccount?.vendor_account_holder_name === "") &&
              (virtualAccount?.vendor_account_type === null ||
                virtualAccount?.vendor_account_type === "") &&
              (virtualAccount?.vendor_bank === null ||
                virtualAccount?.vendor_bank === "") &&
              (virtualAccount?.vendor_ifsc_code === null ||
                virtualAccount?.vendor_ifsc_code === "") &&
              (virtualAccount?.vendor_branch === null ||
                virtualAccount?.vendor_branch === "") ? (
                ""
              ) : (
                <div
                  style={{
                    backgroundColor: "rgb(250 250 250)",
                    margin: "10px",
                    borderRadius: "15px",
                    border: "0.5px solid rgb(233, 232, 232)",
                    cursor: "pointer",
                    display: props.fromInvest ? "none" : "",
                  }}
                  onClick={(event) => handleClick(event, "vpa")}
                >
                  <ul
                    id="pay_online"
                    className="vpaUnorderList"
                    onClick={(event) => handleClick(event)}
                  >
                    <input
                      type="radio"
                      name="vpa"
                      className="radioClass"
                      onChange={handleSelect}
                      checked={option == "vpa" ? true : false}
                    ></input>
                    Pay using your virtual account
                  </ul>
                  <div
                    className="virtual_account_container"
                    style={{
                      display: option == "vpa" ? "" : "none",
                    }}
                  >
                    <div className="virtual_account">
                      {virtualAccount?.vendor_escrow_ac_no === null ||
                      virtualAccount?.vendor_escrow_ac_no === "" ? (
                        ""
                      ) : (
                        <div className="vpa_title">
                          Investor Escrow Account No :{" "}
                          <span
                            className="vpa_subtitle"
                            style={{ marginLeft: "47px" }}
                          ></span>
                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            {virtualAccount?.vendor_escrow_ac_no
                              ? virtualAccount?.vendor_escrow_ac_no
                              : ""}

                            <div
                              style={{ cursor: "pointer", paddingLeft: "7px" }}
                              onClick={() =>
                                onClickCode(
                                  virtualAccount?.vendor_escrow_ac_no
                                    ? virtualAccount?.vendor_escrow_ac_no
                                    : ""
                                )
                              }
                            >
                              <ContentCopyIcon fontSize="small" />
                            </div>
                          </div>
                        </div>
                      )}

                      {virtualAccount?.vendor_account_holder_name === null ||
                      virtualAccount?.vendor_account_holder_name === "" ? (
                        ""
                      ) : (
                        <div className="vpa_title">
                          Account Holder :{" "}
                          <span
                            className="vpa_subtitle"
                            style={{ marginLeft: "47px" }}
                          ></span>
                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            {virtualAccount?.vendor_account_holder_name
                              ? virtualAccount?.vendor_account_holder_name
                              : ""}

                            <div
                              style={{ cursor: "pointer", paddingLeft: "7px" }}
                              onClick={() =>
                                onClickCode(
                                  virtualAccount?.vendor_account_holder_name
                                    ? virtualAccount?.vendor_account_holder_name
                                    : ""
                                )
                              }
                            >
                              <ContentCopyIcon fontSize="small" />
                            </div>
                          </div>
                        </div>
                      )}

                      {virtualAccount?.vendor_bank === null ||
                      virtualAccount?.vendor_bank === "" ? (
                        ""
                      ) : (
                        <div className="vpa_title">
                          Bank :{" "}
                          <span
                            className="vpa_subtitle"
                            style={{ marginLeft: "47px" }}
                          ></span>
                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            {virtualAccount?.vendor_bank
                              ? virtualAccount?.vendor_bank
                              : ""}

                            <div
                              style={{ cursor: "pointer", paddingLeft: "7px" }}
                              onClick={() =>
                                onClickCode(
                                  virtualAccount?.vendor_bank
                                    ? virtualAccount?.vendor_bank
                                    : ""
                                )
                              }
                            >
                              <ContentCopyIcon fontSize="small" />
                            </div>
                          </div>
                        </div>
                      )}
                      {virtualAccount?.vendor_account_type === null ||
                      virtualAccount?.vendor_account_type === "" ? (
                        ""
                      ) : (
                        <div className="vpa_title">
                          Account Type :{" "}
                          <span
                            className="vpa_subtitle"
                            style={{ marginLeft: "47px" }}
                          ></span>
                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            {virtualAccount?.vendor_account_type
                              ? virtualAccount?.vendor_account_type
                              : ""}

                            <div
                              style={{ cursor: "pointer", paddingLeft: "7px" }}
                              onClick={() =>
                                onClickCode(
                                  virtualAccount?.vendor_account_type
                                    ? virtualAccount?.vendor_account_type
                                    : ""
                                )
                              }
                            >
                              <ContentCopyIcon fontSize="small" />
                            </div>
                          </div>
                        </div>
                      )}
                      {virtualAccount?.vendor_ifsc_code === null ||
                      virtualAccount?.vendor_ifsc_code === "" ? (
                        ""
                      ) : (
                        <div className="vpa_title">
                          IFSC Code :{" "}
                          <span
                            className="vpa_subtitle"
                            style={{ marginLeft: "47px" }}
                          ></span>
                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            {virtualAccount?.vendor_ifsc_code
                              ? virtualAccount?.vendor_ifsc_code
                              : ""}

                            <div
                              style={{ cursor: "pointer", paddingLeft: "7px" }}
                              onClick={() =>
                                onClickCode(
                                  virtualAccount?.vendor_ifsc_code
                                    ? virtualAccount?.vendor_ifsc_code
                                    : ""
                                )
                              }
                            >
                              <ContentCopyIcon fontSize="small" />
                            </div>
                          </div>
                        </div>
                      )}
                      {virtualAccount?.vendor_branch === null ||
                      virtualAccount?.vendor_branch === "" ? (
                        ""
                      ) : (
                        <div className="vpa_title">
                          Branch :{" "}
                          <span
                            className="vpa_subtitle"
                            style={{ marginLeft: "47px" }}
                          ></span>
                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            {virtualAccount?.vendor_branch
                              ? virtualAccount?.vendor_branch
                              : ""}

                            <div
                              style={{ cursor: "pointer", paddingLeft: "7px" }}
                              onClick={() =>
                                onClickCode(
                                  virtualAccount?.vendor_branch
                                    ? virtualAccount?.vendor_branch
                                    : ""
                                )
                              }
                            >
                              <ContentCopyIcon fontSize="small" />
                            </div>
                          </div>
                        </div>
                      )}

                      {/* <div className="vpa_title"> */}
                      <div
                      // style={{ifsc_code
                      //   display: "flex",
                      //   justifyContent: "space-between",
                      // }}
                      >
                        {/* <span style={{ fontSize: "10px" }}>VPA </span> */}
                        {/* <span className="element"> */}
                        {/* {" "}
                          <img
                            src={Tooltip}
                            style={{
                              height: "16px",
                              width: "16px",
                              marginRight: "5px",
                              marginLeft: "5px",
                            }}
                          /> */}
                        {/* <span class="tooltip">
                            UPI Virtual Payment Address
                          </span>
                        </span> */}
                        {/* <span style={{ fontSize: "10px" }}>:</span> */}
                        {/* </div> */}

                        {/* <span> */}
                        {/* <span className="vpa_subtitle">
                          {virtualAccount.virtual_upi_vpa
                            ? virtualAccount.virtual_upi_vpa
                            : ""}
                        </span>{" "} */}
                        {/* <span
                          className="copy_button"
                          // onClick={() =>
                          //   onClickCode(
                          //     virtualAccount.virtual_upi_vpa
                          //       ? virtualAccount.virtual_upi_vpa
                          //       : ""
                          //   )
                          // }
                        >
                          copy
                        </span> */}
                        {/* </span> */}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div
                className="wallet_request_confirmation_data_div4"
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  marginLeft: "20px",
                  justifyContent: "space-between",
                  display: !verified ? "none" : "flex",
                }}
              >
                <button
                  onClick={() => {
                    if (props.fromInvest) {
                      props.onCancel();
                    } else {
                      onRequestSubmit("cancel", 3);
                    }
                  }}
                  style={{
                    backgroundColor: "transparent",
                    cursor: "pointer",
                    width: "30%",
                    color: "#cc3a2f",
                    border: "1px solid #cc3a2f",
                  }}
                  className="wallet_request_confirmation_button"
                >
                  Cancel
                </button>

                <button
                  onClick={() => {
                    //onRequestSubmit(option == "vpa" ? 2 : 0)
                    console.log("option value::", option);
                    if (option == "payOnline") {
                      setShowPaymentGateway(true);
                    } else if (option == "paytm") {
                      console.log("option value ::", option);
                      setShowPaymentGateway(true);
                      // checkVpa();
                    } else {
                      onRequestSubmit("auto_collect", 2);
                    }
                  }}
                  style={{
                    backgroundColor: "#3a86ff",
                    cursor: "pointer",
                    width: "30%",
                  }}
                  className="wallet_request_confirmation_button"
                >
                  Confirm
                </button>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Phonepe
                // amount={linkData["advised_amount"]}

                data={{
                  amount: linkData["advised_amount"],
                  // vpa:"",
                  UUID: uuid,
                  investment_request_uuid:
                    props?.walletLoadingData?.investment_request_uuid,
                }}
                onSuccess={(res) => {
                  onRequestSubmit("payment_gateway", 1);
                }}
                onFailure={(res) => {
                  setInfoMsg(res.error);
                }}
              />
            </React.Fragment>
          )}
        </div>
        {/* proceed div */}
        <div
          className="wallet_request_confirmation_data_div4"
          style={{
            marginTop: "10px",
            marginBottom: "10px",
            display: verified ? "none" : "flex",
          }}
        >
          <button
            onClick={onSendOtp}
            style={{
              backgroundColor: "#3a86ff",
              cursor: "pointer",
            }}
            className="wallet_request_confirmation_button"
          >
            Proceed
          </button>
        </div>
      </div>
    );
  };

  // otp enter view
  const otpEnterView = () => {
    return (
      <div className="wallet_request_confirmation_div">
        {/* header */}
        <header className="wallet_request_confirmation_header">
          <h1 className="wallet_request_confirmation_header_title">
            Complete wallet loading request
          </h1>
        </header>
        {/* info */}
        <div className="otp_info_text_div">
          <p className="otp_info_text">
            Please enter the 4-digit OTP sent via SMS on your phone (+91-XXXXXX
            {linkData.client_mobile.substr(linkData.client_mobile.length - 4)})
          </p>
        </div>
        {/* otp input */}
        <input
          type="text"
          maxLength={4}
          placeholder="Enter OTP"
          className="wallet_request_confirmation_input"
          onKeyPress={onKeyPress}
          onChange={(event) => {
            setOtpInvalid(false);
            setOtpInvalidText("");
            setOtp(event.target.value);
          }}
          style={{
            borderColor: otpInvalid ? "#ff2e2e" : undefined,
          }}
        />

        {/*validation error */}
        <div className="wallet_request_confirmation_invalid_div">
          {/* icon */}
          <InfoIcon
            style={{
              fontSize: "14px",
              paddingRight: "5px",
              color: "#ff2e2e",
              opacity: otpInvalid ? 1 : 0,
            }}
          />
          {/* text */}
          <p
            className="wallet_request_confirmation_invalid_text"
            style={{
              opacity: otpInvalid ? 1 : 0,
            }}
          >
            {otpInvalid ? otpInvalidText : "Invalid"}
          </p>
        </div>

        {/* button */}
        <button
          onClick={onGetAuth}
          className="wallet_request_confirmation_otp_button"
        >
          Submit
        </button>
      </div>
    );
  };

  // page not available view
  const pageNotAvailable = () => {
    return (
      <div className="wallet_request_confirmation_page_expire">
        <h1 className="wallet_request_confirmation_header_page_expire_title">
          {errMsg}
        </h1>
      </div>
    );
  };

  return (
    <div>
      {render === false ? (
        <div />
      ) : (
        <div className="wallet_request_confirmation_container">
          {/* logo */}
          <img
            src="https://arthmate-p2p-public-assets.s3.ap-south-1.amazonaws.com/images/1invest-logo-color-dark.png"
            alt="logo"
            className="wallet_request_confirmation_logo_image"
          />

          {linkData === undefined || err
            ? // page not found
              pageNotAvailable()
            : otpView
            ? // otp enter view
              otpEnterView()
            : !otpView && verified
            ? // withdrawal details view with confirm and cancel
              withdrawalDetailsView()
            : // withdrawal details view
              withdrawalDetailsView()}

          {/* info alert */}
          <InfoAlert
            show={showInfo}
            ok={() => {
              setShowInfo(false);
              if (props.fromInvest) {
                if (props.walletLoadingData.consent_status != 2) {
                  onSignOut();
                } else if (props.fromInvest) {
                  props.onDone();
                }
              } else {
                onSignOut();
              }
            }}
            title="Alert"
            content={infoMsg}
            okbutton="OK"
          />

          {/* full screen loader */}
          {fullLoader ? <FullScreenLoader /> : <div />}

          {/* toast container */}
          <SampleToast />
        </div>
      )}
    </div>
  );
};

export default WalletRequestConfirmation;
