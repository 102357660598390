import React, { useEffect, useState, useRef } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";
import { download3 } from "../../assets/images/index";
import Loader from "react-loader-spinner";
import TableSortLabel from "@mui/material/TableSortLabel";
import { useStores } from "../../models";
import { useHistory, Redirect } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Auth } from "aws-amplify";
import InfoIcon from "@mui/icons-material/Info";
import SearchIcon from "@mui/icons-material/Search";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import { kycStatus_API } from "../../services/api/kycstatus/kycstatus-api";
import {
  errorToast,
  convertAmount,
  successToast,
  sorting,
  getFileExtension,
  getUniqueUUID,
  infoToast,
  warningToast,
} from "../../utils/helper";
import {
  ContentLoadingLoader,
  MarketingModel,
  SalesModel,
  LogoModel,
  ListToSendModel,
  SampleToast,
  CustomTooltip,
  AddNewClient,
  SendKycLinkModel,
  WithdrawalModel,
  LabelWithSortComponent,
  FullScreenLoader,
} from "../../components";
import { IFA_KYC_API } from "../../services/api/IFA Kyc/ifa-kyc-api";
import { FastForward } from "@mui/icons-material";
import withdraw from "../../assets/images/withdraw.svg";
import kycEnable from "../../assets/images/kycEnable.svg";
import kycDisable from "../../assets/images/kycDisable.svg";
import walletLoading from "../../assets/images/walletLoadingSolid.svg";
import { User_API, WithdrawalApi, WalletApi } from "../../services/api";
import WalletLoadingModel from "../../components/wallet-loading-model/wallet-loading-model";
import { eventLog } from "../../utils/firebase";
import { Profile_API } from "../../services/api/Profile/profile-api";
import "./marketing.css";
import { Downloads_API } from "../../services/api/Downloads/downloads-api";
import { color } from "@mui/system";
// import HideIFA from "../HideIFA/hideIFA";

const Marketing = observer(function Marketing(props) {
  const [isLogin, setIsLogin] = useState();
  const [loginRender, setLoginRender] = useState(false);
  const [render, setRender] = useState(false);
  const [loader, setLoader] = useState(false);
  const navigate = useHistory();
  const [disable, setDisable] = useState(false);
  const logoFileRef = useRef(null);
  const [error, setErrors] = useState([]);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [submitDisable, setSubmitDisable] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [marketingData, setMarketingData] = useState([]);
  const [salesMail, setSalesMail] = useState("");
  const [logoRender, setLogoRender] = useState(false);
  const [marketRender, setMarketRender] = useState(false);
  const [salesRender, setSalesRender] = useState(false);
  const [openMarketingModel, setOpenMarketingModel] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [salesData, setSalesData] = useState([]);
  const [clients, setclients] = useState([]);
  const [senderLoader, setSenderLoader] = useState(false);
  const [sent, setSent] = useState(false);
  const [logoTab, setLogoTab] = useState(true);
  const [marketingTab, setMarketingTab] = useState(false);
  const [salesTab, setSalesTab] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Logo");
  let [selectedClients, setSelectedClients] = useState([]);
  const [message, setMessage] = useState("");
  const [sendMailBody, setSendMailBody] = useState({});
  const [selectedPara, setSelectedPara] = useState("");
  const [logoFile, setLogoFile] = useState({
    logo_image: localStorage.getItem("logo_image")
      ? JSON.parse(localStorage.getItem("logo_image"))
      : "",
    logo_image_data: {
      base64: null,
      size: null,
      content_type: null,
    },
  });
  const [logoLoader, setLogoLoader] = useState(false);
  const imageSizeLimit = 1;
  const api = new Downloads_API();
  const labelArray = ["Logo", "Marketing", "Sales"];

  // const [hideIFA, setHideIFA] = useState(false);

  // function handleWindowSizeChange() {
  //   if (window.innerWidth <= 1080 || window.innerHeight <= 600) {
  //     setHideIFA(true);
  //     props.showCommonHeader(false);
  //     props.showCommonSidebar(false);
  //   } else {
  //     setHideIFA(false);
  //     props.showCommonHeader(true);
  //     props.showCommonSidebar(true);
  //   }
  // }

  // useEffect(() => {
  //   window.addEventListener("resize", handleWindowSizeChange);
  //   if (window.innerWidth <= 1080 || window.innerHeight <= 600) {
  //     setHideIFA(true);
  //     props.showCommonHeader(false);
  //     props.showCommonSidebar(false);
  //   }
  //   return () => {
  //     window.removeEventListener("resize", handleWindowSizeChange);
  //   };
  // }, []);

  // check if user login or not
  useEffect(() => {
    // getHistory()
    props.showCommonHeader(true);
    //props.showCommonSidebar(true);
    let checkLogin = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        let status = localStorage.getItem("kyc_status");
        if (status != 2) {
          setIsLogin(false);
        }
        else
        {
        fetchIfakycStatus();
        }
        // setIsLogin(true);
        // setLoginRender(true);
        // fetchClientList();
        // getLogoData()
        // getExistingLogo();
        // getMarketingMaterial();
        // getSalesText();
        // getClients();
        // setRender(true);
        // showDownloads()
      } catch {
        setIsLogin(false);
      }
    };
    return checkLogin();
    // eslint-disable-next-line
  }, []);

  // fetch ifa kyc status
  const fetchIfakycStatus = () => {
    setLoader(true);
    const api = new kycStatus_API();

    // fetch data
    api
      .getkycStatusData()
      .then((res) => {
        if (res.kind === "ok") {
          props.showCommonSidebar(true);
          setLoader(false);
          getExistingLogo();
          getMarketingMaterial();
          getSalesText();
          getClients();
          setIsLogin(true);
          setLoginRender(true);
        } else {
          setLoader(false);
          navigate.push("/");
        }
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
        errorToast(
          "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
        );
      });
  };

  const onHandleChange = async (e, label) => {
    if (e.target.files[0].size > imageSizeLimit * 1024 * 1024) {
      warningToast(
        `Logo image size should not be more than ${imageSizeLimit} MB`
      );
    } else {
      setLogoLoader(true);
      let uuid = await getUniqueUUID();
      let ext = await getFileExtension(e);
      let fileName = `${uuid}.${ext}`;
      let reader = new FileReader(e.target.files[0]);
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = function (evt) {
        setTimeout(() => {
          setLogoFile({
            logo_image: fileName,
            logo_image_data: {
              base64: evt.target.result,
              size: e.target.files[0].size,
              content_type: e.target.files[0].type,
            },
          });
          setSubmitDisable(false);
          setLogoLoader(false);
          console.log("props", error["logo_image"]);
          console.log(props);
          if (error.hasOwnProperty("logo_image")) {
            setErrors({ ...error, logo_image: [] });
          }
          //console.log("props", props.error["bank_cancel_cheque"].length );
        }, 2000);
      };
      reader.onerror = function (error) {
        console.log("Error while uploading logo image: ", error);
        setTimeout(() => {
          errorToast(`logo image could not upload, Try again`);
          setLogoLoader(false);
        }, 2000);
      };
    }
  };
  const handleMaterialSelection = (client) => {
    if (selectedClients.includes(client)) {
      const index = selectedClients.indexOf(client);
      setSelectedClients(selectedClients.splice(index, 1));
    } else {
      setSelectedClients([...selectedClients, client]);
    }
  };

  const submitLogo = async () => {
    setSubmitLoader(true);
    if (logoFile.logo_image_data != "" && logoFile.logo_image_data != null) {
      setSubmitLoader(true);
      //call api
      api.getLogoDataUploaded(logoFile).then((res) => {
        setTimeout(() => {
          if (res.kind === "ok") {
            // setDownloadableData(res.data);
            setTimeout(() => {
              successToast("successfully submitted the logo");
              setSubmitLoader(false);
              setSubmitted(true);
            }, 2000);
          } else {
            setSubmitLoader(false);
            errorToast(res.error);
          }
        });
      }, 2000);
    }
  };
  const handleClose = () => {
    setOpenModel(false);
  };

  //close other tab
  const closeOtherTab = async (tab) => {
    if (tab == "Logo") {
      setSelectedTab("Logo");
      setMarketingTab(false);
      setSalesTab(false);
      setLogoTab(true);
    } else if (tab == "Marketing") {
      setSelectedTab("Marketing");
      setSalesTab(false);
      setLogoTab(false);
      setMarketingTab(true);
    } else {
      setSelectedTab("Sales");
      setMarketingTab(false);
      setSalesTab(true);
      setLogoTab(false);
    }
  };
  const clearModel = async () => {
    setOpenModel(false);
    setSelectedClients([]);
    setSelectedPara("");
    setMessage("");
  };

  //to send the promotional text
  const sendLinks = async () => {
    console.log("checking uncheck", selectedClients);
    setOpenModel(false);
    if (selectedClients.length > 0) {
      const body = {
        selectedClients: selectedClients,
        extra: message,
        paragraph: selectedPara,
      };
      setSenderLoader(true);
      console.log("sendMailBody", body);
      //call api
      api.sendMailToClients(body).then((res) => {
        console.log(sendMailBody);
        if (res.kind === "ok") {
          // setDownloadableData(res.data);
          setSenderLoader(false);
          setSent(true);
          successToast("mail has been sent");
        } else {
          setSenderLoader(false);
          errorToast(res.error);
        }
      });
    }
    clearModel();
  };

  const getClients = async () => {
    api.getClientData().then((res) => {
      // setTimeout(()=>{
      if (res.kind == "ok") {
        setclients(res.data);
      } else {
        console.log(res.error);
      }
      // },2000)
    });
  };

  const fileName = "P2P_Marketing_Material";
  //new download
  function downloadBase64File(base64Data, contentType) {
    // const contentType='image/png'
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  const getExistingLogo = () => {
    api.getLogoData().then((res) => {
      if (res.kind === "ok") {
        // setDownloadableData(res.data);
        // setDownloadableData(res.data);

        if (res.data && res.data.logo_status) {
          if (res.data.logo_status == 1 || res.data.logo_status == 2) {
            setDisable(true);
            setSubmitted(true);

            setLogoFile({
              logo_image: res.data.file_name,
              logo_image_data: {
                base64: res.data.base64,
                size: res.data.size,
                content_type: res.data.content_type,
              },
            });
          }
        }

        setLogoRender(true);
      } else {
        setLogoRender(true);
        errorToast(res.error);
      }
    });
  };

  const getMarketingMaterial = () => {
    setMarketRender(false);
    api.getMarketingData().then((res) => {
      if (res.kind === "ok") {
        setMarketingData(res.data);
        setMarketRender(true);
      } else {
        setMarketRender(true);
        errorToast(res.error);
      }
    });
  };
  //get existing sales message
  const getSalesText = () => {
    setRender(false);
    api.getSalesExistingData().then((res) => {
      if (res.kind === "ok") {
        setSalesData(res.data);
        setRender(true);
      } else {
        setRender(true);
        errorToast(res.error);
      }
    });
  };

  // if (hideIFA) {
  //   return <HideIFA />;
  // }
  return (
    <div>
      {loginRender === false ? (
        <div />
      ) : (
        <div className="marketing_div_container">
          {render === false ? (
            <ContentLoadingLoader />
          ) : (
            <div className="marketing_main_div">
              {/* header */}
              <div style={{ display: "table-row", padding: "2%" }}>
                <a
                  className={selectedTab === "Logo" ? "tab selected" : "tab"}
                  onClick={() => closeOtherTab("Logo")}
                >
                  Logo
                </a>
                <a
                  className={
                    selectedTab === "Marketing" ? "tab selected" : "tab"
                  }
                  onClick={() => closeOtherTab("Marketing")}
                >
                  Marketing
                </a>
                <a
                  className={selectedTab === "Sales" ? "tab selected" : "tab"}
                  onClick={() => closeOtherTab("Sales")}
                >
                  Sales
                </a>
              </div>

              {logoTab ? (
                <LogoModel
                  disable={disable}
                  logoFile={logoFile}
                  logoFileRef={logoFileRef}
                  logoLoader={logoLoader}
                  onHandleChange={onHandleChange}
                  submitted={submitted}
                  submitDisable={submitDisable}
                  submitLoader={submitLoader}
                  submitLogo={submitLogo}
                />
              ) : null}

              {marketingTab ? (
                <div>
                  <MarketingModel
                    marketingData={marketingData}
                    downloadBase64File={downloadBase64File}
                    marketRender={marketRender}
                  />
                </div>
              ) : null}

              {salesTab ? (
                <SalesModel
                  salesData={salesData}
                  downloadBase64File={downloadBase64File}
                  setOpenModel={setOpenModel}
                  setSelectedPara={setSelectedPara}
                  clients={clients}
                  errorToast={errorToast}
                />
              ) : null}

              <ListToSendModel
                openModel={openModel}
                handleClose={handleClose}
                selectedClients={selectedClients}
                setSelectedClients={setSelectedClients}
                client={clients}
                handleMaterialSelection={handleMaterialSelection}
                sendLinks={sendLinks}
                message={message}
                setMessage={setMessage}
                // setOpenModel={setOpenModel}
              />
              <SampleToast />
            </div>
          )}
        </div>
      )}
    </div>
  );
});

export default Marketing;
