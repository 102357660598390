import { API } from "aws-amplify";
import { WithdrawalHistorySnapshot } from "../../../models/WithdrawalHistory/withdrawal-history/withdrawal-history";
import * as Types from "../api.types";

/**
 * Manages all requests to the API.
 */
export class WithdrawalHistory_Api {
  /**
   * @description This function is for converting raw data into the actual data
   * @param raw
   * @returns it return the actual data that can be displayed
   */
  dataConverter = (raw: any) => {
    return {
      uuid: raw.uuid,
      name: raw.name === undefined ? "" : raw.name,
      amount: raw.amount === undefined ? "0" : parseFloat(raw.amount),
      status: raw.status === undefined ? 0 : raw.status,
      mobile: raw.mobile === undefined ? "" : raw.mobile,
      action:
        raw.status === undefined
          ? "Withdraw Request Rejected"
          : raw.status === 1
          ? "Transaction Initiated"
          : raw.status === 2
          ? "Withdraw Request Sent"
          : raw.status === 3
          ? "Transaction Approved"
          : raw.status === 4
          ? "Transaction Failed"
          : raw.status === 8
          ? "Withdraw Request Cancelled"
          : raw.status === 9
          ? "Withdraw Processed"
          : " Withdraw Request Rejected",
      sent_at: raw.created_at === undefined ? 0 : raw.created_at,
      updated_at: raw.updated_at === undefined ? 0 : raw.updated_at,
      display_modal :raw.display_modal === undefined ? 0 : raw.display_modal,
      approved_at: raw.approved_at === undefined ? 0 : raw.approved_at,
      processed_at: raw.processed_at === undefined ? 0 : raw.processed_at,
      utr:raw.utr === undefined ? 0 : raw.utr,
    };
  };

  /**
   * @description This function is to get withdrawal history of investor
   * @returns it returns user overall withdrawal history.
   */
  async getWithdrawalHistory(
    data: any
  ): Promise<Types.getWithdrawalHistoryData> {
    // payload
    const params = {
      body: data,
    };

    //call the api try and catch
    try {
      const response: any = await API.post(
        "baseurl",
        "/withdrawalHistory",
        params
      );
      const rawData = response.data;
      // console.log(rawData.map(this.dataConverter), "raw data history")
      const convertedWithdrawalHistoryData: WithdrawalHistorySnapshot[] =
        rawData.map(this.dataConverter);
      //   console.log(convertedWithdrawalHistoryData, "snapshot history")
      return { kind: "ok", data: convertedWithdrawalHistoryData };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description This function is to get withdrawal request history of ifa
   * @returns it returns user overall withdrawal request history.
   */
  async getIfaWithdrawalHistory(): Promise<Types.getWithdrawalHistoryData> {
    //call the api try and catch
    try {
      const response: any = await API.get("baseurl", "/withdrawalHistory", {});
      const rawData = response.data;
      // console.log(rawData.map(this.dataConverter), "raw data history")
      const convertedWithdrawalHistoryData: WithdrawalHistorySnapshot[] =
        rawData.map(this.dataConverter);
      console.log(convertedWithdrawalHistoryData, "snapshot history");
      return { kind: "ok", data: convertedWithdrawalHistoryData };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }
}
