import * as React from "react";
import "./pool-model.css";
import Modal from "@mui/material/Modal";
import { eventLog } from "../../utils/firebase";

export default function PoolModel(props) {
  var clients = [];
  if(Array.isArray(props.client)){
    clients = props.client;
  }else {
    clients = [props.client];
  }
  return (
    <Modal
      open={props.openModel}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.699)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className="poollist_model_div">
        {/* title */}
        <div className="poollist_model_title_div">
          <h1 className="poollist_model_title">Send Payment Link{(clients.length > 1) ? 's' : ''} For {props?.pools[props.selectedIndex]?.pool_title}</h1>

        </div>

        {/* amount input */}
        {clients.map((client, index) => {
          return (
            <div className="modal_client_amount_wrapper">
              <h1
                className="poollist_model_title"
                style={{
                  marginLeft: "5px",
                  color: "#3a86ff",
                }}
              >
                {client.name === "" ? "" : client.name}
              </h1>
              <input
                disabled={props.disabled}
                type="text"
                placeholder="Enter Investment Amount"
                className="poollist_model_input"
                onKeyPress={props.onKeyPress}
                onChange={(event) => {
                  eventLog({
                    type: "investment_amount",
                    action: {
                      trigger: "true",
                    },
                  });
                  const clientIndex = clients.findIndex(function (item, i) {
                    return item.uuid === client.uuid;
                  });
                  let value = [];
                  clients[clientIndex]['investment_amount'] = event.target.value;
                  value[props.selectedIndex] = clients;
                  props.setAmount(value);
                }}
              />
            </div>
          );
        })}

        {/* send button */}
        <button
          disabled={props.disabled}
          className="poollist_model_button"
          onClick={() => props.onSendPayment(props.selectedIndex)}
        >
          Send Payment Link
        </button>
      </div>
    </Modal>
  );
}
