module.exports = {
  /**
   * account Creation State
   */
  accountCreationState: {
    gst_number: {
      defaultValue: null,
      required: false,
      type: "text",
    },
    email: {
      defaultValue: "",
      required: true,
      type: "email",
    },
    mobile: {
      defaultValue: null,
      required: true,
      type: "number",
    },
    firm_name: {
      defaultValue: "",
      required: true,
      type: "string",
    },
    name: {
      defaultValue: null,
      type: "string",
    },
  },

  /**
   * KYC Details State
   */

  kycDetailsState: {
    agreement_signatory: {
      defaultValue: "",
      required: false,
      type: "text",
    },
    pan_name: {
      defaultValue: "",
      required: false,
      type: "text",
    },
    pan_number: {
      defaultValue: null,
      required: true,
      type: "text",
    },
    ckyc_number: {
      defaultValue: null,
      required: false,
      type: "text",
    },
    date_of_birth: {
      defaultValue: null,
      required: true,
      type: "date",
    },
    is_ckyc: {
      defaultValue: false,
      required: false,
      type: "boolean",
    },
    is_pan_ocr_success: {
      defaultValue: false,
      required: false,
      type: "boolean",
    },
    pan_image: {
      defaultValue: false,
      required: false,
      type: "text",
    },
    address_number: {
      defaultValue: null,
      required: false,
      type: "number",
    },
    address_front_image: {
      defaultValue: null,
      required: false,
      type: "text",
    },
    address_back_image: {
      defaultValue: null,
      required: false,
      type: "text",
    },
    address: {
      defaultValue: null,
      required: false,
      type: "text",
    },
    is_aadhaar_json_success: {
      defaultValue: null,
      required: false,
      type: "text",
    },
    is_name_match_success: {
      defaultValue: false,
      required: false,
      type: "boolean",
    },
    father_name: {
      defaultValue: false,
      required: false,
      type: "boolean",
    },
    gst_certificate: {
      defaultValue: null,
      required: false,
      type: "text",
    },
    board_resolution: {
      defaultValue: null,
      required: false,
      type: "text",
    },
    agreement: {
      defaultValue: null,
      required: false,
      type: "text",
    },
    is_agreement_accepted: {
      defaultValue: false,
      required: false,
      type: "boolean",
    },
    has_bank: {
      defaultValue: 0,
      required: false,
      type: "number",
    },
  },

  /**
   * Basic details for client kyc State
   */

  clientKycBasicDetail: {
    investor_type: {
      defaultValue: "",
      required: true,
      type: "text",
    },
    company_type: {
      defaultValue: "",
      required: true,
      type: "text",
      associatedFirm: ["private_limited_company", "public_limited_company"],
    },
    pan_name: {
      defaultValue: "",
      required: true,
      type: "text",
    },
    pan: {
      defaultValue: null,
      required: true,
      type: "text",
    },

    is_pan_consent_accepted: {
      defaultValue: false,
      required: true,
      type: "boolean",
    },
    email: {
      defaultValue: "",
      required: true,
      type: "email",
    },

    mobile: {
      defaultValue: "",
      required: true,
      type: "number",
    },

    dob: {
      defaultValue: "",
      required: true,
      type: "date",
    },

    gender: {
      defaultValue: null,
      required: true,
      type: "text",
    },
  },

  /**
   * Bank details for add client
   */

  clientBankDetails: {
    ifsc_code: {
      defaultValue: "",
      required: true,
      type: "text",
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
        "public_limited_company",
        "individual",
      ],
    },
    account_number: {
      defaultValue: null,
      required: true,
      type: "number",
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
        "public_limited_company",
        "individual",
      ],
    },
    cancel_cheque: {
      defaultValue: null,
      required: false,
      type: "object",
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
        "public_limited_company",
        "individual",
      ],
    },

    is_bank_consent_accepted: {
      defaultValue: false,
      required: true,
      type: "boolean",
      associatedFirm: ["individual"],
    },
  },

  /**
   * Kyc Documents details for add client
   */

  clientKycDocumentDetails: {
    selfie: {
      defaultValue: null,
      required: true,
      type: "object",
      label: "Selfie",
      associatedFirm: ["individual"],
    },
    current_address: {
      defaultValue: null,
      required: true,
      type: "text",
      label: "Address",
      associatedFirm: ["individual"],
    },
    address_type: {
      defaultValue: null,
      required: true,
      type: "number",
      associatedFirm: ["individual"],
    },
    address_front_image: {
      defaultValue: null,
      required: true,
      type: "text",
      associatedFirm: ["individual"],
    },
    address_back_image: {
      defaultValue: null,
      required: false,
      type: "text",
      associatedFirm: ["individual"],
    },
    current_locality: {
      defaultValue: null,
      required: true,
      type: "text",
      associatedFirm: ["individual"],
    },
    current_city: {
      defaultValue: null,
      required: true,
      type: "text",
      associatedFirm: ["individual"],
    },
    current_state: {
      defaultValue: null,
      required: true,
      type: "text",
      associatedFirm: ["individual"],
    },
    current_pin_code: {
      defaultValue: null,
      required: true,
      type: "number",
      associatedFirm: ["individual"],
    },
    permanent_current_address_flag: {
      defaultValue: false,
      required: true,
      type: "boolean",
      associatedFirm: ["individual"],
    },
    permanent_address: {
      defaultValue: null,
      required: false,
      type: "text",
      label: "Address",
      associatedFirm: ["individual"],
    },
    permanent_locality: {
      defaultValue: null,
      required: false,
      type: "text",
      associatedFirm: ["individual"],
    },
    permanent_city: {
      defaultValue: null,
      required: false,
      type: "text",
      associatedFirm: ["individual"],
    },
    permanent_state: {
      defaultValue: null,
      required: false,
      type: "text",
      associatedFirm: ["individual"],
    },
    permanent_pin: {
      defaultValue: null,
      required: false,
      type: "number",
      associatedFirm: ["individual"],
    },
  },

  /**
   * Nominee details for add client
   */

  clientNomineeDetails: {
    nominee_name: {
      defaultValue: null,
      required: true,
      type: "text",
    },

    nominee_relationship: {
      defaultValue: null,
      required: true,
      type: "text",
    },

    nominee_dob: {
      defaultValue: null,
      required: true,
      type: "date",
    },

    nominee_mobile_number: {
      defaultValue: null,
      required: false,
      type: "number",
    },

    nominee_email_id: {
      defaultValue: null,
      required: false,
      type: "email",
    },

    nominee_pan: {
      defaultValue: null,
      required: false,
      type: "text",
    },
  },
  
  orgDocumentState: {
    copy_of_amfi_certificate: {
      defaultValue: null,
      required: false,
      type: "text",
      label: "Copy of AMFI Certificate",
      associatedFirm: [],
    },
    copy_of_arn_card: {
      defaultValue: null,
      required: false,
      type: "text",
      label: "Copy of ARN Card",
      associatedFirm: [],
    },
    memorandom_and_article_of_assosiation: {
      defaultValue: null,
      required: false,
      type: "text",
      label: "Memorandum of Association",
      associatedFirm: ["Private Limited Company", "Public Limited Company"],
    },
    article_of_association: {
      defaultValue: null,
      required: false,
      type: "text",
      label: "Articles of Association",
      associatedFirm: ["Private Limited Company", "Public Limited Company"],
    },
    autherised_signatory_list: {
      defaultValue: null,
      required: false,
      type: "text",
      label: "Autherised Signatory List",
      associatedFirm: [],
    },
    partnership_deed_and_resolution: {
      defaultValue: false,
      required: false,
      type: "text",
      label: "Partnership Deed and Resolution",
      associatedFirm: ["Partnerships Company", "Limited Liability Partnership"],
    },
    deeds_of_declaration: {
      defaultValue: false,
      required: false,
      type: "text",
      label: "Deeds of Declaration",
      associatedFirm: ["HUF"],
    },
    kyd_acknowledgement: {
      defaultValue: false,
      required: false,
      type: "text",
      label: "KYD Acknowledgement",
      associatedFirm: [],
    },
    trade_license: {
      defaultValue: false,
      required: false,
      type: "text",
      label: "Trade License",
      associatedFirm: ["Sole Proprietorship"],
    },
    other_document: {
      defaultValue: false,
      required: false,
      type: "text",
      label: "Other Supporting Documents",
      associatedFirm: [
        "Individual",
        "HUF",
        "Private Limited Company",
        "Public Limited Company",
        "Partnerships Company",
        "Limited Liability Partnership",
        "Sole Proprietorship",
      ],
    },
  },

  /**
   * Entity details for add client
   */

  clientEntityDetails: {
    entity_name: {
      defaultValue: null,
      required: true,
      type: "text",
      associatedFirm: ["sole_proprietorship"],
    },

    date_of_incorporation: {
      defaultValue: null,
      required: true,
      type: "date",
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "partnership_firm",
        "private_limited_company",
        "trust",
      ],
    },

    entity_document_1: {
      defaultValue: null,
      required: true,
      type: "object",
      associatedFirm: ["sole_proprietorship"],
    },
    entity_document_2: {
      defaultValue: null,
      required: true,
      type: "object",
      associatedFirm: ["sole_proprietorship"],
    },

    entity_registered_address: {
      defaultValue: null,
      required: true,
      type: "text",
      label: "Address",
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
    },

    entity_current_locality: {
      defaultValue: null,
      required: true,
      type: "text",
      label: "Locality",
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
    },
    entity_current_city: {
      defaultValue: null,
      required: true,
      type: "text",
      label: "City",
      specialCharCheck: true,
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
    },
    entity_current_state: {
      defaultValue: null,
      required: true,
      type: "text",
      label: "State",
      specialCharCheck: true,
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
    },
    entity_current_pin: {
      defaultValue: null,
      required: true,
      type: "number",
      label: "Pincode",
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
    },

    entity_address_proof: {
      defaultValue: null,
      required: true,
      type: "object",
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
    },

    beneficial_owner_1_pan: {
      defaultValue: null,
      required: true,
      type: "object",
      label: "PAN",
      associatedFirm: ["trust", "partnership_firm", "private_limited_company"],
      note: "Upload image in JPG/JPEG/PNG/PDF format. Max. file size allowed is 15 MB.",
    },
    beneficial_owner_1_address_proof: {
      defaultValue: null,
      required: true,
      type: "object",
      label: "Address Proof",
      associatedFirm: ["trust", "partnership_firm", "private_limited_company"],
      note: "Upload image in JPG/JPEG/PNG/PDF format. Max. file size allowed is 15 MB.",
    },
    beneficial_owner_1_selfie: {
      defaultValue: null,
      required: true,
      type: "object",
      label: "Selfie",
      associatedFirm: ["trust", "partnership_firm", "private_limited_company"],
      note: "Ensure the client's face is clearly visible in the picture. (Upload image in JPG/JPEG/PNG format. Max. file size allowed is 15 MB).",
      requiredFileTypes: ["image/jpeg", "image/png", "image/jpg"],
      acceptedFormat: ".jpg, .jpeg, .png",
    },

    beneficial_owner_2_pan: {
      defaultValue: null,
      required: false,
      type: "object",
      label: "PAN",
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
      note: "Upload image in JPG/JPEG/PNG/PDF format. Max. file size allowed is 15 MB.",
    },
    beneficial_owner_2_address_proof: {
      defaultValue: null,
      required: false,
      type: "object",
      label: "Address Proof",
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
      note: "Upload image in JPG/JPEG/PNG/PDF format. Max. file size allowed is 15 MB.",
    },
    beneficial_owner_2_selfie: {
      defaultValue: null,
      required: false,
      type: "object",
      label: "Selfie",
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
      note: "Ensure the client's face is clearly visible in the picture. (Upload image in JPG/JPEG/PNG format. Max. file size allowed is 15 MB).",
      requiredFileTypes: ["image/jpeg", "image/png", "image/jpg"],
      acceptedFormat: ".jpg, .jpeg, .png",
    },

    beneficial_owner_3_pan: {
      defaultValue: null,
      required: false,
      type: "object",
      label: "PAN",
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
      note: "Upload image in JPG/JPEG/PNG/PDF format. Max. file size allowed is 15 MB.",
    },
    beneficial_owner_3_address_proof: {
      defaultValue: null,
      required: false,
      type: "object",
      label: "Address Proof",
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
      note: "Upload image in JPG/JPEG/PNG/PDF format. Max. file size allowed is 15 MB.",
    },
    beneficial_owner_3_selfie: {
      defaultValue: null,
      required: false,
      type: "object",
      label: "Selfie",
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
      note: "Ensure the client's face is clearly visible in the picture. (Upload image in JPG/JPEG/PNG format. Max. file size allowed is 15 MB).",
      requiredFileTypes: ["image/jpeg", "image/png", "image/jpg"],
      acceptedFormat: ".jpg, .jpeg, .png",
    },

    beneficial_owner_4_pan: {
      defaultValue: null,
      required: false,
      type: "object",
      label: "PAN",
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
      note: "Upload image in JPG/JPEG/PNG/PDF format. Max. file size allowed is 15 MB.",
    },
    beneficial_owner_4_address_proof: {
      defaultValue: null,
      required: false,
      type: "object",
      label: "Address Proof",
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
      note: "Upload image in JPG/JPEG/PNG/PDF format. Max. file size allowed is 15 MB.",
    },
    beneficial_owner_4_selfie: {
      defaultValue: null,
      required: false,
      type: "object",
      label: "Selfie",
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
      note: "Ensure the client's face is clearly visible in the picture. (Upload image in JPG/JPEG/PNG format. Max. file size allowed is 15 MB).",
      requiredFileTypes: ["image/jpeg", "image/png", "image/jpg"],
      acceptedFormat: ".jpg, .jpeg, .png",
    },

    beneficial_owner_5_pan: {
      defaultValue: null,
      required: false,
      type: "object",
      label: "PAN",
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
      note: "Upload image in JPG/JPEG/PNG/PDF format. Max. file size allowed is 15 MB.",
    },
    beneficial_owner_5_address_proof: {
      defaultValue: null,
      required: false,
      type: "object",
      label: "Address Proof",
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
      note: "Upload image in JPG/JPEG/PNG/PDF format. Max. file size allowed is 15 MB.",
    },
    beneficial_owner_5_selfie: {
      defaultValue: null,
      required: false,
      type: "object",
      label: "Selfie",
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
      note: "Ensure the client's face is clearly visible in the picture. (Upload image in JPG/JPEG/PNG format. Max. file size allowed is 15 MB).",
      requiredFileTypes: ["image/jpeg", "image/png", "image/jpg"],
      acceptedFormat: ".jpg, .jpeg, .png",
    },

    beneficial_owner_6_pan: {
      defaultValue: null,
      required: false,
      type: "object",
      label: "PAN",
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
      note: "Upload image in JPG/JPEG/PNG/PDF format. Max. file size allowed is 15 MB.",
    },
    beneficial_owner_6_address_proof: {
      defaultValue: null,
      required: false,
      type: "object",
      label: "Address Proof",
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
      note: "Upload image in JPG/JPEG/PNG/PDF format. Max. file size allowed is 15 MB.",
    },
    beneficial_owner_6_selfie: {
      defaultValue: null,
      required: false,
      type: "object",
      label: "Selfie",
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
      note: "Ensure the client's face is clearly visible in the picture. (Upload image in JPG/JPEG/PNG format. Max. file size allowed is 15 MB).",
      requiredFileTypes: ["image/jpeg", "image/png", "image/jpg"],
      acceptedFormat: ".jpg, .jpeg, .png",
    },

    huf_deed: {
      defaultValue: null,
      required: true,
      type: "object",
      label: "HUF Deed",
      associatedFirm: ["huf"],
    },
    power_of_attorney: {
      defaultValue: null,
      required: false,
      type: "object",
      label: "Power of Attorney",
      associatedFirm: ["huf"],
    },
    affidavit_for_huf: {
      defaultValue: null,
      required: false,
      type: "object",
      label: "Affidavit For HUF",
      associatedFirm: ["huf"],
    },
    registration_certificate: {
      defaultValue: null,
      required: true,
      type: "object",
      label: "Registration Certificate",
      associatedFirm: ["trust", "partnership_firm"],
    },
    trust_deed: {
      defaultValue: null,
      required: true,
      type: "object",
      label: "Trust Deed",
      associatedFirm: ["trust"],
    },
    partnership_deed_and_resolution: {
      defaultValue: null,
      required: true,
      type: "object",
      label: "Partnership Deed",
      associatedFirm: ["partnership_firm"],
    },
    list_of_beneficiaries: {
      defaultValue: null,
      required: true,
      type: "object",
      label: {
        trust:
          "List of Beneficiaries, Trustees, Settlor & Authors of the Trust",
        private_limited_company:
          "List of relevant persons holding senior management positions",
      },
      associatedFirm: ["trust", "private_limited_company"],
    },
    beneficial_ownership_document: {
      defaultValue: null,
      required: true,
      type: "object",
      label: "Beneficial Ownership Document",
      associatedFirm: ["trust", "partnership_firm", "private_limited_company"],
      template: "Download Template",
      template_link:
        "https://docs.google.com/document/d/1NJzMQDSlO0VAd5y_VUAhv1IFAMBB_O461qZfOOC716A/edit",
    },

    certificate_of_incorporation: {
      defaultValue: null,
      required: true,
      type: "object",
      label: "Certificate of Incorporation",
      associatedFirm: ["private_limited_company"],
    },

    memorandum_and_articales_of_association: {
      defaultValue: null,
      required: true,
      type: "object",
      label: "Memorandum & Articles of Association",
      associatedFirm: ["private_limited_company"],
    },
    board_resolution: {
      defaultValue: null,
      required: true,
      type: "object",
      label: "Board Resolution",
      associatedFirm: ["private_limited_company"],
      template: "Download Template",
      template_link:
        "https://docs.google.com/document/d/16OMYBygI2QCZKccG-E9PLxN7U8VO27mt/edit",
    },
  },

  /**
   * Proprietor details for add client
   */
  clientProprietorDetails: {
    authoriser_pan: {
      defaultValue: null,
      required: true,
      type: "text",
      associatedFirm: [
        "huf",
        "private_limited_company",
        "trust",
        "partnership_firm",
      ],
    },

    authoriser_pan_res: {
      defaultValue: null,
      required: true,
      type: "object",
      associatedFirm: [],
    },

    authoriser_pan_name: {
      defaultValue: null,
      required: true,
      type: "text",
      associatedFirm: [],
    },

    is_pan_consent_accepted: {
      defaultValue: null,
      required: true,
      type: "boolean",
      associatedFirm: [
        "huf",
        "private_limited_company",
        "trust",
        "partnership_firm",
      ],
    },

    authoriser_dob: {
      defaultValue: null,
      required: true,
      type: "date",
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "private_limited_company",
        "trust",
        "partnership_firm",
      ],
    },
    authoriser_selfie: {
      defaultValue: null,
      required: true,
      type: "object",
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "private_limited_company",
        "trust",
        "partnership_firm",
      ],
    },

    authoriser_registered_address: {
      defaultValue: null,
      required: true,
      type: "text",
      label: "Address",
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
    },

    authoriser_current_locality: {
      defaultValue: null,
      required: true,
      type: "text",
      label: "Locality",
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
    },
    authoriser_current_city: {
      defaultValue: null,
      required: true,
      type: "text",
      label: "City",
      specialCharCheck: true,
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
    },
    authoriser_current_state: {
      defaultValue: null,
      required: true,
      type: "text",
      label: "State",
      specialCharCheck: true,
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
    },
    authoriser_current_pin: {
      defaultValue: null,
      required: true,
      type: "number",
      label: "Pincode",
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
    },

    authoriser_current_address_flag: {
      defaultValue: true,
      required: false,
      type: "boolean",
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
    },

    authoriser_permanent_address: {
      defaultValue: null,
      required: false,
      type: "text",
      label: "Address",
      requiredCheck: true,
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
    },

    authoriser_permanent_locality: {
      defaultValue: null,
      required: false,
      type: "text",
      label: "Locality",
      requiredCheck: true,
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
    },
    authoriser_permanent_city: {
      defaultValue: null,
      required: false,
      type: "text",
      label: "City",
      requiredCheck: true,
      specialCharCheck: true,
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
    },

    authoriser_permanent_state: {
      defaultValue: null,
      required: false,
      type: "text",
      label: "State",
      requiredCheck: true,
      specialCharCheck: true,
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
    },

    authoriser_permanent_pin: {
      defaultValue: null,
      required: false,
      type: "number",
      label: "Pincode",
      requiredCheck: true,
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "trust",
        "partnership_firm",
        "private_limited_company",
      ],
    },

    authoriser_address_type: {
      defaultValue: null,
      required: true,
      type: "number",
      label: "Current Address Proof",
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "private_limited_company",
        "trust",
        "partnership_firm",
      ],
    },
    authoriser_address_front_image: {
      defaultValue: null,
      required: true,
      type: "text",
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "private_limited_company",
        "trust",
        "partnership_firm",
      ],
    },
    authoriser_address_back_image: {
      defaultValue: null,
      required: false,
      type: "text",
      associatedFirm: [],
    },

    authoriser_gender: {
      defaultValue: null,
      required: true,
      type: "text",
      associatedFirm: [
        "sole_proprietorship",
        "huf",
        "private_limited_company",
        "trust",
        "partnership_firm",
      ],
    },
  },

  orgDocumentState: {
    copy_of_amfi_certificate: {
      defaultValue: null,
      required: false,
      type: "text",
      label: "Copy of AMFI Certificate",
      associatedFirm: [],
    },
    copy_of_arn_card: {
      defaultValue: null,
      required: false,
      type: "text",
      label: "Copy of ARN Card",
      associatedFirm: [],
    },
    memorandom_and_article_of_assosiation: {
      defaultValue: null,
      required: false,
      type: "text",
      label: "Memorandum of Association",
      associatedFirm: ["Private Limited Company", "Public Limited Company"],
    },
    article_of_association: {
      defaultValue: null,
      required: false,
      type: "text",
      label: "Articles of Association",
      associatedFirm: ["Private Limited Company", "Public Limited Company"],
    },
    autherised_signatory_list: {
      defaultValue: null,
      required: false,
      type: "text",
      label: "Autherised Signatory List",
      associatedFirm: [],
    },
    partnership_deed_and_resolution: {
      defaultValue: false,
      required: false,
      type: "text",
      label: "Partnership Deed and Resolution",
      associatedFirm: ["Partnerships Company", "Limited Liability Partnership"],
    },
    deeds_of_declaration: {
      defaultValue: false,
      required: false,
      type: "text",
      label: "Deeds of Declaration",
      associatedFirm: ["HUF"],
    },
    kyd_acknowledgement: {
      defaultValue: false,
      required: false,
      type: "text",
      label: "KYD Acknowledgement",
      associatedFirm: [],
    },
    trade_license: {
      defaultValue: false,
      required: false,
      type: "text",
      label: "Trade License",
      associatedFirm: ["Sole Proprietorship"],
    },
    other_document: {
      defaultValue: false,
      required: false,
      type: "text",
      label: "Other Supporting Documents",
      associatedFirm: [
        "Individual",
        "HUF",
        "Private Limited Company",
        "Public Limited Company",
        "Partnerships Company",
        "Limited Liability Partnership",
        "Sole Proprietorship",
      ],
    },
  },

  /**
   * Org Document State
   */
  clientEntityDetailsorgDocumentState: {
    copy_of_amfi_certificate: {
      defaultValue: null,
      required: false,
      type: "text",
      label: "Copy of AMFI Certificate",
      associatedFirm: [],
    },
    copy_of_arn_card: {
      defaultValue: null,
      required: false,
      type: "text",
      label: "Copy of ARN Card",
      associatedFirm: [],
    },
    memorandom_and_article_of_assosiation: {
      defaultValue: null,
      required: false,
      type: "text",
      label: "Memorandum of Association",
      associatedFirm: ["Private Limited Company", "Public Limited Company"],
    },
    article_of_association: {
      defaultValue: null,
      required: false,
      type: "text",
      label: "Articles of Association",
      associatedFirm: ["Private Limited Company", "Public Limited Company"],
    },
    autherised_signatory_list: {
      defaultValue: null,
      required: false,
      type: "text",
      label: "Autherised Signatory List",
      associatedFirm: [],
    },
    partnership_deed_and_resolution: {
      defaultValue: false,
      required: false,
      type: "text",
      label: "Partnership Deed and Resolution",
      associatedFirm: ["Partnerships Company", "Limited Liability Partnership"],
    },
    deeds_of_declaration: {
      defaultValue: false,
      required: false,
      type: "text",
      label: "Deeds of Declaration",
      associatedFirm: ["HUF"],
    },
    kyd_acknowledgement: {
      defaultValue: false,
      required: false,
      type: "text",
      label: "KYD Acknowledgement",
      associatedFirm: [],
    },
    trade_license: {
      defaultValue: false,
      required: false,
      type: "text",
      label: "Trade License",
      associatedFirm: ["Sole Proprietorship"],
    },
    other_document: {
      defaultValue: false,
      required: false,
      type: "text",
      label: "Other Supporting Documents",
      associatedFirm: [
        "Individual",
        "HUF",
        "Private Limited Company",
        "Public Limited Company",
        "Partnerships Company",
        "Limited Liability Partnership",
        "Sole Proprietorship",
      ],
    },
  },

  bankVerifyState: {
    account_number: {
      defaultValue: null,
      required: true,
      type: "number",
    },
    account_holder_name: {
      defaultValue: null,
      required: false,
      type: "text",
    },
    ifsc_code: {
      defaultValue: null,
      required: true,
      type: "text",
    },
    agreement: {
      defaultValue: null,
      required: false,
      type: "text",
    },
    is_penny_drop_success: {
      defaultValue: false,
      required: false,
      type: "text",
    },
    is_term_and_condition_accepted: {
      defaultValue: false,
      required: false,
      type: "boolean",
    },
    is_agreement_accepted: {
      defaultValue: false,
      required: false,
      type: "boolean",
    },
    cancel_cheque_image: {
      defaultValue: null,
      required: false,
      type: "string",
    },
  },
};
