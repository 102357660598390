import React, { useEffect, useId, useState } from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { Auth, Storage } from "aws-amplify";
import Loader from "react-loader-spinner";
import "./addClientKycV2.css";
import successTick from "../../assets/images/checkMark.svg";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  AddClientKycBasicDetails,
  PrePanValidationV2,
  AddClientKycBankDetails,
  AddClientKycNomineeDetails,
  AddClientKycDocumentsDetails,
  AddClientEntityDetails,
  AddClientProprietorDetails,
} from ".";
import {
  SampleToast,
  ClientOTPModelV2,
  PanValidationFailed,
  FullScreenLoader,
  ContentLoadingLoader,
  ConsentRequestModel,
} from "../../components";
import {
  errorToast,
  successToast,
  checkForEntityRequiredDetails,
  checkForNomineeDetails,
  checkForEntityRequiredDetailsForErrorState,
  checkForKycDocuments,
  checkForKycDocumentsForErrorState,
  checkValidIfsc,
  checkValidPan,
  checkValidEmail,
  checkValidMobileNumber,
  checkForNomineeDetailsForErrorState,
  getAge,
  yearsBefore,
  checkPermanentAddressDocuments,
  getBeneficialAddressErrorState,
  getErrorStateForAddClient,
} from "../../utils/helper";
import {
  clientBankDetails,
  clientEntityDetails,
  clientProprietorDetails,
} from "../../constant/signupState";
import { KYC_API_V2 } from "../../services/api";
import { eventLog } from "../../utils/firebase";

const AddClientKycV2 = (props) => {
  const navigate = useHistory();
  const param = useLocation();
  const api = new KYC_API_V2();
  const [prePanValidation, setPrePanValidation] = useState(0); // 0 - initiatial, 1 - passed , 2 - failed
  const [prePanValidateData, setPrePanValidateData] = useState("");
  const [investorType, setInvestorType] = useState("");
  const [clientRegistration, setClientRegistration] = useState(false);
  const [basicKycDetails, setBasicKycDetails] = useState("");
  const [ckycFailed, setCkycFailed] = useState(false);
  const [addClientKycDocumentsDetails, setAddClientKycDocumentsDetails] =
    useState(""); //object for client kyc documents
  const [ifaCognitoId, setIfaCognitoId] = useState("");
  const [bankDetails, setBankDetails] = useState("");
  const [skippedNominee, setSkippedNominee] = useState(false);
  const [nomineeDetails, setNomineeDetails] = useState("");
  const [entityDocument, setEntityDocument] = useState("");
  const [proprietorDetails, setProprietorDetails] = useState("");
  const [errorState, setErrorState] = useState();
  const [clientConsentModel, setClientConsentModel] = useState(false);
  const [clientConsentModelLoader, setClientConsentModelLoader] =
    useState(false);
  const [startDate, setStartDate] = useState("");
  const [maxDate, setMaxDate] = useState("");
  const [currDate, setCurrDate] = useState("");
  const [clientOTP, setClientOTP] = useState(""); //state to save otp
  const [loader, setLoader] = useState(false);
  const [entityDetails, setEntityDetails] = useState(""); // main object for entity details
  const [addClientSuccess, setAddClientSuccess] = useState(false); //for success screen
  const [autoApproved, setAutoApproved] = useState(false); // if user is ckyc verified and bank details verified
  const [userData, setUserData] = useState("");
  const [render, setRender] = useState(false);
  const [totalDisable, setTotalDisable] = useState(false);
  const [showBasicDetails, setShowBasicDetails] = useState(true);
  const [showBankDetails, setShowBankDetails] = useState(true);
  const [showKycDetails, setShowKycDetails] = useState(false);
  const [showEntityDetails, setShowEntityDetails] = useState(true);
  const [showAuthoriserDetails, setShowAuthoriserDetails] = useState(true);
  const [rejectionFlow, setRejectionFlow] = useState(false);
  const [panVerified,setPanVerified]=useState(true);
  const [finalSubmitFailed, setFinalSubmitFailed] = useState(false);
  //main object to save data
  const [documents, setDocuments] = useState({
    kyc_documents: null,
    bank_details: null,
  });

  const [userID, setUserId] = useState("");

  //get user id
  // useEffect(() => {
  //   setUserId(basicKycDetails?.user_id);
  // }, [basicKycDetails]);

  //add header and side bar
  useEffect(() => {
    props.showCommonHeader(true);
    props.showCommonSidebar(true);
  });

  // get cognito id
  useEffect(() => {
    let getCredentials = async () => {
      const user = await Auth.currentCredentials();
      setIfaCognitoId(user.identityId);
    };
    return getCredentials();
  }, []);

  //for dob
  useEffect(() => {
    const { cDate, sDate, mDate } = yearsBefore(100);
    setCurrDate(cDate);
    setStartDate(sDate);
    setMaxDate(mDate);
  }, []);

  // incomplete flow
  useEffect(() => {
    if (param && param.state && param.state.uuid) {
      setPrePanValidation(1);
      fetchClientData(param.state.uuid);
    } else {
      setRender(true);
    }
  }, []);

  const fetchClientData = (uuid) => {
    api
      .fetchUserDetails(uuid)
      .then((res) => {
        if (res.kind === "ok") {
          setUserData(res.data);
          setUserId(res.data.user_id);
          setRender(true);
        } else {
          errorToast(res.message);
          navigate.push("/clients");
        }
      })
      .catch((error) => {
        errorToast(
          "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
        );
        setRender(true);
      });
  };

  useEffect(() => {
    if (userData !== "" && userData.kyc_status === 3) {
      setInvestorType(userData.investor_type);
      setRejectionFlow(true);
      setSkippedNominee(true);
      if (userData.has_profile) {
        setClientRegistration(true);
        setShowBasicDetails(false);
      }
      if (!userData.ckyc) {
        setCkycFailed(true);
      }
      if (userData.has_bank) {
        setShowBankDetails(false);
      }
      if (
        !userData.has_document &&
        userData.investor_type === "Individual" &&
        clientRegistration &&
        !userData.ckyc
      ) {
        setShowKycDetails(true);
      }
      if (userData.has_document && userData.investor_type !== "Individual") {
        setShowEntityDetails(false);
      }
      if (userData.has_authoriser) {
        setShowAuthoriserDetails(false);
      }
    } else if (userData !== "") {
      setInvestorType(userData.investor_type);
      if (userData.has_profile) {
        setClientRegistration(true);
      }
      if (!userData.ckyc) {
        setCkycFailed(true);
      }

      if (!userData.ckyc && userData.investor_type == "Individual") {
        setShowKycDetails(true);
      }

      if (userData.tc_status != 0) {
        setTotalDisable(true);
        if (!userData.has_nominee) {
          setSkippedNominee(true);
        }
      }
    } else if (userData === "" && ckycFailed) {
      setShowKycDetails(true);
    }
  }, [userData, clientRegistration, ckycFailed]);

  //update main document from each component
  useEffect(() => {
    setDocuments({
      ...documents,
      client_details: basicKycDetails,
      kyc_documents: addClientKycDocumentsDetails,
      bank_details: bankDetails,
      nominee_details: nomineeDetails,
    });
    if (
      basicKycDetails.investor_type === "Individual" &&
      bankDetails.bank_verify
    ) {
      if (userData === "" && !ckycFailed) {
        setAutoApproved(true);
      } else if (userData !== "" && userData.ckyc) {
        setAutoApproved(true);
      }
    }
  }, [
    basicKycDetails,
    bankDetails,
    nomineeDetails,
    addClientKycDocumentsDetails,
    userData,
  ]);

  // set investor Category
  useEffect(() => {
    setInvestorType(basicKycDetails.investor_type);
  }, [basicKycDetails["investor_type"]]);

  useEffect(() => {
    setEntityDetails({
      ...entityDetails,
      ...entityDocument,
      ...proprietorDetails,
    });
  }, [entityDocument, proprietorDetails, errorState]);

  //handle on submit
  const onSubmit = async (e) => {
    setLoader(true);

    let aggregateData = {
      ...documents.client_details,
      ...documents.bank_details,
      ...documents.kyc_documents,
      ...documents.nominee_details,
      ...entityDetails,
      ...proprietorDetails,
    };

        //error handler
    let errorState = getErrorStateForAddClient(
      aggregateData,
      investorType,
      ckycFailed
    );
    //error nomine_errors handler
    let nominee_errors = {};

    if (investorType == "Individual" || investorType == "sole_proprietorship") {
      nominee_errors = checkForNomineeDetailsForErrorState(nomineeDetails);
    }

    if (
      errorState &&
      errorState.hasOwnProperty("bank_verify") &&
      errorState.bank_verify
    ) {
            errorToast(
        "Please provide all the required information to proceed.",
        true
      );
      setErrorState({
        ...errorState,
        ...nominee_errors,
      });
      setLoader(false);
      return;
    }

    if (errorState && Object.keys(errorState).length > 0) {
      setErrorState({
        ...errorState,
        ...nominee_errors,
      });
      errorToast(
        "Please provide all the required information to proceed.",
        true
      );
      setLoader(false);
      console.log("errorrrr=----->", errorState);
      return;
    }

    //check for entity details
    if (investorType != "Individual") {
      if (
        investorType != "sole_proprietorship" &&
        (!checkValidPan(proprietorDetails.authoriser_pan) ||
          proprietorDetails.authoriser_pan_name == null)
      ) {
        errorToast("Please validate or enter appropriate PAN", true);
        setLoader(false);
        return;
      } else if (
        getAge(proprietorDetails?.authoriser_dob) > getAge(startDate)
      ) {
        errorToast("Invalid Date of Birth.", true);
        setLoader(false);
        return;
      }
    }

    //check for nominee
    if (
      (investorType == "sole_proprietorship" ||
        investorType === "Individual") &&
      skippedNominee == false
    ) {
      if (nominee_errors && Object.keys(nominee_errors).length > 0) {
        //setError
        setErrorState(nominee_errors);
        errorToast(
          "Please provide all the required information to proceed.",
          true
        );
        setLoader(false);
        return;
      }
    }

    //call client save api
    let payload = {
      ...documents.client_details,
      ...documents.bank_details,
      ...documents.kyc_documents,
      ...documents.nominee_details,
    };

    if (
      investorType == "private_limited_company" ||
      investorType == "public_limited_company"
    ) {
      payload["investor_type"] = documents.client_details["company_type"];
    }

    if (rejectionFlow) {
      payload = {
        ...payload,
        rejection_flow: true,
        has_profile: userData.has_profile,
        has_document: userData.has_document,
        has_bank: userData.has_bank,
      };
    }
    if (!payload.hasOwnProperty("user_id")) {
      payload.user_id = userID;
    }
    let final_submit_fail = false;
    // call the client save api
    await api.clientSave(payload).then(async (res) => {
      if (res.kind == "ok") {
        if (investorType === "Individual" && rejectionFlow) {
          successToast("Documents submitted successfully");
          setAddClientSuccess(true);
        }
        if (investorType !== "Individual") {
          //call the entity doc upload
          let payloadForEntityDoc = {
            ...entityDetails,
            investor_type: investorType,
            user_id: basicKycDetails?.user_id ?? userID,
          };
          if (payload["investor_type"] == "Public Limited") {
            payloadForEntityDoc["investor_type"] = "public_limited_company";
          } else if (payload["investor_type"] == "Private Limited") {
            payloadForEntityDoc["investor_type"] = "private_limited_company";
          }

          if (rejectionFlow) {
            payloadForEntityDoc = {
              ...payloadForEntityDoc,
              rejection_flow: true,
              has_entity: userData.has_entity,
              has_document: userData.has_document,
            };
          }

          try {
            await api.saveEntityDetails(payloadForEntityDoc).then((res) => {
              if (res.kind == "ok") {
                eventLog({
                  type: "entity_documents_saved",
                  action: {
                    trigger: "true",
                  },
                });
                if (rejectionFlow) {
                  successToast("Documents submitted successfully");
                  setAddClientSuccess(true);
                }
              } else {
                errorToast(res.error, true);
                setFinalSubmitFailed(true);
                final_submit_fail = true;
                setLoader(false);
              }
            });
          } catch (error) {
            setFinalSubmitFailed(true);
            final_submit_fail = true;
            setLoader(false);
            console.log("ERROR ::::", error);
          }
        }

        if (!rejectionFlow && final_submit_fail == false) {
          //payload for terms and condition api
          let payloadForTermsConditionMail = {
            email: payload.email,
            mobile: payload.mobile,
            name: payload.pan_name,
            nominee_obj:
              !skippedNominee && nomineeDetails ? nomineeDetails : null,
          };

          //call terms and condition api
          await api
            .sentTermsAndCondtionMail(payloadForTermsConditionMail)
            .then((resp) => {
              setTimeout(() => {
                eventLog({
                  type: "send_tnc_mail",
                  action: {
                    trigger: "true",
                  },
                });
                setClientConsentModel(true);
                setLoader(false);
              }, 2000);
            });
        }
      } else {
        errorToast(res.error, true);
        setLoader(false);
      }
    });
  };

  // verify otp
  const verifyOTP = async () => {
    // payload to verify otp
    let payload = {
      otp: clientOTP,
      ifa_id: ifaCognitoId,
      pan: String(basicKycDetails.pan).toUpperCase(),
      mobile: basicKycDetails.mobile,
      email: basicKycDetails.email,
      mode: "ifa",
    };

    await api.verifyClientConsent(payload).then((res) => {
      if (res.kind === "ok") {
        eventLog({
          type: "otp_verify",
          action: {
            trigger: "true",
          },
        });
        // if  success
        successToast(res.message);
        //hide otp model
        setClientConsentModel(false);
        //show success screen
        setAddClientSuccess(true);
      }
    });
  };

  const setUseridValue = (user_id) => {
    setUserId(user_id);
  };

  return (
    <div>
      {!render ? (
        <ContentLoadingLoader />
      ) : (
        <div className="add_clientkycv2_div_container">
          {/* add client over all screens*/}
          {!addClientSuccess ? (
            <div className="add_clientkycv2_main_div">
              <div className="add_clientkycv2_top_container">
                <div className="add_clientkycv2_top_row_container">
                  {/* back icon */}
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <KeyboardBackspaceIcon
                      style={{
                        fontSize: "20px",
                        color: "#414141",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        navigate.goBack();
                      }}
                    />

                    {/* back text */}
                    <p
                      className="add_clientkycv2_top_row_back"
                      onClick={() => {
                        navigate.goBack();
                      }}
                    >
                      Back
                    </p>
                  </div>

                  <h4 className="add_clientkycv2_main_container_main_title">
                    New Client KYC
                  </h4>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  alignSelf: "center",
                }}
              >
                <div className="add_clientkycv2_main_conatiner">
                  {/* pre pan nsdl validation */}
                  {prePanValidation == 0 ? (
                    <PrePanValidationV2
                      setPrePanValidation={setPrePanValidation}
                      setPreValidateData={setPrePanValidateData}
                    />
                  ) : null}

                  {/* pre pan validation failed */}
                  {prePanValidation == 2 ? (
                    <PanValidationFailed />
                  ) : (
                    <>
                      {/* basic details */}
                      {prePanValidation == 1 ? (
                        <AddClientKycBasicDetails
                          setPreValidateData={setPrePanValidateData}
                          prePanData={prePanValidateData}
                          setBasicKycDetails={setBasicKycDetails}
                          setCkycFailed={setCkycFailed}
                          setClientRegistration={setClientRegistration}
                          userData={userData}
                          setUseridValue={setUseridValue}
                          visible={showBasicDetails}
                          rejectionFlow={rejectionFlow}
                          setAddClientSuccess={setAddClientSuccess}
                          setPanVerified={setPanVerified}
                        />
                      ) : null}

                      {/* kyc documents */}
                      {ckycFailed && investorType == "Individual" ? (
                        <AddClientKycDocumentsDetails
                          basicKycDetails={basicKycDetails}
                          setAddClientKycDocumentsDetails={
                            setAddClientKycDocumentsDetails
                          }
                          errorState={errorState}
                          investorType={investorType}
                          setErrorState={setErrorState}
                          userData={userData}
                          totalDisable={totalDisable}
                          visible={showKycDetails}
                          userID={userID}
                          panVerified={panVerified}
                        />
                      ) : null}

                      {/* entiy details and documents */}
                      {clientRegistration && investorType != "Individual" ? (
                        <AddClientEntityDetails
                          setEntityDocument={setEntityDocument}
                          errorState={errorState}
                          investorType={investorType}
                          setErrorState={setErrorState}
                          userData={userData}
                          totalDisable={totalDisable}
                          visible={showEntityDetails}
                          userID={userID}
                        />
                      ) : null}

                      {/* bank details */}
                      {clientRegistration ? (
                        <AddClientKycBankDetails
                          basicKycDetails={basicKycDetails}
                          bankDetails={bankDetails}
                          setBankDetails={setBankDetails}
                          investorType={investorType}
                          errorState={errorState}
                          setErrorState={setErrorState}
                          userData={userData}
                          totalDisable={totalDisable}
                          visible={showBankDetails}
                          userID={userID}
                          setUserData={setUserData}
                        />
                      ) : null}

                      {/* Proprietor details || Authorised signatory details*/}
                      {clientRegistration && investorType != "Individual" ? (
                        <AddClientProprietorDetails
                          basicKycDetails={basicKycDetails}
                          userData={userData}
                          setProprietorDetails={setProprietorDetails}
                          investorType={investorType}
                          errorState={errorState}
                          setErrorState={setErrorState}
                          setPrePanValidation={setPrePanValidation}
                          totalDisable={totalDisable}
                          visible={showAuthoriserDetails}
                          userID={userID}
                        />
                      ) : null}

                      {/* nominee details */}
                      {clientRegistration &&
                      (investorType == "sole_proprietorship" ||
                        investorType === "Individual") ? (
                        <AddClientKycNomineeDetails
                          skippedNominee={skippedNominee}
                          setNomineeDetails={setNomineeDetails}
                          setSkippedNominee={setSkippedNominee}
                          errorState={errorState}
                          setErrorState={setErrorState}
                          userData={userData}
                          totalDisable={totalDisable}
                          userID={userID}
                        />
                      ) : null}

                      {/* main submit button */}
                      {clientRegistration &&
                      (userData != ""
                        ? userData.kyc_status === 3 || userData.tc_status === 0
                          ? true
                          : false
                        : true) ? (
                        <div
                          className="add_client_kyc_main_submit_cta"
                          style={{
                            pointerEvents: finalSubmitFailed ? "none" : "",
                          }}
                          onClick={(e) => {
                            onSubmit(e);
                          }}
                        >
                          {loader ? (
                            <Loader
                              type="TailSpin"
                              color="#FFFFFF"
                              height={25}
                              width={25}
                            />
                          ) : (
                            <p className="add_client_kyc_basic_details_cta_text">
                              Submit
                            </p>
                          )}
                        </div>
                      ) : null}
                    </>
                  )}
                </div>
              </div>
            </div>
          ) : null}

          {/* success screen */}
          {addClientSuccess ? (
            <div className="add_client_v2_success_screen">
              <div className="add_client_v2_success_screen_main">
                <div className="add_client_success_screen_container">
                  <img src={successTick} alt="Success" className="check_mark" />
                  <p className="add_client_success_screen_text">
                    Client details submitted successfully.
                  </p>
                  {!autoApproved ? (
                    <div className="add_client_success_screen_v2">
                      <p className="add_client_success_screen_subtext_text">
                        Thank you for sharing the client documents. Our team
                        will review them and get back on the next steps within 1
                        business day.
                      </p>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          ) : null}

          {/* toast container */}
          <SampleToast />

          {/* client consent model */}
          <ClientOTPModelV2
            openModel={clientConsentModel}
            setOtp={(value) => setClientOTP(value)}
            loading={clientConsentModelLoader}
            headerTitle="OTP sent to client"
            inputPlaceHolder="Enter OTP"
            onSubmit={() => {
              setClientConsentModelLoader(true);
              verifyOTP();
            }}
            handleClose={() => {
              setClientConsentModel(false);
              navigate.push("/clients");
            }}
          />
        </div>
      )}
    </div>
  );
};

export default AddClientKycV2;
