import React, { useEffect, useState } from "react";
import { Auth, Storage } from "aws-amplify";
import "./clientkyc.css";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Client_API } from "../../services/api/Clients/clients-api";
import {
  successToast,
  errorToast,
  getFrontendStateObject,
  downloadBase64File,
} from "../../utils/helper";
import {
  ContentLoadingLoader,
  FullScreenLoader,
  InfoAlert,
  SampleToast,
  ClientOTPModel,
} from "../../components";
import {
  ClientKycBank,
  ClientKycDocument,
  ClientKycNominee,
  ClientKycProfile,
  ClientNetWorth,
  ClientPreKyc,
  InvestorTypeComponent,
  EntityKyc,
} from ".";
import { eventLog } from "../../utils/firebase";
import { investor_type } from "../../config/settings";
import { ContactsOutlined, Diversity1TwoTone } from "@mui/icons-material";

const { stage } = getFrontendStateObject();

// import HideIFA from "../HideIFA/hideIFA";

// const sampleData = {
//   profile_name: "Test Client",
//   profile_mobile: "6785768576",
//   profile_email: "testclient@gmail.com",
//   profile_father_name: "Test Father Name",
//   profile_permanent_address: "New Delhi",
//   profile_pincode: "123456",
//   profile_dob: "1999-02-24",
//   document_pan_number: "QWSAB1234X",
//   document_pan_image: "adsadhs.jpg",
//   document_address_front_image: "ds.jpg",
//   document_address_back_image: "ds.ds",
//   document_net_worth_image: "dasdasdas.jpg",
//   bank_account_number: "10633558657568935",
//   bank_account_holder_name: "Test Client",
//   bank_ifsc_code: "ISZH0232187",
//   bank_cancel_cheque: "ddasdass.jpg",
//   nominee_full_name: "Test Nominee",
//   nominee_relationship: "Brother",
//   nominee_dob: "",
//   nominee_pan_number: "",
//   nominee_phone_number: "",
//   nominee_email_id: "",
// };

const ClientKyc = (props) => {
  const navigate = useHistory();
  const param = useLocation();
  const api = new Client_API();
  const [isLogin, setIsLogin] = useState();
  const [loginRender, setLoginRender] = useState(false);
  const [errors, setErrors] = useState([]);
  const [preData, setPreData] = useState({});
  const [submit, setSubmit] = useState(false);
  const [showNetInfo, setShowNetInfo] = useState(false);
  const [render, setRender] = useState(false);
  const [panName, setPanName] = useState("");
  let document_net_worth = {};
  let raw_data = {
    profile: {},
    documents: {},
    bank_details: {},
    nominee: {},
    entity_documents: {},
    entity_flow: false,
    networth: {
      document_net_worth_image: "",
    },
  };
  const [dob, setDob] = useState("");
  const [isVerified, setIsVerified] = useState(false);

  const [panValidateFailed, setPanValidateFailed] = useState(false);
  const [addressFailed, setAddressFailed] = useState(false);
  const [disableNameDob, setDisableNameDob] = useState(false);
  const [name, setName] = useState("");
  const [preCheck, setPreCheck] = useState(
    param?.state?.uuid != "" ? false : true
  );
  const [pan, setPan] = useState("");
  const [mobile, setMobile] = useState("");
  const [showExistModel, setShowExistModel] = useState(false);
  const [userExistLoader, setUserExistLoader] = useState(false);
  const [clientConsentModel, setClientConsentModel] = useState(false);
  const [clientConsentModelLoader, setClientConsentModelLoader] =
    useState(false);
  const [clientOTP, setClientOTP] = useState("");
  const [flow, setFlow] = useState("");
  const [ifaCognitoId, setIfaCognitoId] = useState("");
  const [panVerified, setPanVerified] = useState(false);
  const [bankValidated, setBankValidated] = useState(false);
  const [aadhaarValidated, setAadhaarValidated] = useState(true);
  const [clientPreCheck, setClientPreCheck] = useState(false);
  const [investorType, setInvestorType] = useState("");
  const [entityType, setEntityType] = useState(false);
  const [entityDocuments, setEntityDocuments] = useState({});
  const [doNotFetchCkyc, setDoNotFetchCkyc] = useState(false);
  const [hideUserSelfie, setHideUserSelfie] = useState(true);
  const [ckycVerifiedFailed, setCkycVerifiedFailed] = useState(false);
  const [doNotCheckSelfie, setDoNotCheckSelfie] = useState(true);
  const [entityPan, setEntityPan] = useState("");
  const [entityFlow, setEntityFLow] = useState(false);
  const [entityPanName, setEntityPanName] = useState("");
  const [netWorthName, setNetWorthName] = useState("");
  const [isNetWorthDocumentFetching, setIsNetWorthDocumentFetching] =
    useState(false);
  // const [hideIFA, setHideIFA] = useState(false);
  // function handleWindowSizeChange() {
  //   if (window.innerWidth <= 1080 || window.innerHeight <= 600) {
  //     setHideIFA(true);
  //     props.showCommonHeader(false);
  //     props.showCommonSidebar(false);
  //   } else {
  //     setHideIFA(false);
  //     props.showCommonHeader(true);
  //     props.showCommonSidebar(true);
  //   }
  // }

  // useEffect(() => {
  //   window.addEventListener("resize", handleWindowSizeChange);
  //   if (window.innerWidth <= 1080 || window.innerHeight <= 600) {
  //     setHideIFA(true);
  //     props.showCommonHeader(false);
  //     props.showCommonSidebar(false);
  //   }
  //   return () => {
  //     window.removeEventListener("resize", handleWindowSizeChange);
  //   };
  // }, []);

  useEffect(() => {
    if (ckycVerifiedFailed == true) {
      setDoNotCheckSelfie(false);
    }
  }, [ckycVerifiedFailed]);
  // console.log(param.state.uuid , "uuid of user")

  //check if entity documents are there
  // useEffect(() => {
  //   console.log(typeof(entityDocuments))
  //   raw_data.entity_documents =  entityDocuments ? entityDocuments : {}
  // },[raw_data, entityDocuments])
  // check if user login or not
  useEffect(() => {
    props.showCommonHeader(true);
    props.showCommonSidebar(true);
    let checkLogin = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        let status = localStorage.getItem("kyc_status");
        if (status != 2) {
          setIsLogin(false);
        }
        else
        {
        setIsLogin(true);
        setLoginRender(true);
        if (param.state !== undefined) {
          if (param.state.uuid !== "") {
            fetchKycData();
          } else {
            // setPreData(sampleData);
            setRender(true);
          }
        } else {
          // setPreData(sampleData);
          setRender(true);
        }
      }
      } catch {
        setIsLogin(false);
      }
    };

    return checkLogin();
    // eslint-disable-next-line
  }, []);

  // get cognito id
  useEffect(() => {
    let getCredentials = async () => {
      const user = await Auth.currentCredentials();
      setIfaCognitoId(user.identityId);
    };
    return getCredentials();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setPanName(raw_data.documents.document_pan_name);
  }, [raw_data]);

  entityDocuments["entity_document_pan_name"] = entityPanName;
  entityDocuments["entity_document_pan_number"] = entityPan;

  useEffect(() => {
    raw_data.entity_documents = entityDocuments;
    raw_data.entity_flow = entityFlow;
  }, [raw_data, entityDocuments, entityFlow]);

  // if user is not login then redirect to login page
  if (isLogin === false) {
    return <Redirect to="/" />;
  }

  if (param.state === undefined && param.pathname === "/clients/networth") {
    return <Redirect to="/clients" />;
  }

  // fetch client kyc data
  const fetchKycData = () => {
    api.fetchClientKyc(param.state.uuid).then((res) => {
      if (res.kind === "ok") {
        setPreData(res.data);
        setNetWorthName(res.data.document_net_worth_image);
        setRender(true);
      } else {
        setTimeout(() => {
          setRender(true);
          errorToast(res.error);
        }, 2000);
      }
    });
  };

  const checkOcrData = () => {
    let ocrError = false;

    //check for individual
    if (doNotFetchCkyc == false) {
      if (raw_data.documents.document_pan_name == "pan_name") {
        ocrError = true;
        errorToast("Please provide valid Name");
      }

      if (raw_data.documents.document_address_number == "address_number") {
        ocrError = true;
        errorToast("Please provide valid Address Number");
      }

      if (raw_data.documents.document_data.father_name == "father_name") {
        ocrError = true;
        errorToast("Please provide valid Father Name");
      }

      if (raw_data.documents.document_data.address == "full_address") {
        ocrError = true;
        errorToast("Please provide valid Address");
      }

      // if no error found
      setTimeout(() => {
        if (!ocrError) {
          checkData();
        }
      }, 100);
    } else {
      raw_data.documents["entity_document_pan_number"] =
        entityDocuments?.entity_document_pan_number;

      setTimeout(() => {
        checkData();
      }, 100);
    }
  };

  console.log(errors);

  // check the insert kyc data
  const checkData = () => {
    // if pan number
    if (raw_data.documents.document_pan_number.length != 10) {
      if (pan.length == 10) {
        raw_data.documents.document_pan_number = pan;
      }
    }

    // if mobile number
    if (raw_data.profile.profile_mobile.length != 10) {
      if (mobile.length == 10) {
        raw_data.profile.profile_mobile = mobile;
      }
    }

    // upper case ifsc code
    raw_data.bank_details.bank_ifsc_code = String(
      raw_data.bank_details.bank_ifsc_code
    ).toUpperCase();

    raw_data.documents["document_pan_number"] = String(pan).toUpperCase();
    raw_data.documents["entity_document_pan_number"] =
      entityDocuments?.entity_document_pan_number
        ? entityDocuments?.entity_document_pan_number
        : entityPan;
    raw_data.documents["entity_document_pan_name"] =
      entityDocuments?.entity_document_pan_name
        ? entityDocuments?.entity_document_pan_name
        : entityPanName;
    raw_data.documents["document_pan_name"] =
      raw_data.documents.document_pan_name;

    // payload
    let payload_data = {
      ...raw_data.profile,
      ...raw_data.documents,
      ...raw_data.bank_details,
      ...raw_data.nominee,
      ...raw_data.networth,
      ...(raw_data.entity_documents = entityDocuments),
      mode: "create",
    };

    let key_name = Object.getOwnPropertyNames(payload_data);
    let arr = [];
    let found_err = false;

    // map errors array
    key_name.map((name) => {
      return (arr[name] = []);
    });

    // check the validation
    for (let key in payload_data) {
      if (payload_data.hasOwnProperty(key)) {
        // variable define
        let value = String(payload_data[key]);
        var number_format = /^[6-9][0-9]{9}$/;
        var mail_format =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var pan_format = /(^([a-zA-Z]{5})([0-9]{4})([a-zA-Z]{1})$)/;
        var today = new Date();
        var birthDate = new Date(value);
        var age = today.getFullYear() - birthDate.getFullYear();
        var mon = today.getMonth() - birthDate.getMonth();

        // check the case
        switch (key) {
          // case "profile_name":
          //   if (value.length > 255) {
          //     found_err = true;
          //     arr[key].push("Name cannot be greater than 255 characters");
          //   } else if (value.length < 1) {
          //     found_err = true;
          //     arr[key].push("Name cannot be less than 1 character");
          //   }
          //   break;
          case "profile_mobile":
            if (value.length == 0) {
              found_err = true;
              arr[key].push("Please enter mobile number");
            } else if (value.length !== 10 || !number_format.test(value)) {
              found_err = true;
              arr[key].push("Invalid mobile number");
            }
            break;
          case "profile_email":
            if (value.length == 0 || value == undefined) {
              found_err = true;
              arr[key].push("Please enter email");
            } else if (!mail_format.test(String(value).toLowerCase())) {
              found_err = true;
              arr[key].push("Invalid email");
            }
            break;
          // case "profile_father_name":
          //   if (value) {
          //     if (value.length > 255) {
          //       found_err = true;
          //       arr[key].push("Name cannot be greater than 255 characters");
          //     } else if (value.length < 1) {
          //       found_err = true;
          //       arr[key].push("Name cannot be less than 1 character");
          //     }
          //   }
          //   break;
          case "profile_dob":
            if (
              mon < 0 ||
              (mon === 0 && today.getDate() < birthDate.getDate())
            ) {
              age--;
            }
            if (age < 18) {
              found_err = true;
              arr[key].push("Invalid, age must be at least 18 years");
            } else if (value.length < 10) {
              found_err = true;
              arr[key].push("Please enter Date of Birth");
            }
            break;
          // case "profile_permanent_address":
          //   if (value) {
          //     if (value.length > 255) {
          //       found_err = true;
          //       arr[key].push("Address cannot be greater than 255 characters");
          //     } else if (value.length < 1) {
          //       found_err = true;
          //       arr[key].push("Address cannot be less than 1 character");
          //     }
          //   }
          //   break;
          // case "profile_pincode":
          //   if (value) {
          //     if (value.length !== 6) {
          //       found_err = true;
          //       arr[key].push("Invalid, pin code must be of 6 numbers");
          //     }
          //   }
          //   break;

          case "profile_gender":
            if (value == "" || value == undefined) {
              found_err = true;
              arr[key].push("Please select gender");
            }
            break;

          case "profile_selfie_image":
            if (!doNotCheckSelfie) {
              if (value) {
                if (value.length > 255) {
                  found_err = true;
                  arr[key].push(
                    "File Name cannot be greater than 255 characters"
                  );
                } else if (value.length < 1) {
                  found_err = true;
                  arr[key].push("Please upload the required document");
                }
              } else {
                found_err = true;
                arr[key].push("Please upload the required document");
              }
            }

            break;
          case "document_pan_number":
            if (value == "" || value == undefined) {
              found_err = true;
              arr[key].push("Please enter pan number");
            } else if (!doNotFetchCkyc && !pan_format.test(String(value))) {
              found_err = true;
              arr[key].push("Invalid PAN number");
            }
            break;
          case "document_pan_image":
            if (panValidateFailed) {
              if (value) {
                if (value.length > 255) {
                  found_err = true;
                  arr[key].push(
                    "File Name cannot be greater than 255 characters"
                  );
                } else if (value.length < 1) {
                  found_err = true;
                  arr[key].push("Please upload the required document");
                }
              } else {
                found_err = true;
                arr[key].push("Please upload the required document");
              }
            }
            break;
          case "document_address_type":
            if (raw_data.documents.ckyc != true) {
              if (value != 0) {
                if (value > 4) {
                  found_err = true;
                  arr[key].push("Invalid address type");
                }
              }
            }
            break;

          case "document_address_number":
            if (
              raw_data.documents.ckyc != true &&
              raw_data.documents.document_address_type == 4
            ) {
              if (value) {
                if (value.length > 30) {
                  found_err = true;
                  arr[key].push(
                    "Address number cannot be greater than 30 characters"
                  );
                } else if (value.length < 12) {
                  found_err = true;
                  arr[key].push("Invalid Address number");
                } else if (value == "address_number") {
                  found_err = true;
                  arr[key].push("Invalid Address number");
                } else if (!aadhaarValidated) {
                  found_err = true;
                }
              } else {
                found_err = true;
                arr[key].push("This field cannot be empty.");
              }
            }
            break;
          case "document_address_front_image":
            if (raw_data.documents.ckyc != true) {
              if (raw_data.documents.document_address_type == 4) {
                if (addressFailed) {
                  if (value) {
                    if (value.length > 255) {
                      found_err = true;
                      arr[key].push(
                        "File Name cannot be greater than 255 characters"
                      );
                    } else if (value.length < 1) {
                      found_err = true;
                      arr[key].push("Please upload the required document");
                    }
                  } else {
                    found_err = true;
                    arr[key].push("Please upload the required document");
                  }
                }
              } else {
                if (value) {
                  if (value.length > 255) {
                    found_err = true;
                    arr[key].push(
                      "File Name cannot be greater than 255 characters"
                    );
                  } else if (value.length < 1) {
                    found_err = true;
                    arr[key].push("Please upload the required document");
                  }
                } else {
                  found_err = true;
                  arr[key].push("Please upload the required document");
                }
              }
            }
            break;
          case "document_address_back_image":
            if (raw_data.documents.ckyc != true) {
              if (raw_data.documents.document_address_type == 4) {
                if (addressFailed) {
                  if (value) {
                    if (value.length > 255) {
                      found_err = true;
                      arr[key].push(
                        "File Name cannot be greater than 255 characters"
                      );
                    } else if (value.length < 1) {
                      found_err = true;
                      arr[key].push("Please upload the required document");
                    }
                  } else {
                    found_err = true;
                    arr[key].push("Please upload the required document");
                  }
                }
              } else {
                if (value) {
                  if (value.length > 255) {
                    found_err = true;
                    arr[key].push(
                      "File Name cannot be greater than 255 characters"
                    );
                  } else if (value.length < 1) {
                    found_err = true;
                    arr[key].push("Please upload the required document");
                  }
                } else if (raw_data.documents.document_address_type != 3) {
                  found_err = true;
                  arr[key].push("Please upload the required document");
                }
              }
            }
            break;
          // case "document_net_worth_image":
          //   if (value) {
          //     if (value.length > 255) {
          //       found_err = true;
          //       arr[key].push(
          //         "File Name cannot be greater than 255 characters"
          //       );
          //     } else if (value.length < 1) {
          //       found_err = true;
          //       arr[key].push("Please upload the required document");
          //     }
          //   }
          //   break;
          // case "bank_account_holder_name":
          //   if (value) {
          //     if (value.length > 255) {
          //       found_err = true;
          //       arr[key].push("Name cannot be greater than 255 characters");
          //     } else if (value.length < 1) {
          //       found_err = true;
          //       arr[key].push("Name cannot be less than 1 character");
          //     }
          //   }
          //   break;
          case "bank_account_number":
            if (value.length == 0 || value == undefined) {
              found_err = true;
              arr[key].push("Please enter account number");
            } else if (value.length < 9 || value.length > 18) {
              found_err = true;
              arr[key].push("Invalid account number");
            }
            break;
          case "bank_ifsc_code":
            if (value.length == 0 || value == undefined) {
              found_err = true;
              arr[key].push("Please enter ifsc code");
            } else if (value.length !== 11) {
              found_err = true;
              arr[key].push("Invalid IFSC. Please check and re-enter.");
            }
            break;
          case "bank_cancel_cheque":
            if (value) {
              if (value.length > 255) {
                found_err = true;
                arr[key].push(
                  "File Name cannot be greater than 255 characters"
                );
              } else if (value.length < 1) {
                found_err = true;
                arr[key].push("Please upload the required document");
              }
            } else {
              if (!isVerified) {
                found_err = true;
                arr[key].push("Please upload the required document");
              }
            }
            break;
          // case "nominee_full_name":
          //   if (value.length > 255) {
          //     found_err = true;
          //     arr[key].push("Name cannot be greater than 255 characters");
          //   } else if (value.length < 1) {
          //     found_err = true;
          //     arr[key].push("Name cannot be less than 1 character");
          //   }
          //   break;
          // case "nominee_relationship":
          //   if (value.length > 255) {
          //     found_err = true;
          //     arr[key].push(
          //       "Relationship cannot be greater than 255 characters"
          //     );
          //   } else if (value.length < 1) {
          //     found_err = true;
          //     arr[key].push("Relationship is required");
          //   }
          //   break;
          // case "nominee_phone_number":
          //   if (value) {
          //     if (value.length !== 10 || !number_format.test(value)) {
          //       found_err = true;
          //       arr[key].push("Invalid mobile number");
          //     }
          //   }
          //   break;
          // case "nominee_email_id":
          //   if (value) {
          //     if (!mail_format.test(String(value).toLowerCase())) {
          //       found_err = true;
          //       arr[key].push("Invalid email");
          //     }
          //   }
          //   break;
          // case "nominee_pan_number":
          //   if (value) {
          //     if (!pan_format.test(String(value))) {
          //       found_err = true;
          //       arr[key].push("Invalid PAN number");
          //     }
          //   }
          //   break;
          // case "nominee_dob":
          //   if (
          //     mon < 0 ||
          //     (mon === 0 && today.getDate() < birthDate.getDate())
          //   ) {
          //     age--;
          //   }
          //   if (age < 18) {
          //     found_err = true;
          //     arr[key].push("Invalid, age must be at least 18 years");
          //   } else if (value.length < 10) {
          //     found_err = true;
          //     arr[key].push("Invalid, age must be at least 18 years");
          //   }
          //   break;
          default:
            break;
        }
      }
    }

    // set the errors
    setErrors(arr);

    // if no error founds
    if (!found_err) {
      // data
      let get_client_consent_payload = {
        name: raw_data.documents.document_pan_name
          ? raw_data.documents.document_pan_name
          : raw_data.documents.document_data.name == ""
          ? "User"
          : raw_data.documents.document_data.name,
        email: raw_data.profile.profile_email,
        mobile: raw_data.profile.profile_mobile,
        ifa_id: ifaCognitoId,
        mode: "ifa",
      };

      if (param.state !== undefined) {
        if (param.state.update !== undefined) {
          setSubmit(true);
          onUpdate(payload_data);
        } else {
          setSubmit(true);
          setTimeout(() => {
            setFlow("create");
            generateOTP(get_client_consent_payload);
          }, 1000);
        }
      } else {
        setSubmit(true);
        setTimeout(() => {
          setFlow("create");
          generateOTP(get_client_consent_payload);
        }, 1000);
      }
    } else {
      if (
        arr["profile_mobile"].length != 0 ||
        arr["profile_dob"].length != 0 ||
        arr["profile_email"].length != 0 ||
        arr["profile_gender"].length != 0 ||
        arr["profile_selfie_image"].length != 0
      ) {
        errorToast("Please fill all required details correctly.");
        window.scrollTo(0, 0);
      } else if (!panVerified && !doNotFetchCkyc) {
        errorToast("Please validate your PAN");
        window.scrollTo(0, 500);
      } else if (!aadhaarValidated) {
        errorToast("Please validate your Aadhaar number");
        window.scrollTo(0, 500);
      } else if (
        arr["document_address_back_image"].length != 0 ||
        arr["document_pan_number"].length != 0 ||
        arr["document_address_front_image"].length != 0 ||
        arr["document_pan_image"].length != 0
      ) {
        errorToast("Please fill all required fields correctly.");
        window.scrollTo(0, 500);
      } else if (
        arr["bank_account_number"].length != 0 ||
        arr["bank_ifsc_code"].length != 0
      ) {
        errorToast("There is some invalid data in the form.");
      } else if (!bankValidated) {
        errorToast("Please validate your bank details");
      } else {
        errorToast("There is some invalid data in the form.");
      }
    }
  };

  // generate otp
  const generateOTP = async (data) => {
    // payload
    let payload;

    // assign the payload
    if (data) {
      payload = data;
    } else {
      setFlow("link");
      payload = {
        pan: String(pan).toUpperCase(),
        ifa_id: ifaCognitoId,
        mobile: mobile,
        mode: "ifa",
      };
    }

    //if flow is link, generate otp else trigger email
    if (!data) {
      await api.getClientConsent(payload).then((res) => {
        if (res.kind === "ok") {
          successToast("Successfully sent the OTP");
          setTimeout(() => {
            setSubmit(false);
            setUserExistLoader(false);
            setClientConsentModel(true);
          }, 2000);
        } else {
          setTimeout(() => {
            errorToast(res.error);
            setSubmit(false);
            setUserExistLoader(false);
          }, 2000);
        }
      });
    } else {
      //trigger email

      payload = {
        mobile: raw_data.profile.profile_mobile,
        name: raw_data.documents.document_pan_name
          ? raw_data.documents.document_pan_name
          : raw_data.documents.document_data &&
            raw_data.documents.document_data.name &&
            raw_data.documents.document_data.name == ""
          ? raw_data.documents.document_data.name
          : "User",
        email: raw_data.profile.profile_email,
      };

      await api.getClientConsentEmail(payload).then((res) => {
        if (res.kind === "ok") {
          successToast(
            "An email containing Terms & Conditions was sent successfully"
          );
          setTimeout(() => {
            setSubmit(false);
            setUserExistLoader(false);
            setClientConsentModel(true);
          }, 2000);
        } else if (res.error.hasOwnProperty("profile_mobile")) {
          errorToast("User already exist in our system.");
          window.scrollTo(0, 0);
          setErrors(res.error);
          setSubmit(false);
        } else {
          setTimeout(() => {
            errorToast(res.error);
            setSubmit(false);
            setUserExistLoader(false);
          }, 2000);
        }
      });
    }
  };

  // verify otp
  const verifyOTP = async () => {
    // payload
    let payload = {
      otp: clientOTP,
      ifa_id: ifaCognitoId,
      pan: String(pan).toUpperCase(),
      mobile: raw_data.profile.profile_mobile,
      email: raw_data.profile.profile_email,
      mode: "ifa",
    };

    await api.verifyClientConsent(payload).then((res) => {
      if (res.kind === "ok") {
        // if flow is create
        if (flow == "create") {
        let payload_data_for_create = {
            ...raw_data.profile,
            ...raw_data.documents,
            ...raw_data.bank_details,
            ...raw_data.nominee,
            ...raw_data.networth,
            document_pan_number: pan,
            document_pan_name: raw_data.documents?.document_pan_name,
            entity_type: investorType != "Individual" ? true : false,
            investor_type: investorType,
            mode: "create",
            ip_address: res.data.ip_address,
            datetime: res.data.datetime,
            tc_url: res.data.tc_url,
          };
          setTimeout(() => {
            setClientConsentModelLoader(false);
            setClientConsentModel(false);
            setSubmit(true);
            onSubmit(payload_data_for_create);
          }, 2000);
        }

        // if flow is link
        else if (flow == "link") {
          setTimeout(() => {
            setClientConsentModelLoader(false);
            setClientConsentModel(false);
            setUserExistLoader(true);
            onLink();
          }, 2000);
        }

        // if no flow found
        else {
          setTimeout(() => {
            errorToast(
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
            );
            navigate.push("/clients");
          }, 2000);
        }
      } else {
        setTimeout(() => {
          errorToast(res.error);
          setClientConsentModelLoader(false);
        }, 2000);
      }
    });
  };

  // on submit the kyc data
  const onSubmit = (data) => {
    api
      .addClientKyc(data)
      .then((res) => {
        if (res.kind === "ok") {
          successToast("Successfully saved");
          setTimeout(() => {
            setSubmit(false);
            navigate.push("/clients");
          }, 2000);
        } else {
          if (typeof res.error === "string") {
            errorToast(res.error);
            setSubmit(false);
          } else if (res.error.hasOwnProperty("profile_email")) {
            errorToast("The email you provided already exist in our system.");
            window.scrollTo(0, 0);
            setErrors(res.error);
            setSubmit(false);
          } else if (res.error.hasOwnProperty("profile_mobile")) {
            errorToast("User already exist in our system.");
            window.scrollTo(0, 0);
            setErrors(res.error);
            setSubmit(false);
          } else {
            errorToast("There is some invalid data in the form.");
            window.scrollTo(0, 0);
            setErrors(res.error);
            setSubmit(false);
          }
        }
      })
      .then((res) => {
        if (investorType != "Individual") {
          entityDocuments.mobile =
            mobile.length > 0 ? mobile : raw_data.profile.profile_mobile;
          api.addClientKycEntityDocument(entityDocuments).then((res) => {
            console.log("res ::::", res);
          });
        }
      })
      .catch((error) => {
        console.log("error :::", error);
      });
  };

  // on update the kyc data
  const onUpdate = (data) => {
    api.updateClientKyc(data, param.state.uuid).then((res) => {
      if (res.kind === "ok") {
        successToast("Successfully saved.");
        setTimeout(() => {
          setSubmit(false);
          navigate.push("/clients");
        }, 2000);
      } else {
        if (typeof res.error === "string") {
          errorToast(res.error);
          setSubmit(false);
        } else {
          errorToast("There is some invalid data in the form.");
          setErrors(res.error);
          setSubmit(false);
        }
      }
    });
  };

  // check net worth data
  const checkNetWorth = () => {
    let payload_data = {
      ...document_net_worth,
    };
    let key_name = Object.getOwnPropertyNames(payload_data);
    let arr = [];
    let found_err = false;

    // map errors array
    key_name.map((name) => {
      return (arr[name] = []);
    });

    // check the validation
    for (let key in payload_data) {
      if (payload_data.hasOwnProperty(key)) {
        // variable define
        let value = String(payload_data[key]);

        // check the case
        switch (key) {
          case "document_net_worth_image":
            if (value.length > 255) {
              found_err = true;
              arr[key].push("File Name cannot be greater than 255 characters");
            } else if (value.length < 1) {
              found_err = true;
              arr[key].push("Please upload the required document");
            }
            break;
          default:
            break;
        }
      }
    }

    // set the errors
    setErrors(arr);

    // if no error founds
    if (!found_err) {
      setSubmit(true);
      let data = {
        user_id: param.state.uuid,
        document_net_worth_image: payload_data.document_net_worth_image,
        document_net_worth_image_data:
          payload_data.document_net_worth_image_data,
      };
      onUpdateNetWorth(data);
    }
  };

  // on update net worth data
  const onUpdateNetWorth = (data) => {
    api.updateClientNetWorth(data).then((res) => {
      if (res.kind === "ok") {
        successToast("Successfully saved.");
        setTimeout(() => {
          setSubmit(false);
          navigate.push("/clients");
        }, 2000);
      } else {
        if (typeof res.error === "string") {
          errorToast(res.error);
          setSubmit(false);
        } else {
          errorToast("There is some invalid data in the form.");
          setErrors(res.error);
          setSubmit(false);
        }
      }
    });
  };

  // link user with ifa
  const onLink = async () => {
    // payload
    let payload = {
      pan: String(pan).toUpperCase(),
      mode: "link",
    };

    // call the api
    await api.addClientKyc(payload).then((res) => {
      if (res.kind == "ok") {
        successToast("Successfully link user with your account");
        setTimeout(() => {
          setUserExistLoader(false);
          navigate.push("/clients");
          eventLog({
            type: "client_otp_verification",
            action: {
              trigger: "true",
            },
          });
        }, 2000);
      } else {
        errorToast(res.error);
        setTimeout(() => {
          setUserExistLoader(false);
          navigate.push("/clients");
          eventLog({
            type: "client_otp_verification",
            action: {
              trigger: "false",
            },
          });
        }, 2000);
      }
    });
  };

  // if (hideIFA) {
  //   return <HideIFA />;
  // }

  // console.log("raw_Data >>>", raw_data);
  // console.log("entityDocuments  >>>", entityDocuments);
  // console.log("entityType  >>>", entityFlow);

  // link user with ifa
  const downloadNetWorth = async () => {
    setIsNetWorthDocumentFetching(true);

    let payload = {
      mobile: preData["profile_mobile"],
    };

    api
      .getNetWorthCertificate(payload)
      .then((res) => {
        if (res.kind == "ok") {
          downloadBase64File(res.data, netWorthName);
          setIsNetWorthDocumentFetching(false);
        } else {
          errorToast(res.error);
          setIsNetWorthDocumentFetching(false);
        }
      })
      .catch((error) => {
        errorToast("Error while fetching Net Worth Certificate.");
        setIsNetWorthDocumentFetching(false);
      });
  };

  return (
    <div>
      {loginRender === false ? (
        <div />
      ) : (
        <div className="clientkyc_div_container">
          <div>
            {render === false ? (
              <ContentLoadingLoader />
            ) : (
              <div className="clientkyc_main_div">
                {/* top container */}
                <div className="clientkyc_top_container">
                  <div className="clientkyc_top_row_container">
                    {/* back icon */}
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <KeyboardBackspaceIcon
                        style={{
                          fontSize: "20px",
                          color: "#414141",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (clientPreCheck || showExistModel) {
                            navigate.go(0);
                          } else {
                            navigate.goBack();
                          }
                        }}
                      />

                      {/* back text */}
                      <p
                        className="clientkyc_top_row_back"
                        onClick={() => {
                          if (clientPreCheck || showExistModel) {
                            navigate.go(0);
                          } else {
                            navigate.goBack();
                          }
                        }}
                      >
                        Back
                      </p>
                    </div>

                    {/* title */}
                    {param.state === undefined ||
                    param.state.disable === false ? (
                      param.pathname === "/clients/networth" ? (
                        <div />
                      ) : (
                        <h4 className="clientkyc_main_container_main_title">
                          {param.state === undefined
                            ? "New Client KYC"
                            : param.state.update
                            ? "Update Client KYC"
                            : "New Client KYC"}
                        </h4>
                      )
                    ) : (
                      <h4 className="clientkyc_main_container_main_title">
                        Client KYC
                      </h4>
                    )}
                  </div>
                </div>

                {preCheck && !showExistModel ? (
                  // if precheck true and showexistmodel false
                  preCheckPanNumber(
                    param,
                    setShowExistModel,
                    setPreCheck,
                    setPan,
                    setMobile,
                    setClientPreCheck,
                    clientPreCheck,
                    setInvestorType,
                    investorType,
                    entityType,
                    setEntityType,
                    raw_data,
                    setEntityDocuments,
                    setDoNotFetchCkyc,
                    setEntityPan,
                    setEntityFLow,
                    setEntityPanName
                  )
                ) : !preCheck && showExistModel && !entityType ? (
                  // if precheck false and showexistmodel true
                  userExistModel(param, setUserExistLoader, generateOTP)
                ) : !preCheck && !showExistModel && !entityType ? (
                  // if precheck false and showexistmodel false
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      alignSelf: "center",
                    }}
                  >
                    {/* profile and document container */}
                    <div
                      className="clientkyc_main_container"
                      style={{
                        display:
                          param.pathname === "/clients/networth"
                            ? "none"
                            : "flex",
                      }}
                    >
                      {/* profile */}
                      <div
                        id="profile"
                        className="clientkyc_main_container_div1"
                      >
                        <h4 className="clientkyc_main_container_title">
                          {investorType != "Individual"
                            ? "Profile of Authorised Signatory"
                            : "Profile"}
                        </h4>
                        <ClientKycProfile
                          raw_data={raw_data}
                          value={(data) => {
                            raw_data.profile = data;
                            setDob(data.profile_dob);
                            setName(data.profile_name);
                          }}
                          error={errors}
                          setErrors={setErrors}
                          data={preData}
                          disable={
                            param.state === undefined
                              ? false
                              : param.state.disable
                          }
                          disableNameDob={disableNameDob}
                          mobile={mobile}
                          panVerified={panVerified}
                          hideUserSelfie={ckycVerifiedFailed}
                        />
                      </div>

                      {/* document */}
                      {/* hide component incase of entity other than individual*/}
                      <div
                        id="document"
                        className="clientkyc_main_container_div2"
                        style={{ marginTop: "50px" }}
                      >
                        <h4 className="clientkyc_main_container_title">
                          {investorType != "Individual"
                            ? "Documents of Authorised Signatory"
                            : "Documents"}
                        </h4>
                        <ClientKycDocument
                          raw_data={raw_data}
                          value={(data) => {
                            // console.log("data ::::", data);
                            raw_data.documents = data;
                            console.log(
                              "raw_data.documents",
                              raw_data.documents
                            );
                          }}
                          error={errors}
                          setErrors={setErrors}
                          data={preData}
                          disable={
                            param.state === undefined
                              ? false
                              : param.state.disable
                          }
                          dob={dob}
                          name={name}
                          onDisableNameDob={(value) => setDisableNameDob(value)}
                          pan={pan}
                          setPan={setPan}
                          panValidateFailed={(data) =>
                            setPanValidateFailed(data)
                          }
                          addressFailed={(data) => setAddressFailed(data)}
                          setPanVerified={(data) => setPanVerified(data)}
                          setAadhaarValidated={(data) =>
                            setAadhaarValidated(data)
                          }
                          setCkycVerifiedFailed={(data) =>
                            setCkycVerifiedFailed(data)
                          }
                          investorType={investorType}
                        />
                      </div>
                    </div>

                    {/* Bank and nominee container */}
                    <div
                      className="clientkyc_main_container"
                      style={{
                        display:
                          param.pathname === "/clients/networth"
                            ? "none"
                            : "flex",
                      }}
                    >
                      {/* bank */}
                      <div id="bank" className="clientkyc_main_container_div1">
                        <h4 className="clientkyc_main_container_title">
                          {investorType != "Individual"
                            ? "Bank Details of Entity"
                            : "Bank Details"}
                        </h4>
                        <ClientKycBank
                          raw_data={raw_data}
                          value={(data) => (raw_data.bank_details = data)}
                          error={errors}
                          setErrors={setErrors}
                          data={preData}
                          disable={
                            param.state === undefined
                              ? false
                              : param.state.disable
                          }
                          isVerified={(data) => setIsVerified(data)}
                          panName={panName}
                          setValidateBank={(data) => {
                            setBankValidated(data);
                          }}
                        />
                      </div>

                      {/* nominee */}
                      {/* <div
                        id="nominee"
                        className="clientkyc_main_container_div2"
                        style={{ marginTop: "50px" }}
                      >
                        <h4 className="clientkyc_main_container_title">
                          Nominee
                        </h4>
                        <ClientKycNominee
                          value={(data) => (raw_data.nominee = data)}
                          error={errors}
                          data={preData}
                          disable={
                            param.state === undefined
                              ? false
                              : param.state.disable
                          }
                        />
                      </div> */}
                    </div>

                    {/* net worth certificate */}
                    <div
                      className="clientkyc_main_container"
                      style={{
                        display:
                          param.pathname === "/clients/kyc" &&
                          param.state === undefined
                            ? "none"
                            : param.pathname === "/clients/kyc" &&
                              param.state !== undefined &&
                              param.state.uuid === ""
                            ? "none"
                            : param.pathname === "/clients/networth"
                            ? "flex"
                            : "none",
                      }}
                    >
                      {/* net worth */}
                      <div
                        id="networth"
                        className="clientkyc_main_container_div1"
                      >
                        <div className="clientkyc_net_worth_header_container">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <h4 className="clientkyc_main_container_title_net_worth">
                              Net Worth Certificate{" "}
                              {param.pathname === "/clients/networth"
                                ? param.state.net_worth == 2
                                  ? "Verified"
                                  : ""
                                : "(optional)"}
                            </h4>

                            <InfoIcon
                              style={{
                                fontSize: "18px",
                                marginLeft: "10px",
                                cursor: "pointer",
                                color: "#4287f5",
                                display:
                                  param.state.net_worth == 2
                                    ? "none"
                                    : "initial",
                              }}
                              onClick={() => setShowNetInfo(true)}
                            />
                          </div>
                          <div
                            style={{
                              textAlign: "right",
                              display:
                                param.state && param.state.net_worth == 2
                                  ? "initial"
                                  : "none",
                            }}
                            onClick={() => downloadNetWorth()}
                          >
                            <button className="kyc_download_net_worth_button">
                              <p className="kyc_download_net_worth_button_text">
                                Download
                              </p>
                            </button>
                          </div>
                        </div>
                        <ClientNetWorth
                          disable={param.state.net_worth == 2 ? true : false}
                          value={(data) => {
                            if (param.pathname === "/clients/kyc") {
                              raw_data.networth = data;
                            } else if (param.pathname === "/clients/networth") {
                              document_net_worth = data;
                            }
                          }}
                          error={errors}
                          setErrors={setErrors}
                          data={preData}
                        />
                      </div>
                    </div>

                    {/* submit button for kyc */}
                    {param.state === undefined ||
                    param.state.disable === false ? (
                      <div
                        className="kyc_button_container"
                        style={{
                          display:
                            param.state && param.state.net_worth == 2
                              ? "none"
                              : "flex",
                        }}
                      >
                        <div
                          className="kyc_submit_button"
                          onClick={() => {
                            if (param.pathname === "/clients/kyc") {
                              eventLog({
                                type: "add_client_submit_cta",
                                action: {
                                  trigger: "true",
                                },
                              });
                              checkOcrData();
                            } else if (param.pathname === "/clients/networth") {
                              checkNetWorth();
                            }
                          }}
                        >
                          {param.state === undefined
                            ? "Submit"
                            : param.state.update === true
                            ? "Update"
                            : "Submit"}
                        </div>
                      </div>
                    ) : (
                      <div />
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}

            {/* full screen loader */}
            {submit || userExistLoader || isNetWorthDocumentFetching ? (
              <FullScreenLoader />
            ) : (
              <div />
            )}

            {/* show net worth info */}
            {showNetInfo ? (
              <InfoAlert
                show={showNetInfo}
                title="Net Worth"
                content="In accordance with RBI norms, in order to invest more than ₹10,00,000 across P2P Platforms the investor needs to produce a certificate from a practicing Chartered Accountant certifying minimum net-worth of ₹50,00,000."
                okbutton="OK"
                ok={() => setShowNetInfo(false)}
              />
            ) : (
              <div />
            )}

            {/* toast container */}
            <SampleToast />

            {/* client consent model */}
            <ClientOTPModel
              openModel={clientConsentModel}
              setOtp={(value) => setClientOTP(value)}
              loading={clientConsentModelLoader}
              onSubmit={() => {
                setClientConsentModelLoader(true);
                verifyOTP();
              }}
              resendAgain={() =>
                showExistModel ? generateOTP() : checkOcrData()
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ClientKyc;

// pre check pan Number
function preCheckPanNumber(
  param,
  setShowExistModel,
  setPreCheck,
  setPan,
  setMobile,
  setClientPreCheck,
  clientPreCheck,
  setInvestorType,
  investorType,
  entityType,
  setEntityType,
  raw_data,
  setEntityDocuments,
  setDoNotFetchCkyc,
  setEntityPan,
  setEntityFLow,
  setEntityPanName
) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignSelf: "center",
      }}
    >
      <div
        className="clientkyc_main_container"
        style={{
          display: param.pathname === "/clients/networth" ? "none" : "flex",
        }}
      >
        <InvestorTypeComponent
          data={(value) => {
            setClientPreCheck(true);
            setInvestorType(value.investortype);
            setEntityType(value.entity_type);
            setEntityFLow(true);
          }}
        />

        {investorType === "Individual" ? (
          <div id="precheck" className="clientkyc_main_container_div1">
            <ClientPreKyc
              data={(value) => {
                if (value.exist == true) {
                  if (value.mobile) {
                    setPreCheck(false);
                    setPan(value.pan);
                    setMobile(value.mobile);
                  } else {
                    setShowExistModel(true);
                    setPreCheck(false);
                    setPan(value.pan);
                  }
                } else {
                  setPreCheck(false);
                  setPan(value.pan);
                }
              }}
            />
          </div>
        ) : null}

        {entityType == true ? (
          <EntityKyc
            investorType={investorType}
            setEntityPanName={setEntityPanName}
            document={(data) => {
              setEntityFLow = setEntityFLow(true);
              raw_data.entity_documents = data;
              setEntityDocuments(data);
              setPreCheck(false);
              setShowExistModel(false);
            }}
            data={(value) => {
              setEntityFLow = setEntityFLow(true);
              raw_data.documents["entity_document_pan_name"] = value.pan;
              raw_data.documents["entity_document_pan_number"] = value.pan_name;
              setEntityPanName(value.pan_name);
              setEntityPan(value.pan);
              setDoNotFetchCkyc(value.doNotFetchCkyc);
            }}
            setEntityType={setEntityType}
          />
        ) : null}
      </div>
    </div>
  );
}

// user exist model
function userExistModel(param, setUserExistLoader, generateOTP) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignSelf: "center",
      }}
    >
      <div
        className="clientkyc_main_container"
        style={{
          display: param.pathname === "/clients/networth" ? "none" : "flex",
        }}
      >
        <div id="userexist" className="clientkyc_main_container_div1">
          <div className="clientkyc_user_exist_div">
            <p className="clientkyc_user_exist_text">
              Account with this PAN already exists in the system. Please proceed
              to link this account with your account.
            </p>
            <button
              onClick={() => {
                setUserExistLoader(true);
                setTimeout(() => {
                  eventLog({
                    type: "proceed_cta",
                    action: {
                      trigger: "true",
                    },
                  });
                  generateOTP();
                }, 1000);
              }}
              className="clientkyc_user_exist_button"
            >
              Proceed
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
