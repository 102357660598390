import "./faq.css";
import { observer } from "mobx-react-lite";
import { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { Redirect } from "react-router-dom";
import { errorToast } from "../../utils/helper";
import { ContentLoadingLoader } from "../../components";
import Faq from "react-faq-component";
import config from "./../../config/settings";

const styles = {
  rowContentColor: "grey",
  arrowColor: "#3A86FF",
  rowContentPaddingBottom: "5px",
};

const compConfig = {
  animate: true,
  tabFocus: true,
};
const faq = observer(function FaqComp(props) {
  const [render, setRender] = useState(false);
  const [isLogin, setIsLogin] = useState();
  const [loginRender, setLoginRender] = useState(false);
  const [rows, setRowsOption] = useState(null);

  // check if user login or not
  useEffect(() => {
    props.showCommonHeader(true);
    let checkLogin = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        let status = localStorage.getItem("kyc_status");
        if (status != 2) {
          setIsLogin(false);
        }
        else
        {
          fetchIfakycStatus();
        }
     
        // setIsLogin(true);
        // setLoginRender(true);
        setTimeout(() => {
          setRender(true);
        }, 500);
      } catch {
        setIsLogin(false);
        errorToast(
          "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
        );
      }
    };
    return checkLogin();
    // eslint-disable-next-line
  }, []);

  // fetch ifa kyc status
  const fetchIfakycStatus = () => {
    props.showCommonSidebar(true);
    setIsLogin(true);
    setLoginRender(true);
  };

  useEffect(() => {
    if (rows) {
      rows[0].expand();
    }
  }, [rows]);

  // if user is not login then redirect to login page
  if (isLogin === false) {
    return <Redirect to="/" />;
  }

  return (
    <div>
      {loginRender === false ? (
        <div />
      ) : (
        <div className="faq_div_container">
          {render === false ? (
            <ContentLoadingLoader />
          ) : (
            <div className="faq_main_div">
              <div className="faq_style_wrapper">
                <div className="faq_title">
                  <h1 className="faq_title_text">
                    Frequently Asked Questions (FAQs)
                  </h1>
                </div>
                <Faq
                  data={config.faq_data}
                  styles={styles}
                  config={compConfig}
                  getRowOptions={setRowsOption}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
});
export default faq;
