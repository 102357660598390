import React, { useEffect, useState } from "react";
import Switch from "@mui/material/Switch";

import Loader from "react-loader-spinner";
import {
  convertIntoDefaultState,
  convertIntoErrorState,
  errorToast,
  infoToast,
  successToast,
  checkValidPan,
  checkForAddressType,
  setDisableState,
} from "../../utils/helper";
import {
  InputBoxV2,
  UploadInputV2,
  UploadInputV3,
} from "../../components/index.js";
import { clientKycDocumentDetails } from "../../constant/signupState";
import "./addClientKycDocumentsDetails.css";
import Checkbox from "@mui/material/Checkbox";
import config from "../../config/settings";
import StateDropdown from "../../components/state-dropdown/state-dropdown";
import { state_array } from "../../config/settings";
import { kycDocumentsState } from "../../constant/clientKycState";
import UploadInputPan from "../../components/upload-input-pan/upload-input-pan";

let maxRetries = 2;

const AddClientKycDocumentsDetails = (props) => {
  const [addClientKycDocumentsDetails, setAddClientKycDocumentsDetails] =
    useState(convertIntoDefaultState(clientKycDocumentDetails));

  const [
    addClientKycDocumentsDetailsError,
    setAddClientKycDocumentsDetailsError,
  ] = useState(convertIntoErrorState(clientKycDocumentDetails));

  const [disableKycDocuments, setDisableKycDocuments] = useState(
    convertIntoErrorState(clientKycDocumentDetails)
  );

  const [document, setDocument] = useState({
    document_type: null,
    document_front: null,
    document_back: null,
  });

  const [userID, setUserId] = useState("");

  //get user id
  useEffect(() => {
    setUserId(props.basicKycDetails.user_id);
  }, [props?.basicKycDetails]);

  useEffect(() => {
    if (
      props.userData &&
      props.userData !== "" &&
      props.userData.kyc_status === 3 &&
      !props.userData.has_document
    ) {
      let data = {
        ...props.userData,
        ...props.userData.address,
      };
      setDisableState(data, disableKycDocuments, setDisableKycDocuments);
    }
    if (props.userData && props.userData != "") {
      setAddClientKycDocumentsDetails({
        ...addClientKycDocumentsDetails,
        ...props.userData.address,
        selfie: props.userData.selfie,
      });
      setDocument({
        document_type:
          config.documents.address.types[props.userData.address_type],
        document_front: props.userData.address_front_image,
        document_back: props.userData.address_back_image,
      });
    }
  }, []);

  useEffect(() => {
    //update kyc details for parent
    props.setAddClientKycDocumentsDetails({
      ...addClientKycDocumentsDetails,
      address_type: checkForAddressType(document.document_type),
      address_front_image: document.document_front,
      address_back_image: document.document_back,
    });

    //remove error
    props.setErrorState({
      ...addClientKycDocumentsDetailsError,
    });
  }, [addClientKycDocumentsDetails, document]);

  //check for error
  useEffect(() => {
    if (props.errorState && props.errorState != null) {
      setAddClientKycDocumentsDetailsError({
        ...addClientKycDocumentsDetailsError,
        ...props.errorState,
      });
    }
  }, [props]);

  //array for address types
  const addressProofArray = ["Aadhaar Card", "Passport", "Driving License"];
  const handleSwitchChange = (e) => {
    setAddClientKycDocumentsDetails((prevState) => ({
      ...prevState,
      permanent_current_address_flag: e.target.checked,
    }));
  };
  const handleOcr = (isFileUploaded) => {

    if (isFileUploaded) {
      setAddClientKycDocumentsDetails({ ...addClientKycDocumentsDetails, pan_image: "pan_image" })
      successToast("File uploaded successfully.");
    }
  }

  return (
    <div
      className="add_client_kyc_basic_details_container"
      style={{ display: !props.visible ? "none" : "" }}
    >
      <div className="add_client_kyc_basic_details_main_container">
        <h4
          className="add_client_kyc_pre_pan_main_container_title"
          style={{ marginBottom: "20px" }}
        >
          KYC Documents
        </h4>
        {/* PAN Upload */}
        {!props.panVerified &&
          <UploadInputPan
            label={"Pan Image"}
            id="pan_image"
            fileName={addClientKycDocumentsDetails["pan_image"]}
            uploadFileToS3={true}
            shouldRunOcr={true}
            source="pan" //required with shouldRunOcr paramter
            sourceType="pan_image" //required with shouldRunOcr paramter
            callback={handleOcr}
            additionalPath={props.basicKycDetails.user_id + "/"}
          />
        }

        <UploadInputV2
          id="selfie"
          label={"Selfie"}
          required={clientKycDocumentDetails["selfie"]["required"]}
          object={addClientKycDocumentsDetails}
          setValue={setAddClientKycDocumentsDetails}
          imageSizeLimit={15} //required if note is provided
          note={
            "Ensure the client's face is clearly visible in the picture. (Upload image in JPG/JPEG/PNG format. Max. file size allowed is 15 MB)"
          }
          errorObject={addClientKycDocumentsDetailsError}
          setErrorObject={setAddClientKycDocumentsDetailsError}
          isError={addClientKycDocumentsDetailsError["selfie"]} //props.errorState.hasOwnProperty("selfie")?props.errorState.selfie:
          disabled={props.totalDisable || disableKycDocuments["selfie"]}
          requiredFileTypes={["image/jpeg", "image/png", "image/jpg"]}
          acceptedFormat=".jpg, .jpeg, .png"
          additionalPath={userID}
        />

        {/* address */}
        <h4 id="add_client_proprietor_detail_subtitle">Current Address</h4>
        <InputBoxV2
          label={"Address"}
          id="current_address"
          type={clientKycDocumentDetails["current_address"].type}
          disabled={
            props.totalDisable || disableKycDocuments["current_address"]
          }
          maxLength="255"
          value={addClientKycDocumentsDetails["current_address"]}
          setValue={setAddClientKycDocumentsDetails}
          object={addClientKycDocumentsDetails}
          required={clientKycDocumentDetails["current_address"]["required"]}
          errorObject={addClientKycDocumentsDetailsError}
          setErrorObject={setAddClientKycDocumentsDetailsError}
          isError={addClientKycDocumentsDetailsError["current_address"]} //props.errorState.hasOwnProperty("address")?props.errorState.address:
          errorText={
            addClientKycDocumentsDetails["current_address"]
              ? "Invalid Address"
              : "This field cannot be empty."
          }
        />
        <InputBoxV2
          label={"Locality"}
          id="current_locality"
          type={clientKycDocumentDetails["current_locality"].type}
          disabled={
            props.totalDisable || disableKycDocuments["current_locality"]
          }
          maxLength="255"
          value={addClientKycDocumentsDetails["current_locality"]}
          setValue={setAddClientKycDocumentsDetails}
          object={addClientKycDocumentsDetails}
          required={clientKycDocumentDetails["current_locality"]["required"]}
          errorObject={addClientKycDocumentsDetailsError}
          setErrorObject={setAddClientKycDocumentsDetailsError}
          isError={addClientKycDocumentsDetailsError["current_locality"]} //props.errorState.hasOwnProperty("address")?props.errorState.address:
          errorText={
            addClientKycDocumentsDetails["current_locality"]
              ? "Invalid Locality"
              : "This field cannot be empty."
          }
        />
        <InputBoxV2
          label={"City"}
          id="current_city"
          type={clientKycDocumentDetails["current_city"].type}
          disabled={props.totalDisable || disableKycDocuments["current_city"]}
          maxLength="255"
          value={addClientKycDocumentsDetails["current_city"]}
          setValue={setAddClientKycDocumentsDetails}
          object={addClientKycDocumentsDetails}
          required={clientKycDocumentDetails["current_city"]["required"]}
          errorObject={addClientKycDocumentsDetailsError}
          specialCharCheck={true}
          setErrorObject={setAddClientKycDocumentsDetailsError}
          isError={addClientKycDocumentsDetailsError["current_city"]} //props.errorState.hasOwnProperty("address")?props.errorState.address:
          errorText={
            addClientKycDocumentsDetails["current_city"]
              ? "Invalid City"
              : "This field cannot be empty."
          }
        />
        <StateDropdown
          label={"State"}
          id={"current_state"}
          type={clientKycDocumentDetails["current_state"].type}
          disabled={props.totalDisable || disableKycDocuments["current_state"]}
          dataArray={config.state_array}
          maxLength="255"
          value={addClientKycDocumentsDetails["current_state"]}
          setValue={setAddClientKycDocumentsDetails}
          object={addClientKycDocumentsDetails}
          required={clientKycDocumentDetails["current_state"]["required"]}
          errorObject={addClientKycDocumentsDetailsError}
          setErrorObject={setAddClientKycDocumentsDetailsError}
          isError={addClientKycDocumentsDetailsError["current_state"]} //props.errorState.hasOwnProperty("address")?props.errorState.address:
          errorText={
            addClientKycDocumentsDetails["current_state"]
              ? "Invalid State"
              : "This field cannot be empty."
          }
        />
        <InputBoxV2
          label={"Pin code"}
          id="current_pin_code"
          type={clientKycDocumentDetails["current_pin_code"].type}
          disabled={
            props.totalDisable || disableKycDocuments["current_pin_code"]
          }
          maxLength="6"
          value={addClientKycDocumentsDetails["current_pin_code"]}
          setValue={setAddClientKycDocumentsDetails}
          object={addClientKycDocumentsDetails}
          required={clientKycDocumentDetails["current_pin_code"]["required"]}
          errorObject={addClientKycDocumentsDetailsError}
          setErrorObject={setAddClientKycDocumentsDetailsError}
          isError={addClientKycDocumentsDetailsError["current_pin_code"]} //props.errorState.hasOwnProperty("address")?props.errorState.address:
          errorText={
            addClientKycDocumentsDetails["current_pin_code"]
              ? "Invalid Pin Code"
              : "This field cannot be empty."
          }
        />

        {/* dropdown document upload */}
        <UploadInputV3
          id="address_type"
          label={"Current Address Proof"}
          dropDownArray={addressProofArray}
          object={document}
          value={document}
          setValue={setDocument}
          imageSizeLimit={15} //required if note is provided
          labelForDocumentFront={"Front Side"}
          idForDocumentFront={"address_front_image"}
          idForDocumentBack={"address_back_image"}
          labelForDocumentBack={"Back Side"}
          note={
            "Upload image in JPG/JPEG/PNG/PDF format. Max. file size allowed is 15 MB"
          }
          errorObject={addClientKycDocumentsDetailsError}
          setErrorObject={setAddClientKycDocumentsDetailsError}
          isError={addClientKycDocumentsDetailsError["address_type"]} //{props.errorState.hasOwnProperty("address_type")?props.errorState.address_type:
          disabled={props.totalDisable || disableKycDocuments["address_type"]}
          additionalPath={userID}
        />
        <div></div>
        <div className="address_toggle">
          {/* premament details */}
          <p id="address_toggle_label1">
            Permanent address is the same as current address
          </p>

          {/* props.isGstRequired */}
          <Switch
            checked={
              addClientKycDocumentsDetails["permanent_current_address_flag"]
            }
            onChange={handleSwitchChange}
            inputProps={{ "aria-label": "controlled" }}
            sx={{ "& .MuiSwitch-thumb": { color: "#3a86ff" } }}
          />
        </div>
        {!addClientKycDocumentsDetails["permanent_current_address_flag"] && (
          <div>
            <h4 id="add_client_proprietor_detail_subtitle">
              Permanent Address
            </h4>
            <InputBoxV2
              label={"Address"}
              id={"permanent_address"}
              type={clientKycDocumentDetails["permanent_address"].type}
              disabled={
                props.totalDisable || disableKycDocuments["permanent_address"]
              }
              maxLength="255"
              value={addClientKycDocumentsDetails["permanent_address"]}
              setValue={setAddClientKycDocumentsDetails}
              object={addClientKycDocumentsDetails}
              required={
                !addClientKycDocumentsDetails["permanent_current_address_flag"]
                  ? true
                  : false
              }
              errorObject={addClientKycDocumentsDetailsError}
              setErrorObject={setAddClientKycDocumentsDetailsError}
              isError={addClientKycDocumentsDetailsError["permanent_address"]} //props.errorState.hasOwnProperty("address")?props.errorState.address:
              errorText={
                addClientKycDocumentsDetails["permanent_address"]
                  ? "Invalid Address"
                  : "This field cannot be empty."
              }
            />
            <InputBoxV2
              label={"Locality"}
              id={"permanent_locality"}
              type={clientKycDocumentDetails["permanent_locality"].type}
              disabled={
                props.totalDisable || disableKycDocuments["permanent_locality"]
              }
              maxLength="255"
              value={addClientKycDocumentsDetails["permanent_locality"]}
              setValue={setAddClientKycDocumentsDetails}
              object={addClientKycDocumentsDetails}
              required={
                !addClientKycDocumentsDetails["permanent_current_address_flag"]
                  ? true
                  : false
              }
              errorObject={addClientKycDocumentsDetailsError}
              setErrorObject={setAddClientKycDocumentsDetailsError}
              isError={addClientKycDocumentsDetailsError["permanent_locality"]} //props.errorState.hasOwnProperty("address")?props.errorState.address:
              errorText={
                addClientKycDocumentsDetails["permanent_locality"]
                  ? "Invalid Locality"
                  : "This field cannot be empty."
              }
            />
            <InputBoxV2
              label={"City"}
              id={"permanent_city"}
              type={clientKycDocumentDetails["permanent_city"].type}
              disabled={
                props.totalDisable || disableKycDocuments["permanent_city"]
              }
              maxLength="255"
              specialCharCheck={true}
              value={addClientKycDocumentsDetails["permanent_city"]}
              setValue={setAddClientKycDocumentsDetails}
              object={addClientKycDocumentsDetails}
              required={
                !addClientKycDocumentsDetails["permanent_current_address_flag"]
                  ? true
                  : false
              }
              errorObject={addClientKycDocumentsDetailsError}
              setErrorObject={setAddClientKycDocumentsDetailsError}
              isError={addClientKycDocumentsDetailsError["permanent_city"]} //props.errorState.hasOwnProperty("address")?props.errorState.address:
              errorText={
                addClientKycDocumentsDetails["permanent_city"]
                  ? "Invalid City"
                  : "This field cannot be empty."
              }
            />
            <StateDropdown
              label={"State"}
              id={"permanent_state"}
              type={clientKycDocumentDetails["permanent_state"].type}
              disabled={
                props.totalDisable || disableKycDocuments["permanent_state"]
              }
              dataArray={config.state_array}
              maxLength="255"
              value={addClientKycDocumentsDetails["permanent_state"]}
              setValue={setAddClientKycDocumentsDetails}
              object={addClientKycDocumentsDetails}
              required={
                !addClientKycDocumentsDetails["permanent_current_address_flag"]
                  ? true
                  : false
              }
              errorObject={addClientKycDocumentsDetailsError}
              setErrorObject={setAddClientKycDocumentsDetailsError}
              isError={addClientKycDocumentsDetailsError["permanent_state"]} //props.errorState.hasOwnProperty("address")?props.errorState.address:
              errorText={
                addClientKycDocumentsDetails["permanent_state"]
                  ? "Invalid State"
                  : "This field cannot be empty."
              }
            />
            <InputBoxV2
              label={"Pin code"}
              id={"permanent_pin"}
              type={clientKycDocumentDetails["permanent_pin"].type}
              disabled={
                props.totalDisable || disableKycDocuments["permanent_pin"]
              }
              maxLength="6"
              value={addClientKycDocumentsDetails["permanent_pin"]}
              setValue={setAddClientKycDocumentsDetails}
              object={addClientKycDocumentsDetails}
              required={
                !addClientKycDocumentsDetails["permanent_current_address_flag"]
                  ? true
                  : false
              }
              errorObject={addClientKycDocumentsDetailsError}
              setErrorObject={setAddClientKycDocumentsDetailsError}
              isError={addClientKycDocumentsDetailsError["permanent_pin"]} //props.errorState.hasOwnProperty("address")?props.errorState.address:
              errorText={
                addClientKycDocumentsDetails["permanent_pin"]
                  ? "Invalid Pin Code"
                  : "This field cannot be empty."
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AddClientKycDocumentsDetails;
