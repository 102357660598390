import { API } from "aws-amplify";
import * as Types from "../api.types";

/**
 * Manages all requests to the API.
 */

export class APP_RELEASE_API {
  /**
   * @description This function is to check what is the app release Status of a particular release
   * @param raw
   * @returns it return fp status value
   */

  async getAppReleaseData(): Promise<Types.GetAppReleaseStatusData> {
    //call the api try and catch
    try {
      const response: any = await API.get("baseurl", "/app_release", {});
      return { kind: "ok", data: response.data };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }
}
