import React, { useEffect, useRef, useState } from "react";
import { Auth } from "aws-amplify";
import "./client-growth-plan.css";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import { convertAmount, errorToast, successToast } from "../../utils/helper";
import ClientInvestmentRequestModel from "../client-investment-request-model/client-investment-request-model";
import { eventLog } from "../../utils/firebase";
import { Pools_API } from "../../services/api";
import ClientGrowthPlanInvestmentsModel from "../client-growth-plan-investments-model/client-growth-plan-investments-model";
import { CustomTooltip } from "../../components";
import { Grid, Box } from "@mui/material";

const defaultTenureInvestmentData = {
  total_invested: 0,
  interest_rate: 0,
  investment_count: 0,
  type: 0,
  minimum_investment: 10000,
  id: "",
  tenure: 0,
  pool_title: "",
};

const ClientGrowthPlan = (props, ref) => {
  const navigate = useHistory();
  const param = useLocation();
  const screenOptions = ["Active", "Closed", "In-process"];
  const [tenureInvestmentData, setTenureInvestmentData] = useState(
    defaultTenureInvestmentData
  );
  const [name, setName] = useState("");
  const [openWithdrawalRequestModel, setOpenWithdrawalRequestModel] =
    useState(false);
  const [
    openClientInvestmentRequestModel,
    setOpenClientInvestmentRequestModel,
  ] = useState(false);
  const [amountInvalid, setAmountInvalid] = useState(false);
  const [amountInvalidText, setAmountInvalidText] = useState("");
  const [amount, setAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [
    openClientGrowthPlanInvestmentsModel,
    setOpenClientGrowthPlanInvestmentsModel,
  ] = useState(false);
  const [selectedPoolType, setSelectedPoolType] = useState(
    props.selectedPoolType
  );
  const poolsApi = new Pools_API();
  const dimensions = props.dimensions;
  const poolsState = props.overviewData.investments_by_tenure
    .filter(
      (item) =>
        (item.is_display === 1 || item.total_invested) &&
        item.pool_type === selectedPoolType
    )
    .map((item) => ({
      label:
        item.tenure == 1
          ? `${item.tenure} ${item.tenure_type == 1 ? `Month` : `Day`}`
          : `${item.tenure} ${item.tenure_type == 1 ? `Months` : `Days`}`,
      value: item.tenure,
    }));
  const [pools, setPools] = useState(poolsState);
  const [selectedTenure, setSelectedTenure] = React.useState(
    Math.min(...poolsState.map((item) => item.value))
  );
  useEffect(() => {
    setName(props.overviewData?.name);
    if (props.overviewData && props.overviewData.investments_by_tenure) {
      let obj = props.overviewData.investments_by_tenure.find(
        (item) =>
          item.tenure == selectedTenure &&
          item.pool_type !== null &&
          parseInt(item.pool_type) == selectedPoolType
      );
      if (
        obj &&
        obj.tenure == selectedTenure &&
        parseInt(obj.pool_type) == selectedPoolType
      ) {
        setTenureInvestmentData(obj);
      } else {
        setTenureInvestmentData(defaultTenureInvestmentData);
      }
    }
    if (selectedTenure === 1 || selectedTenure === 3 || selectedTenure === 6) {
      setSelectedPoolType(0);
    }
  }, [props, selectedTenure, selectedPoolType]);

  // amount key press handler
  const onKeyPress = (e) => {
    const re = /[^0-9]/g;
    if (re.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleChange = (event) => {
    setSelectedTenure(event.target.value);
  };

  // on wallet loading proceed click
  const onInvestmentRequestProceed = async () => {
    if (!isNaN(parseFloat(amount))) {
      if (amount == 0) {
        errorToast("Amount cannot be empty.");
      } else if (amount < 0) {
        errorToast("Amount cannot be  negative.");
      } else if (amount % 1000 != 0) {
        errorToast("Amount should be in multiple of 1000.");
      } else {
        setLoading(true);
        const payload = {
          investments: [
            {
              investment_amount: amount,
              pool_template_id: tenureInvestmentData["id"],
              investor_id: props.clientUuid,
            },
          ],
          is_profile_flow: 1,
        };
        poolsApi.sendPaymentLink(payload).then((res) => {
          if (res.kind === "ok") {
            if (
              res.data?.data?.profile_flow_investment_consent_success == true
            ) {
              successToast("Investment done successfully.");
              props.onInvestment();
            } else {
              successToast("Investment request sent successfully.");
            }
            setTimeout(() => {
              setLoading(false);
              setOpenClientInvestmentRequestModel(false);
              setAmount(0);
              eventLog({
                type: "investment_request",
                action: {
                  trigger: "true",
                },
              });
            }, 2000);
          } else {
            setLoading(false);
            errorToast(res.error);
            eventLog({
              type: "investment_request",
              action: {
                trigger: "false",
              },
            });
          }
        });
      }
    } else {
      errorToast("Please enter a valid amount.");
    }
  };

  return (
    <div className="client_growth_plan_div_container">
      <div className="client_growth_header">
        <div className="client_growth_header_text">Tenure</div>
        <div className="client_growth_header_content">
          {pools &&
            pools
              .slice() // Create a shallow copy of the array to avoid modifying the original
              .sort((a, b) => a.value - b.value)
              .map((item) => (
                <div
                  className={
                    selectedTenure == item.value
                      ? "client_growth_tenure_button_active"
                      : "client_growth_tenure_button"
                  }
                  onClick={() => {
                    setSelectedTenure(item.value);
                  }}
                >
                  {item.label}
                </div>
              ))}
        </div>
      </div>
      <div className="client_growth_card">
        <div className="client_growth_card_content_container">
          {tenureInvestmentData["total_invested"] != 0 && (
            <div className="client_growth_card_content">
              <div className="client_growth_card_content_title">
                Total Invested Amount
              </div>
              <div className="client_growth_card_content_value">
                ₹ {convertAmount(tenureInvestmentData["total_invested"])}
              </div>
            </div>
          )}
          {tenureInvestmentData["total_invested"] != 0 && (
            <div className="client_growth_card_content">
              <div className="client_growth_card_content_title">
                Number of Investments
              </div>
              <div className="client_growth_card_content_value">
                {tenureInvestmentData["investment_count"] < 10
                  ? `0${tenureInvestmentData["investment_count"]}`
                  : tenureInvestmentData["investment_count"]}
              </div>
            </div>
          )}
          <div className="client_growth_card_content">
            <div className="client_growth_card_content_title">
              Interest Rate p.a.
            </div>
            <div className="client_growth_card_content_value">
              {tenureInvestmentData["interest_rate"] != 0 ? "Up to" : ""}{" "}
              {tenureInvestmentData["interest_rate"].toFixed(2)}%
            </div>
          </div>
          {tenureInvestmentData["total_invested"] == 0 && (
            <div className="client_growth_card_content">
              <div className="client_growth_card_content_title">Tenure</div>
              <div className="client_growth_card_content_value">
                {selectedTenure == 1
                  ? props.overviewData.investments_by_tenure.find(
                      (item) =>
                        item.tenure == selectedTenure &&
                        item.pool_type !== null &&
                        parseInt(item.pool_type) == selectedPoolType
                    )?.tenure_type === 1
                    ? `${selectedTenure} Month`
                    : `${selectedTenure} Day`
                  : props.overviewData.investments_by_tenure.find(
                      (item) =>
                        item.tenure == selectedTenure &&
                        item.pool_type !== null &&
                        parseInt(item.pool_type) == selectedPoolType
                    )?.tenure_type === 1
                  ? `${selectedTenure} Months`
                  : `${selectedTenure} Days`}
              </div>
            </div>
          )}
          {tenureInvestmentData["total_invested"] == 0 && (
            <div className="client_growth_card_content">
              <div className="client_growth_card_content_title">
                Minimum Investment
              </div>
              <div className="client_growth_card_content_value">
                ₹ {convertAmount(tenureInvestmentData["minimum_investment"])}
              </div>
            </div>
          )}
        </div>
        <div className="client_growth_card_button_container">
          {tenureInvestmentData["total_invested"] != 0 && (
            <button
              className="client_growth_details_investment_main_header_button"
              onClick={() => setOpenClientGrowthPlanInvestmentsModel(true)}
              disabled={tenureInvestmentData["investment_count"] == 0}
              style={{
                borderColor:
                  tenureInvestmentData["investment_count"] == 0
                    ? "#AEAEAE"
                    : "#3A86FF",
                cursor:
                  tenureInvestmentData["investment_count"] == 0
                    ? "auto"
                    : "pointer",
              }}
            >
              <p
                className="client_growth_details_investment_main_header_button_text"
                style={{
                  fontSize: "20px",
                  fontWeight: "500",
                  color:
                    tenureInvestmentData["investment_count"] == 0
                      ? "#AEAEAE"
                      : "#3A86FF",
                }}
              >
                View Details
              </p>
            </button>
          )}
          <button
            className="client_growth_investment_button"
            onClick={() => {
              if (
                props.overviewData &&
                props.overviewData.investments_by_tenure.find(
                  (item) =>
                    item.tenure == selectedTenure &&
                    item.pool_type !== null &&
                    parseInt(item.pool_type) == selectedPoolType
                )?.is_display === 0
              ) {
                errorToast(
                  "This Investment Plan is currently not open for investment."
                );
              } else {
                setOpenClientInvestmentRequestModel(true);
              }
            }}
          >
            <p style={{ fontSize: "20px", fontWeight: "500" }}>
              {tenureInvestmentData["total_invested"] == 0
                ? "Invest Now"
                : "Invest More"}
            </p>
          </button>
        </div>
      </div>

      {/* Investment Request link */}
      <ClientInvestmentRequestModel
        openModel={openClientInvestmentRequestModel}
        amountInvalid={amountInvalid}
        amountInvalidText={amountInvalidText}
        setAmountInvalidText={setAmountInvalidText}
        setAmountInvalid={setAmountInvalid}
        handleClose={() => setOpenClientInvestmentRequestModel(false)}
        minimumInvestment={tenureInvestmentData["minimum_investment"]}
        tenure={tenureInvestmentData["tenure"]}
        tenureType={tenureInvestmentData["tenure_type"]}
        interest_rate={tenureInvestmentData["interest_rate"].toFixed(2)}
        type={tenureInvestmentData["pool_type"]}
        alreadyInvested={tenureInvestmentData["total_invested"]}
        walletBalance={props.walletBalance}
        selectedIndex={0}
        onKeyPress={onKeyPress}
        setAmount={(value) => setAmount(value)}
        loading={loading}
        onProceed={() => onInvestmentRequestProceed()}
      />

      {/* Growth Plan Investment Details*/}
      <ClientGrowthPlanInvestmentsModel
        openModel={openClientGrowthPlanInvestmentsModel}
        handleClose={() => setOpenClientGrowthPlanInvestmentsModel(false)}
        loading={loading}
        clientUuid={props.clientUuid}
        poolTemplateUuid={tenureInvestmentData["id"]}
        selectedPoolType={selectedPoolType}
      />
    </div>
  );
};

export default ClientGrowthPlan;
