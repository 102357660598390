import React, { useEffect,useState } from "react";
import Step from "./step";
import "./step.css";

const labelArray1 = ["Create your account", "Complete KYC","Add bank details"];
const labelArray2 = ["Create your account", "Complete KYC", "Upload documents","Add bank details"];
const labelStatusArray1=[0,0,0]
const labelStatusArray2=[0,0,0,0]



export default function StepNavigation(props) {

  const [labelArray,setLabelArray] = useState(labelArray1) ;
  const [currentStep,setCurrentStep] = useState(props.currentStep);
  const [labelStatus,setLabelStatus] = useState(labelStatusArray2);

  useEffect(()=>{ 
    if(props.currentStep==3 && props.skipOrgDocument){
      if(props.ifa && props.ifa.has_bank!=1){ 
        return setCurrentStep(2);
      }
    }

    setCurrentStep(props.currentStep);

  },[props.currentStep])

  useEffect(()=>{
    if(props.skipOrgDocument){
      setLabelArray(labelArray1)
      setLabelStatus([
        props.has_profile,
        props.has_document,
        props.has_bank
      ])
    }else{
      setLabelArray(labelArray2);
      setLabelStatus([
        props.has_profile,
        props.has_document,
        props.has_org_document,
        props.has_bank
      ])
    }
  },[props.skipOrgDocument,props.ifa])

  // console.log("Label Status",labelStatus)

  return (
    <div className="stepWrapper">
       {/* <span className="horizontal_bar"></span> */}
      {labelArray.map((item, index) => (
        <Step
          label={item}
          key={index}
          index={index}
          selected={currentStep === index } 
          currentStep={currentStep}
          status={labelStatus[index]}
        ></Step>
      ))}
    </div>
  );
}
