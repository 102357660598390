import React, { useState, useEffect } from "react";
import SampleToast from "../sample-toast/sample-toast";
import { errorToast, loadScript } from "../../utils/helper";
import FullScreenLoader from "../full-screen-loader/full-screen-loader";
import { eventLog } from "../../utils/firebase";
import { IFA_Payment_API } from "../../services/api";
const api = new IFA_Payment_API();

const Phonepe = (props) => {
  const [data, setData] = useState({});
  const [render, setRender] = useState(false);
  const [fullLoader, setFullLoader] = useState(false);

  useEffect(() => {
    generateOrder();
  }, []);

  useEffect(() => {
    loadScript("https://mercury.phonepe.com/web/bundle/checkout.js");

    let element = (
      <meta
        name="viewport"
        content="width=device-width, height=device-height, initial-scale=1.0, maximum-scale=1.0"
      />
    );
    document.head.insertAdjacentHTML("beforeend", element);
  }, []);

  useEffect(() => {
    if (data.token_url) {
      setRender(true);
    }
  }, [data]);

  const generateOrder = async () => {
    let params = {
      amount: props.data.amount,
      Mode: "IFA",
      UUID: props.data.UUID,
      investment_request_uuid: props.data.investment_request_uuid,
    };

    setFullLoader(true);
    await api.getSessionPaymentId(params).then((res) => {
      console.log("resp of ordr api", res);
      if (res.kind === "ok") {
        console.log("response::", res);
        eventLog({
          type: "payment_gateway_page",
          action: {
            trigger: "true",
          },
        });
        setData(res.data);
      } else {
        eventLog({
          type: "payment_gateway_page",
          action: {
            trigger: "false",
          },
        });
        setFullLoader(false);
        errorToast("Service not available");
      }
    });
  };

  const onScriptLoad = (tokenUrl) => {
    if (window.PhonePeCheckout) {
      window.PhonePeCheckout.transact({ tokenUrl });
    }
  };

  return (
    <React.Fragment>
      {render ? (
        <React.Fragment>
          {/* phonepe js checkout */}
          <div onLoad={onScriptLoad(data.token_url)}></div>
        </React.Fragment>
      ) : (
        <div />
      )}
      {fullLoader ? <FullScreenLoader /> : <div />}
      <SampleToast />
    </React.Fragment>
  );
};

export default Phonepe;
