import React, { useEffect, useState, useRef } from "react";
import "./clientkyc.css";
import Loader from "react-loader-spinner";
import {
  errorToast,
  getFileExtension,
  getUniqueUUID,
} from "../../utils/helper";
import { ErrorValue, InputInfoText } from "../../components";

const imageSizeLimit = 1;

const ClientNetWorth = function (props) {
  const netWorthRef = useRef(null);
  const [netWorthLoader, setNetWorthLoader] = useState(false);
  const [document, setDocument] = useState({
    document_net_worth_image:
      props.data.document_net_worth_image === undefined
        ? ""
        : props.data.document_net_worth_image,
    document_net_worth_image_data: {
      base64: null,
      size: null,
      content_type: null,
    },
  });

  // send document value
  useEffect(() => {
    props.value(document);
  }, [props, document]);

  // handle changes into input
  const onHandleChange = async (e, label) => {
    // net worth image
    if (label === "document_net_worth_image" && e.target.files[0]) {
      if (e.target.files[0].size > imageSizeLimit * 1024 * 1024) {
        errorToast(
          `Net Worth image size should not be more than ${imageSizeLimit} MB`
        );
      } else {
        setNetWorthLoader(true);
        let uuid = await getUniqueUUID();
        let ext = await getFileExtension(e);
        let fileName = `${uuid}.${ext}`;
        let reader = new FileReader(e.target.files[0]);
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = function (evt) {
          setTimeout(() => {
            setDocument({
              document_net_worth_image: fileName,
              document_net_worth_image_data: {
                base64: evt.target.result,
                size: e.target.files[0].size,
                content_type: e.target.files[0].type,
              },
            });
            setNetWorthLoader(false);
          }, 2000);
        };
        reader.onerror = function (error) {
          console.log("Error while uploading Net Worth image : ", error);
          setTimeout(() => {
            errorToast(`Net Worth image could not upload, Try again`);
            setNetWorthLoader(false);
          }, 2000);
        };
      }
    }
  };

  return (
    <div>
      {/* net worth image */}
      <div
        className="clientkyc_main_input_form"
        style={{
          marginTop: "20px",
        }}
      >
        <div
          className="clientkyc_main_image_div_container"
          style={{
            borderColor: props.error.hasOwnProperty("document_net_worth_image")
              ? props.error["document_net_worth_image"].length > 0
                ? "#d43939"
                : undefined
              : undefined,
          }}
        >
          <div
            className="clientkyc_main_image_div"
            style={{
              backgroundColor: props.disable === true ? "#ececec" : undefined,
              color: props.disable === true ? "rgb(119, 119, 119)" : undefined,
            }}
          >
            <p className="clientkyc_main_image_text">
              {document.document_net_worth_image}
            </p>
          </div>
          {props.disable === true ? (
            <div />
          ) : (
            <button
              onClick={() => netWorthRef.current.click()}
              className="clientkyc_main_image_button"
              style={{
                cursor: netWorthLoader ? "not-allowed" : "pointer",
                display:props.disable?'none':'initial'
              }}
              disabled={netWorthLoader}
            >
              {netWorthLoader ? (
                <Loader type="TailSpin" color="#fff" height={18} width={18} />
              ) : document.document_net_worth_image === "" ? (
                "Upload"
              ) : (
                "Change"
              )}
            </button>
          )}
          <input
            type="file"
            ref={netWorthRef}
            style={{ display: "none" }}
            onChange={(event) =>
              onHandleChange(event, "document_net_worth_image")
            }
          />
        </div>
        {/* info text */}
        {!props.disable? (
          <InputInfoText
            text={`Please upload the image in jpeg/png/pdf format. The maximum file size should not exceed ${imageSizeLimit} MB.`}
          />
        ) : (
          ""
        )}
        {/* error */}
        {props.error.hasOwnProperty("document_net_worth_image") ? (
          <ErrorValue text={props.error["document_net_worth_image"]} />
        ) : (
          <div />
        )}
      </div>
    </div>
  );
};

export default ClientNetWorth;
