import { API } from "aws-amplify";
import * as Types from "../api.types";

export class IFA_KYC_API {
  /**
   * @description this function is for send kyc link to client
   * @param data
   * @returns this return the payment data
   */
  async sendKycLink(data: any): Promise<Types.GetIFAKyc> {
    // payload
    const params = {
      body: data,
    };

    //call the api try and catch
    try {
      const response: any = await API.post("baseurl", "/ifa/kyclinks", params);
      return { kind: "ok", data: response.data };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
            errors: [],
          };
        } else {
          if (err.response.data.code === 422) {
            return {
              kind: "bad-data",
              error: err.response.data.message,
              errors: err.response.data.errors,
            };
          }
          return {
            kind: "bad-data",
            error: err.response.data.message,
            errors: [],
          };
        }
      }
      return { kind: "bad-data", error: err.response.data.message, errors: [] };
    }
  }

  /**
   * @description Method to upload documents of ifa
   * @param data
   * @returns success or faliure
   */
  async uploadKycDocuments(data: any): Promise<Types.GetIFAKycResult> {
    // payload
    const params = {
      body: data,
    };
    console.log("body before calling by gopi->", params);
    // call the api try and catch
    try {
      const response: any = await API.post(
        "baseurl",
        "/ifa/kycdocupload",
        params
      );
      return { kind: "ok", data: response.data || response.message || {} };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description Method to fetch ifa document/bank data
   * @param data
   * @returns success or faliure
   */
  async fetchDocuments(data: any): Promise<Types.GetIFADocumentResult> {
    // payload
    const params = {
      body: data,
    };

    // call the api try and catch
    try {
      const response: any = await API.post("baseurl", "/ifa/documents", params);
      return { kind: "ok", data: response.data || response.message || {} };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }
}
