import React, { useRef, useState, useEffect } from "react";
import "./account-creation.css";
import config from "../../../config/settings";
import { Auth } from "aws-amplify";
import { InputBox, Dropdown, SignupButton, ResendOtp } from "../../index";
import { useHistory } from "react-router-dom";
import Switch from "@mui/material/Switch";
import {
  checkSignupDisabled,
  checkValidEmail,
  checkPermittedEmail,
  checkValidGst,
  checkValidMobileNumber,
  convertIntoDefaultState,
  convertIntoErrorState,
  errorToast,
  successToast,
} from "../../../utils/helper";
import { eventLog } from "../../../utils/firebase";
import { useStores } from "../../../models";
import { Kyc_API, User_API } from "../../../services/api";
import { accountCreationState } from "../../../constant/signupState";

const maxOtpResendTime = 59;
const maxRetries = 3;

export default function AccountCreation(props) {
  const [accountCreation, setAccountCreation] = useState(
    convertIntoDefaultState(accountCreationState)
  );
  const [accountCreationError, setAccountCreationError] = useState(
    convertIntoErrorState(accountCreationState)
  );
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [hasButtonDisabled, sethasButtonDisabled] = useState(true);
  const [loader, setLoader] = useState(false);
  const [otp, setOtp] = useState("");
  const [isOtpInvalid, setIsOtpInvalid] = useState(false);
  const [hasOtpSent, setHasOtpSent] = useState(false);
  const [otpErrorText, setOtpErrorText] = useState(false);
  const [secondLeft, setSecondLeft] = useState(maxOtpResendTime);
  const [resendOtpCount, setResendOtpCount] = useState(maxRetries);
  const [user, setUser] = useState();
  const [countGst, setCountGst] = useState(maxRetries);
  const [permittedEmailError, setPermittedEmailError] = useState(false);

  const prohibitedEmails = ["@outlook.com", "@hotmail.com"];

  const rootStore = useStores();
  const navigate = useHistory();

  const api = new Kyc_API();
  const userApi = new User_API();

  useEffect(() => {
    //check for disablity of signup button
    let accountCreationTempState = accountCreationState;
    accountCreationTempState["gst_number"]["required"] = props.isGstRequired;

    sethasButtonDisabled(
      checkSignupDisabled(accountCreation, accountCreationTempState)
    );
  }, [accountCreation, props.isGstRequired]);

  const handleGstChange = (e) => {
    props.setIsGstRequired(e.target.checked);
  };

  useEffect(() => {
    props.setFirmName(accountCreation["firm_name"]);
  }, [accountCreation["firm_name"]]);

  /**
   * On click, first onSendOtp() function will raise a challenge
   * onSignUp() funtion will answer a challenge resulting in production of cognito ID
   */
  const handleSignup = (e) => {
    if (!hasOtpSent) {
      onSendOTP();
    } else {
      onSignUp();
    }
  };

  // send the otp
  const onSendOTP = async () => {
    if (!checkValidEmail(accountCreation["email"])) {
      setPermittedEmailError(false);
      setAccountCreationError({
        ...accountCreationError,
        email: true,
      });
    } else if (!checkPermittedEmail(accountCreation["email"])) {
      setPermittedEmailError(true);
      setAccountCreationError({
        ...accountCreationError,
        email: true,
      });
    } else if (!checkValidMobileNumber(accountCreation["mobile"])) {
      setAccountCreationError({
        ...accountCreationError,
        mobile: true,
      });
    } else {
      setAccountCreationError({
        ...accountCreationError,
        email: false,
        mobile: false,
      });
      setLoader(true);
      sethasButtonDisabled(true);
      setSecondLeft(maxOtpResendTime);
      setResendOtpCount(resendOtpCount - 1);
      const number = accountCreation["mobile"];
      const payload = {
        mobile: number,
        isIfa: true,
      };
      await userApi
        .sendMobileVerification(payload)
        .then((response) => {
          if (response.kind == "ok") {
            let userName = response.data.pan_number;
            if (
              !response.data.isExistingUser ||
              (response.data.isExistingUser && response.data.oldExistingUser)
            )
              userName = `+91${number}`;
            Auth.signIn(userName)
              .then((res) => {
                setUser(res);
                setLoader(false);
                sethasButtonDisabled(false);
                setHasOtpSent(true);
                successToast("OTP has been sent successfully.");
                eventLog({
                  type: "auth_otp_send",
                  action: {
                    trigger: "true",
                  },
                });
              })
              .catch((err) => {
                errorToast(
                  "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
                );
                eventLog({
                  type: "auth_otp_send",
                  action: {
                    trigger: "false",
                  },
                });
                setTimeout(() => {
                  setAccountCreation(
                    convertIntoDefaultState(accountCreationState)
                  );
                  setLoader(false);
                  sethasButtonDisabled(false);
                  setHasOtpSent(false);
                }, 5000);
              });
          } else {
            setLoader(false);
            errorToast(
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
            );
          }
        })
        .catch((error) => {
          console.log("Error while sending OTP ::: ", error);
          setLoader(false);
          errorToast(
            "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
          );
          eventLog({
            type: "auth_otp_send",
            action: {
              trigger: "false",
            },
          });
        });
    }
  };

  // on signup
  const onSignUp = async () => {
    if (otp.length === 0) {
      setIsOtpInvalid(true);
      setOtpErrorText("OTP cannot be empty");
    } else if (otp.length !== 4) {
      setIsOtpInvalid(true);
      setOtpErrorText("Invalid OTP");
    } else if (isNaN(parseInt(otp))) {
      setIsOtpInvalid(true);
      setOtpErrorText("OTP must be a number");
    } else {
      setLoader(true);
      sethasButtonDisabled(true);
      Auth.sendCustomChallengeAnswer(user, otp)
        .then(async (res) => {
          if (res && !res.Session) {
            const curr_user = await Auth.currentUserCredentials();

            if (props.isGstRequired) {
              await callGstApi(curr_user.identityId);
            } else {
              let payload = {
                ...accountCreation,
                uuid: curr_user.identityId,
              };
              await onSuccess(payload);
            }
          } else {
            setLoader(false);
            sethasButtonDisabled(false);
            setIsOtpInvalid(true);
            setOtpErrorText(
              "Wrong OTP. Please check and enter the correct OTP."
            );
          }
        })
        .catch((err) => {
          errorToast(
            "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
          );
          setTimeout(() => {
            setLoader(false);
            sethasButtonDisabled(false);
          }, 5000);
        });
    }
  };

  //verify gst number
  const callGstApi = async (ifaUUID) => {
    if (!checkValidGst(accountCreation["gst_number"])) {
      setAccountCreationError({
        ...accountCreationError,
        gst_number: true,
      });
      return;
    }

    // payload
    let payload = {
      gst_number: accountCreation["gst_number"],
      loan_app_id: ifaUUID,
    };
    if (countGst == 0) {
      errorToast("Number of retrials exhausted");
      setTimeout(() => {
        hasButtonDisabled(false);
      }, 2000);
    } else {
      setCountGst(countGst - 1);
      // call the api
      api
        .gstCheckApi(payload)
        .then(async (res) => {
          if (res.kind == "ok") {
            let gst_name = res?.data?.document_data?.name;
            let gst_address = res?.data?.document_data?.address;
            setAccountCreation({
              ...accountCreation,
              name: gst_name,
              address: gst_address,
            });
            const payload = {
              ...accountCreation,
              uuid: ifaUUID,
              name: gst_name,
              address: gst_address,
            };
            await onSuccess(payload);
          } else {
            // setTimeout(() => {
            errorToast(res.error);
            // }, 1000);
            setLoader(false);
            sethasButtonDisabled(false);
            eventLog({
              type: "auth_kyc_details",
              action: {
                trigger: "false",
              },
            });
            return false;
          }
        })
        .catch((err) => {
          errorToast(
            "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
          );
          setTimeout(() => {
            setLoader(false);
            sethasButtonDisabled(false);
          }, 5000);
        });
    }
  };

  //fetch IFA data
  const onSuccess = async (payload) => {
    rootStore.userData.getUserData(payload).then(async (res) => {
      if (res.kind === "ok") {
        localStorage.setItem("mobile", res.data.mobile);
        if (res.data.kyc_status === 0) {
          console.log("Res", res.data);
          if (res.data.flow_type == "sign_up") {
            setLoader(false);
            sethasButtonDisabled(false);
            setHasOtpSent(false);
            props.setCurrentStep(1); //moving to kyc details page
            eventLog({
              type: "auth_sign_up",
              action: {
                trigger: "true",
              },
            });
            eventLog({
              type: "auth_kyc_details",
              action: {
                trigger: "true",
              },
            });
          } else {
            Auth.signOut()
              .then(() => {
                errorToast(
                  "You are already our partner. Please login to complete your KYC.",
                  1
                );
                setTimeout(() => {
                  setLoader(false);
                  sethasButtonDisabled(false);
                  setHasOtpSent(false);
                  localStorage.clear();
                  props.setShowPage("Login");
                }, 5000);
              })
              .catch((err) => {
                errorToast(
                  "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
                );
                setTimeout(() => {
                  setLoader(false);
                  sethasButtonDisabled(false);
                  setHasOtpSent(false);
                }, 5000);
              });
          }
        } else if (res.data.kyc_status === 1) {
          Auth.signOut()
            .then(() => {
              errorToast(
                "You are already our partner. Your KYC is still in review. Please be patient while we are reviewing your documents.",
                1
              );
              setTimeout(() => {
                setLoader(false);
                sethasButtonDisabled(false);
                setHasOtpSent(false);
                localStorage.clear();
              }, 5000);
            })
            .catch((err) => {
              errorToast(
                "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
              );
              setTimeout(() => {
                setLoader(false);
                sethasButtonDisabled(false);
                setHasOtpSent(false);
              }, 5000);
            });
        } else if (res.data.kyc_status === 2) {
          successToast(
            "You are already our partner. You have successfully logged in"
          );
          eventLog({
            type: "auth_login_in",
            action: {
              trigger: "true",
            },
          });
          setTimeout(() => {
            navigate.push("/overview");
          }, 2000);
        } else if (res.data.kyc_status === 3) {
          errorToast(
            "Your KYC has been rejected. Please upload your rejected documents.",
            1
          );
          setTimeout(() => {
            setLoader(false);
            sethasButtonDisabled(false);
            setHasOtpSent(false);
            props.setCurrentStep(1); //moving to kyc details pages
          }, 5000);
        } else {
          errorToast(
            "There is something wrong with your KYC status. Please contact support team.",
            1
          );
          setTimeout(() => {
            setLoader(false);
            sethasButtonDisabled(false);
            setHasOtpSent(false);
          }, 5000);
        }
      } else {
        errorToast(res.error);
        setOtp("");
        setTimeout(() => {
          setLoader(false);
          sethasButtonDisabled(false);
          setHasOtpSent(false);
        }, 5000);
      }
    });
  };

  return (
    <React.Fragment>
      {/* form */}
      <form
        className="account_creation_form_container"
        onSubmit={props.handleSubmit}
      >
        {/* radio button */}
        <div className="account_creation_gst_toggle_box">
          {/* question */}
          <p
            style={{
              fontSize: "16px",
              color: "#414141",
              fontWeight: 500,
              marginLeft: -5,
            }}
          >
            Do you have a GST number?
          </p>

          {/* no */}
          <p
            style={{
              fontSize: "16px",
              color: "#414141",
              fontWeight: 500,
              marginRight: -5,
            }}
          >
            No
          </p>
          {/* switch */}
          <Switch
            checked={props.isGstRequired}
            onChange={handleGstChange}
            inputProps={{ "aria-label": "controlled" }}
            sx={{ "& .MuiSwitch-thumb": { color: "#3a86ff" } }}
            disabled={false}
          />
          {/* yes */}
          <p
            style={{
              fontSize: "16px",
              color: "#414141",
              fontWeight: 500,
              marginLeft: -5,
            }}
          >
            Yes
          </p>
        </div>

        <InputBox
          label="GST Number"
          id="gst_number"
          type={accountCreationState["gst_number"]["type"]}
          maxLength="15"
          disabled={hasOtpSent}
          value={accountCreation["gst_number"]}
          setValue={setAccountCreation}
          object={accountCreation}
          required={
            accountCreationState["gst_number"]["required"] ||
            props.isGstRequired
          }
          visibility={props.isGstRequired}
          errorObject={accountCreationError}
          setErrorObject={setAccountCreationError}
          isError={accountCreationError["gst_number"]}
          errorText="GST identification number is not valid."
        />

        {/*email input box*/}
        <InputBox
          label="Email"
          id="email"
          type={accountCreationState["email"]["type"]}
          disabled={hasOtpSent}
          value={accountCreation["email"]}
          setValue={setAccountCreation}
          object={accountCreation}
          required={accountCreationState["email"]["required"]}
          errorObject={accountCreationError}
          setErrorObject={setAccountCreationError}
          isError={accountCreationError["email"]}
          errorText={
            !permittedEmailError
              ? "Invalid email. Please enter a valid email."
              : `Please provide an alternate email ID. Email IDs ending in ${prohibitedEmails.join(
                  " or "
                )} will not be accepted.`
          }
        />

        {/* */}
        <div className="account_creation_input_box_flex_container">
          <InputBox
            label="Mobile Number"
            id="mobile"
            type={accountCreationState["mobile"]["type"]}
            maxLength="10"
            disabled={hasOtpSent}
            value={accountCreation["mobile"]}
            setValue={setAccountCreation}
            object={accountCreation}
            required={accountCreationState["mobile"]["required"]}
            errorObject={accountCreationError}
            setErrorObject={setAccountCreationError}
            isError={accountCreationError["mobile"]}
            errorText="Invalid Mobile Number"
          />
          <Dropdown
            id="firm_name"
            dropdownList={config.documents.firm.types}
            value={accountCreation["firm_name"]}
            disabled={hasOtpSent}
            isActive={isDropdownOpen}
            setIsActive={setIsDropdownOpen}
            setValue={setAccountCreation}
            object={accountCreation}
            label="Type of Entity"
            required={accountCreationState["firm_name"]["required"]}
            placeholder="Select one"
            isError={accountCreationError["firm_name"]}
            errorText="Invalid firm name"
          />
        </div>
        <InputBox
          label="OTP"
          id="otp"
          type="number"
          maxLength="4"
          value={otp}
          setValue={setOtp}
          visibility={hasOtpSent ? true : false}
          isError={isOtpInvalid ? true : false}
          error={isOtpInvalid}
          setError={setIsOtpInvalid}
          errorText={otpErrorText}
          RightLabelComponent={() => (
            <ResendOtp
              resendOtpCount={resendOtpCount}
              secondLeft={secondLeft}
              setSecondLeft={setSecondLeft}
              resendOtp={onSendOTP}
              maxOtpResendLimit={maxRetries}
            />
          )}
        />

        {/* submit */}
        <SignupButton
          loader={loader}
          disabled={hasButtonDisabled}
          text={hasOtpSent ? "Sign Up" : "Send OTP"}
          onSubmit={handleSignup}
        />
      </form>
      <footer className="account_creation_footer">
        <p className="account_creation_footer_text">Already Registered?</p>
        <p
          className="account_creation_text_link"
          onClick={() => {
            props.show("Login");
            localStorage.clear();
          }}
        >
          Login
        </p>
      </footer>
    </React.Fragment>
  );
}
