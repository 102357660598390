import "./investortype.css";
import React, { useState, useEffect } from "react";
import { investor_type } from "../../config/settings";
import Loader from "react-loader-spinner";

const InvestorTypeComponent = (props) => {
  const [type, setType] = useState("Individual");
  const [loader, setLoader] = useState(false);
  const [hideInvestorSelector, setHideInvestorSelector] = useState(false);
  const firmtypes = investor_type;

  const onHandleChange = (event) => {
    setType(event.target.value);
  };

  const OnProceed = () => {
    //proceed with selected type
    setLoader(true);
    setHideInvestorSelector(true);
    props.data({
      investortype: type,
      clientPreCheck: true,
      entity_type: type == "Individual" ? false : true,
    });
  };
  return (
    <>
      {hideInvestorSelector === false ? (
        <div className="investortype_main_container">
          <div>
            <lable className="investortype_main_header">Investor Type</lable>
          </div>
          <div className="investortype_container">
            <div className="investortype_input_conatiner">
              <label className="investortype_label">
                <input
                  type="radio"
                  name="Investor_type"
                  value={"Individual"}
                  onChange={onHandleChange}
                  className="radioClass"
                  checked={type == "Individual" ? true : false}
                />
                Individual
              </label>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <div
                style={{ flex: 1, height: "1px", backgroundColor: "black" }}
              />

              <div>
                <p
                  style={{
                    textAlign: "center",
                    backgroundColor: "#D9D9D9",
                    padding: "10px",
                    borderRadius: "25px",
                  }}
                >
                  OR
                </p>
              </div>

              <div
                style={{ flex: 1, height: "1px", backgroundColor: "black" }}
              />
            </div>

            <div className="investor_entity_container">
              <span className="investortype_main_header">Entity Type</span>

              {firmtypes.map((data, value) => {
                return (
                  <div className="investor_entity_label_conatiner">
                    <label className="investortype_label">
                      <input
                        type="radio"
                        name="Investor_type"
                        value={data.value}
                        key={data.label}
                        className="radioClass"
                        onChange={onHandleChange}
                      />
                      {data.label}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="investor_type_proceed">
            <div className="investor_type_proceed_button" onClick={OnProceed}>
              {loader ? (
                <Loader
                  type="TailSpin"
                  color="#FFFFFF"
                  height={20}
                  width={20}
                />
              ) : (
                <p className="investor_type_proceed_button_text">Proceed</p>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default InvestorTypeComponent;
