import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/Warning";
import InfoIcon from "@mui/icons-material/Info";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import imageCompression from "browser-image-compression";
import { deflate } from "zlib";

import {
  orgDocumentState,
  clientEntityDetails,
  clientProprietorDetails,
  clientNomineeDetails,
  clientKycDocumentDetails,
  clientBankDetails,
} from "../constant/signupState";
import { Kyc_API } from "../services/api";
import mineTypes from "../constant/mine-types";
import React, { useState, useEffect, useRef } from "react";
import html2pdf from "html2pdf.js";
import { ConsentRequestModel } from "../components";

/**
 * @author Abhay Upadhyay
 * @description This function is for success message toast
 * @param {string} msg
 * @param {number} status
 * @returns Toast message
 */

export function successToast(msg, status = false) {
  toast.success(msg, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
    icon: () =>
      status ? (
        <AnnouncementIcon
          style={{
            fontSize: "24px",
            color: "#409E13",
          }}
        />
      ) : (
        <CheckCircleIcon
          style={{
            fontSize: "24px",
            color: "#409E13",
          }}
        />
      ),
    style: {
      backgroundColor: "rgb(240,249,236)",
      border: "1px solid #409E13",
      color: "#409E13",
      fontSize: "14px",
      fontFamily: "Asap",
    },
  });
}
/**
 * @description End
 */

export function warningToast(msg, status = false) {
  toast.success(msg, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
    icon: () =>
      status ? (
        <AnnouncementIcon
          style={{
            fontSize: "24px",
            color: "#e77b00",
          }}
        />
      ) : (
        <WarningIcon
          style={{
            fontSize: "24px",
            color: "#e77b00",
          }}
        />
      ),
    style: {
      backgroundColor: "rgb(255, 235, 214)",
      border: "1px solid #e77b00",
      color: "#e77b00",
      fontSize: "14px",
      fontFamily: "Asap",
    },
  });
}
/**
 * @description End
 */

/**
 * @author Abhay Upadhyay
 * @description This function is for error message toast
 * @param {string} msg
 * @param {number} status
 * @returns Toast message
 */

export function errorToast(msg, status = false) {
  toast.error(msg, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
    icon: () =>
      status ? (
        <InfoIcon
          style={{
            fontSize: "24px",
            color: "#D92B2B",
          }}
        />
      ) : (
        <ReportProblemIcon
          style={{
            fontSize: "24px",
            color: "#D92B2B",
          }}
        />
      ),
    style: {
      backgroundColor: "rgb(247,224,225)",
      border: "1px solid #D92B2B",
      color: "#D92B2B",
      fontSize: "14px",
      fontFamily: "Asap",
    },
  });
}

// info toast
export function infoToast(msg) {
  toast.success(msg, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
    icon: () => (
      <InfoIcon
        style={{
          fontSize: "24px",
          color: "#4d94ff",
        }}
      />
    ),
    style: {
      backgroundColor: "rgb(230, 240, 255)",
      border: "1px solid #4d94ff",
      color: "#4d94ff",
      fontSize: "14px",
      fontFamily: "Asap",
    },
  });
}
/**
 * @description End
 */

/**
 * @author Abhay Upadhyay
 * @description This function is for formatting amount into Lakh and Cr.
 * @param {any} value
 * @returns formatted date
 */

export function convertAmount(value, decimalPlace = 2) {
  var val = Math.abs(value);

  return amountComma(val.toFixed(decimalPlace));
  // if (val >= 10000000) {
  //   val = (val / 10000000).toFixed(2) + " Cr";
  //   return amountComma(val);
  // } else if (val >= 100000) {
  //   val = (val / 100000).toFixed(2) + " Lac";
  //   return amountComma(val);
  // } else {
  //   return amountComma(val.toFixed(2));
  // }
}
/**
 * @description End
 */

/**
 * @author Abhay Upadhyay
 * @description This function is for adding comma between amount in (IN Rupees) format
 * @param {any} data
 * @returns amount with comma seperator
 */

export function amountComma(data) {
  let req = data;
  let res;
  if (req.toString().split(".")[0].length > 3) {
    res =
      req
        .toString()
        .substring(0, req.toString().split(".")[0].length - 3)
        .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
      "," +
      req.toString().substring(req.toString().split(".")[0].length - 3);
  } else {
    res = req.toString();
  }
  return res;
}
/**
 * @description End
 */

/**
 * @author Abhay Upadhyay
 * @description This function is for formatting raw date into actual date
 * @param {string} str
 * @returns formatted date
 */

export function getFormattedDate(str, repayment = false) {
  let output;
  if (repayment) {
    let data = str.split(" ");
    let d = data[0].substring(0, 2);
    let m = data[1].replace(",", "");
    let y = data[2];
    output = `${d}/${m}/${y}`;
  } else {
    var arr = str.split(" ");
    var [y, m, d] = arr[0].split("-");
    if (d < 10) {
      d = "0" + d.substring(1);
    }
    output = `${d}/${m}/${y}`;
  }
  return output;
}
/**
 * @description End
 */

/**
 * @author Abhay Upadhyay
 * @description This function is to get unique uuid
 * @returns unique uuid
 */

export async function getUniqueUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
/**
 * @description End
 */

/**
 * @author Abhay Upadhyay
 * @description This function is to get file name extension
 * @returns file extension
 */

export async function getFileExtension(event) {
  if (
    !event ||
    !event.target ||
    !event.target.files ||
    event.target.files.length === 0
  ) {
    return "";
  }
  const name = event.target.files[0].name;
  const lastDot = name.lastIndexOf(".");
  const ext = name.substring(lastDot + 1).toLowerCase();
  return ext;
}
/**
 * @description End
 */

export function timeStampHelper(a, b) {
  if (a === b || b == null) {
    return true;
  }
  if (a == null) {
    return false;
  }
  var arr1 = a.split(" ");
  var arr2 = b.split(" ");

  if (arr1[0] == arr2[0] && arr1[1] == arr2[1]) {
    return arr1[2] == "PM" ? true : false;
  } else if (arr1[0] == arr2[0]) {
    if (arr1[2] == arr2[2]) {
      var time1 = arr1[1].split(":");
      var time2 = arr2[1].split(":");

      if (time1[0] == time2[0]) {
        return time1[1] > time2[1] ? true : false;
      } else {
        return time1[0] > time2[0] ? true : false;
      }
    } else {
      return arr1[2] == "PM" ? true : false;
    }
  } else {
    var date1 = arr1[0].split("/");
    var date2 = arr2[0].split("/");

    if (date1[2] == date2[2] && date1[1] == date2[1]) {
      return date1[0] > date2[0] ? true : false;
    } else if (date1[2] == date2[2]) {
      return date1[1] > date2[1] ? true : false;
    } else {
      return date1[2] > date2[2] ? true : false;
    }
  }
}

export function sorting(a, b, selected, selectedType, type) {
  var asc_postive = 1;
  var asc_negative = -1;

  if (type == "desc") {
    asc_postive = -1;
    asc_negative = 1;
  }
  if (selectedType == "number") {
    if (parseFloat(a[selected]) - parseFloat(b[selected]) > 0) {
      return asc_postive;
    } else if (parseFloat(a[selected]) - parseFloat(b[selected]) < 0) {
      return asc_negative;
    } else {
      return 0;
    }
  }
  if (selectedType == "timestamp") {
    return timeStampHelper(String(a[selected]), String(b[selected]))
      ? asc_postive
      : asc_negative;
  }
  if (
    String(a[selected]).trim().toLowerCase() <
    String(b[selected]).trim().toLowerCase()
  ) {
    return asc_negative;
  }
  if (
    String(a[selected]).trim().toLowerCase() >
    String(b[selected]).trim().toLowerCase()
  ) {
    return asc_postive;
  }

  return 0;
}

export function getFrontendStateObject() {
  var object = {};
  if (window.location.hostname == "localhost") {
    object["url"] = "http://localhost:3000";
    object["stage"] = "me";
  }
  // if runs on server
  else {
    if (process.env.REACT_APP_ENV == "production") {
      object["url"] = "https://ifa.arthmatedirect.com";
      object["stage"] = "prod";
    } else if (process.env.REACT_APP_ENV == "uat") {
      object["url"] = "https://ifa-uat.arthmatedirect.com";
      object["stage"] = "uat";
    } else {
      object["url"] = "https://ifa-dev.arthmatedirect.com";
      object["stage"] = "dev";
    }
  }

  return object;
}

export function loadScript(url, callback) {
  /*
  let script = document.createElement("script")
    script.type = "text/javascript";

    if (script.readyState){  //IE
        script.onreadystatechange = function(){
            if (script.readyState == "loaded" ||
                    script.readyState == "complete"){
                script.onreadystatechange = null;
                callback();
            }
        };
    } else {  //Others
        script.onload = function(){
            callback();
        };
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
  */
  var tag = document.createElement("script");
  tag.async = false;
  tag.defer = true;
  tag.src = url;
  var body = document.getElementsByTagName("body")[0];
  body.appendChild(tag);
}

export function queryStringToJSON(qs) {
  if (qs[0] == "?") {
    qs = qs.substring(1);
  }

  var pairs = qs.split("&");
  var result = {};
  pairs.forEach(function (p) {
    var pair = p.split("=");
    var key = pair[0];
    var value = decodeURIComponent(pair[1] || "");

    if (result[key]) {
      if (Object.prototype.toString.call(result[key]) === "[object Array]") {
        result[key].push(value);
      } else {
        result[key] = [result[key], value];
      }
    } else {
      result[key] = value;
    }
  });

  return JSON.parse(JSON.stringify(result));
}

//get current date and time in YYYY-MM-DD HH:MM:SS format
export function dateTimeFormat() {
  var date = new Date();
  var dateStr =
    date.getFullYear() +
    "-" +
    ("00" + (date.getMonth() + 1)).slice(-2) +
    "-" +
    ("00" + date.getDate()).slice(-2) +
    " " +
    ("00" + date.getHours()).slice(-2) +
    ":" +
    ("00" + date.getMinutes()).slice(-2) +
    ":" +
    ("00" + date.getSeconds()).slice(-2);

  return dateStr;
}

export function yearsBefore(numOfYears) {
  var maxDate = new Date();
  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  var startYear = yyyy - numOfYears;
  var maxYear = today.getFullYear() - 18;
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  today = yyyy + "-" + mm + "-" + dd;
  var y = startYear + "-" + mm + "-" + dd;
  maxDate = maxYear + "-" + mm + "-" + dd;
  return {
    cDate: today,
    sDate: y,
    mDate: maxDate,
  };
}

export async function imageCompressor(imageFile) {
  var controller = new AbortController();

  var options = {
    // other options here
    maxSizeMB: 1,
    maxWidthOrHeight: 1024,
    useWebWorker: true,
    signal: controller.signal,
  };

  return await imageCompression(imageFile, options);
}

export function blobToBase64(blob, callback) {
  var reader = new FileReader();
  reader.onload = function () {
    var dataUrl = reader.result;
    var base64 = dataUrl.split(",")[1];
    callback(base64, blob);
  };
  reader.readAsDataURL(blob);
}

export function generateUUID(length = 16) {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(length);
  });
}

export async function getBase64FromUrl(url) {
  const data = await fetch(url);
  const blob = await data.blob();

  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
}

export function compressPdfBlob(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const data = new Uint8Array(reader.result);
      deflate(data, (err, compressedData) => {
        if (err) {
          reject(err);
        } else {
          const compressedBlob = new Blob([compressedData], {
            type: "application/pdf",
          });
          console.log("compressed blob", compressedBlob);
          resolve(compressedBlob);
        }
      });
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(blob);
  });
}

export function dateIntoWords(date) {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const ordinalNumbers = [
    "th",
    "st",
    "nd",
    "rd",
    "th",
    "th",
    "th",
    "th",
    "th",
    "th",
  ];

  const day = date.getDate();

  const monthIndex = date.getMonth();
  const monthName = monthNames[monthIndex];

  const year = date.getFullYear();

  return `${day}<sup>${ordinalNumbers[day % 10]}</sup> ${monthName}, ${year}`;
}

export function convertTimestampFormat(date) {
  const year = date.substring(0, 4);
  const month = date.substring(5, 7);
  const day = date.substring(8, 10);
  const hour = date.substring(11, 13);
  const min = date.substring(14, 16);
  const sec = date.substring(17, 19);

  return `${day}-${month}-${year} ${hour}:${min}:${sec}`;
}

/**
 *
 * @param {*} arrayOfObjects
 */
export function convertIntoDefaultState(object) {
  var objectTemp = {};

  for (let [key, value] of Object.entries(object)) {
    objectTemp[key] = value.defaultValue;
  }

  return objectTemp;
}

/**
 *
 * @param {*} arrayOfObjects
 */
export function convertIntoErrorState(object) {
  var objectTemp = {};

  for (let [key, value] of Object.entries(object)) {
    objectTemp[key] = false;
  }

  return objectTemp;
}

/**
 *
 * @param {*} stateObject
 * @param {*} mainObject
 * @returns
 */
export function checkSignupDisabled(stateObject, mainObject) {
  let disabledFlag = false;

  for (let [key, value] of Object.entries(stateObject)) {
    if (mainObject[key] && mainObject[key].required) {
      if (!value) {
        disabledFlag = true;
        break;
      }
    }
  }

  return disabledFlag;
}

export function checkSignupErrorDisabled(stateObject, mainObject) {
  let disabledFlag = false;

  for (let [key, value] of Object.entries(stateObject)) {
    if (value === true) {
      disabledFlag = true;
      break;
    }
  }
  return disabledFlag;
}

/**
 *
 * @param {*} mobile
 * @returns
 */
export function checkValidMobileNumber(mobile) {
  var mobile_format = /^[6-9]\d{9}$/;
  if (!mobile_format.test(String(mobile).toLowerCase())) {
    return false;
  }

  return true;
}

export function checkValidEmail(email) {
  var mail_format =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!mail_format.test(String(email).toLowerCase())) {
    return false;
  }

  return true;
}
export function checkValidEmailV2(email) {
  var mail_format =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!mail_format.test(String(email).toLowerCase())) {
    return false;
  }

  return true;
}

export function checkPermittedEmail(email) {
  var mail_format =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(?!hotmail.com|outlook.com)(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!mail_format.test(String(email).toLowerCase())) {
    return false;
  }

  return true;
}

export function checkValidGst(gstNumber) {
  var reggst = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
  if (!reggst.test(gstNumber) && gstNumber != "") {
    return false;
  }

  return true;
}

export function checkValidPan(pan) {
  const panRegex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
  //if pan is not valid upload pan image
  if (!panRegex.test(pan) && pan != "") {
    // setUploadPan(true);
    return false;
  }

  return true;
}

export function checkValidIfsc(ifscCode) {
  const ifscRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
  if (!ifscRegex.test(ifscCode) && ifscCode != "") {
    //if pan is not valid upload pan image 
    return false;
  }

  return true;
}

export function isOver18(dateString) {
  // Check if the string is a valid date
  const date = new Date(dateString);
  if (isNaN(date) || date.toString() === "Invalid DOB") {
    return false;
  }
  // Calculate age
  const today = new Date();
  const birthDate = new Date(date);
  const age = today.getFullYear() - birthDate.getFullYear();
  if (date.getFullYear() < 1850) {
    return false;
  }
  // Check if age is greater than or equal to 18
  if (age < 18) {
    return false;
  }
  return true;
}

export function convertDateFormat(date) {
  console.log("date", date);
  const formatDate = new Date(date);
  const yyyy = formatDate.getFullYear();
  let mm = formatDate.getMonth() + 1; // Months start at 0!
  let dd = formatDate.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  return dd + "-" + mm + "-" + yyyy;
}

/**
 *
 * @param {*} date
 * @returns age
 */

export function getAge(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

export function checkValidAadhaar(aadhaar) {
  if (!aadhaar || aadhaar.length == 0) {
    return false;
  }

  const aadhaarRegex = /^[2-9]{1}[0-9]{3}\s{1}[0-9]{4}\s{1}[0-9]{4}$/;

  var formattedAadhaar = aadhaar.toString().replace(/\d{4}(?=.)/g, "$& ");

  if (!aadhaarRegex.test(formattedAadhaar)) {
    return false;
  }

  return true;
}

/**
 *
 * @param {*} ifaData
 * @param {*} mainObject
 * @param {*} setValue
 */
export function fillKycData(ifaData, mainObject, setValue) {
  let tempData = { ...mainObject };
  for (let key in mainObject) {
    if (
      mainObject.hasOwnProperty(key) &&
      ifaData &&
      ifaData[key] &&
      ifaData[key] != "" &&
      ifaData[key].length != 0
    ) {
      tempData = {
        ...tempData,
        [key]: ifaData[key],
      };
    }
  }

  setValue(tempData);
}

export function setDisableState(data, mainObject, setValue) {
  let tempData = { ...mainObject };
  for (let key in mainObject) {
    if (!data.hasOwnProperty(key) || data[key] === null || data[key] === "") {
      tempData = {
        ...tempData,
        [key]: false,
      };
    } else {
      tempData = {
        ...tempData,
        [key]: true,
      };
    }
  }
  setValue(tempData);
}

/**
 *
 * @param {*} firmName
 * @returns
 */
export function getFirmAssociatedOrgDocuments(firmName) {
  let document = [];
  for (let key in orgDocumentState) {
    if (key != "other_document") {
      if (orgDocumentState[key].associatedFirm.includes(firmName)) {
        document.push(key);
      }
    }
  }

  return document;
}

export function getClientEntityAssociatedOrgDocuments(state, investorType) {
  let document = [];
  for (let key in state) {
    if (
      key != "other_document" &&
      key != "entity_document_1" &&
      key != "entity_document_2" &&
      key != "date_of_incorporation" &&
      key != "entity_registered_address" &&
      key != "entity_name" &&
      key != "entity_address_proof" &&
      key != "entity_current_locality" &&
      key != "entity_current_city" &&
      key != "entity_current_state" &&
      key != "entity_current_pin" &&
      key != "beneficial_owner_1_pan" &&
      key != "beneficial_owner_1_address_proof" &&
      key != "beneficial_owner_1_selfie" &&
      key != "beneficial_owner_2_pan" &&
      key != "beneficial_owner_2_address_proof" &&
      key != "beneficial_owner_2_selfie" &&
      key != "beneficial_owner_3_pan" &&
      key != "beneficial_owner_3_address_proof" &&
      key != "beneficial_owner_3_selfie" &&
      key != "beneficial_owner_4_pan" &&
      key != "beneficial_owner_4_address_proof" &&
      key != "beneficial_owner_4_selfie" &&
      key != "beneficial_owner_5_pan" &&
      key != "beneficial_owner_5_address_proof" &&
      key != "beneficial_owner_5_selfie" &&
      key != "beneficial_owner_6_pan" &&
      key != "beneficial_owner_6_address_proof" &&
      key != "beneficial_owner_6_selfie" &&
      key != "beneficial_owner_1_pan"
    ) {
      if (state[key].associatedFirm.includes(investorType)) {
        document.push(key);
      }
    }
  }

  return document;
}

/**
 *
 * @param {*} firmName
 * @returns
 */
export function checkHasOrgDocuments(firmName, documents) {
  for (let key in orgDocumentState) {
    if (
      key != "other_document" &&
      orgDocumentState[key].associatedFirm.includes(firmName)
    ) {
      if (!documents[key] || documents[key].length == 0) {
        return false;
      }
    }
  }

  return true;
}

export function convertUTCDateToLocalDate(date) {
  date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  const yyyy = date.getFullYear();
  let mm = date.getMonth() + 1; // Months start at 0!
  let dd = date.getDate();
  let hh = date.getHours();
  let min = date.getMinutes();
  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;
  if (hh < 10) hh = "0" + hh;
  if (min < 10) min = "0" + min;
  return yyyy + "/" + mm + "/" + dd + " " + hh + ":" + min;
}

export function convertedAsLocal(formattedString) {
  if (formattedString != null) {
    console.log("formattedString", formattedString);
    const [datePart, timePart] = formattedString.toString().split(" ");
    console.log(datePart, timePart);
    const [year, month, day] = datePart.split("-");
    console.log(year, month, day);

    const formatted = `${year}/${month.padStart(2, "0")}/${day.padStart(
      2,
      "0"
    )} ${timePart}`;

    return formatted;
  }
}

export async function verifyNameMatch(firstName, secondName) {
  //Name matching function

  if (
    !firstName ||
    firstName.length == 0 ||
    !secondName ||
    secondName.length == 0
  ) {
    return false;
  }
  const api = new Kyc_API();

  // payload
  let payload = {
    pan_name: firstName,
    other_name: secondName,
  };

  try {
    // call the api
    const res = await api.nameMatchApi(payload);

    if (res.kind == "ok") {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
}

export function convertAccountSpaces(account) {
  return `${account.substring(0, 4)} ${account.substring(
    4,
    8
  )} ${account.substring(8, 12)} ${account.substring(
    12,
    14
  )} ${account.substring(14, account.length)}`;
}

/**
 *
 * @param {*} data
 * @param {*} filename
 */
export function downloadBase64File(data, filename) {
  var file = filename.split(".");

  const linkSource = `data:${mineTypes[file[1]]};base64,${data}`;
  const downloadLink = document.createElement("a");
  const fileName = filename;

  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}

/**
 *
 * @param {*} html
 * @param {*} filename
 */
export function generateHTMLPDFFile(html, filename) {
  const options = {
    filename: filename,
    margin: [0.5, 0.5, 0.5, 0.5],
    image: { type: "jpeg", quality: 0.98 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
  };

  html2pdf().set(options).from(html).save();
}

/**
 *
 * @param {*} html
 * @param {*} filename
 */
export function generateHTMLPDFBase64(html) {
  const options = {
    filename: "",
    margin: [0.5, 0.5, 0.5, 0.5],
    image: { type: "jpeg", quality: 0.98 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
  };

  return new Promise((resolve, reject) => {
    html2pdf()
      .set(options)
      .from(html)
      .outputPdf()
      .then((pdf) => {
        // This logs the right base64
        const padfBase64 = window.btoa(pdf);
        resolve(padfBase64);
      })
      .catch((err) => {})
      .catch((err) => {
        console.log("err >>", err);
      });
  });
}

export function checkForAddressType(address_type) {
  if (address_type == "Aadhaar Card") {
    return 4;
  } else if (address_type == "Passport") {
    return 1;
  } else if (address_type == "Driving License") {
    return 3;
  } else if (address_type == 4) {
    return "Aadhaar Card";
  } else if (address_type == 1) {
    return "Passport";
  } else if (address_type == 3) {
    return "Driving License";
  }
}

/**
 * @description This function is for checking if entity required details is populated
 * @param  data , investor_type, clientEntityDetails
 * @returns boolean
 */

export function checkForEntityRequiredDetails(
  data,
  investor_type,
  clientEntityDetails,
  clientProprietorDetails
) {
  let enityObject = {
    ...clientEntityDetails,
    ...clientProprietorDetails,
  };

  for (let key in data) {
    let key_object = enityObject[key];
    if (
      investor_type == "sole_proprietorship" &&
      (key == "entity_document_1" || key == "entity_document_2")
    ) {
      if (
        data[key] == null ||
        data[key].document == null ||
        data[key].document_type == null
      ) {
        return false;
      }
    } else if (
      key_object?.associatedFirm?.includes(investor_type) &&
      key_object.required === true &&
      (data[key] == null || data[key] == "")
    ) {
      return false;
    } else {
      continue;
    }
  }
  return true;
}

export function checkForEntityRequiredDetailsForErrorState(
  data,
  investor_type,
  clientEntityDetails,
  clientProprietorDetails
) {
  let enityObject = {
    ...clientEntityDetails,
    ...clientProprietorDetails,
  };

  let pinRegex = /^[1-9][0-9]{5}$/;

  let errorField = {};

  for (let key in data) {
    let key_object = enityObject[key];
    if (
      investor_type == "sole_proprietorship" &&
      (key == "entity_document_1" || key == "entity_document_2")
    ) {
      if (
        data[key] == null ||
        data[key].document == null ||
        data[key].document_type == null
      ) {
        errorField[key] = true;
      }
    } else if (
      key_object?.associatedFirm?.includes(investor_type) &&
      key_object.required === true &&
      (data[key] == null || data[key] == "")
    ) {
      //error found
      errorField[key] = true;
    } else if (key.includes("current_pin") && !pinRegex.test(data[key])) {
      errorField[key] = true;
    } else {
      continue;
    }
  }

  return errorField;
}

/**
 * @description This function is for checking if nominee's required details is populated
 * @param  data
 * @returns boolean
 * required field : nominee_name, nominee_relationship, nominee_dob
 */
export function checkForNomineeDetails(data) {
  for (let key in data) {
    if (clientNomineeDetails[key].required && data[key] == null) {
      return false;
    }
  }

  return true;
}

/**
 * @description This function is for checking if kyc's documnets details is populated
 * @param  data
 * @returns boolean
 * required field : selfie, address, address proof
 */
export function checkForKycDocuments(data) {
  for (let key in data) {
    if (clientKycDocumentDetails[key].required && data[key] == null) {
      return false;
    }
  }

  return true;
}

export function checkForKycDocumentsForErrorState(data) {
  let errorField = {};

  for (let key in data) {
    if (
      clientKycDocumentDetails[key].required &&
      (data[key] == null || data[key] == "")
    ) {
      //error found
      errorField[key] = true;
    }
  }

  return errorField;
}

export function checkForNomineeDetailsForErrorState(data) {
  let errorField = {};

  for (let key in data) {
    if (
      data[key] != null &&
      key == "nominee_pan" &&
      data[key].length > 0 &&
      !checkValidPan(data[key])
    ) {
      errorField[key] = true;
    } else if (
      data[key] != null &&
      data[key].length > 0 &&
      key == "nominee_email_id" &&
      !checkValidEmailV2(data[key])
    ) {
      errorField[key] = true;
    } else if (
      data[key] != null &&
      data[key].length > 0 &&
      key == "nominee_mobile_number" &&
      !checkValidMobileNumber(data[key])
    ) {
      errorField[key] = true;
    } else if (
      data[key] != null &&
      data[key].length > 0 &&
      key == "nominee_dob" &&
      !isOver18(data[key])
    ) {
      errorField[key] = true;
    } else if (
      clientNomineeDetails[key].required &&
      (data[key] == null || data[key] == "")
    ) {
      //error found
      errorField[key] = true;
    }
  }

  return errorField;
}

export function convertInvestorType(investor_type) {
  let converted_type = "";

  switch (investor_type) {
    case "Public Limited":
      converted_type = "private_limited_company";
      break;
    case "Private Limited":
      converted_type = "private_limited_company";
      break;
    case "Sole Proprietorship":
      converted_type = "sole_proprietorship";
      break;
    case "sole_proprietorship":
      converted_type = "sole_proprietorship";
      break;
    case "Individual":
      converted_type = "Individual";
      break;
    case "HUF":
      converted_type = "huf";
      break;
    case "huf":
      converted_type = "huf";
      break;
    case "Public Company":
      converted_type = "private_limited_company";
      break;
    case "private_limited_company":
      converted_type = "private_limited_company";
      break;
    case "Partnership Firm":
      converted_type = "partnership_firm";
      break;
    case "partnership_firm":
      converted_type = "partnership_firm";
      break;
    case "Trust":
      converted_type = "trust";
      break;
    case "trust":
      converted_type = "trust";
      break;
    default:
      converted_type = "Individual";
  }
  return converted_type;
}

/**
 * @description function to get current addresss field
 * @param  
 * @returns array

 */

export function getCurrentAddressDocuments(state) {
  let document = [];

  let fields_to_check = [
    "registered_address",
    "current_locality",
    "current_city",
    "current_pin",
    "current_state",
  ];

  for (let key in state) {
    for (let i = 0; i < fields_to_check.length; i++) {
      if (key.includes(fields_to_check[i])) {
        document.push(key);
      }
    }
  }

  return document;
}

export function getPermanentAddressDocuments(state) {
  let document = [];

  let fields_to_check = [
    "permanent_address",
    "permanent_locality",
    "permanent_city",
    "permanent_pin",
    "permanent_state",
  ];

  for (let key in state) {
    for (let i = 0; i < fields_to_check.length; i++) {
      if (key.includes(fields_to_check[i])) {
        document.push(key);
      }
    }
  }

  return document;
}

//check for permanent address
export function checkPermanentAddressDocuments(data, prefix = "") {
  let errorField = {};

  // construct a regular expression to match keys related to permanent address fields
  const permanentAddressKeyRegex = new RegExp(
    `^${prefix}(permanent_address|permanent_locality|permanent_city|permanent_pin|permanent_state)`
  );

  for (const key in data) {
    // check if the key matches the regular expression
    if (permanentAddressKeyRegex.test(key)) {
      // check if the field is empty
      if (data[key] == null || data[key] === "") {
        errorField[key] = true;
      }
    }
  }

  let pinRegex = /^[1-9][0-9]{5}$/;
  // check permanent_pin separately
  const pinKey = prefix + "permanent_pin";
  if (pinKey in data && !pinRegex.test(data[pinKey])) {
    errorField[pinKey] = true;
  }

  return errorField;
}

/**
 * @description function to get beneficial addresss field
 * @param  
 * @returns array

 */

export function getBeneficialAddressDocuments(state) {
  let document = [];

  let fields_to_check = [
    "beneficial_owner_1_pan",
    "beneficial_owner_1_address_proof",
    "beneficial_owner_1_selfie",
  ];

  for (let key in state) {
    for (let i = 0; i < fields_to_check.length; i++) {
      if (key.includes(fields_to_check[i])) {
        document.push(key);
      }
    }
  }

  return document;
}

/**
 * @description function to get beneficial addresss render for incomplete
 * @param  
 * @returns array

 */

export function getBeneficialAddressFilledDocuments(object) {
  let counter = 1;

  for (let i = 2; i <= 5; i++) {
    const prefix = `beneficial_owner_${i}`;

    if (
      object[`${prefix}_address_proof`] ||
      object[`${prefix}_pan`] ||
      object[`${prefix}_selfie`]
    ) {
      counter++;
    }
  }
  return counter;
}

/**
 * @description function to get beneficial addresss error state
 * @param  
 * @returns array

 */

export function getBeneficialAddressErrorState(
  data,
  beneficial_owner_selected
) {
  let errorField = {};

  for (let i = 2; i <= beneficial_owner_selected; i++) {
    const prefix = `beneficial_owner_${i}`;

    if (data[`${prefix}_address_proof`] == null) {
      errorField[`${prefix}_address_proof`] = true;
    }
    if (data[`${prefix}_pan`] == null) {
      errorField[`${prefix}_pan`] = true;
    }
    if (data[`${prefix}_selfie`] == null) {
      errorField[`${prefix}_selfie`] = true;
    }
  }
  return errorField;
}

export function getEntityDocumentsToRender(state, investorType) {
  let document = [];

  for (let key in state) {
    if (
      key != "other_document" &&
      key != "entity_document_1" &&
      key != "entity_document_2" &&
      key != "date_of_incorporation" &&
      key != "entity_registered_address" &&
      key != "entity_name" &&
      key != "entity_current_locality" &&
      key != "entity_current_city" &&
      key != "entity_current_state" &&
      key != "entity_current_pin" &&
      key != "entity_address_proof" &&
      key != "beneficial_owner_1_pan" &&
      key != "beneficial_owner_1_address_proof" &&
      key != "beneficial_owner_1_selfie" &&
      key != "beneficial_owner_2_pan" &&
      key != "beneficial_owner_2_address_proof" &&
      key != "beneficial_owner_2_selfie" &&
      key != "beneficial_owner_3_pan" &&
      key != "beneficial_owner_3_address_proof" &&
      key != "beneficial_owner_3_selfie" &&
      key != "beneficial_owner_4_pan" &&
      key != "beneficial_owner_4_address_proof" &&
      key != "beneficial_owner_4_selfie" &&
      key != "beneficial_owner_5_pan" &&
      key != "beneficial_owner_5_address_proof" &&
      key != "beneficial_owner_5_selfie" &&
      key != "beneficial_owner_6_pan" &&
      key != "beneficial_owner_6_address_proof" &&
      key != "beneficial_owner_6_selfie" &&
      key != "beneficial_owner_1_pan"
    ) {
      if (state[key].associatedFirm.includes(investorType)) {
        document.push(key);
      }
    }
  }

  return document;
}

export function getErrorStateForAddClient(data, investor_type, ckyc_failed) {
  let errorField = {};
  //check for individual
  if (investor_type.toLowerCase() === "individual") {
    const entityObject = ckyc_failed
      ? { ...clientBankDetails, ...clientKycDocumentDetails }
      : { ...clientBankDetails };
    for (let key in data) {
      const keyObject = entityObject[key];
      const isBankVerify = key === "bank_verify";
      if (
        keyObject?.associatedFirm?.includes(
          String(investor_type).toLowerCase()
        ) &&
        keyObject.required &&
        (data[key] == null || data[key] === "")
      ) {
        // Error found
        errorField[key] = true;
      } else if (
        isBankVerify &&
        data["bank_verify"] === false &&
        (data["cancel_cheque"] == null || data["cancel_cheque"] === "")
      ) {
        // Error found
        errorField[key] = true;
      } else if (key == "current_pin_code") {
        let pinRegex = /^[1-9][0-9]{5}$/;
        if (!pinRegex.test(data[key])) {
          errorField[key] = true;
        }
      } else if (
        key == "permanent_current_address_flag" &&
        data["permanent_current_address_flag"] == false
      ) {
        let permAddErrors = checkPermanentAddressDocuments(data);
        errorField = { ...errorField, ...permAddErrors };
      }
    }

    return errorField;
  }
  //check for other entities
  else {
    for (let key in data) {
      let entityObject = {
        ...clientEntityDetails,
        ...clientProprietorDetails,
        ...clientBankDetails,
      };
      let keyObject = entityObject[key];
      const isBankVerify = key === "bank_verify";
      //checks for sole_proprietorship
      if (
        investor_type == "sole_proprietorship" &&
        (key == "entity_document_1" || key == "entity_document_2")
      ) {
        if (
          data[key] == null ||
          data[key].document == null ||
          data[key].document_type == null
          
        ) {
          errorField[key] = true;
        }
      }

      if (
        key === "ifsc_code" &&
        data["ifsc_code"] &&
        !checkValidIfsc(data["ifsc_code"])
      ) {
        // Error found for invalid ifsc_code
        errorField[key] = true;
      } else if (
        key === "cancel_cheque" &&
        isBankVerify &&
        data["bank_verify"] === false &&
        (data["cancel_cheque"] == null || data["cancel_cheque"] === "")
      ) {
        // Error found for null or empty cancel_cheque
        errorField[key] = true;
      } else if (
        keyObject?.associatedFirm?.includes(
          String(investor_type).toLowerCase()
        ) &&
        keyObject.required &&
        (data[key] == null || data[key] === "")
      ) {
        // Error found for required key with null or empty value
        errorField[key] = true;
      } else if (
        key == "entity_current_pin" ||
        key == "authoriser_current_pin"
      ) {
        let pinRegex = /^[1-9][0-9]{5}$/;
        if (!pinRegex.test(data[key])) {
          errorField[key] = true;
        }
      } else if (
        key == "authoriser_current_address_flag" &&
        data["authoriser_current_address_flag"] == false
      ) {
        let permAddErrors = checkPermanentAddressDocuments(data, "authoriser_");
        errorField = { ...errorField, ...permAddErrors };
      }

      //check for beneficial document
      if (
        key == "beneficial_owner_selected" &&
        data["beneficial_owner_selected"] > 1
      ) {
        let beneficialOwnerError = getBeneficialAddressErrorState(
          data,
          data["beneficial_owner_selected"]
        );
        errorField = { ...errorField, ...beneficialOwnerError };
      }
    }

    return errorField;
  }
}

export function handleKeyDown(event) {
  if (event.key === "ArrowUp" || event.key === "ArrowDown") {
    event.preventDefault();
  }
};

export const handleDownload = async (pdfUrl, fileName) => {
  try {
    const response = await fetch(pdfUrl);
    const blob = await response.blob();

    // Create a temporary URL
    const url = window.URL.createObjectURL(new Blob([blob]));

    // Create a temporary anchor element
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName); // Set the filename

    // Append the anchor to the body
    document.body.appendChild(link);

    // Trigger the download
    link.click();

    // Clean up
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url); // Release the object URL
  } catch (error) {
    console.error("Error downloading the file:", error);
  }
};
