import { flow, Instance, SnapshotOut, types, detach } from "mobx-state-tree";
import { OpenPoolModel } from "../open-pool/open-pool";
import { Pools_API } from "../../../services/api";

// make the instance of api
const api = new Pools_API();

export const OpenPoolStoreModel = types
  .model("OpenPoolStore")
  .props({
    openpools: types.optional(types.array(OpenPoolModel), []),
  })
  .views((self) => ({}))
  .actions((self) => ({
    // saveOpenPools: flow(function* (data: any, curr_page: any) {
    //   // if current page is 1
    //   if (curr_page == 1) {
    //     if (self.openpools) {
    //       if (self.openpools.length != 0) {
    //         if (self.openpools[0].pools) {
    //           if (self.openpools[0].pools.length > 0) {
    //             detach(self.openpools[0].pools);
    //             self.openpools[0].pools.push(...data.pools);
    //             self.openpools[0].pagination = data.pagination;
    //           } else {
    //             self.openpools.push(data);
    //           }
    //         } else {
    //           self.openpools.push(data);
    //         }
    //       } else {
    //         self.openpools.push(data);
    //       }
    //     }
    //   }
    //   // if current page is not 1
    //   else {
    //     self.openpools[0].pools.push(...data.pools);
    //     self.openpools[0].pagination = data.pagination;
    //   }
    // }),
  }))
  .actions((self) => ({
    getOpenPools: flow(function* (per_page,type) {
      const result = yield api.getOpenPoolsAPI(per_page,type);
      if (result.kind == "ok") {
        if (result.openpools.pools.length == 0) {
          return {
            kind: "ok",
            found: false,
            data: [],
            pagination: result.openpools.pagination,
          };
        } else {
          // yield self.saveOpenPools(result.openpools, curr_page);
          return {
            kind: "ok",
            found: true,
            data: result.openpools.pools,
            pagination: result.openpools.pagination,
          };
        }
      } else {
        return { kind: "bad-data", error: result.error };
      }
    }),
  }));

type OpenPoolStoreType = Instance<typeof OpenPoolStoreModel>;
export interface OpenPoolStore extends OpenPoolStoreType {}

type OpenPoolStoreSnapshotType = SnapshotOut<typeof OpenPoolStoreModel>;
export interface OpenPoolStoreSnapshot extends OpenPoolStoreSnapshotType {}
