import "./logo-model.css";
import Loader from "react-loader-spinner";

export default function LogoModel(props) {
  return (
    <>
      <div className="support_header"></div>
      <div
        className="logo_main_input_form"
        style={{
          display: "flex",
        }}
      >
        <label className="logo_main_input_label">Logo</label>
        <div className="logo_main_image_div_container">
          <div
            className="logo_main_image_div"
            style={{
              backgroundColor: props.disable === true ? "#ececec" : undefined,
              color: props.disable === true ? "rgb(119, 119, 119)" : undefined,
            }}
          >
            <p className="logo_main_image_text">{props.logoFile.logo_image}</p>
          </div>
          {props.disable === true ? (
            <div />
          ) : (
            <button
              onClick={() => {
                props.logoFileRef.current.value = "";
                props.logoFileRef.current.click();
              }}
              className="logo_main_image_button"
              style={{
                cursor: props.logoLoader ? "not-allowed" : "pointer",
                backgroundColor: props.submitted ? "#808080" : "#3A86FF",
                width: "30%",
                height: "100%",
              }}
              disabled={props.logoLoader || props.submitted}
            >
              {props.logoLoader ? (
                <Loader type="TailSpin" color="#fff" height={18} width={18} />
              ) : props.logoFile.logo_image === "" ||
                props.logoFile.logo_image === null ? (
                "Upload"
              ) : (
                "Change"
              )}
            </button>
          )}
          <input
            type="file"
            ref={props.logoFileRef}
            style={{ display: "none" }}
            onChange={(event) => props.onHandleChange(event, "logo_image")}
          />
        </div>
        {props.logoFile.logo_image_data.base64 != "" &&
        props.logoFile.logo_image_data.base64 != null &&
        props.disable == false ? (
          <img
            src={`${props.logoFile.logo_image_data.base64}`}
            alt={`Uploaded Logo`}
            className="img"
            // style={{ width: "100%", height: "100%" }}
          />
        ) : null}

        {props.logoFile.logo_image_data.base64 != "" &&
        props.logoFile.logo_image_data.base64 != null &&
        props.disable == true ? (
          <img
            src={`data:image/png;base64,${props.logoFile.logo_image_data.base64}`}
            alt={`Uploaded Logo`}
            className="img"
            // style={{ width: "80%", height: "100%" }}
          />
        ) : null}

        {props.submitted == false ? (
          <div className="logo_field_container">
            <button
              className="logo_submit_container"
              style={{
                cursor: props.disable ? "not-allowed" : "pointer",
                backgroundColor:
                  props.submitDisable == true ? "#808080" : "#3A86FF",
              }}
              onClick={props.submitLogo}
              disabled={
                props.disable ||
                props.logoFile.logo_image_data == "" ||
                props.logoFile.logo_image_data == null ||
                props.submitDisable
              }
            >
              {props.submitLoader == true ? (
                <Loader type="TailSpin" color="#fff" height={15} width={15} />
              ) : (
                "Submit"
              )}
            </button>
          </div>
        ) : null}
      </div>
    </>
  );
}
