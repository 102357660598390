import React, { useEffect, useState } from "react";
import "./InvestmentConsentConfirmation.css";
import { useParams, useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import { Client_API, User_API } from "../../services/api";
import InfoIcon from "@mui/icons-material/Info";
import Checkbox from "@mui/material/Checkbox";
import checkMark from "../../assets/images/checkMark.svg";
import { errorToast, successToast } from "../../utils/helper";
import { FullScreenLoader, InfoAlert, SampleToast } from "../../components";
import { eventLog } from "../../utils/firebase";

const InvestmentConsentConfirmation = (props) => {
  const { consent_uuid } = useParams();
  const navigate = useHistory();
  const clientAPI = new Client_API();
  const userApi = new User_API();

  const [consentData, setConsentData] = useState("");
  const [render, setRender] = useState(false);
  const [err, setErr] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [fullLoader, setFullLoader] = useState(false);
  const [otpView, setOtpView] = useState(false);
  const [verified, setVerified] = useState(false);
  const [user, setUser] = useState(null);
  const [otp, setOtp] = useState("");
  const [Confirm, setConfirm] = useState(false);
  const [otpInvalid, setOtpInvalid] = useState(false);
  const [otpInvalidText, setOtpInvalidText] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [soaConsent, setSoaConsent] = useState(false);
  const [investmentConsent, setInvestmentConsent] = useState(false);
  const [currentSessionSoaConsent, setCurrentSessionSoaConsent] =
    useState(false);
  const [currentSessionInvestmentConsent, setCurrentSessionInvestmentConsent] =
    useState(false);
  const [otpVerified, setOtpVerified] = useState(false);

  useEffect(() => {
    props.showCommonHeader(false);
    props.showCommonSidebar(false);
    fetchInvestmentConsentData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (consentData.status === 2) {
      setInvestmentConsent(true);
    }
    if (consentData.soa_consent === 2) {
      setSoaConsent(true);
    }
  }, [consentData]);

  const fetchInvestmentConsentData = async () => {
    // payload
    let payload = {
      consent_uuid,
    };
    clientAPI
      .fetchInvestmentConsentData(payload)
      .then((res) => {
        if (res.kind === "ok") {
          eventLog({
            type: "authorise_otp_screen",
            action: {
              trigger: "true",
            },
          });
          setConsentData(res.data);
          if (res.data.status !== 1 && res.data.soa_consent !== 1) {
            setErrMsg("The page you are looking for has expired.");
          }
          setRender(true);
        } else {
          setRender(false);
        }
      })
      .catch((error) => {
        errorToast(
          "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
        );
      });
  };

  // Function to check if atleast one checkbox is ticked
  const checkConsents = () => {
    if (currentSessionInvestmentConsent || currentSessionSoaConsent) {
      setOtpSent(true);
      onSendOtp(consentData);
    } else {
      setOtpSent(false);
      errorToast("To continue, please provide your consent.");
    }
  };

  const updateInvestmentConsent = async () => {
    let payload = {
      client_id: consentData.user_id,
      ifa_id: consentData.ifa_id,
      status: investmentConsent ? 2 : 0,
      soa_status: soaConsent ? 2 : 0,
    };
    await clientAPI.postClientInvestmentConsent(payload).then((res) => {
      if (res.kind === "ok") {
        setRender(true);
      } else {
        errorToast(res.error);
        setRender(false);
      }
    });
  };
  // amount key press handler
  const onKeyPress = (e) => {
    const re = /[^0-9]/g;
    if (re.test(e.key)) {
      setConfirm(true);
      e.preventDefault();
    }
  };

  // on send otp
  const onSendOtp = async (data) => {
    eventLog({
      type: "otp_verification",
      action: {
        trigger: "true",
      },
    });

    setFullLoader(true);
    const payload = {
      mobile: data.client_mobile,
      isIfa: false,
    };
    await userApi
      .sendMobileVerification(payload)
      .then((response) => {
        console.log("response", response);
        if (response.kind == "ok") {
          let userName = response.data.pan_number;
          if (response.data.oldExistingUser)
            userName = `+91${data.client_mobile}`;
          if (response.data.isExistingUser) {
            Auth.signIn(userName)
              .then((response) => {
                setUser(response);
                setFullLoader(false);
                setRender(true);
                setOtpView(true);
                successToast(
                  "OTP has been sent to your registered mobile number and email ID."
                );
              })
              .catch((err) => {
                setFullLoader(false);
                console.log(err);
                errorToast(
                  "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
                );
                setTimeout(() => {
                  window.location.reload();
                }, 5000);
              });
          } else {
            setFullLoader(false);
            errorToast("User doesn't exist");
          }
        } else {
          setFullLoader(false);
          errorToast(
            "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
          );
        }
      })
      .catch((error) => {
        console.log("Error while sending OTP ::: ", error);
        setFullLoader(false);
        errorToast(
          "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
        );
      });
  };

  // get authentication
  const onGetAuth = async () => {
    if (otp.length === 0) {
      setOtpInvalid(true);
      setOtpInvalidText("OTP cannot be empty");
    } else if (otp.length !== 4) {
      setOtpInvalid(true);
      setOtpInvalidText("Invalid OTP");
    } else if (isNaN(parseInt(otp))) {
      setOtpInvalid(true);
      setOtpInvalidText("OTP must be a number");
    } else {
      setFullLoader(true);
      await Auth.sendCustomChallengeAnswer(user, otp)
        .then(async (res) => {
          if (res && !res.Session) {
            //successToast("OTP verified successfully.");
            setOtpVerified(true);
            updateInvestmentConsent();
            eventLog({
              type: "otp_verified",
              action: {
                trigger: "true",
              },
            });

            eventLog({
              type: "investment_consent_accepted",
              action: {
                trigger: "true",
              },
            });
            setTimeout(() => {
              setFullLoader(false);
              setOtpView(false);
              setVerified(true);
            }, 2000);
          } else {
            setFullLoader(false);
            setOtpInvalid(true);
            setOtpInvalidText(
              "Wrong OTP. Please check and enter the correct OTP."
            );
          }
        })
        .catch((err) => {
          setFullLoader(false);
          eventLog({
            type: "investment_consent_accepted",
            action: {
              trigger: "false",
            },
          });
          console.log(err);
          errorToast(
            "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
          );
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        });
    }
  };

  // otp enter view
  const otpEnterView = () => {
    return (
      <div className="investment_consent_confirmation_div">
        {!otpVerified ? (
          <div>
            {/* header */}
            <header className="investment_consent_confirmation_header">
              <h1 className="investment_consent_confirmation_header_title">
                IFA {consentData.ifa_name} has requested your consent for the
                following:
              </h1>
            </header>
            {/* checkboxes */}
            <div className="consent_checkboxes">
              {consentData.soa_consent === 1 ? (
                <div className="consent_checkbox">
                  <div className="checkbox">
                    <Checkbox
                      checked={soaConsent}
                      onChange={() => {
                        setSoaConsent(!soaConsent);
                        setCurrentSessionSoaConsent(!currentSessionSoaConsent);
                      }}
                      sx={{
                        "& .MuiSvgIcon-root": { fontSize: 16 },
                      }}
                      disabled={otpSent}
                    />
                  </div>

                  <div className="checkbox_label">
                    To access any reports, certificates or statements linked to
                    your investments on 1Invest.
                  </div>
                </div>
              ) : null}
              <br />
              {consentData.status === 1 ? (
                <div className="consent_checkbox">
                  <div className="checkbox">
                    <Checkbox
                      checked={investmentConsent}
                      onChange={() => {
                        setInvestmentConsent(!investmentConsent);
                        setCurrentSessionInvestmentConsent(
                          !currentSessionInvestmentConsent
                        );
                      }}
                      sx={{
                        "& .MuiSvgIcon-root": { fontSize: 16 },
                      }}
                      disabled={otpSent}
                    />
                  </div>

                  <div className="checkbox_label">
                    To act on your behalf for selecting the 1Invest plan and
                    investing funds from your escrow account maintained with
                    RNVP Technology Private Limited. This authorization is
                    revocable at any time, for any reason.
                  </div>
                </div>
              ) : null}
            </div>
            {/* otp input */}
            {otpSent ? (
              <div>
                <div className="otp_input">
                  <input
                    type="text"
                    maxLength={4}
                    placeholder="Enter OTP"
                    className="investment_consent_confirmation_input"
                    onKeyPress={onKeyPress}
                    onChange={(event) => {
                      setOtpInvalid(false);
                      setOtpInvalidText("");
                      setOtp(event.target.value);
                    }}
                    style={{
                      borderColor: otpInvalid ? "#ff2e2e" : undefined,
                    }}
                  />
                </div>

                {/*validation error */}
                <div className="investment_consent_confirmation_invalid_div">
                  {/* icon */}
                  <InfoIcon
                    style={{
                      fontSize: "14px",
                      paddingRight: "5px",
                      color: "#ff2e2e",
                      opacity: otpInvalid ? 1 : 0,
                    }}
                  />
                  {/* text */}
                  <p
                    className="investment_consent_confirmation_invalid_text"
                    style={{
                      opacity: otpInvalid ? 1 : 0,
                    }}
                  >
                    {otpInvalid ? otpInvalidText : "Invalid"}
                  </p>
                </div>
              </div>
            ) : null}

            {/* buttons */}
            <div className="consent_buttons">
              {otpSent ? (
                <button
                  onClick={() => {
                    fetchInvestmentConsentData();
                    onGetAuth();
                  }}
                  className="investment_consent_confirmation_otp_button_verify"
                >
                  Verify
                </button>
              ) : (
                <button
                  onClick={() => {
                    fetchInvestmentConsentData();
                    checkConsents();
                  }}
                  className="investment_consent_confirmation_otp_button"
                >
                  Authorize via OTP
                </button>
              )}
            </div>
          </div>
        ) : (
          <div>
            <div className="consent_success_message">
              <div className="check_mark_image">
                <img
                  src={checkMark}
                  alt="check_mark_icon"
                  className="check_mark"
                />
              </div>
              <h2 className="success_message_header">
                Your consent has been recorded successfully.
              </h2>
              {currentSessionInvestmentConsent || currentSessionSoaConsent ? (
                <p className="success_message_body">
                  As per your consent, {consentData.ifa_name} can{" "}
                  {currentSessionSoaConsent
                    ? `access any
                  reports, certificates or statements linked to your investments on 1Invest`
                    : ""}
                  {currentSessionSoaConsent && currentSessionInvestmentConsent
                    ? " and "
                    : currentSessionSoaConsent
                    ? "."
                    : ""}
                  {currentSessionInvestmentConsent
                    ? `act on your behalf for selecting the 1Invest plan and investing funds 
                  from your escrow account maintained with RNVP Technology Private Limited. You can withdraw this consent 
                  at any time, for any reason from the 1Invest app.`
                    : ""}
                </p>
              ) : null}
            </div>
          </div>
        )}
      </div>
    );
  };

  // page not available view
  const pageNotAvailable = () => {
    return (
      <div className="investment_consent_confirmation_page_expire">
        <h1 className="investment_consent_confirmation_header_page_expire_title">
          {errMsg}
        </h1>
      </div>
    );
  };

  return (
    <div>
      {render === false ? (
        <div />
      ) : (
        <div className="investment_consent_confirmation_container">
          {/* logo */}
          <div className="image_container">
            <img
              src="https://arthmate-p2p-public-assets.s3.ap-south-1.amazonaws.com/images/1invest-logo-color-dark.png"
              alt="logo"
              className="investment_consent_confirmation_logo_image"
            />
          </div>

          {err || errMsg
            ? // page not found
              pageNotAvailable()
            : otpView
            ? // otp enter view
              otpEnterView()
            : otpEnterView()}

          {/* full screen loader */}
          {fullLoader ? <FullScreenLoader /> : <div />}

          {/* toast container */}
          <SampleToast />
        </div>
      )}
    </div>
  );
};

export default InvestmentConsentConfirmation;
