import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Amplify } from "aws-amplify";
import awsConfig from "./constant/aws-exports";
import { RootStoreModel } from "./models";
import { appInitialize, eventLog } from "./utils/firebase";
import { getFrontendStateObject } from "./utils/helper";
import * as Sentry from "@sentry/react";
const { sentry } = require("./config/settings");

// if runs on local
const { stage } = getFrontendStateObject();

Amplify.configure(awsConfig[stage]);
console.log(stage, "this is the stage");

if (stage != "me") {
  Sentry.init({
    dsn: sentry[stage].SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        // routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        //   React.useEffect,
        //   useLocation,
        //   useNavigationType,
        //   createRoutesFromChildren,
        //   matchRoutes
        // ),
      }),
      new Sentry.Replay(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

// console.log(appInitialize());

const initialState = {};

function createInitialStore(snapshot) {
  RootStoreModel.create(snapshot);
}

function renderApp(App) {
  ReactDOM.render(
    <Sentry.ErrorBoundary>
      <App />
    </Sentry.ErrorBoundary>,
    document.getElementById("root")
  );
}

renderApp(App, createInitialStore(initialState));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
