import React, { useEffect, useState } from "react";
import "./pool-templates.css";
import { Pagination } from "@mui/material";
import { useLocation, useHistory, Redirect } from "react-router-dom";
import { useStores } from "../../models";
import { eventLog } from "../../utils/firebase";
import { Pools_API } from "../../services/api";
import { observer } from "mobx-react-lite";
import { errorToast, successToast } from "../../utils/helper";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import {
  ContentLoadingLoader,
  PoolCard,
  PoolModel,
  SampleToast,
  FullScreenLoader,
  PoolClientlistModel,
} from "../../components";
import ClientInvestmentRequestModel from "../client-investment-request-model/client-investment-request-model";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import config from "../../config/settings";
// import HideIFA from "../HideIFA/hideIFA";

const defaultInvestedPool = {
  pool_title: "",
  id: "",
  minimum_investment: 0,
  type: 0,
  tenure: 0,
  interest_rate: 0,
  tenure_type: "Months",
};

const PoolTemplates = observer(function PoolTemplates(props) {
  const param = useLocation();
  const poolsApi = new Pools_API();
  const [client, setClient] = useState({
    name: "",
    net_worth: 0,
    uuid: "",
    available_funds: 0,
  });
  const navigate = useHistory();
  const rootStore = useStores();
  const [clientData, setClientData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [page, setPage] = useState(0);
  const rowsPerPage = 10;
  const [maturityPlanPools, setMaturityPlanPools] = useState([]);
  const [freedomPlanPools, setFreedomPlanPools] = useState([]);
  const [displayMonthlyPlanPools, setDisplayMonthlyPlanPools] = useState([]);
  const [displayMaturityPlanPools, setDisplayMaturityPlanPools] = useState([]);
  const [displayFreedomPlanPools, setDisplayFreedomPlanPools] = useState([]);
  const [monthlyPlanPools, setMonthlyPlanPools] = useState([]);
  const [poolById, setPoolbyId] = useState([]);
  const [viewMore, setViewMore] = useState(false);
  const [showIndex, setShowIndex] = useState(0);
  const [render, setRender] = useState(false);
  const [loader, setLoader] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [openClientModel, setOpenClientModel] = useState(false);
  const api = new Pools_API();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [amount, setAmount] = useState([]);
  const [investAmount, setInvestAmount] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [hideIFA, setHideIFA] = useState(false);
  const [requestType, setRequestType] = useState(0);
  const [investedPool, setInvestedPool] = useState(defaultInvestedPool);
  const [
    openClientInvestmentRequestModel,
    setOpenClientInvestmentRequestModel,
  ] = useState(false);
  const [amountInvalid, setAmountInvalid] = useState(false);
  const [amountInvalidText, setAmountInvalidText] = useState("");
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current_page: 0,
    count: 0,
    starts_from: 0,
    per_page: 0,
    total_count: 0,
  });
  const per_page = 10;
  const curr_page = 1;
  const showFreedomPlan = !config.fp_flag_info.flag_status ? false : true;

  // function handleWindowSizeChange() {
  //   if (window.innerWidth <= 1080 || window.innerHeight <= 600) {
  //     setHideIFA(true);
  //     props.showCommonHeader(false);
  //     props.showCommonSidebar(false);
  //   } else {
  //     setHideIFA(false);
  //     props.showCommonHeader(true);
  //     props.showCommonSidebar(true);
  //   }
  // }

  // useEffect(() => {
  //   window.addEventListener("resize", handleWindowSizeChange);
  //   if (window.innerWidth <= 1080 || window.innerHeight <= 600) {
  //     setHideIFA(true);
  //     props.showCommonHeader(false);
  //     props.showCommonSidebar(false);
  //   }
  //   return () => {
  //     window.removeEventListener("resize", handleWindowSizeChange);
  //   };
  // }, []);

  // check if user login or not
  useEffect(() => {
    let data = {
      name: param.state === undefined ? "" : param.state.name,
      net_worth: param.state === undefined ? 0 : param.state.net_worth,
      uuid: param.state === undefined ? "" : param.state.uuid,
    };
    poolList(requestType);
    setClient(data);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const result = maturityPlanPools.filter(
      (item) => item.type == 0 && item.display
    );
    setDisplayMaturityPlanPools(result);
  }, [maturityPlanPools]);

  useEffect(() => {
    const result = monthlyPlanPools.filter(
      (item) => item.type == 2 && item.display
    );
    setDisplayMonthlyPlanPools(result);
  }, [monthlyPlanPools]);

  useEffect(() => {
    const result = freedomPlanPools.filter(
      (item) => item.type == 1 && item.display
    );
    setDisplayFreedomPlanPools(result);
  }, [freedomPlanPools]);

  // fetch the pool list
  const poolList = (type) => {
    rootStore.openPool.getOpenPools(per_page, type).then((res) => {
      if (res.kind === "ok") {
        if (type == 0) setMaturityPlanPools(res.data);
        else if (type == 2) setMonthlyPlanPools(res.data);
        else setFreedomPlanPools(res.data);
        setRender(true);
      } else {
        setRender(true);
        errorToast(res.error);
      }
    });
  };

  // fetch the pool list again
  const fetchPoolAgain = (crr_pg) => {
    rootStore.openPool.getOpenPools(per_page, crr_pg).then((res) => {
      if (res.kind === "ok") {
        if (requestType == 0) setMaturityPlanPools(res.data);
        else if (requestType == 2) setMonthlyPlanPools(res.data);
        else setFreedomPlanPools(res.data);
        if (requestType == 0) setMaturityPlanPools(res.data);
        else if (requestType == 2) setMonthlyPlanPools(res.data);
        else setFreedomPlanPools(res.data);
        setPagination(res.pagination);
      } else {
        errorToast(res.error);
      }
    });
  };

  // amount key press handler
  const onKeyPress = (e) => {
    const re = /[^0-9]/g;
    if (re.test(e.key)) {
      e.preventDefault();
    }
  };
  const handleToggle = (e) => {
    setRequestType(e.target.id);
    if (
      (e.target.id == 0 && maturityPlanPools.length == 0) ||
      (e.target.id == 1 && freedomPlanPools.length == 0) ||
      (e.target.id == 2 && monthlyPlanPools.length == 0)
    ) {
      setRender(false);
      poolList(e.target.id);
    }
    setPage(0);
  };
  // close model
  const handleClose = () => {
    setOpenModel(false);
    setOpenClientModel(false);
  };

  // check selected client
  const checkClient = (pool) => {
    if (props.clientUuid) {
      setInvestedPool({
        pool_title: pool["pool_title"],
        id: pool["id"],
        minimum_investment: pool["minimum_investment"],
        type: pool["type"],
        tenure: pool["tenure"],
        interest_rate: pool["interest_rate"],
        tenure_type: pool["tenure_type"],
      });
      setOpenClientInvestmentRequestModel(true);
    } else {
      navigate.push("/pools/clients", {
        pool: pool,
        poolsData:
          requestType == 0
            ? maturityPlanPools
            : requestType == 2
            ? monthlyPlanPools
            : freedomPlanPools,
        poolsData:
          requestType == 0
            ? maturityPlanPools
            : requestType == 2
            ? monthlyPlanPools
            : freedomPlanPools,
      });
    }
    // if (Array.isArray(client)) {
    //   if (client[0].name === "") {
    //     errorToast("Please select a client first.");
    //   }
    //   setSelectedIndex(index);
    //   setOpenModel(true);
    // } else if (client.name === "") {
    //   errorToast("Please select a client first.");
    // } else {
    // setSelectedIndex(index);
    // setOpenModel(true);
    // }
  };

  // on send  payment link to investors
  const onSendPayment = async (index) => {
    var data = [];
    var flag = false;
    if (Array.isArray(amount[index])) {
      amount[index].map(async (item, i) => {
        if (isNaN(parseFloat(item.investment_amount))) {
          data = [];
          flag = true;
          errorToast(`Investment amount for ${item.name} cannot be empty`);
        } else if (
          parseFloat(item.investment_amount) <
          parseInt(
            requestType == 0
              ? maturityPlanPools[index].minimum_investment.replace(",", "")
              : requestType == 2
              ? monthlyPlanPools[index].minimum_investment.replace(",", "")
              : freedomPlanPools[index].minimum_investment.replace(",", "")
          )
        ) {
          data = [];
          flag = true;
          errorToast(
            `Investment amount for ${item.name} is less than minimum investment amount.`
          );
        } else if (parseFloat(item.investment_amount) > 5000000) {
          data = [];
          flag = true;
          errorToast(
            `Investment amount for ${item.name} cannot be greater than 50 lakhs.`
          );
        } else {
          if (parseInt(item.investment_amount) > 1000000) {
            if (item.net_worth === 2) {
              setOpenModel(false);
              setLoader(true);
              setDisabled(true);
              data.push({
                investment_amount: item.investment_amount,
                pool_template_id:
                  requestType == 0
                    ? maturityPlanPools[index].id
                    : requestType == 2
                    ? monthlyPlanPools[index].id
                    : freedomPlanPools[index].id,
                pool_template_id:
                  requestType == 0
                    ? maturityPlanPools[index].id
                    : requestType == 2
                    ? monthlyPlanPools[index].id
                    : freedomPlanPools[index].id,
                investor_id: item.uuid,
              });
              // await api.sendPaymentLink(data).then((res) => {
              //   if (res.kind === "ok") {
              //     successToast("Successfully sent the payment link.");
              //     setTimeout(() => {
              //       setLoader(false);
              //       setDisabled(false);
              //       navigate.push("/paymentlinks");
              //     }, 2000);
              //   } else {
              //     errorToast(res.error);
              //     setLoader(false);
              //     setDisabled(false);
              //   }
              // });
            } else {
              if (item.net_worth === 1) {
                data = [];
                flag = true;
                errorToast(
                  `The net worth certicifate for ${item.name} is yet to verified.`,
                  1
                );
                return;
              } else {
                data = [];
                flag = true;
                errorToast(
                  `Please upload the net worth certificate of ${item.name} for making investment more than 10 lakhs.`
                );
                return;
              }
            }
          } else {
            data.push({
              investment_amount: item.investment_amount,
              pool_template_id:
                requestType == 0
                  ? maturityPlanPools[index].id
                  : requestType == 2
                  ? monthlyPlanPools[index].id
                  : freedomPlanPools[index].id,
              pool_template_id:
                requestType == 0
                  ? maturityPlanPools[index].id
                  : requestType == 2
                  ? monthlyPlanPools[index].id
                  : freedomPlanPools[index].id,
              investor_id: item.uuid,
            });
            // await api.sendPaymentLink(data).then((res) => {
            //   if (res.kind === "ok") {
            //     successToast("Successfully sent the payment link.");
            //     setTimeout(() => {
            //       setLoader(false);
            //       setDisabled(false);
            //       navigate.push("/paymentlinks");
            //     }, 2000);
            //   } else {
            //     errorToast(res.error);
            //     setLoader(false);
            //     setDisabled(false);
            //   }
            // });
          }
        }
      });
      let inputs = {
        investments: data,
      };
      if (!flag) {
        setOpenModel(false);
        setLoader(true);
        setDisabled(true);
        await api.sendPaymentLink(inputs).then((res) => {
          if (res.kind === "ok") {
            successToast("Successfully sent the payment link.");
            setTimeout(() => {
              setLoader(false);
              setDisabled(false);
              navigate.push("/paymentlinks");
            }, 2000);
          } else {
            errorToast(res.error);
            setLoader(false);
            setDisabled(false);
          }
        });
      }
    } else {
      errorToast("Amount cannot be empty.");
    }
  };

  // get client list and open model
  const getClient = () => {
    rootStore.clientList.getClientlist().then((res) => {
      if (res.kind === "ok") {
        let data = rootStore.clientList.clientlist;
        data = data.filter(function (e) {
          return e.action === "Invest";
        });
        setClientData(data);
        setFilterData(data);
        setLoader(false);
        setOpenClientModel(true);
      } else {
        setLoader(false);
        errorToast(res.error);
      }
    });
  };

  // request search function
  const requestSearch = (event) => {
    setPage(0);
    const filter_rows = filterData.filter((row) => {
      return row.name.toLowerCase().includes(event.target.value.toLowerCase());
    });
    setClientData(filter_rows);
  };

  // handle page change function
  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
    if (pagination.current_page != 1) {
      fetchPoolAgain(pagination.current_page - 1);
    }
    if (pagination.count == 6 && totalPage != pagination.current_page) {
      fetchPoolAgain(pagination.current_page + 1);
    }
  };

  // sort list via name
  const sortByName = (a, b) => {
    if (String(a.name).trim() < String(b.name).trim()) {
      return -1;
    }
    if (String(a.name).trim() > String(b.name).trim()) {
      return 1;
    }
    return 0;
  };

  // fetch pool by id
  const getPoolById = (id, index) => {
    rootStore.getPoolById.fetchGetPoolByIds(id).then((res) => {
      if (res.kind === "ok") {
        setPoolbyId(rootStore.getPoolById.getpoolbyid);
        setShowIndex(index);
        setViewMore(true);
      } else {
        errorToast(res.error);
      }
    });
  };

  // on Investment Request proceed click
  const onInvestmentRequestProceed = async () => {
    if (!isNaN(parseFloat(investAmount))) {
      if (investAmount == 0) {
        errorToast("Amount cannot be empty.");
      } else if (investAmount < 0) {
        errorToast("Amount cannot be  negative.");
      } else if (investAmount % 1000 != 0) {
        errorToast("Amount should be in multiple of 1000.");
      } else {
        setLoading(true);
        if (investedPool != null) {
          const payload = {
            investments: [
              {
                investment_amount: investAmount,
                pool_template_id: investedPool["id"],
                investor_id: props.clientUuid,
              },
            ],
            is_profile_flow: 1,
          };

          poolsApi.sendPaymentLink(payload).then((res) => {
            if (res.kind === "ok") {
              if (
                res.data?.data?.profile_flow_investment_consent_success == true
              ) {
                successToast("Investment done successfully.");
                props.onInvestment();
              } else {
                successToast("Investment request sent successfully.");
              }
              setTimeout(() => {
                setLoading(false);
                setOpenClientInvestmentRequestModel(false);
                setInvestAmount(0);
                eventLog({
                  type: "investment_request",
                  action: {
                    trigger: "true",
                  },
                });
              }, 2000);
            } else {
              setLoading(false);
              errorToast(res.error);
              errorToast({
                type: "investment_request",
                action: {
                  trigger: "false",
                },
              });
            }
          });
        }
      }
    } else {
      errorToast("Please enter a valid amount.");
    }
  };

  // if (hideIFA) {
  //   return <HideIFA />;
  // }
  return (
    <div className="pool_list_div_main_container">
      {/* pool display div */}
      <div className="pool_list_main_div">
        <div className="pool_list_toggle_container">
          {config.pool_templates.map((item, index) => {
            return item.type == 1 && !showFreedomPlan ? null : (
              <div
                className={
                  requestType == item.type
                    ? "pool_list_toggle_element_active"
                    : "pool_list_toggle_element"
                }
                id={item.type}
                key={index}
                onClick={handleToggle}
              >
                {item.title}
              </div>
            );
          })}
        </div>
        <div className="pool_list_sub_heading">
          {requestType == 0 && (
            <p className="pool_list_sub_heading_content">
              {/* With Growth Plan, earn up to 13% per annum. Invested amount,
              including the interest earned, is paid at the end of the plan
              tenure. */}
              Investment in Growth Plans - Earnings at Maturity have been discontinued w.e.f. 17th August 2024.
              Stay Tuned for exciting new investment plans, which will be live
              shortly.
            </p>
          )}
          {requestType == 2 && (
            <p className="pool_list_sub_heading_content">
              {/* Invest in Growth Plan with Monthly Earnings to earn up to 12.25%
              per annum. Interest earned is paid every month. Invested amount is
              repaid at the end of the plan tenure. */}
              Investment in Growth Plans -
              Monthly Earnings have been discontinued w.e.f. 17th August 2024.
              Stay Tuned for exciting new investment plans, which will be live
              shortly.
            </p>
          )}
          {requestType == 1 && showFreedomPlan && (
            <div>
              <p className="pool_list_sub_heading_content">
                With Freedom Plan, earn up to 10% per annum. Get daily earnings
                and withdraw any time with zero additional charges.
              </p>
              <p className="pool_list_sub_heading_content">
                <span
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "#757575",
                  }}
                >
                  Important Note :{" "}
                </span>
                Withdrawal requests are typically processed within 2 to 4
                business days of the request date. Please refer to{" "}
                <span
                  style={{
                    fontSize: "16px",
                    color: "#3A86FF",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate.push("/support")}
                >
                  support
                </span>{" "}
                for more information.
              </p>
            </div>
          )}
        </div>
        {render === false ? (
          <ContentLoadingLoader marginLeft="0px" marginTop="0px" />
        ) : (
          <div>
            {" "}
            {/* if no pool available */}
            {(requestType == 0 && maturityPlanPools.length === 0) ||
            (requestType == 2 && monthlyPlanPools.length === 0) ||
            (requestType == 1 &&
              freedomPlanPools.length === 0 &&
              pagination.current_page === 1) ? (
              <div className="no_row_found_div">
                <FindInPageIcon
                  style={{
                    fontSize: "100px",
                    color: "#555555",
                    marginTop: "5%",
                  }}
                />
                <p className="no_row_found"> Coming soon{" "}</p>
              </div>
            ) : (
              <div className="pool_pagination_div_container">
                <PoolCard
                  pools={
                    requestType == 0
                      ? maturityPlanPools
                      : requestType == 2
                      ? monthlyPlanPools
                      : freedomPlanPools
                  }
                  displayPools={
                    requestType == 0
                      ? displayMaturityPlanPools
                      : requestType == 2
                      ? displayMonthlyPlanPools
                      : displayFreedomPlanPools
                  }
                  checkClient={(value) => checkClient(value)}
                  disabled={disabled}
                  getPoolDetails={(id, index, text) => {
                    if (text === "View Less") {
                      setViewMore(false);
                    } else {
                      getPoolById(id, index);
                    }
                  }}
                  open={viewMore}
                  poolbyid={poolById}
                  showIndex={showIndex}
                  requestType={requestType}
                />
              </div>
            )}
          </div>
        )}
        {/* </TableContainer> */}
        {/* pagination */}
        {/* <div className="pagination_div_pool">
              <Pagination
                color="standard"
                count={Math.ceil(pagination.total_count / per_page)}
                page={page + 1}
                variant="outlined"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
                onChange={handleChangePage}
                shape="rounded"
              />
            </div> */}
      </div>

      {/* pool model */}
      <PoolModel
        openModel={openModel}
        handleClose={handleClose}
        client={client}
        pools={
          requestType == 0
            ? maturityPlanPools
            : requestType == 2
            ? monthlyPlanPools
            : freedomPlanPools
        }
        disabled={disabled}
        onKeyPress={onKeyPress}
        selectedIndex={selectedIndex}
        setAmount={(value) => setAmount(value)}
        onSendPayment={(value) => onSendPayment(value)}
      />

      {/* pool client list model
          <PoolClientlistModel
            openClientModel={openClientModel}
            handleClose={handleClose}
            selectedClient={client}
            clientData={clientData}
            requestSearch={requestSearch}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            sortByName={sortByName}
            invest={(row) => {
              if (Array.isArray(row)) {
                setClient(row);
              } else {
                let data = {
                  name: row.name,
                  uuid: row.uuid,
                  available_funds: row.available_funds,
                  net_worth: row.net_worth,
                };
                setClient(data);
              }
              setOpenClientModel(false);
            }}
          /> */}
      {/* Investment request model */}
      <ClientInvestmentRequestModel
        openModel={openClientInvestmentRequestModel}
        amountInvalid={amountInvalid}
        amountInvalidText={amountInvalidText}
        setAmountInvalidText={setAmountInvalidText}
        setAmountInvalid={setAmountInvalid}
        handleClose={() => setOpenClientInvestmentRequestModel(false)}
        minimumInvestment={investedPool["minimum_investment"]}
        type={investedPool["type"]}
        tenure={
          investedPool["type"] == 1
            ? "Withdraw Anytime"
            : investedPool["tenure"]
        }
        tenureType={investedPool["tenure_type"]}
        interest_rate={investedPool["interest_rate"]}
        alreadyInvested={0}
        walletBalance={props.walletBalance}
        selectedIndex={0}
        onKeyPress={onKeyPress}
        setAmount={(value) => setInvestAmount(value)}
        loading={loading}
        onProceed={() => onInvestmentRequestProceed()}
      />
    </div>
  );
});

export default PoolTemplates;
