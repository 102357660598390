import * as React from "react";
import "./pdf-viewer.css";
import Loader from "react-loader-spinner";
import Modal from "@mui/material/Modal";
import { alertTitleClasses, Box } from "@mui/material";
import config from "../../config/settings";
import { AlreadyAcceptedModal } from "..";
import CloseIcon from "@mui/icons-material/Close";

const styles = (theme) => ({
  modalStyle1: {},
});

export default function PdfViewer(props) {
  // prevent only digit

  const [bottom, setBottom] = React.useState(false);
  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [acceptedModelOpen, setAcceptedModelOpen] = React.useState(
    props.alreadyAccepted
  );

  const handleScroll = (e) => {
    console.log(e);
    setBottom(true);
  };

  React.useEffect(() => {
    setAcceptedModelOpen(props.alreadyAccepted);
  }, [props.alreadyAccepted]);

  // console.log("url",props.url);

  return (
    <Modal
      open={props.openModel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      hideBackdrop={true}
      style={{
        backgroundColor: '#e1e3e6',//"#e1e3e6"
        alignItems: "center",
        justifyContent: "center",
        border: "1px solid transparent",
        borderRadius: "25px",
        margin: "0px",
        padding: "0px",
        top: "15%",
        left: "3%",
        right: "3%",
        bottom: window.innerWidth > 550 ? "10%" : "12%",
        WebkitBoxShadow: "10px 10px 30px #ebe8e8",
        MozBoxShadow: "10px 10px 30px #ebe8e8",
      }}
      disableScrollLock={false}
    >
      <div style={{ textAlign: "center", outline: 0 }}>
        <div
          style={{
            backgroundColor: "#e1e3e6",
            position: "absolute",
            left: "0%",
            right: "0%",
            top: window.innerWidth > 550?"1%":"0%",
            borderTopLeftRadius: "25px",
            borderTopRightRadius: "25px",
          }}
        >
          {props.hasOwnProperty("setOpenModel") ? (
            <div className="pdf_viewer_header_container">
              <div>
                <p
                  style={{
                    color: "black",
                    fontSize: window.innerWidth > 550 ? "24px" : "18px",
                  }}
                >
                  {props.header}
                </p>
              </div>
              <div
                style={{ cursor: "pointer", paddingRight: "5px", paddingTop : "10px"}}
                onClick={() => props.setOpenModel(false)}
              >
                <CloseIcon fontSize="medium" />
              </div>
            </div>
          ) : (
            <p
              style={{
                color: "black",
                fontSize: window.innerWidth > 550 ? "24px" : "18px",
              }}
            >
              {props.header}
            </p>
          )}
        </div>
        {acceptedModelOpen ? (
          <AlreadyAcceptedModal
            closeModel={setAcceptedModelOpen}
            header={props.header}
          ></AlreadyAcceptedModal>
        ) : (
          <div />
        )}
        <br />
        <div style={{ textAlign: "center" }}>
          {/* title */}

          <div
            className="pdf_viewer_model_title_div"
            style={{
              overflowY: "scroll",
              marginTop: "5vh",
              maxHeight: window.innerWidth > 550 ? "62vh" : "60vh",
            }}
            onScroll={handleScroll}
          >
            <iframe
              id="myFrame"
              src={`${props.url}#toolbar=0&navpanes=0`}
              frameBorder="0"
              style={{
                width: "100vw",
                margin: "0px",
                height: window.innerWidth > 550 ? "62vh" : "65vh",
                border: "0px white solid",
                lineHeight: "0",
              }}
            />
          </div>
          {/* send button */}
          <div style={{display: props.onSubmit?'flex':'none', justifyContent: "center", backgroundColor: "rgb(225, 227, 230)", borderRadius: "0px 0px 25px 25px", padding: "10px"}}>
            <button
              disabled={props.alreadyAccepted}
              className="pdf_viewer_model_button"
              style={{
                backgroundColor:
                  !props.alreadyAccepted ? "#3a86ff" : "#ada8a8",
                cursor:
                  !props.alreadyAccepted ? "pointer" : "not-allowed",
                borderRadius: "10px"
              }}
              onClick={props.onSubmit}
            >
              {props.loading ? (
                <Loader type="TailSpin" color="#fff" height={15} width={15} />
              ) : (
                "I Agree"
              )}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
