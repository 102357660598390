import React, { useRef, useState, useEffect } from "react";
import "./org-document.css";
import { SignupButton, UploadInput } from "../../index";
import {
  checkSignupDisabled,
  convertIntoDefaultState,
  convertIntoErrorState,
  errorToast,
  fillKycData,
  getFirmAssociatedOrgDocuments,
  getFrontendStateObject,
  successToast,
} from "../../../utils/helper";
import { orgDocumentState } from "../../../constant/signupState";
import { Kyc_API } from "../../../services/api";
import { useStores } from "../../../models";
import { InputInfoText } from "../..";
import { eventLog } from "../../../utils/firebase";

const imageSizeLimit = 15;

export default function OrgDocument(props) {
  const [orgDocument, setOrgDocument] = useState(
    convertIntoDefaultState(orgDocumentState)
  );
  const [orgDocumentError, setOrgDocumentError] = useState(
    convertIntoErrorState(orgDocumentState)
  );
  const [loader, setLoader] = useState(false);
  const [hasButtonDisabled, sethasButtonDisabled] = useState(false);
  const [documentsToRender, setDocumentsToRender] = useState([]);

  const api = new Kyc_API();
  const rootStore = useStores();

  useEffect(() => {
    //fill data in kyc input
    fillKycData(props.ifa, orgDocument, setOrgDocument);
  }, [props.ifa]);

  useEffect(() => {
    //check for disablity of signup button
    setDocumentsToRender(getFirmAssociatedOrgDocuments(props.firmName));
  }, [props.firmName]);

  useEffect(() => {
    //check for disablity of signup button
    let flag = false;
    for (let key of documentsToRender) {
      if (!orgDocument[key] || orgDocument[key].length == 0) {
        flag = true;
        break;
      }
    }
    if (flag) {
      sethasButtonDisabled(true);
    } else {
      sethasButtonDisabled(false);
    }

    eventLog({
      type: "org_documents",
      action: {
        trigger: "true",
      },
    });
  }, [orgDocument, documentsToRender]);

  const handleUploadCallback = (res, id, fileName, isFileUploaded) => {
    setOrgDocument({
      ...orgDocument,
      [id]: fileName,
    });
    if (isFileUploaded) {
      successToast("File uploaded successfully.");
    }
  };

  const handleSubmit = async () => {
    onSuccess(orgDocument);
  };

  const onSuccess = (payload) => {
    setLoader(true);
    rootStore.userData
      .updateOrgDocument(payload)
      .then((res) => {
        if (res.kind == "ok") {
          setLoader(false);
          props.setCurrentStep(3);
        } else {
          errorToast(res.error);
          setLoader(false);
        }
      })
      .catch((error) => {
        errorToast(
          "Failed to update KYC due to api failure. Please try again or else contact support."
        );
        setLoader(false);
      });
  };

  return (
    <React.Fragment>
      <div>
        <form className="org_document_form_container">
          {/*account number input box*/}

          {/**upload cancel cheque image */}
          {Object.keys(orgDocument).map((key, index) => {
            if (key != "other_document") {
              return (
                <div>
                  <UploadInput
                    key={index}
                    label={orgDocumentState[key].label}
                    id={key}
                    raw={true}
                    visibility={documentsToRender.includes(key)}
                    fileName={orgDocument[key]}
                    setFileName={setOrgDocument}
                    object={setOrgDocument}
                    uploadFileToS3={true}
                    additionalPath="firm_type_doc/"
                    sourceType={orgDocumentState[key].label} //required with shouldRunOcr paramter
                    callback={handleUploadCallback}
                  />
                  <div className="info_text_container">
                    {documentsToRender.includes(key) ? (
                      <InputInfoText
                        text={`The maximum file size should not exceed ${imageSizeLimit} MB.`}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              );
            }
          })}
          <UploadInput
            key={100}
            label={orgDocumentState["other_document"].label}
            id={"other_document"}
            raw={true}
            fileName={orgDocument["other_document"]}
            optional={true}
            setFileName={setOrgDocument}
            object={setOrgDocument}
            uploadFileToS3={true}
            additionalPath="firm_type_doc/"
            sourceType={orgDocumentState["other_document"].label} //required with shouldRunOcr paramter
            callback={handleUploadCallback}
          />
          <div className="info_text_container">
            <InputInfoText
              text={`The maximum file size should not exceed ${imageSizeLimit} MB.`}
            />
          </div>
          {/* submit */}
          <SignupButton
            loader={loader}
            disabled={hasButtonDisabled}
            text={"Verify"}
            onSubmit={handleSubmit}
          />
        </form>
      </div>
    </React.Fragment>
  );
}
