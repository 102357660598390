import * as React from "react";
import "./pool-card.css";
import logo from "../../assets/images/logo.jpg";
import { amountComma, convertAmount } from "../../utils/helper";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CircleIcon from "@mui/icons-material/Circle";
import WarningIcon from "@mui/icons-material/Warning";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { eventLog } from "../../utils/firebase";

export default function PoolCard(props) {
  if (props.requestType == 1) {
    return (
      <div>
        {props.displayPools
          .slice()
          .sort((a, b) => a.tenure - b.tenure)
          .map((item, index) => (
            <div className="pool_template_card">
              <div className="pool_template_card_content_container">
                <div className="pool_template_card_content">
                  <div className="pool_template_card_content_title">
                    Interest Rate p.a.
                  </div>
                  <div className="pool_template_card_content_value">
                    <span style={{ fontWeight: "500px" }}>Up to</span>
                    <span
                      style={{
                        fontSize: "30px",
                        fontWeight: "600",
                        marginLeft: "5px",
                      }}
                    >
                      {" "}
                      {parseFloat(item.interest_rate).toFixed(2)} %
                    </span>
                  </div>
                </div>
                <div className="pool_template_card_content">
                  <div className="pool_template_card_content_title">Tenure</div>
                  <div className="pool_template_card_content_value">
                    {item?.pool_title?.toLowerCase() == "freedom plan"
                      ? "Withdraw Anytime"
                      : item.tenure == "undefined"
                      ? "NA"
                      : `${item.tenure} ${
                          item.tenure == 1 && item.tenure_type === "Months"
                            ? "Month"
                            : item.tenure_type
                        }`}
                  </div>
                </div>
                <div className="pool_template_card_content">
                  <div className="pool_template_card_content_title">
                    Minimum Investment
                  </div>
                  <div className="pool_template_card_content_value">
                    ₹ {amountComma(item.minimum_investment)}
                  </div>
                </div>
              </div>
              <div className="pool_template_card_button_container">
                <button
                  disabled={props.disabled}
                  className="pool_template_investment_button"
                  onClick={() => {
                    props.checkClient(item);
                    eventLog({
                      type: "invest_now",
                      action: {
                        trigger: "true",
                      },
                    });
                  }}
                >
                  Invest Now
                </button>
              </div>
            </div>
          ))}
      </div>
    );
  } else {
    return (
      <div className="poollist_column_container">
        {props.displayPools
          .slice()
          .sort((a, b) => a.tenure - b.tenure)
          .map((item, index) => (
            <div className="pool_container" key={index}>
              {/* pool top container */}
              <div className="pool_top_container">
                {/* pool logo and title container */}
                <div className="pool_top_element">
                  {/* interest rate */}
                  <p className="pool_top_title">Interest Rate p.a.</p>
                  <div className="pool_top_subtitle">
                    Up to{" "}
                    <span
                      style={{
                        fontSize: "34px",
                        fontWeight: "600",
                        marginLeft: "5px",
                      }}
                    >
                      {" "}
                      {parseFloat(item.interest_rate).toFixed(2)} %
                    </span>
                  </div>
                </div>

                {/* issue period */}
                {/* issue period */}

                {/* <div>
                {/* <div>
                <p className="pool_top_title">Issue Period</p>
                <p className="pool_top_subtitle">
                  {String(item.start_date).split(",")[0]} to{" "}
                  {String(item.end_date).split(",")[0]}
                </p>
              </div> */}

                {/* amount till invested */}
                {/* <div>
                {/* amount till invested */}
                {/* <div>
                <p className="pool_top_title">Amount Invested Till Date</p>
                <p className="pool_top_subtitle">
                  ₹ {convertAmount(item.totle_investment)}
                </p>
              </div> */}

                {/* risk rate container */}
                {/* <div
                {/* risk rate container */}
                {/* <div
                className="pool_riskrate_container"
                style={{ backgroundColor: `${item.risk_color}20` }}
              >
                <p
                  className="pool_riskrate_container_title"
                  style={{ color: item.risk_color }}
                >
                  Risk rating
                </p>
                <p
                  className="pool_riskrate_container_text"
                  style={{ color: item.risk_color }}
                >
                  {item.risk_rate}
                </p>
              </div> */}
              </div>

              {/* pool bottom container */}
              {/* <div className="pool_bottom_container"> */}
              {/* tenure */}
              {/* <div className="pool_bottom_element">
                  <p className="pool_bottom_title">Tenure</p>
                  <p className="pool_bottom_subtitle">
                    {item?.pool_title?.toLowerCase() == "freedom plan"
                      ? "Withdraw anytime"
                      : item.tenure == "undefined"
                      ? "NA"
                      : `${item.tenure} ${
                          item.tenure == 1 && item.tenure_type === "Months"
                            ? "Month"
                            : item.tenure_type
                        }`}
                  </p>
                </div> */}

              {/* minimum investment amount */}
              {/* <div className="pool_bottom_element">
                  <p className="pool_bottom_title">Minimum Investment</p>
                  <p className="pool_bottom_subtitle">
                    ₹ {amountComma(item.minimum_investment)}
                  </p>
                </div> */}
              {/* invest now */}
              {/* {parseFloat(item.demand_amount) <=
                parseFloat(item.totle_investment) +
                  parseFloat(item.minimum_investment) ? (
                  // warning container
                  <div className="pool_container_warning">
                    <WarningIcon
                      style={{
                        color: "#ED8134",
                        fontSize: "20px",
                      }}
                    />
                    <p className="pool_container_warning_text">
                      {" "}
                      This pool has been fully funded.
                    </p>
                  </div>
                ) : (
                  <div className="pool_bottom_container_button_div">
                    <button
                      disabled={props.disabled}
                      className="pool_bottom_container_button"
                      onClick={() => {
                        props.checkClient(item);
                        eventLog({
                          type: "invest_now",
                          action: {
                            trigger: "true",
                          },
                        });
                      }}
                    >
                      Invest Now
                    </button>
                  </div>
                )} */}
              {/* pool bottom container */}
              <div className="pool_bottom_container">
                {/* tenure */}
                <div className="pool_bottom_element">
                  <p className="pool_bottom_title">Tenure</p>
                  <p className="pool_bottom_subtitle">
                    {item?.pool_title?.toLowerCase() == "freedom plan"
                      ? "Withdraw anytime"
                      : item.tenure == "undefined"
                      ? "NA"
                      : `${item.tenure} ${
                          item.tenure == 1 && item.tenure_type === "Months"
                            ? "Month"
                            : item.tenure_type
                        }`}
                  </p>
                </div>
                {/* minimum investment amount */}
                <div className="pool_bottom_element">
                  <p className="pool_bottom_title">Minimum Investment</p>
                  <p className="pool_bottom_subtitle">
                    ₹ {amountComma(item.minimum_investment)}
                  </p>
                </div>
                {/* invest now */}
                {parseFloat(item.demand_amount) <=
                parseFloat(item.totle_investment) +
                  parseFloat(item.minimum_investment) ? (
                  // warning container
                  <div className="pool_container_warning">
                    <WarningIcon
                      style={{
                        color: "#ED8134",
                        fontSize: "20px",
                      }}
                    />
                    <p className="pool_container_warning_text">
                      {" "}
                      This pool has been fully funded.
                    </p>
                  </div>
                ) : (
                  <div className="pool_bottom_container_button_div">
                    <button
                      disabled={props.disabled}
                      className="pool_bottom_container_button"
                      onClick={() => {
                        props.checkClient(item);
                        eventLog({
                          type: "invest_now",
                          action: {
                            trigger: "true",
                          },
                        });
                      }}
                    >
                      Invest Now
                    </button>
                  </div>
                )}

                {/* view more */}
                {/* <div className="pool_bottom_container_view_more_div">
                {/* view more */}
                {/* <div className="pool_bottom_container_view_more_div">
                <p
                  className="pool_view_more_text"
                  onClick={() =>
                    props.getPoolDetails(
                      item.id,
                      index,
                      props.open && props.showIndex === index
                        ? "View Less"
                        : "View More"
                    )
                  }
                >
                  {props.open && props.showIndex === index
                    ? "View Less"
                    : "View More"}
                </p>
                {props.open && props.showIndex === index ? (
                  <KeyboardArrowUpIcon
                    style={{
                      color: "#3A86FF",
                      fontSize: "20px",
                    }}
                  />
                ) : (
                  <KeyboardArrowDownIcon
                    style={{
                      color: "#3A86FF",
                      fontSize: "20px",
                    }}
                  />
                )}
              </div> */}
              </div>

              {/* pool more details container */}
              <div
                className="pool_more_details"
                style={{
                  display:
                    props.open && props.showIndex === index ? "flex" : "none",
                }}
              >
                {/* about */}
                <div>
                  <p className="pool_more_details_title">About</p>
                  <p className="pool_more_details_subtitle">
                    {props.poolbyid[0] !== undefined
                      ? props.poolbyid[0].description
                      : "About"}
                  </p>
                </div>
                {/* pool more details container */}
                <div
                  className="pool_more_details"
                  style={{
                    display:
                      props.open && props.showIndex === index ? "flex" : "none",
                  }}
                >
                  {/* about */}
                  <div>
                    <p className="pool_more_details_title">About</p>
                    <p className="pool_more_details_subtitle">
                      {props.poolbyid[0] !== undefined
                        ? props.poolbyid[0].description
                        : "About"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    );
  }
}
