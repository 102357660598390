import * as React from "react";
import "./common-sidebar.css";
import { useHistory, useLocation } from "react-router-dom";
import { Auth } from "aws-amplify";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import AlertBox from "../alert-box/alert";
import {
  clientLogo1,
  clientLogo2,
  support1,
  support2,
  overview1,
  overview2,
  paymentLink1,
  paymentLink2,
  loanPools1,
  loanPools2,
  faq1,
  faq2,
  download1,
  download2,
  market2,
  market1,
  transaction1,
  transaction2,
} from "../../assets/images/index";

const CommonSidebar = function () {
  const navigate = useHistory();
  const param = useLocation();
  const [logoutAlert, setLogoutAlert] = React.useState(false);

  // log out function
  const onLogOut = async () => {
    await Auth.signOut()
      .then(() => {
        navigate.push("/");
        localStorage.clear();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="common_sidebar_main_container">
      {/* menu div */}
      <div className="common_sidebar_menu_div">
        {/* menu items */}
        <div className="common_sidebar_menu_item_div">
          {/* overview */}

          <div
            className="common_sidebar_menu_text_div"
            style={{
              backgroundColor:
                param.pathname === "/overview" ? "#E9F2FF" : undefined,
            }}
            onClick={() => navigate.push("/overview")}
          >
            <div className="logo">
              <img
                src={param.pathname === "/overview" ? overview1 : overview2}
              />
            </div>
            <p
              className="common_sidebar_menu_text"
              style={{
                color: param.pathname === "/overview" ? "#3A86FF" : "#272727",
              }}
            >
              Overview
            </p>
          </div>

          {/* clients */}
          <div
            className="common_sidebar_menu_text_div"
            style={{
              backgroundColor: param.pathname.startsWith("/clients")
                ? "#E9F2FF"
                : undefined,
            }}
            onClick={() => navigate.push("/clients")}
          >
            <div className="logo">
              <img
                src={param.pathname === "/clients" ? clientLogo1 : clientLogo2}
              />
            </div>
            <p
              className="common_sidebar_menu_text"
              style={{
                color: param.pathname.startsWith("/clients")
                  ? "#3A86FF"
                  : "#272727",
              }}
            >
              Clients
            </p>
          </div>

          {/* open pools */}
          <div
            className="common_sidebar_menu_text_div"
            style={{
              backgroundColor: param.pathname.startsWith("/pools")
                ? "#E9F2FF"
                : undefined,
            }}
            onClick={() => {
              if (param.pathname !== "/pools") {
                navigate.push("/pools");
              }
            }}
          >
            <div className="logo">
              <img
                src={param.pathname === "/pools" ? loanPools1 : loanPools2}
              />
            </div>
            <p
              className="common_sidebar_menu_text"
              style={{
                color: param.pathname.startsWith("/pools")
                  ? "#3A86FF"
                  : "#272727",
              }}
            >
              Investment Plans
            </p>
          </div>

          {/* transaction requests */}
          <div
            className="common_sidebar_menu_text_div"
            style={{
              backgroundColor: param.pathname.startsWith(
                "/transaction-requests"
              )
                ? "#E9F2FF"
                : undefined,
            }}
            onClick={() => navigate.push("/transaction-requests")}
          >
            <div className="logo">
              <img
                src={
                  param.pathname.startsWith("/transaction-requests")
                    ? transaction1
                    : transaction2
                }
              />
            </div>
            <p
              className="common_sidebar_menu_text"
              style={{
                color: param.pathname.startsWith("/transaction-requests")
                  ? "#3A86FF"
                  : "#272727",
              }}
            >
              Transaction Requests
            </p>
          </div>

          {/* commissions summary */}
          {/* <div
            className="common_sidebar_menu_text_div"
            style={{
              backgroundColor: param.pathname.startsWith("/summary/commissions")
                ? "#E9F2FF"
                : undefined,
            }}
            onClick={() => navigate.push("/summary/commissions")}
          >
            <div className="logo">
              <img
                src={
                  param.pathname.startsWith("/summary/commissions")
                    ? commissions1
                    : commissions2
                }
              />
            </div>
            <p
              className="common_sidebar_menu_text"
              style={{
                color: param.pathname.startsWith("/summary/commissions")
                  ? "#3A86FF"
                  : "#272727",
              }}
            >
              Commissions Summary
            </p>
          </div> */}

          {/* payment links */}
          {/* <div
            className="common_sidebar_menu_text_div"
            style={{
              backgroundColor: param.pathname.startsWith("/paymentlinks")
                ? "#E9F2FF"
                : undefined,
            }}
            onClick={() => navigate.push("/paymentlinks")}
          >
            <div className="logo">
              <img
                src={
                  param.pathname.startsWith("/paymentlinks")
                    ? paymentLink1
                    : paymentLink2
                }
              />
            </div>
            <p
              className="common_sidebar_menu_text"
              style={{
                color: param.pathname.startsWith("/paymentlinks")
                  ? "#3A86FF"
                  : "#272727",
              }}
            >
              Investment Requests
            </p>
          </div> */}

          {/* wallet loading links */}
          {/* <div
            className="common_sidebar_menu_text_div"
            style={{
              backgroundColor: param.pathname.startsWith("/walletLinks")
                ? "#E9F2FF"
                : undefined,
            }}
            onClick={() => navigate.push("/walletLinks")}
          >
            <div className={param.pathname.startsWith("/walletLinks") ? "logo1" : "logo"}>
              <img
                style={{margin: '4px -1px 0px 0px', color: param.pathname.startsWith("/walletLinks")
                ? "#3A86FF"
                : "#272727"}}
                src={walletLoadingOutline}
              />
            </div>
            <p
              className="common_sidebar_menu_text"
              style={{
                color: param.pathname.startsWith("/walletLinks")
                  ? "#3A86FF"
                  : "#272727",
              }}
            >
              Wallet Loading Requests
            </p>
          </div> */}

          {/* withdrawal */}
          {/* <div
            className="common_sidebar_menu_text_div"
            style={{
              backgroundColor: param.pathname.startsWith("/withdrawals")
                ? "#E9F2FF"
                : undefined,
            }}
            onClick={() => navigate.push("/withdrawals")}
          >
            <p
              className="common_sidebar_menu_text"
              style={{
                color: param.pathname.startsWith("/withdrawals")
                  ? "#3A86FF"
                  : "#272727",
              }}
            >
              Withdrawal
            </p>
          </div> */}

          {/* Downloads */}
          <div
            className="common_sidebar_menu_text_div"
            style={{
              backgroundColor: param.pathname.startsWith("/downloads")
                ? "#E9F2FF"
                : undefined,
            }}
            onClick={() => navigate.push("/downloads")}
          >
            <div className="logo">
              <img
                src={param.pathname === "/downloads" ? download1 : download2}
              />
            </div>
            <p
              className="common_sidebar_menu_text"
              style={{
                color: param.pathname.startsWith("/downloads")
                  ? "#3A86FF"
                  : "#272727",
              }}
            >
              Resources
            </p>
          </div>

          {/* Marketing */}
          {/* <div
            className="common_sidebar_menu_text_div"
            style={{
              backgroundColor: param.pathname.startsWith("/marketing")
                ? "#E9F2FF"
                : undefined,
            }}
            onClick={() => navigate.push("/marketing")}
          >
            <div className="logo">
              <img
                src={param.pathname === "/marketing" ? market1 : market2}
              />
            </div>
            <p
              className="common_sidebar_menu_text"
              style={{
                color: param.pathname.startsWith("/marketing")
                  ? "#3A86FF"
                  : "#272727",
              }}
            >
              Marketing
            </p>
          </div> */}

          {/* Supports */}
          <div
            className="common_sidebar_menu_text_div"
            style={{
              backgroundColor: param.pathname.startsWith("/support")
                ? "#E9F2FF"
                : undefined,
            }}
            onClick={() => navigate.push("/support")}
          >
            <div className="logo">
              <img src={param.pathname === "/support" ? support1 : support2} />
            </div>
            <p
              className="common_sidebar_menu_text"
              style={{
                color: param.pathname.startsWith("/support")
                  ? "#3A86FF"
                  : "#272727",
              }}
            >
              Support
            </p>
          </div>
          <div
            className="common_sidebar_menu_text_div"
            style={{
              backgroundColor: param.pathname.startsWith("/faq")
                ? "#E9F2FF"
                : undefined,
            }}
            onClick={() => navigate.push("/faq")}
          >
            <div className="logo">
              <img src={param.pathname === "/faq" ? faq2 : faq1} />
            </div>
            <p
              className="common_sidebar_menu_text"
              style={{
                color: param.pathname.startsWith("/faq")
                  ? "#3A86FF"
                  : "#272727",
              }}
            >
              FAQs
            </p>
          </div>
        </div>

        {/* logout div */}
        <div className="common_sidebar_menu_logout_div">
          {/* loogout button */}
          <div
            className="common_sidebar_menu_logout_button_container"
            onClick={() => setLogoutAlert(true)}
          >
            {/* logout icon */}
            <PowerSettingsNewIcon
              style={{
                fontSize: "18px",
                marginRight: "10px",
                color: "#fff",
              }}
            />
            {/* logout text */}
            <p className="common_sidebar_menu_logout_text">Logout</p>
          </div>
        </div>
      </div>
      {/* show logout alert */}
      {logoutAlert ? (
        <AlertBox
          show={logoutAlert}
          title="Log out"
          content="Are you sure you want to logout?"
          positivebutton="Yes"
          negativebutton="No"
          yes={onLogOut}
          no={() => setLogoutAlert(false)}
        />
      ) : (
        <div />
      )}
    </div>
  );
};

export default CommonSidebar;
