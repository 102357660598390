import { Instance, SnapshotOut, types } from "mobx-state-tree";

export const Ifa_Data_Model = types
  .model("IfaDataModel")
  .props({
    mobile: types.optional(types.string, ""),
    panNumber: types.optional(types.string, ""),
    addressFrontImage: types.optional(types.string, ""),
    addressBackImage: types.optional(types.string, ""),
    gstNumber: types.optional(types.string, ""),
    panImage: types.optional(types.string, ""),
    document1: types.optional(types.string, ""),
    document2: types.optional(types.string, ""),
    document3: types.optional(types.string, ""),
    document4: types.optional(types.string, ""),
    accountNumber: types.optional(types.string, ""),
    ifscCode: types.optional(types.string, ""),
    cancelChequeImage: types.optional(types.string, ""),
  })
  .views((self) => ({}))
  .actions((self) => ({}));

type Ifa_Data_Model_DataType = Instance<typeof Ifa_Data_Model>;
export interface Ifa_Data extends Ifa_Data_Model_DataType {}

type Ifa_Data_Model_SnapShotType = SnapshotOut<typeof Ifa_Data_Model>;
export interface Ifa_Data_SnapShot extends Ifa_Data_Model_SnapShotType {}
