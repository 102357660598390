import * as React from "react";
import "./withdrawal-model.css";
import Modal from "@mui/material/Modal";
import Loader from "react-loader-spinner";
import { convertAmount, handleKeyDown } from "../../utils/helper";
import CloseIcon from "@mui/icons-material/Close";
import { eventLog } from "../../utils/firebase";
import AdditionalInfo from "../additional_info/additional_info";

export default function WithdrawalModel(props) {
  return (
    <Modal
      open={props.openModel}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{
        backgroundColor: "rgba(102, 102, 102, 0.72)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className="withdrawal_model_div">
        <div className="withdrawal_model_header">
          <div style={{ float: "left" }}>
            <h1 className="withdrawal_model_header_title">
              Withdrawal Request
            </h1>
          </div>
          <div
            style={{ float: "right", cursor: "pointer" }}
            onClick={props.handleClose}
          >
            <h1 className="withdrawal_model_header_title">
              <CloseIcon />
            </h1>
          </div>
        </div>
        {/* title */}
        <div className="withdrawal_model_main_div">
          <div className="withdrawal_model_title_div">
            <h1 className="withdrawal_model_title">
              Amount available to withdraw for
            </h1>
            <h1
              className="withdrawal_model_title"
              style={{
                marginLeft: "5px",
                // color: "#3a86ff",
                marginRight: "5px",
              }}
            >
              {props.clientData
                ? props.clientData[props.selectedIndex]
                  ? props.clientData[props.selectedIndex].name
                    ? props.clientData[props.selectedIndex].name
                        .toLowerCase()
                        .replace(/\b\w/g, function (char) {
                          return char.toUpperCase();
                        })
                    : ""
                  : ""
                : ""}
            </h1>
          </div>

          <div
            className="withdrawal_transaction_container"
            style={{ paddingTop: "5px" }}
          >
            <div className="withdrawal_transaction_grid_container">
              <div className="withdrawal_transaction_item_left">
                <p className="withdrawal_transaction_item_text">Idle Funds</p>
              </div>
              <div className="withdrawal_transaction_item_right">
                <p className="withdrawal_transaction_item_text">
                  ₹{" "}
                  {convertAmount(
                    props.clientData
                      ? props.clientData[props.selectedIndex]
                        ? props.clientData[props.selectedIndex].withdrawable
                          ? props.clientData[props.selectedIndex].withdrawable
                          : 0
                        : 0
                      : 0
                  )}
                </p>
              </div>
            </div>

            <div className="withdrawal_transaction_grid_container">
              <div className="withdrawal_transaction_item_left">
                <p
                  className="withdrawal_transaction_item_text"
                  style={{ color: "black", fontWeight: "bold" }}
                >
                  Freedom Plan
                </p>
              </div>
              {/* <div className="withdrawal_transaction_item_right">
              <p className="withdrawal_transaction_item_text">
                ₹{" "}
                {convertAmount(props.clientData
                  ? props.clientData[props.selectedIndex]
                    ? props.clientData[props.selectedIndex]
                        .freedom_plan_withdrawable
                      ? props.clientData[props.selectedIndex]
                          .freedom_plan_withdrawable
                      : 0
                    : 0
                  : 0)}
              </p>
            </div> */}
            </div>

            <div
              className="withdrawal_transaction_grid_container"
              style={{ marginLeft: "15px" }}
            >
              <div className="withdrawal_transaction_item_left">
                <p className="withdrawal_transaction_item_text">
                  Unwithdrawn Earnings
                </p>
              </div>
              <div className="withdrawal_transaction_item_right">
                <p className="withdrawal_transaction_item_text">
                  ₹{" "}
                  {convertAmount(
                    props.clientData
                      ? props.clientData[props.selectedIndex]
                        ? props.clientData[props.selectedIndex].overall_fund
                          ? props.clientData[props.selectedIndex].overall_fund
                          : 0
                        : 0
                      : 0
                  )}
                </p>
              </div>
            </div>

            <div
              className="withdrawal_transaction_grid_container"
              style={{ marginLeft: "15px" }}
            >
              <div className="withdrawal_transaction_item_left">
                <p className="withdrawal_transaction_item_text">Principal</p>
              </div>
              <div className="withdrawal_transaction_item_right">
                <p className="withdrawal_transaction_item_text">
                  ₹{" "}
                  {convertAmount(
                    props.clientData
                      ? props.clientData[props.selectedIndex]
                        ? props.clientData[props.selectedIndex]
                            .freedom_plan_invested_amount
                          ? props.clientData[props.selectedIndex]
                              .freedom_plan_invested_amount
                          : 0
                        : 0
                      : 0
                  )}
                </p>
              </div>
            </div>

            <div
              className="withdrawal_transaction_grid_container"
              style={{
                marginLeft: "15px",
                display: props.clientData
                  ? props.clientData[props.selectedIndex]
                    ? props.clientData[props.selectedIndex]
                        .freedom_plan_locked_earnings
                      ? props.clientData[props.selectedIndex]
                          .freedom_plan_locked_earnings != 0
                        ? "flex"
                        : "none"
                      : "none"
                    : "none"
                  : "none",
              }}
            >
              <div className="withdrawal_transaction_item_left">
                <p className="withdrawal_transaction_item_text">
                  Locked Unwithdrawn Earnings
                </p>
              </div>
              <div className="withdrawal_transaction_item_right">
                <p className="withdrawal_transaction_item_text">
                  ₹{" "}
                  {convertAmount(
                    props.clientData
                      ? props.clientData[props.selectedIndex]
                        ? props.clientData[props.selectedIndex]
                            .freedom_plan_locked_earnings
                          ? props.clientData[props.selectedIndex]
                              .freedom_plan_locked_earnings
                          : 0
                        : 0
                      : 0
                  )}
                </p>
              </div>
            </div>

            <div
              className="withdrawal_transaction_grid_container"
              style={{
                marginLeft: "15px",
                display: props.clientData
                  ? props.clientData[props.selectedIndex]
                    ? props.clientData[props.selectedIndex]
                        .freedom_plan_locked_invested_amount
                      ? props.clientData[props.selectedIndex]
                          .freedom_plan_locked_invested_amount != 0
                        ? "flex"
                        : "none"
                      : "none"
                    : "none"
                  : "none",
              }}
            >
              <div className="withdrawal_transaction_item_left">
                <p className="withdrawal_transaction_item_text">
                  Locked Principal
                </p>
              </div>
              <div className="withdrawal_transaction_item_right">
                <p className="withdrawal_transaction_item_text">
                  ₹{" "}
                  {convertAmount(
                    props.clientData
                      ? props.clientData[props.selectedIndex]
                        ? props.clientData[props.selectedIndex]
                            .freedom_plan_locked_invested_amount
                          ? props.clientData[props.selectedIndex]
                              .freedom_plan_locked_invested_amount
                          : 0
                        : 0
                      : 0
                  )}
                </p>
              </div>
            </div>

            <hr />

            <div
              className="withdrawal_transaction_grid_container"
              style={{ paddingBottom: "6px" }}
            >
              <div className="withdrawal_transaction_item_left">
                <p className="withdrawal_transaction_item_text">
                  Total Withdrawable Amount
                </p>
              </div>
              <div className="withdrawal_transaction_item_right">
                <p className="withdrawal_transaction_item_text">
                  ₹{" "}
                  {convertAmount(
                    props.clientData
                      ? props.clientData[props.selectedIndex]
                        ? props.clientData[props.selectedIndex].available_funds
                          ? props.clientData[props.selectedIndex]
                              .available_funds
                          : 0
                        : 0
                      : 0
                  )}
                </p>
              </div>
            </div>
          </div>            
          {/* {props.clientData[0]?.locked ? <AdditionalInfo iIcon={"left"} information1={`Locked Amount: ₹ ${props.clientData[0].locked}`} information2={"The requested withdrawal amount is locked and unavailable for investment. Please verify the status in your Withdrawal Requests."} /> : null} */}
          {/* amount input */}
          <input
            type="number"
            onWheel={() => document.activeElement.blur()}
            onKeyDown={handleKeyDown}
            placeholder="Enter Amount"
            className="withdrawal_model_input"
            min="0"
            onKeyPress={props.onKeyPress}
            onChange={(event) => {
              const valid = event.target.value.match(/^(\d*\.{0,1}\d{0,2}$)/)
              if (!valid) {
                event.target.value = (Math.floor(parseFloat(event.target.value)*100)/100).toFixed(2);
              }
              props.setAmount(event.target.value)
              eventLog({
                type: "withdrawal_amount",
                action: {
                  trigger: "true",
                },
              });
            }}
          />

          <div style={{ textAlign: "center" }}>
            {/* send button */}
            <button
              className="withdrawal_model_button"
              onClick={() => props.onProceed()}
            >
              {props.loading ? (
                <Loader type="TailSpin" color="#fff" height={15} width={15} />
              ) : (
                "Proceed"
              )}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
