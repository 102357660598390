import * as React from "react";
import "./client_otp_model.css";
import Loader from "react-loader-spinner";
import Modal from "@mui/material/Modal";

export default function ClientOTPModel(props) {
  // prevent only digit
  const onKeyPress = (e) => {
    const re = /[^0-9]/g;
    if (re.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <Modal
      open={props.openModel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.699)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        className="client_otpenter_model_div"
        style={{ textAlign: "center", lineHeight: "30px" }}
      >
        {/* title */}
        <div className="client_otpenter_model_title_div">
          <h1 className="client_otpenter_model_title">
            OTP has been sent to registered Mobile Number
          </h1>
        </div>

        {/* amount input */}
        <input
          type="text"
          placeholder="Enter OTP"
          maxLength={6}
          className="client_otpenter_model_input"
          onKeyPress={onKeyPress}
          onChange={(event) => {
            props.setOtp(event.target.value);
          }}
        />

        {/* info */}
        <div className="client_otpenter_model_info_div">
          <p className="client_otpenter_model_info">Didn't receive OTP ?</p>
          <p
            className="client_otpenter_model_info"
            style={{
              marginLeft: "5px",
              color: "#3a86ff",
              cursor: "pointer",
              fontWeight: "600",
            }}
            // onClick={() => null}
            onClick={() => props.resendAgain()}
          >
            Resend again
          </p>
        </div>

        {/* send button */}
        <button
          className="client_otpenter_model_button"
          onClick={props.onSubmit}
        >
          {props.loading ? (
            <Loader type="TailSpin" color="#fff" height={15} width={15} />
          ) : (
            "Submit"
          )}
        </button>
      </div>
    </Modal>
  );
}
