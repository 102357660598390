import React, { useState } from "react";
import "./ifa_switch_plan_request.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import Loader from "react-loader-spinner";
import { convertAmount, convertedAsLocal, errorToast, successToast } from "../../utils/helper";
import { ContentLoadingLoader, SampleToast, LabelWithSortComponent } from "..";
import { SwitchPlanAPI } from "../../services/api";
import { sorting } from "../../utils/helper";
import TelegramIcon from "@mui/icons-material/Telegram";
import config from "../../config/settings";

const tableContainer = {
  marginTop: "20px",
  width: "92%",
  borderTopLeftRadius: "5px",
  borderTopRightRadius: "5px",
  borderTop: "1px solid #c7c7c7",
  borderLeft: "1px solid #c7c7c7",
  borderRight: "1px solid #c7c7c7",
  alignSelf: "center",
  overflow: "scroll",
};

const tableHeader = {
  backgroundColor: "#f6f6f6",
  fontWeight: "600",
  fontSize: 16,
  fontFamily: "Asap",
  color: "#1f1f1f",
  borderRight: "1px solid #c7c7c7",
  paddingTop: "15px",
  paddingLeft: "10px",
  paddingRight: "0px",
  paddingBottom: "15px",
};

const tableRow = {
  fontSize: 14,
  fontFamily: "Asap",
  fontWeight: "400",
  color: "#1c1c1c",
  borderRight: "1px solid #c7c7c7",
  paddingTop: "10px",
  paddingLeft: "10px",
  paddingRight: "0px",
  paddingBottom: "10px",
};

const IfaSwitchPlanRequest = (props) => {
    const api = new SwitchPlanAPI();
  const [page, setPage] = useState(0);
  const rowsPerPage = 15;
  const asc_label_sort = {
    request_date_time: false,
    client_name: false,
    amount: false,
  };
  const [asc, setAsc] = useState(asc_label_sort);
  const [selected, setSelected] = useState("request_date_time");
  const [selectedType, setSelectedType] = useState("timestamp");
  const [clickedIndex, setClickedIndex] = useState(-1);

  // request search function
  const requestSearch = (event) => {
    setPage(0);
    const filter_rows = props.filterData.filter((row) => {
      return row.client_name
        .toLowerCase()
        .includes(event.target.value.toLowerCase());
    });
    props.setPaymentData(filter_rows);
  };

  // resend request
  const handleResendRequest = async (row, index) => {
    setClickedIndex(index);
    api
      .resendSwitchPlanRequest(row.uuid)
      .then((res) => {
        if (res.kind == "ok") {
          successToast(res.data);
          setClickedIndex(-1);
          props.fetchAgain();
        } else {
          errorToast(res.error);
          setClickedIndex(-1);
        }
      })
      .catch((err) => {
        console.log("Error while resending request ::: ", err);
        setClickedIndex(-1);
      });
  };

  // handle page change function
  const handleChangePage = (event, newPage) => {
    console.log("handleChangePageEvent", event);
    setPage(newPage - 1);
  };
  
  return (
    <div>
      <div className="ifa_switch_plan_div_container">
        {/* table */}
        <div>
          {props.render === false ? (
            <ContentLoadingLoader />
          ) : (
            <div className="ifa_switch_plan_div_tablecontainer">
              {/* search_bar */}
              <div className="ifa_switch_plan_search_bar_div">
                <div className="ifa_switch_plan_search_bar">
                  {/* icon */}
                  <SearchIcon
                    style={{
                      fontSize: "20px",
                      paddingRight: "10px",
                      color: "#555555",
                    }}
                  />
                  {/* input box */}
                  <input
                    type="text"
                    placeholder="Search by Name"
                    className="ifa_switch_plan_search_bar_input"
                    onChange={requestSearch}
                  />
                </div>
              </div>

              {/* table */}
              <TableContainer style={tableContainer}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" style={tableHeader}>
                        <LabelWithSortComponent
                          label="Request Date & Time"
                          id="request_date_time"
                          type="timestamp"
                          asc={asc}
                          selected={selected}
                          setAsc={setAsc}
                          setSelected={setSelected}
                          setSelectedType={setSelectedType}
                        />
                      </TableCell>
                      <TableCell align="left" style={tableHeader}>
                        <LabelWithSortComponent
                          label="Client Name"
                          id="client_name"
                          type="string"
                          asc={asc}
                          selected={selected}
                          setAsc={setAsc}
                          setSelected={setSelected}
                          setSelectedType={setSelectedType}
                        />
                      </TableCell>
                      <TableCell align="left" style={tableHeader}>
                        Mobile No.
                      </TableCell>
                      <TableCell align="left" style={tableHeader}>
                        Switch To
                      </TableCell>
                      <TableCell align="left" style={tableHeader}>
                        <LabelWithSortComponent
                          label="Amount"
                          id="amount"
                          type="number"
                          asc={asc}
                          selected={selected}
                          setAsc={setAsc}
                          setSelected={setSelected}
                          setSelectedType={setSelectedType}
                        />
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          ...tableHeader,
                        }}
                      >
                        Status
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          ...tableHeader,
                          borderRight: "none",
                        }}
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.paymentData
                      .sort((a, b) =>
                        asc[selected]
                          ? sorting(a, b, selected, selectedType, "asc")
                          : sorting(a, b, selected, selectedType, "desc")
                      )
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => (
                        <TableRow
                          key={index}
                          style={{
                            backgroundColor:
                              index % 2 === 0 ? "#fafafa" : "#fff",
                          }}
                        >
                          <TableCell style={tableRow} align="left">
                            {convertedAsLocal(row.request_date_time)}
                          </TableCell>
                          <TableCell style={tableRow} align="left">
                            {row.client_name}
                          </TableCell>
                          <TableCell style={tableRow} align="left">
                            {row.mobile}
                          </TableCell>
                          <TableCell style={tableRow} align="left">
                            {row.investment_plan}
                          </TableCell>
                          <TableCell style={tableRow} align="left">
                            {`₹ ${convertAmount(row.amount)}`}
                          </TableCell>
                          <TableCell
                            style={{
                              ...tableRow,
                            }}
                            align="left"
                          >
                            <p
                              className="ifa_switch_plan_status_text"
                              style={{
                                color:
                                  config.switch_plan_status_color[row.status],
                              }}
                            >
                              {config.switch_plan_status[row.status]}
                            </p>
                          </TableCell>
                          <TableCell
                            style={{
                              ...tableRow,
                              borderRight: "none",
                            }}
                            align="left"
                          >
                            <div>
                              <button
                                className="ifa_switch_plan_resend_button_div"
                                onClick={() => {
                                  if (row.resend == 1) {
                                    handleResendRequest(row, index);
                                  }
                                }}
                                disabled={row.resend === 0 ? true : false}
                                style={{
                                  cursor:
                                    row.resend == 0 ? "not-allowed" : "pointer",
                                }}
                              >
                                {clickedIndex == index ? (
                                  <Loader
                                    type="TailSpin"
                                    color="#3a86ff"
                                    height={13}
                                    width={13}
                                  />
                                ) : (
                                  <TelegramIcon
                                    className={
                                      row.resend == 0
                                        ? "ifa_switch_plan_resend_button_disable"
                                        : "ifa_switch_plan_resend_button"
                                    }
                                  />
                                )}
                              </button>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                {/* if no data available */}
                {props.paymentData.length === 0 ? (
                  <div className="no_row_found_div">
                    <FindInPageIcon
                      style={{
                        fontSize: "100px",
                        color: "#555555",
                      }}
                    />
                    <p className="no_row_found">No row found</p>
                  </div>
                ) : (
                  <div />
                )}
              </TableContainer>
              {/* pagination */}
              <div className="pagination_div">
                <Pagination
                  color="standard"
                  count={Math.ceil(props.paymentData.length / rowsPerPage)}
                  page={page + 1}
                  variant="outlined"
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                  onChange={handleChangePage}
                  shape="rounded"
                />
              </div>
            </div>
          )}
        </div>

        {/* toast container */}
        <SampleToast />
      </div>
    </div>
  );
};

export default IfaSwitchPlanRequest;
