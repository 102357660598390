import "./cashfree.css";
import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import SampleToast from "../sample-toast/sample-toast";
import {
  errorToast,
  loadScript,
} from "../../utils/helper";
import FullScreenLoader from "../full-screen-loader/full-screen-loader";
import { IFA_Payment_API } from "../../services/api";
const api = new IFA_Payment_API();

const defaultStyle = {
  //to be replaced by the desired values
  backgroundColor: "#ffffff",
  color: "#3b8794",
  fontFamily: "Lato",
  fontSize: "15px",
  errorColor: "#ff0000",
  theme: "light",
};

const Cashfree = ({amount,style,onSuccess,onFailure }) => {
  const [fullLoader, setFullLoader] = useState(false);

  useEffect(() => {
    loadScript(
      `https://sdk.cashfree.com/js/ui/2.0.0/cashfree.${
        process.env.REACT_APP_ENV == "production" ? "prod" : "sandbox"
      }.js`
    );
    document.getElementById("pay-btn").click();
  }, []);

  const generateOrder = async () => {
    let params = {
      amount: amount
    };

    setFullLoader(true);
    await api.getSessionPaymentId(params).then((res) => {
      console.log(res);
      if (res.kind === "ok") {
        const response = res.data
        var paymentSessionId =
          response['payment_session_id'];
        var orderId =
          response['order_id'];
        // console.log(paymentSessionId);
        render(paymentSessionId);
        setFullLoader(false);
      } else {
        setFullLoader(false);
        errorToast("Order Id not generated");
      }
    });
  };

  const render = (paymentSessionId) => {
    console.log("rendering...");

    const cashfree = new window.Cashfree(paymentSessionId);

    const dropinConfig = {
      components: ["order-details", "card", "netbanking", "app", "upi"],
      onSuccess: async function (data) {
        //on success
        const response= data;
        console.log(data);
        setFullLoader(true);
        console.log("order_id");
        await api.checkPaymentStatus(response['order']['orderId']).then((res) => {
          if (res.kind === "ok") {
            console.log(`final_data:${res}`)
            const response = res.data
            setFullLoader(false);
            return onSuccess?onSuccess(res):null;
          } else {
            errorToast("Payment doesn't not verify");
            setFullLoader(false);
            return onFailure?onFailure(res):null;
          }
        });
      },
      onFailure: function (data) {
        //on failure
        console.log(`failure:${JSON.stringify(data)}`)
        return null;
      },
      style: style ? style : defaultStyle,
    };

    cashfree.drop(document.getElementById("payment-form"), dropinConfig);
  };

  return (
    <React.Fragment>
      <div>
        <div id="payment-form" style={{ margin: "7%" }}></div>
        <button
          id="pay-btn"
          onClick={generateOrder}
          style={{ display: "none" }}
        >
          Pay
        </button>
      </div>
      {fullLoader ? <FullScreenLoader /> : <div />}

      {/* toast container */}
      <SampleToast />
    </React.Fragment>
  );
};

export default Cashfree;
