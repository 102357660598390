import React, { useEffect, useState, useRef } from "react";
import { ErrorValue, InputInfoText } from "../../components";
import "./clientkyc.css";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Loader from "react-loader-spinner";
import {
  errorToast,
  getFileExtension,
  getUniqueUUID,
  imageCompressor,
  queryStringToJSON,
  warningToast,
  yearsBefore,
} from "../../utils/helper";
import { useLocation, useParams } from "react-router-dom";

const imageSizeLimit = 15;

const ClientKycProfile = function (props) {
  const location = useLocation();
  const search = queryStringToJSON(location.search);
  const [currDate, setCurrDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [maxDate, setMaxDate] = useState("");
  const addSelfieRef = useRef(null);
  const [addSelfieLoader, setAddSelfieLoader] = useState(false);
  const [profile, setProfile] = useState({
    profile_name:
      props.data.profile_name === undefined ? "" : props.data.profile_name,
    profile_mobile:
      props.data.profile_mobile === undefined
        ? search.mobile
          ? search.mobile
          : ""
        : props.data.profile_mobile,
    profile_email:
      props.data.profile_email === undefined
        ? search.email
          ? search.email
          : ""
        : props.data.profile_email,
    profile_father_name:
      props.data.profile_father_name === undefined
        ? ""
        : props.data.profile_father_name,
    profile_dob:
      props.data.profile_dob === undefined ? "" : props.data.profile_dob,
    profile_permanent_address:
      props.data.profile_permanent_address === undefined
        ? ""
        : props.data.profile_permanent_address,
    profile_pincode:
      props.data.profile_pincode === undefined
        ? ""
        : props.data.profile_pincode,
    profile_gender:
      props.data.profile_gender === undefined ? "" : props.data.profile_gender,
    profile_selfie_image:
      props.data.profile_selfie_image === undefined
        ? ""
        : props.data.profile_selfie_image,
    profile_selfie_image_data: {
      base64: null,
      size: null,
      content_type: null,
    },
  });

  // send profile value
  useEffect(() => {
    const { cDate, sDate, mDate } = yearsBefore(100);
    setCurrDate(cDate);
    setStartDate(sDate);
    setMaxDate(mDate);
    props.value(profile);
  }, [props, profile]);

  // handle changes into input
  const onHandleChange = async (e, label) => {
    if (label === "profile_name") {
      setProfile({
        ...profile,
        profile_name: e.target.value,
      });
    }
    if (label === "profile_mobile") {
      setProfile({
        ...profile,
        profile_mobile: e.target.value,
      });

      if (props.error.hasOwnProperty("profile_mobile")) {
        props.error["profile_mobile"] = "";
      }
    }
    if (label === "profile_email") {
      setProfile({
        ...profile,
        profile_email: e.target.value,
      });
      if (props.error.hasOwnProperty("profile_email")) {
        props.error["profile_email"] = "";
      }
    }
    if (label === "profile_father_name") {
      setProfile({
        ...profile,
        profile_father_name: e.target.value,
      });
    }
    if (label === "profile_dob") {
      setProfile({
        ...profile,
        profile_dob: e.target.value,
      });
      if (e.target.value > maxDate) {
        props.error["profile_dob"] = "Age must be 18 years or above.";
      } else if (e.target.value < startDate) {
        props.error["profile_dob"] = "Invalid Date of Birth.";
      } else {
        if (props.error.hasOwnProperty("profile_dob")) {
          props.error["profile_dob"] = "";
        }
      }
    }
    if (label === "profile_permanent_address") {
      setProfile({
        ...profile,
        profile_permanent_address: e.target.value,
      });
    }
    if (label === "profile_pincode") {
      setProfile({
        ...profile,
        profile_pincode: e.target.value,
      });
    }
    if (label === "profile_gender") {
      setProfile({
        ...profile,
        profile_gender: e.target.value,
      });
      if (props.error.hasOwnProperty("profile_gender")) {
        props.error["profile_gender"] = "";
      }
    }
    if (label === "profile_selfie_image" && e.target.files[0]) {
      let ext = await getFileExtension(e);
      if (e.target.files[0].size > imageSizeLimit * 1024 * 1024) {
        errorToast(
          `User image size should not be more than ${imageSizeLimit} MB`
        );
        setProfile({
          ...profile,
          profile_selfie_image: "",
        });
      }
      // else if(ext != "jpeg" && ext != "png"){
      //   errorToast(
      //     `Selfie image should be of proper format`
      //   );
      // }
      else if (ext != "jpeg" && ext != "png" && ext != "jpg") {
        props.error["profile_selfie_image"] = "Invalid image format";
        errorToast(`Please upload a valid format for the user image.`);
        setProfile({
          ...profile,
          profile_selfie_image: "",
          profile_selfie_image_data: {
            base64: null,
            size: null,
            content_type: null,
          },
        });
      } else {
        setAddSelfieLoader(true);
        const compressedImage = await imageCompressor(e.target.files[0]);
        let uuid = await getUniqueUUID();
        let fileName = `${uuid}.${ext}`;
        let reader = new FileReader(compressedImage);
        reader.readAsDataURL(compressedImage);
        reader.onload = (evt) => {
          if (ext == "jpeg" || ext == "png" || ext == "jpg") {
            console.log(compressedImage);
            setProfile({
              ...profile,
              profile_selfie_image: fileName,
              profile_selfie_image_data: {
                base64: evt.target.result,
                size: compressedImage.size,
                content_type: compressedImage.type,
              },
            });
          }
          setAddSelfieLoader(false);
        };
        reader.onerror = function (error) {
          console.log("Error while uploading User image : ", error);
          setTimeout(() => {
            errorToast(`User image could not be uploaded, Try again`);
            setAddSelfieLoader(false);
          }, 2000);
        };
        if (props.error.hasOwnProperty("profile_selfie_image")) {
          props.error["profile_selfie_image"] = "";
        }
      }
    }
  };

  // prevent only digit input
  const onKeyPress = (e) => {
    const re = /[^0-9]/g;
    if (re.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <div>
      {/* name */}
      {/* <div className="clientkyc_main_input_form" style={{ marginTop: "20px" }}>
        <label className="clientkyc_main_input_label">
          Full Name<span style={{ color: "red" }}> *</span>
        </label>
        <input
          type="text"
          disabled={props.disable || props.disableNameDob}
          value={profile.profile_name}
          className="clientkyc_main_input_field"
          style={{
            borderColor: props.error.hasOwnProperty("profile_name")
              ? props.error["profile_name"].length > 0
                ? "#d43939"
                : undefined
              : undefined,
          }}
          onChange={(event) => onHandleChange(event, "profile_name")}
        />
        {props.error.hasOwnProperty("profile_name") ? (
          <ErrorValue text={props.error["profile_name"]} />
        ) : (
          <div />
        )}
      </div> */}

      {/* mobile */}
      <div className="clientkyc_main_input_form">
        <label className="clientkyc_main_input_label">
          Mobile Number<span style={{ color: "red" }}> *</span>
        </label>
        <input
          type="text"
          disabled={props.disable || props.mobile.length == 10}
          maxLength={10}
          value={
            props.mobile.length == 10 ? props.mobile : profile.profile_mobile
          }
          className="clientkyc_main_input_field"
          style={{
            borderColor: props.error.hasOwnProperty("profile_mobile")
              ? props.error["profile_mobile"].length > 0
                ? "#d43939"
                : undefined
              : undefined,
          }}
          onChange={(event) => onHandleChange(event, "profile_mobile")}
          onKeyPress={onKeyPress}
        />
        {props.error.hasOwnProperty("profile_mobile") ? (
          <ErrorValue text={props.error["profile_mobile"]} />
        ) : (
          <div />
        )}
      </div>

      {/* email */}
      <div className="clientkyc_main_input_form">
        <label className="clientkyc_main_input_label">
          Email<span style={{ color: "red" }}> *</span>
        </label>
        <input
          type="email"
          disabled={props.disable}
          value={profile.profile_email}
          className="clientkyc_main_input_field"
          style={{
            borderColor: props.error.hasOwnProperty("profile_email")
              ? props.error["profile_email"].length > 0
                ? "#d43939"
                : undefined
              : undefined,
          }}
          onChange={(event) => onHandleChange(event, "profile_email")}
        />
        {props.error.hasOwnProperty("profile_email") ? (
          <ErrorValue text={props.error["profile_email"]} />
        ) : (
          <div />
        )}
      </div>

      {/* father name */}
      {/* <div className="clientkyc_main_input_form">
        <label className="clientkyc_main_input_label">
          Father Name<span style={{ color: "red" }}> *</span>
        </label>
        <input
          type="text"
          disabled={props.disable}
          value={profile.profile_father_name}
          className="clientkyc_main_input_field"
          style={{
            borderColor: props.error.hasOwnProperty("profile_father_name")
              ? props.error["profile_father_name"].length > 0
                ? "#d43939"
                : undefined
              : undefined,
          }}
          onChange={(event) => onHandleChange(event, "profile_father_name")}
        />
        {props.error.hasOwnProperty("profile_father_name") ? (
          <ErrorValue text={props.error["profile_father_name"]} />
        ) : (
          <div />
        )}
      </div> */}

      {/* dob */}
      <div className="clientkyc_main_input_form">
        <label className="clientkyc_main_input_label">
          Date of Birth<span style={{ color: "red" }}> *</span>
        </label>
        <input
          type="date"
          disabled={props.disable || props.disableNameDob}
          value={profile.profile_dob}
          min={startDate}
          max={maxDate}
          className="clientkyc_main_input_field"
          style={{
            borderColor: props.error.hasOwnProperty("profile_dob")
              ? props.error["profile_dob"].length > 0
                ? "#d43939"
                : undefined
              : undefined,
          }}
          onChange={(event) => onHandleChange(event, "profile_dob")}
        />
        {props.error.hasOwnProperty("profile_dob") ? (
          <ErrorValue text={props.error["profile_dob"]} />
        ) : (
          <div />
        )}
      </div>

      {/* Gender */}
      <div className="clientkyc_main_input_form">
        <label className="clientkyc_gender_input_label">
          Gender<span style={{ color: "red" }}> *</span>
        </label>
        <FormControl>
          <RadioGroup
            row
            value={profile.profile_gender}
            onChange={(event) => onHandleChange(event, "profile_gender")}
            disabled={props.disable}
            style={{
              borderColor: props.error.hasOwnProperty("profile_gender")
                ? props.error["profile_gender"].length > 0
                  ? "#d43939"
                  : undefined
                : undefined,
            }}
            className="clientkyc_gender_input"
          >
            <FormControlLabel
              value="male"
              control={<Radio />}
              label="Male"
              sx={{ mr: 5 }}
              disabled={props.disable}
            />
            <FormControlLabel
              value="female"
              control={<Radio />}
              label="Female"
              sx={{ mr: 5 }}
              disabled={props.disable}
            />
            <FormControlLabel
              value="transgender"
              control={<Radio />}
              label="Other"
              disabled={props.disable}
            />
          </RadioGroup>
        </FormControl>
        {props.error.hasOwnProperty("profile_gender") ? (
          <ErrorValue text={props.error["profile_gender"]} />
        ) : (
          <div />
        )}
      </div>

      {/*selfie*/}
      {props.hideUserSelfie == true ? (
        <div className="clientkyc_main_input_form">
          <label className="clientkyc_main_input_label">
            User Image<span style={{ color: "red" }}> *</span>
          </label>

          <div
            className="clientkyc_main_image_div_container"
            style={{
              borderColor: props.error.hasOwnProperty("profile_selfie_image")
                ? props.error["profile_selfie_image"].length > 0
                  ? "#d43939"
                  : undefined
                : undefined,
            }}
          >
            <div
              className="clientkyc_main_image_div"
              style={{
                backgroundColor: props.disable === true ? "#ececec" : undefined,
                color:
                  props.disable === true ? "rgb(119, 119, 119)" : undefined,
              }}
            >
              <p className="clientkyc_main_image_text">
                {profile.profile_selfie_image}
              </p>
            </div>
            {props.disable === true ? (
              <div />
            ) : (
              <button
                onClick={() => {
                  addSelfieRef.current.value = "";
                  addSelfieRef.current.click();
                }}
                className="clientkyc_main_image_button"
                style={{
                  cursor: addSelfieLoader ? "not-allowed" : "pointer",
                }}
                disabled={addSelfieLoader}
              >
                {addSelfieLoader ? (
                  <Loader type="TailSpin" color="#fff" height={18} width={18} />
                ) : profile.profile_selfie_image === "" ||
                  profile.profile_selfie_image === null ? (
                  "Upload"
                ) : (
                  "Change"
                )}
              </button>
            )}
            <input
              type="file"
              ref={addSelfieRef}
              style={{ display: "none" }}
              onChange={(event) =>
                onHandleChange(event, "profile_selfie_image")
              }
            />
          </div>

          {props.error.hasOwnProperty("profile_selfie_image") ? (
            <ErrorValue text={props.error["profile_selfie_image"]} />
          ) : (
            <div />
          )}

          {/* info text */}
          {!props.disable ? (
            <>
              <InputInfoText
                text={`Please upload the image in jpeg/png format. The maximum file size should not exceed ${imageSizeLimit} MB.`}
              />
            </>
          ) : (
            ""
          )}
        </div>
      ) : null}

      {/* address */}
      {/* <div className="clientkyc_main_input_form">
        <label className="clientkyc_main_input_label">
          Address<span style={{ color: "red" }}> *</span>
        </label>
        <input
          type="text"
          disabled={props.disable}
          value={profile.profile_permanent_address}
          className="clientkyc_main_input_field"
          style={{
            borderColor: props.error.hasOwnProperty("profile_permanent_address")
              ? props.error["profile_permanent_address"].length > 0
                ? "#d43939"
                : undefined
              : undefined,
          }}
          onChange={(event) =>
            onHandleChange(event, "profile_permanent_address")
          }
        />
        {props.error.hasOwnProperty("profile_permanent_address") ? (
          <ErrorValue text={props.error["profile_permanent_address"]} />
        ) : (
          <div />
        )}
      </div> */}

      {/* pincode */}
      {/* <div className="clientkyc_main_input_form">
        <label className="clientkyc_main_input_label">
          Pin Code<span style={{ color: "red" }}> *</span>
        </label>
        <input
          type="text"
          disabled={props.disable}
          maxLength={6}
          value={profile.profile_pincode}
          className="clientkyc_main_input_field"
          style={{
            borderColor: props.error.hasOwnProperty("profile_pincode")
              ? props.error["profile_pincode"].length > 0
                ? "#d43939"
                : undefined
              : undefined,
          }}
          onChange={(event) => onHandleChange(event, "profile_pincode")}
          onKeyPress={onKeyPress}
        />
        {props.error.hasOwnProperty("profile_pincode") ? (
          <ErrorValue text={props.error["profile_pincode"]} />
        ) : (
          <div />
        )}
      </div> */}
    </div>
  );
};

export default ClientKycProfile;
